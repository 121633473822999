// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPoopSpamFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.165 2.5c0-.608.522-1.244 1.266-1.087 1.43.302 2.568 1.46 2.568 2.92 0 .64-.221 1.225-.588 1.703a2.334 2.334 0 0 1 1.64 3.41A2.334 2.334 0 0 1 12.331 14H3.665a2.333 2.333 0 0 1-.718-4.554A2.334 2.334 0 0 1 4.28 6.113a2.333 2.333 0 0 1 2.051-3.446H8a.167.167 0 0 0 .166-.167m-1 3.5a.5.5 0 0 0 0 1h1a.5.5 0 1 0 0-1zm2 3.333a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPoopSpamFill.displayName = 'PoopSpamFill';
const Memo = memo(SvgPoopSpamFill);
export default Memo;
