// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMicrophoneMicSoundOnPodcastOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.665 4.667a3.333 3.333 0 1 1 6.667 0v3a3.333 3.333 0 0 1-6.667 0zm3.333-2.334a2.333 2.333 0 0 0-2.333 2.334v3a2.333 2.333 0 0 0 4.667 0v-3a2.333 2.333 0 0 0-2.334-2.334M3.214 9.208a.5.5 0 0 1 .658.258 4.501 4.501 0 0 0 8.252 0 .5.5 0 0 1 .916.4 5.5 5.5 0 0 1-4.542 3.278v1.023a.5.5 0 1 1-1 0v-1.023a5.5 5.5 0 0 1-4.542-3.277.5.5 0 0 1 .258-.659"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMicrophoneMicSoundOnPodcastOutline.displayName = 'MicrophoneMicSoundOnPodcastOutline';
const Memo = memo(SvgMicrophoneMicSoundOnPodcastOutline);
export default Memo;
