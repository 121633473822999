// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrending1TrendsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.497 3.026a.16.16 0 0 0-.063.092L6.351 9.782c-.338 1.081-1.861 1.096-2.22.02l-.843-2.528a.166.166 0 0 0-.311-.012l-.684 1.623a.5.5 0 0 1-.922-.388l.684-1.623c.419-.993 1.84-.938 2.181.084l.844 2.528a.166.166 0 0 0 .316-.003L7.48 2.82c.355-1.135 1.977-1.076 2.249.08l2.347 9.97a.166.166 0 0 0 .319.018l1.299-3.679a.5.5 0 0 1 .943.333l-1.3 3.679c-.388 1.1-1.967 1.013-2.235-.122L8.756 3.13a.16.16 0 0 0-.056-.095A.18.18 0 0 0 8.6 3a.18.18 0 0 0-.102.026"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrending1TrendsOutline.displayName = 'Trending1TrendsOutline';
const Memo = memo(SvgTrending1TrendsOutline);
export default Memo;
