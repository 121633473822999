// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFilterDescendingSortZaDescendingDescOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.668 2a.5.5 0 0 1 .5.5v9.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708l1.146 1.147V2.5a.5.5 0 0 1 .5-.5m4.667.5a.5.5 0 0 1 .5-.5H13.5a.5.5 0 0 1 .382.823l-2.97 3.51H13.5a.5.5 0 0 1 0 1H9.835a.5.5 0 0 1-.382-.823L12.423 3H9.835a.5.5 0 0 1-.5-.5m2.333 6.167a.5.5 0 0 1 .45.282l2.095 4.333a.5.5 0 0 1-.9.436l-.67-1.385h-1.955l-.672 1.385a.5.5 0 0 1-.9-.436l2.102-4.334a.5.5 0 0 1 .45-.281m-.495 2.666h.987l-.493-1.019z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFilterDescendingSortZaDescendingDescOutline.displayName =
  'FilterDescendingSortZaDescendingDescOutline';
const Memo = memo(SvgFilterDescendingSortZaDescendingDescOutline);
export default Memo;
