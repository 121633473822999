// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFitnessFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.093 2a4 4 0 0 0-1.495.29 3.9 3.9 0 0 0-1.267.824L8 3.436l-.331-.322A3.96 3.96 0 0 0 4.907 2c-1.037 0-2.03.4-2.763 1.114A3.75 3.75 0 0 0 1 5.802c0 .444.08.879.231 1.288a.56.56 0 0 1 .307-.089h2.345l1.778-1.835a.56.56 0 0 1 .44-.164.55.55 0 0 1 .409.222l2.487 3.464 1.475-1.522a.56.56 0 0 1 .4-.165h3.59c.114 0 .22.033.307.09a3.7 3.7 0 0 0-.066-2.744 3.8 3.8 0 0 0-.847-1.233 3.9 3.9 0 0 0-1.268-.824A4 4 0 0 0 11.093 2m3.187 6.001h-3.168L9.334 9.835a.56.56 0 0 1-.44.165.55.55 0 0 1-.409-.222L5.998 6.314 4.523 7.835a.56.56 0 0 1-.4.166H1.72q.19.26.424.49l5.524 5.375c.183.178.48.178.663 0l5.525-5.376q.233-.227.424-.489"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFitnessFill.displayName = 'FitnessFill';
const Memo = memo(SvgFitnessFill);
export default Memo;
