// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStar2MagicSparkleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a.5.5 0 0 1 .49.407c.32 1.676.95 2.951 1.884 3.886.934.934 2.21 1.564 3.886 1.883a.5.5 0 0 1 0 .982c-1.676.32-2.952.95-3.886 1.883-.934.934-1.564 2.21-1.883 3.886a.5.5 0 0 1-.982 0c-.32-1.676-.95-2.951-1.884-3.886-.934-.934-2.21-1.564-3.886-1.883a.5.5 0 0 1 0-.982c1.676-.32 2.952-.95 3.886-1.883.934-.935 1.564-2.21 1.884-3.886a.5.5 0 0 1 .49-.407M3.694 8c1.033.392 1.914.943 2.637 1.667S7.607 11.272 8 12.304c.392-1.032.943-1.913 1.667-2.637S11.27 8.392 12.303 8c-1.033-.392-1.913-.943-2.637-1.667S8.391 4.728 7.999 3.696c-.392 1.032-.944 1.913-1.668 2.637C5.608 7.057 4.727 7.608 3.694 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStar2MagicSparkleOutline.displayName = 'Star2MagicSparkleOutline';
const Memo = memo(SvgStar2MagicSparkleOutline);
export default Memo;
