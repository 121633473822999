import React from 'react';
import {Text as ReactText, type TextStyle} from 'react-native';

import {useHive} from '@propertyguru/hive';

import type {TextProps} from './types';

const getTextDecorationLine = (
  underline: boolean = false,
  strikethrough: boolean = false,
): TextStyle['textDecorationLine'] => {
  switch (true) {
    case underline && strikethrough:
      return 'underline line-through';
    case underline:
      return 'underline';
    case strikethrough:
      return 'line-through';
    default:
      return undefined;
  }
};

export const Text: React.FC<TextProps> = ({
  children,
  typography = 'body/m',
  color = 'text/active/primary',
  underline = false,
  strikethrough = false,
  style,
  ...rest
}) => {
  const hive = useHive();

  return (
    <ReactText
      {...rest}
      style={[
        hive.typography(typography),
        {
          color: hive.color(color),
          textDecorationLine: getTextDecorationLine(underline, strikethrough),
        },
        style,
      ]}
    >
      {children}
    </ReactText>
  );
};
