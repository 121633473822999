// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrainMrtFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.437 3.456v5.932c0 .564.3 1.06.744 1.316l-1.204 2.164A.5.5 0 0 0 2.9 13l-.84 1.512c-.124.22-.052.5.16.628s.48.052.604-.168l.752-1.352h8.84l.756 1.352c.124.22.392.292.604.164a.47.47 0 0 0 .16-.628L13.093 13a.4.4 0 0 0-.08-.14l-1.2-2.144c.46-.252.772-.756.772-1.332V3.456c0-1.248-.972-2.256-2.168-2.256H5.605c-1.196 0-2.168 1.012-2.168 2.256m7.492 7.44H5.057a.4.4 0 0 1-.056.168l-.912 1.64h7.816l-.916-1.636a.4.4 0 0 1-.056-.168zm.776-6.756H4.32v2.02l3.692.8 3.692-.8zM5.093 8.776c0-.252.196-.46.44-.46h.792c.244 0 .44.204.44.46s-.196.46-.44.46h-.792a.45.45 0 0 1-.44-.46m4.592-.46a.45.45 0 0 0-.44.46c0 .256.196.46.44.46h.792c.244 0 .44-.204.44-.46a.45.45 0 0 0-.44-.46z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrainMrtFill.displayName = 'TrainMrtFill';
const Memo = memo(SvgTrainMrtFill);
export default Memo;
