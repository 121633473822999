// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgJumpropeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.313 5.733c0-2.578 1.924-4.734 4.375-4.734.46 0 .9.075 1.313.215.413-.14.854-.215 1.313-.215 2.451 0 4.375 2.156 4.375 4.734v2.704h.438a.875.875 0 0 1 .875.875v3.939a1.75 1.75 0 0 1-3.5 0V9.312a.875.875 0 0 1 .875-.875h.437V5.733c0-2.168-1.602-3.86-3.5-3.86l-.087.002c1.116.863 1.834 2.277 1.837 3.849a3.19 3.19 0 0 1-3.083 3.31 3.19 3.19 0 0 1-3.043-3.31c.003-1.572.72-2.986 1.837-3.849l-.087-.001c-1.898 0-3.5 1.69-3.5 3.859v2.704h.437a.875.875 0 0 1 .876.875v3.939a1.75 1.75 0 0 1-3.501 0V9.312a.875.875 0 0 1 .875-.875h.438zM8 2.156c-1.27.568-2.188 1.936-2.188 3.577v.018A2.315 2.315 0 0 0 8 8.159a2.315 2.315 0 0 0 2.188-2.426c0-1.641-.918-3.01-2.188-3.577"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgJumpropeFill.displayName = 'JumpropeFill';
const Memo = memo(SvgJumpropeFill);
export default Memo;
