// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBurgerHamburgerSandwichFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.332 9.333c0-.368.299-.666.667-.666h12a.667.667 0 1 1 0 1.333h-12a.667.667 0 0 1-.667-.667M11.399 14c.35 0 .642 0 .88-.02.25-.02.484-.062.706-.173.355-.178.643-.466.82-.82.111-.223.155-.457.175-.706.009-.116.014-.324.016-.548a.66.66 0 0 0-.664-.666H2.665a.66.66 0 0 0-.664.666c.003.224.008.432.017.548.02.25.063.483.174.705.177.355.465.643.82.82.222.112.456.155.706.175.238.019.53.019.88.019zM4.09 3.115C5.28 2.29 6.765 2 7.998 2s2.717.29 3.91 1.115c1.138.789 1.974 2.044 2.079 3.885.02.368-.288.667-.656.667H2.665c-.368 0-.676-.3-.655-.667.104-1.841.94-3.096 2.08-3.885" />
    </Svg>
  );
};
SvgBurgerHamburgerSandwichFill.displayName = 'BurgerHamburgerSandwichFill';
const Memo = memo(SvgBurgerHamburgerSandwichFill);
export default Memo;
