// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgColorsPaletteColoursOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.667C4.843 2.667 2.332 5.08 2.332 8s2.51 5.333 5.667 5.333q.105 0 .174-.006a4 4 0 0 0-.144-.283l-.068-.125c-.147-.264-.335-.603-.453-.953-.14-.418-.211-.934.043-1.444.405-.811 1.182-.983 1.885-.973.352.005.723.055 1.077.11l.359.06c.234.038.46.075.689.105.703.092 1.205.073 1.532-.131.277-.173.572-.582.572-1.693 0-2.92-2.51-5.333-5.666-5.333M1.332 8c0-3.524 3.011-6.333 6.667-6.333S14.665 4.477 14.665 8c0 1.259-.337 2.101-1.043 2.542-.657.41-1.49.365-2.19.274-.246-.032-.502-.075-.746-.115l-.328-.053a7 7 0 0 0-.937-.099c-.557-.008-.827.124-.975.42-.088.176-.088.389.01.678.086.256.224.505.372.773q.039.071.08.144c.081.15.169.317.23.478.056.15.119.37.071.602a.79.79 0 0 1-.484.572c-.208.09-.46.117-.726.117-3.656 0-6.667-2.81-6.667-6.333m5.5-3.167a.333.333 0 1 0 0 .667.333.333 0 0 0 0-.667m-1.333.334a1.333 1.333 0 1 1 2.666 0 1.333 1.333 0 0 1-2.666 0m4.666.666a.333.333 0 1 0 0 .667.333.333 0 0 0 0-.667m-1.333.334a1.333 1.333 0 1 1 2.667 0 1.333 1.333 0 0 1-2.667 0m-4 1.5a.333.333 0 1 0 0 .666.333.333 0 0 0 0-.666M3.499 8a1.333 1.333 0 1 1 2.666 0A1.333 1.333 0 0 1 3.5 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgColorsPaletteColoursOutline.displayName = 'ColorsPaletteColoursOutline';
const Memo = memo(SvgColorsPaletteColoursOutline);
export default Memo;
