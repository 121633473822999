// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSoccerFootballMlsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m6.825 2.455.684.497a.83.83 0 0 0 .98 0l.683-.497a5.7 5.7 0 0 0-2.347 0m3.519.385-1.268.92a1.83 1.83 0 0 1-2.155 0l-1.268-.92c-.7.318-1.324.776-1.837 1.337L4.3 5.665a1.83 1.83 0 0 1-.666 2.05l-1.267.92c.087.78.332 1.51.702 2.161h1.566c.794 0 1.498.512 1.744 1.267l.484 1.49a5.7 5.7 0 0 0 2.272 0l.484-1.49a1.83 1.83 0 0 1 1.743-1.267h1.567c.37-.65.614-1.382.701-2.16l-1.267-.921a1.83 1.83 0 0 1-.666-2.05l.484-1.488a5.7 5.7 0 0 0-1.837-1.337m2.566 2.33-.261.804c-.112.344.01.72.302.932l.683.496a5.6 5.6 0 0 0-.724-2.231m-.704 6.626h-.844a.83.83 0 0 0-.792.576l-.262.804a5.7 5.7 0 0 0 1.898-1.38m-6.517 1.38-.261-.804a.83.83 0 0 0-.793-.576h-.844a5.7 5.7 0 0 0 1.898 1.38M2.363 7.402l.683-.496a.83.83 0 0 0 .303-.932l-.261-.803a5.6 5.6 0 0 0-.725 2.231m3.17-5.598A6.7 6.7 0 0 1 8 1.334a6.65 6.65 0 0 1 5.13 2.408 6.64 6.64 0 0 1 1.523 4.687 6.6 6.6 0 0 1-1.018 3.135 6.68 6.68 0 0 1-3.988 2.898 6.68 6.68 0 0 1-7.282-2.898A6.63 6.63 0 0 1 1.332 8c0-1.618.577-3.103 1.537-4.258a6.7 6.7 0 0 1 2.664-1.938m1.388 4.194a1.83 1.83 0 0 1 2.155 0l.493.359c.643.466.912 1.294.666 2.05l-.188.58a1.83 1.83 0 0 1-1.743 1.266h-.61A1.83 1.83 0 0 1 5.95 8.986l-.188-.58a1.83 1.83 0 0 1 .666-2.05zm2.06 1.168-.492-.359a.83.83 0 0 0-.98 0l-.493.359a.83.83 0 0 0-.303.931l.188.58a.83.83 0 0 0 .793.576h.61a.83.83 0 0 0 .792-.576l.188-.58a.83.83 0 0 0-.302-.931"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSoccerFootballMlsOutline.displayName = 'SoccerFootballMlsOutline';
const Memo = memo(SvgSoccerFootballMlsOutline);
export default Memo;
