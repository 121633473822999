// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddPagesWideAddBoardsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.719 4.938a.78.78 0 0 0-.781.78v4.063c0 .432.35.781.78.781H4.5V8.22c0-.95.77-1.719 1.719-1.719h4.844v-.781a.78.78 0 0 0-.782-.782zM12 6.5v-.781C12 4.769 11.23 4 10.281 4H3.72C2.769 4 2 4.77 2 5.719V9.78c0 .95.77 1.719 1.719 1.719H4.5v.781c0 .95.77 1.719 1.719 1.719h6.562c.95 0 1.719-.77 1.719-1.719V8.22c0-.95-.77-1.719-1.719-1.719zm-5.781.938a.78.78 0 0 0-.782.78v4.063c0 .432.35.781.782.781h6.562c.432 0 .781-.35.781-.78V8.218a.78.78 0 0 0-.78-.781zm3.281.937c.259 0 .469.21.469.469v.937h.937a.469.469 0 1 1 0 .938H9.97v.937a.469.469 0 1 1-.938 0v-.937h-.937a.469.469 0 0 1 0-.938h.937v-.937c0-.26.21-.469.469-.469"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAddPagesWideAddBoardsOutline.displayName = 'AddPagesWideAddBoardsOutline';
const Memo = memo(SvgAddPagesWideAddBoardsOutline);
export default Memo;
