// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudOff2OfflineFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m6.304 13.334 4.915-9.313a4.48 4.48 0 0 1 1.272 2.838 3.25 3.25 0 0 1-.406 6.475zM10.944 2.4a.5.5 0 1 0-.885-.467l-.528 1a4.501 4.501 0 0 0-5.677 2.483 4.001 4.001 0 0 0 .212 7.873l-.34.644a.5.5 0 0 0 .884.467z" />
    </Svg>
  );
};
SvgCloudOff2OfflineFill.displayName = 'CloudOff2OfflineFill';
const Memo = memo(SvgCloudOff2OfflineFill);
export default Memo;
