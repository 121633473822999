// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCallPlusAddFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.815 2c-.983 0-1.863.804-1.75 1.864a11.3 11.3 0 0 0 1.46 4.482 11.4 11.4 0 0 0 4.13 4.13 11.3 11.3 0 0 0 4.46 1.448c1.065.118 1.886-.762 1.886-1.757v-1.17c0-.813-.536-1.529-1.316-1.758l-1.122-.33a1.8 1.8 0 0 0-1.801.479c-.251.261-.609.302-.857.142a8.2 8.2 0 0 1-2.434-2.435c-.16-.247-.12-.605.142-.856a1.8 1.8 0 0 0 .479-1.802l-.33-1.121A1.83 1.83 0 0 0 5.003 2z" />
      <Path
        stroke="#0D1011"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.42 1.645V3.81m0 0v2.167m0-2.167H9.255m2.167 0h2.166"
      />
    </Svg>
  );
};
SvgCallPlusAddFill.displayName = 'CallPlusAddFill';
const Memo = memo(SvgCallPlusAddFill);
export default Memo;
