// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShowEyeSeeRevealLookVisibleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 2.667c2.586 0 5.105 1.546 6.788 4.498a1.69 1.69 0 0 1 0 1.67c-1.683 2.952-4.202 4.498-6.788 4.498s-5.105-1.546-6.788-4.498a1.69 1.69 0 0 1 0-1.67c1.683-2.952 4.202-4.498 6.788-4.498M5.584 8a2.417 2.417 0 1 1 4.834 0 2.417 2.417 0 0 1-4.834 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShowEyeSeeRevealLookVisibleFill.displayName = 'ShowEyeSeeRevealLookVisibleFill';
const Memo = memo(SvgShowEyeSeeRevealLookVisibleFill);
export default Memo;
