// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCameraPictureImageCamCloseCrossOffFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.046 2c-.613 0-1.186.306-1.526.816l-.542.813A.83.83 0 0 1 4.285 4h-1.12c-1.012 0-1.833.82-1.833 1.833v6.334c0 1.012.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833V5.833c0-1.012-.82-1.833-1.833-1.833h-1.12a.83.83 0 0 1-.693-.371l-.542-.813A1.83 1.83 0 0 0 8.952 2zM6.31 7.313a.5.5 0 0 1 .707 0l.98.981.982-.98a.5.5 0 0 1 .707.706l-.981.981.98.98a.5.5 0 1 1-.708.707l-.98-.98-.98.98a.5.5 0 1 1-.706-.707l.98-.98-.981-.98a.5.5 0 0 1 0-.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCameraPictureImageCamCloseCrossOffFill.displayName = 'CameraPictureImageCamCloseCrossOffFill';
const Memo = memo(SvgCameraPictureImageCamCloseCrossOffFill);
export default Memo;
