// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAiImagesMidjourneyAiGeneratedFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM3.833 3A.833.833 0 0 0 3 3.833v5.793L4.037 8.59a1.833 1.833 0 0 1 2.593 0L11.04 13h1.127c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3z"
        clipRule="evenodd"
      />
      <Path d="M8.95 5.692a.17.17 0 0 0 .075-.075l.493-.986a.167.167 0 0 1 .298 0l.492.986a.17.17 0 0 0 .075.075l.986.492a.167.167 0 0 1 0 .298l-.986.493a.17.17 0 0 0-.074.075l-.493.985a.167.167 0 0 1-.298 0l-.493-.985a.17.17 0 0 0-.075-.075l-.985-.493a.167.167 0 0 1 0-.298z" />
    </Svg>
  );
};
SvgAiImagesMidjourneyAiGeneratedFill.displayName = 'AiImagesMidjourneyAiGeneratedFill';
const Memo = memo(SvgAiImagesMidjourneyAiGeneratedFill);
export default Memo;
