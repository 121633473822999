// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSoccerFootballMlsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m10.344 2.84-1.268.92a1.83 1.83 0 0 1-2.155 0l-1.268-.92c-.7.318-1.324.776-1.837 1.337L4.3 5.665a1.83 1.83 0 0 1-.666 2.05l-1.267.92c.087.78.332 1.51.702 2.161h1.566c.794 0 1.498.512 1.744 1.267l.484 1.49a5.7 5.7 0 0 0 2.272 0l.484-1.49a1.83 1.83 0 0 1 1.743-1.267h1.567c.37-.65.614-1.382.701-2.16l-1.267-.921a1.83 1.83 0 0 1-.666-2.05l.484-1.488a5.7 5.7 0 0 0-1.837-1.337m-4.81-1.036a6.7 6.7 0 0 1 2.465-.47 6.65 6.65 0 0 1 5.13 2.408 6.64 6.64 0 0 1 1.523 4.687 6.6 6.6 0 0 1-1.018 3.135 6.68 6.68 0 0 1-3.988 2.898 6.68 6.68 0 0 1-7.282-2.898A6.63 6.63 0 0 1 1.332 8c0-1.618.577-3.103 1.537-4.258a6.7 6.7 0 0 1 2.664-1.938M6.92 5.998a1.83 1.83 0 0 1 2.155 0l.493.359c.643.466.912 1.294.666 2.05l-.188.58a1.83 1.83 0 0 1-1.743 1.266h-.61A1.83 1.83 0 0 1 5.95 8.986l-.188-.58a1.83 1.83 0 0 1 .666-2.05z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSoccerFootballMlsFill.displayName = 'SoccerFootballMlsFill';
const Memo = memo(SvgSoccerFootballMlsFill);
export default Memo;
