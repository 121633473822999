// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgKitchenFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.984 3.603v-1.62H5.34v1.62zM1.926 1h12.149c.511 0 .926.414.926.925v1.736a.926.926 0 0 1-.926.926H1.926A.926.926 0 0 1 1 3.66V1.925c0-.51.414-.925.926-.925m8.736 2.603h3.355v-1.62h-3.355zm-.983-1.62v1.62H6.323v-1.62zM7.893 8.521h2.539l-.317-1.582a.492.492 0 0 1 .483-.588H13.2a.492.492 0 0 1 .482.588l-.316 1.582h.274c.75 0 1.36.608 1.36 1.36v3.76c0 .75-.61 1.36-1.36 1.36H2.36A1.36 1.36 0 0 1 1 13.64V9.88c0-.75.609-1.36 1.36-1.36H3.76a2.66 2.66 0 0 1-.595-1.677c0-.272.22-.492.491-.492h4.34c.271 0 .491.22.491.492 0 .614-.212 1.206-.595 1.678m-1.57 3.297h3.356v2.199H6.323zm4.34 0v2.199h2.978a.376.376 0 0 0 .376-.376v-1.823zm-5.323 0H1.984v1.823c0 .207.168.376.376.376h2.98z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgKitchenFill.displayName = 'KitchenFill';
const Memo = memo(SvgKitchenFill);
export default Memo;
