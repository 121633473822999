// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAltAltTextAltTagOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833v-7a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
      <Path d="M3.588 10.06c-.321 0-.518-.182-.518-.484 0-.085.028-.21.072-.332l.985-2.706c.147-.406.393-.592.789-.592.418 0 .661.177.813.592l.994 2.706c.047.13.066.225.066.33 0 .285-.213.487-.509.487-.293 0-.446-.133-.534-.46L5.61 9.16H4.243l-.135.426c-.097.337-.25.476-.52.476m.857-1.668h.944l-.468-1.538H4.9zM7.788 10c-.321 0-.51-.194-.51-.531V6.475c0-.338.189-.529.51-.529.32 0 .506.191.506.529V9.18h1.25c.272 0 .452.15.452.41S9.819 10 9.545 10zM11.38 10.06c-.322 0-.507-.19-.507-.528V6.826h-.7c-.271 0-.454-.15-.454-.41s.177-.409.454-.409h2.415c.275 0 .452.15.452.41s-.183.41-.451.41h-.703v2.705c0 .338-.186.529-.507.529" />
    </Svg>
  );
};
SvgAltAltTextAltTagOutline.displayName = 'AltAltTextAltTagOutline';
const Memo = memo(SvgAltAltTextAltTagOutline);
export default Memo;
