// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEdit01Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.204 2.09a1.833 1.833 0 0 1 2.592 0l.115.114a1.833 1.833 0 0 1 0 2.592l-3.057 3.058A.5.5 0 0 1 10.5 8h-2a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .146-.354z" />
      <Path d="M7.5 2H4.613c-.356 0-.653 0-.896.02-.253.02-.491.065-.716.18A1.83 1.83 0 0 0 2.2 3c-.115.225-.16.463-.18.716-.02.243-.02.54-.02.896v6.774c0 .356 0 .653.02.896.02.253.065.491.18.716.176.345.456.625.801.801.225.115.463.16.716.18.243.02.54.02.896.02h6.774c.356 0 .653 0 .896-.02.253-.02.491-.065.716-.18.345-.176.625-.456.801-.801.115-.225.16-.463.18-.716.02-.243.02-.54.02-.896V8.5a.5.5 0 0 0-1 0v2.867c0 .381 0 .637-.017.835-.015.191-.043.283-.074.343a.83.83 0 0 1-.364.364c-.06.03-.152.059-.343.074-.198.017-.454.017-.835.017H4.633c-.381 0-.637 0-.835-.017-.191-.015-.283-.043-.343-.074a.83.83 0 0 1-.364-.364c-.03-.06-.059-.152-.074-.343A12 12 0 0 1 3 11.367V4.633c0-.381 0-.637.017-.835.015-.191.043-.283.074-.343a.83.83 0 0 1 .364-.364c.06-.03.152-.059.343-.074C3.996 3 4.252 3 4.633 3H7.5a.5.5 0 0 0 0-1" />
    </Svg>
  );
};
SvgEdit01Fill.displayName = 'Edit01Fill';
const Memo = memo(SvgEdit01Fill);
export default Memo;
