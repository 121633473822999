// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCirclePlaceholderOffOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333c-.895 0-1.74.207-2.492.576a.5.5 0 1 1-.44-.898 6.667 6.667 0 0 1 8.92 8.92.5.5 0 0 1-.897-.44 5.667 5.667 0 0 0-5.091-8.158m-6.02-.354a.5.5 0 0 1 .707 0l1.306 1.306 10.027 10.028a.5.5 0 1 1-.707.707l-.966-.966a6.667 6.667 0 0 1-9.402-9.402l-.966-.965a.5.5 0 0 1 0-.708m1.675 2.383a5.667 5.667 0 0 0 7.983 7.983z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCirclePlaceholderOffOutline.displayName = 'CirclePlaceholderOffOutline';
const Memo = memo(SvgCirclePlaceholderOffOutline);
export default Memo;
