// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgColorPickerColorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.923 3.296a.833.833 0 0 0-1.179 0l-.354-.353.354.353-2.37 2.37 1.96 1.96 2.37-2.37a.833.833 0 0 0 0-1.179zm-.883 5.037 2.37-2.37a1.833 1.833 0 0 0 0-2.593l-.78-.78a1.833 1.833 0 0 0-2.593 0l-2.37 2.37-.647-.647a.5.5 0 1 0-.707.707l.647.647-4.618 4.618A1.17 1.17 0 0 0 2 11.11v1.723C2 13.478 2.522 14 3.167 14H4.89c.309 0 .606-.123.824-.342l4.618-4.618.647.647a.5.5 0 1 0 .707-.707zm-1.414 0-1.96-1.96-4.617 4.619-.351-.352.35.352a.17.17 0 0 0-.048.117v1.724c0 .092.075.167.167.167H4.89a.17.17 0 0 0 .117-.049z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgColorPickerColorOutline.displayName = 'ColorPickerColorOutline';
const Memo = memo(SvgColorPickerColorOutline);
export default Memo;
