// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPhoneDeviceIphoneMobileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.332 2.5c0-1.013.82-1.833 1.833-1.833h5.667c1.013 0 1.833.82 1.833 1.833v11c0 1.012-.82 1.833-1.833 1.833H5.165A1.833 1.833 0 0 1 3.332 13.5zm3.167 9.833a.5.5 0 0 0 0 1h3a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPhoneDeviceIphoneMobileFill.displayName = 'PhoneDeviceIphoneMobileFill';
const Memo = memo(SvgPhoneDeviceIphoneMobileFill);
export default Memo;
