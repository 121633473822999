// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgIceHockeyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.12 5.044c-.334.304-.452.576-.452.803s.118.5.452.803c.335.305.847.602 1.522.862 1.347.518 3.242.85 5.36.85 2.117 0 4.012-.332 5.359-.85.675-.26 1.187-.557 1.522-.862s.452-.576.452-.803-.118-.499-.452-.803c-.335-.304-.847-.602-1.522-.861-1.347-.519-3.242-.85-5.36-.85-2.117 0-4.012.331-5.36.85-.674.26-1.186.557-1.521.86m14.215 2.532c-.433.337-.986.627-1.615.869-1.488.573-3.51.916-5.719.916-2.208 0-4.23-.343-5.718-.916-.63-.242-1.182-.532-1.615-.87v2.578c0 .227.118.499.452.803.335.304.847.601 1.522.861 1.347.518 3.242.85 5.36.85 2.117 0 4.012-.332 5.359-.85.675-.26 1.187-.557 1.522-.861s.452-.576.452-.803z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgIceHockeyFill.displayName = 'IceHockeyFill';
const Memo = memo(SvgIceHockeyFill);
export default Memo;
