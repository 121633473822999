// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSecretarialServicesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.849 2.167a1.2 1.2 0 0 1 1.236 1.153v.097H5.92V3.32a1.2 1.2 0 0 1 1.237-1.153h1.693m-3.763 1.25v-.118a2.034 2.034 0 0 1 2.089-1.965h1.654a2.033 2.033 0 0 1 2.09 1.965v.118h2.246a1.504 1.504 0 0 1 1.504 1.503v3.37a.96.96 0 0 1-.768.94l.614 3.996a1.25 1.25 0 0 1-1.236 1.44H2.724a1.25 1.25 0 0 1-1.235-1.44l.614-3.996a.96.96 0 0 1-.767-.94V4.92a1.503 1.503 0 0 1 1.503-1.503zm-2.501 5h3.75v-.834a.833.833 0 0 1 .834-.833h1.666a.833.833 0 0 1 .834.833v.833h4.04a.127.127 0 0 0 .126-.126V4.92a.67.67 0 0 0-.67-.67H2.839a.67.67 0 0 0-.67.67v3.37a.127.127 0 0 0 .127.126zm10.476.833H9.616a1.666 1.666 0 0 1-3.227 0H2.943l-.63 4.103a.417.417 0 0 0 .411.48H13.28a.42.42 0 0 0 .402-.302.4.4 0 0 0 .01-.178zM7.169 7.583v1.25a.833.833 0 1 0 1.666 0v-1.25z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSecretarialServicesOutline.displayName = 'SecretarialServicesOutline';
const Memo = memo(SvgSecretarialServicesOutline);
export default Memo;
