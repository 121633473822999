// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRescueRingSwimBoyleHelpSupportOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.654 4.362A5.64 5.64 0 0 0 2.332 8c0 1.385.497 2.654 1.322 3.638l1.66-1.661A3.3 3.3 0 0 1 4.666 8c0-.74.242-1.424.65-1.977zm.707-.707 1.66 1.661A3.3 3.3 0 0 1 8 4.666c.74 0 1.424.242 1.977.65l1.66-1.66A5.64 5.64 0 0 0 8 2.332 5.64 5.64 0 0 0 4.36 3.655m7.983.707-1.661 1.661c.408.553.649 1.237.649 1.977s-.241 1.424-.65 1.977l1.662 1.66A5.64 5.64 0 0 0 13.665 8a5.64 5.64 0 0 0-1.321-3.638m-.707 7.983-1.661-1.66a3.3 3.3 0 0 1-1.977.648c-.74 0-1.424-.24-1.977-.649L4.36 12.345a5.64 5.64 0 0 0 3.638 1.322 5.64 5.64 0 0 0 3.638-1.322M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.667-2.333a2.333 2.333 0 1 0 0 4.666 2.333 2.333 0 0 0 0-4.666"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRescueRingSwimBoyleHelpSupportOutline.displayName = 'RescueRingSwimBoyleHelpSupportOutline';
const Memo = memo(SvgRescueRingSwimBoyleHelpSupportOutline);
export default Memo;
