// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgIceHockeyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.027 5.7c-.286.24-.359.433-.359.564s.073.323.359.563c.283.239.723.476 1.31.684 1.17.417 2.819.683 4.664.683 1.846 0 3.495-.266 4.665-.683.587-.208 1.027-.445 1.31-.684.286-.24.359-.433.359-.563s-.073-.323-.36-.564c-.282-.238-.722-.475-1.31-.684-1.17-.416-2.818-.683-4.664-.683s-3.494.267-4.664.683c-.587.21-1.027.446-1.31.684m12.308 2.107c-.37.25-.825.465-1.334.647-1.303.463-3.071.74-5 .74s-3.696-.277-5-.74a6 6 0 0 1-1.333-.647v1.93c0 .13.073.322.359.563.283.238.723.475 1.31.684 1.17.416 2.819.683 4.664.683 1.846 0 3.495-.267 4.665-.683.587-.21 1.027-.446 1.31-.684.286-.24.359-.433.359-.564zm1-1.543v3.472c0 .54-.31.988-.716 1.329-.408.343-.969.63-1.618.86-1.303.464-3.071.742-5 .742s-3.696-.278-5-.741c-.649-.231-1.21-.518-1.618-.861-.406-.341-.715-.788-.715-1.329V6.264c0-.54.31-.988.715-1.329.408-.343.97-.63 1.619-.86 1.303-.464 3.07-.742 5-.742 1.928 0 3.695.278 4.999.741.65.231 1.21.518 1.618.861.406.341.716.788.716 1.329"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgIceHockeyOutline.displayName = 'IceHockeyOutline';
const Memo = memo(SvgIceHockeyOutline);
export default Memo;
