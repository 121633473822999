// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudSyncDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.501 1.334c-1.012 0-1.833.82-1.833 1.833v9.667c0 1.012.82 1.833 1.833 1.833h3.884a3.5 3.5 0 1 1 4.95-4.95v-6.55c0-1.013-.821-1.833-1.834-1.833z" />
      <Path
        fillRule="evenodd"
        d="M10.835 9.667a2.5 2.5 0 0 0 0 5h1.833a2 2 0 0 0 .172-3.993 2.5 2.5 0 0 0-2.005-1.007m-1.5 2.5a1.5 1.5 0 0 1 2.8-.747.5.5 0 0 0 .466.249l.067-.002a1 1 0 1 1 0 2h-1.833a1.5 1.5 0 0 1-1.5-1.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudSyncDocumentListPageFileFill.displayName = 'CloudSyncDocumentListPageFileFill';
const Memo = memo(SvgCloudSyncDocumentListPageFileFill);
export default Memo;
