// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHeartLikeHealthLifeFavoriteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.268 3.873c-.543.58-.936 1.484-.936 2.752 0 1.742 1.259 3.332 2.71 4.535.712.59 1.44 1.062 2.02 1.385.29.162.537.283.722.362q.14.06.214.083H8q.074-.024.214-.083c.184-.079.432-.2.722-.362a13.7 13.7 0 0 0 2.02-1.385c1.45-1.203 2.71-2.793 2.71-4.535 0-1.268-.394-2.171-.937-2.752A2.71 2.71 0 0 0 10.74 3a3.68 3.68 0 0 0-2.244.763 2 2 0 0 0-.14.12l-.004.003.001-.001m0 0q-.002.001 0 0m-.706 0-.004-.003-.025-.023a3.457 3.457 0 0 0-.587-.405A3.7 3.7 0 0 0 5.259 3c-.722 0-1.445.289-1.99.873m4.378.013a.5.5 0 0 0 .705 0M2.537 3.19A3.71 3.71 0 0 1 5.258 2a4.68 4.68 0 0 1 2.74.885A4.68 4.68 0 0 1 10.739 2c.993 0 1.983.398 2.722 1.19.742.794 1.205 1.953 1.205 3.435 0 2.21-1.567 4.058-3.071 5.305a14.7 14.7 0 0 1-2.172 1.488 9 9 0 0 1-.813.408q-.168.073-.303.116c-.073.024-.19.058-.307.058s-.234-.034-.307-.058a4 4 0 0 1-.303-.116 9 9 0 0 1-.814-.408 14.7 14.7 0 0 1-2.171-1.488c-1.505-1.247-3.072-3.094-3.072-5.305 0-1.482.464-2.641 1.205-3.435"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHeartLikeHealthLifeFavoriteOutline.displayName = 'HeartLikeHealthLifeFavoriteOutline';
const Memo = memo(SvgHeartLikeHealthLifeFavoriteOutline);
export default Memo;
