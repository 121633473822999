// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedRealityScan3DOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.413 4.812a.83.83 0 0 0-.826 0L5.507 6 8 7.424 10.492 6zM11 6.862 8.5 8.29v2.848l2.08-1.188a.83.83 0 0 0 .42-.724zm-3.5 4.276V8.29L5 6.862v2.364c0 .3.16.575.42.724zm-.41-7.194a1.83 1.83 0 0 1 1.82 0l2.166 1.238c.572.326.924.934.924 1.592v2.452c0 .658-.352 1.266-.924 1.592L8.91 12.056a1.83 1.83 0 0 1-1.82 0l-2.166-1.238A1.83 1.83 0 0 1 4 9.226V6.774c0-.658.353-1.266.924-1.592z"
        clipRule="evenodd"
      />
      <Path d="M13.332 3a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M2.665 3a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M13.332 13.667a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M2.665 13.667a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667" />
      <Path
        fillRule="evenodd"
        d="M13.333 3.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m-10.666 0a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333M13.333 14a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333M2.667 14a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333M2.333 2.667a.333.333 0 1 0 .667 0 .333.333 0 0 0-.667 0m10.667 0a.333.333 0 1 0 .667 0 .333.333 0 0 0-.667 0m.333 11a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667m-11-.334a.333.333 0 1 0 .667 0 .333.333 0 0 0-.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArAugmentedRealityScan3DOutline.displayName = 'ArAugmentedRealityScan3DOutline';
const Memo = memo(SvgArAugmentedRealityScan3DOutline);
export default Memo;
