// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShiftFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.832 1.638a1.167 1.167 0 0 0-1.668 0L1.58 7.351c-.722.739-.199 1.982.834 1.982h1.662v2.834c0 1.012.82 1.833 1.833 1.833h4.176c1.012 0 1.833-.82 1.833-1.833V9.333h1.662c1.033 0 1.556-1.243.835-1.982z" />
    </Svg>
  );
};
SvgShiftFill.displayName = 'ShiftFill';
const Memo = memo(SvgShiftFill);
export default Memo;
