// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAppleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.124 12.439q.293-.417.524-.905a4 4 0 0 1-.36-.304c-.812-.755-1.229-1.725-1.24-2.84v-.002c-.01-1.027.33-1.905.97-2.605-.292-.154-.648-.252-1.087-.288h-.004c-.215-.018-.566.024-1.092.213-.321.118-.59.211-.798.276a5 5 0 0 1-.294.083c-.066.016-.21.05-.366.05-.233 0-.478-.066-.645-.115q-.302-.091-.713-.245c-.478-.178-.766-.23-.912-.232a2.27 2.27 0 0 0-1.138.323 2.4 2.4 0 0 0-.862.874l-.003.004-.002.005c-.278.463-.438 1.049-.438 1.796 0 .656.12 1.351.374 2.092.24.692.54 1.28.891 1.775l.002.002c.374.53.641.835.806.98l.012.01.012.01c.28.26.436.273.49.27h.009c.094-.003.315-.044.707-.203h.001a4.3 4.3 0 0 1 1.588-.334c.52 0 1.042.124 1.558.335l.003.001c.348.143.576.184.704.187.136-.006.303-.059.521-.26l.01-.009.012-.009c.14-.123.392-.41.76-.935m-1.292 2.213q-.456 0-1.096-.262t-1.18-.261q-.565 0-1.214.26-.65.264-1.048.277-.608.025-1.214-.537-.386-.337-.966-1.156a8 8 0 0 1-1.021-2.028q-.429-1.248-.429-2.418 0-1.341.58-2.31a3.4 3.4 0 0 1 1.214-1.229A3.27 3.27 0 0 1 6.1 4.525q.469 0 1.226.28l.044.016q.603.226.874.28.082.016.133.016.166 0 1.117-.349.723-.26 1.266-.276.132-.004.252.006 1.073.087 1.805.603.415.294.72.727-.246.15-.45.317-1.053.863-1.04 2.234.015 1.278.925 2.121.41.392.923.606-.11.322-.235.618-.302.698-.717 1.289-.566.806-.924 1.116-.551.508-1.187.523m-.53-11.122.056-.064q.449-.533.621-1.117.108-.362.108-.746V1.6q0-.132-.014-.267a2 2 0 0 0-.25.028 3 3 0 0 0-.828.278 3 3 0 0 0-.943.707 3.2 3.2 0 0 0-.685 1.246 2.5 2.5 0 0 0-.085.53 2 2 0 0 0 .012.362 1.96 1.96 0 0 0 1.028-.193c.352-.165.681-.426.98-.761"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAppleOutline.displayName = 'AppleOutline';
const Memo = memo(SvgAppleOutline);
export default Memo;
