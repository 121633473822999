// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVideoClapperboardFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1 0c0-.46.373-.833.833-.833h1.557L4.255 6H2.332zM13.665 6V4.5a.833.833 0 0 0-.833-.833h-.943L11.306 6zm-3.39 0 .583-2.333H8.742L8.275 6zm-3.02 0 .467-2.333h-1.98L5.275 6z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVideoClapperboardFill.displayName = 'VideoClapperboardFill';
const Memo = memo(SvgVideoClapperboardFill);
export default Memo;
