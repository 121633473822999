// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBatteryLoadingPowerOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.47 3.435a.5.5 0 0 1 .096.701L6.01 7.5h2.992a.5.5 0 0 1 .398.803l-3.166 4.166a.5.5 0 1 1-.796-.605L7.993 8.5H5.001a.5.5 0 0 1-.398-.802L7.77 3.53a.5.5 0 0 1 .7-.096M.669 5.167c0-1.013.82-1.833 1.833-1.833h2.667a.5.5 0 0 1 0 1H2.501a.833.833 0 0 0-.833.833v5.667c0 .46.373.833.833.833h1a.5.5 0 1 1 0 1h-1a1.833 1.833 0 0 1-1.833-1.833zm9.333-1.333a.5.5 0 0 1 .5-.5h1c1.013 0 1.834.82 1.834 1.833v.167h.833c.644 0 1.167.522 1.167 1.166v3c0 .645-.523 1.167-1.167 1.167h-.833v.167c0 1.012-.821 1.833-1.834 1.833H8.835a.5.5 0 0 1 0-1H11.5c.46 0 .834-.373.834-.833V5.167a.833.833 0 0 0-.834-.833h-1a.5.5 0 0 1-.5-.5m3.334 5.833h.833a.167.167 0 0 0 .167-.167v-3a.167.167 0 0 0-.167-.166h-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBatteryLoadingPowerOutline.displayName = 'BatteryLoadingPowerOutline';
const Memo = memo(SvgBatteryLoadingPowerOutline);
export default Memo;
