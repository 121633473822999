// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSunriseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.503 1.833a.5.5 0 1 0-1 0v.687a.5.5 0 0 0 1 0zM3.996 3.29a.5.5 0 1 0-.707.706l.484.485a.5.5 0 1 0 .708-.707zM12.717 3.996a.5.5 0 1 0-.708-.707l-.485.485a.5.5 0 0 0 .708.707zM1.836 7.667a.5.5 0 0 0 0 1h.685a.5.5 0 0 0 0-1zM13.484 7.667a.5.5 0 1 0 0 1h.685a.5.5 0 0 0 0-1zM1.836 10.333a.5.5 0 1 0 0 1h12.333a.5.5 0 0 0 0-1zM4.503 13a.5.5 0 0 0 0 1h7a.5.5 0 1 0 0-1zM10.836 8.667a.5.5 0 0 0 .5-.5v-.162a3.336 3.336 0 0 0-3.333-3.338 3.336 3.336 0 0 0-3.334 3.338v.162a.5.5 0 0 0 .5.5z" />
    </Svg>
  );
};
SvgSunriseFill.displayName = 'SunriseFill';
const Memo = memo(SvgSunriseFill);
export default Memo;
