// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRoomRoomForRentRoomOnlyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.333 3.667H10v-1h2.762c.151 0 .297.093.404.26s.167.393.167.629v8.888c0 .236-.06.462-.167.629s-.253.26-.404.26H10v-1h2.333z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M3.45 13.774a.5.5 0 0 0 .292.152l5.715.735a.5.5 0 0 0 .38-.099.44.44 0 0 0 .122-.15.4.4 0 0 0 .044-.184V1.776a.4.4 0 0 0-.048-.191.45.45 0 0 0-.13-.154.5.5 0 0 0-.4-.087L3.71 2.66a.5.5 0 0 0-.267.152.43.43 0 0 0-.108.275V13.48a.43.43 0 0 0 .114.293m3.884-5.107a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRoomRoomForRentRoomOnlyFill.displayName = 'RoomRoomForRentRoomOnlyFill';
const Memo = memo(SvgRoomRoomForRentRoomOnlyFill);
export default Memo;
