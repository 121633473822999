// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDirectorChairRegieChairFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.332 7.333V2.5c0-.644-.522-1.167-1.167-1.167H3.832c-.644 0-1.167.523-1.167 1.167v4.833h-.833a.5.5 0 0 0 0 1h1.184L7.086 11l-4.195 2.748a.5.5 0 0 0 .548.837L8 11.598l4.56 2.987a.5.5 0 1 0 .547-.837L8.911 11l4.07-2.667h1.184a.5.5 0 0 0 0-1zm-2.176 1H4.841l3.158 2.07z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDirectorChairRegieChairFill.displayName = 'DirectorChairRegieChairFill';
const Memo = memo(SvgDirectorChairRegieChairFill);
export default Memo;
