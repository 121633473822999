import React, {createContext} from 'react';

import deepmerge from 'deepmerge';

import type {Theme} from '@propertyguru/hive-theme';

import {DefaultOption} from './defaults/DefaultOption';
import {Hive} from './Hive';
import {type Option, type Props, type RequiredOption, ThemeVariant} from './types';

const HiveContext = createContext<Hive>(Hive.create(DefaultOption.theme.light));

const mergeOption = (option?: Option): RequiredOption => {
  if (option) {
    return deepmerge(DefaultOption, option as RequiredOption);
  }

  return DefaultOption;
};

const resolveTheme = (variant: ThemeVariant, option: RequiredOption): Theme => {
  switch (variant) {
    case ThemeVariant.Dark:
      return option.theme.dark;
    case ThemeVariant.HighContrast:
      return option.theme.highContrast;
    default:
      return option.theme.light;
  }
};

export const createProvider = (option?: Option) => {
  const safeOption = mergeOption(option);

  const HiveProvider: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    variant = ThemeVariant.Light,
  }) => {
    return (
      <HiveContext.Provider value={Hive.create(resolveTheme(variant, safeOption), variant)}>
        {children}
      </HiveContext.Provider>
    );
  };

  HiveProvider.displayName = 'HiveProvider';

  return HiveProvider;
};

export const useHive = (): Hive => {
  /**
   * Keep below code just to note
   *
   * We have a default Theme setup
   *
   * The problem with default theme is that,
   * there might be component/screen missing to wrap the component
   *
   * likewise, testing effort for the consumer will be increase
   *
   * So we provide the default theme setup, so consumer can test their component without wrapping the Provider
   *
   * In the future, we might change this behavior
   */
  // if (!hive) {
  //   throw new Error('Missing Provider, use createProvider(), and wrap your App with it.');
  // }
  return React.useContext(HiveContext);
};
