// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNotificationAlarmBellActivityAlertOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.774 1.488a.5.5 0 0 1-.019.707A6.17 6.17 0 0 0 2.071 4.97a.5.5 0 0 1-.962-.275A7.17 7.17 0 0 1 3.067 1.47a.5.5 0 0 1 .707.019m8.455 0a.5.5 0 0 1 .707-.019 7.16 7.16 0 0 1 1.958 3.227.5.5 0 1 1-.962.275 6.17 6.17 0 0 0-1.685-2.776.5.5 0 0 1-.018-.707M2.871 5.703a5.197 5.197 0 0 1 10.261 0l.78 4.838a1.833 1.833 0 0 1-1.81 2.126h-1.106c-.566 1.166-1.674 2-2.995 2-1.32 0-2.428-.834-2.994-2H3.9a1.833 1.833 0 0 1-1.81-2.126zm3.295 6.964c.451.625 1.12 1 1.835 1 .716 0 1.384-.375 1.836-1zM8 2.333a4.2 4.2 0 0 0-4.143 3.529l-.78 4.839a.833.833 0 0 0 .822.966h8.203c.513 0 .904-.46.822-.966l-.78-4.84A4.2 4.2 0 0 0 8 2.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNotificationAlarmBellActivityAlertOutline.displayName =
  'NotificationAlarmBellActivityAlertOutline';
const Memo = memo(SvgNotificationAlarmBellActivityAlertOutline);
export default Memo;
