// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeRoofHouseOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.395 1.187a1.1 1.1 0 0 1 .546 0c.206.051.385.177.552.296l.042.03 5.434 3.82c.214.15.263.442.11.651a.48.48 0 0 1-.664.108l-.674-.474v5.095c0 .332 0 .61-.019.837-.02.236-.062.458-.171.668a1.73 1.73 0 0 1-.762.749c-.214.107-.44.148-.681.168-.231.018-.514.018-.852.018H5.08c-.338 0-.621 0-.852-.018a1.8 1.8 0 0 1-.681-.168 1.73 1.73 0 0 1-.762-.749 1.7 1.7 0 0 1-.171-.668 11 11 0 0 1-.02-.838V5.618l-.674.474a.48.48 0 0 1-.663-.108.46.46 0 0 1 .11-.651L6.8 1.513l.043-.03c.167-.119.346-.245.552-.296M3.546 4.949v5.745c0 .356 0 .595.016.78.015.178.041.264.07.32a.8.8 0 0 0 .347.34c.057.03.144.055.326.07.188.015.432.015.795.015h5.136c.363 0 .607 0 .794-.015.183-.015.27-.04.327-.07a.8.8 0 0 0 .347-.34c.029-.056.055-.142.07-.32.016-.185.016-.424.016-.78V4.949L7.982 2.272a5 5 0 0 0-.227-.154.3.3 0 0 0-.049-.026.2.2 0 0 0-.076 0 .3.3 0 0 0-.05.026 5 5 0 0 0-.226.154zm4.085-2.857H7.63m.074 0h.001"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHomeRoofHouseOutline.displayName = 'HomeRoofHouseOutline';
const Memo = memo(SvgHomeRoofHouseOutline);
export default Memo;
