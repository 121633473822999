// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWindy1WindFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.503 3a.83.83 0 0 0-.637.299.5.5 0 0 1-.767-.643 1.833 1.833 0 1 1 1.403 3.01H1.837a.5.5 0 1 1 0-1h5.667a.833.833 0 0 0 0-1.666m5.333 2.833a.83.83 0 0 0-.64.299.5.5 0 0 1-.766-.642 1.833 1.833 0 1 1 1.406 3.01h-11a.5.5 0 0 1 0-1h11a.833.833 0 1 0 0-1.667m-11.5 5a.5.5 0 0 1 .5-.5h8.333a1.833 1.833 0 1 1-1.505 2.88.5.5 0 0 1 .82-.57.832.832 0 0 0 1.519-.476.833.833 0 0 0-.834-.834H1.836a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWindy1WindFill.displayName = 'Windy1WindFill';
const Memo = memo(SvgWindy1WindFill);
export default Memo;
