// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPieChart2GraphChartStatisticsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m10.55 1.47.014.004a5.63 5.63 0 0 1 3.964 3.975c.021.078.046.17.06.254a.8.8 0 0 1-.02.366.86.86 0 0 1-.363.475.8.8 0 0 1-.36.114 3 3 0 0 1-.28.009h-3.213c-.082 0-.17 0-.246-.007a.8.8 0 0 1-.319-.084.83.83 0 0 1-.364-.364.8.8 0 0 1-.085-.319 3 3 0 0 1-.006-.246V2.433c0-.09 0-.191.009-.278a.8.8 0 0 1 .114-.36.86.86 0 0 1 .474-.364.8.8 0 0 1 .367-.02c.084.013.176.038.254.06m-.218.977v3.22h3.219a4.63 4.63 0 0 0-3.219-3.22m-3.33 1.23A5 5 0 1 0 12.321 9h-3.24c-.263 0-.49 0-.678-.015a1.5 1.5 0 0 1-.584-.148 1.5 1.5 0 0 1-.655-.656 1.5 1.5 0 0 1-.148-.583C7 7.41 7 7.183 7 6.919zm-5.67 4.99a6 6 0 0 1 6.185-5.998.5.5 0 0 1 .484.5V6.9c0 .288 0 .475.012.616a.6.6 0 0 0 .043.211.5.5 0 0 0 .218.219.6.6 0 0 0 .211.042C8.627 8 8.813 8 9.101 8h3.729a.5.5 0 0 1 .5.485l.002.182a6 6 0 1 1-12 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPieChart2GraphChartStatisticsOutline.displayName = 'PieChart2GraphChartStatisticsOutline';
const Memo = memo(SvgPieChart2GraphChartStatisticsOutline);
export default Memo;
