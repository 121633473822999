// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgOculusOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.308 5.688c.606-1.377 1.966-2.355 3.574-2.355h6.233c1.608 0 2.968.978 3.575 2.355l.119-.122a.5.5 0 1 1 .713.701l-.523.532c.182 1.24-.02 2.624-.615 3.737-.645 1.207-1.777 2.13-3.383 2.13H4.996c-1.605 0-2.737-.923-3.383-2.13C1.02 9.423.816 8.04.998 6.799l-.523-.532a.5.5 0 1 1 .714-.7zm3.574-1.355c-1.418 0-2.594 1.033-2.858 2.393-.213 1.098-.05 2.364.471 3.339.513.959 1.345 1.602 2.501 1.602h6.005c1.157 0 1.989-.643 2.5-1.602.522-.975.685-2.241.472-3.34-.264-1.36-1.44-2.392-2.858-2.392zm1.783 1.5a.5.5 0 0 1 .5-.5h1.667a.5.5 0 0 1 0 1H7.165a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgOculusOutline.displayName = 'OculusOutline';
const Memo = memo(SvgOculusOutline);
export default Memo;
