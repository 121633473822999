// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgZipRarCompressedArchiveOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m5.333 0a.5.5 0 0 1 .5-.5h.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167V3.833C2 2.821 2.82 2 3.833 2h.334a.5.5 0 0 1 0 1h-.334A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3h-.334a.5.5 0 0 1-.5-.5M6 5.167a.5.5 0 0 1 .5-.5h3a.5.5 0 1 1 0 1h-3a.5.5 0 0 1-.5-.5m0 2.666a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1.5a2 2 0 0 1-4 0zm1 .5v1a1 1 0 1 0 2 0v-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgZipRarCompressedArchiveOutline.displayName = 'ZipRarCompressedArchiveOutline';
const Memo = memo(SvgZipRarCompressedArchiveOutline);
export default Memo;
