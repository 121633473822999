// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBookGuideInfoFaqOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v9.667c0 1.012-.821 1.833-1.834 1.833h-7a1.833 1.833 0 0 1-1.833-1.833zm2 10.5a1 1 0 1 1 0-2h6.833c.3 0 .584-.072.834-.2v1.367c0 .46-.374.833-.834.833zm7.667-3.833c0 .46-.374.833-.834.833H4.668a2 2 0 0 0-1 .267V3.167c0-.46.373-.833.833-.833h7c.46 0 .834.373.834.833zm-7-5.167a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5h2.333a.5.5 0 1 1 0 1H5.835a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBookGuideInfoFaqOutline.displayName = 'BookGuideInfoFaqOutline';
const Memo = memo(SvgBookGuideInfoFaqOutline);
export default Memo;
