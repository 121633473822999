// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUndoBackDownwardFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.332 3.834a.5.5 0 0 1 .5-.5h3.667A3.167 3.167 0 0 1 14.665 6.5v.833A3.167 3.167 0 0 1 11.5 10.5H2.872l1.813 1.813a.5.5 0 1 1-.707.707l-2.195-2.195a1.167 1.167 0 0 1 0-1.65L3.978 6.98a.5.5 0 1 1 .707.707L2.872 9.5H11.5c1.196 0 2.166-.97 2.166-2.166V6.5c0-1.196-.97-2.166-2.166-2.166H7.832a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUndoBackDownwardFill.displayName = 'UndoBackDownwardFill';
const Memo = memo(SvgUndoBackDownwardFill);
export default Memo;
