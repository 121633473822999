// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShakaCallMeHangTenFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m11.6 7.628-.785.807-.003.003-.387.397a.72.72 0 0 1-1.036 0 .77.77 0 0 1 0-1.07l1.17-1.202a.72.72 0 0 1 1.036 0c.285.294.287.77.005 1.065m-3.161.578c-.025.417.117.843.427 1.162a1.444 1.444 0 0 0 2.082 0l1.17-1.202.006-.007 1.932-1.986c.292-.3.765-.3 1.057 0 .288.296.292.77.013 1.069-.958 1.02-2.635 2.78-3.91 3.987-.634.6-1.41 1.29-2.162 1.945-1.44 1.252-3.588 1.055-4.831-.42l-1.734-2.057A3.7 3.7 0 0 1 1.63 8.51l-.299-5.843h.04c.778 0 1.483.47 1.8 1.203l.942 2.179a.363.363 0 0 0 .599.114l.266-.275c.064.247.191.481.38.675a1.44 1.44 0 0 0 1.534.356c.044.31.183.608.415.846.31.32.725.466 1.131.44m.427-.974.78-.801a.77.77 0 0 0 0-1.07.72.72 0 0 0-1.036 0l-.78.801a.77.77 0 0 0 0 1.07.72.72 0 0 0 1.036 0M7.306 5.63h.002l.39-.402a.77.77 0 0 0 0-1.07.72.72 0 0 0-1.037 0l-.78.802a.77.77 0 0 0 0 1.07.72.72 0 0 0 1.037 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShakaCallMeHangTenFill.displayName = 'ShakaCallMeHangTenFill';
const Memo = memo(SvgShakaCallMeHangTenFill);
export default Memo;
