// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTreeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2.333a5 5 0 0 0-.5 9.976v-2.102L6.146 8.854a.5.5 0 1 1 .708-.708L8 9.293 9.813 7.48a.5.5 0 1 1 .707.707l-2.02 2.02v2.102A5 5 0 0 0 8 2.333m.5 10.98a6 6 0 1 0-1 0v.854a.5.5 0 1 0 1 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTreeOutline.displayName = 'TreeOutline';
const Memo = memo(SvgTreeOutline);
export default Memo;
