// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGlobusMapEarthGlobeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.352 7.5h3.322c.052-1.696.366-3.242.862-4.41.272-.64.609-1.19 1.008-1.587l.013-.013A6.67 6.67 0 0 0 1.352 7.5M1.352 8.5a6.67 6.67 0 0 0 5.205 6.01l-.013-.013c-.4-.397-.736-.947-1.008-1.587-.496-1.168-.81-2.714-.862-4.41zM9.442 14.51a6.67 6.67 0 0 0 5.206-6.01h-3.322c-.052 1.696-.366 3.242-.863 4.41-.272.64-.608 1.19-1.008 1.587zM14.648 7.5a6.67 6.67 0 0 0-5.206-6.01l.013.013c.4.397.736.947 1.008 1.587.497 1.168.81 2.714.863 4.41zM8.75 2.212c-.277-.276-.53-.379-.75-.379s-.474.103-.75.379c-.279.277-.553.703-.794 1.27-.435 1.024-.73 2.431-.781 4.018h4.65c-.052-1.587-.346-2.994-.782-4.019-.24-.566-.514-.992-.793-1.27M9.543 12.518c.436-1.024.73-2.431.782-4.018h-4.65c.051 1.587.346 2.994.781 4.018.241.567.515.993.794 1.27.276.275.53.379.75.379s.473-.104.75-.379c.278-.277.552-.703.793-1.27" />
    </Svg>
  );
};
SvgGlobusMapEarthGlobeFill.displayName = 'GlobusMapEarthGlobeFill';
const Memo = memo(SvgGlobusMapEarthGlobeFill);
export default Memo;
