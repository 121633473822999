// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSpaFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m7.563 9.479-.31.34a4.75 4.75 0 0 1-1.374-1.52 4.2 4.2 0 0 1-.543-1.916 4.1 4.1 0 0 1 .442-1.719 4.6 4.6 0 0 1 1.147-1.435A.98.98 0 0 1 7.563 3a.98.98 0 0 1 .638.23c.485.406.875.893 1.148 1.434A4.1 4.1 0 0 1 9.79 6.41 4.2 4.2 0 0 1 9.247 8.3c-.33.587-.8 1.105-1.375 1.519z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M12.005 5.533a4.86 4.86 0 0 0-2.477.684.53.53 0 0 1-.683-.116c-.153-.2-.095-.471.13-.607a5.94 5.94 0 0 1 3.09-.835l-.002.437v-.437c.245 0 .479.086.652.24a.78.78 0 0 1 .272.57 4.06 4.06 0 0 1-.332 1.731 4.5 4.5 0 0 1-1.077 1.509 5.35 5.35 0 0 1-1.887.994 5.8 5.8 0 0 1-2.18.21c-.27-.025-.466-.24-.438-.48s.27-.414.54-.389a4.7 4.7 0 0 0 1.767-.17 4.3 4.3 0 0 0 1.518-.796c.364-.344.649-.748.839-1.19a3.3 3.3 0 0 0 .268-1.355"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M3.04 4.66c1.112-.012 2.2.282 3.117.841.224.137.28.409.127.608a.53.53 0 0 1-.683.113 4.8 4.8 0 0 0-2.5-.69A3.3 3.3 0 0 0 3.37 6.89c.19.44.475.845.839 1.189.44.358.962.63 1.53.797.572.168 1.179.226 1.779.17.27-.026.512.148.54.388.03.24-.166.455-.436.48a5.9 5.9 0 0 1-2.192-.209A5.4 5.4 0 0 1 3.506 8.69a4.5 4.5 0 0 1-1.055-1.488 4.06 4.06 0 0 1-.332-1.73.78.78 0 0 1 .273-.573 1 1 0 0 1 .649-.239m.062.821v.001"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M11.397 7.544c.09-.228.37-.347.626-.267.83.258 1.555.607 2.082 1.029.526.42.895.95.895 1.558v.002q0 .264-.09.515a.49.49 0 0 1-.47.306h-2.043v1.355c0 .19-.14.36-.344.417-1.31.37-2.68.552-4.055.54-1.852 0-3.552-.31-4.808-.83C1.974 11.663 1 10.88 1 9.863c0-.498.277-.972.668-1.36.397-.392.943-.732 1.57-.97.25-.094.539.01.645.231.107.222-.01.479-.26.573-.51.194-.933.464-1.222.75-.294.292-.418.566-.418.776 0 .475.484 1.04 1.623 1.512 1.1.456 2.652.75 4.394.75h.005c1.153.01 2.3-.128 3.408-.408V10.25c0-.242.22-.437.492-.437h2.11c-.02-.262-.19-.555-.568-.858-.402-.322-1-.62-1.75-.854-.256-.08-.39-.33-.3-.557"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSpaFill.displayName = 'SpaFill';
const Memo = memo(SvgSpaFill);
export default Memo;
