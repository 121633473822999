// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBedOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.84 7.16a1.7 1.7 0 0 0-.84-.453v-3.36c0-.367-.153-.714-.413-.96A1.4 1.4 0 0 0 12.613 2H3.72c-.36 0-.713.14-.973.387-.26.253-.414.593-.414.96v3.36a1.73 1.73 0 0 0-.84.453A1.7 1.7 0 0 0 1 8.353v4.814c0 .273.227.5.5.5s.5-.227.5-.5v-1.5h12.333v1.5c0 .273.227.5.5.5.274 0 .5-.227.5-.5V8.353c0-.446-.18-.873-.493-1.193M3.333 3.347a.32.32 0 0 1 .107-.24.42.42 0 0 1 .28-.114h8.887c.113 0 .213.04.28.114a.32.32 0 0 1 .106.24v3.32h-1.666V5.7a.99.99 0 0 0-.34-.747 1.13 1.13 0 0 0-.754-.286h-4.14c-.273 0-.546.1-.753.286A1.02 1.02 0 0 0 5 5.7v.967H3.333zm7 2.353v.96H6V5.7l.007-.007c.013-.013.046-.026.086-.026h4.147c.04 0 .073.013.087.026zm4 4.96H2V8.347a.7.7 0 0 1 .2-.487c.127-.133.3-.2.487-.2h10.96a.7.7 0 0 1 .486.2c.134.127.2.3.2.487z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBedOutline.displayName = 'BedOutline';
const Memo = memo(SvgBedOutline);
export default Memo;
