// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStarAiSparkleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.499 1.833a.5.5 0 0 0-1 0c0 2.08-.46 3.464-1.331 4.336C5.296 7.041 3.912 7.5 1.832 7.5a.5.5 0 0 0 0 1c2.08 0 3.464.46 4.336 1.331.872.872 1.33 2.255 1.33 4.336a.5.5 0 1 0 1 0c0-2.08.46-3.464 1.332-4.336S12.085 8.5 14.165 8.5a.5.5 0 0 0 0-1c-2.08 0-3.463-.46-4.335-1.331-.872-.872-1.331-2.256-1.331-4.336" />
    </Svg>
  );
};
SvgStarAiSparkleFill.displayName = 'StarAiSparkleFill';
const Memo = memo(SvgStarAiSparkleFill);
export default Memo;
