// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCityViewCitySkylineHighFloorFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.938 1a1.75 1.75 0 0 0-1.53.898 1.3 1.3 0 0 0-.208-.023h-.013a1.312 1.312 0 1 0 0 2.625h1.75a1.75 1.75 0 0 0 0-3.5m3.361.299A1.02 1.02 0 0 1 9.021 1h4.958A1.02 1.02 0 0 1 15 2.02v12.25a.73.73 0 0 1-.73.73H8.73a.73.73 0 0 1-.73-.73V2.02c0-.27.108-.53.299-.721m2.326 1.889a.438.438 0 0 0-.875 0v.437a.437.437 0 1 0 .875 0zm2.625 0a.438.438 0 0 0-.875 0v.437a.438.438 0 0 0 .875 0zm-3.062 1.75c.241 0 .437.195.437.437v.438a.438.438 0 0 1-.875 0v-.438c0-.242.196-.437.438-.437m3.062.437a.438.438 0 0 0-.875 0v.438a.438.438 0 0 0 .875 0zm-3.062 1.75c.241 0 .437.196.437.438V8a.438.438 0 0 1-.875 0v-.437c0-.242.196-.438.438-.438m3.062.438a.438.438 0 0 0-.875 0V8a.438.438 0 0 0 .875 0zm-3.062 1.75c.241 0 .437.195.437.437v.438a.438.438 0 0 1-.875 0V9.75c0-.242.196-.437.438-.437m3.062.437a.438.438 0 0 0-.875 0v.438a.438.438 0 0 0 .875 0zm-3.062 1.75c.241 0 .437.196.437.438v.437a.438.438 0 0 1-.875 0v-.437c0-.242.196-.438.438-.438m3.062.438a.438.438 0 0 0-.875 0v.437a.438.438 0 0 0 .875 0zM2.02 7.125a.146.146 0 0 0-.145.146V8H6.25v-.73a.146.146 0 0 0-.146-.145zM1.3 6.549a1.02 1.02 0 0 1 .722-.299h4.083a1.02 1.02 0 0 1 1.021 1.02v7.001a.73.73 0 0 1-.73.729H1.73A.73.73 0 0 1 1 14.27V7.272c0-.27.108-.53.299-.722m2.326 4.076v-.437a.438.438 0 0 0-.875 0v.437a.438.438 0 0 0 .875 0m1.75 0v-.437a.438.438 0 0 0-.875 0v.437a.438.438 0 0 0 .875 0M2.75 12.813v-.438a.438.438 0 0 1 .875 0v.438a.438.438 0 0 1-.875 0m2.625 0v-.438a.438.438 0 0 0-.875 0v.438a.438.438 0 0 0 .875 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCityViewCitySkylineHighFloorFill.displayName = 'CityViewCitySkylineHighFloorFill';
const Memo = memo(SvgCityViewCitySkylineHighFloorFill);
export default Memo;
