// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPopsicleIceCreamSweetsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.332 4.5A3.833 3.833 0 0 1 7.165.667h1.667A3.833 3.833 0 0 1 12.665 4.5v6.333c0 .645-.522 1.167-1.166 1.167H9.665v1.667a1.667 1.667 0 1 1-3.333 0V12H4.499a1.167 1.167 0 0 1-1.167-1.167zm4 7.5v1.667a.667.667 0 1 0 1.333 0V12zm-.333-7.5a.5.5 0 0 0-1 0v4a.5.5 0 0 0 1 0zm2.5-.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPopsicleIceCreamSweetsFill.displayName = 'PopsicleIceCreamSweetsFill';
const Memo = memo(SvgPopsicleIceCreamSweetsFill);
export default Memo;
