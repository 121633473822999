// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedRealityScan3DFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.667 3.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333M13.333 3.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333M13.333 14a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333M2.667 14a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333M8.91 3.944a1.83 1.83 0 0 0-1.82 0L4.924 5.182q-.203.117-.366.275L8 7.424l3.442-1.967a1.8 1.8 0 0 0-.366-.275zM4 6.774q.001-.232.056-.452L7.5 8.29v3.938a1.8 1.8 0 0 1-.41-.172l-2.166-1.238A1.83 1.83 0 0 1 4 9.226zM8.91 12.056a1.8 1.8 0 0 1-.41.172V8.29l3.444-1.968q.056.22.056.452v2.452c0 .658-.352 1.266-.924 1.592z" />
    </Svg>
  );
};
SvgArAugmentedRealityScan3DFill.displayName = 'ArAugmentedRealityScan3DFill';
const Memo = memo(SvgArAugmentedRealityScan3DFill);
export default Memo;
