// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgConstructionCapOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.544 10.266h-.454V9.4c0-1.128-.39-2.24-1.097-3.14A5.45 5.45 0 0 0 9.47 4.496v-.728c0-.238-.2-.435-.454-.435H6.306a.445.445 0 0 0-.454.435v.733A5.45 5.45 0 0 0 3.33 6.266a5.1 5.1 0 0 0-1.097 3.135v.865H1.79a.445.445 0 0 0-.454.434c0 .243.2.435.454.435h2.073l.77.739c.085.08.2.126.322.126h5.429a.47.47 0 0 0 .321-.127l.77-.738h2.074a.445.445 0 0 0 .454-.435c0-.242-.2-.434-.454-.434zm-6.78-6.068h1.81v3.034a.444.444 0 0 1-.454.435h-.902a.48.48 0 0 1-.322-.127.43.43 0 0 1-.131-.308zm4.522 6.068a.47.47 0 0 0-.322.126l-.77.738H5.14l-.77-.738a.47.47 0 0 0-.322-.126h-.903V9.4c0-.935.322-1.866.913-2.614a4.5 4.5 0 0 1 1.8-1.35v1.795c0 .349.142.672.395.92.258.248.596.38.96.38h.902c.359 0 .707-.137.96-.38a1.27 1.27 0 0 0 .396-.92V5.437a4.5 4.5 0 0 1 1.799 1.35 4.23 4.23 0 0 1 .912 2.614v.865h-.902z" />
    </Svg>
  );
};
SvgConstructionCapOff.displayName = 'ConstructionCapOff';
const Memo = memo(SvgConstructionCapOff);
export default Memo;
