// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowTopRightFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.329 3.833a.5.5 0 0 1 .5-.5h5.003c1.013 0 1.833.821 1.833 1.834v5.664a.5.5 0 1 1-1 0V5.167q0-.06-.008-.118L4.686 12.02a.5.5 0 1 1-.708-.707l6.972-6.971a1 1 0 0 0-.118-.009H5.829a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowTopRightFill.displayName = 'ArrowTopRightFill';
const Memo = memo(SvgArrowTopRightFill);
export default Memo;
