// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgScissorsCutOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.444 3.917a1.528 1.528 0 1 0 0 3.055 1.528 1.528 0 0 0 0-3.055M2 5.444a2.444 2.444 0 1 1 4.122 1.779l1.378.57v-.638c0-.664.391-1.266.998-1.535l2.704-1.202a1.68 1.68 0 0 1 1.759.244l1.25 1.041a.458.458 0 0 1-.12.776L9.209 8.5l4.884 2.02a.46.46 0 0 1 .118.777l-1.25 1.04a1.68 1.68 0 0 1-1.758.245L8.498 11.38A1.68 1.68 0 0 1 7.5 9.845v-.638l-1.378.57a2.444 2.444 0 1 1-1.01-.574L6.81 8.5l-1.7-.703A2.446 2.446 0 0 1 2 5.444m6.417 3.72 4.624 1.914-.667.555a.76.76 0 0 1-.8.112L8.87 10.543a.76.76 0 0 1-.453-.698zm0-1.329v-.68c0-.301.177-.575.453-.698l2.705-1.202a.76.76 0 0 1 .799.112l.666.555zm-3.973 2.193a1.528 1.528 0 1 0 0 3.055 1.528 1.528 0 0 0 0-3.055"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgScissorsCutOutline.displayName = 'ScissorsCutOutline';
const Memo = memo(SvgScissorsCutOutline);
export default Memo;
