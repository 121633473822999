// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgProcessorChipFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.331 8a1.667 1.667 0 1 1 3.334 0A1.667 1.667 0 0 1 6.33 8" />
      <Path
        fillRule="evenodd"
        d="M7.999 1.33a.5.5 0 0 1 .5.5v.837h1.666v-.834a.5.5 0 1 1 1 0v.834h.334c1.012 0 1.833.82 1.833 1.833v.333h.833a.5.5 0 0 1 0 1h-.833V7.5h.833a.5.5 0 0 1 0 1h-.833v1.667h.833a.5.5 0 0 1 0 1h-.833v.333c0 1.013-.82 1.833-1.833 1.833h-.334v.834a.5.5 0 1 1-1 0v-.834H8.5v.834a.5.5 0 1 1-1 0v-.834H5.832v.834a.5.5 0 1 1-1 0v-.834h-.333A1.833 1.833 0 0 1 2.665 11.5v-.333h-.833a.5.5 0 1 1 0-1h.833V8.5h-.833a.5.5 0 0 1 0-1h.833V5.833h-.833a.5.5 0 0 1 0-1h.833V4.5c0-1.012.821-1.833 1.834-1.833h.333V1.83a.5.5 0 0 1 1 0v.836h1.667V1.83a.5.5 0 0 1 .5-.5m-.001 4.003a2.667 2.667 0 1 0 0 5.333 2.667 2.667 0 0 0 0-5.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgProcessorChipFill.displayName = 'ProcessorChipFill';
const Memo = memo(SvgProcessorChipFill);
export default Memo;
