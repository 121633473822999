// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudOffOfflineOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.648 1.646a.5.5 0 0 1 .707 0l12 12a.5.5 0 0 1-.707.708l-1.06-1.06a3 3 0 0 1-.503.04H4.668a4 4 0 0 1-.813-7.918q.125-.295.288-.567L1.648 2.354a.5.5 0 0 1 0-.708m3.23 3.938a3.5 3.5 0 0 0-.186.44.5.5 0 0 1-.41.334 3 3 0 0 0 .386 5.975h6.96zm3.123-1.917c-.515 0-1.004.11-1.443.31a.5.5 0 0 1-.413-.91 4.5 4.5 0 0 1 6.346 3.792 3.25 3.25 0 0 1 2.329 4.98.5.5 0 1 1-.841-.54 2.25 2.25 0 0 0-1.965-3.464.5.5 0 0 1-.516-.521l.003-.147a3.5 3.5 0 0 0-3.5-3.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudOffOfflineOutline.displayName = 'CloudOffOfflineOutline';
const Memo = memo(SvgCloudOffOfflineOutline);
export default Memo;
