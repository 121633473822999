// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCodeBracketsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.631 2.018a.5.5 0 0 1 .351.614l-3 11a.5.5 0 0 1-.965-.264l3-11a.5.5 0 0 1 .614-.35M4.176 4.803a.5.5 0 0 1 .021.706L2.391 7.43a.833.833 0 0 0 0 1.142l1.806 1.92a.5.5 0 1 1-.728.685l-1.807-1.92a1.833 1.833 0 0 1 0-2.512l1.807-1.92a.5.5 0 0 1 .707-.021m7.648 0a.5.5 0 0 1 .707.021l1.806 1.92a1.833 1.833 0 0 1 0 2.513l-1.806 1.919a.5.5 0 1 1-.729-.685l1.807-1.92a.833.833 0 0 0 0-1.142l-1.807-1.92a.5.5 0 0 1 .022-.706"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCodeBracketsFill.displayName = 'CodeBracketsFill';
const Memo = memo(SvgCodeBracketsFill);
export default Memo;
