// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChillerPlantOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.276 2.332A.37.37 0 0 1 11.652 2h1.605c.196 0 .36.145.376.332l.521 6.11q.134.063.24.165a.88.88 0 0 1 .275.634v3.73a.37.37 0 0 1-.377.362H1.713a.37.37 0 0 1-.377-.361V9.24c0-.238.1-.466.275-.634a1 1 0 0 1 .245-.168l.521-6.107A.37.37 0 0 1 2.753 2h1.605c.197 0 .36.145.376.332L5.11 6.74a.367.367 0 0 1-.345.39.373.373 0 0 1-.407-.33L4.01 2.722h-.909l-.48 5.623h2.185l2.647-1.835a.97.97 0 0 1 1.1 0l2.646 1.835h2.19l-.48-5.623h-.91l-.347 4.075c-.017.2-.2.348-.407.331a.367.367 0 0 1-.345-.39zM7.895 7.097 5.149 9a.4.4 0 0 1-.221.068H2.272a.2.2 0 0 0-.129.051.17.17 0 0 0-.052.12v3.37h11.824V9.24a.17.17 0 0 0-.053-.12.2.2 0 0 0-.13-.05h-2.655a.4.4 0 0 1-.22-.07L8.11 7.098a.19.19 0 0 0-.215 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChillerPlantOutline.displayName = 'ChillerPlantOutline';
const Memo = memo(SvgChillerPlantOutline);
export default Memo;
