import {type TextStyle} from 'react-native';

import {type ColorValue, type ElevationValue, type Theme} from '@propertyguru/hive-theme';

import {BaseTheme} from './defaults/Themes';
import {findProperty} from './findProperty';
import {
  type BorderRadius,
  type Breakpoint,
  type Color,
  type Elevation,
  type Spacing,
  ThemeVariant,
  type Typography,
} from './types';

export class Hive {
  private constructor(private readonly theme: Theme, private readonly variant: ThemeVariant) {}

  themeVariant = (): ThemeVariant => {
    return this.variant;
  };

  color = (color: Color | undefined): ColorValue | undefined => {
    return color && findProperty(this.theme.colors, color);
  };

  elevation = (elevation: Elevation | undefined): ElevationValue | undefined => {
    return elevation && findProperty(this.theme.elevation, elevation);
  };

  borderRadius = (borderRadius: BorderRadius | undefined): number | undefined => {
    return borderRadius && findProperty(this.theme.shape.borderRadius, borderRadius);
  };

  breakpoint = (breakpoint: Breakpoint | undefined): number | undefined => {
    return breakpoint && findProperty(this.theme.dimension.breakpoint, breakpoint);
  };

  spacing = (spacing: Spacing): number => {
    return findProperty(this.theme.dimension.spacing, spacing);
  };

  typography = (typography: Typography): TextStyle => {
    return findProperty(this.theme.typography, typography);
  };

  static create = (theme: Theme = BaseTheme, variant: ThemeVariant = ThemeVariant.Light) => {
    return new Hive(theme, variant);
  };
}
