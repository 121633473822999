// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSchoolFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.393 3.788h1.841a.67.67 0 0 0 .67-.67V2.004a.67.67 0 0 0-.67-.67H8.002a.39.39 0 0 0-.39.39v2.979a2.865 2.865 0 0 0-2.51 2.8v.944H2.563a1.227 1.227 0 0 0-1.227 1.228v4.602c0 .216.175.39.39.39H14.28a.39.39 0 0 0 .39-.39V9.674a1.227 1.227 0 0 0-1.227-1.228h-2.539v-.944a2.865 2.865 0 0 0-2.51-2.8zM8.01 5.462h-.016A2.084 2.084 0 0 0 5.883 7.51v6.375h.89V12.08l.001-.028a1.18 1.18 0 0 1 1.227-1.096 1.18 1.18 0 0 1 1.227 1.124v1.806h.895V7.51a2.084 2.084 0 0 0-2.113-2.05m-.856 1.283a1.209 1.209 0 1 1 1.71 1.71 1.209 1.209 0 0 1-1.71-1.71"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSchoolFill.displayName = 'SchoolFill';
const Memo = memo(SvgSchoolFill);
export default Memo;
