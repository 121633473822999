// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVisionProGogglesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.79 4.018c1.546-.602 3.508-.685 5.21-.685s3.664.083 5.21.685c.782.305 1.486.753 1.994 1.415C15.717 6.102 16 6.95 16 8c0 1.093-.273 2.243-.9 3.136-.643.911-1.643 1.53-3.01 1.53-.674 0-1.204-.148-1.643-.385-.43-.232-.75-.538-1.014-.806l-.109-.111c-.484-.496-.762-.78-1.324-.78s-.84.284-1.324.78l-.109.111c-.263.268-.583.574-1.014.806-.44.237-.969.386-1.644.386-1.362 0-2.363-.605-3.007-1.513C.272 10.266 0 9.114 0 8c0-1.05.283-1.898.796-2.567.508-.662 1.212-1.11 1.994-1.415m-1.2 2.024C1.227 6.512 1 7.14 1 8c0 .97.24 1.901.718 2.575.464.655 1.167 1.092 2.191 1.092.518 0 .883-.112 1.169-.266.293-.158.527-.373.775-.626l.13-.134C6.429 10.175 6.997 9.583 8 9.583s1.57.592 2.018 1.058l.129.134c.248.252.482.468.775.626.286.154.65.266 1.169.266 1.02 0 1.724-.445 2.19-1.107.48-.682.719-1.615.719-2.56 0-.86-.228-1.487-.59-1.958-.365-.477-.896-.832-1.563-1.092-1.352-.526-3.14-.617-4.847-.617s-3.495.09-4.847.617c-.667.26-1.198.615-1.564 1.092"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVisionProGogglesOutline.displayName = 'VisionProGogglesOutline';
const Memo = memo(SvgVisionProGogglesOutline);
export default Memo;
