// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicEyesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.789 7.165C13.106 4.213 10.587 2.667 8 2.667S2.896 4.213 1.213 7.165a1.69 1.69 0 0 0 0 1.67c1.683 2.952 4.202 4.498 6.788 4.498s5.105-1.546 6.788-4.498a1.69 1.69 0 0 0 0-1.67M7.702 5.93 7.16 7.012a.33.33 0 0 1-.15.149L5.93 7.7a.333.333 0 0 0 0 .597l1.082.541a.33.33 0 0 1 .149.15l.54 1.081a.333.333 0 0 0 .597 0l.541-1.082a.33.33 0 0 1 .15-.149l1.081-.54a.333.333 0 0 0 0-.597L8.988 7.16a.33.33 0 0 1-.149-.15L8.3 5.93a.333.333 0 0 0-.597 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMagicEyesFill.displayName = 'MagicEyesFill';
const Memo = memo(SvgMagicEyesFill);
export default Memo;
