// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCookerFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2.001c-.953 0-2 .707-2 1.727v.273h4v-.273c0-1.02-1.047-1.727-2-1.727M5 3.728v.273H2.6c-.331 0-.6.224-.6.5s.269.5.6.5H4v1H2.5a.5.5 0 1 0 0 1H4v3.88c0 .297.123.582.342.792s.515.328.825.328H8c-.17 0-.338.035-.488.103a1 1 0 0 0-.392.311l-.654.994a.04.04 0 0 1-.016.012.1.1 0 0 1-.022.006h-.02a.07.07 0 0 1-.044-.011.9.9 0 0 0-.36-.279c-.158-.07-.335-.1-.511-.09a1.1 1.1 0 0 0-.44.11.9.9 0 0 0-.33.282l-.666 1.004c-.12.18-.044.409.17.51s.485.037.605-.144l.662-.996a.04.04 0 0 1 .014-.012.1.1 0 0 1 .02-.005l.027-.001q.019 0 .036.006.016.008.026.021.015.023.033.043a.94.94 0 0 0 .366.243c.141.053.296.076.45.068.154-.006.305-.044.438-.11a.9.9 0 0 0 .33-.28l.005-.008.646-.98a.14.14 0 0 1 .045-.033.17.17 0 0 1 .185.032l.646.981.005.008c.08.115.194.211.33.28.133.066.284.104.438.11.154.008.308-.015.45-.068a.94.94 0 0 0 .366-.243.4.4 0 0 0 .033-.043.1.1 0 0 1 .026-.02.1.1 0 0 1 .036-.007l.027.001q.01 0 .02.005a.04.04 0 0 1 .014.012l.662.996c.12.18.39.245.605.144s.29-.33.17-.51l-.663-1-.003-.004a.9.9 0 0 0-.33-.282 1.1 1.1 0 0 0-.44-.11c-.176-.01-.353.02-.51.09a.9.9 0 0 0-.36.279.07.07 0 0 1-.044.012l-.02-.001a.1.1 0 0 1-.023-.006.1.1 0 0 1-.016-.012l-.654-.994-.02-.027a1 1 0 0 0-.372-.284A1.2 1.2 0 0 0 8 12h2.833c.31 0 .607-.118.825-.328.22-.21.342-.495.342-.792v-3.88h1.5a.5.5 0 0 0 0-1H12v-1h1.4c.331 0 .6-.224.6-.5s-.269-.5-.6-.5H11v-.273c0-.723-.316-1.417-.879-1.928A3.16 3.16 0 0 0 8 1c-.796 0-1.559.287-2.121.799C5.316 2.31 5 3.005 5 3.728"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCookerFill.displayName = 'CookerFill';
const Memo = memo(SvgCookerFill);
export default Memo;
