// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTicketAdmitVipOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v1.736a.5.5 0 0 1-.364.48 1.334 1.334 0 0 0 0 2.567.5.5 0 0 1 .364.481V11.5c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5V9.764a.5.5 0 0 1 .364-.48 1.334 1.334 0 0 0 0-2.567.5.5 0 0 1-.364-.481zm1.833-.833a.833.833 0 0 0-.833.833v1.391a2.333 2.333 0 0 1 0 4.218V11.5c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833V10.11a2.333 2.333 0 0 1 0-4.218v-1.39a.833.833 0 0 0-.833-.834z"
        clipRule="evenodd"
      />
      <Path d="M9.665 5.167a.5.5 0 0 1 1 0V5.5a.5.5 0 0 1-1 0zM9.665 7.833a.5.5 0 0 1 1 0v.334a.5.5 0 0 1-1 0zM9.665 10.5a.5.5 0 1 1 1 0v.333a.5.5 0 1 1-1 0z" />
    </Svg>
  );
};
SvgTicketAdmitVipOutline.displayName = 'TicketAdmitVipOutline';
const Memo = memo(SvgTicketAdmitVipOutline);
export default Memo;
