// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBadgeVerifiedAwardOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.077 1.754a1.224 1.224 0 0 1 1.847 0l.913 1.051c.051.059.128.087.204.075l1.375-.219a1.224 1.224 0 0 1 1.415 1.188l.024 1.392a.22.22 0 0 0 .109.188l1.194.717c.637.382.789 1.241.32 1.819l-.876 1.082a.22.22 0 0 0-.038.213l.454 1.316a1.224 1.224 0 0 1-.924 1.6l-1.367.266a.22.22 0 0 0-.166.14l-.498 1.299a1.224 1.224 0 0 1-1.736.632l-1.218-.675a.22.22 0 0 0-.217 0l-1.217.675c-.65.36-1.47.063-1.736-.632l-.499-1.3a.22.22 0 0 0-.166-.14l-1.367-.265a1.224 1.224 0 0 1-.923-1.6l.453-1.316a.22.22 0 0 0-.038-.213l-.876-1.082a1.224 1.224 0 0 1 .32-1.82l1.194-.716a.22.22 0 0 0 .109-.188l.024-1.392a1.224 1.224 0 0 1 1.415-1.188l1.375.219a.22.22 0 0 0 .204-.075zm1.092.656a.224.224 0 0 0-.337 0l-.913 1.051a1.22 1.22 0 0 1-1.116.406L4.428 3.65a.224.224 0 0 0-.258.217l-.024 1.392c-.008.422-.232.81-.594 1.028l-1.193.717a.224.224 0 0 0-.059.332l.876 1.082c.266.328.344.77.207 1.17l-.454 1.315a.224.224 0 0 0 .169.293l1.366.265c.415.08.759.369.91.763l.498 1.3a.224.224 0 0 0 .318.115l1.217-.675c.37-.205.818-.205 1.187 0l1.218.675a.224.224 0 0 0 .317-.115l.498-1.3c.152-.394.495-.683.91-.763l1.366-.265a.224.224 0 0 0 .17-.293l-.454-1.316c-.138-.399-.06-.84.206-1.169l.876-1.082a.224.224 0 0 0-.058-.332l-1.194-.717a1.22 1.22 0 0 1-.593-1.028l-.024-1.392a.224.224 0 0 0-.259-.217l-1.375.218a1.22 1.22 0 0 1-1.115-.406zm1.852 4.403a.5.5 0 0 1 0 .707L7.688 9.854a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647 1.98-1.98a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBadgeVerifiedAwardOutline.displayName = 'BadgeVerifiedAwardOutline';
const Memo = memo(SvgBadgeVerifiedAwardOutline);
export default Memo;
