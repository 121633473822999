// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierCircleVectorNodesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.999 2.5c0-.644.522-1.167 1.166-1.167h1.667c.644 0 1.167.523 1.167 1.167v.735A5.18 5.18 0 0 1 12.764 6h.735c.644 0 1.166.522 1.166 1.167v1.666c0 .645-.522 1.167-1.166 1.167h-.735a5.18 5.18 0 0 1-2.765 2.765v.735c0 .644-.523 1.167-1.167 1.167H7.165A1.167 1.167 0 0 1 6 13.5v-.735A5.18 5.18 0 0 1 3.234 10h-.735a1.167 1.167 0 0 1-1.167-1.167V7.167C1.332 6.522 1.854 6 2.499 6h.735a5.18 5.18 0 0 1 2.765-2.765zm.012 1.837a4.2 4.2 0 0 0-1.675 1.675c.563.083.996.568.996 1.155v1.666c0 .587-.433 1.072-.996 1.155.384.707.968 1.29 1.675 1.675a1.17 1.17 0 0 1 1.154-.996h1.667c.587 0 1.072.433 1.154.996a4.2 4.2 0 0 0 1.676-1.675 1.17 1.17 0 0 1-.997-1.155V7.167c0-.587.433-1.072.997-1.155a4.2 4.2 0 0 0-1.676-1.675 1.17 1.17 0 0 1-1.154.996H7.165a1.17 1.17 0 0 1-1.154-.996m1.154-2.004A.167.167 0 0 0 7 2.5v1.667c0 .092.074.166.166.166h1.667a.167.167 0 0 0 .167-.166V2.5a.167.167 0 0 0-.167-.167zM2.5 7a.167.167 0 0 0-.167.167v1.666c0 .092.075.167.167.167h1.666a.167.167 0 0 0 .167-.167V7.167A.167.167 0 0 0 4.165 7zm9.333 0a.167.167 0 0 0-.167.167v1.666c0 .092.075.167.167.167h1.667a.167.167 0 0 0 .166-.167V7.167A.167.167 0 0 0 13.5 7zm-4.667 4.667a.167.167 0 0 0-.166.166V13.5c0 .092.074.167.166.167h1.667a.167.167 0 0 0 .167-.167v-1.667a.167.167 0 0 0-.167-.166z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBezierCircleVectorNodesOutline.displayName = 'BezierCircleVectorNodesOutline';
const Memo = memo(SvgBezierCircleVectorNodesOutline);
export default Memo;
