// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTwitterXColour: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.837 2h2.044L9.415 7.105l5.254 6.947h-4.114L7.332 9.84l-3.687 4.213H1.599l4.778-5.46L1.336 2h4.219l2.913 3.851zm-.718 10.828h1.133L4.94 3.16H3.723z" />
    </Svg>
  );
};
SvgTwitterXColour.displayName = 'TwitterXColour';
const Memo = memo(SvgTwitterXColour);
export default Memo;
