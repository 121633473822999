// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgColonialHouseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.824 2.549a2.5 2.5 0 0 0-.583-.814 2.7 2.7 0 0 0-.867-.543A2.8 2.8 0 0 0 11.354 1H4.677a2.7 2.7 0 0 0-1.88.735c-.506.476-.79 1.112-.79 1.76-.042.57.159 1.12.561 1.55.402.423.95.681 1.554.714h.125q.07-.001.152-.013v8.83c0 .232.194.417.437.417a.426.426 0 0 0 .437-.417v-7.52h1.096v7.527c0 .232.194.417.437.417a.426.426 0 0 0 .437-.417V7.057h1.096v7.526c0 .232.194.417.437.417a.426.426 0 0 0 .436-.417V7.057h1.096v7.52c0 .231.194.416.437.416a.426.426 0 0 0 .437-.417v-8.91q.344.108.708.087.448-.032.846-.212c.27-.126.506-.291.7-.503s.347-.45.444-.722c.097-.264.132-.542.118-.794 0-.331-.07-.655-.201-.966zM5.279 6.216v-.675h5.056v.675zm7.803-2.171a1.2 1.2 0 0 1-.27.437 1.3 1.3 0 0 1-.43.304c-.167.073-.34.12-.521.133a1.1 1.1 0 0 1-.395-.047 1.014 1.014 0 0 1-.583-.483.9.9 0 0 1-.097-.357.63.63 0 0 1 .18-.49.76.76 0 0 1 .5-.232c.062 0 .131 0 .194.027q.093.032.166.092a.46.46 0 0 0 .624-.04.413.413 0 0 0-.041-.595 1.426 1.426 0 0 0-.992-.318 1.66 1.66 0 0 0-1.103.51 1.5 1.5 0 0 0-.402 1.099c.014.211.076.417.166.615H5.973c.09-.192.152-.397.166-.615a1.46 1.46 0 0 0-.402-1.1 1.63 1.63 0 0 0-1.103-.509c-.354-.02-.7.086-.971.305a.407.407 0 0 0-.055.589c.159.178.43.198.617.053a.51.51 0 0 1 .353-.113.73.73 0 0 1 .5.232.67.67 0 0 1 .18.49.8.8 0 0 1-.104.357.9.9 0 0 1-.243.291 1 1 0 0 1-.346.186c-.132.04-.264.06-.396.046a1.42 1.42 0 0 1-.95-.437 1.27 1.27 0 0 1-.34-.966c0-.444.188-.867.52-1.185a1.88 1.88 0 0 1 1.27-.497h6.693c.235 0 .464.047.686.126.215.086.416.205.583.364.166.16.298.338.388.543s.132.417.139.669c.014.172-.014.337-.07.496z" />
    </Svg>
  );
};
SvgColonialHouseFill.displayName = 'ColonialHouseFill';
const Memo = memo(SvgColonialHouseFill);
export default Memo;
