// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLightningZapFlashFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.22 1.333c-.418 0-.804.223-1.012.585L2.141 7.252A1.167 1.167 0 0 0 3.153 9h1.962c.105 0 .184.097.163.2l-.823 4.026c-.232 1.133 1.15 1.876 1.968 1.058l7.293-7.292C14.45 6.257 13.93 5 12.89 5h-1.645a.167.167 0 0 1-.15-.241l.87-1.737a1.167 1.167 0 0 0-1.044-1.689z" />
    </Svg>
  );
};
SvgLightningZapFlashFill.displayName = 'LightningZapFlashFill';
const Memo = memo(SvgLightningZapFlashFill);
export default Memo;
