// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRocketStartupLaunchFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m10.333 9.55-2.667 2.117v1.043c0 .127.136.207.247.147l1.986-1.083a.83.83 0 0 0 .434-.732zm-3.667 2.083-2.3-2.3H3.29a1.167 1.167 0 0 1-1.024-1.725l1.083-1.986a1.83 1.83 0 0 1 1.61-.955h2.31c1.74-1.851 3.681-3.108 6.193-3.306a1.093 1.093 0 0 1 1.178 1.178c-.199 2.511-1.455 4.454-3.306 6.193v2.31c0 .67-.366 1.288-.955 1.61l-1.986 1.083a1.167 1.167 0 0 1-1.726-1.025zM6.45 5.667H4.958a.83.83 0 0 0-.732.434L3.143 8.087c-.06.111.02.246.146.246h1.043z"
        clipRule="evenodd"
      />
      <Path d="M1.332 12.796a1.87 1.87 0 1 1 1.87 1.87h-1.37a.5.5 0 0 1-.5-.5z" />
    </Svg>
  );
};
SvgRocketStartupLaunchFill.displayName = 'RocketStartupLaunchFill';
const Memo = memo(SvgRocketStartupLaunchFill);
export default Memo;
