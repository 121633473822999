// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStoreShopBusinessFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.091 2.714C3.438 2.264 3.975 2 4.543 2h6.911c.569 0 1.105.264 1.452.714l1.655 2.149a.5.5 0 0 1 .104.305v.667c0 .66-.262 1.26-.685 1.704v4.627c0 1.013-.82 1.834-1.833 1.834H3.85a1.834 1.834 0 0 1-1.834-1.834V7.539a2.47 2.47 0 0 1-.685-1.704v-.667a.5.5 0 0 1 .104-.305zm-.074 5.473v3.979c0 .46.373.834.834.834h2.277v-1.501c0-1.026.85-1.834 1.87-1.834s1.871.808 1.871 1.834v1.5h2.278c.46 0 .833-.373.833-.833v-3.98a2.6 2.6 0 0 1-2.926-.864A2.58 2.58 0 0 1 8 8.336a2.58 2.58 0 0 1-2.056-1.014 2.601 2.601 0 0 1-2.926.865"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStoreShopBusinessFill.displayName = 'StoreShopBusinessFill';
const Memo = memo(SvgStoreShopBusinessFill);
export default Memo;
