// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleCopyMembersOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834V4h.833c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.834-1.833 1.834h-7a1.833 1.833 0 0 1-1.833-1.834V12h-.834a1.833 1.833 0 0 1-1.833-1.833zm3.667 9.666c0 .46.371.832.83.834C6.308 12.133 7.68 11 9.332 11s3.024 1.133 3.503 2.667a.833.833 0 0 0 .83-.834v-7A.833.833 0 0 0 12.832 5h-7a.833.833 0 0 0-.833.833zm6-8.833H5.832c-1.012 0-1.833.82-1.833 1.833V11h-.834a.833.833 0 0 1-.833-.833v-7c0-.46.373-.834.833-.834h7c.46 0 .834.373.834.834zm.771 9.667C11.34 12.672 10.398 12 9.332 12s-2.008.672-2.438 1.667zM9.332 7.333a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPeopleCopyMembersOutline.displayName = 'PeopleCopyMembersOutline';
const Memo = memo(SvgPeopleCopyMembersOutline);
export default Memo;
