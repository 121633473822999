// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRaisingHand5FingerHeyHelloFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.705 1.333a.74.74 0 0 0-.743.741V6.89a.371.371 0 0 1-.744 0V2.815c0-.41-.333-.74-.744-.74a.74.74 0 0 0-.743.74V7.63a.371.371 0 0 1-.744 0V4.296c0-.409-.333-.74-.743-.74a.74.74 0 0 0-.744.74v5.556c0 2.659 2.164 4.815 4.833 4.815s4.834-2.156 4.834-4.815V5.407a1.854 1.854 0 0 0-1.86 1.852v.969a.58.58 0 0 1-.366.54 2.365 2.365 0 0 0-1.492 2.195.371.371 0 0 1-.744 0c0-1.234.732-2.346 1.86-2.841v-.863c0-.881.44-1.66 1.115-2.128V2.815c0-.41-.333-.74-.744-.74a.74.74 0 0 0-.744.74v4.074a.371.371 0 0 1-.743 0V2.074c0-.409-.333-.74-.744-.74"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRaisingHand5FingerHeyHelloFill.displayName = 'RaisingHand5FingerHeyHelloFill';
const Memo = memo(SvgRaisingHand5FingerHeyHelloFill);
export default Memo;
