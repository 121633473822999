// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleBehindUserAvatarGroup3Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.666 4.667a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0M8.999 5a2.333 2.333 0 1 1 4.667 0 2.333 2.333 0 0 1-4.667 0M5.332 8c-1.176 0-2.157.423-2.91 1.147-.744.716-1.245 1.704-1.52 2.817C.621 13.092 1.566 14 2.6 14h5.465c1.033 0 1.979-.908 1.699-2.036-.276-1.113-.777-2.101-1.521-2.817C7.49 8.423 6.509 8 5.333 8M9.449 8.477c.806.891 1.322 2.007 1.609 3.166a2.87 2.87 0 0 1-.069 1.69h2.574c1.025 0 1.997-.91 1.673-2.05C14.706 9.427 13.403 8 11.332 8c-.72 0-1.349.173-1.883.477" />
    </Svg>
  );
};
SvgPeopleBehindUserAvatarGroup3Fill.displayName = 'PeopleBehindUserAvatarGroup3Fill';
const Memo = memo(SvgPeopleBehindUserAvatarGroup3Fill);
export default Memo;
