// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCursorBoxArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v3.334a.5.5 0 0 1-1 0V3.833A.833.833 0 0 0 12.167 3H3.833A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h3.334a.5.5 0 0 1 0 1H3.833A1.833 1.833 0 0 1 2 12.167zm6.54 4.708 1.297 4.833 1.186-2.048a.83.83 0 0 1 .303-.303l2.048-1.186zM7.5 8.52A.833.833 0 0 1 8.52 7.5l5.5 1.475a.833.833 0 0 1 .202 1.526l-2.356 1.364-1.364 2.356a.833.833 0 0 1-1.526-.201z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCursorBoxArrowOutline.displayName = 'CursorBoxArrowOutline';
const Memo = memo(SvgCursorBoxArrowOutline);
export default Memo;
