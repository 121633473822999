// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPiggyBankSaveMoneyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.605 2.093c-.742-.167-1.27.46-1.27 1.073v1.49c0 .041-.018.098-.071.154-.29.311-.54.661-.738 1.043-.055.107-.142.147-.201.147h-.49C1.19 6 .668 6.522.668 7.167v1.666C.668 9.477 1.19 10 1.835 10h.49c.059 0 .146.04.201.147.198.381.447.732.738 1.042.053.056.07.113.07.154v1.49c0 .644.523 1.167 1.167 1.167h1.667c.644 0 1.167-.523 1.167-1.167 0-.092.074-.167.166-.167h1c.092 0 .167.075.167.167 0 .644.522 1.167 1.167 1.167H11.5c.645 0 1.167-.523 1.167-1.167V11.35c0-.041.018-.098.071-.154a4.7 4.7 0 0 0 .57-.742 1.833 1.833 0 0 0 2.29-2.766.5.5 0 0 0-.78.626.833.833 0 0 1-1.078 1.236 4.667 4.667 0 0 0-4.403-6.216H6.76a4.2 4.2 0 0 0-2.156-1.24M5.5 8a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPiggyBankSaveMoneyFill.displayName = 'PiggyBankSaveMoneyFill';
const Memo = memo(SvgPiggyBankSaveMoneyFill);
export default Memo;
