// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRotateRotationXAxisOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.999 2a.5.5 0 0 1 .5.5v5.667a.5.5 0 0 1-1 0V2.5a.5.5 0 0 1 .5-.5M7.999 11.667a.5.5 0 0 1 .5.5V13.5a.5.5 0 0 1-1 0v-1.333a.5.5 0 0 1 .5-.5" />
      <Path d="M9.837 5.923a.5.5 0 0 1 .566-.424c1.157.166 2.174.453 2.917.834.7.36 1.345.906 1.345 1.667 0 .507-.293.92-.665 1.228-.374.311-.886.569-1.475.776-1.182.415-2.782.663-4.526.663s-3.345-.248-4.527-.663c-.588-.207-1.1-.465-1.475-.776-.371-.309-.665-.721-.665-1.228 0-.686.529-1.197 1.122-1.545.57-.334 1.335-.6 2.211-.79v-.487c0-.282.33-.437.547-.256l1.146.955c.16.134.16.38 0 .512l-1.146.955a.333.333 0 0 1-.547-.256V6.69c-.715.17-1.295.388-1.705.628-.518.304-.628.554-.628.682 0 .091.052.249.304.459.25.207.64.416 1.168.601 1.05.37 2.533.607 4.195.607s3.145-.238 4.194-.607c.528-.185.919-.394 1.168-.601.253-.21.304-.367.304-.459 0-.146-.147-.44-.801-.777-.611-.314-1.51-.577-2.603-.734a.5.5 0 0 1-.424-.566" />
    </Svg>
  );
};
SvgRotateRotationXAxisOutline.displayName = 'RotateRotationXAxisOutline';
const Memo = memo(SvgRotateRotationXAxisOutline);
export default Memo;
