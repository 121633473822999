// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCelebratePartyConfettiOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.275.674a.5.5 0 0 1 .577.408l-.493.085.493-.085v.005l.002.008.005.03.014.102c.01.086.023.206.031.35.017.28.018.668-.053 1.052-.082.44-.289.844-.458 1.12a5 5 0 0 1-.327.469l-.007.008-.002.003-.001.001-.384-.321.384.321a.5.5 0 0 1-.767-.642l.002-.003.014-.016.054-.072c.047-.064.112-.156.18-.268.142-.232.279-.514.329-.782.05-.274.052-.572.038-.812a5 5 0 0 0-.036-.363l-.003-.018v-.003a.5.5 0 0 1 .408-.577m3.516 1.05a.5.5 0 0 1 .3.64l-.308.858a.5.5 0 0 1-.941-.34l.309-.857a.5.5 0 0 1 .64-.3m1.823 2.99a.5.5 0 0 1-.224.67l-.687.344a.5.5 0 1 1-.446-.895l.686-.343a.5.5 0 0 1 .671.224m-3.184-.13a.5.5 0 0 1 0 .707l-.687.686a.5.5 0 0 1-.707-.708l.687-.685a.5.5 0 0 1 .707 0m-7.403 1.04a1.836 1.836 0 0 1 3.006-.629l3.98 3.973a1.835 1.835 0 0 1-.628 3.008L3.84 14.537c-1.49.584-2.963-.888-2.378-2.38zm2.3.079a.836.836 0 0 0-1.369.286l-2.564 6.534a.835.835 0 0 0 1.083 1.083l6.543-2.562a.835.835 0 0 0 .286-1.368zm5.28 1.613a.5.5 0 0 1 .52-.478l-.02.5.02-.5h.011l.022.001a4 4 0 0 1 .336.034c.21.028.5.08.793.173.274.087.563.23.77.34a8 8 0 0 1 .34.193l.022.013.006.004.002.001-.262.426.263-.425a.5.5 0 0 1-.527.85m-1.818-.611h.014l.056.004a4 4 0 0 1 .83.158c.186.06.412.167.604.27a7 7 0 0 1 .294.166l.016.01.004.003m-2.296-1.132a.5.5 0 0 0 .478.52z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCelebratePartyConfettiOutline.displayName = 'CelebratePartyConfettiOutline';
const Memo = memo(SvgCelebratePartyConfettiOutline);
export default Memo;
