// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgServerDataStorageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v3h11.333v-3a.833.833 0 0 0-.833-.833zm10.5 4.833H2.332v3c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833z"
        clipRule="evenodd"
      />
      <Path d="M4.667 5.583a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0M4.667 10.417a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0" />
      <Path
        fillRule="evenodd"
        d="M5.001 5.583a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m0 4.834a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m-.666-4.5a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667m0 4.833a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgServerDataStorageOutline.displayName = 'ServerDataStorageOutline';
const Memo = memo(SvgServerDataStorageOutline);
export default Memo;
