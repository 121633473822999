// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSignaturePencilPencilPenWriteDrawOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.036 2.423a1.833 1.833 0 0 1 2.592 0l.281.28-.353.354.353-.353a1.833 1.833 0 0 1 0 2.592l-8.166 8.167c-.344.344-.81.537-1.297.537H1.832a.5.5 0 0 1-.5-.5v-1.614c0-.487.193-.953.537-1.297zm1.885.707a.833.833 0 0 0-1.178 0l-8.167 8.166a.83.83 0 0 0-.244.59V13h1.114a.83.83 0 0 0 .59-.244l8.166-8.167a.833.833 0 0 0 0-1.178l.283-.283-.283.283zm1.7 8.543a.5.5 0 0 1 .756.654L14 12l.378.327-.002.002-.003.004-.011.012a3 3 0 0 1-.168.174c-.11.108-.27.252-.466.397-.377.28-.957.614-1.618.614-.627 0-1.176-.292-1.598-.516l-.036-.02c-.474-.25-.808-.417-1.152-.417-.777 0-1.223.327-1.794.933a.5.5 0 0 1-.727-.687c.641-.68 1.334-1.246 2.521-1.246.615 0 1.157.288 1.575.51q.023.013.046.024c.47.25.811.419 1.165.419.34 0 .705-.182 1.022-.417a3.6 3.6 0 0 0 .485-.435z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSignaturePencilPencilPenWriteDrawOutline.displayName =
  'SignaturePencilPencilPenWriteDrawOutline';
const Memo = memo(SvgSignaturePencilPencilPenWriteDrawOutline);
export default Memo;
