// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClockCircleTimeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.003 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334m.5-9.5a.5.5 0 1 0-1 0V8a.5.5 0 0 0 .146.354l1.833 1.833a.5.5 0 1 0 .708-.707L8.503 7.793z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClockCircleTimeFill.displayName = 'ClockCircleTimeFill';
const Memo = memo(SvgClockCircleTimeFill);
export default Memo;
