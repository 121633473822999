import type {ColorValue, ColorVariant} from './types';

export const Palette = {
  gray: {
    0: '#FFFFFF',
    10: '#EFF1F5',
    20: '#C1C9D2',
    30: '#A7B0B8',
    40: '#737D87',
    50: '#5A6067',
    60: '#3E4246',
    70: '#282C2E',
    80: '#1C1F22',
    90: '#0D1011',
    100: '#040506',
  },
  red: {
    0: '#FFFFFF',
    10: '#FFF0F0',
    20: '#FFB2B2',
    30: '#FF8080',
    40: '#FF4242',
    50: '#FF0F0F',
    60: '#C20000',
    70: '#940000',
    80: '#660000',
    90: '#330000',
    100: '#1A0000',
  },
  teal: {
    0: '#FFFFFF',
    10: '#E5FFFF',
    20: '#85FDFF',
    30: '#29FBFF',
    40: '#00C4C7',
    50: '#009194',
    60: '#005F61',
    70: '#004142',
    80: '#002D2E',
    90: '#001414',
    100: '#000A0A',
  },
  purple: {
    0: '#FFFFFF',
    10: '#F8F0FF',
    20: '#E4C4FF',
    30: '#CD8FFF',
    40: '#B357FF',
    50: '#9C24FF',
    60: '#7E00E5',
    70: '#5A00A3',
    80: '#3E0070',
    90: '#1F0038',
    100: '#11001F',
  },
  orange: {
    0: '#FFFFFF',
    10: '#FFEEE5',
    20: '#FFB694',
    30: '#FF9361',
    40: '#FF5405',
    50: '#CC4100',
    60: '#942F00',
    70: '#611F00',
    80: '#421500',
    90: '#1F0A00',
    100: '#0A0300',
  },
  blue: {
    0: '#FFFFFF',
    10: '#E5ECFF',
    20: '#94B2FF',
    30: '#5786FF',
    40: '#336DFF',
    50: '#1F5DFF',
    60: '#002585',
    70: '#001A5C',
    80: '#00113D',
    90: '#00071A',
    100: '#00030A',
  },
  green: {
    0: '#FFFFFF',
    10: '#E5FFF4',
    20: '#94FFCF',
    30: '#57FFB3',
    40: '#05FF8F',
    50: '#00CC70',
    60: '#008549',
    70: '#005730',
    80: '#003D22',
    90: '#001A0E',
    100: '#000A06',
  },
  gold: {
    0: '#FFFFFF',
    10: '#FFF6E5',
    20: '#FFD894',
    30: '#FFC157',
    40: '#FFA305',
    50: '#CC8100',
    60: '#8F5A00',
    70: '#573700',
    80: '#3D2700',
    90: '#1A1000',
    100: '#0A0600',
  },
  white: {
    100: 'rgba(255,255,255,1)',
  },
  black: {
    100: 'rgba(0,0,0,1)',
  },
  transparent: {
    black: {
      10: 'rgba(0,0,0,0.07)',
      20: 'rgba(0,0,0,0.17)',
      30: 'rgba(0,0,0,0.40)',
      40: 'rgba(0,0,0,0.60)',
    },
    white: {
      10: 'rgba(255,255,255,0.60)',
      20: 'rgba(255,255,255,0.50)',
      30: 'rgba(255,255,255,0.15)',
      40: 'rgba(255,255,255,0.07)',
    },
  },
} satisfies {
  gray: Readonly<Record<ColorVariant, ColorValue>>;
  red: Readonly<Record<ColorVariant, ColorValue>>;
  teal: Readonly<Record<ColorVariant, ColorValue>>;
  purple: Readonly<Record<ColorVariant, ColorValue>>;
  orange: Readonly<Record<ColorVariant, ColorValue>>;
  blue: Readonly<Record<ColorVariant, ColorValue>>;
  green: Readonly<Record<ColorVariant, ColorValue>>;
  gold: Readonly<Record<ColorVariant, ColorValue>>;
  white: Readonly<Record<Extract<ColorVariant, 100>, ColorValue>>;
  black: Readonly<Record<Extract<ColorVariant, 100>, ColorValue>>;
  transparent: {
    black: Readonly<Record<Extract<ColorVariant, 10 | 20 | 30 | 40>, ColorValue>>;
    white: Readonly<Record<Extract<ColorVariant, 10 | 20 | 30 | 40>, ColorValue>>;
  };
};
