// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAttachmentAttachPaperClipOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.842 2.044a2.43 2.43 0 0 1 1.722-.711A2.433 2.433 0 0 1 14 3.763c0 .622-.238 1.245-.714 1.72l-6.845 6.824a1.47 1.47 0 0 1-2.075 0 1.463 1.463 0 0 1 0-2.073L7.79 6.822a.5.5 0 0 1 .706.708l-3.422 3.412a.463.463 0 0 0 0 .657.47.47 0 0 0 .662 0l6.845-6.825a1.421 1.421 0 0 0 0-2.022c-.28-.28-.647-.419-1.016-.419s-.735.14-1.016.42L3.704 9.576A2.38 2.38 0 0 0 3 11.271a2.4 2.4 0 0 0 2.404 2.395 2.4 2.4 0 0 0 1.7-.702l3.423-3.412a.5.5 0 1 1 .706.708L7.81 13.672a3.4 3.4 0 0 1-2.406.994A3.4 3.4 0 0 1 2 11.271c0-.869.333-1.739.997-2.402z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAttachmentAttachPaperClipOutline.displayName = 'AttachmentAttachPaperClipOutline';
const Memo = memo(SvgAttachmentAttachPaperClipOutline);
export default Memo;
