// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFinderFilesOsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3H8.929a30 30 0 0 0-.894 5.333h.798a.5.5 0 1 1 0 1H7.5a.5.5 0 0 1-.5-.529c.116-1.985.418-3.901.897-5.804zM2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zm3.167 1.5a.5.5 0 0 1 .5.5v1a.5.5 0 1 1-1 0v-1a.5.5 0 0 1 .5-.5m5.666 0a.5.5 0 0 1 .5.5v1a.5.5 0 1 1-1 0v-1a.5.5 0 0 1 .5-.5M4.756 10.05a.5.5 0 0 1 .695-.127c1.053.729 1.825 1.036 2.549 1.036s1.496-.307 2.549-1.036a.5.5 0 1 1 .569.822c-1.114.772-2.092 1.214-3.118 1.214s-2.004-.442-3.118-1.214a.5.5 0 0 1-.126-.695"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFinderFilesOsOutline.displayName = 'FinderFilesOsOutline';
const Memo = memo(SvgFinderFilesOsOutline);
export default Memo;
