// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRaisingHand4FingerHeyHelloOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.168 2.333a.833.833 0 0 0-.833.834V6.5a.5.5 0 0 1-1 0v-2a.833.833 0 0 0-1.667 0V9a4.667 4.667 0 0 0 9.333 0V6a.167.167 0 0 0-.166-.167 1.5 1.5 0 0 0-1.5 1.5c0 .59-.31 1.136-.815 1.439l-.237.142A1.27 1.27 0 0 0 9.668 10a.5.5 0 1 1-1 0c0-.796.418-1.534 1.1-1.943l.238-.143a.68.68 0 0 0 .329-.58c0-.454.12-.881.333-1.249V3.833a.833.833 0 0 0-1.667 0v2a.5.5 0 1 1-1 0V3.167a.833.833 0 0 0-.833-.834m1.628-.01a1.833 1.833 0 0 0-3.439.555A1.833 1.833 0 0 0 2.667 4.5V9a5.667 5.667 0 0 0 11.334 0V6c0-.644-.522-1.167-1.166-1.167-.422 0-.819.105-1.167.289V3.833a1.833 1.833 0 0 0-2.872-1.51"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRaisingHand4FingerHeyHelloOutline.displayName = 'RaisingHand4FingerHeyHelloOutline';
const Memo = memo(SvgRaisingHand4FingerHeyHelloOutline);
export default Memo;
