// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudOff2OfflineOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.735 1.724a.5.5 0 0 1 .209.676l-6.334 12a.5.5 0 1 1-.884-.467l.34-.645a4.001 4.001 0 0 1-.211-7.872A4.5 4.5 0 0 1 9.53 2.934l.528-1a.5.5 0 0 1 .676-.21M9.059 3.83a3.502 3.502 0 0 0-4.367 2.196.5.5 0 0 1-.41.333 3 3 0 0 0 .289 5.974zm1.97.676a.5.5 0 0 1 .695.132c.436.642.711 1.402.767 2.221a3.25 3.25 0 0 1-.406 6.475H7.133a.5.5 0 1 1 0-1h4.952a2.25 2.25 0 1 0-.072-4.499.5.5 0 0 1-.515-.52l.003-.147c0-.73-.223-1.407-.604-1.967a.5.5 0 0 1 .132-.695"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudOff2OfflineOutline.displayName = 'CloudOff2OfflineOutline';
const Memo = memo(SvgCloudOff2OfflineOutline);
export default Memo;
