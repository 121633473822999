// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSunDownFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.003 1.333a.5.5 0 0 1 .5.5V4.46l.813-.814a.5.5 0 1 1 .707.708L8.356 6.02a.5.5 0 0 1-.707 0L5.982 4.354a.5.5 0 1 1 .707-.708l.814.814V1.833a.5.5 0 0 1 .5-.5M1.339 13.5a.5.5 0 0 1 .5-.5h12.333a.5.5 0 0 1 0 1H1.839a.5.5 0 0 1-.5-.5M1.836 10.333a.5.5 0 1 0 0 1h1a.5.5 0 0 0 0-1zM13.17 10.333a.5.5 0 1 0 0 1h1a.5.5 0 0 0 0-1zM11.58 7.667a.5.5 0 0 1 .06-.704l.767-.643a.5.5 0 1 1 .643.766l-.766.643a.5.5 0 0 1-.705-.062M3.6 6.32a.5.5 0 0 0-.644.766l.766.643a.5.5 0 0 0 .643-.766zM10.836 11.333a.5.5 0 0 0 .5-.5v-.166a3.333 3.333 0 1 0-6.667 0v.166a.5.5 0 0 0 .5.5z" />
    </Svg>
  );
};
SvgSunDownFill.displayName = 'SunDownFill';
const Memo = memo(SvgSunDownFill);
export default Memo;
