// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChecklistBoxCheckListNotificationFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 2C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h4.5v-2.667A3.667 3.667 0 0 1 14 8.26V3.833C14 2.821 13.18 2 12.167 2zm3.493 2.768a.5.5 0 0 1 .1.7L6.18 7.132a.5.5 0 0 1-.678.116l-.75-.5a.5.5 0 1 1 .555-.832l.356.237.964-1.285a.5.5 0 0 1 .7-.1M8.706 6a.5.5 0 0 1 .5-.5h1.666a.5.5 0 0 1 0 1H9.205a.5.5 0 0 1-.5-.5m-1.38 2.769a.5.5 0 0 1 .1.7L6.18 11.133a.5.5 0 0 1-.678.116l-.75-.5a.5.5 0 1 1 .555-.832l.356.237.964-1.285a.5.5 0 0 1 .7-.1"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M9.333 11.333a2.667 2.667 0 0 1 5.334 0V13.5a.5.5 0 0 1-.5.5H9.833a.5.5 0 0 1-.5-.5zM12 9.667c-.92 0-1.667.746-1.667 1.666V13h3.334v-1.667c0-.92-.746-1.666-1.667-1.666"
        clipRule="evenodd"
      />
      <Path d="M12 14.667c.736 0 1.333-.597 1.333-1.334h-2.666c0 .737.597 1.334 1.333 1.334" />
    </Svg>
  );
};
SvgChecklistBoxCheckListNotificationFill.displayName = 'ChecklistBoxCheckListNotificationFill';
const Memo = memo(SvgChecklistBoxCheckListNotificationFill);
export default Memo;
