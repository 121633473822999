// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPlusLinesAddDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v6.057a1.5 1.5 0 0 0-2.667.943v.5h-.5a1.5 1.5 0 1 0 0 3h.5v.5q.001.264.085.5H4.501a1.833 1.833 0 0 1-1.833-1.833zM5.335 4.5a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5H7.5a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <Path d="M12.668 10.167a.5.5 0 0 0-1 0v1.5h-1.5a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 1 0 1 0v-1.5h1.5a.5.5 0 1 0 0-1h-1.5z" />
    </Svg>
  );
};
SvgPlusLinesAddDocumentListPageFileFill.displayName = 'PlusLinesAddDocumentListPageFileFill';
const Memo = memo(SvgPlusLinesAddDocumentListPageFileFill);
export default Memo;
