// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloseQuoteBlockquoteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.336 4.5c0-1.012.82-1.833 1.833-1.833h2.334c1.012 0 1.833.82 1.833 1.832v4.192c0 1.714-.742 2.873-1.485 3.595a5.3 5.3 0 0 1-1.354.95l-.132.057-.01.004-.002.002H4.35l-.182-.466.181.466a.5.5 0 0 1-.68-.466V9.191h-.5a1.833 1.833 0 0 1-1.834-1.834zm3.333 7.474c.153-.11.32-.245.485-.406.59-.573 1.182-1.485 1.182-2.877V4.499a.833.833 0 0 0-.833-.832H3.169a.833.833 0 0 0-.833.833v2.857c0 .46.373.834.833.834h1a.5.5 0 0 1 .5.5zm4-7.474c0-1.012.821-1.833 1.834-1.833h2.333c1.012 0 1.833.82 1.833 1.832v4.192c0 1.714-.742 2.873-1.485 3.595a5.3 5.3 0 0 1-1.353.95l-.132.057-.01.004-.003.002h-.001l-.182-.466.18.466a.5.5 0 0 1-.68-.466V9.191h-.5a1.833 1.833 0 0 1-1.834-1.834zm3.334 7.474c.153-.11.319-.245.485-.406.59-.573 1.181-1.485 1.181-2.877V4.499a.833.833 0 0 0-.833-.832h-2.333a.833.833 0 0 0-.834.833v2.857c0 .46.373.834.834.834h1a.5.5 0 0 1 .5.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloseQuoteBlockquoteOutline.displayName = 'CloseQuoteBlockquoteOutline';
const Memo = memo(SvgCloseQuoteBlockquoteOutline);
export default Memo;
