// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierCurveBezierVectorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3 4.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0M15 4.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
      <Path
        stroke="#0D1011"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 4.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0M15 4.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
      />
      <Path
        fillRule="evenodd"
        d="M6.001 3.833c0-.644.523-1.166 1.167-1.166h1.667c.644 0 1.166.522 1.166 1.166v.334h4a.5.5 0 0 1 0 1h-2.5a5.83 5.83 0 0 1 2.313 4.166h.354c.644 0 1.167.523 1.167 1.167v1.667c0 .644-.523 1.166-1.167 1.166h-1.667a1.167 1.167 0 0 1-1.166-1.166V10.5c0-.644.522-1.167 1.166-1.167h.308a4.84 4.84 0 0 0-2.808-3.901V5.5c0 .644-.522 1.167-1.166 1.167H7.168A1.167 1.167 0 0 1 6.001 5.5v-.068a4.84 4.84 0 0 0-2.807 3.901H3.5c.645 0 1.167.523 1.167 1.167v1.667c0 .644-.522 1.166-1.167 1.166H1.835a1.167 1.167 0 0 1-1.167-1.166V10.5c0-.644.522-1.167 1.167-1.167h.354a5.83 5.83 0 0 1 2.312-4.166H2a.5.5 0 0 1 0-1h4zm1 0c0-.092.075-.166.167-.166h1.667c.092 0 .166.074.166.166V5.5a.167.167 0 0 1-.166.167H7.168a.167.167 0 0 1-.167-.167zm-5.166 6.5a.167.167 0 0 0-.167.167v1.667c0 .092.075.166.167.166H3.5a.167.167 0 0 0 .167-.166V10.5a.167.167 0 0 0-.167-.167zm10.666 0a.167.167 0 0 0-.166.167v1.667c0 .092.074.166.166.166h1.667a.167.167 0 0 0 .167-.166V10.5a.167.167 0 0 0-.167-.167z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBezierCurveBezierVectorOutline.displayName = 'BezierCurveBezierVectorOutline';
const Memo = memo(SvgBezierCurveBezierVectorOutline);
export default Memo;
