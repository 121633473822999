// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPercentSalesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a6.667 6.667 0 1 0 0 13.334 6.667 6.667 0 0 0 0-13.334M5.332 6a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0m4 4a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0m1.687-4.313a.5.5 0 1 0-.707-.707l-5.334 5.333a.5.5 0 0 0 .708.707z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPercentSalesFill.displayName = 'PercentSalesFill';
const Memo = memo(SvgPercentSalesFill);
export default Memo;
