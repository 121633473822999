// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPagesBoardsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 1.333c-1.012 0-1.833.821-1.833 1.834v7c0 1.012.82 1.833 1.833 1.833h.834v.833c0 1.013.82 1.834 1.833 1.834h4.333c1.013 0 1.834-.821 1.834-1.834v-7C13.335 4.821 12.514 4 11.5 4h-.833v-.833c0-1.013-.82-1.834-1.833-1.834zM7.168 4h2.5v-.833a.833.833 0 0 0-.833-.834H4.5a.833.833 0 0 0-.833.834v7c0 .46.373.833.833.833h.834V5.833C5.335 4.821 6.155 4 7.168 4"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPagesBoardsFill.displayName = 'PagesBoardsFill';
const Memo = memo(SvgPagesBoardsFill);
export default Memo;
