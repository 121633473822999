// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStar2MagicSparkleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.49 1.74a.5.5 0 0 0-.982 0c-.32 1.676-.95 2.951-1.884 3.886-.934.934-2.21 1.564-3.886 1.883a.5.5 0 0 0 0 .982c1.676.32 2.952.95 3.886 1.883.934.934 1.564 2.21 1.884 3.886a.5.5 0 0 0 .982 0c.32-1.676.949-2.951 1.883-3.886.934-.934 2.21-1.564 3.886-1.883a.5.5 0 0 0 0-.982c-1.676-.32-2.952-.95-3.886-1.883C9.44 4.69 8.81 3.416 8.49 1.74" />
    </Svg>
  );
};
SvgStar2MagicSparkleFill.displayName = 'Star2MagicSparkleFill';
const Memo = memo(SvgStar2MagicSparkleFill);
export default Memo;
