// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBurgerHamburgerSandwichOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.658 3.938c-.948.656-1.66 1.726-1.66 3.458v.27h10v-.27c0-1.732-.71-2.802-1.659-3.458C10.365 3.263 9.1 3 8 3c-1.101 0-2.366.263-3.34.938M13.998 8v-.604c0-2.06-.871-3.437-2.09-4.28C10.716 2.29 9.231 2 7.998 2c-1.232 0-2.717.29-3.909 1.115C2.871 3.96 2 5.337 2 7.395V8a1.664 1.664 0 0 0 0 2.667v.733c0 .35 0 .642.019.881.02.25.063.483.174.705.177.355.465.643.82.82.222.112.456.155.706.175.238.019.53.019.88.019h6.8c.35 0 .643 0 .882-.02.249-.02.483-.062.705-.173.355-.178.643-.466.82-.82.111-.223.155-.457.175-.706.019-.239.019-.53.019-.881v-.733a1.664 1.664 0 0 0 0-2.667m-1 .667h-10a.667.667 0 1 0 0 1.333h10a.667.667 0 1 0 0-1.333m0 2.333v.38c0 .375 0 .627-.015.821-.015.188-.042.279-.072.338a.83.83 0 0 1-.373.373c-.06.03-.15.057-.338.072-.194.016-.446.016-.821.016h-6.76c-.375 0-.627 0-.821-.016-.189-.015-.279-.042-.338-.072a.83.83 0 0 1-.373-.373c-.03-.06-.057-.15-.072-.338a11 11 0 0 1-.016-.821V11z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBurgerHamburgerSandwichOutline.displayName = 'BurgerHamburgerSandwichOutline';
const Memo = memo(SvgBurgerHamburgerSandwichOutline);
export default Memo;
