// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTruckDeliveryOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.401 4a1.834 1.834 0 0 1 1.764-1.333h5.667c1.013 0 1.833.82 1.833 1.833v5.07c0 .827-.568 1.52-1.336 1.711a2.167 2.167 0 0 1-4.324.052H6.992a2.167 2.167 0 0 1-4.324-.062 1.81 1.81 0 0 1-1.336-1.747v-2.44c0-.374.114-.738.326-1.044l.865-1.25C2.866 4.295 3.43 4 4.031 4zm-.069 1H4.031a.83.83 0 0 0-.686.36L2.48 6.608a.83.83 0 0 0-.148.474v2.44a.81.81 0 0 0 .522.758 2.17 2.17 0 0 1 2.478-1.223zm1 4.603c.213.205.385.453.5.73h2.333a2.167 2.167 0 0 1 3.984-.04.76.76 0 0 0 .516-.722V4.5a.833.833 0 0 0-.833-.834H7.165a.833.833 0 0 0-.833.833zm-1.5.397a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333m6.333 0a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTruckDeliveryOutline.displayName = 'TruckDeliveryOutline';
const Memo = memo(SvgTruckDeliveryOutline);
export default Memo;
