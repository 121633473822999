// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPhoneDynamicIslandFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.165 15.333A1.833 1.833 0 0 1 3.332 13.5v-11c0-1.013.82-1.833 1.833-1.833h5.667c1.013 0 1.833.82 1.833 1.833v11c0 1.012-.82 1.833-1.833 1.833zm2-13.006a.5.5 0 1 0 0 1h1.667a.5.5 0 0 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPhoneDynamicIslandFill.displayName = 'PhoneDynamicIslandFill';
const Memo = memo(SvgPhoneDynamicIslandFill);
export default Memo;
