// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgResizeSmallBoxArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill={color}
      viewBox="0 0 24 24"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.92 3h10.16c.535 0 .98 0 1.345.03.38.03.736.098 1.073.27A2.75 2.75 0 0 1 20.7 4.502c.172.337.24.693.27 1.073.03.365.03.81.03 1.345v2.33a.75.75 0 0 1-1.5 0v-2.3c0-.572 0-.957-.025-1.253-.023-.287-.065-.424-.111-.514a1.25 1.25 0 0 0-.547-.547c-.09-.046-.227-.088-.515-.111-.295-.024-.68-.025-1.252-.025H6.95c-.572 0-.957 0-1.253.025-.287.023-.424.065-.514.111a1.25 1.25 0 0 0-.547.547c-.046.09-.088.227-.111.514-.024.296-.025.68-.025 1.253v10.1c0 .572 0 .957.025 1.252.023.288.065.425.111.515.12.236.311.427.547.547.09.046.227.088.514.111.296.024.68.025 1.253.025h2.3a.75.75 0 0 1 0 1.5H6.92c-.535 0-.98 0-1.345-.03-.38-.03-.736-.098-1.073-.27A2.75 2.75 0 0 1 3.3 19.5c-.172-.338-.24-.694-.27-1.074C3 18.06 3 17.615 3 17.08V6.92c0-.535 0-.98.03-1.345.03-.38.098-.736.27-1.073A2.75 2.75 0 0 1 4.502 3.3c.337-.172.693-.24 1.073-.27C5.94 3 6.385 3 6.92 3m3.55 7.47a.75.75 0 0 1 1.06 0l7.97 7.97v-4.69a.75.75 0 0 1 1.5 0v6.5a.75.75 0 0 1-.75.75h-6.5a.75.75 0 0 1 0-1.5h4.69l-7.97-7.97a.75.75 0 0 1 0-1.06"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgResizeSmallBoxArrowFill.displayName = 'ResizeSmallBoxArrowFill';
const Memo = memo(SvgResizeSmallBoxArrowFill);
export default Memo;
