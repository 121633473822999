// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgExplosionBoomBangPopOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.334a.5.5 0 0 1 .444.27l.9 1.746a.83.83 0 0 0 .994.412l1.87-.599a.5.5 0 0 1 .628.629l-.598 1.87a.83.83 0 0 0 .412.995l1.745.899a.5.5 0 0 1 0 .889l-1.566.807a.833.833 0 0 0-.36 1.12l1.493 2.918a.5.5 0 0 1-.673.673l-2.917-1.494a.833.833 0 0 0-1.12.36l-.808 1.567a.5.5 0 0 1-.889 0l-.899-1.745a.83.83 0 0 0-.995-.412l-1.87.598a.5.5 0 0 1-.628-.629l.598-1.87a.83.83 0 0 0-.412-.994l-1.745-.9a.5.5 0 0 1 0-.888l1.566-.807a.833.833 0 0 0 .36-1.12L2.036 2.71a.5.5 0 0 1 .673-.672L5.627 3.53c.409.21.91.048 1.12-.36l.807-1.566A.5.5 0 0 1 8 1.333m0 1.591-.363.704a1.833 1.833 0 0 1-2.465.792l-1.54-.788.789 1.54c.46.9.106 2.002-.793 2.465L2.924 8l.882.455c.796.41 1.18 1.335.907 2.188l-.303.946.946-.303a1.83 1.83 0 0 1 2.188.907l.455.882.362-.703a1.833 1.833 0 0 1 2.465-.793l1.54.788-.788-1.54c-.46-.899-.106-2.002.792-2.464L13.074 8l-.883-.454a1.83 1.83 0 0 1-.906-2.189l.302-.945-.945.302a1.83 1.83 0 0 1-2.189-.906z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgExplosionBoomBangPopOutline.displayName = 'ExplosionBoomBangPopOutline';
const Memo = memo(SvgExplosionBoomBangPopOutline);
export default Memo;
