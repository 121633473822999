// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUfoBeamOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.674 4.306c-1.04.205-1.948.506-2.631.887C1.3 5.606.668 6.205.668 7c0 .789.624 1.386 1.356 1.798.607.34 1.394.619 2.293.821l-2.24 3.618a.5.5 0 1 0 .85.527L6.75 7.589a.71.71 0 0 1 .501-.347 5.9 5.9 0 0 1 1.498 0 .71.71 0 0 1 .502.347l3.824 6.175a.5.5 0 0 0 .85-.527l-2.24-3.618c.899-.202 1.685-.48 2.292-.821.733-.412 1.357-1.009 1.357-1.798 0-.795-.633-1.394-1.375-1.807-.683-.381-1.592-.682-2.632-.887-.105-1.683-1.581-2.972-3.327-2.972s-3.22 1.289-3.327 2.972M5.7 4.141a18 18 0 0 1 2.301-.14c.804 0 1.578.049 2.301.14-.182-1.007-1.12-1.807-2.3-1.807-1.181 0-2.12.8-2.302 1.807m5.425 4.574c.986-.193 1.796-.47 2.363-.789.66-.372.847-.71.847-.926s-.19-.56-.862-.934c-.639-.356-1.58-.659-2.73-.85A17 17 0 0 0 8 5c-.98 0-1.91.078-2.741.216-1.15.191-2.091.494-2.73.85-.673.374-.862.717-.862.934 0 .216.186.554.847.926.567.319 1.377.596 2.362.789l1.024-1.652c.259-.419.692-.746 1.225-.813a6.9 6.9 0 0 1 1.75 0c.533.067.967.394 1.226.813z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUfoBeamOutline.displayName = 'UfoBeamOutline';
const Memo = memo(SvgUfoBeamOutline);
export default Memo;
