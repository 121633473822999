// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCornerUpRightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.813 2.98a.5.5 0 0 1 .707 0l2.196 2.195a1.167 1.167 0 0 1 0 1.65L11.52 9.02a.5.5 0 0 1-.707-.707L12.626 6.5h-7.46C3.97 6.5 3 7.47 3 8.667v4.167a.5.5 0 0 1-1 0V8.667A3.167 3.167 0 0 1 5.167 5.5h7.46l-1.814-1.813a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCornerUpRightOutline.displayName = 'CornerUpRightOutline';
const Memo = memo(SvgCornerUpRightOutline);
export default Memo;
