// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble4AnnotationMessageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 0 0-5.139 8.06l-.453.211.453-.211c.112.239.151.519.084.795l-.54 2.22c-.028.12.079.23.2.202l2.313-.52c.265-.06.531-.022.76.08l-.205.457.205-.456A5.667 5.667 0 1 0 7.999 2.333M1.332 8a6.667 6.667 0 1 1 3.935 6.083.2.2 0 0 0-.13-.018l-2.314.52a1.167 1.167 0 0 1-1.39-1.413l.54-2.22.485.118-.485-.118a.2.2 0 0 0-.019-.136l.45-.21-.45.21A6.64 6.64 0 0 1 1.332 8"
        clipRule="evenodd"
      />
      <Path d="M5.001 8.167a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M8.001 8.167a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M11.001 8.167a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667" />
      <Path
        fillRule="evenodd"
        d="M4.999 8.5a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m3 0a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m3 0a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m-6-.333a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667m3 0a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667m2.666-.334a.333.333 0 1 0 .667 0 .333.333 0 0 0-.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble4AnnotationMessageOutline.displayName = 'Bubble4AnnotationMessageOutline';
const Memo = memo(SvgBubble4AnnotationMessageOutline);
export default Memo;
