// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBasketballNbaFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.504 1.352a6.64 6.64 0 0 0-4 1.729A6.65 6.65 0 0 1 5.652 7.5h1.852zM4.649 7.5a5.65 5.65 0 0 0-1.83-3.69A6.64 6.64 0 0 0 1.354 7.5zM1.355 8.5H4.65a5.65 5.65 0 0 1-1.83 3.69A6.64 6.64 0 0 1 1.354 8.5M5.652 8.5h1.852v6.148a6.64 6.64 0 0 1-4-1.73A6.65 6.65 0 0 0 5.652 8.5M8.504 14.648a6.64 6.64 0 0 0 4-1.73A6.65 6.65 0 0 1 10.355 8.5H8.505zM11.359 8.5a5.65 5.65 0 0 0 1.83 3.69 6.64 6.64 0 0 0 1.463-3.69zM14.652 7.5h-3.293a5.65 5.65 0 0 1 1.83-3.69 6.64 6.64 0 0 1 1.463 3.69M10.356 7.5H8.503V1.352c1.537.114 2.929.749 4 1.729A6.65 6.65 0 0 0 10.355 7.5" />
    </Svg>
  );
};
SvgBasketballNbaFill.displayName = 'BasketballNbaFill';
const Memo = memo(SvgBasketballNbaFill);
export default Memo;
