// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVideoClapperboardOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3.667a.833.833 0 0 0-.833.833V6h1.923l.467-2.333zm2.577 0L5.275 6h1.98l.467-2.333zm3 0L8.275 6h2l.583-2.333zm3.147 0L11.306 6h2.36V4.5a.833.833 0 0 0-.834-.833zM13.665 7H2.332v4.5c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833zM1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVideoClapperboardOutline.displayName = 'VideoClapperboardOutline';
const Memo = memo(SvgVideoClapperboardOutline);
export default Memo;
