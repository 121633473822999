// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBagLuggageBuggageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.334 3a.5.5 0 0 0-.5.5v1.167h4.333V3.5a.5.5 0 0 0-.5-.5zm4.833 1.667V3.5a1.5 1.5 0 0 0-1.5-1.5H6.334a1.5 1.5 0 0 0-1.5 1.5v1.167H3.165c-1.012 0-1.833.82-1.833 1.833v5.667c0 1.012.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833V6.5c0-1.013-.82-1.833-1.833-1.833zm-8.002 1a.833.833 0 0 0-.833.833V8h11.333V6.5a.833.833 0 0 0-.833-.833zM13.665 9H8.5l.001 1.166a.5.5 0 0 1-1 .001V9H2.331v3.167c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBagLuggageBuggageOutline.displayName = 'BagLuggageBuggageOutline';
const Memo = memo(SvgBagLuggageBuggageOutline);
export default Memo;
