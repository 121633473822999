// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Circle} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBulletPointOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Circle
        cx={7.999}
        cy={8}
        r={3.917}
        stroke="#0D1011"
        strokeWidth={1.5}
      />
    </Svg>
  );
};
SvgBulletPointOutline.displayName = 'BulletPointOutline';
const Memo = memo(SvgBulletPointOutline);
export default Memo;
