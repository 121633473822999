// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRewind10SFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.665.524a.52.52 0 0 0-.307-.475.54.54 0 0 0-.567.072l-1.6 1.31a.52.52 0 0 0 0 .805l1.6 1.31c.159.13.38.157.567.071a.52.52 0 0 0 .307-.474v-.77a5.668 5.668 0 1 1-4.799 1.75.5.5 0 1 0-.73-.685 6.667 6.667 0 1 0 5.528-2.072z" />
      <Path
        fillRule="evenodd"
        d="M9.49 10.604c-1.16 0-1.852-.952-1.852-2.428v-.229c0-1.468.702-2.427 1.866-2.427 1.167 0 1.855.948 1.855 2.427v.23c0 1.464-.71 2.427-1.87 2.427m.007-4.297c-.555 0-.892.613-.892 1.644v.218c0 1.032.337 1.647.892 1.647s.891-.615.891-1.647v-.218c0-1.028-.336-1.644-.891-1.644"
        clipRule="evenodd"
      />
      <Path d="M6.313 10.543c-.3 0-.476-.193-.476-.519v-3.48h-.021l-.49.347c-.155.1-.244.132-.348.132a.335.335 0 0 1-.344-.343q0-.224.276-.405l.64-.448c.259-.175.463-.247.663-.247.348 0 .58.24.58.602v3.842c0 .33-.175.52-.48.52" />
    </Svg>
  );
};
SvgRewind10SFill.displayName = 'Rewind10SFill';
const Memo = memo(SvgRewind10SFill);
export default Memo;
