// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrending2TrendsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.395 3.112a.166.166 0 0 0-.32.018L9.73 13.1c-.272 1.156-1.894 1.216-2.249.08L5.396 6.517a.166.166 0 0 0-.316-.003l-.844 2.528c-.34 1.022-1.762 1.078-2.18.084L1.37 7.503a.5.5 0 0 1 .922-.388l.684 1.623c.06.142.262.135.31-.012l.844-2.529c.359-1.075 1.882-1.06 2.22.021l2.083 6.664a.16.16 0 0 0 .063.091A.18.18 0 0 0 8.6 13a.18.18 0 0 0 .1-.034.16.16 0 0 0 .056-.096l2.347-9.97c.268-1.134 1.847-1.221 2.236-.12l1.299 3.678a.5.5 0 0 1-.943.333z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrending2TrendsOutline.displayName = 'Trending2TrendsOutline';
const Memo = memo(SvgTrending2TrendsOutline);
export default Memo;
