// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSpatialSpatialCaptureFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.084 1.116a1.83 1.83 0 0 1 1.829 0l2.32 1.334-5.569 3.214V1.932zM4.664 2.508 2.478 3.765c-.569.327-.92.933-.92 1.59v1.788l3.106 1.793zM1.559 8.298v2.347c0 .656.35 1.263.919 1.59l1.573.904 2.946-1.701zM5.052 13.716l2.033 1.168a1.83 1.83 0 0 0 1.828 0l1.418-.816v-3.4zM11.331 13.493l2.188-1.258c.569-.327.92-.934.92-1.59V9.191L11.33 7.397zM14.439 8.036V5.355c0-.657-.351-1.263-.92-1.59l-1.285-.739-3.237 1.869zM7.997 10.86 5.664 9.515V6.819l2.333-1.347 2.334 1.347v2.694z" />
    </Svg>
  );
};
SvgSpatialSpatialCaptureFill.displayName = 'SpatialSpatialCaptureFill';
const Memo = memo(SvgSpatialSpatialCaptureFill);
export default Memo;
