// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPieChart1GraphChartStatisticsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.435 9.745a6.667 6.667 0 0 0-5.936-8.393v6.294z" />
      <Path d="m14.102 10.687-6.27-2.216A.5.5 0 0 1 7.5 8V1.352a6.667 6.667 0 1 0 6.603 9.335" />
    </Svg>
  );
};
SvgPieChart1GraphChartStatisticsFill.displayName = 'PieChart1GraphChartStatisticsFill';
const Memo = memo(SvgPieChart1GraphChartStatisticsFill);
export default Memo;
