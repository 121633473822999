// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeOpenHouseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.21 1.565a1.83 1.83 0 0 0-2.42 0L2.623 5.226C2.227 5.574 2 6.076 2 6.603v5.564C2 13.179 2.82 14 3.833 14H5.44c.645 0 1.167-.522 1.167-1.167v-1.5c0-.736.597-1.333 1.333-1.333H8c.736 0 1.333.597 1.333 1.333v1.5c0 .645.523 1.167 1.167 1.167h1.667C13.179 14 14 13.18 14 12.167V6.603c0-.527-.227-1.029-.623-1.377z" />
    </Svg>
  );
};
SvgHomeOpenHouseFill.displayName = 'HomeOpenHouseFill';
const Memo = memo(SvgHomeOpenHouseFill);
export default Memo;
