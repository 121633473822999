// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCheckRadioCircleCheckboxCheckCheckmarkConfirmFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.334a6.667 6.667 0 1 0 0 13.333 6.667 6.667 0 0 0 0-13.333m2.387 5.316a.5.5 0 0 0-.774-.633l-2.65 3.239-.943-.943a.5.5 0 1 0-.707.707l1.333 1.334a.5.5 0 0 0 .74-.037z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCheckRadioCircleCheckboxCheckCheckmarkConfirmFill.displayName =
  'CheckRadioCircleCheckboxCheckCheckmarkConfirmFill';
const Memo = memo(SvgCheckRadioCircleCheckboxCheckCheckmarkConfirmFill);
export default Memo;
