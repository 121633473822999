// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRightGamepadBaseControllsGameJoystickFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.332 2.5c0-.644.522-1.167 1.167-1.167h3c.644 0 1.166.523 1.166 1.167v1.724c0 .31-.123.606-.341.825L8.706 6.667a1 1 0 0 1-1.414 0L5.674 5.049a1.17 1.17 0 0 1-.342-.825z" />
      <Path
        fillRule="evenodd"
        d="M14.665 6.5c0-.644-.522-1.167-1.166-1.167h-1.724c-.31 0-.606.123-.825.342L9.332 7.293a1 1 0 0 0 0 1.414l1.618 1.618c.219.219.515.342.825.342h1.724c.644 0 1.166-.523 1.166-1.167zM13.5 6.333c.092 0 .166.075.166.167v3a.167.167 0 0 1-.166.167h-1.724a.17.17 0 0 1-.118-.05L10.039 8l1.618-1.618a.17.17 0 0 1 .118-.049z"
        clipRule="evenodd"
      />
      <Path d="M9.499 14.667c.644 0 1.166-.523 1.166-1.167v-1.724c0-.31-.123-.606-.341-.825L8.706 9.333a1 1 0 0 0-1.414 0l-1.618 1.618a1.17 1.17 0 0 0-.342.825V13.5c0 .644.522 1.167 1.167 1.167zM1.332 9.5c0 .644.522 1.167 1.167 1.167h1.724c.309 0 .606-.123.825-.342l1.617-1.618a1 1 0 0 0 0-1.414L5.048 5.675a1.17 1.17 0 0 0-.825-.342H2.499c-.645 0-1.167.523-1.167 1.167z" />
    </Svg>
  );
};
SvgRightGamepadBaseControllsGameJoystickFill.displayName =
  'RightGamepadBaseControllsGameJoystickFill';
const Memo = memo(SvgRightGamepadBaseControllsGameJoystickFill);
export default Memo;
