// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDollarCurrencyMoneyCoinUsdFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m7.167-4.167a.5.5 0 0 0-1 0v.704a2.13 2.13 0 0 0-1.005.412c-.43.338-.653.842-.653 1.452 0 .709.288 1.19.727 1.514.354.26.802.41 1.16.53l.11.037.083.028c.398.134.695.235.915.397.187.137.32.321.32.709 0 .348-.116.544-.271.667-.174.136-.464.233-.886.233-.536 0-.88-.244-1.05-.55a.5.5 0 1 0-.874.486c.286.513.785.887 1.424 1.016v.699a.5.5 0 0 0 1 0v-.687c.371-.058.72-.187 1.005-.412.429-.339.653-.843.653-1.452 0-.71-.289-1.19-.728-1.514-.353-.26-.801-.41-1.16-.53l-.11-.037-.082-.028c-.399-.135-.696-.235-.916-.397-.186-.138-.32-.322-.32-.71 0-.348.116-.543.272-.666.174-.137.463-.234.886-.234.536 0 .879.245 1.05.551a.5.5 0 1 0 .873-.486 2.06 2.06 0 0 0-1.423-1.016z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDollarCurrencyMoneyCoinUsdFill.displayName = 'DollarCurrencyMoneyCoinUsdFill';
const Memo = memo(SvgDollarCurrencyMoneyCoinUsdFill);
export default Memo;
