// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStrikeThroughOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.735 2.536c.706-.716 1.783-1.203 3.268-1.203 1.553 0 2.576.534 3.23 1.226.638.677.883 1.462.977 1.913a.5.5 0 1 1-.979.204c-.073-.352-.261-.938-.726-1.43-.45-.478-1.203-.913-2.502-.913-1.255 0-2.062.405-2.556.905a2.44 2.44 0 0 0-.712 1.679c0 .431.1.757.258 1.016.16.262.397.484.71.679.633.394 1.493.632 2.452.888h6.014a.5.5 0 0 1 0 1H1.836a.5.5 0 0 1 0-1h3.403l-.064-.04c-.41-.254-.774-.578-1.036-1.006-.263-.432-.404-.941-.404-1.537 0-.775.303-1.674 1-2.381M10.642 9.5h1.368l.029.046c.263.432.404.941.404 1.537 0 1.603-1.449 3.584-4.44 3.584-1.552 0-2.617-.533-3.328-1.207a4.5 4.5 0 0 1-1.17-1.882.5.5 0 1 1 .953-.304c.113.355.37.954.905 1.46.523.496 1.34.933 2.64.933 2.491 0 3.44-1.584 3.44-2.584 0-.431-.1-.757-.258-1.016q-.195-.318-.543-.567" />
    </Svg>
  );
};
SvgStrikeThroughOutline.displayName = 'StrikeThroughOutline';
const Memo = memo(SvgStrikeThroughOutline);
export default Memo;
