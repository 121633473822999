// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBoldOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.328 3.833C3.328 2.821 4.148 2 5.161 2h3.674A3.167 3.167 0 0 1 12 5.167v.166a3.16 3.16 0 0 1-1.17 2.459 3.17 3.17 0 0 1 1.837 2.875v.166A3.167 3.167 0 0 1 9.501 14h-4.34a1.833 1.833 0 0 1-1.833-1.833zM9.501 13c1.197 0 2.167-.97 2.167-2.167v-.166c0-1.197-.97-2.167-2.167-2.167H4.328v3.667c0 .46.373.833.833.833zM4.328 7.5h4.507C10.03 7.5 11 6.53 11 5.333v-.166C11.001 3.97 10.031 3 8.835 3H5.16a.833.833 0 0 0-.833.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBoldOutline.displayName = 'BoldOutline';
const Memo = memo(SvgBoldOutline);
export default Memo;
