// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudLinesSyncDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v6.55a3.5 3.5 0 1 0-4.95 4.95H4.501a1.833 1.833 0 0 1-1.833-1.833zm2 .667a.5.5 0 0 1 .5-.5h4.333a.5.5 0 1 1 0 1H5.168a.5.5 0 0 1-.5-.5m0 2.666a.5.5 0 0 1 .5-.5h1.667a.5.5 0 0 1 0 1H5.168a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M10.835 9.667a2.5 2.5 0 0 0 0 5h1.833a2 2 0 0 0 .172-3.993 2.5 2.5 0 0 0-2.005-1.007m-1.5 2.5a1.5 1.5 0 0 1 2.8-.747.5.5 0 0 0 .466.249l.067-.002a1 1 0 1 1 0 2h-1.833a1.5 1.5 0 0 1-1.5-1.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudLinesSyncDocumentListPageFileFill.displayName = 'CloudLinesSyncDocumentListPageFileFill';
const Memo = memo(SvgCloudLinesSyncDocumentListPageFileFill);
export default Memo;
