// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSpeakerMusicSoundOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834v9.666c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834zm1.833-.834a.833.833 0 0 0-.833.834v9.666c0 .46.373.834.833.834h7c.46 0 .834-.373.834-.834V3.167a.833.833 0 0 0-.834-.834zm1.5 2.167a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m2 3.167a1.833 1.833 0 1 0 0 3.666 1.833 1.833 0 0 0 0-3.666M5.168 9.5a2.833 2.833 0 1 1 5.667 0 2.833 2.833 0 0 1-5.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSpeakerMusicSoundOutline.displayName = 'SpeakerMusicSoundOutline';
const Memo = memo(SvgSpeakerMusicSoundOutline);
export default Memo;
