// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLifeVestVestFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.167 1.333a.5.5 0 0 1 .5.5c0 .84.302 1.432.722 1.816.308.282.696.467 1.111.549v3.135h-1a.5.5 0 0 0 0 1h1V10H2V7.833c0-.524.231-.956.529-1.289.294-.328.671-.581 1.024-.77.502-.27.794-.79.953-1.528.159-.74.16-1.595.16-2.413a.5.5 0 0 1 .5-.5M2 11v1.167C2 13.179 2.82 14 3.833 14H7.5v-3zM8.5 14h3.667C13.179 14 14 13.18 14 12.167V11H8.5zM14 10V7.79c0-1.021-.85-1.681-1.514-2.03-.526-.275-.825-.794-.987-1.519-.164-.732-.166-1.582-.166-2.409a.5.5 0 1 0-1 0c0 .84-.302 1.432-.722 1.816a2.3 2.3 0 0 1-1.111.549v3.135h1a.5.5 0 0 1 0 1h-1V10z" />
    </Svg>
  );
};
SvgLifeVestVestFill.displayName = 'LifeVestVestFill';
const Memo = memo(SvgLifeVestVestFill);
export default Memo;
