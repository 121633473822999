import type {BorderColor, FillColor, IconColor, TextColor} from '@propertyguru/hive';
import type {SpinnerColor} from '@propertyguru/hive-spinner';
import type {PressableState} from '@propertyguru/hive-surface';

import type {ButtonIconType, ButtonType} from './types';

const backgroundColor = (type: ButtonType | ButtonIconType): FillColor | undefined => {
  switch (type) {
    case 'primary':
      return 'fill/active/primary';
    case 'secondary':
      return undefined;
    case 'tertiary':
      return 'fill/active/secondary';
    case 'light':
      return 'fill/static/primary';
    case 'dark':
      return 'fill/static/secondary';
    case 'icon':
      return 'fill/neutral/secondary';
  }
};

const surfaceColor = (
  type: ButtonType | ButtonIconType,
  state: PressableState,
  loading?: boolean,
): FillColor | undefined => {
  switch (true) {
    case state.disabled:
      // Disabled state has same color for any type of button
      return 'fill/inactive/primary';
    case state.pressed:
      return loading ? undefined : 'fill/pressed/primary';
    case state.hovered:
      return type !== 'dark' ? 'fill/hover/primary' : undefined;
    default:
      return backgroundColor(type);
  }
};

const borderColorInactive = (type: ButtonType | ButtonIconType): BorderColor | undefined => {
  return type === 'secondary' ? 'border/inactive/primary' : undefined;
};

const borderColorDefault = (type: ButtonType | ButtonIconType): BorderColor => {
  switch (type) {
    case 'secondary':
    case 'dark':
      return 'border/active/tertiary';
    case 'tertiary':
      return 'border/error/secondary';
    case 'light':
    case 'icon':
      return 'border/active/primary';
    case 'primary':
    default:
      return 'border/active/quaternary';
  }
};

const surfaceBorderColor = (
  type: ButtonType | ButtonIconType,
  state: PressableState,
): BorderColor | undefined => {
  switch (true) {
    case state.disabled:
      return borderColorInactive(type);
    case state.focused:
      return 'border/focus/primary';
    default:
      return borderColorDefault(type);
  }
};

const getTextColor = (type: ButtonType, enabled: boolean): TextColor => {
  if (!enabled) {
    // Disabled state has same color for any type of button
    return 'text/inactive/primary';
  }
  switch (type) {
    case 'secondary':
    case 'tertiary':
      return 'text/active/primary';
    case 'primary':
    case 'light':
    case 'dark':
    default:
      return 'text/static/primary';
  }
};

const getIconColor = (type: ButtonType | ButtonIconType, enabled: boolean): IconColor => {
  if (!enabled) {
    // Disabled state has same color for any type of button
    return 'icon/inactive/primary';
  }

  switch (type) {
    case 'secondary':
    case 'tertiary':
      return 'icon/active/primary';
    case 'primary':
    case 'light':
    case 'dark':
    default:
      return 'icon/static/primary';
  }
};

const getSpinnerColor = (type: ButtonType | ButtonIconType, enabled: boolean): SpinnerColor => {
  if (!enabled) {
    return 'light';
  }

  switch (type) {
    case 'secondary':
    case 'tertiary':
    case 'icon':
    case 'light':
      return 'dark';

    case 'primary':
    case 'dark':
    default:
      return 'light';
  }
};

export {getIconColor, getSpinnerColor, getTextColor, surfaceBorderColor, surfaceColor};
