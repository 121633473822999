// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowTopCircleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2.087a5.913 5.913 0 1 0 0 11.826A5.913 5.913 0 0 0 8 2.087M1.043 8a6.957 6.957 0 1 1 13.914 0A6.957 6.957 0 0 1 1.043 8m7.479-1.697 1.37 1.37a.522.522 0 1 0 .738-.738L8.86 5.166a1.217 1.217 0 0 0-1.72 0l-1.77 1.77a.522.522 0 1 0 .738.737l1.37-1.37v4.654a.522.522 0 1 0 1.044 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowTopCircleOutline.displayName = 'ArrowTopCircleOutline';
const Memo = memo(SvgArrowTopCircleOutline);
export default Memo;
