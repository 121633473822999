// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGraphLinesStatisticsDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v4.321a4.333 4.333 0 0 0-4.769 7.179H4.5a1.833 1.833 0 0 1-1.833-1.833zm2 .667a.5.5 0 0 1 .5-.5h4.333a.5.5 0 1 1 0 1H5.168a.5.5 0 0 1-.5-.5m0 2.666a.5.5 0 0 1 .5-.5h1.667a.5.5 0 0 1 0 1H5.168a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M11.335 8a3.333 3.333 0 1 0 0 6.667 3.333 3.333 0 0 0 0-6.667M9 11.334c0-1.117.785-2.051 1.834-2.28v2.28a.5.5 0 0 0 .146.353l1.612 1.612A2.333 2.333 0 0 1 9 11.334m2.834-.208V9.054a2.334 2.334 0 0 1 1.465 3.538z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGraphLinesStatisticsDocumentListPageFileFill.displayName =
  'GraphLinesStatisticsDocumentListPageFileFill';
const Memo = memo(SvgGraphLinesStatisticsDocumentListPageFileFill);
export default Memo;
