// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChart5StatisticsGraph3Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.667 3.167C5.667 2.522 6.189 2 6.833 2h2.334c.644 0 1.166.522 1.166 1.167v2.166h2.5c.645 0 1.167.523 1.167 1.167v6.667c0 .46-.373.833-.833.833h-10A1.167 1.167 0 0 1 2 12.833v-3c0-.644.522-1.166 1.167-1.166h2.5zm1 9.833h2.666V3.167A.167.167 0 0 0 9.167 3H6.833a.167.167 0 0 0-.166.167zm3.666 0H13V6.5a.167.167 0 0 0-.167-.167h-2.5zm-4.666 0V9.667h-2.5A.167.167 0 0 0 3 9.833v3c0 .092.075.167.167.167z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChart5StatisticsGraph3Outline.displayName = 'Chart5StatisticsGraph3Outline';
const Memo = memo(SvgChart5StatisticsGraph3Outline);
export default Memo;
