// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFocusCameraExposureMagicAutoAiSparklesEffectsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 3A.833.833 0 0 0 3 3.833v1.5a.5.5 0 1 1-1 0v-1.5C2 2.821 2.82 2 3.833 2h1.5a.5.5 0 1 1 0 1zm6.334-.5a.5.5 0 0 1 .5-.5h1.5C13.179 2 14 2.82 14 3.833v1.5a.5.5 0 0 1-1 0v-1.5A.833.833 0 0 0 12.167 3h-1.5a.5.5 0 0 1-.5-.5M2.5 10.167a.5.5 0 0 1 .5.5v1.5c0 .46.373.833.833.833h1.5a.5.5 0 0 1 0 1h-1.5A1.833 1.833 0 0 1 2 12.167v-1.5a.5.5 0 0 1 .5-.5m11 0a.5.5 0 0 1 .5.5v1.5C14 13.179 13.18 14 12.167 14h-1.5a.5.5 0 1 1 0-1h1.5c.46 0 .833-.373.833-.833v-1.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
      <Path d="M8.123 6.193a.33.33 0 0 0 .15-.149l.429-.86a.333.333 0 0 1 .596 0l.43.86a.33.33 0 0 0 .15.15l.86.43a.333.333 0 0 1 0 .595l-.86.43a.33.33 0 0 0-.15.15l-.43.86a.333.333 0 0 1-.596 0l-.43-.86a.33.33 0 0 0-.149-.15l-.86-.43a.333.333 0 0 1 0-.596zM5.718 9.062a.2.2 0 0 0 .09-.09l.347-.693a.2.2 0 0 1 .357 0l.347.694a.2.2 0 0 0 .09.09l.693.346a.2.2 0 0 1 0 .358l-.693.347a.2.2 0 0 0-.09.09l-.347.693a.2.2 0 0 1-.357 0l-.347-.694a.2.2 0 0 0-.09-.09l-.693-.346a.2.2 0 0 1 0-.358z" />
    </Svg>
  );
};
SvgFocusCameraExposureMagicAutoAiSparklesEffectsFill.displayName =
  'FocusCameraExposureMagicAutoAiSparklesEffectsFill';
const Memo = memo(SvgFocusCameraExposureMagicAutoAiSparklesEffectsFill);
export default Memo;
