// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGymFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.011 4.39a1.303 1.303 0 0 1 1.856 0c.247.25.385.59.385.944v2.222h3.503V5.334c0-.354.138-.693.384-.943a1.303 1.303 0 0 1 1.857 0c.246.25.384.59.384.943v.52a1.29 1.29 0 0 1 1.367.315c.246.25.384.589.384.942v.445h.43a.44.44 0 0 1 .437.444.44.44 0 0 1-.437.445h-.43v.444c0 .354-.138.693-.384.943a1.3 1.3 0 0 1-1.367.314v.521c0 .354-.138.693-.384.943s-.58.39-.928.39-.682-.14-.929-.39a1.34 1.34 0 0 1-.384-.943V8.445H6.252v2.222c0 .354-.138.693-.385.943a1.3 1.3 0 0 1-1.856 0 1.34 1.34 0 0 1-.385-.943v-.521a1.29 1.29 0 0 1-1.366-.314 1.34 1.34 0 0 1-.385-.943v-.444h-.438A.44.44 0 0 1 1 8a.44.44 0 0 1 .437-.444h.438V7.11c0-.353.139-.692.385-.942a1.3 1.3 0 0 1 1.366-.314v-.521c0-.354.139-.693.385-.943m9.245 3.53a.5.5 0 0 0 0 .16v.81c0 .117-.046.23-.128.313a.434.434 0 0 1-.619 0 .45.45 0 0 1-.128-.314V7.111c0-.117.046-.23.128-.314a.434.434 0 0 1 .619 0 .45.45 0 0 1 .128.314zM3.188 6.667a.43.43 0 0 0-.31.13.45.45 0 0 0-.127.314V8.89c0 .118.046.231.128.314a.434.434 0 0 0 .619 0 .45.45 0 0 0 .128-.314V7.111a.45.45 0 0 0-.128-.314.43.43 0 0 0-.31-.13"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGymFill.displayName = 'GymFill';
const Memo = memo(SvgGymFill);
export default Memo;
