// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGamepadGamingJoystickGamesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.999 3.5A3.167 3.167 0 0 0 .832 6.667v2.666A3.167 3.167 0 0 0 3.999 12.5h8a3.167 3.167 0 0 0 3.166-3.167V6.667A3.167 3.167 0 0 0 12 3.5zM5.332 6a.5.5 0 0 1 .5.5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 .5-.5m6.667 1.5a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m-2 2.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGamepadGamingJoystickGamesFill.displayName = 'GamepadGamingJoystickGamesFill';
const Memo = memo(SvgGamepadGamingJoystickGamesFill);
export default Memo;
