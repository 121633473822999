// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSearch2MagnifyingGlassOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.333 3a4.333 4.333 0 1 0 0 8.667 4.333 4.333 0 0 0 0-8.667M2 7.333a5.333 5.333 0 1 1 9.442 3.402l2.245 2.245a.5.5 0 0 1-.707.707l-2.245-2.245A5.333 5.333 0 0 1 2 7.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSearch2MagnifyingGlassOutline.displayName = 'Search2MagnifyingGlassOutline';
const Memo = memo(SvgSearch2MagnifyingGlassOutline);
export default Memo;
