// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRotateRepeatRefreshFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.167 2a.5.5 0 0 1 .5.5v1.307A6.1 6.1 0 0 1 8 2a6 6 0 0 1 5.954 6.75.5.5 0 0 1-.993-.124A5 5 0 0 0 8 3a5.1 5.1 0 0 0-3.77 1.667h1.603a.5.5 0 1 1 0 1H3.667a1 1 0 0 1-1-1V2.5a.5.5 0 0 1 .5-.5m-.563 4.816a.5.5 0 0 1 .435.558A5 5 0 0 0 8 13a5.1 5.1 0 0 0 3.77-1.667h-1.595a.5.5 0 1 1 0-1h2.166a1 1 0 0 1 1 1V13.5a.5.5 0 1 1-1 0v-1.315A6.1 6.1 0 0 1 8 14a6 6 0 0 1-5.954-6.75.5.5 0 0 1 .558-.434"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRotateRepeatRefreshFill.displayName = 'RotateRepeatRefreshFill';
const Memo = memo(SvgRotateRepeatRefreshFill);
export default Memo;
