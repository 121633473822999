// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgReadingListGlassesSteveJobsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4 4.667c-1.091 0-2.06.524-2.667 1.333H.5a.5.5 0 0 0 0 1h.32a3.333 3.333 0 1 0 6.185-.445c.301-.142.639-.222.995-.222s.694.08.995.223A3.333 3.333 0 1 0 15.18 7h.32a.5.5 0 0 0 0-1h-.833A3.33 3.33 0 0 0 12 4.666c-.96 0-1.827.407-2.435 1.056A3.3 3.3 0 0 0 8 5.333a3.3 3.3 0 0 0-1.565.39A3.33 3.33 0 0 0 4 4.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgReadingListGlassesSteveJobsFill.displayName = 'ReadingListGlassesSteveJobsFill';
const Memo = memo(SvgReadingListGlassesSteveJobsFill);
export default Memo;
