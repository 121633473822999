// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVolumeFullSpeakerLoudSoundOnMusicFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.668 2.817a.833.833 0 0 0-1.32-.676L4.058 4.51a.83.83 0 0 1-.487.157h-1.07C1.49 4.667.668 5.487.668 6.5v3c0 1.012.82 1.833 1.833 1.833h1.07c.175 0 .345.055.487.157l3.29 2.369a.833.833 0 0 0 1.32-.676zM12.48 2.814a.5.5 0 0 1 .707 0A7.3 7.3 0 0 1 15.335 8a7.3 7.3 0 0 1-2.148 5.185.5.5 0 1 1-.707-.707A6.3 6.3 0 0 0 14.335 8a6.3 6.3 0 0 0-1.855-4.478.5.5 0 0 1 0-.708M10.948 5.054a.5.5 0 1 0-.708.707c.574.574.928 1.365.928 2.24 0 .874-.354 1.665-.928 2.239a.5.5 0 0 0 .707.707A4.16 4.16 0 0 0 12.168 8c0-1.15-.466-2.193-1.22-2.946" />
    </Svg>
  );
};
SvgVolumeFullSpeakerLoudSoundOnMusicFill.displayName = 'VolumeFullSpeakerLoudSoundOnMusicFill';
const Memo = memo(SvgVolumeFullSpeakerLoudSoundOnMusicFill);
export default Memo;
