// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAnonymousAnonymHiddenOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5 3c-.418 0-.771.31-.827.723L3.736 7h8.525l-.437-3.277A.83.83 0 0 0 10.998 3zm8.27 4-.455-3.409A1.83 1.83 0 0 0 10.998 2H4.999c-.918 0-1.695.68-1.817 1.591L2.728 7h-.896a.5.5 0 0 0 0 1h12.333a.5.5 0 0 0 0-1zM4.665 9.667a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333M2 11.333a2.667 2.667 0 0 1 5.066-1.165 2.66 2.66 0 0 1 1.868 0 2.666 2.666 0 1 1-.26.974 1.66 1.66 0 0 0-1.348 0q.007.095.007.191a2.667 2.667 0 1 1-5.333 0m9.333-1.666a1.667 1.667 0 1 0 0 3.335 1.667 1.667 0 0 0 0-3.335"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAnonymousAnonymHiddenOutline.displayName = 'AnonymousAnonymHiddenOutline';
const Memo = memo(SvgAnonymousAnonymHiddenOutline);
export default Memo;
