// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChatGptFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.373 3.46c.37-1.224 1.532-2.127 2.917-2.127.712 0 1.366.239 1.883.637q-.138.065-.273.141l-2.193 1.24c-.57.322-.925.923-.931 1.577l-.033 3.186-1.078-.624a.83.83 0 0 1-.416-.721V4.3c0-.293.044-.575.124-.841" />
      <Path d="M13.552 4.07c.356.603.47 1.278.372 1.917a4 4 0 0 0-.284-.177L11.427 4.56a1.83 1.83 0 0 0-1.789-.009L6.763 6.138l.013-1.2a.83.83 0 0 1 .423-.716l2.193-1.24c1.46-.826 3.323-.332 4.16 1.088M6.737 8.689l.014-1.402 1.262-.697 1.249.722-.015 1.4-1.263.698z" />
      <Path d="m9.035 6.025 1.086-.6a.83.83 0 0 1 .814.004l2.213 1.252q.392.224.687.53c.9.928 1.11 2.357.424 3.518a3 3 0 0 1-1.524 1.282q.013-.155.013-.312V9.231c0-.654-.349-1.259-.915-1.586z" />
      <Path d="m10.256 7.887 1.076.623a.83.83 0 0 1 .416.721V11.7q-.002.44-.124.841c-.37 1.224-1.532 2.127-2.917 2.127a3.07 3.07 0 0 1-1.882-.637q.138-.064.274-.142l2.193-1.24c.57-.322.924-.922.93-1.576z" />
      <Path d="m9.235 9.862-.012 1.2a.83.83 0 0 1-.423.716l-2.193 1.24a3 3 0 0 1-.814.318c-1.282.302-2.66-.242-3.346-1.406a2.9 2.9 0 0 1-.373-1.916q.136.092.283.176L4.57 11.44a1.83 1.83 0 0 0 1.79.01z" />
      <Path d="m6.962 9.975-1.086.6a.83.83 0 0 1-.813-.004L2.849 9.319a3 3 0 0 1-.686-.528 2.915 2.915 0 0 1-.425-3.52A3 3 0 0 1 3.262 3.99a4 4 0 0 0-.013.312V6.77c0 .654.35 1.259.915 1.587z" />
    </Svg>
  );
};
SvgChatGptFill.displayName = 'ChatGptFill';
const Memo = memo(SvgChatGptFill);
export default Memo;
