// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTarget1ZoomFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a6.667 6.667 0 1 1 0 13.333 6.667 6.667 0 0 1 0-13.333m0 2.667a.5.5 0 0 1 .5.5v2a.5.5 0 1 1-1 0v-2a.5.5 0 0 1 .5-.5m1.5 3.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zM7.999 9a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2a.5.5 0 0 1 .5-.5m-3.5-1.5a.5.5 0 0 0 0 1h2a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTarget1ZoomFill.displayName = 'Target1ZoomFill';
const Memo = memo(SvgTarget1ZoomFill);
export default Memo;
