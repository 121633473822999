// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNotesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.335 3.5c0-1.013.82-1.833 1.833-1.833h7.667c1.012 0 1.833.82 1.833 1.833v9c0 1.012-.82 1.833-1.833 1.833H4.168A1.833 1.833 0 0 1 2.335 12.5V11h-.167a.5.5 0 1 1 0-1h.167V8.5h-.167a.5.5 0 0 1 0-1h.167V6h-.167a.5.5 0 0 1 0-1h.167zM6 5.5a.5.5 0 0 1 .5-.5h3a.5.5 0 1 1 0 1h-3a.5.5 0 0 1-.5-.5m0 2.5a.5.5 0 0 1 .5-.5h1.667a.5.5 0 0 1 0 1H6.501a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNotesFill.displayName = 'NotesFill';
const Memo = memo(SvgNotesFill);
export default Memo;
