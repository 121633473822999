// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRestaurantOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.823 2.634v-.509h.342v.509c0 .41.123.811.352 1.153.142.21.218.46.218.713v2.488H9.253V4.5a1.3 1.3 0 0 1 .218-.713 2.07 2.07 0 0 0 .352-1.153m-.114-1.3a.69.69 0 0 0-.483.198c-.128.127-.2.3-.2.48v.622c0 .254-.076.502-.218.713A2.07 2.07 0 0 0 8.456 4.5v2.488H6.41V5.717c.301-.104.57-.29.772-.542.278-.344.41-.783.365-1.223a.4.4 0 0 0-.396-.357H4.87a.4.4 0 0 0-.397.357c-.043.44.088.879.366 1.223.202.251.47.438.772.542v1.271H3.73a.397.397 0 0 0-.4.396c0 .219.18.396.4.396h3.877v4.327a2.65 2.65 0 0 0-1.911 1.3.394.394 0 0 0 .148.54.4.4 0 0 0 .545-.147 1.86 1.86 0 0 1 1.61-.931h.014a1.87 1.87 0 0 1 1.609.931.4.4 0 0 0 .544.148.394.394 0 0 0 .149-.54 2.65 2.65 0 0 0-1.91-1.3V7.78h3.872c.22 0 .398-.177.398-.396a.397.397 0 0 0-.398-.396h-.744V4.5c0-.41-.123-.811-.352-1.153a1.3 1.3 0 0 1-.218-.713v-.622a.68.68 0 0 0-.2-.48.69.69 0 0 0-.484-.199zM5.463 4.68a.9.9 0 0 1-.158-.294h1.412a.9.9 0 0 1-.706.618.9.9 0 0 1-.548-.324m-3.33.419a.4.4 0 0 0-.42-.373.397.397 0 0 0-.376.417l.283 5.062-.283 3.365a.397.397 0 0 0 .365.428.4.4 0 0 0 .43-.362l.255-3.03H4.3a.17.17 0 0 1 .171.17v2.827c0 .219.179.396.4.396.22 0 .398-.177.398-.396v-2.827c0-.255-.102-.5-.284-.68a.97.97 0 0 0-.685-.281H2.397zm12.159-.373c.22.012.388.199.376.417l-.283 5.062.282 3.365a.397.397 0 0 1-.364.428.4.4 0 0 1-.43-.362l-.255-3.03h-1.912a.17.17 0 0 0-.171.17v2.827a.397.397 0 0 1-.4.396.397.397 0 0 1-.398-.396v-2.827c0-.255.102-.5.284-.68a.97.97 0 0 1 .685-.281h1.902L13.87 5.1a.4.4 0 0 1 .42-.373"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRestaurantOff.displayName = 'RestaurantOff';
const Memo = memo(SvgRestaurantOff);
export default Memo;
