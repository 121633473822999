// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVolumeFullSpeakerLoudSoundOnMusicOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.668 3.143 4.642 5.32a1.83 1.83 0 0 1-1.07.346h-1.07a.833.833 0 0 0-.834.833v3c0 .46.373.833.833.833h1.07c.385 0 .76.121 1.071.346l3.026 2.178zm-.32-1.002a.833.833 0 0 1 1.32.676v10.366a.833.833 0 0 1-1.32.676l-3.29-2.369a.83.83 0 0 0-.487-.157h-1.07A1.833 1.833 0 0 1 .668 9.5v-3c0-1.013.82-1.833 1.833-1.833h1.07a.83.83 0 0 0 .487-.157zm5.132.673a.5.5 0 0 1 .707 0A7.3 7.3 0 0 1 15.335 8a7.3 7.3 0 0 1-2.148 5.185.5.5 0 0 1-.707-.707A6.3 6.3 0 0 0 14.335 8a6.3 6.3 0 0 0-1.855-4.478.5.5 0 0 1 0-.708m-2.24 2.24a.5.5 0 0 1 .708 0A4.16 4.16 0 0 1 12.168 8c0 1.15-.467 2.193-1.22 2.947a.5.5 0 0 1-.707-.707A3.16 3.16 0 0 0 11.168 8c0-.874-.354-1.665-.927-2.239a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVolumeFullSpeakerLoudSoundOnMusicOutline.displayName =
  'VolumeFullSpeakerLoudSoundOnMusicOutline';
const Memo = memo(SvgVolumeFullSpeakerLoudSoundOnMusicOutline);
export default Memo;
