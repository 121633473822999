// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFootballOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m1.639 3.633.026-.025A2.3 2.3 0 0 1 3.227 3h9.546c.59 0 1.157.223 1.575.62.417.396.652.934.652 1.495v7.055a.8.8 0 0 1-.136.445.86.86 0 0 1-.367.307.92.92 0 0 1-.93-.115l-1.84-1.456H4.273l-1.84 1.456a.915.915 0 0 1-.93.114.86.86 0 0 1-.367-.306A.8.8 0 0 1 1 12.17V5.115c0-.555.23-1.087.639-1.482m2.948 6.785h1.616v-.715H4.587zm4.226 0H7.187v-.715h1.625zm.983 0h1.617v-.715H9.796zm0-1.649h1.617v-.714H9.796zm-.983-.714H7.187v.714h1.625zm0-.934H7.187v-.714h1.625zm.983 0h1.617v-.714H9.796zm-.483-1.649h2.381l1.534-1.456a1.3 1.3 0 0 0-.455-.082H3.227c-.159 0-.315.03-.46.084l1.531 1.454h5.015M4.587 8.055h1.616v.714H4.587zm0-.934h1.616v-.714H4.587zm7.81 2.189a.5.5 0 0 0 0-.148v-1.5a.5.5 0 0 0 0-.147V6.126l1.53-1.452q.088.21.09.441v6.833l-1.62-1.282zM2.072 4.679a1.1 1.1 0 0 0-.089.436v6.833l1.62-1.282V6.134z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFootballOutline.displayName = 'FootballOutline';
const Memo = memo(SvgFootballOutline);
export default Memo;
