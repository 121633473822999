// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEditPencilWriteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.369 1.922a1.833 1.833 0 0 1 2.593 0l1.114 1.115a1.833 1.833 0 0 1 0 2.593L12.54 7.167 8.832 3.46l-.707.707 3.707 3.707-6.256 6.256c-.344.343-.81.536-1.296.536H1.832a.5.5 0 0 1-.5-.5V11.72c0-.486.193-.953.537-1.297z" />
    </Svg>
  );
};
SvgEditPencilWriteFill.displayName = 'EditPencilWriteFill';
const Memo = memo(SvgEditPencilWriteFill);
export default Memo;
