// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPinLocationOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 2.333a4.333 4.333 0 0 0-4.333 4.334c0 1.373.617 2.719 1.438 3.895.817 1.17 1.804 2.125 2.467 2.705.25.218.606.218.856 0 .664-.58 1.65-1.535 2.467-2.705.821-1.176 1.439-2.522 1.439-3.895A4.333 4.333 0 0 0 8 2.333M2.668 6.667a5.333 5.333 0 1 1 10.667 0c0 1.663-.742 3.211-1.619 4.467-.88 1.262-1.932 2.278-2.629 2.886a1.64 1.64 0 0 1-2.172 0c-.696-.608-1.748-1.624-2.629-2.886C3.41 9.878 2.668 8.33 2.668 6.667m5.333-1.334a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667M5.668 6.667a2.333 2.333 0 1 1 4.667 0 2.333 2.333 0 0 1-4.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPinLocationOutline.displayName = 'PinLocationOutline';
const Memo = memo(SvgPinLocationOutline);
export default Memo;
