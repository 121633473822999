// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRubberDuckDuckFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.91 2.005a3.75 3.75 0 0 1 2.242.599 3.5 3.5 0 0 1 1.401 1.778c.25.724.245 1.506-.016 2.226-.13.36-.321.695-.562.992h1.666a2 2 0 0 0 .83-.186c.26-.12.488-.293.67-.507l.001-.002c.126-.147.293-.255.482-.31.189-.057.391-.06.581-.007.19.052.36.156.49.3a.95.95 0 0 1 .237.507v.007c.138 1.152.124 2.798-.72 4.17-.868 1.411-2.55 2.434-5.524 2.428-1.35-.003-2.629-.274-3.587-.834-.972-.567-1.624-1.443-1.624-2.604 0-.603.194-1.192.554-1.687.208-.285.466-.532.76-.73l-.006-.005h-1.89c-.617 0-1.104-.307-1.421-.729A2.5 2.5 0 0 1 1 5.927c0-.228.192-.414.43-.418a2.76 2.76 0 0 0 1.472-.449c.353-.23.641-.539.843-.896.218-.512.56-.97 1.004-1.332a3.72 3.72 0 0 1 2.162-.827M3.486 5.691l-.088.06a3.65 3.65 0 0 1-1.482.558c.05.234.143.446.268.613.187.247.429.38.713.38h1.128a3.352 3.352 0 0 1-.539-1.612m2.187-.88c0-.462.392-.836.875-.836s.875.374.875.837-.392.837-.875.837-.875-.375-.875-.837"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRubberDuckDuckFill.displayName = 'RubberDuckDuckFill';
const Memo = memo(SvgRubberDuckDuckFill);
export default Memo;
