// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPrinterPrintFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill={color}
      viewBox="0 0 24 24"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.75 2A2.75 2.75 0 0 0 6 4.75V6H4.75A2.75 2.75 0 0 0 2 8.75v6.5A2.75 2.75 0 0 0 4.75 18H6v1.25A2.75 2.75 0 0 0 8.75 22h6.5A2.75 2.75 0 0 0 18 19.25V18h1.25A2.75 2.75 0 0 0 22 15.25v-6.5A2.75 2.75 0 0 0 19.25 6H18V4.75A2.75 2.75 0 0 0 15.25 2zm7.75 4V4.75c0-.69-.56-1.25-1.25-1.25h-6.5c-.69 0-1.25.56-1.25 1.25V6zm0 8.5h-9v4.75c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25zM6 10.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPrinterPrintFill.displayName = 'PrinterPrintFill';
const Memo = memo(SvgPrinterPrintFill);
export default Memo;
