// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeOpenHouseOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.55 2.316a.833.833 0 0 0-1.1 0L3.283 5.977A.83.83 0 0 0 3 6.603v5.564c0 .46.373.833.833.833h2A.167.167 0 0 0 6 12.833v-1.666c0-1.013.82-1.834 1.833-1.834h.334c1.012 0 1.833.821 1.833 1.834v1.666c0 .092.075.167.167.167h2c.46 0 .833-.373.833-.833V6.603a.83.83 0 0 0-.283-.626zm-1.76-.751a1.83 1.83 0 0 1 2.42 0l4.167 3.661c.396.348.623.85.623 1.377v5.564C14 13.179 13.18 14 12.167 14h-2A1.167 1.167 0 0 1 9 12.833v-1.666a.833.833 0 0 0-.833-.834h-.334a.833.833 0 0 0-.833.834v1.666C7 13.478 6.478 14 5.833 14h-2A1.833 1.833 0 0 1 2 12.167V6.603c0-.527.227-1.029.623-1.377z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHomeOpenHouseOutline.displayName = 'HomeOpenHouseOutline';
const Memo = memo(SvgHomeOpenHouseOutline);
export default Memo;
