// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBuildingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.876 2.9a.47.47 0 0 1 .335-.143h7.579c.125 0 .246.051.334.142a.5.5 0 0 1 .14.344v10H9.421v-2.216a.374.374 0 0 0-.369-.378H6.948a.373.373 0 0 0-.368.378v2.216H3.737v-10c0-.129.05-.253.139-.344m4.809 10.343H7.316v-1.838h1.369zM6.942 14H3.368A.374.374 0 0 1 3 13.622V3.243c0-.33.128-.646.355-.879S3.889 2 4.21 2h7.579c.32 0 .628.131.855.364.228.233.355.55.355.88v10.378a.373.373 0 0 1-.368.378h-5.69m-2.18-9c0-.262.197-.474.44-.474h1.765c.244 0 .441.212.441.474s-.197.474-.44.474H5.202c-.244 0-.442-.212-.442-.474m3.97-.474c-.243 0-.44.212-.44.474s.197.474.44.474h1.765c.244 0 .441-.212.441-.474s-.197-.474-.44-.474zm-3.97 2.369c0-.262.197-.474.44-.474h1.765c.244 0 .441.212.441.474 0 .261-.197.473-.44.473H5.202c-.244 0-.442-.212-.442-.473m3.97-.474c-.243 0-.44.212-.44.474 0 .261.197.473.44.473h1.765c.244 0 .441-.212.441-.473 0-.262-.197-.474-.44-.474zm-3.97 2.368c0-.261.197-.473.44-.473h1.765c.244 0 .441.212.441.473 0 .262-.197.474-.44.474H5.202c-.244 0-.442-.212-.442-.474m3.97-.473c-.243 0-.44.212-.44.473 0 .262.197.474.44.474h1.765c.244 0 .441-.212.441-.474 0-.261-.197-.473-.44-.473z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBuildingOutline.displayName = 'BuildingOutline';
const Memo = memo(SvgBuildingOutline);
export default Memo;
