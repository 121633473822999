// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDrinkCupStrawOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.323 1.058a.5.5 0 0 1-.38.597l-1.956.434a.83.83 0 0 0-.652.814V4h3.122c.674 0 1.207.57 1.164 1.242l-.54 8.376a1.833 1.833 0 0 1-1.83 1.715h-4.5c-.966 0-1.767-.75-1.83-1.715l-.54-8.376A1.167 1.167 0 0 1 4.546 4h2.789V2.903c0-.86.596-1.603 1.435-1.79L10.726.68a.5.5 0 0 1 .597.38M4.546 5a.167.167 0 0 0-.167.177l.14 2.156h6.965l.14-2.156A.167.167 0 0 0 11.456 5zm6.873 3.333H4.583l.337 5.22c.028.44.392.78.831.78h4.5c.44 0 .804-.34.832-.78z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDrinkCupStrawOutline.displayName = 'DrinkCupStrawOutline';
const Memo = memo(SvgDrinkCupStrawOutline);
export default Memo;
