// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBirthdayCakeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.998.712a.5.5 0 0 1 .353.146l1.06 1.061A2 2 0 0 1 8.5 5.27V6h3.333c1.013 0 1.833.82 1.833 1.833v1.209c0 .386-.121.755-.337 1.06a1 1 0 0 1 .004.065v2.666c0 1.013-.82 1.834-1.833 1.834h-7a1.833 1.833 0 0 1-1.834-1.834v-2.666q0-.033.005-.065a1.83 1.83 0 0 1-.338-1.06V7.833C2.332 6.821 3.152 6 4.165 6H7.5v-.73a2 2 0 0 1-.915-3.351L7.643.86a.5.5 0 0 1 .354-.147M3.665 10.857v1.976c0 .46.373.834.834.834h7c.46 0 .833-.373.833-.834v-1.976l-.09.05a1.83 1.83 0 0 1-1.822-.038l-.326-.195a.83.83 0 0 0-.857 0l-.295.177a1.83 1.83 0 0 1-1.887 0l-.294-.177a.83.83 0 0 0-.858 0l-.326.195a1.83 1.83 0 0 1-1.821.038zM7.998 1.92l-.707.707a1 1 0 1 0 1.414 0zM4.165 7a.833.833 0 0 0-.833.833v1.209c0 .305.167.585.434.731l.469.256c.26.142.574.135.828-.017l.326-.196a1.83 1.83 0 0 1 1.886 0l.295.177a.83.83 0 0 0 .857 0l.295-.177a1.83 1.83 0 0 1 1.887 0l.326.196a.83.83 0 0 0 .828.017l.468-.256a.83.83 0 0 0 .434-.731V7.833A.833.833 0 0 0 11.832 7z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBirthdayCakeOutline.displayName = 'BirthdayCakeOutline';
const Memo = memo(SvgBirthdayCakeOutline);
export default Memo;
