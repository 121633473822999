// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgInfinityLoopBoomerangFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M1.053 5.41a3.565 3.565 0 0 1 5.107 0L8 7.287l1.84-1.875a3.565 3.565 0 0 1 5.107 0 3.71 3.71 0 0 1 0 5.179 3.565 3.565 0 0 1-5.107 0L8 8.714 6.16 10.59a3.565 3.565 0 0 1-5.107 0 3.71 3.71 0 0 1 0-5.178M7.3 8 5.446 6.111a2.565 2.565 0 0 0-3.68 0 2.71 2.71 0 0 0 0 3.778 2.565 2.565 0 0 0 3.68 0zm1.4 0 1.854 1.89a2.565 2.565 0 0 0 3.68 0 2.71 2.71 0 0 0 0-3.779 2.565 2.565 0 0 0-3.68 0z"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgInfinityLoopBoomerangFill.displayName = 'InfinityLoopBoomerangFill';
const Memo = memo(SvgInfinityLoopBoomerangFill);
export default Memo;
