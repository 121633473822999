// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAppleFruitOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.517 1.268a.5.5 0 0 1 .675-.21c.7.367 1.238.833 1.62 1.424a4.3 4.3 0 0 1 .579 1.42c1.882-.627 3.345-.29 4.32.721 1.024 1.064 1.38 2.75 1.27 4.366-.113 1.623-.705 3.307-1.744 4.42-.524.56-1.172.986-1.934 1.165-.71.167-1.484.113-2.304-.197-.82.31-1.594.364-2.303.197-.762-.18-1.41-.604-1.934-1.166-1.038-1.112-1.63-2.796-1.743-4.42-.11-1.614.246-3.301 1.27-4.365.927-.962 2.295-1.314 4.047-.805q-.136-.446-.362-.792C6.698 2.6 6.3 2.244 5.727 1.943a.5.5 0 0 1-.21-.675M5.925 13.6c.532.125 1.156.084 1.879-.223a.5.5 0 0 1 .39 0c.723.307 1.348.348 1.88.223.535-.127 1.017-.43 1.432-.875.842-.902 1.376-2.345 1.477-3.806.1-1.47-.243-2.824-.993-3.603-.701-.728-1.883-1.075-3.797-.271a.5.5 0 0 1-.387 0c-1.914-.804-3.096-.457-3.796.27-.75.78-1.094 2.134-.993 3.604.1 1.461.634 2.905 1.476 3.806.416.445.897.749 1.432.874"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAppleFruitOutline.displayName = 'AppleFruitOutline';
const Memo = memo(SvgAppleFruitOutline);
export default Memo;
