// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbleMessageLikeHeartOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v7c0 1.013-.82 1.834-1.833 1.834H10.25a.17.17 0 0 0-.107.038l-1.399 1.159a1.17 1.17 0 0 1-1.493-.004l-1.377-1.154a.17.17 0 0 0-.107-.04H3.833A1.833 1.833 0 0 1 2 10.834zM3.833 3A.833.833 0 0 0 3 3.833v7c0 .46.373.834.833.834h1.935c.274 0 .54.096.75.272l1.376 1.154a.17.17 0 0 0 .213 0l1.4-1.158c.209-.174.472-.268.744-.268h1.916c.46 0 .833-.373.833-.834v-7A.833.833 0 0 0 12.167 3zm2.74 3.37c-.126.134-.24.367-.24.742 0 .457.341.93.834 1.338A4.5 4.5 0 0 0 8 8.99q.071-.033.172-.089a4.5 4.5 0 0 0 .661-.451c.493-.407.835-.88.835-1.338 0-.375-.115-.608-.242-.742a.63.63 0 0 0-.463-.203.97.97 0 0 0-.613.218l-.001.001a.5.5 0 0 1-.698 0l-.025-.02a.973.973 0 0 0-.59-.199.63.63 0 0 0-.462.203M8 5.417l-.016-.009a2 2 0 0 0-.947-.241c-.436 0-.87.174-1.193.519-.325.347-.512.837-.512 1.426 0 .927.652 1.658 1.198 2.109.286.236.578.424.812.555q.178.098.316.157.068.03.135.051c.03.01.112.036.207.036s.178-.026.206-.035q.068-.023.136-.052.137-.059.316-.157a5.5 5.5 0 0 0 .812-.555c.546-.451 1.197-1.182 1.197-2.11 0-.588-.186-1.078-.511-1.425a1.63 1.63 0 0 0-1.193-.52A2 2 0 0 0 8 5.418m.096 3.615"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubbleMessageLikeHeartOutline.displayName = 'BubbleMessageLikeHeartOutline';
const Memo = memo(SvgBubbleMessageLikeHeartOutline);
export default Memo;
