// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBookmarkDeleteRemoveBannerFlagTagFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      fill={color}
      viewBox="0 0 17 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.725 1.02c-.363 0-.666 0-.914.02a1.9 1.9 0 0 0-.73.184 1.87 1.87 0 0 0-.817.817 1.9 1.9 0 0 0-.184.73c-.02.248-.02.55-.02.914v8.886c0 .315 0 .586.02.8.019.214.064.465.224.675.213.279.538.45.888.466.264.013.496-.092.683-.198.187-.105.41-.26.67-.438l2.009-1.385c.48-.331.613-.413.742-.445a.85.85 0 0 1 .407 0c.129.032.262.114.742.445l2.008 1.385c.26.179.483.333.67.438.188.106.42.211.684.198a1.19 1.19 0 0 0 .888-.466c.16-.21.205-.46.224-.674s.02-.486.02-.801V3.685c0-.363 0-.666-.02-.914a1.9 1.9 0 0 0-.184-.73 1.87 1.87 0 0 0-.817-.817 1.9 1.9 0 0 0-.73-.184c-.248-.02-.551-.02-.914-.02zm4.698 3.52c.199.199.199.521 0 .72L9.22 6.464l1.201 1.202a.51.51 0 1 1-.72.72L8.498 7.185 7.297 8.386a.51.51 0 0 1-.72-.721l1.201-1.202L6.576 5.26a.51.51 0 1 1 .721-.722L8.5 5.741 9.701 4.54c.2-.199.522-.199.721 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBookmarkDeleteRemoveBannerFlagTagFill.displayName = 'BookmarkDeleteRemoveBannerFlagTagFill';
const Memo = memo(SvgBookmarkDeleteRemoveBannerFlagTagFill);
export default Memo;
