// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShipChangelogFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m12.664 12.664 1.607.347a.5.5 0 1 1-.211.978l-2.802-.606a.8.8 0 0 0-.352 0l-2.52.545a1.8 1.8 0 0 1-.775 0l-2.52-.545a.8.8 0 0 0-.352 0l-2.801.606a.5.5 0 0 1-.212-.978l1.608-.347c-.774-1.195-2.11-3.364.225-3.932l4.006-.975c.285-.069.582-.069.867 0l4.007.975c2.335.568.998 2.737.225 3.932" />
      <Path d="M5.999 2.333V4H4.332a1 1 0 0 0-1 1v2.758l3.997-.972c.44-.107.9-.107 1.34 0l3.996.972V5a1 1 0 0 0-1-1H10V2.333a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1" />
    </Svg>
  );
};
SvgShipChangelogFill.displayName = 'ShipChangelogFill';
const Memo = memo(SvgShipChangelogFill);
export default Memo;
