// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSeaViewFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 1.001c.298 0 .54.269.54.6v1.8c0 .331-.242.6-.54.6-.297 0-.538-.269-.538-.6v-1.8c0-.331.241-.6.539-.6m-3.959 7H1.437c-.241 0-.437.224-.437.5s.196.5.438.5h13.124c.242 0 .438-.224.438-.5s-.196-.5-.437-.5h-2.604c-.123-.743-.516-1.438-1.13-1.975C10.078 5.37 9.06 5.001 8 5.001c-1.06 0-2.078.369-2.828 1.025-.615.537-1.008 1.232-1.131 1.975m-1.516 3c-.248 0-.45.224-.45.5s.202.5.45.5h10.947c.248 0 .45-.224.45-.5s-.202-.5-.45-.5zm2.786 3.5c0-.276.22-.5.49-.5h4.405c.27 0 .49.224.49.5s-.22.5-.49.5H5.801a.495.495 0 0 1-.49-.5m8.463-10.672a.46.46 0 0 0 0-.686.55.55 0 0 0-.739 0l-1.108 1.03a.46.46 0 0 0 0 .686c.204.19.534.19.739 0zM2.23 3.143a.55.55 0 0 1 .739 0l1.108 1.03a.46.46 0 0 1 0 .686.55.55 0 0 1-.739 0L2.23 3.829a.46.46 0 0 1 0-.686"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSeaViewFill.displayName = 'SeaViewFill';
const Memo = memo(SvgSeaViewFill);
export default Memo;
