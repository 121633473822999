// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPlusLinesAddDocumentListPageFileOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 2.334a.833.833 0 0 0-.833.833v9.667c0 .46.373.833.833.833h3a.5.5 0 0 1 0 1h-3a1.833 1.833 0 0 1-1.833-1.833V3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v5a.5.5 0 1 1-1 0v-5a.833.833 0 0 0-.834-.833zM5.335 4.5a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5H7.5a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m6.166 2.5a.5.5 0 0 1 .5.5v1.5h1.5a.5.5 0 0 1 0 1h-1.5v1.5a.5.5 0 1 1-1 0v-1.5h-1.5a.5.5 0 1 1 0-1h1.5v-1.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPlusLinesAddDocumentListPageFileOutline.displayName = 'PlusLinesAddDocumentListPageFileOutline';
const Memo = memo(SvgPlusLinesAddDocumentListPageFileOutline);
export default Memo;
