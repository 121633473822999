// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCursorClickArrowClickbaitFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.332 1.334a.5.5 0 0 1 .5.5V3a.5.5 0 1 1-1 0V1.834a.5.5 0 0 1 .5-.5M11.624 3.042a.5.5 0 0 1 0 .707l-.874.874a.5.5 0 1 1-.707-.707l.873-.874a.5.5 0 0 1 .707 0M4.621 10.044a.5.5 0 0 1 0 .707l-.873.874a.5.5 0 0 1-.707-.707l.873-.874a.5.5 0 0 1 .707 0M3.499 7.334a.5.5 0 0 1-.5.5H1.832a.5.5 0 0 1 0-1h1.167a.5.5 0 0 1 .5.5M4.621 4.623a.5.5 0 0 1-.707 0l-.873-.874a.5.5 0 1 1 .707-.707l.873.874a.5.5 0 0 1 0 .707"
        clipRule="evenodd"
      />
      <Path d="M7.542 6.31a.833.833 0 0 0-1.09 1.062l2.499 6.8a.833.833 0 0 0 1.503.13l1.41-2.436 2.456-1.421a.833.833 0 0 0-.11-1.496z" />
    </Svg>
  );
};
SvgCursorClickArrowClickbaitFill.displayName = 'CursorClickArrowClickbaitFill';
const Memo = memo(SvgCursorClickArrowClickbaitFill);
export default Memo;
