import React, {type FC, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';

import {useHive, withStaticProperties} from '@propertyguru/hive';
import {Surface} from '@propertyguru/hive-surface';
import {Text} from '@propertyguru/hive-text';

import {borderColor, surfaceColor, textColor} from './chip-colors';
import type {ChipProps} from './types';

const MAX_COUNT_BADGE_LIMIT = 99;
const MAX_VALUE_LIMIT = 20;
const MAX_VALUE_LENGTH = 17;

const formatValue = (label: string) => {
  if (label?.length > MAX_VALUE_LIMIT) {
    return `${label.substring(0, MAX_VALUE_LENGTH)}...`;
  }

  return label;
};

const Count: FC<{count: number}> = ({count}) => {
  const hive = useHive();

  // If count is more than 99, show 99+
  const getCount = useMemo(() => {
    if (count > MAX_COUNT_BADGE_LIMIT) {
      return `${MAX_COUNT_BADGE_LIMIT}+`;
    }
    return count.toString();
  }, [count]);

  return (
    <View
      style={[
        {
          backgroundColor: hive.color('fill/active/primary'),
          borderRadius: hive.borderRadius('full'),
          paddingHorizontal: hive.spacing('x1'),
          minWidth: hive.spacing('x4'),
        },
        styles.count,
      ]}
    >
      <Text
        typography={'caption/xs'}
        color={'text/static/primary'}
      >
        {getCount}
      </Text>
    </View>
  );
};

export const BaseChip: React.FC<ChipProps> = ({children, style, text, active, ...props}) => {
  const {spacing} = useHive();
  const valueText = formatValue(text);

  return (
    <Surface
      style={[
        {
          paddingVertical: spacing('x2'),
          paddingHorizontal: spacing('x4'),
        },
        style,
      ]}
      color={(state) => surfaceColor(state, active)}
      borderColor={(state) => borderColor(state, active)}
      borderRadius={'full'}
      borderWidth={1}
      {...props}
    >
      {(state) => (
        <View style={[styles.item, {gap: spacing('x2')}]}>
          <Text
            typography={'body/xs'}
            color={textColor(state, active)}
          >
            {valueText}
          </Text>
          {children}
        </View>
      )}
    </Surface>
  );
};

export const Chip = withStaticProperties(BaseChip, {
  Count,
});

Chip.displayName = 'Chip';
Chip.Count.displayName = 'Chip.Count';

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  count: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
