// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUndockBoxArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.707 3h3.126a.5.5 0 1 0 0-1H3a1 1 0 0 0-1 1v3.833a.5.5 0 1 0 1 0V3.707l3.48 3.48a.5.5 0 0 0 .707-.707zm4.96 1.5a.5.5 0 0 1 .5-.5h3.666c1.013 0 1.834.82 1.834 1.833v7c0 1.013-.821 1.834-1.834 1.834h-7A1.833 1.833 0 0 1 4 12.833V9.167a.5.5 0 1 1 1 0v3.666c0 .46.373.834.833.834h7c.46 0 .834-.373.834-.834v-7A.833.833 0 0 0 12.833 5H9.167a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUndockBoxArrowFill.displayName = 'UndockBoxArrowFill';
const Memo = memo(SvgUndockBoxArrowFill);
export default Memo;
