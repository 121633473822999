// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayers3StackOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.058 3.06a.17.17 0 0 0-.12 0L2.454 5.177a.167.167 0 0 0 0 .31l5.484 2.12a.17.17 0 0 0 .12 0l5.483-2.12a.167.167 0 0 0 0-.31zm-.48-.933c.27-.105.57-.105.84 0l5.484 2.118c.995.385.995 1.792 0 2.177L8.418 8.54c-.27.105-.57.105-.84 0L2.093 6.422c-.995-.385-.995-1.792 0-2.177z"
        clipRule="evenodd"
      />
      <Path d="m13.902 9.088-5.484 2.119c-.27.104-.57.104-.84 0L2.093 9.088a1.16 1.16 0 0 1-.719-1.344l6.535 2.525a.25.25 0 0 0 .178 0l6.533-2.524a1.16 1.16 0 0 1-.72 1.343" />
      <Path d="M13.902 11.755 8.42 13.874c-.27.104-.57.104-.841 0l-5.484-2.119a1.16 1.16 0 0 1-.718-1.344l6.534 2.524a.25.25 0 0 0 .178 0l6.533-2.524a1.16 1.16 0 0 1-.719 1.344" />
    </Svg>
  );
};
SvgLayers3StackOutline.displayName = 'Layers3StackOutline';
const Memo = memo(SvgLayers3StackOutline);
export default Memo;
