// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowTriangleRightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.87 3.756a.83.83 0 0 0-1.202.743V11.5a.83.83 0 0 0 1.202.743l7.006-3.501a.83.83 0 0 0 0-1.486zM2.668 4.5a1.83 1.83 0 0 1 2.65-1.637l7.005 3.5c1.349.675 1.349 2.6 0 3.275l-7.006 3.501a1.83 1.83 0 0 1-2.649-1.637z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowTriangleRightOutline.displayName = 'ArrowTriangleRightOutline';
const Memo = memo(SvgArrowTriangleRightOutline);
export default Memo;
