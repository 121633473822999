// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBrackets2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.665 3.833C2.665 2.821 3.486 2 4.5 2h1a.5.5 0 0 1 0 1h-1a.833.833 0 0 0-.834.833v2.834c0 .525-.22.999-.575 1.333.354.334.575.808.575 1.333v2.834c0 .46.373.833.834.833h1a.5.5 0 0 1 0 1h-1a1.833 1.833 0 0 1-1.834-1.833V9.333a.833.833 0 0 0-.833-.833.5.5 0 0 1 0-1c.46 0 .833-.373.833-.833zM10 2.5a.5.5 0 0 1 .5-.5h1c1.012 0 1.833.82 1.833 1.833v2.834c0 .46.373.833.833.833a.5.5 0 0 1 0 1 .833.833 0 0 0-.833.833v2.834c0 1.012-.82 1.833-1.833 1.833h-1a.5.5 0 0 1 0-1h1c.46 0 .833-.373.833-.833V9.333c0-.525.221-.999.575-1.333a1.83 1.83 0 0 1-.575-1.333V3.833A.833.833 0 0 0 11.499 3h-1a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBrackets2Outline.displayName = 'Brackets2Outline';
const Memo = memo(SvgBrackets2Outline);
export default Memo;
