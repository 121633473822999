// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFeaturesToTheMoonFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.998 1.504a1.166 1.166 0 0 1 1.998 0l.874 1.452a.17.17 0 0 0 .105.077l1.651.382a1.166 1.166 0 0 1 .618 1.9l-1.111 1.28a.17.17 0 0 0-.04.124l.146 1.688a1.166 1.166 0 0 1-1.617 1.175l-1.56-.661a.17.17 0 0 0-.13 0l-1.56.661a1.166 1.166 0 0 1-1.617-1.175L7.9 6.72a.17.17 0 0 0-.04-.124l-1.11-1.28a1.166 1.166 0 0 1 .617-1.9l1.65-.382a.17.17 0 0 0 .106-.077zm-4.146 5.62a.5.5 0 0 1 0 .707L2.853 10.83a.5.5 0 1 1-.707-.707l3-2.999a.5.5 0 0 1 .706 0m1.333 3.332a.5.5 0 0 1 0 .707l-2.999 3a.5.5 0 1 1-.707-.708l3-2.999a.5.5 0 0 1 .706 0m4.665 0a.5.5 0 0 1 0 .707l-2.999 3a.5.5 0 1 1-.707-.708l3-2.999a.5.5 0 0 1 .706 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFeaturesToTheMoonFill.displayName = 'FeaturesToTheMoonFill';
const Memo = memo(SvgFeaturesToTheMoonFill);
export default Memo;
