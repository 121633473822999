// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDateTimeCalendarTimeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.406 3.287A1.833 1.833 0 0 0 .919 5.411l.164.93 5.54-.977a5.65 5.65 0 0 1 4.143-1.696 1.833 1.833 0 0 0-2.123-1.48zM5.739 6.536a5.64 5.64 0 0 0-.738 2.797 5.64 5.64 0 0 0 .762 2.84l-1.736.306a1.833 1.833 0 0 1-2.124-1.487l-.647-3.666z" />
      <Path
        fillRule="evenodd"
        d="M6.001 9.333a4.667 4.667 0 1 1 9.334 0 4.667 4.667 0 0 1-9.334 0m4.667-2a.5.5 0 0 1 .5.5v1.293l1.187 1.187a.5.5 0 1 1-.707.707l-1.333-1.333a.5.5 0 0 1-.147-.354v-1.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDateTimeCalendarTimeFill.displayName = 'DateTimeCalendarTimeFill';
const Memo = memo(SvgDateTimeCalendarTimeFill);
export default Memo;
