// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStoveFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.997 1.983a1.244 1.244 0 0 0-1.244 1.244v.087h2.488v-.087a1.244 1.244 0 0 0-1.244-1.244M5.77 3.227v.087H4.095a.492.492 0 0 0 0 .983h.379V5H3.362c-.2 0-.362.224-.362.5 0 .277.162.5.362.5h1.112v3.591a1.07 1.07 0 0 0 1.07 1.07h4.917a1.07 1.07 0 0 0 1.07-1.07v-3.59h1.107c.2 0 .362-.224.362-.5 0-.277-.162-.5-.362-.5h-1.106v-.704h.374a.492.492 0 1 0 0-.983h-1.682v-.087a2.227 2.227 0 1 0-4.454 0m2.232 8.185a1.07 1.07 0 0 0-.91.509l-.634 1.142h-.013a.99.99 0 0 0-.894-.44.97.97 0 0 0-.798.483l-.651 1.162a.492.492 0 0 0 .858.48l.64-1.142.023-.001.002.002q.017.03.037.055a.99.99 0 0 0 .845.383.97.97 0 0 0 .797-.48l.005-.01.628-1.13a.09.09 0 0 1 .106-.02q.013.009.024.02l.627 1.13.006.01a.97.97 0 0 0 .797.48.98.98 0 0 0 .845-.383 1 1 0 0 0 .039-.057h.023l.64 1.144a.492.492 0 0 0 .858-.48l-.648-1.158-.003-.005a.97.97 0 0 0-.798-.483.99.99 0 0 0-.894.44h-.013l-.634-1.142-.022-.036a1.07 1.07 0 0 0-.888-.473"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStoveFill.displayName = 'StoveFill';
const Memo = memo(SvgStoveFill);
export default Memo;
