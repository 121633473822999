// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGolfBallOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.165 8.5a.667.667 0 0 0 .65-.816.667.667 0 1 1-.8.8.7.7 0 0 0 .15.016M6.482 8.167a.667.667 0 0 0 .65-.817.667.667 0 1 1-.8.8.7.7 0 0 0 .15.017M7.815 6.834a.667.667 0 0 0 .65-.817.667.667 0 1 1-.8.8.7.7 0 0 0 .15.017M9.165 5.517a.667.667 0 0 0 .65-.816.667.667 0 1 1-.8.8.7.7 0 0 0 .15.016M9.482 7.167a.667.667 0 0 0 .65-.817.667.667 0 1 1-.8.8.7.7 0 0 0 .15.017" />
      <Path
        fillRule="evenodd"
        d="M7.875 2.975a3.9 3.9 0 1 0 0 7.8 3.9 3.9 0 0 0 0-7.8M3 6.875a4.875 4.875 0 1 1 9.75 0 4.875 4.875 0 0 1-9.75 0m2.275 5.363c0-.27.218-.488.487-.488.885 0 1.647.46 2.113 1.153.466-.692 1.228-1.153 2.113-1.153a.488.488 0 0 1 0 .975c-.865 0-1.625.766-1.625 1.787a.488.488 0 0 1-.975 0c0-1.021-.761-1.787-1.625-1.787a.49.49 0 0 1-.488-.487"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGolfBallOutline.displayName = 'GolfBallOutline';
const Memo = memo(SvgGolfBallOutline);
export default Memo;
