// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleShadowUserAvatarGroupFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9 4.167a2.833 2.833 0 1 0-5.666 0 2.833 2.833 0 0 0 5.666 0M11.834 4.167c0-1.013-.821-1.834-1.834-1.834a.5.5 0 0 1 0-1A2.833 2.833 0 0 1 10 7a.5.5 0 0 1 0-1c1.013 0 1.834-.82 1.834-1.833M11.628 13.422c-.317.35-.792.557-1.294.557H2c-.502 0-.977-.207-1.294-.557a1.42 1.42 0 0 1-.313-1.405C1.11 9.73 3.439 8 6.167 8s5.056 1.73 5.774 4.017a1.42 1.42 0 0 1-.313 1.405M14.167 14a1.75 1.75 0 0 0 1.294-.557c.325-.36.48-.87.31-1.406-.502-1.581-1.784-2.917-3.437-3.502a.5.5 0 0 0-.334.942c1.364.483 2.412 1.586 2.818 2.862a.42.42 0 0 1-.098.433.75.75 0 0 1-.553.228h-.333a.5.5 0 1 0 0 1z" />
    </Svg>
  );
};
SvgPeopleShadowUserAvatarGroupFill.displayName = 'PeopleShadowUserAvatarGroupFill';
const Memo = memo(SvgPeopleShadowUserAvatarGroupFill);
export default Memo;
