// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVolleyballCourtOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.342 2a1.537 1.537 0 1 0 0 3.075 1.537 1.537 0 0 0 0-3.075m-.266.896a.694.694 0 1 1 .531 1.282.694.694 0 0 1-.53-1.282M2.865 6.584a.07.07 0 0 1 .052-.022h1.685V7.95H2.843V6.636q0-.03.022-.052m1.737 2.21H2.843v1.388h1.759zm.843 1.388V8.793h2.133v1.389zm2.976 0V8.793h2.134v1.389zm2.977 0V8.793h1.759v1.389zm0-2.232h1.759V6.636a.074.074 0 0 0-.074-.074h-1.685zm-.422-2.231H2.917A.917.917 0 0 0 2 6.636v6.943a.421.421 0 1 0 .843 0v-2.554h10.313v2.554a.422.422 0 0 0 .843 0v-2.95l.001-.026V6.636a.917.917 0 0 0-.917-.917zm-.421.843V7.95H8.42V6.562zM7.578 7.95V6.562H5.445V7.95z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVolleyballCourtOutline.displayName = 'VolleyballCourtOutline';
const Memo = memo(SvgVolleyballCourtOutline);
export default Memo;
