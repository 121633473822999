// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDisk1SaveOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h.845a1 1 0 0 1-.011-.167V9.167C4.667 8.522 5.189 8 5.833 8h4.334c.644 0 1.166.522 1.166 1.167v3.666q0 .086-.011.167h.845c.46 0 .833-.373.833-.833V5.052a.83.83 0 0 0-.244-.589l-1.219-1.219a.8.8 0 0 0-.204-.15v1.74c0 .644-.522 1.166-1.166 1.166H5.833a1.167 1.167 0 0 1-1.166-1.167V3zm0-1C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h8.334C13.179 14 14 13.18 14 12.167V5.052c0-.486-.193-.952-.537-1.296l-1.219-1.219A1.83 1.83 0 0 0 10.948 2zm1.834 1v1.833c0 .092.074.167.166.167h4.334a.167.167 0 0 0 .166-.167V3zm4.5 10a.167.167 0 0 0 .166-.167V9.167A.167.167 0 0 0 10.167 9H5.833a.167.167 0 0 0-.166.167v3.666c0 .092.074.167.166.167z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDisk1SaveOutline.displayName = 'Disk1SaveOutline';
const Memo = memo(SvgDisk1SaveOutline);
export default Memo;
