// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPenthouseOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4 1.501C4 1.259 4.236 1 4.5 1h10.05a.46.46 0 0 1 .453.467v13.066c0 .243-.24.468-.503.468-.264 0-.5-.225-.5-.468V6.001H3v8.532c0 .243-.236.468-.5.468s-.5-.225-.5-.468V6.001h-.547A.46.46 0 0 1 1 5.534l.003-2.066A.46.46 0 0 1 1.456 3H7v-1H4.5c-.264 0-.5-.258-.5-.5m4 .5v3h6v-3zm-6 2v1h1v-1zm2 0v1h1v-1zm2 0v1h1v-1z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7 8.001H5v1h2zm-2-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zM7 12.001H5v1h2zm-2-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zM12 8.001h-2v1h2zm-2-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zM12 12.001h-2v1h2zm-2-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1z"
        clipRule="evenodd"
      />
      <Path d="M9 2.001h1v3H9zM12 2.001h1v3h-1z" />
    </Svg>
  );
};
SvgPenthouseOutline.displayName = 'PenthouseOutline';
const Memo = memo(SvgPenthouseOutline);
export default Memo;
