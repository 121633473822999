// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgOculusFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.308 5.688c.606-1.377 1.966-2.355 3.574-2.355h6.233c1.608 0 2.968.978 3.575 2.355l.119-.122a.5.5 0 1 1 .713.701l-.523.532c.182 1.24-.02 2.624-.615 3.737-.645 1.207-1.777 2.13-3.383 2.13H4.996c-1.605 0-2.737-.923-3.383-2.13C1.02 9.423.816 8.04.998 6.799l-.523-.532a.5.5 0 1 1 .714-.7zm5.857-.355a.5.5 0 0 0 0 1h1.667a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgOculusFill.displayName = 'OculusFill';
const Memo = memo(SvgOculusFill);
export default Memo;
