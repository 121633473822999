// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStorageHddSsdFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.668 11.333a.667.667 0 1 0 0 1.334.667.667 0 0 0 0-1.334M8.001 12a.667.667 0 1 1 1.334 0A.667.667 0 0 1 8 12" />
      <Path
        fillRule="evenodd"
        d="M4.501 1.333c-1.012 0-1.833.821-1.833 1.834v9.666c0 1.013.82 1.834 1.833 1.834h7c1.013 0 1.834-.821 1.834-1.834V3.167c0-1.013-.821-1.834-1.834-1.834zm-.833 11.5v-1.666c0-.46.373-.834.833-.834h7c.46 0 .834.373.834.834v1.666c0 .46-.374.834-.834.834h-7a.833.833 0 0 1-.833-.834"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStorageHddSsdFill.displayName = 'StorageHddSsdFill';
const Memo = memo(SvgStorageHddSsdFill);
export default Memo;
