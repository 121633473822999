// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgReceptionServicesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.455 1.577a.83.83 0 0 1 .59-.244H6.96a.833.833 0 0 1 .833.833v1.518a.4.4 0 0 1-.008.132 2.292 2.292 0 0 1-4.575-.191V2.166c0-.22.088-.433.244-.589m3.506.59V3.17A6 6 0 0 0 5.505 3a6 6 0 0 0-1.46.172V2.166zM4.47 4.655a1.46 1.46 0 0 1-.373-.638c.457-.125.93-.187 1.405-.185h.003c.474-.002.945.06 1.401.184a1.458 1.458 0 0 1-2.436.639m1.034 1.26a3.75 3.75 0 0 1 3.682 2.917h.11a2.5 2.5 0 0 1 2.039-1.994v-.506h-.417a.417.417 0 0 1 0-.833h1.667a.417.417 0 1 1 0 .833h-.417v.506a2.5 2.5 0 0 1 2.08 2.309l.054.051c.235.235.366.552.366.884v4.167c0 .23-.186.416-.416.416h-12.5a.417.417 0 0 1-.417-.416v-4.167a1.25 1.25 0 0 1 .43-.944 3.75 3.75 0 0 1 3.739-3.223m7.847 2.917a1.7 1.7 0 0 0-.39-.675 1.668 1.668 0 0 0-2.809.675zm-10.998.904.037-.022a.4.4 0 0 1 .195-.049h10.833a.417.417 0 0 1 .417.417v3.75H2.169v-3.75a.42.42 0 0 1 .185-.346m5.972-.904H2.684A2.92 2.92 0 0 1 5.502 6.75h.006a2.92 2.92 0 0 1 2.818 2.083"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgReceptionServicesOutline.displayName = 'ReceptionServicesOutline';
const Memo = memo(SvgReceptionServicesOutline);
export default Memo;
