// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSort1SwitchVerticalFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.01 2.285a1.167 1.167 0 0 1 1.65 0L7.855 4.48a.5.5 0 0 1-.707.707L5.335 3.374V13.5a.5.5 0 0 1-1 0V3.374L2.522 5.187a.5.5 0 1 1-.708-.707zl.353.353zM11.168 2a.5.5 0 0 1 .5.5v10.126l1.813-1.813a.5.5 0 1 1 .707.707l-2.195 2.196a1.166 1.166 0 0 1-1.65 0L8.148 11.52a.5.5 0 1 1 .707-.707l1.813 1.813V2.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSort1SwitchVerticalFill.displayName = 'Sort1SwitchVerticalFill';
const Memo = memo(SvgSort1SwitchVerticalFill);
export default Memo;
