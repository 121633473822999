// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAiAvatarGeneratedAvatarProfileAiMagicAvatarFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.114.943a.5.5 0 0 0-.895 0l-.647 1.295-1.296.648a.5.5 0 0 0 0 .895l1.296.647.648 1.296a.5.5 0 0 0 .894 0l.648-1.296 1.295-.647a.5.5 0 0 0 0-.895l-1.295-.648z" />
      <Path
        fillRule="evenodd"
        d="M3 3.833c0-.46.373-.833.833-.833H7.5a.5.5 0 0 0 0-1H3.833C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h8.334C13.179 14 14 13.18 14 12.167V8.5a.5.5 0 0 0-1 0v3.667c0 .46-.373.833-.833.833h-.56a3.668 3.668 0 0 0-7.213 0h-.56A.833.833 0 0 1 3 12.167z"
        clipRule="evenodd"
      />
      <Path d="M8 5a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
    </Svg>
  );
};
SvgAiAvatarGeneratedAvatarProfileAiMagicAvatarFill.displayName =
  'AiAvatarGeneratedAvatarProfileAiMagicAvatarFill';
const Memo = memo(SvgAiAvatarGeneratedAvatarProfileAiMagicAvatarFill);
export default Memo;
