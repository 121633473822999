// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShaka2CallMeHangTenFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.368 8.635a1.546 1.546 0 0 1-2.312-.06 1.547 1.547 0 0 1-2.367 0c-.285.336-.709.55-1.183.55-.781 0-1.425-.579-1.539-1.33l-.01.002-1.17-5.182v-.008A.736.736 0 0 0 2.064 2a.74.74 0 0 0-.731.795l.483 7.101C1.975 12.21 3.877 14 6.167 14h1.497a5.13 5.13 0 0 0 4.117-2.08l2.538-3.418c.198-.268.173-.653-.121-.808a1.97 1.97 0 0 0-1.86.012c-.087.046-.171.117-.265.213zm-4.305-1.08c0 .455.364.82.81.82.444 0 .809-.365.809-.82V5.563a.815.815 0 0 0-.81-.82.815.815 0 0 0-.81.82zm2.366 0c0 .455.365.82.81.82s.81-.365.81-.82V5.96a.815.815 0 0 0-.81-.82.815.815 0 0 0-.81.82zm-3.114-2.39a.815.815 0 0 0-.81-.821.815.815 0 0 0-.809.82v2.39c0 .456.365.821.81.821s.81-.365.81-.82z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShaka2CallMeHangTenFill.displayName = 'Shaka2CallMeHangTenFill';
const Memo = memo(SvgShaka2CallMeHangTenFill);
export default Memo;
