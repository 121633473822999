// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMapFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill={color}
      viewBox="0 0 18 18"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M16.252 3.628c.162.241.248.526.248.818h-.006v6.415a.5.5 0 0 1-.03.168q.004.075.014.15c.008.065.016.13.016.197 0 1.246-1.376 3.266-2.535 4.748q-.14.175-.347.279a1.01 1.01 0 0 1-.874 0 1 1 0 0 1-.341-.28c-.713-.917-1.513-2.039-2.02-3.061L7.22 14.394q-.272.105-.551.106-.279-.001-.54-.106l-3.719-1.487a1.43 1.43 0 0 1-.663-.533 1.48 1.48 0 0 1-.248-.819V2.649c0-.16.037-.322.112-.458a.98.98 0 0 1 .768-.527.96.96 0 0 1 .477.062l3.67 1.469q.075.028.149.03.075-.002.148-.03l3.961-1.587a1.41 1.41 0 0 1 1.079 0l3.725 1.481c.267.112.502.298.663.54m-.874.595a.43.43 0 0 0-.186-.15l-3.34-1.338v5.603a3.3 3.3 0 0 1 1.332-.285c.85 0 1.649.329 2.263.905V4.446a.4.4 0 0 0-.069-.223m-8.175-.05v9.08l2.789-1.177a2.6 2.6 0 0 1-.124-.707c0-.861.335-1.673.93-2.293V2.735L7.215 4.173zm6.74 7.203a.756.756 0 1 1-1.513 0 .756.756 0 0 1 1.512 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMapFill.displayName = 'MapFill';
const Memo = memo(SvgMapFill);
export default Memo;
