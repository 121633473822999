// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronLargeLeftChevLeftFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.755 1.404a.5.5 0 0 1 .174.685L6.668 7.574l-.43-.255.43.255a.83.83 0 0 0 0 .852l3.261 5.485a.5.5 0 1 1-.86.511L5.81 8.937a1.83 1.83 0 0 1 0-1.874l3.26-5.485a.5.5 0 0 1 .686-.174"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronLargeLeftChevLeftFill.displayName = 'ChevronLargeLeftChevLeftFill';
const Memo = memo(SvgChevronLargeLeftChevLeftFill);
export default Memo;
