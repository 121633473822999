// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClockAlertTimerFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M.982 3.146a.5.5 0 1 0 .707.708l2-2a.5.5 0 1 0-.707-.708zM13.023 1.146a.5.5 0 1 0-.707.708l2 2a.5.5 0 1 0 .707-.708z" />
      <Path
        fillRule="evenodd"
        d="M14.67 8A6.667 6.667 0 1 1 1.335 8a6.667 6.667 0 0 1 13.333 0M8.002 4.667a.5.5 0 0 1 .5.5v2.626l1.686 1.687a.5.5 0 1 1-.707.707L7.65 8.354A.5.5 0 0 1 7.503 8V5.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClockAlertTimerFill.displayName = 'ClockAlertTimerFill';
const Memo = memo(SvgClockAlertTimerFill);
export default Memo;
