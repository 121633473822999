// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAmericanFootballNflOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 9.833a8.5 8.5 0 0 1 8.5-8.5h3c1.013 0 1.833.821 1.833 1.834v3a8.5 8.5 0 0 1-8.5 8.5h-3a1.833 1.833 0 0 1-1.833-1.834zm1.001-.125-.001.125v3c0 .46.373.834.833.834h3l.126-.001zm.131-1.283 5.11 5.11a7.51 7.51 0 0 0 5.96-5.96l-5.11-5.11a7.51 7.51 0 0 0-5.96 5.96m7.243-6.09 3.957 3.957.001-.125v-3a.833.833 0 0 0-.833-.834h-3zm-.021 3.978a.5.5 0 0 1 0 .707L7.019 9.687a.5.5 0 0 1-.707-.707l2.666-2.667a.5.5 0 0 1 .708 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAmericanFootballNflOutline.displayName = 'AmericanFootballNflOutline';
const Memo = memo(SvgAmericanFootballNflOutline);
export default Memo;
