// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalendarTimeClockOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.667 2.667v-.834a.5.5 0 0 0-1 0v.834h-.834C2.821 2.667 2 3.487 2 4.5v7.667C2 13.179 2.82 14 3.833 14h2a.5.5 0 1 0 0-1h-2A.833.833 0 0 1 3 12.167V4.5c0-.46.373-.833.833-.833h8.334c.46 0 .833.373.833.833v1.333a.5.5 0 0 0 1 0V4.5c0-1.012-.82-1.833-1.833-1.833h-.834v-.834a.5.5 0 1 0-1 0v.834zm1.51 10.388a4.5 4.5 0 1 1 8.314-3.442 4.5 4.5 0 0 1-8.315 3.442m7.389-3.06a3.499 3.499 0 1 0-6.465 2.676 3.499 3.499 0 0 0 6.465-2.676"
        clipRule="evenodd"
      />
      <Path d="M11.336 9.333a.5.5 0 0 1 .5.5v1.293l1.02 1.02a.5.5 0 0 1-.707.708l-1.167-1.167a.5.5 0 0 1-.146-.354v-1.5a.5.5 0 0 1 .5-.5" />
    </Svg>
  );
};
SvgCalendarTimeClockOutline.displayName = 'CalendarTimeClockOutline';
const Memo = memo(SvgCalendarTimeClockOutline);
export default Memo;
