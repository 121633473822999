// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClockSnoozeTimerSnoozeZzFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.503.667a.5.5 0 0 0 0 1h1.31L12.109 3.86a.5.5 0 0 0 .395.807h2.333a.5.5 0 1 0 0-1h-1.311l1.706-2.193a.5.5 0 0 0-.395-.807z" />
      <Path
        fillRule="evenodd"
        d="M10.264 1.727c.25.09.405.332.405.597v2.343c0 .736.597 1.333 1.334 1.333h1.872c.29 0 .552.188.618.472q.174.737.176 1.528a6.667 6.667 0 1 1-4.405-6.273M8.503 5.333a.5.5 0 1 0-1 0V8a.5.5 0 0 0 .146.354l1.667 1.666a.5.5 0 1 0 .707-.707l-1.52-1.52z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClockSnoozeTimerSnoozeZzFill.displayName = 'ClockSnoozeTimerSnoozeZzFill';
const Memo = memo(SvgClockSnoozeTimerSnoozeZzFill);
export default Memo;
