// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRewindSymbolOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.84 4.448a.167.167 0 0 0-.276-.126L2.418 7.873a.167.167 0 0 0 0 .253l4.146 3.552a.167.167 0 0 0 .276-.127zm-.926-.886c.757-.648 1.926-.11 1.926.886V6.62l3.569-3.057c.757-.648 1.925-.11 1.925.886v7.103c0 .997-1.168 1.534-1.925.886L7.839 9.38v2.171c0 .997-1.168 1.534-1.925.886L1.767 8.886a1.167 1.167 0 0 1 0-1.772zM7.84 8.064l4.22 3.614a.167.167 0 0 0 .274-.127V4.448a.167.167 0 0 0-.275-.126L7.84 7.936z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRewindSymbolOutline.displayName = 'RewindSymbolOutline';
const Memo = memo(SvgRewindSymbolOutline);
export default Memo;
