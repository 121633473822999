// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRemovePeopleRemoveUserRemovePerson2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.333 2.333a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0m7 3.167a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-4 1.833c-2.04 0-3.576 1.202-4.21 2.916a.48.48 0 0 0 .09.493.77.77 0 0 0 .585.258h7.07c.25 0 .462-.11.586-.258a.48.48 0 0 0 .088-.493c-.633-1.714-2.169-2.916-4.209-2.916m-5.147 2.569c.77-2.085 2.666-3.569 5.147-3.569s4.376 1.484 5.147 3.569a1.48 1.48 0 0 1-.26 1.483c-.32.38-.815.615-1.352.615h-7.07a1.77 1.77 0 0 1-1.352-.615 1.48 1.48 0 0 1-.26-1.483"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRemovePeopleRemoveUserRemovePerson2Outline.displayName =
  'RemovePeopleRemoveUserRemovePerson2Outline';
const Memo = memo(SvgRemovePeopleRemoveUserRemovePerson2Outline);
export default Memo;
