// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHighlightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M7.999 14.167a.5.5 0 0 1 .5-.5h5.666a.5.5 0 0 1 0 1H8.5a.5.5 0 0 1-.5-.5m-6.167 1a1 1 0 0 1-1-1v-2.26c0-.616.245-1.206.68-1.642l8.752-8.752a2.32 2.32 0 0 1 3.283 0l.938.94-.707.706-.939-.939a1.32 1.32 0 0 0-1.868 0L2.22 10.973a1.32 1.32 0 0 0-.387.934v2.26h2.26c.35 0 .687-.14.934-.387l8.752-8.752a1.32 1.32 0 0 0 0-1.869l.707-.707a2.32 2.32 0 0 1 0 3.283l-8.752 8.752a2.32 2.32 0 0 1-1.64.68z"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgHighlightOutline.displayName = 'HighlightOutline';
const Memo = memo(SvgHighlightOutline);
export default Memo;
