// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Defs, Path, RadialGradient, Stop} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMessengerColour: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.002 1.333c-3.754 0-6.666 2.752-6.666 6.467 0 1.943.797 3.623 2.093 4.783a.53.53 0 0 1 .179.38l.036 1.187a.533.533 0 0 0 .749.471l1.323-.583a.54.54 0 0 1 .356-.027 7.3 7.3 0 0 0 1.93.257c3.755 0 6.667-2.752 6.667-6.467s-2.912-6.468-6.667-6.468" />
      <Path d="m3.997 9.691 1.958-3.106a1 1 0 0 1 1.447-.267L8.96 7.487a.4.4 0 0 0 .482-.002l2.103-1.597c.28-.213.647.124.46.422l-1.96 3.105a1 1 0 0 1-1.447.266L7.04 8.513a.4.4 0 0 0-.481.002L4.455 10.11c-.28.214-.646-.121-.458-.42" />
      <Defs>
        <RadialGradient
          id="a"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="translate(3.57 14.666)scale(14.6663)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#09F" />
          <Stop
            offset={0.6}
            stopColor="#A033FF"
          />
          <Stop
            offset={0.9}
            stopColor="#FF5280"
          />
          <Stop
            offset={1}
            stopColor="#FF7061"
          />
        </RadialGradient>
      </Defs>
    </Svg>
  );
};
SvgMessengerColour.displayName = 'MessengerColour';
const Memo = memo(SvgMessengerColour);
export default Memo;
