// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArchiveBoxInboxFileOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 2.5a.5.5 0 0 1 .5-.5h12.333a.5.5 0 0 1 .5.5v2.667a.5.5 0 0 1-.5.5H14v5.72c0 .356 0 .653-.02.896-.02.253-.066.491-.18.716a1.83 1.83 0 0 1-.801.801c-.225.115-.463.16-.716.18-.243.02-.54.02-.896.02H4.612c-.356 0-.654 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.199 13a1.9 1.9 0 0 1-.18-.716c-.02-.243-.02-.54-.02-.896v-5.72h-.167a.5.5 0 0 1-.5-.5zm1.667 3.167v5.7c0 .381 0 .637.016.835.016.191.044.283.075.343.08.157.207.284.364.364.06.03.152.059.343.074.197.017.453.017.835.017h6.733c.382 0 .638 0 .835-.017.192-.015.283-.043.344-.074a.83.83 0 0 0 .364-.364c.03-.06.059-.152.074-.343.016-.198.017-.454.017-.835v-5.7zm10.666-1V3H2.332v1.667zm-7.5 3.166a.5.5 0 0 1 .5-.5h2.667a.5.5 0 0 1 0 1H6.665a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArchiveBoxInboxFileOutline.displayName = 'ArchiveBoxInboxFileOutline';
const Memo = memo(SvgArchiveBoxInboxFileOutline);
export default Memo;
