// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSupportOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.676 6.008A1.5 1.5 0 0 0 1.332 7.5v2.333a1.5 1.5 0 0 0 1.5 1.5h1a.5.5 0 0 0 .5-.5V6.5a.5.5 0 0 0-.5-.5H3.68C3.863 3.974 5.696 2.333 8 2.333S12.134 3.974 12.317 6h-.152a.5.5 0 0 0-.5.5v4.333a.5.5 0 0 0 .5.5h.328A2.5 2.5 0 0 1 10 13.667H8.665A.167.167 0 0 1 8.5 13.5v-.405a.5.5 0 1 0-1 0v.405c0 .644.522 1.167 1.166 1.167H10a3.5 3.5 0 0 0 3.497-3.37 1.5 1.5 0 0 0 1.17-1.464V7.5a1.5 1.5 0 0 0-1.345-1.492c-.18-2.638-2.525-4.675-5.322-4.675S2.856 3.37 2.676 6.008m9.99.992h.5a.5.5 0 0 1 .5.5v2.333a.5.5 0 0 1-.5.5h-.5zM2.831 7a.5.5 0 0 0-.5.5v2.333a.5.5 0 0 0 .5.5h.5V7z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSupportOutline.displayName = 'SupportOutline';
const Memo = memo(SvgSupportOutline);
export default Memo;
