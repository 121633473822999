// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEuroCurrencyMoneyCoinEuroOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m4.03.5c.082.688.332 1.316.707 1.807.465.608 1.143 1.026 1.93 1.026.788 0 1.467-.42 1.932-1.03a.5.5 0 1 0-.795-.606c-.32.42-.727.636-1.137.636s-.816-.216-1.135-.633a2.7 2.7 0 0 1-.493-1.2h.794a.5.5 0 1 0 0-1h-.794c.075-.474.254-.887.493-1.2.32-.417.725-.633 1.135-.633s.817.217 1.137.636a.5.5 0 0 0 .795-.606c-.465-.61-1.144-1.03-1.932-1.03-.787 0-1.465.418-1.93 1.026A3.7 3.7 0 0 0 5.362 7.5h-.197a.5.5 0 0 0 0 1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEuroCurrencyMoneyCoinEuroOutline.displayName = 'EuroCurrencyMoneyCoinEuroOutline';
const Memo = memo(SvgEuroCurrencyMoneyCoinEuroOutline);
export default Memo;
