import {type StyleProp, StyleSheet, type ViewStyle} from 'react-native';

import type {Hive} from '@propertyguru/hive';

import type {BorderRadiusEdges, SurfaceProps} from './types';

const normalizedBorderRadius = (
  borderRadius: NonNullable<SurfaceProps['borderRadius']>,
): BorderRadiusEdges => {
  if (typeof borderRadius === 'string') {
    return {
      topStart: borderRadius,
      topEnd: borderRadius,
      bottomStart: borderRadius,
      bottomEnd: borderRadius,
    };
  }

  return borderRadius;
};

export const borderStyle = (
  hive: Hive,
  borderRadius?: SurfaceProps['borderRadius'],
): StyleProp<ViewStyle> | undefined => {
  if (!borderRadius) {
    return undefined;
  }

  const edges = normalizedBorderRadius(borderRadius);

  return StyleSheet.flatten([
    edges?.topStart && {borderTopStartRadius: hive.borderRadius(edges.topStart)},
    edges?.topEnd && {borderTopEndRadius: hive.borderRadius(edges.topEnd)},
    edges?.bottomStart && {borderBottomStartRadius: hive.borderRadius(edges.bottomStart)},
    edges?.bottomEnd && {borderBottomEndRadius: hive.borderRadius(edges.bottomEnd)},
  ]);
};
