// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShaka2CallMeHangTenOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.336 3.566a1.463 1.463 0 0 1 2.896-.368l.25 1.354a1.523 1.523 0 0 1 2.331.109 1.523 1.523 0 0 1 2.055.344 1.53 1.53 0 0 1 2.372 1.277v.787a2.55 2.55 0 0 1 3.1.399l.18.18a.5.5 0 0 1 .046.651l-2.672 3.575A5.29 5.29 0 0 1 7.656 14h-1.3a4.617 4.617 0 0 1-4.6-4.308zm9.716 4.826a1.526 1.526 0 0 1-2.367.397 1.52 1.52 0 0 1-1.028.399 1.52 1.52 0 0 1-1.028-.399 1.53 1.53 0 0 1-2.539-.906l-.008.002-.834-4.505a.463.463 0 0 0-.915.117l.42 6.127A3.617 3.617 0 0 0 6.356 13h1.3a4.29 4.29 0 0 0 3.437-1.724L13.5 8.055a1.55 1.55 0 0 0-1.829-.076zM7.13 7.656a.53.53 0 0 0 .528.532.53.53 0 0 0 .528-.532V5.938a.53.53 0 0 0-.528-.532.53.53 0 0 0-.528.532zm2.056 0a.53.53 0 0 0 .527.532.53.53 0 0 0 .528-.532V6.281a.53.53 0 0 0-.528-.531.53.53 0 0 0-.527.531zM6.129 5.594a.53.53 0 0 0-.527-.532.53.53 0 0 0-.528.532v2.062a.53.53 0 0 0 .528.532.53.53 0 0 0 .527-.532z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShaka2CallMeHangTenOutline.displayName = 'Shaka2CallMeHangTenOutline';
const Memo = memo(SvgShaka2CallMeHangTenOutline);
export default Memo;
