// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPasskeysPasskeyPasswordlessOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.5 9.333a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
      <Path
        fillRule="evenodd"
        d="M8 2.333a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0m7 4a1 1 0 0 0-.556 1.832.5.5 0 0 1 .222.416v2.512l.333.267.334-.267v-.333l-.284-.585a.5.5 0 0 1 .01-.454l.274-.513v-.627a.5.5 0 0 1 .222-.416.999.999 0 0 0-.556-1.832m-2 1a2 2 0 1 1 3.333 1.491v.51a.5.5 0 0 1-.06.235l-.213.4.223.458q.05.104.05.218v.688a.5.5 0 0 1-.188.39l-.833.667a.5.5 0 0 1-.625 0l-.833-.666a.5.5 0 0 1-.188-.39v-2.51A2 2 0 0 1 10 9.334M8 9c-2.052 0-3.615 1.318-4.238 3.23-.11.34.159.77.693.77h4.878a.5.5 0 0 1 0 1H4.455c-1.03 0-2.018-.93-1.644-2.079C3.548 9.657 5.454 8 8 8q.374 0 .731.047a.5.5 0 0 1-.13.992A5 5 0 0 0 8 9"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPasskeysPasskeyPasswordlessOutline.displayName = 'PasskeysPasskeyPasswordlessOutline';
const Memo = memo(SvgPasskeysPasskeyPasswordlessOutline);
export default Memo;
