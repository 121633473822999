// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierVectorNodesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.167 2C2.522 2 2 2.522 2 3.167v1.666C2 5.478 2.522 6 3.167 6H3.5v4h-.333C2.522 10 2 10.522 2 11.167v1.666C2 13.478 2.522 14 3.167 14h1.5c.644 0 1.166-.522 1.166-1.167V12.5H10v.333c0 .645.522 1.167 1.167 1.167h1.666c.645 0 1.167-.522 1.167-1.167v-1.666c0-.645-.522-1.167-1.167-1.167H12.5V6h.333C13.478 6 14 5.478 14 4.833V3.167C14 2.522 13.478 2 12.833 2h-1.666C10.522 2 10 2.522 10 3.167V3.5H6v-.333C6 2.522 5.478 2 4.833 2zM4.5 10V6h.333C5.478 6 6 5.478 6 4.833V4.5h4v.333C10 5.478 10.522 6 11.167 6h.333v4h-.333c-.645 0-1.167.522-1.167 1.167v.333H5.833v-.333c0-.645-.522-1.167-1.166-1.167z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBezierVectorNodesFill.displayName = 'BezierVectorNodesFill';
const Memo = memo(SvgBezierVectorNodesFill);
export default Memo;
