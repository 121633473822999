// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSearchLinesMagnifierDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834v5.25a4 4 0 0 0-4.98 6.25H4.501a1.833 1.833 0 0 1-1.833-1.834zm2 .666a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H5.168a.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5h2.333a.5.5 0 0 1 0 1H5.168a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M11.001 8.667a3 3 0 1 0 1.75 5.437l.558.577a.5.5 0 0 0 .718-.696l-.573-.591a3 3 0 0 0-2.453-4.727m-2 3a2 2 0 1 1 4 0 2 2 0 0 1-4 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSearchLinesMagnifierDocumentListPageFileFill.displayName =
  'SearchLinesMagnifierDocumentListPageFileFill';
const Memo = memo(SvgSearchLinesMagnifierDocumentListPageFileFill);
export default Memo;
