// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCupTeaCoffeeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 2.5a.5.5 0 0 1 .5-.5h8.333a.5.5 0 0 1 .5.5V4h.834C13.179 4 14 4.82 14 5.833v1c0 1.013-.82 1.834-1.833 1.834h-.834v3.5c0 1.012-.82 1.833-1.833 1.833H3.833A1.833 1.833 0 0 1 2 12.167zm9.333 5.167h.834c.46 0 .833-.373.833-.834v-1A.833.833 0 0 0 12.167 5h-.834z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCupTeaCoffeeFill.displayName = 'CupTeaCoffeeFill';
const Memo = memo(SvgCupTeaCoffeeFill);
export default Memo;
