// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHairDryerOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.625 1C7.76 1 3.497 2.133 1.946 2.58h-.001A1.31 1.31 0 0 0 1 3.84v3.07a1.31 1.31 0 0 0 .945 1.26h.003c1.218.347 4.057 1.11 6.607 1.432l-.33 2.97A2.188 2.188 0 0 0 10.41 15h.67a2.19 2.19 0 0 0 2.187-2.187V8.862A4.38 4.38 0 0 0 15 5.375 4.375 4.375 0 0 0 10.625 1m-.82 8.723q.426.026.82.027h.01a4.3 4.3 0 0 0 1.757-.365v3.428a1.31 1.31 0 0 1-1.312 1.312h-.674a1.313 1.313 0 0 1-1.312-1.457l.331-2.972q.19.016.38.027m.82-.848v-7c-1.714 0-3.992.434-5.836.868v5.262c1.56.368 3.427.736 5.005.84q.415.027.831.03M2.189 3.42c.417-.12 1.017-.286 1.725-.465v4.836a59 59 0 0 1-1.725-.463.44.44 0 0 1-.314-.42V3.841a.44.44 0 0 1 .314-.42M11.5 8.764a3.5 3.5 0 0 0 0-6.778z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHairDryerOutline.displayName = 'HairDryerOutline';
const Memo = memo(SvgHairDryerOutline);
export default Memo;
