// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGolfFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.258 1.046a.438.438 0 0 0-.633.391v4.189c-.768.183-1.291.437-1.555.633A2.17 2.17 0 0 0 1 8c0 .835.604 1.499 1.378 1.929.79.438 1.85.696 2.997.696s2.208-.258 2.997-.696c.774-.43 1.378-1.094 1.378-1.929 0-.777-.527-1.408-1.22-1.835-.702-.434-1.65-.712-2.69-.775l-.028-.001c-.48 0-.917.03-1.312.08v-.705l2.223-1.171a.737.737 0 0 0-.005-1.316zm1.828 1.892L4.5 3.774V2.145zm8.91 1.618a.437.437 0 1 0-.867-.113l-1.09 8.35-.323-.295a2.12 2.12 0 0 0-1.435-.56 1.531 1.531 0 0 0 0 3.062h1.586a.875.875 0 0 0 .866-.762l.059-.448a.4.4 0 0 0 .012-.093zM6.634 12.32a1.313 1.313 0 1 1 1.856 1.857 1.313 1.313 0 0 1-1.856-1.857m.928.49a.437.437 0 1 0 0 .876.437.437 0 0 0 0-.875"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGolfFill.displayName = 'GolfFill';
const Memo = memo(SvgGolfFill);
export default Memo;
