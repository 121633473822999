// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEarthGlobeWorldFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334M5.602 6.8a.5.5 0 0 1-.746.175l-1.62-1.214a.47.47 0 0 1-.135-.612 5.66 5.66 0 0 1 5.783-2.747.467.467 0 0 1 .369.585l-.514 2.056a.5.5 0 0 1-.363.363l-2.15.542a.5.5 0 0 0-.325.26zm4.536 4.257a.5.5 0 0 1-.447.276H8.268a.5.5 0 0 1-.416-.222l-.957-1.436a.5.5 0 0 1 .063-.632l.288-.287a.5.5 0 0 1 .385-.145l.936.06a.5.5 0 0 1 .247.084l1.488 1a.5.5 0 0 1 .168.638z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEarthGlobeWorldFill.displayName = 'EarthGlobeWorldFill';
const Memo = memo(SvgEarthGlobeWorldFill);
export default Memo;
