// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStorageHddSsdOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834v9.666c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834zm1 8v1.666c0 .46.373.834.833.834h7c.46 0 .834-.373.834-.834v-1.666a.833.833 0 0 0-.834-.834h-7a.833.833 0 0 0-.833.834m8.667-1.634c-.25-.128-.534-.2-.834-.2h-7c-.3 0-.583.072-.833.2V3.167c0-.46.373-.834.833-.834h7c.46 0 .834.373.834.834z"
        clipRule="evenodd"
      />
      <Path d="M10.665 12.333a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666M8.665 12.333a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666" />
      <Path
        fillRule="evenodd"
        d="M10.667 12.667a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334m-2 0a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334M8.333 12A.333.333 0 1 0 9 12a.333.333 0 0 0-.667 0m2 0A.333.333 0 1 0 11 12a.333.333 0 0 0-.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStorageHddSsdOutline.displayName = 'StorageHddSsdOutline';
const Memo = memo(SvgStorageHddSsdOutline);
export default Memo;
