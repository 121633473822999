// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSmatvSystemOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.476 1.85a1.8 1.8 0 0 0-.79-.455 1.74 1.74 0 0 0-1.7.455 1.74 1.74 0 0 0-.262 2.149L9.399 5.324 6.232 2.162a.426.426 0 0 0-.603 0 5.33 5.33 0 0 0-1.376 5.146 5.27 5.27 0 0 0 3.764 3.765 5.27 5.27 0 0 0 5.146-1.376.43.43 0 0 0 0-.603l-3.161-3.162 1.324-1.325a1.76 1.76 0 0 0 .916.256c.46 0 .898-.182 1.24-.517.335-.336.517-.773.517-1.245s-.182-.916-.517-1.246zm-5.237 8.398a4.43 4.43 0 0 1-3.162-3.161 4.49 4.49 0 0 1 .87-4.003l6.295 6.294a4.42 4.42 0 0 1-4.003.87m4.634-6.51c-.233.233-.563.318-.876.233a.87.87 0 0 1-.403-.234.96.96 0 0 1-.233-.403.89.89 0 0 1 .233-.876.9.9 0 0 1 .875-.233.903.903 0 0 1 .41 1.518zM6.508 11.425a1.325 1.325 0 0 0-1.865 0l-2.52 2.513a.43.43 0 0 0 0 .603.43.43 0 0 0 .603 0l2.52-2.52a.46.46 0 0 1 .653 0l2.52 2.52a.42.42 0 0 0 .3.125c.109 0 .217-.04.302-.125a.43.43 0 0 0 0-.603L6.502 11.42z" />
    </Svg>
  );
};
SvgSmatvSystemOutline.displayName = 'SmatvSystemOutline';
const Memo = memo(SvgSmatvSystemOutline);
export default Memo;
