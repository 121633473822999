// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbleMessageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.165 2c-1.012 0-1.833.82-1.833 1.833v7c0 1.013.82 1.834 1.833 1.834h2.602q.06 0 .107.039L7.25 13.86c.432.361 1.06.363 1.494.004l1.399-1.159a.17.17 0 0 1 .106-.038h2.583c1.013 0 1.833-.821 1.833-1.834v-7c0-1.012-.82-1.833-1.833-1.833z" />
    </Svg>
  );
};
SvgBubbleMessageFill.displayName = 'BubbleMessageFill';
const Memo = memo(SvgBubbleMessageFill);
export default Memo;
