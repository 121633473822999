import React, {useCallback, useEffect} from 'react';

import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';

import {type AnimatedProp, Canvas, type Color, Path, Skia} from '@shopify/react-native-skia';

import {useHive} from '@propertyguru/hive';

import type {
  SpinnerColor,
  SpinnerColors,
  SpinnerDimensions,
  SpinnerProps,
  SpinnerSize,
} from './types';

export const getSpinnerSize = (size: SpinnerSize): SpinnerDimensions => {
  switch (size) {
    case 'small':
      return {
        size: 24,
        strokeWidth: 2.5,
      };
    case 'xsmall':
      return {
        size: 16,
        strokeWidth: 1.5,
      };
    default:
      return {
        size: 32,
        strokeWidth: 3,
      };
  }
};
export const getSpinnerType = (type: SpinnerColor): SpinnerColors => {
  switch (type) {
    case 'light':
      return {
        bg: 'fill/pressed/primary',
        fill: 'fill/neutral/secondary',
      };
    case 'red':
      return {
        bg: 'fill/error/secondary',
        fill: 'fill/error/primary',
      };
    case 'orange':
      return {
        bg: 'fill/warning/secondary',
        fill: 'fill/warning/primary',
      };
    case 'green':
      return {
        bg: 'fill/success/secondary',
        fill: 'fill/success/primary',
      };

    case 'dark':
      return {
        bg: 'fill/hover/primary',
        fill: 'fill/active/tertiary',
      };

    default:
      return {
        bg: 'fill/pressed/primary',
        fill: 'fill/neutral/secondary',
      };
  }
};

export const Spinner: React.FC<SpinnerProps> = ({
  size: sizeProp = 'default',
  color = 'dark',
  style,
  ...props
}) => {
  const {size, strokeWidth} = getSpinnerSize(sizeProp);
  const {bg, fill} = getSpinnerType(color);
  const radius = (size - strokeWidth) / 2;
  const hive = useHive();

  const path = Skia.Path.Make();
  path.addCircle(size / 2, size / 2, radius);
  const strokeColor = hive.color(bg) as AnimatedProp<Color | undefined>;
  const pgColor = hive.color(fill) as AnimatedProp<Color | undefined>;

  const process = useSharedValue(0);
  const rotation = useSharedValue(0);

  const startAnimation = useCallback(() => {
    process.value = withTiming(0.6, {duration: 1000});
    process.value = withRepeat(
      withSequence(withTiming(0.7, {duration: 1000}), withTiming(0.1, {duration: 2000})),
      -1,
      true,
    );
    rotation.value = withRepeat(
      withTiming(360, {duration: 1000, easing: Easing.linear}),
      -1,
      false,
    );
  }, [process, rotation]);

  useEffect(() => {
    startAnimation();
  }, [startAnimation]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          rotate: `${rotation.value}deg`,
        },
      ],
    }),
    [],
  );

  return (
    <Animated.View
      {...props}
      style={[style, animatedStyle, {width: size, height: size}]}
    >
      <Canvas style={{width: size, height: size}}>
        <Path
          path={path}
          strokeWidth={strokeWidth}
          style={'stroke'}
          color={strokeColor}
          strokeJoin={'round'}
          strokeCap={'round'}
          start={0}
          end={1}
        />
        <Path
          path={path}
          strokeWidth={strokeWidth}
          style={'stroke'}
          color={pgColor}
          strokeJoin={'round'}
          strokeCap={'round'}
          start={0}
          end={process}
        />
      </Canvas>
    </Animated.View>
  );
};
