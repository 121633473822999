// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHeartLikeHealthLifeFavoriteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.165 6.625c0 3.953-5.652 6.875-6.166 6.875s-6.167-2.922-6.167-6.875c0-2.75 1.713-4.125 3.426-4.125s2.74 1.031 2.74 1.031S9.027 2.5 10.74 2.5s3.426 1.375 3.426 4.125" />
    </Svg>
  );
};
SvgHeartLikeHealthLifeFavoriteFill.displayName = 'HeartLikeHealthLifeFavoriteFill';
const Memo = memo(SvgHeartLikeHealthLifeFavoriteFill);
export default Memo;
