// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettings2GearPreferencesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.085 1.783a1.83 1.83 0 0 1 1.83 0l4.166 2.4c.569.326.919.932.919 1.588v4.458c0 .656-.35 1.262-.919 1.589l-4.166 2.399a1.83 1.83 0 0 1-1.83 0L2.92 11.818A1.83 1.83 0 0 1 2 10.23V5.771c0-.656.35-1.262.919-1.589zM5.583 8a2.417 2.417 0 1 1 4.834 0 2.417 2.417 0 0 1-4.834 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettings2GearPreferencesFill.displayName = 'Settings2GearPreferencesFill';
const Memo = memo(SvgSettings2GearPreferencesFill);
export default Memo;
