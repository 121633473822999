// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLogoutLeaveDoorFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833H7.5a.5.5 0 0 1 0 1H3.833A1.833 1.833 0 0 1 2 12.167V3.833C2 2.821 2.82 2 3.833 2H7.5a.5.5 0 0 1 0 1zm6.313 1.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L12.293 8.5H6a.5.5 0 0 1 0-1h6.293l-2.147-2.146a.5.5 0 0 1 0-.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLogoutLeaveDoorFill.displayName = 'LogoutLeaveDoorFill';
const Memo = memo(SvgLogoutLeaveDoorFill);
export default Memo;
