// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRedoForwardDownwardFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.499 4.334c-1.197 0-2.167.97-2.167 2.166v.833c0 1.197.97 2.167 2.167 2.167h8.793l-1.813-1.813a.5.5 0 1 1 .707-.707l2.195 2.195a1.166 1.166 0 0 1 0 1.65l-2.195 2.195a.5.5 0 0 1-.707-.707l1.813-1.813H4.499a3.167 3.167 0 0 1-3.167-3.166V6.5a3.167 3.167 0 0 1 3.167-3.166h3.666a.5.5 0 1 1 0 1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRedoForwardDownwardFill.displayName = 'RedoForwardDownwardFill';
const Memo = memo(SvgRedoForwardDownwardFill);
export default Memo;
