// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStoppCircleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334m-1-8.334A.667.667 0 0 0 6.332 7v2c0 .368.299.667.667.667h2A.667.667 0 0 0 9.665 9V7A.667.667 0 0 0 9 6.333z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStoppCircleFill.displayName = 'StoppCircleFill';
const Memo = memo(SvgStoppCircleFill);
export default Memo;
