// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGifOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.832 8.245c0 1.096.64 1.755 1.727 1.755.967 0 1.618-.575 1.618-1.423v-.233c0-.358-.167-.519-.545-.519h-.703c-.252 0-.387.116-.387.327 0 .214.137.333.387.333h.353v.163c0 .352-.276.589-.686.589-.528 0-.817-.352-.817-.995v-.465c0-.65.284-.991.826-.991.37 0 .585.22.817.448.084.081.17.118.282.118.226 0 .384-.152.384-.375 0-.222-.17-.464-.428-.647C6.374 6.118 5.98 6 5.536 6c-1.056 0-1.704.668-1.704 1.732zM8.258 9.944c.302 0 .47-.18.47-.502V6.544c0-.319-.171-.502-.475-.502s-.474.18-.474.502v2.898c0 .319.175.502.48.502M9.974 9.944c.298 0 .464-.18.464-.502v-.974h1.205c.224 0 .376-.147.376-.364s-.15-.363-.376-.363h-1.205v-.924h1.352c.218 0 .375-.152.375-.37a.364.364 0 0 0-.375-.377h-1.753c-.356 0-.548.19-.548.533v2.84c0 .315.178.5.485.5" />
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833v-7a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGifOutline.displayName = 'GifOutline';
const Memo = memo(SvgGifOutline);
export default Memo;
