// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFilter1SortFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.833 2C2.821 2 2 2.82 2 3.833v.948c0 .486.193.953.537 1.296l3.219 3.22A.83.83 0 0 1 6 9.885v4.28a.5.5 0 0 0 .634.482l3-.833a.5.5 0 0 0 .366-.482V9.886c0-.221.088-.433.244-.59l3.219-3.219c.344-.343.537-.81.537-1.296v-.948C14 2.821 13.18 2 12.167 2z" />
    </Svg>
  );
};
SvgFilter1SortFill.displayName = 'Filter1SortFill';
const Memo = memo(SvgFilter1SortFill);
export default Memo;
