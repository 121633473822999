// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWebcamCameraViewFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.001 5a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333" />
      <Path
        fillRule="evenodd"
        d="M8.001 1.333a5.333 5.333 0 0 0-.5 10.644v1.69h-3a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-3v-1.69a5.334 5.334 0 0 0-.5-10.644M5.335 6.667a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWebcamCameraViewFill.displayName = 'WebcamCameraViewFill';
const Memo = memo(SvgWebcamCameraViewFill);
export default Memo;
