// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGiftSideViewPresentFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.167 2.944c0-.89.721-1.61 1.61-1.61.918 0 1.73.453 2.223 1.149a2.72 2.72 0 0 1 2.222-1.15c.89 0 1.611.722 1.611 1.611 0 .654-.23 1.254-.614 1.723h1.364a1.417 1.417 0 1 1 0 2.833H8.5V4.667h.611c.951 0 1.722-.771 1.722-1.723a.61.61 0 0 0-.61-.61c-.952 0-1.723.77-1.723 1.722v.61h-1v-.61c0-.952-.771-1.723-1.722-1.723a.61.61 0 0 0-.611.611c0 .952.77 1.723 1.722 1.723H7.5V7.5H3.417a1.417 1.417 0 1 1 0-2.833H4.78a2.7 2.7 0 0 1-.614-1.723M8.5 8.5h4.833v3.667c0 1.012-.82 1.833-1.833 1.833h-3zM7.5 8.5H2.667v3.667c0 1.012.82 1.833 1.833 1.833h3z" />
    </Svg>
  );
};
SvgGiftSideViewPresentFill.displayName = 'GiftSideViewPresentFill';
const Memo = memo(SvgGiftSideViewPresentFill);
export default Memo;
