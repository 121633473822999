// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWifi2SpotSignalHotSpotFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.692 4.82c3.68-2.872 8.936-2.872 12.616 0a.5.5 0 1 1-.616.789c-3.318-2.59-8.066-2.59-11.384 0a.5.5 0 0 1-.616-.789m9.5 4.45a5.226 5.226 0 0 0-6.384 0 .5.5 0 0 1-.615-.788 6.226 6.226 0 0 1 7.615 0 .5.5 0 0 1-.615.789"
        clipRule="evenodd"
      />
      <Path d="M8.335 12.667a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0" />
      <Path
        fillRule="evenodd"
        d="M8.665 12.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M8 13a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWifi2SpotSignalHotSpotFill.displayName = 'Wifi2SpotSignalHotSpotFill';
const Memo = memo(SvgWifi2SpotSignalHotSpotFill);
export default Memo;
