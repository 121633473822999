// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRemovePeopleRemoveUserRemovePersonFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.001 1.333a3 3 0 1 0 0 6 3 3 0 0 0 0-6M11.006 9.205a1.499 1.499 0 0 0-1.399 2.522L9.88 12l-.273.273A1.5 1.5 0 0 0 9.324 14H4.465a1.77 1.77 0 0 1-1.35-.615 1.48 1.48 0 0 1-.261-1.483C3.638 9.782 5.748 8.333 8 8.333c1.106 0 2.139.312 3.005.872" />
      <Path d="M13.688 11.02a.5.5 0 1 0-.707-.707l-.98.98-.98-.98a.5.5 0 1 0-.707.707l.98.98-.98.98a.5.5 0 0 0 .707.707l.98-.98.98.98a.5.5 0 0 0 .707-.707l-.98-.98z" />
    </Svg>
  );
};
SvgRemovePeopleRemoveUserRemovePersonFill.displayName = 'RemovePeopleRemoveUserRemovePersonFill';
const Memo = memo(SvgRemovePeopleRemoveUserRemovePersonFill);
export default Memo;
