// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPlaygroundFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.638 1.333h2.372a2 2 0 0 1 1.432.62c.38.396.594.932.596 1.491V13.94c0 .178-.068.349-.19.475a.63.63 0 0 1-.456.197h-1.185a.63.63 0 0 1-.457-.197.7.7 0 0 1-.189-.475v-3.91a.36.36 0 0 0-.1-.252.34.34 0 0 0-.242-.105h-.79a.34.34 0 0 0-.243.105.36.36 0 0 0-.1.252v3.91c0 .178-.068.349-.19.475a.63.63 0 0 1-.456.197H8.255a.63.63 0 0 1-.457-.197.69.69 0 0 1-.189-.475v-2.477l-.967.883-1.944 1.657a2.76 2.76 0 0 1-1.787.664h-.337c-.332 0-.643-.132-.874-.379a1.32 1.32 0 0 1-.364-.91V11.66c0-.324.121-.631.342-.862a1.14 1.14 0 0 1 .828-.356 1.86 1.86 0 0 0 1.227-.467L7.61 6.674v-3.23c.002-.559.216-1.095.596-1.49.38-.396.894-.619 1.432-.62M7.609 7.882l-3.301 2.812a2.75 2.75 0 0 1-1.802.68.27.27 0 0 0-.195.083.3.3 0 0 0-.08.203v1.717a.37.37 0 0 0 .1.253.34.34 0 0 0 .243.104h.337c.443 0 .875-.16 1.218-.456l1.928-1.64 1.552-1.414zm1.579-3.613c0-.258.2-.467.447-.467h2.371c.248 0 .448.21.448.467v1.646c0 .258-.2.466-.448.466h-2.37a.457.457 0 0 1-.448-.466zm.895.466v.714h1.476v-.714z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPlaygroundFill.displayName = 'PlaygroundFill';
const Memo = memo(SvgPlaygroundFill);
export default Memo;
