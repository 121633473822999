// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRewind30SFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.665.524a.52.52 0 0 0-.307-.475.54.54 0 0 0-.567.072l-1.6 1.31a.52.52 0 0 0 0 .805l1.6 1.31c.159.13.38.157.567.071a.52.52 0 0 0 .307-.474v-.77a5.668 5.668 0 1 1-4.799 1.75.5.5 0 1 0-.73-.685 6.667 6.667 0 1 0 5.528-2.072z" />
      <Path d="M5.832 10.604c-.967 0-1.708-.544-1.708-1.135 0-.226.16-.39.38-.39.157 0 .275.075.407.268.208.33.527.505.928.505.537 0 .884-.29.884-.734s-.354-.745-.88-.745h-.351c-.215 0-.373-.16-.373-.383 0-.215.154-.376.373-.376h.333c.44 0 .762-.286.762-.68s-.315-.663-.77-.663c-.354 0-.63.165-.82.487-.107.18-.232.255-.4.255a.38.38 0 0 1-.395-.394c0-.573.699-1.1 1.633-1.1.989 0 1.683.534 1.683 1.297 0 .53-.397 1.017-.891 1.088v.061c.594.057 1.042.566 1.042 1.185 0 .856-.76 1.454-1.837 1.454" />
      <Path
        fillRule="evenodd"
        d="M10.068 10.604c-1.16 0-1.852-.952-1.852-2.428v-.229c0-1.468.702-2.427 1.866-2.427 1.167 0 1.855.948 1.855 2.427v.23c0 1.464-.71 2.427-1.87 2.427m.007-4.297c-.555 0-.892.613-.892 1.644v.218c0 1.032.337 1.647.892 1.647s.892-.615.892-1.647v-.218c0-1.028-.337-1.644-.892-1.644"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRewind30SFill.displayName = 'Rewind30SFill';
const Memo = memo(SvgRewind30SFill);
export default Memo;
