// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGearCogWheelFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M8.001.667a.5.5 0 0 1 .5.5v.853c.73.06 1.424.252 2.058.55l.426-.738a.5.5 0 1 1 .866.5l-.427.74a6 6 0 0 1 1.506 1.505l.74-.427a.5.5 0 0 1 .5.866l-.74.427c.3.633.49 1.326.55 2.057h.855a.5.5 0 1 1 0 1h-.854a6 6 0 0 1-.55 2.057l.738.426a.5.5 0 1 1-.5.866l-.74-.427a6 6 0 0 1-1.505 1.506l.427.74a.5.5 0 0 1-.866.5l-.426-.74a6 6 0 0 1-2.058.551v.854a.5.5 0 0 1-1 0v-.854a6 6 0 0 1-2.057-.55l-.426.739a.5.5 0 1 1-.866-.5l.426-.74a6 6 0 0 1-1.505-1.506l-.74.427a.5.5 0 0 1-.5-.866l.74-.426A6 6 0 0 1 2.021 8.5h-.854a.5.5 0 0 1 0-1h.854c.06-.73.251-1.424.55-2.058l-.739-.426a.5.5 0 1 1 .5-.866l.74.427A6 6 0 0 1 4.578 3.07l-.426-.74a.5.5 0 1 1 .866-.5l.426.74a6 6 0 0 1 2.057-.55v-.854a.5.5 0 0 1 .5-.5M5.081 3.94A5 5 0 0 0 3.67 5.5 5 5 0 0 0 3 8c0 .911.244 1.765.67 2.5a5 5 0 0 0 1.41 1.559L6.6 9.427A2 2 0 0 1 6 8c0-.56.23-1.065.6-1.427zm2.385 2.131L5.946 3.44A5 5 0 0 1 8.001 3c.912 0 1.765.243 2.5.669a5 5 0 0 1 1.832 1.83c.346.6.572 1.277.644 2H9.938a2 2 0 0 0-2.472-1.427M9.938 8.5a2 2 0 0 1-2.472 1.427l-1.52 2.632a5 5 0 0 0 2.055.44c.912 0 1.765-.243 2.5-.668a5 5 0 0 0 1.832-1.831 5 5 0 0 0 .644-2z"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgGearCogWheelFill.displayName = 'GearCogWheelFill';
const Memo = memo(SvgGearCogWheelFill);
export default Memo;
