import React from 'react';
import {Platform, StyleSheet, View, type ViewProps} from 'react-native';

import type {PressableState} from './types';

interface SurfaceForegroundProps extends ViewProps {
  state: PressableState;
}

export const SurfaceForeground: React.FC<SurfaceForegroundProps> = ({style, state}) => {
  if (!state.pressed && !state.hovered) {
    return null;
  }

  return Platform.select({
    /**
     * Android use ripple effect
     */
    android: null,
    default: <View style={[styles.foreground, StyleSheet.absoluteFill, style]} />,
  });
};

const styles = StyleSheet.create({
  foreground: {
    pointerEvents: 'none',
  },
});
