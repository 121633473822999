// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBank2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.872 2.215 2.204 5.962a.1.1 0 0 0-.03.026l.002.005a.1.1 0 0 0 .032.036c.02.012.054.027.11.027h11.359a.2.2 0 0 0 .11-.027.1.1 0 0 0 .033-.036l.001-.005a.1.1 0 0 0-.029-.026L8.124 2.215a.26.26 0 0 0-.252 0m-.433-.728a1.1 1.1 0 0 1 1.135.01l5.68 3.756a.87.87 0 0 1 .347 1.053c-.14.356-.494.6-.924.6h-.456v4.486h.54c.497 0 .9.408.9.91v1.455c0 .502-.403.91-.9.91H2.229a.905.905 0 0 1-.9-.91v-1.455c0-.502.402-.91.9-.91h.542V6.905h-.453c-.43 0-.784-.243-.924-.599a.87.87 0 0 1 .347-1.053h.001l5.68-3.755zm4.941 9.905V6.905h-1.56v4.487zM8.414 6.905h1.565v4.487H8.414zm-.841 4.487V6.905h-1.56v4.487zM3.612 6.905h1.56v4.487h-1.56zm-1.383 5.336a.06.06 0 0 0-.06.06v1.456c0 .034.027.06.06.06h11.532a.06.06 0 0 0 .06-.06v-1.455a.06.06 0 0 0-.06-.06z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBank2Outline.displayName = 'Bank2Outline';
const Memo = memo(SvgBank2Outline);
export default Memo;
