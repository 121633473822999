// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTurnstileSystemOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.384 3.333a2.05 2.05 0 0 0-2.048 2.049v7.374a.41.41 0 0 0 .41.41h3.277a.41.41 0 0 0 .41-.41V8.633a3.28 3.28 0 0 0 2.458-1.665 3.28 3.28 0 0 0 2.68 1.686v4.102a.41.41 0 0 0 .41.41h3.278a.41.41 0 0 0 .41-.41V5.382a2.049 2.049 0 0 0-4.056-.41H5.39a2.05 2.05 0 0 0-2.007-1.639m1.23 4.916v4.098H2.155V5.382a1.23 1.23 0 0 1 2.459-.001zM6.76 7.12a2.46 2.46 0 0 1-1.328.686V5.791h2.014c-.084.5-.322.965-.686 1.329m4.99-2.607a1.23 1.23 0 0 1 2.098.87v6.965h-2.458V5.382c0-.326.13-.64.36-.87m-8.367 1.28a.41.41 0 0 1 .41.409v.82a.41.41 0 0 1-.82 0V6.2a.41.41 0 0 1 .41-.41m9.424.409a.41.41 0 1 0-.82 0v.82a.41.41 0 0 0 .82 0zM9.02 7.12a2.46 2.46 0 0 1-.686-1.329h2.014v2.014a2.46 2.46 0 0 1-1.328-.685"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTurnstileSystemOutline.displayName = 'TurnstileSystemOutline';
const Memo = memo(SvgTurnstileSystemOutline);
export default Memo;
