import type {Spacings} from '@propertyguru/hive-theme';

import BaselineSpace from './BaselineSpace';

export const BaseSpacing: Spacings = {
  none: 0,
  x1: BaselineSpace,
  x2: BaselineSpace * 2,
  x3: BaselineSpace * 3,
  x4: BaselineSpace * 4,
  x5: BaselineSpace * 5,
  x6: BaselineSpace * 6,
  x8: BaselineSpace * 8,
  x10: BaselineSpace * 10,
  x12: BaselineSpace * 12,
  x14: BaselineSpace * 14,
};
