// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowPathDownOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.151 14.74a1.5 1.5 0 0 1-2.3 0l-3.94-4.705a.833.833 0 0 1 .638-1.368h1.786v-5.5c0-1.013.82-1.834 1.833-1.834h1.667c1.012 0 1.833.821 1.833 1.834v5.5h1.786a.833.833 0 0 1 .639 1.368zm-1.533-.643a.5.5 0 0 0 .767 0l3.712-4.43h-1.929a.5.5 0 0 1-.5-.5v-6a.833.833 0 0 0-.833-.834H7.168a.833.833 0 0 0-.833.834v6a.5.5 0 0 1-.5.5H3.906zl-.383.322z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowPathDownOutline.displayName = 'ArrowPathDownOutline';
const Memo = memo(SvgArrowPathDownOutline);
export default Memo;
