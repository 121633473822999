// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSharerightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.737 2.977c0-.872 1.039-1.326 1.679-.734l5.43 5.023a1 1 0 0 1 0 1.468l-5.43 5.023c-.64.593-1.679.138-1.679-.734v-2.186c-2.552.034-3.859.335-4.637.793-.76.45-1.074 1.077-1.567 2.063l-.015.03A.5.5 0 0 1 .57 13.5c0-2.787.354-4.884 1.509-6.284 1.153-1.397 3-1.98 5.658-2.043zM14.167 8l-5.43-5.023v2.356a.84.84 0 0 1-.828.836c-2.648.042-4.167.603-5.06 1.684-.678.823-1.055 2.016-1.204 3.713.253-.297.557-.567.947-.797 1.038-.612 2.617-.911 5.304-.934a.834.834 0 0 1 .841.832v2.356z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSharerightOutline.displayName = 'SharerightOutline';
const Memo = memo(SvgSharerightOutline);
export default Memo;
