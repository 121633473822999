// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDrinkCupStrawFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.287 1.943a.477.477 0 1 0-.207-.932l-1.867.415a1.75 1.75 0 0 0-1.37 1.708v1.048H6.18c-.643 0-1.153.543-1.111 1.185l.205 3.178v.004l.31 4.814A1.75 1.75 0 0 0 7.332 15h4.295a1.75 1.75 0 0 0 1.747-1.637l.515-7.996a1.114 1.114 0 0 0-1.11-1.185H9.796V3.134c0-.372.259-.695.623-.776zm-6.133 5.42-.133-2.057a.16.16 0 0 1 .16-.17h6.596a.16.16 0 0 1 .159.17l-.133 2.058z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDrinkCupStrawFill.displayName = 'DrinkCupStrawFill';
const Memo = memo(SvgDrinkCupStrawFill);
export default Memo;
