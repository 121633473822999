// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLiftOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.016 2.532a.51.51 0 0 1 .508-.51h10.952a.51.51 0 0 1 .508.51v10.936a.51.51 0 0 1-.508.51h-1.05V7.627c0-.846-.681-1.532-1.523-1.532H5.094c-.841 0-1.524.686-1.524 1.532v6.351H2.524a.51.51 0 0 1-.508-.51zM7.5 13.978H4.586V7.627a.51.51 0 0 1 .508-.51H7.5zm3.911 0H8.516V7.116h2.387a.51.51 0 0 1 .508.51zM4.053 15h-1.53A1.53 1.53 0 0 1 1 13.468V2.532C1 1.686 1.682 1 2.524 1h10.952C14.318 1 15 1.686 15 2.532v10.936c0 .846-.682 1.531-1.524 1.531h-1.531l-.026.001H4.053M5.91 2.641c.144 0 .28.061.377.169l1.176 1.314c.188.21.171.532-.038.72a.506.506 0 0 1-.717-.037l-.798-.892-.799.892a.506.506 0 0 1-.717.038.513.513 0 0 1-.038-.721L5.533 2.81a.5.5 0 0 1 .378-.17m2.898.13a.506.506 0 0 1 .717.039l.799.892.798-.892a.506.506 0 0 1 .718-.038.513.513 0 0 1 .037.721l-1.176 1.314a.507.507 0 0 1-.755 0L8.771 3.493a.513.513 0 0 1 .038-.721"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLiftOutline.displayName = 'LiftOutline';
const Memo = memo(SvgLiftOutline);
export default Memo;
