// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrending4ChartAnalyticsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.5 2a.5.5 0 0 1 .5.5v7.73l2.068-2.068c.67-.67 1.756-.67 2.426 0 .28.28.732.28 1.012 0l1.635-1.635a1.833 1.833 0 0 1 2.593 0l.432.432a.5.5 0 0 1-.707.707l-.432-.432a.833.833 0 0 0-1.179 0L9.213 8.869c-.67.67-1.756.67-2.426 0a.715.715 0 0 0-1.012 0L3 11.645v.522c0 .46.373.833.833.833H13.5a.5.5 0 0 1 0 1H3.833A1.833 1.833 0 0 1 2 12.167V2.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrending4ChartAnalyticsOutline.displayName = 'Trending4ChartAnalyticsOutline';
const Memo = memo(SvgTrending4ChartAnalyticsOutline);
export default Memo;
