// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronDoubleRightFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.314 4.98a.5.5 0 0 1 .708 0l2.195 2.195a1.167 1.167 0 0 1 0 1.65L5.022 11.02a.5.5 0 1 1-.708-.707L6.51 8.118a.167.167 0 0 0 0-.236L4.314 5.687a.5.5 0 0 1 0-.707m4.667 0a.5.5 0 0 1 .707 0l2.195 2.195a1.167 1.167 0 0 1 0 1.65L9.689 11.02a.5.5 0 1 1-.707-.707l2.195-2.195a.167.167 0 0 0 0-.236L8.981 5.687a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronDoubleRightFill.displayName = 'ChevronDoubleRightFill';
const Memo = memo(SvgChevronDoubleRightFill);
export default Memo;
