// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgReceiptTicketOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 2.334a.833.833 0 0 0-.833.833v9.82l1.373-.758c.332-.183.73-.194 1.071-.03l1.817.878c.046.022.099.022.145 0L9.89 12.2c.34-.165.74-.154 1.071.029l1.374.758v-9.82a.833.833 0 0 0-.834-.833zm-1.833.833c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v10.102a.833.833 0 0 1-1.236.73l.241-.438-.241.438-1.62-.895a.17.17 0 0 0-.154-.004l-1.816.877c-.32.155-.694.155-1.015 0l.217-.45-.217.45-1.816-.877a.17.17 0 0 0-.153.004L3.904 14l-.24-.434.24.434a.833.833 0 0 1-1.236-.73zm7.02 1.513a.5.5 0 0 1 0 .707L7.822 7.254a.5.5 0 0 1-.708 0l-.8-.8a.5.5 0 1 1 .708-.707l.446.446L8.981 4.68a.5.5 0 0 1 .707 0M6.001 9.167a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgReceiptTicketOutline.displayName = 'ReceiptTicketOutline';
const Memo = memo(SvgReceiptTicketOutline);
export default Memo;
