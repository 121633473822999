// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudOffOfflineFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.443 12.32a3.25 3.25 0 0 0-1.952-5.461 4.5 4.5 0 0 0-6.962-3.453zM2.355 1.646a.5.5 0 1 0-.707.708l2.495 2.495q-.164.27-.288.567a4.001 4.001 0 0 0 .813 7.917h7.417q.256 0 .503-.039l1.06 1.06a.5.5 0 0 0 .707-.708l-1.237-1.236-.012-.012L5.14 4.432l-.012-.012z" />
    </Svg>
  );
};
SvgCloudOffOfflineFill.displayName = 'CloudOffOfflineFill';
const Memo = memo(SvgCloudOffOfflineFill);
export default Memo;
