// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgKeyboardUp1CloseDownOpenArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        stroke="#0D1011"
        strokeWidth={0.5}
        d="M3.833 9.083a.583.583 0 1 0 0-1.166.583.583 0 0 0 0 1.166Zm0 3a.583.583 0 1 0 0-1.166.583.583 0 0 0 0 1.166Zm8.334-3a.583.583 0 1 0 0-1.166.583.583 0 0 0 0 1.166Zm0 3a.583.583 0 1 0 0-1.166.583.583 0 0 0 0 1.166Zm-5.667-3a.583.583 0 1 0 0-1.166.583.583 0 0 0 0 1.166Zm3 0a.583.583 0 1 0 0-1.166.583.583 0 0 0 0 1.166Z"
      />
      <Path
        fillRule="evenodd"
        d="M7.648 1.646a.5.5 0 0 1 .707 0L9.688 2.98a.5.5 0 0 1-.707.707l-.98-.98-.98.98a.5.5 0 0 1-.707-.707zm-6.98 5.52c0-1.012.82-1.833 1.833-1.833h11c1.013 0 1.834.821 1.834 1.834v5.666c0 1.013-.821 1.834-1.834 1.834h-11a1.833 1.833 0 0 1-1.833-1.834zm1.833-.833a.833.833 0 0 0-.833.834v5.666c0 .46.373.834.833.834h11c.46 0 .834-.373.834-.834V7.167a.833.833 0 0 0-.834-.834zm3.5 5.167a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgKeyboardUp1CloseDownOpenArrowOutline.displayName = 'KeyboardUp1CloseDownOpenArrowOutline';
const Memo = memo(SvgKeyboardUp1CloseDownOpenArrowOutline);
export default Memo;
