// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGrowthGrowLeafsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.5 2a.5.5 0 0 0-.5.5v.833A5.167 5.167 0 0 0 7.167 8.5H7.5v5a.5.5 0 0 0 1 0v-2.833h.333A5.167 5.167 0 0 0 14 5.5v-1a.5.5 0 0 0-.5-.5h-.833a5.16 5.16 0 0 0-4.252 2.23A5.17 5.17 0 0 0 3.333 2z" />
    </Svg>
  );
};
SvgGrowthGrowLeafsFill.displayName = 'GrowthGrowLeafsFill';
const Memo = memo(SvgGrowthGrowLeafsFill);
export default Memo;
