// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloseXCircleRemoveFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m5.02-2.354a.5.5 0 0 0-.707.707L7.292 8 5.645 9.646a.5.5 0 1 0 .707.707L8 8.707l1.646 1.646a.5.5 0 1 0 .707-.707L8.706 8l1.646-1.647a.5.5 0 0 0-.707-.707L8 7.293z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloseXCircleRemoveFill.displayName = 'CloseXCircleRemoveFill';
const Memo = memo(SvgCloseXCircleRemoveFill);
export default Memo;
