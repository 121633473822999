// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowPathUpOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.849 1.26a1.5 1.5 0 0 1 2.3 0l3.94 4.705a.833.833 0 0 1-.638 1.368h-1.786v5.5c0 1.013-.82 1.834-1.833 1.834H7.165a1.833 1.833 0 0 1-1.833-1.834v-5.5H3.546a.833.833 0 0 1-.639-1.368zm1.533.643a.5.5 0 0 0-.767 0l-3.712 4.43h1.929a.5.5 0 0 1 .5.5v6c0 .46.373.834.833.834h1.667c.46 0 .833-.373.833-.834v-6a.5.5 0 0 1 .5-.5h1.929zl.383-.321z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowPathUpOutline.displayName = 'ArrowPathUpOutline';
const Memo = memo(SvgArrowPathUpOutline);
export default Memo;
