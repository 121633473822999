// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgReflexologyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.833 14.899c-.655 0-1.3-.272-1.766-.746a2.55 2.55 0 0 1-.734-1.798c0-.56-.15-1.108-.439-1.577A2.95 2.95 0 0 0 6.7 9.671a3.1 3.1 0 0 1-1.239-1.153A3.2 3.2 0 0 1 5 6.873c0-.367.083-.74.239-1.074.155-.333.389-.633.672-.865a2.418 2.418 0 0 1 2.056-.486l2.427.549a3.77 3.77 0 0 1 2.111 1.333c.534.673.828 1.52.828 2.391v3.64c0 .667-.266 1.322-.733 1.797a2.5 2.5 0 0 1-1.767.746zM7.44 5.234q-.184-.002-.367.04a1.6 1.6 0 0 0-.639.316c-.189.153-.339.35-.444.57-.106.221-.161.464-.161.707 0 .424.116.837.333 1.198.217.362.533.65.906.843a3.9 3.9 0 0 1 1.527 1.418c.373.61.567 1.311.567 2.024 0 .446.178.881.489 1.198.622.633 1.733.633 2.355 0 .312-.317.49-.752.49-1.198V8.71c0-.673-.228-1.334-.645-1.86a2.92 2.92 0 0 0-1.644-1.04l-2.428-.548a1.5 1.5 0 0 0-.35-.04zM5.003 4.725c-.445 0-.867-.175-1.178-.497a1.7 1.7 0 0 1-.49-1.198c0-.452.173-.882.49-1.198.628-.64 1.728-.64 2.355 0 .317.322.49.746.49 1.198s-.173.881-.49 1.198a1.64 1.64 0 0 1-1.177.497m0-2.543a.83.83 0 0 0-.59.249.854.854 0 0 0 0 1.198.84.84 0 0 0 1.178 0 .854.854 0 0 0 0-1.198.83.83 0 0 0-.588-.25M8.75 3.878a1.24 1.24 0 0 1-.883-.373 1.4 1.4 0 0 1-.273-.413 1.3 1.3 0 0 1 0-.972 1.26 1.26 0 0 1 .678-.69c.306-.13.65-.13.956 0a1.294 1.294 0 0 1 .678.69q.092.238.094.486c0 .164-.033.334-.094.486a1.27 1.27 0 0 1-.678.69q-.226.094-.478.096m0-1.696a.43.43 0 0 0-.294.124.36.36 0 0 0-.09.136.41.41 0 0 0 0 .328.4.4 0 0 0 .09.136c.038.04.083.073.133.09.1.045.217.045.317 0a.4.4 0 0 0 .133-.09.36.36 0 0 0 .089-.136.41.41 0 0 0 0-.328.4.4 0 0 0-.09-.135.35.35 0 0 0-.132-.09.4.4 0 0 0-.162-.035zM12.086 4.726c-.328 0-.65-.136-.883-.373a1.28 1.28 0 0 1-.367-.9c0-.338.128-.66.367-.898.466-.474 1.3-.474 1.766 0 .234.238.367.56.367.899s-.128.661-.367.899a1.26 1.26 0 0 1-.883.373m0-1.696a.41.41 0 0 0-.295.124.427.427 0 0 0 0 .6.425.425 0 0 0 .59 0 .427.427 0 0 0 0-.6.41.41 0 0 0-.295-.124" />
    </Svg>
  );
};
SvgReflexologyOutline.displayName = 'ReflexologyOutline';
const Memo = memo(SvgReflexologyOutline);
export default Memo;
