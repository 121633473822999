// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBellNotifyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.17 5.914A4.83 4.83 0 0 1 8 1.334a4.83 4.83 0 0 1 4.83 4.58l.124 2.44a.8.8 0 0 0 .091.34l.825 1.599A1.17 1.17 0 0 1 12.83 12h-1.703a3.167 3.167 0 0 1-6.254 0H3.17a1.17 1.17 0 0 1-1.04-1.707l.824-1.6a.8.8 0 0 0 .091-.34zM5.89 12a2.168 2.168 0 0 0 4.218 0zm-2.72-1a.17.17 0 0 1-.152-.249l.825-1.6c.12-.231.188-.486.201-.747l.123-2.44A3.83 3.83 0 0 1 8 2.335a3.83 3.83 0 0 1 3.832 3.63l.123 2.44c.013.26.082.516.201.748l.825 1.6a.17.17 0 0 1-.152.248z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBellNotifyOutline.displayName = 'BellNotifyOutline';
const Memo = memo(SvgBellNotifyOutline);
export default Memo;
