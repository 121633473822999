// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSlidesPagesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v.833h.833c1.013 0 1.833.821 1.833 1.834V11.5c0 1.013-.82 1.833-1.833 1.833h-7A1.833 1.833 0 0 1 3.999 11.5v-.833h-.834a1.833 1.833 0 0 1-1.833-1.834zm2.667 2.667v2.5h-.834a.833.833 0 0 1-.833-.834V4.5c0-.46.373-.833.833-.833h7c.46 0 .834.373.834.833v.833H5.832c-1.012 0-1.833.821-1.833 1.834"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSlidesPagesFill.displayName = 'SlidesPagesFill';
const Memo = memo(SvgSlidesPagesFill);
export default Memo;
