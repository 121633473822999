// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowBottomRightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.168 5.33a.5.5 0 0 1 .5.5v5.003c0 1.013-.82 1.834-1.833 1.834H5.17a.5.5 0 1 1 0-1h5.664q.06 0 .118-.009L3.98 4.687a.5.5 0 0 1 .707-.707l6.972 6.971a1 1 0 0 0 .008-.118V5.83a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowBottomRightOutline.displayName = 'ArrowBottomRightOutline';
const Memo = memo(SvgArrowBottomRightOutline);
export default Memo;
