// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNotificationAlarmBellActivityAlertFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 1.333a5.2 5.2 0 0 0-5.13 4.37l-.78 4.838a1.833 1.833 0 0 0 1.81 2.126h1.106c.566 1.166 1.674 2 2.994 2s2.43-.834 2.995-2h1.106c1.13 0 1.99-1.011 1.81-2.126l-.78-4.838a5.2 5.2 0 0 0-5.13-4.37m1.836 11.334H6.166c.452.625 1.12 1 1.835 1 .716 0 1.384-.375 1.836-1M3.774 1.488a.5.5 0 0 1-.019.707A6.17 6.17 0 0 0 2.071 4.97a.5.5 0 0 1-.962-.275A7.17 7.17 0 0 1 3.067 1.47a.5.5 0 0 1 .707.019m8.455 0a.5.5 0 0 1 .707-.019 7.16 7.16 0 0 1 1.958 3.227.5.5 0 1 1-.962.275 6.17 6.17 0 0 0-1.685-2.776.5.5 0 0 1-.018-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNotificationAlarmBellActivityAlertFill.displayName = 'NotificationAlarmBellActivityAlertFill';
const Memo = memo(SvgNotificationAlarmBellActivityAlertFill);
export default Memo;
