// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDockBoxArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.978 1.98a.5.5 0 0 1 .708 0l3.646 3.646V2.5a.5.5 0 0 1 1 0v3.833a1 1 0 0 1-1 1H2.499a.5.5 0 0 1 0-1h3.126L1.978 2.687a.5.5 0 0 1 0-.707M8.665 4.5a.5.5 0 0 1 .5-.5h3.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.834-1.833 1.834h-7a1.833 1.833 0 0 1-1.833-1.834V9.167a.5.5 0 0 1 1 0v3.666c0 .46.373.834.833.834h7c.46 0 .833-.373.833-.834v-7A.833.833 0 0 0 12.832 5H9.165a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDockBoxArrowFill.displayName = 'DockBoxArrowFill';
const Memo = memo(SvgDockBoxArrowFill);
export default Memo;
