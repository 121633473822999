// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHdOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833v-7a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
      <Path d="M4.117 10.395c-.31 0-.492-.189-.492-.511V6.085c0-.325.182-.514.492-.514.309 0 .491.189.491.514v1.458h2.116V6.085c0-.325.182-.514.491-.514.31 0 .492.189.492.514v3.799c0 .322-.182.511-.492.511-.309 0-.491-.189-.491-.511v-1.53H4.608v1.53c0 .322-.182.511-.491.511M8.98 10.333c-.31 0-.492-.189-.492-.514V6.147c0-.322.183-.511.492-.511h1.302c1.41 0 2.24.84 2.24 2.33 0 1.492-.83 2.367-2.24 2.367zm.491-.814h.694c.879 0 1.354-.533 1.354-1.55 0-.982-.488-1.523-1.354-1.523H9.47z" />
    </Svg>
  );
};
SvgHdOutline.displayName = 'HdOutline';
const Memo = memo(SvgHdOutline);
export default Memo;
