// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClinicOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.4 1.333c.22 0 .4.178.4.396V4.5h1.601v-.66h.4-.4v-.018a1 1 0 0 1 .014-.13c.013-.075.039-.181.095-.293a.86.86 0 0 1 .294-.333.95.95 0 0 1 .53-.15h7.734v.396-.395h.019a1 1 0 0 1 .13.013c.076.013.184.039.296.094a.87.87 0 0 1 .488.816v7.653h-.4.4v.019a1 1 0 0 1-.014.129 1 1 0 0 1-.095.293.86.86 0 0 1-.294.334.95.95 0 0 1-.53.149H5.334v-.396.396h-.018a1 1 0 0 1-.13-.014 1 1 0 0 1-.297-.094.87.87 0 0 1-.337-.291.92.92 0 0 1-.151-.525v-.66H2.8v2.771c0 .219-.18.396-.4.396s-.4-.177-.4-.396V1.73c0-.218.18-.396.4-.396m2.001 8.709v-4.75H2.8v4.75zm3.433-5.31A.8.8 0 0 1 8.4 4.5H10c.212 0 .416.083.566.232.15.148.234.35.234.56v.791h.8c.212 0 .416.084.566.232s.234.35.234.56v1.583c0 .21-.084.412-.234.56a.8.8 0 0 1-.566.232h-.8v.792c0 .21-.084.411-.234.56a.8.8 0 0 1-.566.231H8.4a.8.8 0 0 1-.566-.232.79.79 0 0 1-.234-.56V9.25h-.8a.8.8 0 0 1-.566-.232.8.8 0 0 1-.234-.56V6.875c0-.21.084-.411.234-.56a.8.8 0 0 1 .566-.232h.8v-.791c0-.21.084-.412.234-.56m2.166.56H8.4v1.187c0 .219-.18.396-.4.396H6.8v1.583H8c.22 0 .4.178.4.396v1.188H10V8.854c0-.218.18-.396.4-.396h1.2V6.875h-1.2c-.22 0-.4-.177-.4-.396zm-4.799 6.201V3.846a.3.3 0 0 1 .024-.093.1.1 0 0 1 .023-.029c.003-.002.024-.016.086-.016h7.728a.3.3 0 0 1 .094.024q.026.015.029.023c.002.003.016.024.016.085v7.648l-.003.022a.3.3 0 0 1-.021.07.1.1 0 0 1-.023.03c-.003 0-.024.015-.086.015H5.34a.3.3 0 0 1-.093-.024.1.1 0 0 1-.03-.023c-.002-.002-.016-.023-.016-.085"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClinicOutline.displayName = 'ClinicOutline';
const Memo = memo(SvgClinicOutline);
export default Memo;
