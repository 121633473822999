// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUpGamepadBaseControllsGameJoystickOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.499 1.833h-3a.667.667 0 0 0-.667.667v1.724c0 .177.07.346.195.471l1.618 1.618a.5.5 0 0 0 .707 0L9.97 4.695a.67.67 0 0 0 .195-.471V2.5a.667.667 0 0 0-.666-.667" />
      <Path
        fillRule="evenodd"
        d="M5.332 2.5c0-.644.522-1.167 1.167-1.167h3c.644 0 1.166.523 1.166 1.167v1.724c0 .31-.123.606-.341.825L8.706 6.667a1 1 0 0 1-1.414 0L5.674 5.049a1.17 1.17 0 0 1-.342-.825zm1.167-.167a.167.167 0 0 0-.167.167v1.724q.001.068.049.118L7.999 5.96l1.618-1.618a.17.17 0 0 0 .048-.118V2.5a.167.167 0 0 0-.166-.167zM11.775 6.333a.17.17 0 0 0-.118.05l-.35-.35.35.35L10.039 8l1.618 1.618a.17.17 0 0 0 .118.049h1.724a.167.167 0 0 0 .166-.167v-3a.167.167 0 0 0-.166-.167zm-.825-.658c.219-.219.515-.342.825-.342h1.724c.644 0 1.166.523 1.166 1.167v3c0 .644-.522 1.167-1.166 1.167h-1.724c-.31 0-.606-.123-.825-.342L9.332 8.707a1 1 0 0 1 0-1.414zm-8.451.658a.167.167 0 0 0-.167.167v3c0 .092.075.167.167.167h1.724a.17.17 0 0 0 .117-.05L5.958 8 4.34 6.382a.17.17 0 0 0-.117-.049zM1.332 6.5c0-.644.522-1.167 1.167-1.167h1.724c.309 0 .606.123.825.342l1.617 1.618a1 1 0 0 1 0 1.414l-1.617 1.618a1.17 1.17 0 0 1-.825.342H2.499A1.167 1.167 0 0 1 1.332 9.5zm5.96 2.833a1 1 0 0 1 1.414 0l1.618 1.618c.218.219.341.516.341.825V13.5c0 .644-.522 1.167-1.166 1.167h-3A1.167 1.167 0 0 1 5.332 13.5v-1.724c0-.31.123-.606.342-.825zm.707.707L6.38 11.658a.17.17 0 0 0-.049.118V13.5c0 .092.075.167.167.167h3a.167.167 0 0 0 .166-.167v-1.724a.17.17 0 0 0-.048-.118z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUpGamepadBaseControllsGameJoystickOutline.displayName =
  'UpGamepadBaseControllsGameJoystickOutline';
const Memo = memo(SvgUpGamepadBaseControllsGameJoystickOutline);
export default Memo;
