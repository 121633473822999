// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSort2SwitchHorizonatlFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.812 14.187a.5.5 0 0 0 .707 0l2.195-2.195a1.167 1.167 0 0 0 0-1.65L11.52 8.147a.5.5 0 1 0-.707.707l1.813 1.813H2.499a.5.5 0 0 0 0 1h10.126l-1.813 1.813a.5.5 0 0 0 0 .707M5.185 7.854a.5.5 0 0 0 0-.707L3.372 5.333H13.5a.5.5 0 0 0 0-1H3.372L5.185 2.52a.5.5 0 1 0-.707-.707L2.283 4.01a1.167 1.167 0 0 0 0 1.65l2.195 2.195a.5.5 0 0 0 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSort2SwitchHorizonatlFill.displayName = 'Sort2SwitchHorizonatlFill';
const Memo = memo(SvgSort2SwitchHorizonatlFill);
export default Memo;
