// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHighRiseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.154 2.146v12.277a.577.577 0 0 1-1.154 0V1.68q0-.164.09-.31a.74.74 0 0 1 .255-.242.95.95 0 0 1 .362-.12c.13-.016.264-.007.39.027L12.43 3.3c.166.045.31.132.413.248a.6.6 0 0 1 .157.397v10.48a.577.577 0 0 1-1.154 0V4.235z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M9.577 13.001c.319 0 .577.235.577.525v.7c0 .29-.258.525-.577.525S9 14.516 9 14.226v-.7c0-.29.258-.525.577-.525M9.577 9.001c.319 0 .577.235.577.525v.7c0 .29-.258.525-.577.525S9 10.516 9 10.226v-.7c0-.29.258-.525.577-.525M9.577 5.001c.319 0 .577.235.577.525v.7c0 .29-.258.525-.577.525S9 6.516 9 6.226v-.7c0-.29.258-.525.577-.525M6.577 13.001c.319 0 .577.235.577.525v.7c0 .29-.258.525-.577.525S6 14.516 6 14.226v-.7c0-.29.258-.525.577-.525M6.577 9.001c.319 0 .577.235.577.525v.7c0 .29-.258.525-.577.525S6 10.516 6 10.226v-.7c0-.29.258-.525.577-.525M6.577 5.001c.319 0 .577.235.577.525v.7c0 .29-.258.525-.577.525S6 6.516 6 6.226v-.7c0-.29.258-.525.577-.525"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHighRiseFill.displayName = 'HighRiseFill';
const Memo = memo(SvgHighRiseFill);
export default Memo;
