// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMultiMediaMediaImageAndVideoOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.165 5a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667M8.665 8.627v2.746a.5.5 0 0 0 .787.41l1.962-1.373a.5.5 0 0 0 0-.82L9.452 8.217a.5.5 0 0 0-.787.41" />
      <Path
        fillRule="evenodd"
        d="M1.332 3.167c0-1.013.82-1.834 1.833-1.834h5.667c1.013 0 1.833.821 1.833 1.834v2.166h2.167c1.013 0 1.833.821 1.833 1.834v5.666c0 1.013-.82 1.834-1.833 1.834H7.165a1.833 1.833 0 0 1-1.833-1.834v-2.166H3.165a1.833 1.833 0 0 1-1.833-1.834zm8.333 0v2.166h-2.5c-.943 0-1.72.713-1.822 1.63l-.329-.22a1.83 1.83 0 0 0-2.034 0l-.648.433v-4.01c0-.46.373-.833.833-.833h5.667c.46 0 .833.373.833.834M4.46 7.575l.872.582v1.51H3.165a.833.833 0 0 1-.833-.834v-.456l1.203-.802a.83.83 0 0 1 .925 0m1.872 5.258V7.167c0-.46.373-.834.833-.834h5.667c.46 0 .833.373.833.834v5.666c0 .46-.373.834-.833.834H7.165a.833.833 0 0 1-.833-.834"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMultiMediaMediaImageAndVideoOutline.displayName = 'MultiMediaMediaImageAndVideoOutline';
const Memo = memo(SvgMultiMediaMediaImageAndVideoOutline);
export default Memo;
