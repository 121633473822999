// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierVectorNodesEditCursorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.167C2 2.522 2.522 2 3.167 2h1.666C5.478 2 6 2.522 6 3.167V3.5h4v-.333C10 2.522 10.522 2 11.167 2h1.666C13.478 2 14 2.522 14 3.167v1.666C14 5.478 13.478 6 12.833 6H12.5v.833a.5.5 0 0 1-1 0V6h-.333A1.167 1.167 0 0 1 10 4.833V4.5H6v.333C6 5.478 5.478 6 4.833 6H4.5v4h.167c.644 0 1.166.522 1.166 1.167v.333h1a.5.5 0 0 1 0 1h-1v.333c0 .645-.522 1.167-1.166 1.167h-1.5A1.167 1.167 0 0 1 2 12.833v-1.666C2 10.522 2.522 10 3.167 10H3.5V6h-.333A1.167 1.167 0 0 1 2 4.833zM4.833 5A.167.167 0 0 0 5 4.833V3.167A.167.167 0 0 0 4.833 3H3.167A.167.167 0 0 0 3 3.167v1.666c0 .092.075.167.167.167zm-1.666 6a.167.167 0 0 0-.167.167v1.666c0 .092.075.167.167.167h1.5a.167.167 0 0 0 .166-.167v-1.666A.167.167 0 0 0 4.667 11zm8-8a.167.167 0 0 0-.167.167v1.666c0 .092.075.167.167.167h1.666A.167.167 0 0 0 13 4.833V3.167A.167.167 0 0 0 12.833 3zM7.694 8.748a.853.853 0 0 1 1.054-1.054l4.555 1.301c.735.21.843 1.208.17 1.571l-1.89 1.017-1.017 1.89c-.363.673-1.36.565-1.571-.17zm1.034-.02 1.13 3.952.866-1.61c.079-.146.2-.267.346-.346l1.61-.867z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBezierVectorNodesEditCursorOutline.displayName = 'BezierVectorNodesEditCursorOutline';
const Memo = memo(SvgBezierVectorNodesEditCursorOutline);
export default Memo;
