// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStapleNotebookCoverOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.165 2.667a.833.833 0 0 0-.833.833v9c0 .46.373.833.833.833h1.167V2.667zm2.167 0v10.666h5.5c.46 0 .833-.373.833-.833v-9a.833.833 0 0 0-.833-.833zm-4 .833c0-1.013.82-1.833 1.833-1.833h7.667c1.013 0 1.833.82 1.833 1.833v9c0 1.012-.82 1.833-1.833 1.833H4.165A1.833 1.833 0 0 1 2.332 12.5zm5.667 1.667a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0 2.666a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStapleNotebookCoverOutline.displayName = 'StapleNotebookCoverOutline';
const Memo = memo(SvgStapleNotebookCoverOutline);
export default Memo;
