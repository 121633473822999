// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBinocularOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.168 5.132c.105-.334.293-.693.536-.955.241-.26.503-.393.771-.374a.6.6 0 0 1 .408.173q.146.145.236.442c.123.41.131.928.131 1.388 0 .443.336.801.75.801s.75-.358.75-.8c0-.46.008-.98.131-1.389q.091-.297.236-.442c.09-.09.214-.16.408-.173.268-.019.53.114.771.374.243.262.431.62.536.955l.065.209.2.053c.27.072.52.273.744.55.218.268.388.58.506.83l.242.617a2.66 2.66 0 0 0-1.402-.399c-1.33 0-2.445.987-2.737 2.313a.9.9 0 0 0-.45-.11.9.9 0 0 0-.45.11C7.258 7.98 6.143 6.992 4.813 6.992c-.51 0-.99.145-1.402.399l.242-.616a4.2 4.2 0 0 1 .506-.832c.223-.276.473-.477.744-.55l.2-.052zm2.435 5.245C7.427 11.857 6.245 13 4.813 13 3.258 13 2 11.655 2 9.996c0-.382.067-.748.189-1.084l.778-2.46.005-.012.005-.012a5 5 0 0 1 .614-1.009c.238-.293.547-.58.933-.736.142-.374.36-.76.646-1.07.337-.363.8-.649 1.355-.61.357.026.65.164.873.39.218.22.351.502.435.781.164.548.167 1.192.167 1.632 0-.44.003-1.084.167-1.632.084-.28.217-.56.435-.78.224-.227.516-.365.873-.39.556-.04 1.018.246 1.355.61.285.308.504.695.646 1.069.385.156.695.443.933.736.278.344.482.727.614 1.01l.005.011.005.012.778 2.46c.122.336.189.702.189 1.084C14 11.656 12.74 13 11.188 13c-1.433 0-2.615-1.144-2.79-2.623-.012-.187-.087-.38-.398-.38-.375.012-.38.236-.397.38M4.813 12.2c1.139 0 2.062-.986 2.062-2.203s-.923-2.203-2.062-2.203c-1.14 0-2.063.987-2.063 2.203 0 1.217.923 2.203 2.063 2.203m6.375 0c1.139 0 2.062-.986 2.062-2.203s-.923-2.203-2.062-2.203c-1.14 0-2.063.987-2.063 2.203 0 1.217.923 2.203 2.063 2.203"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBinocularOutline.displayName = 'BinocularOutline';
const Memo = memo(SvgBinocularOutline);
export default Memo;
