// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDossierFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.332 5.417a2.75 2.75 0 0 1 2.75-2.75h1.25v3.666h9.333v4.25a2.75 2.75 0 0 1-2.75 2.75H4.082a2.75 2.75 0 0 1-2.75-2.75z" />
      <Path d="M6.332 2.667h3.667v2.666H6.332zM10.999 2.667h1a2.667 2.667 0 0 1 2.666 2.666H11z" />
    </Svg>
  );
};
SvgDossierFill.displayName = 'DossierFill';
const Memo = memo(SvgDossierFill);
export default Memo;
