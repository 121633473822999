// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPaperPlaneSendFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.778 3.42c-.365-.993.671-1.92 1.617-1.447l9.968 4.984c.86.43.86 1.657 0 2.087l-9.968 4.984c-.946.473-1.982-.455-1.617-1.447l1.504-4.08h2.883a.5.5 0 1 0 0-1H3.282z" />
    </Svg>
  );
};
SvgPaperPlaneSendFill.displayName = 'PaperPlaneSendFill';
const Memo = memo(SvgPaperPlaneSendFill);
export default Memo;
