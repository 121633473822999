// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbleMessageTextOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 4.511C2 3.677 2.82 3 3.833 3h8.334C13.179 3 14 3.677 14 4.511v5.77c0 .835-.82 1.511-1.833 1.511H10.25a.2.2 0 0 0-.107.032l-1.399.955a1.36 1.36 0 0 1-1.493-.004l-1.377-.95a.2.2 0 0 0-.107-.033H3.833C2.821 11.792 2 11.116 2 10.281zm1.833-.687c-.46 0-.833.308-.833.687v5.77c0 .38.373.687.833.687h1.935c.274 0 .54.08.75.225l1.376.95a.2.2 0 0 0 .213.001l1.4-.955c.209-.143.472-.221.744-.221h1.916c.46 0 .833-.308.833-.687V4.51c0-.38-.373-.687-.833-.687zm1.5 2.473c0-.228.224-.412.5-.412h4.334c.276 0 .5.184.5.412s-.224.412-.5.412H5.833c-.276 0-.5-.184-.5-.412m0 2.198c0-.228.224-.412.5-.412h4.334c.276 0 .5.184.5.412s-.224.412-.5.412H5.833c-.276 0-.5-.184-.5-.412"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubbleMessageTextOutline.displayName = 'BubbleMessageTextOutline';
const Memo = memo(SvgBubbleMessageTextOutline);
export default Memo;
