// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFastForward15SFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.64.05a.52.52 0 0 0-.308.474v.842a6.668 6.668 0 1 0 5.529 2.072.5.5 0 0 0-.73.685 5.667 5.667 0 1 1-4.8-1.75v.77c.001.203.12.388.308.474a.54.54 0 0 0 .568-.072l1.6-1.31a.52.52 0 0 0 0-.804L8.207.12A.54.54 0 0 0 7.639.05" />
      <Path d="M5.804 10.024c0 .326.175.519.476.519.304 0 .48-.19.48-.52V6.183c0-.362-.233-.602-.58-.602-.2 0-.405.072-.663.247l-.64.448q-.275.18-.276.404c0 .197.146.344.343.344.104 0 .194-.032.348-.132l.49-.348h.022zM7.795 9.508c0 .548.687 1.096 1.657 1.096 1.11 0 1.859-.698 1.859-1.74 0-.946-.641-1.59-1.572-1.59-.419 0-.816.157-.985.386h-.05l.1-1.264h1.852c.29 0 .454-.14.454-.39s-.168-.397-.454-.397H8.629c-.394 0-.598.164-.623.505l-.133 1.84c-.021.33.18.54.473.54.133 0 .236-.042.448-.221.21-.165.454-.254.694-.254.516 0 .885.358.885.88 0 .541-.383.917-.924.917-.365 0-.652-.168-.877-.505-.119-.168-.233-.233-.384-.233-.24 0-.393.19-.393.43" />
    </Svg>
  );
};
SvgFastForward15SFill.displayName = 'FastForward15SFill';
const Memo = memo(SvgFastForward15SFill);
export default Memo;
