// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWhatsappBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m1.336 14.667.937-3.424a6.6 6.6 0 0 1-.882-3.304C1.393 4.297 4.357 1.333 8 1.333c1.767 0 3.426.69 4.674 1.938a6.57 6.57 0 0 1 1.933 4.675c-.002 3.642-2.966 6.606-6.607 6.606a6.6 6.6 0 0 1-3.16-.804zM5 12.552c.931.552 1.82.883 2.995.884a5.5 5.5 0 0 0 5.494-5.492A5.49 5.49 0 0 0 8 2.45 5.5 5.5 0 0 0 2.508 7.94c0 1.236.362 2.162.97 3.13l-.555 2.027zm6.326-3.036c-.041-.069-.151-.11-.317-.193-.165-.082-.976-.482-1.128-.537s-.261-.083-.372.083c-.11.165-.426.537-.522.647s-.193.124-.358.041-.697-.256-1.328-.82a5 5 0 0 1-.918-1.143c-.096-.165-.01-.255.072-.337.074-.074.165-.193.248-.29.084-.095.11-.164.166-.274.055-.11.028-.207-.014-.29-.041-.082-.371-.895-.508-1.225-.135-.322-.271-.279-.372-.284l-.317-.005a.6.6 0 0 0-.44.207c-.15.165-.578.564-.578 1.377s.592 1.598.674 1.708 1.164 1.777 2.82 2.492c.394.17.702.272.941.348.396.126.756.108 1.04.066.318-.048.977-.4 1.115-.785.138-.386.138-.717.096-.786" />
    </Svg>
  );
};
SvgWhatsappBw.displayName = 'WhatsappBw';
const Memo = memo(SvgWhatsappBw);
export default Memo;
