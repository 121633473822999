// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGraphLinesStatisticsDocumentListPageFileOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 2.334a.833.833 0 0 0-.833.833v9.667c0 .46.373.833.833.833h2.334a.5.5 0 0 1 0 1H4.5a1.833 1.833 0 0 1-1.833-1.833V3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833V6.5a.5.5 0 1 1-1 0V3.167a.833.833 0 0 0-.834-.833zM5.335 4.5a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5H7.5a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m5.5 1.887a2.334 2.334 0 1 0 1.758 4.245l-1.612-1.612a.5.5 0 0 1-.146-.353zm1 0v2.072l1.465 1.466a2.334 2.334 0 0 0-1.465-3.538M8 11.334a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGraphLinesStatisticsDocumentListPageFileOutline.displayName =
  'GraphLinesStatisticsDocumentListPageFileOutline';
const Memo = memo(SvgGraphLinesStatisticsDocumentListPageFileOutline);
export default Memo;
