// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRoomRoomForRentRoomOnlyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.95 13.874V2.19l-4.9 1.13v9.923zm-5.523.348a.5.5 0 0 1-.307-.159.45.45 0 0 1-.12-.308V2.841a.45.45 0 0 1 .113-.288.5.5 0 0 1 .28-.16l6-1.382a.54.54 0 0 1 .42.092.5.5 0 0 1 .137.161q.05.096.05.201V2h2.486c.136 0 .267.105.363.293.097.187.151.442.151.707v10c0 .265-.054.52-.15.707-.097.188-.228.293-.364.293H10v.54a.44.44 0 0 1-.046.193.5.5 0 0 1-.128.157.5.5 0 0 1-.4.104zM12 12.875h-2v-9.75h2zM7.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRoomRoomForRentRoomOnlyOutline.displayName = 'RoomRoomForRentRoomOnlyOutline';
const Memo = memo(SvgRoomRoomForRentRoomOnlyOutline);
export default Memo;
