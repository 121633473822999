// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubblesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.165 2.333a2.833 2.833 0 1 0 0 5.667 2.833 2.833 0 0 0 0-5.667M1.332 5.167a3.833 3.833 0 1 1 7.667 0 3.833 3.833 0 0 1-7.667 0m11-.334a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667M9.999 6.167a2.333 2.333 0 1 1 4.666 0 2.333 2.333 0 0 1-4.666 0M9.332 10a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667m-2.833 1.833a2.833 2.833 0 1 1 5.666 0 2.833 2.833 0 0 1-5.666 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubblesOutline.displayName = 'BubblesOutline';
const Memo = memo(SvgBubblesOutline);
export default Memo;
