// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTapeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 4.5c0-1.012.82-1.833 1.833-1.833h11c1.013 0 1.834.82 1.834 1.833v7c0 1.013-.821 1.833-1.834 1.833h-11A1.833 1.833 0 0 1 .668 11.5zm1.833-.833a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h11c.46 0 .834-.373.834-.833v-7a.833.833 0 0 0-.834-.833zM5.668 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2M7.4 9a2 2 0 1 0-1.732 1h4.667a2 2 0 1 0-1.733-1zm2.935 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTapeOutline.displayName = 'TapeOutline';
const Memo = memo(SvgTapeOutline);
export default Memo;
