// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRewind15SOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.665.524a.52.52 0 0 0-.307-.475.54.54 0 0 0-.567.072l-1.6 1.31a.52.52 0 0 0 0 .805l1.6 1.31c.159.13.38.157.567.071a.52.52 0 0 0 .307-.474v-.77a5.668 5.668 0 1 1-4.799 1.75.5.5 0 1 0-.73-.685 6.667 6.667 0 1 0 5.528-2.072z" />
      <Path d="M6.28 10.543c-.301 0-.477-.193-.477-.52v-3.48h-.021l-.49.348c-.155.1-.244.132-.348.132A.335.335 0 0 1 4.6 6.68q0-.224.276-.404l.64-.448c.259-.175.463-.247.663-.247.348 0 .58.24.58.602v3.842c0 .33-.175.519-.48.519M9.452 10.604c-.97 0-1.658-.548-1.658-1.096 0-.24.154-.43.394-.43.15 0 .265.065.383.233.226.337.512.505.878.505.54 0 .923-.376.923-.917 0-.522-.368-.88-.884-.88-.24 0-.483.09-.695.254-.211.179-.315.222-.447.222-.294 0-.494-.211-.473-.54l.133-1.841c.025-.34.229-.505.623-.505h2.026c.287 0 .455.147.455.397s-.165.39-.455.39H8.804l-.1 1.264h.05c.168-.229.566-.386.985-.386.93 0 1.571.644 1.571 1.59 0 1.042-.748 1.74-1.858 1.74" />
    </Svg>
  );
};
SvgRewind15SOutline.displayName = 'Rewind15SOutline';
const Memo = memo(SvgRewind15SOutline);
export default Memo;
