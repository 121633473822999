// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDashboardMiddleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.069 1.867.75.75 0 0 0-.402-.117h-5.25V5.833a.75.75 0 0 0-1.5 0V9.75h-5.25a.75.75 0 0 0-.402.117A6.7 6.7 0 0 1 1.332 8m.844 3.25A6.67 6.67 0 0 0 8 14.667a6.67 6.67 0 0 0 5.822-3.417H2.177"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDashboardMiddleFill.displayName = 'DashboardMiddleFill';
const Memo = memo(SvgDashboardMiddleFill);
export default Memo;
