// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTagSaleSellForSaleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.127 2.312a.816.816 0 0 0-.816.816v4.184c0 .216.086.424.239.576l5.227 5.228a.816.816 0 0 0 1.153 0l4.184-4.184a.816.816 0 0 0 0-1.154L7.887 2.551a.82.82 0 0 0-.576-.239zm-1.795.816c0-.991.803-1.795 1.795-1.795H7.31c.475 0 .932.19 1.268.526l5.227 5.227c.701.7.701 1.837 0 2.538l-4.184 4.184c-.7.7-1.837.7-2.537 0L1.858 8.58a1.8 1.8 0 0 1-.526-1.27zm2.61 1.794a.979.979 0 1 1 1.958 0 .979.979 0 0 1-1.958 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTagSaleSellForSaleOutline.displayName = 'TagSaleSellForSaleOutline';
const Memo = memo(SvgTagSaleSellForSaleOutline);
export default Memo;
