// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPencilPenToolWriteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8 3.293 12.705 8l.92-.92a2 2 0 0 0 0-2.827l-1.88-1.88a2 2 0 0 0-2.828 0zM12.2 8.909l-5.11-5.11-2.143.779a2 2 0 0 0-1.274 1.471l-1.466 7.035 3.302-3.301a1.667 1.667 0 1 1 .707.707l-3.303 3.303 7.037-1.466a2 2 0 0 0 1.471-1.275z" />
      <Path d="M6.32 9.011a.667.667 0 1 1 1.334 0 .667.667 0 0 1-1.334 0" />
    </Svg>
  );
};
SvgPencilPenToolWriteFill.displayName = 'PencilPenToolWriteFill';
const Memo = memo(SvgPencilPenToolWriteFill);
export default Memo;
