// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugumentRealityVrVirtualRealityOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m8.5 2.667.935.526a.5.5 0 1 0 .49-.871L8.572 1.56c-.355-.2-.789-.2-1.144 0l-1.353.76a.5.5 0 1 0 .49.872l.935-.526v1.5a.5.5 0 0 0 1 0zm-3.778.99a.5.5 0 0 1-.19.68l-1.022.575 1.279.738a.5.5 0 0 1-.5.866L3 5.772v1.061a.5.5 0 0 1-1 0V5.296c0-.421.227-.81.595-1.017l1.446-.813a.5.5 0 0 1 .681.19m6.556 0a.5.5 0 0 1 .68-.191l1.447.813c.368.207.595.596.595 1.017v1.537a.5.5 0 0 1-1 0v-1.06l-1.289.743a.5.5 0 0 1-.5-.866l1.28-.738-1.023-.575a.5.5 0 0 1-.19-.68M6.268 7a.5.5 0 0 1 .683-.183L8 7.423l1.049-.606a.5.5 0 0 1 .5.866L8.5 8.29V9.5a.5.5 0 0 1-1 0V8.289l-1.05-.606A.5.5 0 0 1 6.269 7M13.5 8.667a.5.5 0 0 1 .5.5v1.537c0 .421-.227.81-.595 1.017l-1.446.813a.5.5 0 1 1-.49-.871l1.021-.575-1.306-.754a.5.5 0 1 1 .5-.866l1.316.76V9.167a.5.5 0 0 1 .5-.5m-11 0a.5.5 0 0 1 .5.5v1.06l1.316-.759a.5.5 0 1 1 .5.866l-1.306.754 1.022.575a.5.5 0 1 1-.49.871l-1.447-.813A1.17 1.17 0 0 1 2 10.704V9.167a.5.5 0 0 1 .5-.5M8 11.302a.5.5 0 0 1 .5.5v1.53l.935-.525a.5.5 0 1 1 .49.871l-1.353.761c-.355.2-.789.2-1.144 0l.165-.294-.165.295-1.353-.761a.5.5 0 0 1 .49-.872l.935.526v-1.53a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArAugumentRealityVrVirtualRealityOutline.displayName =
  'ArAugumentRealityVrVirtualRealityOutline';
const Memo = memo(SvgArAugumentRealityVrVirtualRealityOutline);
export default Memo;
