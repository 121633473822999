// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSdCardMemoryStickOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834h5.667c1.013 0 1.833.821 1.833 1.834V4.6c0 .277.077.55.223.786l.74 1.202a2.5 2.5 0 0 1 .37 1.31v4.934c0 1.013-.82 1.834-1.833 1.834h-7a1.833 1.833 0 0 1-1.833-1.834zm1.833-.834a.833.833 0 0 0-.833.834v9.666c0 .46.373.834.833.834h7c.46 0 .834-.373.834-.834V7.9a1.5 1.5 0 0 0-.223-.786l-.74-1.202a2.5 2.5 0 0 1-.37-1.31V3.167a.833.833 0 0 0-.834-.834zM6.168 4a.5.5 0 0 1 .5.5v1.667a.5.5 0 1 1-1 0V4.5a.5.5 0 0 1 .5-.5m2.333 0a.5.5 0 0 1 .5.5v1.667a.5.5 0 1 1-1 0V4.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSdCardMemoryStickOutline.displayName = 'SdCardMemoryStickOutline';
const Memo = memo(SvgSdCardMemoryStickOutline);
export default Memo;
