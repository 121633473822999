// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAlignLeft2ArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.5 2.667a.5.5 0 0 1 .5.5v4.472c.055-.17.15-.329.285-.464l2.361-2.362a.5.5 0 1 1 .708.708L4.374 7.5H13.5a.5.5 0 0 1 0 1H4.374l1.98 1.98a.5.5 0 1 1-.708.706L3.285 8.825A1.16 1.16 0 0 1 3 8.362v4.472a.5.5 0 0 1-1 0V3.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAlignLeft2ArrowOutline.displayName = 'AlignLeft2ArrowOutline';
const Memo = memo(SvgAlignLeft2ArrowOutline);
export default Memo;
