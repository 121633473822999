// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMuteSoundOffOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.333 3.96V2.817a.833.833 0 0 0-1.32-.676l-3.29 2.368a.83.83 0 0 1-.486.157h-1.07c-1.013 0-1.834.821-1.834 1.834v3c0 .667.356 1.25.889 1.571l-2.076 2.075a.5.5 0 0 0 .708.708l11-11a.5.5 0 0 0-.708-.708zm-6.352 6.352a.834.834 0 0 1-.648-.812v-3c0-.46.373-.834.834-.834h1.07c.384 0 .759-.12 1.07-.345l3.026-2.178V4.96z"
        clipRule="evenodd"
      />
      <Path d="m10.333 12.857-2.948-2.122-.718.718.057.037 3.29 2.369a.833.833 0 0 0 1.32-.677V6.787l-1 1z" />
    </Svg>
  );
};
SvgMuteSoundOffOutline.displayName = 'MuteSoundOffOutline';
const Memo = memo(SvgMuteSoundOffOutline);
export default Memo;
