// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSharedSpaceSharedOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.672 1.443a.55.55 0 0 1 .661 0l6.116 4.57a.55.55 0 0 1 .11.77.553.553 0 0 1-.772.109L8.003 2.569 2.218 6.892a.553.553 0 0 1-.772-.11.55.55 0 0 1 .11-.769zm-5.71 7.322a1.9 1.9 0 0 1 1.358-.572 1.9 1.9 0 0 1 1.356.572c.358.364.557.856.557 1.367a1.95 1.95 0 0 1-.828 1.596 2.9 2.9 0 0 1 1.259.977 2.95 2.95 0 0 1 1.257-.977 1.952 1.952 0 0 1-.828-1.596c0-.511.2-1.003.557-1.367a1.9 1.9 0 0 1 1.357-.572 1.9 1.9 0 0 1 1.357.572c.357.364.556.856.556 1.367a1.95 1.95 0 0 1-.826 1.596 2.9 2.9 0 0 1 1.258.977 2.96 2.96 0 0 1 1.258-.976 1.9 1.9 0 0 1-.685-.859 1.96 1.96 0 0 1 .413-2.105 1.91 1.91 0 0 1 1.357-.572 1.9 1.9 0 0 1 1.356.572 1.94 1.94 0 0 1 .557 1.367 1.96 1.96 0 0 1-.557 1.367 2 2 0 0 1-.27.229q.336.136.63.351a.484.484 0 0 1 .104.678.49.49 0 0 1-.68.104 1.913 1.913 0 0 0-1.663-.301c-.281.08-.542.223-.763.42a2 2 0 0 0-.514.722.49.49 0 0 1-.448.298h-.189a.49.49 0 0 1-.449-.298 2 2 0 0 0-.725-.887 1.93 1.93 0 0 0-2.152 0c-.32.216-.573.524-.725.887a.49.49 0 0 1-.449.298H5.57a.49.49 0 0 1-.45-.298 2 2 0 0 0-.512-.721 1.95 1.95 0 0 0-.764-.421 1.91 1.91 0 0 0-1.663.3.49.49 0 0 1-.68-.103.484.484 0 0 1 .104-.678q.294-.215.63-.35a1.952 1.952 0 0 1-.828-1.597c0-.511.198-1.003.556-1.367m1.358.397c-.246 0-.484.1-.662.28a.99.99 0 0 0-.279.69c0 .26.102.508.28.69.177.18.415.28.66.28s.485-.1.662-.28a.99.99 0 0 0 .28-.69.99.99 0 0 0-.28-.69.93.93 0 0 0-.661-.28m9.018.073a.92.92 0 0 1 1.018.207.97.97 0 0 1 .28.69 1 1 0 0 1-.28.69.94.94 0 0 1-.661.28.92.92 0 0 1-.662-.28.97.97 0 0 1-.279-.69 1 1 0 0 1 .28-.69.9.9 0 0 1 .304-.207m-4.33-.073c-.247 0-.485.1-.662.28a.99.99 0 0 0-.28.69c0 .26.102.508.28.69.177.18.415.28.661.28s.484-.1.661-.28a.99.99 0 0 0 .28-.69.99.99 0 0 0-.28-.69.93.93 0 0 0-.66-.28"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSharedSpaceSharedOutline.displayName = 'SharedSpaceSharedOutline';
const Memo = memo(SvgSharedSpaceSharedOutline);
export default Memo;
