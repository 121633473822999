// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFistbumpBoomHandsFriendsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.502 2.5a.5.5 0 1 0-1 0v1.667a.5.5 0 0 0 1 0zM4.845 3.465a.5.5 0 1 0-.766.643l1.072 1.277a.5.5 0 0 0 .766-.643zM11.916 4.108a.5.5 0 1 0-.766-.643L10.08 4.742a.5.5 0 0 0 .766.643zM3.482 6a1.47 1.47 0 0 0-1.358.905l-.264.633A.75.75 0 0 1 1.168 8a.5.5 0 0 0-.5.5v4.333a.5.5 0 0 0 .5.5h3.76a2.594 2.594 0 0 0 2.582-2.352l.108-1.15A2.59 2.59 0 0 0 5.041 7a.2.2 0 0 1-.164-.087l-.171-.258A1.47 1.47 0 0 0 3.481 6M12.483 6a1.47 1.47 0 0 0-1.224.655l-.172.258a.2.2 0 0 1-.163.087 2.59 2.59 0 0 0-2.578 2.83l.108 1.151a2.594 2.594 0 0 0 2.583 2.352h3.76a.5.5 0 0 0 .5-.5V8.5a.5.5 0 0 0-.5-.5.75.75 0 0 1-.693-.462l-.264-.633A1.47 1.47 0 0 0 12.483 6" />
    </Svg>
  );
};
SvgFistbumpBoomHandsFriendsFill.displayName = 'FistbumpBoomHandsFriendsFill';
const Memo = memo(SvgFistbumpBoomHandsFriendsFill);
export default Memo;
