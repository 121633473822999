// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbleNotificationBadgeMessageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.665 2.333a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334M10 4a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0M3.165 3a.833.833 0 0 0-.833.833v7c0 .46.373.834.833.834h2.602c.274 0 .539.096.749.272l1.377 1.154a.17.17 0 0 0 .213 0l1.399-1.158c.21-.174.473-.268.744-.268h2.583c.46 0 .833-.373.833-.834V8.167a.5.5 0 1 1 1 0v2.666c0 1.013-.82 1.834-1.833 1.834h-2.583a.17.17 0 0 0-.106.038l-1.399 1.159a1.17 1.17 0 0 1-1.494-.004l-1.376-1.154a.17.17 0 0 0-.107-.04H3.165a1.833 1.833 0 0 1-1.833-1.833v-7C1.332 2.821 2.152 2 3.165 2H8.5a.5.5 0 0 1 0 1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubbleNotificationBadgeMessageOutline.displayName = 'BubbleNotificationBadgeMessageOutline';
const Memo = memo(SvgBubbleNotificationBadgeMessageOutline);
export default Memo;
