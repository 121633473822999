// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgImages1PhotosPicturesShotFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.583 5.833a1.583 1.583 0 1 1 3.167 0 1.583 1.583 0 0 1-3.167 0" />
      <Path
        fillRule="evenodd"
        d="M3.833 2C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h8.334A1.833 1.833 0 0 0 14 12.167V3.833C14 2.821 13.18 2 12.167 2zM13 8.467V3.833A.833.833 0 0 0 12.167 3H3.833A.833.833 0 0 0 3 3.833V8.6l1.264-.946.022-.015a1.83 1.83 0 0 1 2.36.278C7.63 8.975 8.654 9.802 10 9.802c1.285 0 2.124-.46 2.977-1.313z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgImages1PhotosPicturesShotFill.displayName = 'Images1PhotosPicturesShotFill';
const Memo = memo(SvgImages1PhotosPicturesShotFill);
export default Memo;
