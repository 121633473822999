// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRaisedAccessFloorPwdAccessibilityOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.113 2.15a1.09 1.09 0 0 0-1 .664 1.07 1.07 0 0 0 .235 1.173 1.084 1.084 0 0 0 1.847-.762c0-.285-.114-.559-.317-.76a1.09 1.09 0 0 0-.765-.316m-1.057-.498a1.91 1.91 0 0 1 2.402.235 1.888 1.888 0 0 1-.745 3.135l.356 2.71 2.648-.672a.408.408 0 1 1 .203.791l-2.745.696.239 1.816a.14.14 0 0 0 .055.094.14.14 0 0 0 .107.023c.574-.112 1.49-.288 2.317-.437l.1-.018.052-.009c.357-.063.693-.12.97-.164a6 6 0 0 1 .717-.083c.27 0 .44.182.524.295.092.126.165.283.223.436.118.31.217.71.303 1.083l.088.395c.051.233.098.445.144.623q.015.063.031.115.376-.078.74-.203a.408.408 0 1 1 .27.77q-.57.199-1.166.29a.478.478 0 0 1-.438-.186.8.8 0 0 1-.106-.187 3 3 0 0 1-.126-.396 20 20 0 0 1-.155-.672l-.083-.368a8 8 0 0 0-.27-.975 1.5 1.5 0 0 0-.088-.196q-.155.015-.48.066c-.189.029-.407.065-.641.106v.511a3.48 3.48 0 0 1-1.049 2.39 3.52 3.52 0 0 1-2.42 1h-.011a3.42 3.42 0 0 1-2.38-1.016 3.39 3.39 0 0 1-.978-2.386v-.004a3.47 3.47 0 0 1 .775-2.147 3.5 3.5 0 0 1 1.829-1.18l-.371-2.827a1.9 1.9 0 0 1-1.129-.542 1.89 1.89 0 0 1-.412-2.062c.144-.346.388-.642.7-.85m4.625 9.26c-.717.131-1.457.273-1.948.37a.96.96 0 0 1-1.006-.448.95.95 0 0 1-.127-.365l-.225-1.718c-.51.156-.961.46-1.297.874a2.66 2.66 0 0 0-.593 1.642 2.57 2.57 0 0 0 .742 1.811c.476.482 1.123.76 1.802.772a2.7 2.7 0 0 0 1.85-.766 2.67 2.67 0 0 0 .802-1.825zm1.9-.38q0-.001.005.004z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRaisedAccessFloorPwdAccessibilityOutline.displayName =
  'RaisedAccessFloorPwdAccessibilityOutline';
const Memo = memo(SvgRaisedAccessFloorPwdAccessibilityOutline);
export default Memo;
