// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSellResidentialSellResidentialFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.25 1.189a1.16 1.16 0 0 0-.572 0c-.215.055-.402.19-.577.315l-.044.033L1.372 5.61a.499.499 0 0 0 .58.81l.705-.505v5.434c0 .354 0 .65.02.893.02.252.065.489.179.713.175.344.454.623.797.798.224.114.46.159.712.18.242.02.538.02.892.02h5.414c.354 0 .65 0 .892-.02.252-.021.489-.066.712-.18.343-.175.623-.454.797-.798.115-.224.159-.461.18-.713.02-.242.02-.539.02-.893V5.916l.705.505a.497.497 0 1 0 .58-.81L8.871 1.537l-.045-.032c-.175-.127-.362-.261-.577-.316m-.038 3.688a.67.67 0 0 1 .326-.037l1.751.27a.67.67 0 0 1 .599.598l.27 1.751.004.035a.7.7 0 0 1-.042.292l-.467-.178.468.177a.7.7 0 0 1-.157.24l.002-.002-.353-.354.345.362.006-.006-3.56 3.56a.847.847 0 0 1-1.197 0L4.412 9.79a.847.847 0 0 1 0-1.196l3.56-3.56-.005.005.361.346-.353-.354-.003.003a.7.7 0 0 1 .24-.157m.352.979L5.228 9.192l1.577 1.578 3.337-3.337-.21-1.367zM9.198 7.2a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSellResidentialSellResidentialFill.displayName = 'SellResidentialSellResidentialFill';
const Memo = memo(SvgSellResidentialSellResidentialFill);
export default Memo;
