// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeLineHouseOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.226 1.92a.83.83 0 0 0-.452 0 .9.9 0 0 0-.263.142c-.135.095-.299.229-.545.43L3.599 5.247c-.309.252-.389.323-.444.4q-.086.123-.126.266c-.025.092-.029.2-.029.598v4.856c0 .381 0 .637.017.835.015.191.043.283.074.343.08.157.207.284.364.364.06.03.152.059.343.074.198.017.454.017.835.017h6.734c.381 0 .637 0 .835-.017.191-.015.283-.043.343-.074a.83.83 0 0 0 .364-.364c.031-.06.059-.152.074-.343.017-.198.017-.454.017-.835V6.51c0-.399-.004-.506-.03-.598a.8.8 0 0 0-.125-.265c-.055-.078-.135-.149-.444-.401L9.034 2.492a10 10 0 0 0-.545-.43.9.9 0 0 0-.263-.141M7.503.959c.325-.091.669-.091.994 0 .209.059.388.16.566.286.17.118.362.276.59.463l3.381 2.766.043.035c.246.2.44.359.582.56.126.176.22.373.277.583.064.237.064.487.064.805v4.931c0 .356 0 .653-.02.896-.02.253-.065.491-.18.716A1.83 1.83 0 0 1 13 13.8c-.225.115-.463.16-.716.18-.243.02-.54.02-.896.02H4.613c-.356 0-.653 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.2 13a1.9 1.9 0 0 1-.18-.716C2 12.04 2 11.743 2 11.387V6.456c0-.318 0-.568.064-.805q.086-.317.277-.584c.143-.2.336-.358.582-.56l.043-.034 3.38-2.766c.23-.187.422-.345.591-.463.178-.125.357-.227.566-.286m-2.836 9.875a.5.5 0 0 1 .5-.5h5.666a.5.5 0 0 1 0 1H5.167a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHomeLineHouseOutline.displayName = 'HomeLineHouseOutline';
const Memo = memo(SvgHomeLineHouseOutline);
export default Memo;
