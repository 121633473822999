// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPoundCurrencyMoneyCoinGbpFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m5.73-1.74C7.063 5.58 7.64 5 8.39 5c.367 0 .695.14.934.365a.5.5 0 1 0 .685-.728A2.36 2.36 0 0 0 8.389 4c-1.267 0-2.326.994-2.326 2.26 0 .489.153.923.325 1.259h-.556a.5.5 0 0 0 0 1h.754l-.853 2.129a.5.5 0 0 0 .464.685h3.288a.5.5 0 0 0 0-1H6.936l.727-1.814h1.822a.5.5 0 0 0 0-1h-1.91a3 3 0 0 1-.201-.286c-.168-.273-.311-.616-.311-.974"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPoundCurrencyMoneyCoinGbpFill.displayName = 'PoundCurrencyMoneyCoinGbpFill';
const Memo = memo(SvgPoundCurrencyMoneyCoinGbpFill);
export default Memo;
