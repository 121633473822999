import React from 'react';

import {useHive} from '@propertyguru/hive';

import type {IconProps, Svg} from './types';

const DefaultIconSize = 24;

export const Icon: React.FC<IconProps> = ({
  id,
  icon,
  width = DefaultIconSize,
  height = DefaultIconSize,
  color = 'icon/active/primary',
}) => {
  const hive = useHive();

  const fill = hive.color(color);

  const C = icon as Svg;

  return (
    <C
      id={id}
      fill={fill}
      width={width}
      height={height}
    />
  );
};
Icon.displayName = 'Icon';
