// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAvocadoOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.163 2.836c-.34-.34-.887-.519-1.651-.502-.759.017-1.663.228-2.617.585-1.91.715-3.91 1.973-5.164 3.197-1.774 1.73-1.894 4.646-.167 6.323l.01.01.003.003c1.71 1.713 4.63 1.576 6.321-.17 1.215-1.254 2.469-3.255 3.182-5.168.357-.955.567-1.861.584-2.622.018-.766-.162-1.315-.501-1.656m.707-.706c.61.61.815 1.477.794 2.385-.02.911-.268 1.933-.647 2.949-.757 2.03-2.081 4.152-3.4 5.514-2.045 2.11-5.608 2.324-7.748.18h-.001l-.001-.001C.692 11.044.902 7.477 3.032 5.4c1.363-1.33 3.484-2.658 5.512-3.417 1.015-.38 2.035-.628 2.946-.648.906-.02 1.772.185 2.38.795M6.458 7.986a1.556 1.556 0 1 0 0 3.111 1.556 1.556 0 0 0 0-3.11M3.902 9.542a2.556 2.556 0 1 1 5.11 0 2.556 2.556 0 0 1-5.11 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAvocadoOutline.displayName = 'AvocadoOutline';
const Memo = memo(SvgAvocadoOutline);
export default Memo;
