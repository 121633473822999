// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRowsWideRowsWideFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v3H1.332zM1.332 8.5v3c0 1.013.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833v-3z" />
    </Svg>
  );
};
SvgRowsWideRowsWideFill.displayName = 'RowsWideRowsWideFill';
const Memo = memo(SvgRowsWideRowsWideFill);
export default Memo;
