// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFilter1SortOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v.948c0 .486-.193.953-.537 1.296l-3.219 3.22a.83.83 0 0 0-.244.589v3.447a.5.5 0 0 1-.366.482l-3 .833A.5.5 0 0 1 6 14.167V9.886a.83.83 0 0 0-.244-.59L2.537 6.077A1.83 1.83 0 0 1 2 4.781zM3.833 3A.833.833 0 0 0 3 3.833v.948c0 .221.088.433.244.59l3.219 3.218c.344.344.537.81.537 1.297v3.623l2-.556V9.886c0-.487.193-.953.537-1.297l3.219-3.219A.83.83 0 0 0 13 4.781v-.948A.833.833 0 0 0 12.167 3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFilter1SortOutline.displayName = 'Filter1SortOutline';
const Memo = memo(SvgFilter1SortOutline);
export default Memo;
