// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTubOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.9 9.027a.43.43 0 0 0-.332-.152h-.438V3.171c0-.584-.228-1.134-.642-1.549-.829-.83-2.27-.83-3.093 0-.321.322-.526.73-.601 1.17a2.17 2.17 0 0 0-1.15.584 2.18 2.18 0 0 0-.642 1.548c0 .24.198.439.438.439h3.501c.24 0 .438-.199.438-.439 0-.584-.228-1.133-.642-1.548a2.12 2.12 0 0 0-1.05-.561 1.3 1.3 0 0 1 .326-.573c.496-.497 1.36-.497 1.856 0 .245.245.385.584.385.93v5.697H9.38a1.287 1.287 0 0 0-1.231-.877H5.522a1.29 1.29 0 0 0-1.232.877H1.436a.45.45 0 0 0-.332.152.45.45 0 0 0-.1.357l.386 2.338a3.96 3.96 0 0 0 1.342 2.355A3.93 3.93 0 0 0 5.27 15h5.456a3.95 3.95 0 0 0 2.539-.93 3.93 3.93 0 0 0 1.342-2.36l.385-2.338a.44.44 0 0 0-.099-.357zm-3.472-4.535H8.953c.064-.181.17-.35.31-.491.496-.497 1.36-.497 1.855 0 .14.14.245.31.31.491M5.212 8.998a.44.44 0 0 1 .31-.129h2.626a.437.437 0 0 1 .437.439v2.776H5.084V9.308c0-.117.047-.228.128-.31m8.538 2.577a3.07 3.07 0 0 1-3.023 2.56h-5.45a3.07 3.07 0 0 1-3.024-2.554l-.303-1.83h2.258v2.344c0 .234.094.45.257.614.164.164.38.257.619.257H8.59c.234 0 .45-.093.607-.257a.87.87 0 0 0 .257-.62V9.753h4.593l-.303 1.823z" />
    </Svg>
  );
};
SvgTubOutline.displayName = 'TubOutline';
const Memo = memo(SvgTubOutline);
export default Memo;
