// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGraphStatisticsDocumentListPageFileOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 2.333a.833.833 0 0 0-.833.834v9.666c0 .46.373.834.833.834h2.334a.5.5 0 1 1 0 1H4.5a1.833 1.833 0 0 1-1.833-1.834V3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834V6.5a.5.5 0 1 1-1 0V3.167a.833.833 0 0 0-.834-.834zm6.334 6.72a2.334 2.334 0 1 0 1.758 4.246l-1.612-1.612a.5.5 0 0 1-.146-.354zm1 0v2.073l1.465 1.466a2.334 2.334 0 0 0-1.465-3.538M8 11.334a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGraphStatisticsDocumentListPageFileOutline.displayName =
  'GraphStatisticsDocumentListPageFileOutline';
const Memo = memo(SvgGraphStatisticsDocumentListPageFileOutline);
export default Memo;
