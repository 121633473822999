// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMoneyBillDollarEuroFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 4.833C.668 3.821 1.488 3 2.501 3h11c1.013 0 1.834.82 1.834 1.833v6.334c0 1.012-.821 1.833-1.834 1.833h-11a1.833 1.833 0 0 1-1.833-1.833zm1.833-.166a.5.5 0 1 0 0 1h.667a.5.5 0 0 0 0-1zm9.834 6.166a.5.5 0 0 1 .5-.5h.666a.5.5 0 0 1 0 1h-.666a.5.5 0 0 1-.5-.5M6.335 8a1.667 1.667 0 1 1 3.333 0 1.667 1.667 0 0 1-3.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMoneyBillDollarEuroFill.displayName = 'MoneyBillDollarEuroFill';
const Memo = memo(SvgMoneyBillDollarEuroFill);
export default Memo;
