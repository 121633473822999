// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgKeyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.668 5a3 3 0 1 0 2.375 4.833c.218-.281.566-.5.98-.5h1.12a.17.17 0 0 0 .089-.025l.818-.511a1.17 1.17 0 0 1 1.236 0l.818.511a.17.17 0 0 0 .089.025h1.159c.05 0 .097-.022.129-.06l.955-1.167a.17.17 0 0 0 0-.212l-.955-1.166a.17.17 0 0 0-.129-.061h-5.33c-.413 0-.761-.219-.98-.5A3 3 0 0 0 4.669 5m-4 3a4 4 0 0 1 7.166-2.445c.065.084.14.112.188.112h5.33c.35 0 .681.157.903.428l.954 1.166c.352.43.352 1.048 0 1.478l-.954 1.166c-.222.271-.553.428-.903.428h-1.16c-.218 0-.432-.061-.618-.177l-.818-.511a.17.17 0 0 0-.176 0l-.818.511c-.186.116-.4.177-.619.177h-1.12c-.05 0-.124.028-.19.112A4 4 0 0 1 .668 8m3 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgKeyOutline.displayName = 'KeyOutline';
const Memo = memo(SvgKeyOutline);
export default Memo;
