import {Palette, type Theme} from '@propertyguru/hive';

import {Charcoal, Goldenrod, MidnightShadow, SepiaBrown, WarmSand} from './SendhelperBrands';

export const SendhelperTheme: Theme = {
  typography: {
    headline: {
      fontWeight: '400',
    },
    title: {
      xs: {
        color: Charcoal,
        fontSize: 24,
        lineHeight: 32,
      },
    },
    body: {
      s: {
        color: Charcoal,
      },
      m: {
        color: MidnightShadow,
      },
    },
    label: {
      m: {
        fontWeight: '500',
      },
    },
    caption: {
      xs: {
        color: Palette.gray[60],
      },
    },
  },
  colors: {
    fill: {
      active: {
        primary: Goldenrod,
        secondary: WarmSand,
      },
    },
    border: {
      active: {
        quaternary: SepiaBrown,
      },
    },
    text: {
      active: {
        primary: Palette.gray[90],
        tertiary: Palette.gray[10],
      },
      static: {
        primary: Palette.gray[90],
      },
      error: {
        primary: Palette.red[60],
      },
    },
    icon: {
      static: {
        primary: Palette.gray[90],
      },
    },
  },
  shape: {
    borderRadius: {
      large: 16,
      full: 16,
    },
  },
};
