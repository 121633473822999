// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCarOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.739 3.667a.83.83 0 0 0-.707.391L2 7.31v4.357a.667.667 0 1 0 1.333 0v-.214a.5.5 0 0 1 .5-.5h8.334a.5.5 0 0 1 .5.5v.214a.667.667 0 1 0 1.333 0V7.31l-2.032-3.252a.83.83 0 0 0-.707-.391zm10.261 4v4a1.667 1.667 0 0 1-3.309.286H4.31A1.667 1.667 0 0 1 1 11.667v-4H.5a.5.5 0 0 1 0-1h.723l1.961-3.139a1.83 1.83 0 0 1 1.555-.861h6.522c.632 0 1.22.325 1.555.861l1.961 3.139h.723a.5.5 0 0 1 0 1zM3.333 8.69a.5.5 0 0 1 .5-.5h1.334a.5.5 0 1 1 0 1H3.833a.5.5 0 0 1-.5-.5m7 0a.5.5 0 0 1 .5-.5h1.334a.5.5 0 0 1 0 1h-1.334a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCarOutline.displayName = 'CarOutline';
const Memo = memo(SvgCarOutline);
export default Memo;
