// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAutoFlashFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.331 1.57c0-.817-1.055-1.146-1.519-.473L2.114 9.36a.833.833 0 0 0 .686 1.307h3.865v3.763c0 .817 1.055 1.146 1.52.473l5.698-8.263a.833.833 0 0 0-.686-1.307H9.33zM10.525 14.447a.75.75 0 0 0 1.286.772zm3.643-4.614.735-.147a.75.75 0 0 0-1.378-.239zm.264 5.147a.75.75 0 0 0 1.471-.294zm-2.62.24 3-5-1.287-.773-3 5zm1.62-5.24 1 5 1.471-.294-1-5zm-1.264 4.27h2.5v-1.5h-2.5z" />
    </Svg>
  );
};
SvgAutoFlashFill.displayName = 'AutoFlashFill';
const Memo = memo(SvgAutoFlashFill);
export default Memo;
