import type {BorderRadiuses} from '@propertyguru/hive-theme';

import LargeRadius from './LargeRadius';

export const BaseBorderRadius: BorderRadiuses = {
  small: 8,
  medium: 16,
  large: LargeRadius,
  full: 100,
};
