// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDeliveryFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 2h1.5v3.167a1.5 1.5 0 0 0 1.5 1.5h2.334a1.5 1.5 0 0 0 1.5-1.5V2h1.5C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167V3.833C2 2.821 2.82 2 3.833 2m6 9a.5.5 0 0 0 0 1H11.5a.5.5 0 0 0 0-1z"
        clipRule="evenodd"
      />
      <Path d="M6.333 2h3.334v3.167a.5.5 0 0 1-.5.5H6.833a.5.5 0 0 1-.5-.5z" />
    </Svg>
  );
};
SvgDeliveryFill.displayName = 'DeliveryFill';
const Memo = memo(SvgDeliveryFill);
export default Memo;
