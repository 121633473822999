// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRotateCounterclockwiseRotateFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.34 3.938C4.515 2.736 5.822 2 7.687 2a6 6 0 1 1-5.658 8 .5.5 0 1 1 .943-.333A5.002 5.002 0 0 0 12.687 8a5 5 0 0 0-5-5C6.072 3 4.97 3.642 3.87 4.833h1.638a.5.5 0 0 1 0 1H3.34a1 1 0 0 1-1-1V2.667a.5.5 0 1 1 1 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRotateCounterclockwiseRotateFill.displayName = 'RotateCounterclockwiseRotateFill';
const Memo = memo(SvgRotateCounterclockwiseRotateFill);
export default Memo;
