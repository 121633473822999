// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWalkInWardrobeWardrobeClosetOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.895 1.334c-.68 0-1.23.56-1.23 1.25v10c0 .544.342 1.007.82 1.178v.488c0 .23.183.417.41.417s.41-.187.41-.417v-.416h7.385v.416c0 .23.183.417.41.417s.41-.187.41-.417v-.488a1.25 1.25 0 0 0 .82-1.178v-10c0-.69-.55-1.25-1.23-1.25H3.895m3.692.833H3.895a.413.413 0 0 0-.41.417v10c0 .23.183.416.41.416h3.692zm.82 0H12.1c.226 0 .41.186.41.417v10c0 .23-.184.416-.41.416H8.408zm.616 5.417a.62.62 0 0 0 .615.625.62.62 0 0 0 .616-.625.62.62 0 0 0-.616-.625.62.62 0 0 0-.615.625m-3.282 0a.62.62 0 0 0 .615.625.62.62 0 0 0 .616-.625.62.62 0 0 0-.616-.625.62.62 0 0 0-.615.625"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWalkInWardrobeWardrobeClosetOutline.displayName = 'WalkInWardrobeWardrobeClosetOutline';
const Memo = memo(SvgWalkInWardrobeWardrobeClosetOutline);
export default Memo;
