// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMouse1Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.332 6a4.667 4.667 0 0 1 9.333 0v4a4.667 4.667 0 0 1-9.333 0zm4.667-3.667A3.667 3.667 0 0 0 4.332 6v4a3.667 3.667 0 0 0 7.333 0V6A3.667 3.667 0 0 0 8 2.333m0 1.667a.5.5 0 0 1 .5.5v1.333a.5.5 0 1 1-1 0V4.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMouse1Outline.displayName = 'Mouse1Outline';
const Memo = memo(SvgMouse1Outline);
export default Memo;
