// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStoppCircleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
      <Path d="M6.332 7c0-.368.299-.667.667-.667h2c.368 0 .666.299.666.667v2A.667.667 0 0 1 9 9.667h-2A.667.667 0 0 1 6.332 9z" />
    </Svg>
  );
};
SvgStoppCircleOutline.displayName = 'StoppCircleOutline';
const Memo = memo(SvgStoppCircleOutline);
export default Memo;
