// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeLineHouseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.16 1.304a1.83 1.83 0 0 0-2.32 0L2.671 4.713A1.83 1.83 0 0 0 2 6.132v6.035C2 13.179 2.82 14 3.833 14h8.334C13.179 14 14 13.18 14 12.167V6.132c0-.55-.247-1.07-.672-1.42zm-3.993 9.03a.5.5 0 0 0 0 1h5.666a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHomeLineHouseFill.displayName = 'HomeLineHouseFill';
const Memo = memo(SvgHomeLineHouseFill);
export default Memo;
