// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgConnectDevicesMacbookIphonePhoneFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.501 11.667a.5.5 0 1 0 0 1h1.5a.5.5 0 0 0 0-1z" />
      <Path
        fillRule="evenodd"
        d="M3.835 2C2.822 2 2 2.82 2 3.833v6.834h-.833a.5.5 0 0 0-.5.5v1c0 1.012.82 1.833 1.833 1.833h6.92a1.83 1.83 0 0 0 1.414.667H13.5c1.013 0 1.834-.821 1.834-1.834V7.167c0-.84-.564-1.547-1.334-1.765V3.833c0-1.012-.82-1.833-1.833-1.833zM10 7.167c0-.46.373-.834.834-.834H13.5c.46 0 .834.373.834.834v5.666c0 .46-.374.834-.834.834h-2.666a.833.833 0 0 1-.834-.834zm-8.333 4.5h7.333v1.166q0 .084.008.167H2.5a.833.833 0 0 1-.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgConnectDevicesMacbookIphonePhoneFill.displayName = 'ConnectDevicesMacbookIphonePhoneFill';
const Memo = memo(SvgConnectDevicesMacbookIphonePhoneFill);
export default Memo;
