// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSmilingFaceHeartEyesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334m-.69-9.134c.375.587.19 1.55-1.075 2.553a.17.17 0 0 1-.16.029C4.54 7.604 4.037 6.763 4.188 6.083c.074-.33.303-.598.607-.722.268-.11.583-.102.884.054.23-.25.523-.364.812-.353.328.012.635.186.817.47m2.617 2.582c1.532-.511 2.035-1.352 1.884-2.032a1.02 1.02 0 0 0-.607-.722 1.04 1.04 0 0 0-.884.054 1.04 1.04 0 0 0-.812-.353 1.02 1.02 0 0 0-.817.47c-.375.588-.19 1.55 1.075 2.554a.17.17 0 0 0 .16.029m.372 1.254c.196-.018.37.137.342.333a2.667 2.667 0 0 1-5.283 0c-.027-.196.146-.35.343-.333 1.54.137 3.058.137 4.598 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSmilingFaceHeartEyesFill.displayName = 'SmilingFaceHeartEyesFill';
const Memo = memo(SvgSmilingFaceHeartEyesFill);
export default Memo;
