// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPinLocationFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 6.667a5.333 5.333 0 1 1 10.667 0c0 1.663-.742 3.211-1.619 4.467-.88 1.262-1.932 2.278-2.629 2.886a1.64 1.64 0 0 1-2.172 0c-.696-.608-1.748-1.624-2.629-2.886C3.41 9.878 2.668 8.33 2.668 6.667m3.25 0a2.083 2.083 0 1 1 4.167 0 2.083 2.083 0 0 1-4.167 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPinLocationFill.displayName = 'PinLocationFill';
const Memo = memo(SvgPinLocationFill);
export default Memo;
