// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAnnotationBubbleCheckFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v7c0 1.013-.82 1.834-1.833 1.834H10.25a.17.17 0 0 0-.107.038l-1.399 1.159a1.17 1.17 0 0 1-1.493-.004l-1.377-1.154a.17.17 0 0 0-.107-.04H3.833A1.833 1.833 0 0 1 2 10.834zm8.254 2.62a.5.5 0 1 0-.708-.707L7.333 7.96l-.746-.747a.5.5 0 0 0-.707.707l1.1 1.1a.5.5 0 0 0 .707 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAnnotationBubbleCheckFill.displayName = 'AnnotationBubbleCheckFill';
const Memo = memo(SvgAnnotationBubbleCheckFill);
export default Memo;
