// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTerraceOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.119 2.348C7.353 2.125 7.67 2 8.003 2s.65.125.883.348c.235.222.367.524.367.839s-.132.616-.367.839a1.3 1.3 0 0 1-.467.28v.068c0 .524-.22 1.027-.61 1.398s-.92.58-1.473.58h-.14v2.373h7.223c.675 0 1.25.546 1.25 1.187a.407.407 0 0 1-.416.395.407.407 0 0 1-.417-.395c0-.204-.202-.396-.417-.396h-.58v4.088a.407.407 0 0 1-.416.396H3.534a.407.407 0 0 1-.417-.396V9.516h-.531c-.214 0-.417.192-.417.396a.407.407 0 0 1-.416.395.407.407 0 0 1-.417-.395c0-.64.575-1.187 1.25-1.187h2.776V6.347a2.13 2.13 0 0 1-1.333-.575 1.93 1.93 0 0 1-.61-1.398v-.068a1.3 1.3 0 0 1-.467-.28 1.16 1.16 0 0 1-.366-.84c0-.314.132-.616.366-.838.234-.223.552-.348.884-.348s.65.125.884.348.366.524.366.839-.132.616-.366.839a1.3 1.3 0 0 1-.467.28v.068c0 .314.131.616.366.839.2.19.463.31.743.34v-.784c0-.218.187-.395.417-.395s.416.177.416.395v.791h.14c.332 0 .65-.125.885-.347.234-.223.366-.525.366-.84v-.067a1.3 1.3 0 0 1-.467-.28 1.16 1.16 0 0 1-.366-.84c0-.314.131-.616.366-.838M3.84 3.582a.43.43 0 0 0 .29-.115.39.39 0 0 0 .122-.28.39.39 0 0 0-.122-.28.43.43 0 0 0-.295-.116.43.43 0 0 0-.295.116.39.39 0 0 0-.122.28c0 .105.044.205.122.28a.43.43 0 0 0 .3.115m4.167 0h-.011a.43.43 0 0 1-.289-.115.39.39 0 0 1-.122-.28c0-.105.044-.206.122-.28a.43.43 0 0 1 .295-.116c.11 0 .216.042.294.116a.39.39 0 0 1 .122.28.39.39 0 0 1-.122.28.43.43 0 0 1-.289.115M6.195 9.516v3.693H7.56V9.516zm-.833 0H3.951v3.693h1.411zm6.644 3.693h-1.389V9.516h1.39zm-2.222 0V9.516h-1.39v3.693z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTerraceOutline.displayName = 'TerraceOutline';
const Memo = memo(SvgTerraceOutline);
export default Memo;
