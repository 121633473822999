// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCourtFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.631 1.43c.24-.08.498-.08.738 0l4.833 1.61c.477.16.798.605.798 1.108V5.5c0 .645-.522 1.167-1.167 1.167H12.5v5.166a.5.5 0 0 1-1 0V6.667H9.833v5.166a.5.5 0 0 1-1 0V6.667H7.167v5.166a.5.5 0 0 1-1 0V6.667H4.5v5.166a.5.5 0 0 1-1 0V6.667h-.333A1.167 1.167 0 0 1 2 5.5V4.148c0-.503.321-.948.798-1.107zM14 13H2v1h12z" />
    </Svg>
  );
};
SvgCourtFill.displayName = 'CourtFill';
const Memo = memo(SvgCourtFill);
export default Memo;
