// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowTriangleBottomOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.864 5.316A1.83 1.83 0 0 1 4.5 2.667h7.002a1.83 1.83 0 0 1 1.637 2.65L9.64 12.321c-.674 1.349-2.6 1.349-3.274 0zM4.5 3.667a.83.83 0 0 0-.743 1.202l3.501 7.006a.83.83 0 0 0 1.486 0l3.5-7.006a.83.83 0 0 0-.742-1.202z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowTriangleBottomOutline.displayName = 'ArrowTriangleBottomOutline';
const Memo = memo(SvgArrowTriangleBottomOutline);
export default Memo;
