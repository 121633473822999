// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFoldersOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.335 3.833C3.335 2.821 4.155 2 5.168 2h2.281c.486 0 .953.193 1.296.537l.553.552a.83.83 0 0 0 .589.244H13.5c1.013 0 1.834.821 1.834 1.834V9.5c0 1.012-.821 1.833-1.834 1.833h-.833v.834c0 1.012-.82 1.833-1.833 1.833H2.5a1.833 1.833 0 0 1-1.833-1.833V6.5c0-1.013.82-1.833 1.833-1.833h.834zM2.5 5.667a.833.833 0 0 0-.833.833v5.667c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V7.833A.833.833 0 0 0 10.835 7H7.22c-.486 0-.952-.193-1.296-.537l-.552-.552a.83.83 0 0 0-.59-.244zm1.834-1v-.834c0-.46.373-.833.833-.833h2.281c.221 0 .433.088.59.244l.552.552c.343.344.81.537 1.296.537H13.5c.46 0 .834.373.834.834V9.5c0 .46-.374.833-.834.833h-.833v-2.5c0-1.012-.82-1.833-1.833-1.833H7.22a.83.83 0 0 1-.589-.244l-.552-.552a1.83 1.83 0 0 0-1.297-.537z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFoldersOutline.displayName = 'FoldersOutline';
const Memo = memo(SvgFoldersOutline);
export default Memo;
