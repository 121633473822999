// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronDownSmallFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.978 6.313a.5.5 0 0 1 .708 0L7.88 8.508c.065.066.17.066.236 0l2.195-2.195a.5.5 0 1 1 .707.707L8.824 9.216a1.167 1.167 0 0 1-1.65 0L4.978 7.02a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronDownSmallFill.displayName = 'ChevronDownSmallFill';
const Memo = memo(SvgChevronDownSmallFill);
export default Memo;
