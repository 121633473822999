// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderDeleteXFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2h2.787c.613 0 1.185.306 1.525.816l.542.813A.83.83 0 0 0 8.712 4h4.12c1.013 0 1.833.82 1.833 1.833V11.5c0 1.012-.82 1.833-1.833 1.833H6.247a1.5 1.5 0 0 0-.354-1.56l-.44-.44.44-.439A1.5 1.5 0 0 0 3.77 8.773l-.439.439-.44-.44a1.5 1.5 0 0 0-1.56-.353z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M5.186 9.48a.5.5 0 0 1 0 .707l-1.147 1.146 1.147 1.147a.5.5 0 1 1-.708.707L3.332 12.04l-1.146 1.147a.5.5 0 0 1-.708-.707l1.147-1.147-1.147-1.146a.5.5 0 0 1 .708-.707l1.146 1.146L4.478 9.48a.5.5 0 0 1 .708 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolderDeleteXFill.displayName = 'FolderDeleteXFill';
const Memo = memo(SvgFolderDeleteXFill);
export default Memo;
