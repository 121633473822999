// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRepeat2RetweetFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.313 14.52a.5.5 0 0 0 .707 0l1.647-1.646a1 1 0 0 0 0-1.415L12.02 9.813a.5.5 0 1 0-.707.707l1.147 1.146H3.833A.833.833 0 0 1 3 10.833V9.166a.5.5 0 0 0-1 0v1.667c0 1.013.82 1.833 1.833 1.833h8.627l-1.147 1.147a.5.5 0 0 0 0 .707M13.5 7.667a.5.5 0 0 0 .5-.5v-2c0-1.013-.82-1.834-1.833-1.834H3.54l1.147-1.146a.5.5 0 1 0-.707-.707L2.333 3.126a1 1 0 0 0 0 1.414L3.98 6.187a.5.5 0 1 0 .707-.707L3.54 4.333h8.627c.46 0 .833.373.833.833v2a.5.5 0 0 0 .5.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRepeat2RetweetFill.displayName = 'Repeat2RetweetFill';
const Memo = memo(SvgRepeat2RetweetFill);
export default Memo;
