// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayerBehindSlidesPagesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.165 2a.5.5 0 0 0 0 1h9.667a.5.5 0 1 0 0-1zM3.165 4c-1.012 0-1.833.82-1.833 1.833V11.5c0 1.012.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833V5.833c0-1.012-.82-1.833-1.833-1.833z" />
    </Svg>
  );
};
SvgLayerBehindSlidesPagesFill.displayName = 'LayerBehindSlidesPagesFill';
const Memo = memo(SvgLayerBehindSlidesPagesFill);
export default Memo;
