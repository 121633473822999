// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPieChart1GraphChartStatisticsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.499 2.355a5.667 5.667 0 1 0 5.656 7.998L7.832 8.471A.5.5 0 0 1 7.5 8zm1 0v5.291l4.99 1.764a5.667 5.667 0 0 0-4.99-7.055M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPieChart1GraphChartStatisticsOutline.displayName = 'PieChart1GraphChartStatisticsOutline';
const Memo = memo(SvgPieChart1GraphChartStatisticsOutline);
export default Memo;
