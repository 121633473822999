// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudDownloadFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.855 5.416a4.501 4.501 0 0 1 8.636 1.442 3.25 3.25 0 0 1-.406 6.475H4.668a4 4 0 0 1-.813-7.917m3.646.417a.5.5 0 0 1 1 0v3.793l.813-.813a.5.5 0 1 1 .707.707l-1.666 1.667a.5.5 0 0 1-.707 0L5.98 9.52a.5.5 0 0 1 .707-.707l.813.813z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudDownloadFill.displayName = 'CloudDownloadFill';
const Memo = memo(SvgCloudDownloadFill);
export default Memo;
