// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgForkFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.333 1.333a2.333 2.333 0 0 0-.5 4.613v.72c0 1.013.821 1.834 1.834 1.834h1c.46 0 .833.373.833.833v.72a2.334 2.334 0 1 0 1 0v-.72c0-.46.373-.833.833-.833h1c1.013 0 1.834-.82 1.834-1.833v-.72a2.334 2.334 0 1 0-1 0v.72c0 .46-.373.833-.834.833h-1c-.525 0-.999.22-1.333.575A1.83 1.83 0 0 0 6.667 7.5h-1a.833.833 0 0 1-.834-.833v-.72a2.334 2.334 0 0 0-.5-4.614" />
    </Svg>
  );
};
SvgForkFill.displayName = 'ForkFill';
const Memo = memo(SvgForkFill);
export default Memo;
