// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPetFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.52 1c-.754 0-1.422.274-1.996.657l-.018.012-1.793 1.329c-.298.178-.515.3-.712.342C1.422 3.44 1 3.99 1 4.587v.059c0 .588.13 1.195.473 1.744l.93 1.567c.193.336.492.538.818.613.053.973.288 1.583.571 1.967.104.14.212.246.314.325l-1.223 3.556a.44.44 0 1 0 .832.285l1.343-3.906a.44.44 0 0 0-.317-.57l-.036-.018a.7.7 0 0 1-.205-.195c-.156-.21-.362-.652-.403-1.54l.021-.012c.405-.242.68-.667.68-1.134V5.054a.44.44 0 0 0-.879 0v2.274c0 .11-.067.258-.228.365l-.048.015a.4.4 0 0 0-.116.018.4.4 0 0 1-.362-.208l-.938-1.58-.006-.01c-.24-.382-.341-.822-.341-1.282v-.059c0-.215.157-.363.274-.383l.014-.002c.368-.074.717-.283.987-.445l.03-.017.035-.024 1.8-1.334c.475-.314.972-.504 1.5-.504h2.96c.528 0 1.025.19 1.5.504l1.8 1.334.036.024.03.018c.27.161.618.37.986.444l.014.002c.117.02.274.168.274.383v.059c0 .46-.102.9-.341 1.283l-.006.009-.934 1.574-.004.006a.4.4 0 0 1-.358.208.4.4 0 0 0-.12-.018l-.048-.015c-.161-.107-.228-.256-.228-.365V5.054a.44.44 0 0 0-.88 0v2.274c0 .467.276.892.68 1.134l.026.014c-.041.886-.247 1.328-.403 1.538a.7.7 0 0 1-.205.195l-.036.018a.44.44 0 0 0-.317.57l1.343 3.906a.44.44 0 0 0 .832-.285l-1.223-3.556c.102-.08.21-.185.314-.325.283-.384.518-.994.571-1.968.325-.076.621-.277.814-.612l.93-1.567C14.87 5.84 15 5.234 15 4.646v-.059c0-.597-.422-1.146-1.001-1.247-.197-.041-.414-.164-.712-.342l-1.793-1.329-.018-.012C10.902 1.274 10.234 1 9.481 1zm3.373 3.556q-.03 0-.059.004a.556.556 0 0 0 .059 1.108H9.9q.03 0 .06-.004a.556.556 0 0 0-.06-1.108h-.007M7.698 5.664a.44.44 0 1 0 0 .879h.584a.44.44 0 1 0 0-.879zM5.99 9.034q.195.129.4.19v.26a1.61 1.61 0 0 0 1.608 1.606 1.61 1.61 0 0 0 1.608-1.605v-.268a1.5 1.5 0 0 0 .413-.211c.504-.42.694-1.198.251-1.817a.44.44 0 0 0-.715.51c.136.192.1.452-.082.617a.65.65 0 0 1-.642.021l-.635-.346a.44.44 0 0 0-.42 0l-.635.346a.65.65 0 0 1-.642-.021.463.463 0 0 1-.082-.617.439.439 0 1 0-.716-.51c-.443.619-.252 1.398.252 1.817zm.114-4.478h-.008a.556.556 0 1 0 0 1.112h.008a.556.556 0 1 0 0-1.112"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPetFill.displayName = 'PetFill';
const Memo = memo(SvgPetFill);
export default Memo;
