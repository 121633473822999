// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTwoDiceGameRandomOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.182 2.922a1.833 1.833 0 0 1 2.35-1.096l2.192.798a1.833 1.833 0 0 1 1.096 2.35l-.798 2.193a1.833 1.833 0 0 1-2.35 1.095l-2.193-.798a1.833 1.833 0 0 1-1.095-2.35zm2.007-.156a.833.833 0 0 0-1.068.498l-.798 2.193a.833.833 0 0 0 .498 1.068l2.193.798a.833.833 0 0 0 1.068-.498l.798-2.193a.833.833 0 0 0-.498-1.068zM6.697 6.79a.833.833 0 0 0-1.107-.403l-3.323 1.55a.833.833 0 0 0-.403 1.107l1.55 3.323a.833.833 0 0 0 1.107.403l3.323-1.55a.833.833 0 0 0 .403-1.107zm-1.53-1.31a1.833 1.833 0 0 1 2.437.887l1.55 3.323c.427.918.03 2.009-.888 2.437l-3.323 1.55a1.833 1.833 0 0 1-2.436-.887L.957 9.466a1.833 1.833 0 0 1 .887-2.436z"
        clipRule="evenodd"
      />
      <Path d="M4.275 8.901a.667.667 0 1 1-1.208.564.667.667 0 0 1 1.208-.564M6.934 9.869a.667.667 0 1 1-1.209.563.667.667 0 0 1 1.209-.563M12.294 5.228a.667.667 0 1 1-1.253-.456.667.667 0 0 1 1.253.456" />
    </Svg>
  );
};
SvgTwoDiceGameRandomOutline.displayName = 'TwoDiceGameRandomOutline';
const Memo = memo(SvgTwoDiceGameRandomOutline);
export default Memo;
