// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCodeInsertOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.829 1.167a.5.5 0 0 1 .5.5v1h1.5c1.012 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833h-1.5v1a.5.5 0 1 1-1 0V1.667a.5.5 0 0 1 .5-.5m.5 11.166h1.5c.46 0 .833-.373.833-.833v-7a.833.833 0 0 0-.833-.833h-1.5zM3.165 3.667a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h5.667a.5.5 0 0 1 0 1H3.165A1.833 1.833 0 0 1 1.332 11.5v-7c0-1.013.82-1.833 1.833-1.833h5.667a.5.5 0 1 1 0 1zM5.312 5.98a.5.5 0 0 1 .707 0l1.667 1.666a.5.5 0 0 1 0 .708L6.019 10.02a.5.5 0 1 1-.707-.707L6.625 8 5.312 6.687a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCodeInsertOutline.displayName = 'CodeInsertOutline';
const Memo = memo(SvgCodeInsertOutline);
export default Memo;
