// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicHandsMagicRainbowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 3a5 5 0 0 0-4.644 3.143.5.5 0 1 1-.928-.372 6.002 6.002 0 0 1 11.144 0 .5.5 0 0 1-.928.372A5 5 0 0 0 7.999 3m0 1.667a3.335 3.335 0 0 0-3.096 2.095.5.5 0 0 1-.928-.372 4.335 4.335 0 0 1 8.048 0 .5.5 0 0 1-.928.372 3.335 3.335 0 0 0-3.096-2.095m0 1.666c-.701 0-1.302.433-1.548 1.048a.5.5 0 0 1-.929-.372 2.667 2.667 0 0 1 4.953 0 .5.5 0 1 1-.928.372 1.67 1.67 0 0 0-1.548-1.048M4.515 9.312a1.125 1.125 0 0 0-2.115.77l.47 1.291a1.5 1.5 0 0 0 1.923.897l.314-.114a1.5 1.5 0 0 0 .896-1.923.357.357 0 0 0-.458-.213l-.17-.47.17.47-.212.077a.5.5 0 0 1-.641-.299zm.955-.3-.016-.042a2.125 2.125 0 1 0-3.993 1.453l.47 1.292a2.5 2.5 0 0 0 3.204 1.494l.314-.114a2.5 2.5 0 0 0 1.494-3.204 1.36 1.36 0 0 0-1.473-.879m5.075-.042a2.125 2.125 0 1 1 3.994 1.453l-.47 1.292a2.5 2.5 0 0 1-3.205 1.494l-.313-.114a2.5 2.5 0 0 1-1.494-3.204 1.36 1.36 0 0 1 1.473-.879zm2.382-.33a1.125 1.125 0 0 0-1.442.672l-.177.486a.5.5 0 0 1-.641.3l-.213-.078.171-.47-.17.47a.357.357 0 0 0-.459.213 1.5 1.5 0 0 0 .897 1.923l.313.114a1.5 1.5 0 0 0 1.923-.897l.47-1.292a1.125 1.125 0 0 0-.672-1.442"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMagicHandsMagicRainbowOutline.displayName = 'MagicHandsMagicRainbowOutline';
const Memo = memo(SvgMagicHandsMagicRainbowOutline);
export default Memo;
