// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgZaloBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.881 1.28c-.88 0-1.6.72-1.6 1.6v10.24c0 .88.72 1.6 1.6 1.6h10.24c.88 0 1.6-.72 1.6-1.6V2.88c0-.88-.72-1.6-1.6-1.6zm0 .64h2.106C3.844 3.109 3.2 4.646 3.2 6.24c0 1.651.676 3.232 1.892 4.429.038.067.07.396-.077.777-.093.24-.279.554-.637.672a.32.32 0 0 0-.218.324c.01.144.116.265.257.294.918.183 1.513-.093 1.993-.31.432-.199.717-.333 1.155-.154.896.349 1.85.528 2.835.528 1.31 0 2.57-.32 3.68-.924v1.244c0 .534-.426.96-.96.96H2.881a.955.955 0 0 1-.96-.96V2.88c0-.534.426-.96.96-.96m7.68 2.88a.32.32 0 0 1 .32.32V8a.32.32 0 0 1-.32.32.32.32 0 0 1-.32-.32V5.12a.32.32 0 0 1 .32-.32m-4.8.32h1.6c.115 0 .225.064.282.166.055.1.05.224-.01.323L6.338 7.68H7.36a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.32h-1.6a.33.33 0 0 1-.282-.166.32.32 0 0 1 .01-.323L6.785 5.76H5.761a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.32m3.04.96c.195 0 .378.054.54.144a.31.31 0 0 1 .26-.144.32.32 0 0 1 .32.32V8a.32.32 0 0 1-.32.32.31.31 0 0 1-.26-.144c-.162.09-.345.144-.54.144a1.121 1.121 0 0 1 0-2.24m3.52 0a1.121 1.121 0 0 1 0 2.24 1.121 1.121 0 0 1 0-2.24m-3.52.64a.48.48 0 0 0-.398.211.48.48 0 0 0 0 .538.48.48 0 1 0 .398-.749m3.52 0a.484.484 0 0 0-.47.383.5.5 0 0 0 .072.366.48.48 0 0 0 .495.201.479.479 0 0 0-.097-.95" />
    </Svg>
  );
};
SvgZaloBw.displayName = 'ZaloBw';
const Memo = memo(SvgZaloBw);
export default Memo;
