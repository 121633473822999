// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCelebratePartyConfettiFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.275.674a.5.5 0 0 1 .577.408v.005q.012.07.02.14c.011.086.024.206.032.35.017.28.018.668-.053 1.052-.082.44-.289.844-.458 1.12q-.149.25-.334.477l-.002.003-.001.001a.5.5 0 0 1-.767-.642 3 3 0 0 0 .25-.36c.142-.231.279-.513.329-.781.05-.274.052-.572.038-.812a4 4 0 0 0-.04-.381v-.003a.5.5 0 0 1 .409-.577M12.791 1.725a.5.5 0 0 1 .3.64l-.308.857a.5.5 0 0 1-.941-.34l.309-.857a.5.5 0 0 1 .64-.3M14.614 4.714a.5.5 0 0 1-.224.67l-.687.344a.5.5 0 1 1-.446-.895l.686-.343a.5.5 0 0 1 .671.224M11.43 4.584a.5.5 0 0 1 0 .707l-.687.686a.5.5 0 0 1-.707-.708l.687-.685a.5.5 0 0 1 .707 0M4.027 5.623a1.836 1.836 0 0 1 3.006-.628l3.98 3.973a1.835 1.835 0 0 1-.628 3.008L3.84 14.537c-1.49.584-2.963-.888-2.378-2.38zM11.607 7.316a.5.5 0 0 1 .52-.478h.004q.183.01.365.035c.21.028.5.08.793.173.274.087.563.23.77.34q.19.1.37.211a.5.5 0 0 1-.526.85 6 6 0 0 0-.314-.178 4 4 0 0 0-.605-.27 3.7 3.7 0 0 0-.897-.162h-.002a.5.5 0 0 1-.478-.521" />
    </Svg>
  );
};
SvgCelebratePartyConfettiFill.displayName = 'CelebratePartyConfettiFill';
const Memo = memo(SvgCelebratePartyConfettiFill);
export default Memo;
