// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgImacComputerDevice2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.165 2c-1.012 0-1.833.82-1.833 1.833v4.5h13.333v-4.5c0-1.012-.82-1.833-1.833-1.833z" />
      <Path
        fillRule="evenodd"
        d="M1.332 10.167v-.834h13.333v.834c0 1.012-.82 1.833-1.833 1.833H9.999v2.167a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V12H3.165a1.833 1.833 0 0 1-1.833-1.833M6.999 12v1.667h2V12z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgImacComputerDevice2Fill.displayName = 'ImacComputerDevice2Fill';
const Memo = memo(SvgImacComputerDevice2Fill);
export default Memo;
