import type {Color, IconColor} from '@propertyguru/hive';

export const knobColor = (disabled: boolean | null, checked: boolean): Color => {
  return disabled && !checked ? 'fill/inactive/primary' : 'fill/neutral/secondary';
};

export const trackDisabledColor = (checked: boolean): Color => {
  return checked ? 'fill/inactive/tertiary' : 'fill/inactive/primary';
};

export const knobIconColor = (disabled: boolean | null): IconColor => {
  return disabled ? 'icon/inactive/primary' : 'icon/success/primary';
};
