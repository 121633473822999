// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPicnicOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.29 5.036q.24.252.56.383a1.708 1.708 0 0 0 1.319 0 1.8 1.8 0 0 0 .56-.383 1.6 1.6 0 0 0 .372-.579 1.862 1.862 0 0 0 0-1.361 1.9 1.9 0 0 0-.371-.578 1.6 1.6 0 0 0-.56-.384 1.69 1.69 0 0 0-1.32 0 1.8 1.8 0 0 0-.56.384 1.6 1.6 0 0 0-.372.578 1.862 1.862 0 0 0 0 1.361q.134.324.372.579m.407-1.607a.9.9 0 0 1 .48-.491.85.85 0 0 1 .675.005q.162.069.287.2a.921.921 0 0 1 .183.993.9.9 0 0 1-.48.49.86.86 0 0 1-.962-.2.921.921 0 0 1-.183-.993z" />
      <Path d="M13.579 8.627A.43.43 0 0 0 14 8.192a.43.43 0 0 0-.421-.434H10.23a1.194 1.194 0 0 0-.293-1.182 1.15 1.15 0 0 0-.822-.353H7.529l-1.24-.64a1.1 1.1 0 0 0-.436-.123c-.154-.01-.308.01-.451.056a1.2 1.2 0 0 0-.397.236c-.114.102-.208.23-.278.368l-1.86 3.838a1.3 1.3 0 0 0-.123.593c0 .092.02.19.044.277h-.367a.43.43 0 0 0-.421.434c0 .241.188.435.421.435h1.067v1.868c0 .24.188.435.421.435a.43.43 0 0 0 .422-.435v-1.868h.971l.481 1.48c.08.24.224.444.427.593a1.13 1.13 0 0 0 1.21.092 1.227 1.227 0 0 0 .575-1.453l-.744-2.303a1.2 1.2 0 0 0-.426-.593 1.13 1.13 0 0 0-.68-.226h-.347l.496-1.023.447.23c.158.082.342.128.52.128h2.415l-.5 1.817H9.12a.43.43 0 0 0-.422.435c0 .174.1.322.248.394l-.6 2.174c-.064.23.065.471.288.537q.062.015.114.016a.425.425 0 0 0 .406-.317l.655-2.37h2.34l.655 2.37a.43.43 0 0 0 .407.317q.06 0 .114-.015a.436.436 0 0 0 .287-.538l-.6-2.174a.44.44 0 0 0 .248-.394.43.43 0 0 0-.421-.435h-.055l-.5-1.817h1.299zm-4.463-.87h-1.86q-.076.001-.144-.035l-.823-.425a.415.415 0 0 0-.565.194l-.987 2.042a.45.45 0 0 0 .02.425.42.42 0 0 0 .357.204h1.027q.105 0 .188.062c.054.04.094.097.119.163l.744 2.303q.022.076.015.154a.3.3 0 0 1-.06.143.4.4 0 0 1-.114.102.322.322 0 0 1-.456-.19l-.575-1.775a.42.42 0 0 0-.402-.296H3.91a.35.35 0 0 1-.16-.041.35.35 0 0 1-.169-.276q-.002-.086.036-.164l1.859-3.838a.4.4 0 0 1 .074-.102.4.4 0 0 1 .11-.067.3.3 0 0 1 .099-.015h.024c.04 0 .085.015.12.036l1.328.685a.4.4 0 0 0 .189.046h1.686c.084 0 .168.036.228.098a.343.343 0 0 1 0 .47.32.32 0 0 1-.228.098zm2.787 2.687h-1.86l.5-1.817h.854l.5 1.817z" />
    </Svg>
  );
};
SvgPicnicOff.displayName = 'PicnicOff';
const Memo = memo(SvgPicnicOff);
export default Memo;
