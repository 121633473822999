// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBrainAiThinkingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.584 3.461a3.24 3.24 0 0 1 4.415-1.824 3.242 3.242 0 0 1 4.415 1.825A2.9 2.9 0 0 1 14.105 8a2.898 2.898 0 0 1-1.02 4.295 3.585 3.585 0 0 1-5.086 1.936 3.585 3.585 0 0 1-5.086-1.937A2.898 2.898 0 0 1 1.893 8a2.899 2.899 0 0 1 1.692-4.539m4.915 9.904a2.585 2.585 0 0 0 3.697-1.57l.066-.233.225-.092a1.9 1.9 0 0 0 .609-3.113L12.73 8l.365-.357A1.899 1.899 0 0 0 11.974 4.4l-.347-.037-.085-.338A2.242 2.242 0 0 0 8.5 2.509v2.16c.001.92.747 1.664 1.666 1.664a.5.5 0 0 1 0 1c-.63 0-1.21-.219-1.666-.585zm-1-8.695V2.509a2.242 2.242 0 0 0-3.043 1.516l-.086.338-.346.037a1.898 1.898 0 0 0-1.122 3.243L3.266 8l-.364.357a1.899 1.899 0 0 0 .609 3.113l.224.092.067.233a2.585 2.585 0 0 0 3.697 1.57v-2.032c0-.92-.746-1.666-1.667-1.666a.5.5 0 0 1 0-1c.63 0 1.21.219 1.667.585z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBrainAiThinkingOutline.displayName = 'BrainAiThinkingOutline';
const Memo = memo(SvgBrainAiThinkingOutline);
export default Memo;
