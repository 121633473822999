// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgQuestionmarkFaqHelpQuestionaireFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.334a6.667 6.667 0 1 0 0 13.333 6.667 6.667 0 0 0 0-13.333m-.5 4.333a.5.5 0 0 0-.5.5.5.5 0 0 1-1 0 1.5 1.5 0 0 1 1.5-1.5h.805a1.694 1.694 0 0 1 .94 3.104l-.374.25a.83.83 0 0 0-.371.693v.12a.5.5 0 0 1-1 0v-.12c0-.613.306-1.186.816-1.526l.375-.25a.694.694 0 0 0-.386-1.271zm.5 4.333a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgQuestionmarkFaqHelpQuestionaireFill.displayName = 'QuestionmarkFaqHelpQuestionaireFill';
const Memo = memo(SvgQuestionmarkFaqHelpQuestionaireFill);
export default Memo;
