// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPizzaFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.334 3a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
      <Path d="M7.334 4c0-.362.096-.702.265-.996l-3.143.54a9.66 9.66 0 0 0 3.577 6.061 2.667 2.667 0 0 1 4.92-.954l.722-4.209a1.833 1.833 0 0 0-2.117-2.116l-.92.157A2 2 0 1 1 7.334 4" />
      <Path d="m11.665 11.383.176-.176a1.667 1.667 0 1 0-2.84-1.183v.25a9.6 9.6 0 0 0 2.664 1.109" />
      <Path d="M12.289 12.53A10.675 10.675 0 0 1 3.47 3.712l-.39.067c-.99.17-1.72 1.143-1.405 2.179a12.5 12.5 0 0 0 3.14 5.226 12.5 12.5 0 0 0 5.227 3.141c1.036.315 2.009-.415 2.179-1.406z" />
    </Svg>
  );
};
SvgPizzaFill.displayName = 'PizzaFill';
const Memo = memo(SvgPizzaFill);
export default Memo;
