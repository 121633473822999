// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBathOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.971 4.317a.523.523 0 0 1-.694-.129.45.45 0 0 1 .139-.647l.78-.486-.326-.615-.003-.005a1.2 1.2 0 0 0-1.057-.611c-.655 0-1.185.499-1.185 1.115v4.54h10.5c.483 0 .875.368.875.823s-.392.823-.875.823v2.883c0 1.117-.946 2.027-2.125 2.058v.467c0 .258-.224.467-.5.467s-.5-.209-.5-.467v-.466H5v.466c0 .258-.224.467-.5.467s-.5-.209-.5-.467v-.466c-1.18-.031-2.125-.942-2.125-2.06V9.126c-.483 0-.875-.368-.875-.823s.392-.824.875-.824h.875v-4.54C2.75 1.869 3.672 1 4.81 1c.654 0 1.26.292 1.646.773q.111.138.196.299l.28.526.652-.406a.5.5 0 0 1 .21-.074.52.52 0 0 1 .483.204.45.45 0 0 1-.138.647zM2.875 8.412v3.596c0 .618.521 1.108 1.153 1.125h7.944c.632-.017 1.153-.507 1.153-1.125V8.412z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBathOutline.displayName = 'BathOutline';
const Memo = memo(SvgBathOutline);
export default Memo;
