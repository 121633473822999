// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDongCurrencyMoneyCoinDongFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.457 6.956q-.242-.168-.496-.264a1.7 1.7 0 0 0-.648-.108c-.272 0-.504.06-.708.176-.208.12-.368.28-.488.488q-.175.298-.176.704c0 .368.112.656.32.88.22.216.488.328.828.328s.588-.08.8-.228q.331-.227.572-.58V6.956z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7.867 1.2A6.666 6.666 0 0 0 1.2 7.868a6.666 6.666 0 0 0 6.668 6.668 6.669 6.669 0 0 0 0-13.336m2 10.5H5.2a.5.5 0 0 1 0-1h4.668a.5.5 0 0 1 0 1m0-6.668H9.4v4.58a.47.47 0 0 1-.128.32h-.004l-.004.008a.47.47 0 0 1-.332.124.48.48 0 0 1-.32-.116L8.6 9.94l-.008-.008a.46.46 0 0 1-.128-.316q-.199.145-.428.248a2.2 2.2 0 0 1-.948.204q-.576.002-1.056-.26a2 2 0 0 1-.752-.736 2.27 2.27 0 0 1-.272-1.124q-.002-.656.296-1.18a2.17 2.17 0 0 1 .828-.808 2.4 2.4 0 0 1 1.184-.292q.414 0 .768.108.193.054.38.132v-.884h-.596a.5.5 0 0 1 0-1h.596v-.58c0-.124.044-.24.14-.328h.004l.004-.008a.5.5 0 0 1 .32-.116.448.448 0 0 1 .472.452v.58h.468a.5.5 0 0 1 0 1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDongCurrencyMoneyCoinDongFill.displayName = 'DongCurrencyMoneyCoinDongFill';
const Memo = memo(SvgDongCurrencyMoneyCoinDongFill);
export default Memo;
