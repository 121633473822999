// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSunriseOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.003 1.333a.5.5 0 0 1 .5.5v.687a.5.5 0 1 1-1 0v-.687a.5.5 0 0 1 .5-.5M3.289 3.29a.5.5 0 0 1 .707 0l.485.485a.5.5 0 1 1-.708.707l-.484-.485a.5.5 0 0 1 0-.707m9.427 0a.5.5 0 0 1 0 .707l-.484.485a.5.5 0 0 1-.708-.707l.485-.485a.5.5 0 0 1 .707 0M8.003 5.667a2.336 2.336 0 0 0-2.334 2.338v.162a.5.5 0 0 1-1 0v-.162a3.336 3.336 0 0 1 3.334-3.338 3.336 3.336 0 0 1 3.333 3.338v.162a.5.5 0 1 1-1 0v-.162a2.336 2.336 0 0 0-2.333-2.338m-6.667 2.5a.5.5 0 0 1 .5-.5h.685a.5.5 0 0 1 0 1h-.685a.5.5 0 0 1-.5-.5m11.648 0a.5.5 0 0 1 .5-.5h.685a.5.5 0 0 1 0 1h-.685a.5.5 0 0 1-.5-.5M1.336 10.833a.5.5 0 0 1 .5-.5h12.333a.5.5 0 1 1 0 1H1.836a.5.5 0 0 1-.5-.5M4.003 13.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSunriseOutline.displayName = 'SunriseOutline';
const Memo = memo(SvgSunriseOutline);
export default Memo;
