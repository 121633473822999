// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMoveFocusFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.588 2.797a.833.833 0 0 0-1.179 0L6.352 3.854a.5.5 0 0 1-.707-.707L6.702 2.09a1.833 1.833 0 0 1 2.593 0l1.057 1.057a.5.5 0 1 1-.707.707zm-4.736 2.85a.5.5 0 0 1 0 .707L2.795 7.411a.833.833 0 0 0 0 1.179l1.057 1.057a.5.5 0 1 1-.707.707L2.088 9.297a1.833 1.833 0 0 1 0-2.593l1.057-1.057a.5.5 0 0 1 .707 0m8.293 0a.5.5 0 0 1 .707 0l1.057 1.057a1.833 1.833 0 0 1 0 2.593l-1.057 1.057a.5.5 0 1 1-.707-.707l1.057-1.057a.833.833 0 0 0 0-1.179l-1.057-1.057a.5.5 0 0 1 0-.707m-6.5 6.5a.5.5 0 0 1 .707 0l1.057 1.057a.833.833 0 0 0 1.179 0l1.057-1.057a.5.5 0 1 1 .707.707l-1.057 1.057a1.833 1.833 0 0 1-2.593 0l-1.057-1.057a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMoveFocusFill.displayName = 'MoveFocusFill';
const Memo = memo(SvgMoveFocusFill);
export default Memo;
