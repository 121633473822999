// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLockPrivateFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 1.5a3.333 3.333 0 0 0-3.333 3.333V6h-.167c-1.012 0-1.833.82-1.833 1.833v5c0 1.013.82 1.834 1.833 1.834h7c1.013 0 1.834-.821 1.834-1.834v-5C13.335 6.821 12.514 6 11.5 6h-.166V4.833A3.333 3.333 0 0 0 8 1.5M10.335 6V4.833a2.333 2.333 0 1 0-4.667 0V6zM8 8.833a.5.5 0 0 1 .5.5v2a.5.5 0 1 1-1 0v-2a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLockPrivateFill.displayName = 'LockPrivateFill';
const Memo = memo(SvgLockPrivateFill);
export default Memo;
