// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMarkdownOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833v-7a.833.833 0 0 0-.833-.833zm1.142 2.371a.5.5 0 0 1 .545.109L6 7.293l1.146-1.146A.5.5 0 0 1 8 6.5v3a.5.5 0 0 1-1 0V7.707l-.647.647a.5.5 0 0 1-.707 0L5 7.707V9.5a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .308-.462M10.5 6a.5.5 0 0 1 .5.5v1.793l.313-.313a.5.5 0 1 1 .707.707l-1.167 1.167a.5.5 0 0 1-.707 0L8.978 8.687a.5.5 0 0 1 .708-.707l.313.313V6.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMarkdownOutline.displayName = 'MarkdownOutline';
const Memo = memo(SvgMarkdownOutline);
export default Memo;
