// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUnderlineOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.836 2a.5.5 0 0 1 .5.5V8a3.667 3.667 0 0 0 7.333 0V2.5a.5.5 0 1 1 1 0V8a4.667 4.667 0 0 1-9.333 0V2.5a.5.5 0 0 1 .5-.5m-.5 11.833a.5.5 0 0 1 .5-.5h8.333a.5.5 0 0 1 0 1H3.836a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUnderlineOutline.displayName = 'UnderlineOutline';
const Memo = memo(SvgUnderlineOutline);
export default Memo;
