// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCustomerServiceCustomerSupportOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.906 3.156a4.375 4.375 0 0 1 7.447 2.657.875.875 0 0 0-.853.875v4.375a.875.875 0 0 0 .875.875 1.31 1.31 0 0 1-1.312 1.312h-.95a1.31 1.31 0 0 0-1.238-.875H8A1.313 1.313 0 0 0 8 15h.875a1.31 1.31 0 0 0 1.237-.875h.95a2.19 2.19 0 0 0 2.188-2.187v-.045A2.19 2.19 0 0 0 15 9.75V8a2.19 2.19 0 0 0-1.765-2.146 5.25 5.25 0 0 0-10.47 0A2.19 2.19 0 0 0 1 8v1.75a2.19 2.19 0 0 0 2.188 2.188h.437a.875.875 0 0 0 .875-.875V6.688a.875.875 0 0 0-.853-.875c.1-1 .543-1.94 1.26-2.657m4.407 10.532a.44.44 0 0 0-.438-.438H8a.438.438 0 0 0 0 .875h.875a.437.437 0 0 0 .438-.437m3.5-2.625h-.438V6.688h.438A1.313 1.313 0 0 1 14.125 8v1.75a1.31 1.31 0 0 1-1.312 1.313M3.188 6.688A1.313 1.313 0 0 0 1.875 8v1.75a1.31 1.31 0 0 0 1.313 1.313h.437V6.688z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCustomerServiceCustomerSupportOutline.displayName = 'CustomerServiceCustomerSupportOutline';
const Memo = memo(SvgCustomerServiceCustomerSupportOutline);
export default Memo;
