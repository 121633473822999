// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDownloadCircleArrowDownFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m4.5 3.334a.5.5 0 0 1 0-1h4.333a.5.5 0 0 1 0 1zm4.02-3.48-1.5 1.5a.5.5 0 0 1-.707 0l-1.5-1.5a.5.5 0 0 1 .707-.707l.647.646V5.167a.5.5 0 0 1 1 0v2.626l.646-.646a.5.5 0 0 1 .707.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDownloadCircleArrowDownFill.displayName = 'DownloadCircleArrowDownFill';
const Memo = memo(SvgDownloadCircleArrowDownFill);
export default Memo;
