// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAvEquipmentPresentationOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.225 2.881v.86l.01.001h11.544V3.74l.001-.007v-.86H2.226zm11.555-.445V2h.02a1 1 0 0 1 .123.013c.07.011.172.035.278.087a.83.83 0 0 1 .324.278c.096.14.144.308.144.493v.871h-.444.444v.019a1 1 0 0 1-.102.394.8.8 0 0 1-.283.317.9.9 0 0 1-.504.141H2.225v-.435.435h-.02a1 1 0 0 1-.123-.012 1 1 0 0 1-.278-.088.83.83 0 0 1-.324-.277.86.86 0 0 1-.144-.494v-.87h.444-.444v-.02a1 1 0 0 1 .013-.12 1 1 0 0 1 .09-.273.8.8 0 0 1 .282-.318A.9.9 0 0 1 2.225 2H13.78zm.025 1.304"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M2.227 4.178a.44.44 0 0 1 .444-.436h10.667a.44.44 0 0 1 .444.436v7.84a.44.44 0 0 1-.444.435.44.44 0 0 1-.445-.435V4.613H3.115v7.405a.44.44 0 0 1-.444.435.44.44 0 0 1-.444-.435z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M1.336 12.018a.44.44 0 0 1 .444-.436H14.2a.44.44 0 0 1 .444.436.44.44 0 0 1-.444.435H1.78a.44.44 0 0 1-.444-.435"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7.82 11.644a.44.44 0 0 1 .444.435v1.485a.44.44 0 0 1-.445.435.44.44 0 0 1-.444-.435v-1.485a.44.44 0 0 1 .444-.435M6.84 6.592c-.051.03-.107.094-.123.21-.039.283-.07.643-.07 1.067v.001q-.002.536.07 1.067c.016.115.072.179.123.209.05.03.131.048.246.004.26-.1.605-.25 1.049-.473h.001a14 14 0 0 0 1.003-.556c.14-.086.174-.186.174-.252 0-.065-.034-.165-.174-.252q-.489-.298-1.003-.555h-.001a12 12 0 0 0-1.049-.473c-.115-.044-.196-.026-.246.003m.57-.815a1.18 1.18 0 0 0-1.026.068 1.14 1.14 0 0 0-.548.84 9 9 0 0 0-.078 1.184h.444-.444q-.002.593.078 1.183v.001c.05.358.247.664.548.84.302.178.674.203 1.026.068m0-4.184c.293.113.665.275 1.13.51q.548.274 1.07.591c.37.229.592.593.592.991 0 .399-.221.763-.591.99l-.002.001q-.521.319-1.069.592c-.466.235-.837.397-1.13.509"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAvEquipmentPresentationOutline.displayName = 'AvEquipmentPresentationOutline';
const Memo = memo(SvgAvEquipmentPresentationOutline);
export default Memo;
