// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalenderRemoveOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.167 1.333a.5.5 0 0 1 .5.5v.834h4.666v-.834a.5.5 0 0 1 1 0v.834h.834c1.012 0 1.833.82 1.833 1.833v3a.5.5 0 0 1-1 0v-.833H3v5.5c0 .46.373.833.833.833H7.5a.5.5 0 1 1 0 1H3.833A1.833 1.833 0 0 1 2 12.167V4.5c0-1.013.82-1.833 1.833-1.833h.834v-.834a.5.5 0 0 1 .5-.5M3 5.667h10V4.5a.833.833 0 0 0-.833-.833H3.833A.833.833 0 0 0 3 4.5zm7.146 4.48a.5.5 0 0 1 .707 0l1.146 1.146 1.147-1.147a.5.5 0 0 1 .707.707L12.706 12l1.147 1.146a.5.5 0 1 1-.707.707l-1.147-1.146-1.146 1.146a.5.5 0 1 1-.707-.707L11.292 12l-1.146-1.146a.5.5 0 0 1 0-.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCalenderRemoveOutline.displayName = 'CalenderRemoveOutline';
const Memo = memo(SvgCalenderRemoveOutline);
export default Memo;
