// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRulerOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.37 1.756a1.833 1.833 0 0 1 2.593 0l2.28 2.281a1.833 1.833 0 0 1 0 2.593L6.63 14.244a1.833 1.833 0 0 1-2.593 0l-2.281-2.28a1.833 1.833 0 0 1 0-2.594zm1.886.707a.833.833 0 0 0-1.179 0l-1.12 1.12.73.73a.5.5 0 1 1-.707.707l-.73-.73-1.626 1.627L8.02 7.313a.5.5 0 1 1-.707.707L5.916 6.624 4.29 8.25l.73.73a.5.5 0 0 1-.707.707l-.73-.73-1.12 1.12a.833.833 0 0 0 0 1.179l2.28 2.281a.833.833 0 0 0 1.18 0l7.614-7.614a.833.833 0 0 0 0-1.179z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRulerOutline.displayName = 'RulerOutline';
const Memo = memo(SvgRulerOutline);
export default Memo;
