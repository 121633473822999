// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNewspaperNewsPaperFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.999 5.667v2h2.666v-2z" />
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2H9.5c1.012 0 1.833.82 1.833 1.833V7.5h1.5c1.013 0 1.833.82 1.833 1.833v2.5c0 1.197-.97 2.167-2.166 2.167h-9a2.167 2.167 0 0 1-2.167-2.167zM12.499 13c.644 0 1.166-.522 1.166-1.167v-2.5a.833.833 0 0 0-.833-.833h-1.5v3.333c0 .645.522 1.167 1.167 1.167m-8-3a.5.5 0 0 0 0 1h3.666a.5.5 0 0 0 0-1zm-.5-4.833a.5.5 0 0 1 .5-.5h3.666a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1-.5-.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNewspaperNewsPaperFill.displayName = 'NewspaperNewsPaperFill';
const Memo = memo(SvgNewspaperNewsPaperFill);
export default Memo;
