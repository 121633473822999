// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayoutGridListSearchFindMagifierOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.613 2h2.22a.5.5 0 0 1 .5.5v4.333a.5.5 0 0 1-.5.5H2.5a.5.5 0 0 1-.5-.5v-2.22c0-.356 0-.653.02-.896.02-.253.065-.491.18-.716.176-.345.456-.625.801-.801.225-.115.463-.16.716-.18.243-.02.54-.02.896-.02m-.815 1.017c-.191.015-.283.043-.343.074a.83.83 0 0 0-.364.364c-.03.06-.059.152-.074.343C3 3.996 3 4.252 3 4.633v1.7h3.333V3h-1.7c-.381 0-.637 0-.835.017m8.404 0A12 12 0 0 0 11.367 3h-1.7v3.333H13v-1.7c0-.381 0-.637-.017-.835-.015-.191-.043-.283-.074-.343a.83.83 0 0 0-.364-.364c-.06-.03-.152-.059-.343-.074m.081-.997c.253.02.491.065.716.18.345.176.625.456.801.801.115.225.16.463.18.716.02.243.02.54.02.896v2.22a.5.5 0 0 1-.5.5H9.167a.5.5 0 0 1-.5-.5V2.5a.5.5 0 0 1 .5-.5h2.22c.356 0 .653 0 .896.02M2 9.167a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 .5.5V13.5a.5.5 0 0 1-.5.5h-2.22c-.356 0-.653 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.2 13a1.9 1.9 0 0 1-.18-.716C2 12.04 2 11.743 2 11.387zm1 .5v1.7c0 .381 0 .637.017.835.015.191.043.283.074.343.08.157.207.284.364.364.06.03.152.059.343.074.198.017.454.017.835.017h1.7V9.667zm8.333.005a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333m-2.666 1.666a2.667 2.667 0 1 1 4.874 1.496l.898.898a.5.5 0 1 1-.707.707l-.897-.897a2.667 2.667 0 0 1-4.169-2.204"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLayoutGridListSearchFindMagifierOutline.displayName = 'LayoutGridListSearchFindMagifierOutline';
const Memo = memo(SvgLayoutGridListSearchFindMagifierOutline);
export default Memo;
