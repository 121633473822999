// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChromecastCastOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2h9.667c1.013 0 1.833.82 1.833 1.833v8.334c0 1.012-.82 1.833-1.833 1.833h-1.667a.5.5 0 1 1 0-1h1.667c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.832 3H3.165a.833.833 0 0 0-.833.833v.334a.5.5 0 0 1-1 0zm0 2.667a.5.5 0 0 1 .5-.5 7.5 7.5 0 0 1 7.5 7.5.5.5 0 0 1-1 0 6.5 6.5 0 0 0-6.5-6.5.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5A4.833 4.833 0 0 1 6.665 13.5a.5.5 0 0 1-1 0 3.833 3.833 0 0 0-3.833-3.833.5.5 0 0 1-.5-.5m0 2.666a.5.5 0 0 1 .5-.5c1.197 0 2.167.97 2.167 2.167a.5.5 0 0 1-1 0c0-.644-.523-1.167-1.167-1.167a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChromecastCastOutline.displayName = 'ChromecastCastOutline';
const Memo = memo(SvgChromecastCastOutline);
export default Memo;
