import type {BorderColor, FillColor, IconColor, TextColor} from '@propertyguru/hive';

import type {BadgeType, Colors, Size} from './types';

// Define the base color paths for each color variant
type ColorPaths = Record<
  Colors,
  Record<
    Size,
    {
      textColor: TextColor;
      backgroundColor: FillColor;
      iconColor?: IconColor;
      borderColor?: BorderColor;
    }
  >
>;

const colorPaths: ColorPaths = {
  neutral: {
    default: {
      textColor: 'text/active/secondary',
      iconColor: 'icon/active/secondary',
      borderColor: 'border/active/primary',
      backgroundColor: 'fill/neutral/primary',
    },
    small: {
      textColor: 'text/active/secondary',
      backgroundColor: 'fill/neutral/tertiary',
    },
  },
  lightNeutral: {
    default: {
      textColor: 'text/active/secondary',
      iconColor: 'icon/active/secondary',
      borderColor: 'none' as BorderColor,
      backgroundColor: 'fill/neutral/primary',
    },
    small: {
      textColor: 'text/active/secondary',
      backgroundColor: 'fill/neutral/primary',
    },
  },
  red: {
    default: {
      textColor: 'text/error/tertiary',
      iconColor: 'icon/error/tertiary',
      borderColor: 'border/error/primary',
      backgroundColor: 'fill/error/secondary',
    },
    small: {
      textColor: 'text/active/tertiary',
      backgroundColor: 'fill/error/primary',
    },
  },
  lightRed: {
    default: {
      textColor: 'text/error/tertiary',
      iconColor: 'icon/error/tertiary',
      borderColor: 'none' as BorderColor,
      backgroundColor: 'fill/active/secondary',
    },
    small: {
      textColor: 'text/error/tertiary',
      backgroundColor: 'fill/active/secondary',
    },
  },
  orange: {
    default: {
      textColor: 'text/warning/primary',
      iconColor: 'icon/warning/tertiary',
      borderColor: 'border/warning/primary',
      backgroundColor: 'fill/warning/secondary',
    },
    small: {
      textColor: 'text/active/tertiary',
      backgroundColor: 'fill/warning/primary',
    },
  },
  lightOrange: {
    default: {
      textColor: 'text/warning/primary',
      iconColor: 'icon/warning/tertiary',
      borderColor: 'none' as BorderColor,
      backgroundColor: 'fill/warning/secondary',
    },
    small: {
      textColor: 'text/warning/primary',
      backgroundColor: 'fill/warning/secondary',
    },
  },
  green: {
    default: {
      textColor: 'text/success/tertiary',
      iconColor: 'icon/success/tertiary',
      borderColor: 'border/success/primary',
      backgroundColor: 'fill/success/secondary',
    },
    small: {
      textColor: 'text/active/tertiary',
      backgroundColor: 'fill/success/primary',
    },
  },
  lightGreen: {
    default: {
      textColor: 'text/success/primary',
      iconColor: 'icon/success/tertiary',
      borderColor: 'none' as BorderColor,
      backgroundColor: 'fill/success/secondary',
    },
    small: {
      textColor: 'text/success/primary',
      backgroundColor: 'fill/success/secondary',
    },
  },
};

export const getColorBadge = (
  type: BadgeType,
  color: Colors,
  size: Size,
  disabled?: boolean,
): {
  textColor: TextColor;
  backgroundColor: FillColor | undefined;
  iconColor?: IconColor;
  borderColor?: BorderColor;
} => {
  if (disabled) {
    return {
      textColor: 'text/inactive/primary',
      iconColor: 'icon/inactive/primary',
      borderColor: 'border/inactive/primary',
      backgroundColor: type === 'primary' ? 'fill/inactive/primary' : undefined,
    };
  }

  let currentColorPaths;

  if (size === 'small' && type === 'primary') {
    // Get the specific color paths for the current color variant
    currentColorPaths = colorPaths[color][size] || colorPaths.neutral[size];

    return {
      textColor: currentColorPaths.textColor,
      iconColor: currentColorPaths.iconColor,
      borderColor: 'border/static/primary',
      backgroundColor: type === 'primary' ? currentColorPaths.backgroundColor : undefined,
    };
  }

  // Get the specific color paths for the current color variant for default size
  currentColorPaths = colorPaths[color]?.default || colorPaths.neutral.default;

  return {
    textColor: currentColorPaths.textColor,
    iconColor: currentColorPaths.iconColor,
    borderColor: currentColorPaths.borderColor,
    backgroundColor: type === 'primary' ? currentColorPaths.backgroundColor : undefined,
  };
};
