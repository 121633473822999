// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPoopSpamOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.165 2.5c0-.608.522-1.244 1.266-1.087 1.43.302 2.568 1.46 2.568 2.92 0 .64-.221 1.225-.588 1.703a2.334 2.334 0 0 1 1.64 3.41A2.334 2.334 0 0 1 12.331 14H3.665a2.333 2.333 0 0 1-.718-4.554A2.334 2.334 0 0 1 4.28 6.113a2.333 2.333 0 0 1 2.051-3.446H8a.167.167 0 0 0 .166-.167M5.45 6h.715a.5.5 0 1 1 0 1H5a1.333 1.333 0 0 0-.882 2.333h4.048a.5.5 0 1 1 0 1h-4.5a1.333 1.333 0 0 0 0 2.667h8.667a1.333 1.333 0 1 0 0-2.667h-1.167a.5.5 0 1 1 0-1h.716A1.333 1.333 0 0 0 10.999 7H9.165a.5.5 0 0 1 0-1h.789c.65-.37 1.045-.993 1.045-1.667 0-.898-.713-1.718-1.775-1.942h-.008q0-.002-.013.008a.14.14 0 0 0-.038.101c0 .644-.522 1.167-1.166 1.167H6.332A1.333 1.333 0 0 0 5.45 6"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPoopSpamOutline.displayName = 'PoopSpamOutline';
const Memo = memo(SvgPoopSpamOutline);
export default Memo;
