// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgToiletPaperWipeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.667 7.5c.276 0 .5-.373.5-.833s-.224-.834-.5-.834-.5.373-.5.834.224.833.5.833" />
      <Path
        fillRule="evenodd"
        d="M2.691 9.844c.396.761 1.01 1.402 1.809 1.481v.008h1.833v.834c0 1.012.821 1.833 1.834 1.833h4C13.179 14 14 13.18 14 12.167V6.5c0-1.165-.235-2.245-.639-3.052C12.973 2.671 12.344 2 11.5 2H4.667c-.88 0-1.553.676-1.976 1.49C2.255 4.328 2 5.452 2 6.667c0 1.214.255 2.338.691 3.177m.887-5.893C3.23 4.621 3 5.58 3 6.667s.23 2.046.578 2.715c.361.694.771.951 1.089.951s.727-.257 1.088-.95c.348-.67.578-1.63.578-2.716 0-.951-.176-1.805-.453-2.452a4 4 0 0 0-.125-.264C5.395 3.257 4.984 3 4.667 3s-.728.257-1.089.951"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgToiletPaperWipeFill.displayName = 'ToiletPaperWipeFill';
const Memo = memo(SvgToiletPaperWipeFill);
export default Memo;
