// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSwimSwimmingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.302 13.103c-1.034.212-1.723-.793-1.78-.846-.115-.105-.23-.211-.402-.211s-.344.053-.402.211a1.9 1.9 0 0 1-.689.635c-.287.158-.631.211-.918.211s-.574-.106-.861-.211c-.23-.159-.46-.37-.575-.582-.057-.159-.23-.211-.401-.264-.173 0-.345.053-.402.158a3 3 0 0 1-.804.635c-.345.158-.69.264-.976.264-.345 0-.632-.106-.919-.264a1.9 1.9 0 0 1-.689-.635c-.115-.105-.23-.211-.402-.211a.54.54 0 0 0-.401.159s-.977 1.11-2.01.898c-.287-.053-.517.106-.574.37-.057.265.115.476.402.529.172.053.344.053.46.053.918 0 1.664-.476 2.066-.846q.258.238.689.476c.402.211.919.37 1.435.37.517 0 .976-.159 1.436-.37.23-.106.459-.264.689-.423.172.159.344.317.574.423.402.211.86.37 1.377.37.46 0 .977-.159 1.379-.37.23-.106.459-.264.631-.423.46.423 1.263.952 2.354.74.287-.053.46-.264.402-.529-.172-.211-.46-.37-.69-.317M1.38 11.623c.173.053.345.053.46.053.918 0 1.665-.476 2.067-.846q.257.237.689.476c.401.211.918.37 1.435.37s.976-.159 1.435-.37c.23-.106.46-.265.69-.423.171.158.344.317.573.423.402.211.861.37 1.378.37.46 0 .976-.159 1.378-.37.23-.106.46-.265.632-.423.459.423 1.263.951 2.353.74.288-.053.46-.264.402-.529-.057-.264-.287-.423-.574-.37-1.033.211-1.722-.793-1.78-.846-.114-.106-.23-.211-.401-.211-.058 0-.115 0-.173.053L7.925 5.226l1.895-.899c.172-.053.287-.158.402-.317.115-.106.23-.264.287-.423s.057-.317.057-.476-.057-.317-.114-.476c-.058-.158-.173-.264-.345-.37-.115-.053-.287-.158-.46-.211C9.477 2 9.304 2 9.132 2s-.344.053-.517.106L6.146 3.27c-.287.158-.574.317-.747.581-.23.265-.344.529-.402.846a2.4 2.4 0 0 0 0 .899c.115.317.288.581.517.846l1.263 1.321-3.1 2.062c-.115.053-.172.159-.172.212-.287.264-1.034.898-1.895.74-.287-.053-.517.106-.574.37-.115.211.057.423.344.476" />
      <Path d="M10.783 7.499c.402.37.862.529 1.436.529s1.033-.212 1.435-.529c.402-.37.574-.793.574-1.322 0-.475-.23-.951-.574-1.321-.746-.688-2.067-.688-2.813 0a1.8 1.8 0 0 0-.574 1.321 1.57 1.57 0 0 0 .516 1.322" />
    </Svg>
  );
};
SvgSwimSwimmingFill.displayName = 'SwimSwimmingFill';
const Memo = memo(SvgSwimSwimmingFill);
export default Memo;
