import React from 'react';

import {useHive} from '@propertyguru/hive';

import {ElevationCompat} from '../Elevation/ElevationCompat';
import type {SurfaceCompatProps} from './SurfaceCompatProps';

export const SurfaceCompat: React.FC<SurfaceCompatProps> = ({elevation, ...props}) => {
  const hive = useHive();

  const value = hive.elevation(elevation);

  return (
    <ElevationCompat
      elevation={value}
      {...props}
    />
  );
};
