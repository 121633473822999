// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLink2ChainOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.501 5a.833.833 0 0 0-.833.833v4.334c0 .46.373.833.833.833h3a.5.5 0 0 1 0 1h-3a1.833 1.833 0 0 1-1.833-1.833V5.833C.668 4.821 1.488 4 2.501 4h3a.5.5 0 0 1 0 1zm7.5-.5a.5.5 0 0 1 .5-.5h3c1.013 0 1.834.82 1.834 1.833v4.334c0 1.012-.821 1.833-1.834 1.833h-3a.5.5 0 0 1 0-1h3c.46 0 .834-.373.834-.833V5.833A.833.833 0 0 0 13.5 5h-3a.5.5 0 0 1-.5-.5m-6 3.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLink2ChainOutline.displayName = 'Link2ChainOutline';
const Memo = memo(SvgLink2ChainOutline);
export default Memo;
