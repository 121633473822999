// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShredderPermanentlyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8.5a.5.5 0 0 1 .5-.5h12.333a.5.5 0 0 1 0 1H1.832a.5.5 0 0 1-.5-.5m2.5 1.833a.5.5 0 0 1 .5.5v1.963a.5.5 0 1 1-1 0v-1.963a.5.5 0 0 1 .5-.5m2.778 0a.5.5 0 0 1 .5.5v3.334a.5.5 0 0 1-1 0v-3.334a.5.5 0 0 1 .5-.5m2.778 0a.5.5 0 0 1 .5.5v1.963a.5.5 0 1 1-1 0v-1.963a.5.5 0 0 1 .5-.5m2.777 0a.5.5 0 0 1 .5.5v3.334a.5.5 0 0 1-1 0v-3.334a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
      <Path d="M2.665 3.167c0-1.013.821-1.834 1.834-1.834h7c1.012 0 1.833.821 1.833 1.834v3.5H2.665z" />
    </Svg>
  );
};
SvgShredderPermanentlyFill.displayName = 'ShredderPermanentlyFill';
const Memo = memo(SvgShredderPermanentlyFill);
export default Memo;
