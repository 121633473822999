// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddSheetAddCanvasAddPageVerticalFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 14.667a1.833 1.833 0 0 1-1.833-1.834V3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834v9.666c0 1.013-.821 1.834-1.834 1.834zM5.835 7.5a.5.5 0 1 0 0 1H7.5v1.667a.5.5 0 0 0 1 0V8.5h1.667a.5.5 0 0 0 0-1H8.501V5.833a.5.5 0 0 0-1 0V7.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAddSheetAddCanvasAddPageVerticalFill.displayName = 'AddSheetAddCanvasAddPageVerticalFill';
const Memo = memo(SvgAddSheetAddCanvasAddPageVerticalFill);
export default Memo;
