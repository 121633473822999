// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNfc2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.665 2.976a.5.5 0 0 1 .685.177 9.5 9.5 0 0 1 1.315 4.844c0 1.768-.48 3.425-1.315 4.844a.5.5 0 1 1-.862-.508 8.5 8.5 0 0 0 1.177-4.336c0-1.585-.43-3.068-1.177-4.336a.5.5 0 0 1 .177-.685m-2.19 1.663a.5.5 0 0 1 .675.21c.495.94.774 2.011.774 3.148 0 1.136-.28 2.208-.774 3.148a.5.5 0 1 1-.885-.465c.42-.8.66-1.713.66-2.683s-.24-1.884-.66-2.683a.5.5 0 0 1 .21-.675m-3.56.289a.5.5 0 0 1 .701-.096 3.96 3.96 0 0 1 1.568 3.165 3.965 3.965 0 0 1-1.568 3.165.5.5 0 0 1-.581.017L4.294 9.334a.5.5 0 1 1 .558-.83l2.426 1.633a2.97 2.97 0 0 0 .906-2.14c0-.97-.46-1.829-1.172-2.368a.5.5 0 0 1-.096-.701M2.9 4.832a.5.5 0 0 1 .581-.017L6.222 6.66a.5.5 0 1 1-.558.83L3.238 5.857a2.97 2.97 0 0 0-.906 2.14c0 .97.46 1.828 1.172 2.368a.5.5 0 1 1-.604.797 3.96 3.96 0 0 1-1.568-3.165A3.965 3.965 0 0 1 2.9 4.832"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNfc2Outline.displayName = 'Nfc2Outline';
const Memo = memo(SvgNfc2Outline);
export default Memo;
