// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderSharedUserOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.502 3.833C1.502 2.821 2.322 2 3.335 2h2.787c.613 0 1.185.306 1.525.816l.542.813A.83.83 0 0 0 8.882 4h4.12c1.012 0 1.833.82 1.833 1.833V11.5c0 1.012-.82 1.833-1.833 1.833H8.669a.5.5 0 1 1 0-1h4.333c.46 0 .833-.373.833-.833V5.833A.833.833 0 0 0 13.002 5h-4.12a1.83 1.83 0 0 1-1.525-.816l-.542-.813A.83.83 0 0 0 6.122 3H3.335a.833.833 0 0 0-.833.833V5.5a.5.5 0 0 1-1 0zm2.5 3.834a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333m-1.667.666a1.667 1.667 0 1 1 3.334 0 1.667 1.667 0 0 1-3.334 0m1.667 3.334c-.84 0-1.597.498-2.014 1.282l-.002.004.002.003c.012.017.048.044.105.044H5.91c.058 0 .094-.027.106-.044l.002-.003-.002-.004c-.417-.784-1.174-1.282-2.014-1.282m-2.897.812c.567-1.066 1.64-1.812 2.897-1.812s2.33.745 2.897 1.813a.99.99 0 0 1-.074 1.063c-.204.281-.542.457-.915.457H2.093c-.372 0-.71-.176-.915-.457a.99.99 0 0 1-.073-1.063"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolderSharedUserOutline.displayName = 'FolderSharedUserOutline';
const Memo = memo(SvgFolderSharedUserOutline);
export default Memo;
