// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGraphStatisticsDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.501 1.333c-1.012 0-1.833.821-1.833 1.834v9.666c0 1.013.82 1.834 1.833 1.834h4.065a4.333 4.333 0 0 1 4.769-7.179V3.167c0-1.013-.821-1.834-1.834-1.834z" />
      <Path
        fillRule="evenodd"
        d="M11.335 8a3.333 3.333 0 1 0 0 6.667 3.333 3.333 0 0 0 0-6.667M9 11.333c0-1.117.785-2.05 1.834-2.28v2.28a.5.5 0 0 0 .146.354l1.612 1.612A2.333 2.333 0 0 1 9 11.334m2.834-.207V9.054a2.334 2.334 0 0 1 1.465 3.538z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGraphStatisticsDocumentListPageFileFill.displayName = 'GraphStatisticsDocumentListPageFileFill';
const Memo = memo(SvgGraphStatisticsDocumentListPageFileFill);
export default Memo;
