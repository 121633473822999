// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFilterTimelineSortFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.001 2.667a.5.5 0 0 1 .5.5v8.459l1.145-1.146a.5.5 0 0 1 .708.707l-2 2a.5.5 0 0 1-.706 0l-2.001-2a.5.5 0 0 1 .706-.707l1.148 1.147v-8.46a.5.5 0 0 1 .5-.5M7.333 4.5a.5.5 0 0 1 .5-.5H13.5a.5.5 0 0 1 0 1H7.833a.5.5 0 0 1-.5-.5M8.667 8a.5.5 0 0 1 .5-.5H13.5a.5.5 0 0 1 0 1H9.167a.5.5 0 0 1-.5-.5M10 11.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFilterTimelineSortFill.displayName = 'FilterTimelineSortFill';
const Memo = memo(SvgFilterTimelineSortFill);
export default Memo;
