// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgJacuzziBathingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.607 8.117a1.34 1.34 0 0 0-.96-.412h-2.812l.034-.053a1.43 1.43 0 0 0 .203-1.051 1.5 1.5 0 0 0-.203-.508 1.34 1.34 0 0 0-1.892-.388l-1.52 1.051a.35.35 0 0 1-.232.066.34.34 0 0 1-.22-.09L5.397 5.353a1.4 1.4 0 0 0-.347-.215 2 2 0 0 0 .682-.149q.366-.161.654-.448.285-.287.434-.674a2.173 2.173 0 0 0 0-1.588 2.2 2.2 0 0 0-.434-.675 1.85 1.85 0 0 0-.654-.447 1.97 1.97 0 0 0-1.539 0 2.2 2.2 0 0 0-.653.447q-.287.288-.434.675a2.172 2.172 0 0 0 0 1.588q.156.377.434.674.28.296.653.448c.058.024.116.036.174.054a1.2 1.2 0 0 0-.41.12 1.36 1.36 0 0 0-.574.519 1.43 1.43 0 0 0-.214.752v1.283h-.81c-.364 0-.705.143-.96.412-.254.269-.399.62-.399.991v.896c0 1.474 1.122 2.757 2.505 3.474l-.625.644a.52.52 0 0 0 0 .717c.099.101.22.149.347.149a.47.47 0 0 0 .347-.15l.926-.954c.445.131.897.209 1.33.209h4.34c.433 0 .885-.072 1.33-.21l.926.956c.098.101.22.149.347.149a.47.47 0 0 0 .347-.15.52.52 0 0 0 0-.716l-.625-.644c1.383-.723 2.505-2 2.505-3.475V9.12c0-.37-.145-.728-.4-.99zM4.02 3.467a1.08 1.08 0 0 1 .231-1.158 1 1 0 0 1 .336-.227.99.99 0 0 1 .787.006q.19.08.335.233a1.075 1.075 0 0 1 .295.752 1.08 1.08 0 0 1-.306.752 1 1 0 0 1-.336.227 1 1 0 0 1-1.122-.233 1 1 0 0 1-.22-.346zm10.002 6.537c0 1.63-2.152 3.074-3.847 3.074H5.836c-1.695 0-3.847-1.445-3.847-3.074v-.896a.4.4 0 0 1 .11-.275.38.38 0 0 1 .266-.113h1.302a.5.5 0 0 0 .492-.507V6.422c0-.072.023-.15.058-.21a.4.4 0 0 1 .156-.142.4.4 0 0 1 .208-.036.4.4 0 0 1 .19.09l1.61 1.384a1.327 1.327 0 0 0 1.626.09l1.526-1.05c.081-.06.185-.078.284-.06a.38.38 0 0 1 .237.167c.029.041.046.09.058.137a.3.3 0 0 1 0 .15.4.4 0 0 1-.162.244l-.828.567s-.058.036-.08.054l-.614.424a2.1 2.1 0 0 1-1.284.358 2.1 2.1 0 0 1-1.238-.496l-.33-.286a.49.49 0 0 0-.694.06.527.527 0 0 0 .058.716l.33.286c.509.436 1.156.693 1.816.729a3.07 3.07 0 0 0 1.88-.526l.532-.364h4.143c.098 0 .196.042.266.114a.4.4 0 0 1 .11.274v.896z" />
    </Svg>
  );
};
SvgJacuzziBathingOutline.displayName = 'JacuzziBathingOutline';
const Memo = memo(SvgJacuzziBathingOutline);
export default Memo;
