// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBoldFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.336 4.875C3.336 3.308 4.583 2 6.169 2h2.445c1.923 0 3.444 1.588 3.444 3.5 0 .828-.285 1.596-.765 2.2a3.52 3.52 0 0 1 1.376 2.8c0 1.912-1.52 3.5-3.444 3.5H6.169c-1.586 0-2.833-1.309-2.833-2.875zM8.614 7c.776 0 1.444-.65 1.444-1.5S9.39 4 8.614 4H6.169c-.439 0-.833.37-.833.875V7zM5.336 9h3.889c.777 0 1.444.65 1.444 1.5S10.001 12 9.225 12H6.169c-.439 0-.833-.37-.833-.875z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBoldFill.displayName = 'BoldFill';
const Memo = memo(SvgBoldFill);
export default Memo;
