// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronDoubleUpOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.978 12.02a.5.5 0 0 0 .708 0L7.88 9.825a.167.167 0 0 1 .236 0l2.195 2.195a.5.5 0 1 0 .707-.707L8.824 9.118a1.167 1.167 0 0 0-1.65 0l-2.196 2.195a.5.5 0 0 0 0 .707m0-4.666a.5.5 0 0 0 .708 0L7.88 5.158a.167.167 0 0 1 .236 0l2.195 2.196a.5.5 0 1 0 .707-.707L8.824 4.45a1.167 1.167 0 0 0-1.65 0L4.978 6.647a.5.5 0 0 0 0 .707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronDoubleUpOutline.displayName = 'ChevronDoubleUpOutline';
const Memo = memo(SvgChevronDoubleUpOutline);
export default Memo;
