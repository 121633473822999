// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPlayCircleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.167 2.5a5.667 5.667 0 1 0 0 11.333 5.667 5.667 0 0 0 0-11.333M1.5 8.167a6.667 6.667 0 1 1 13.333 0 6.667 6.667 0 0 1-13.333 0"
        clipRule="evenodd"
      />
      <Path d="M6.833 10.036V6.297c0-.267.3-.426.521-.275l2.742 1.87a.333.333 0 0 1 0 .55l-2.742 1.87a.333.333 0 0 1-.52-.276" />
    </Svg>
  );
};
SvgPlayCircleOutline.displayName = 'PlayCircleOutline';
const Memo = memo(SvgPlayCircleOutline);
export default Memo;
