// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgH1HeadingHeadlineOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.836 2.667a.5.5 0 0 1 .5.5V7.5h6V3.167a.5.5 0 1 1 1 0v9.666a.5.5 0 1 1-1 0V8.5h-6v4.333a.5.5 0 1 1-1 0V3.167a.5.5 0 0 1 .5-.5M14.38 6.713a.5.5 0 0 1 .29.454v5.666a.5.5 0 1 1-1 0V8.245l-1.344 1.137a.5.5 0 0 1-.646-.764l2.166-1.833a.5.5 0 0 1 .534-.072"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgH1HeadingHeadlineOutline.displayName = 'H1HeadingHeadlineOutline';
const Memo = memo(SvgH1HeadingHeadlineOutline);
export default Memo;
