// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGolfBallFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3 6.333a5 5 0 1 1 10 0 5 5 0 0 1-10 0m6.833-.55a.667.667 0 0 0 .65-.816.667.667 0 1 1-.8.8.7.7 0 0 0 .15.016m-.683.65a.667.667 0 0 1-.817.65.667.667 0 1 0 .8-.8.7.7 0 0 1 .017.15M7.816 7.766a.667.667 0 0 1-.816.65.667.667 0 1 0 .8-.8.7.7 0 0 1 .016.15m1.017 1a.667.667 0 0 0 .65-.816.667.667 0 1 1-.8.8.7.7 0 0 0 .15.016m1.317-1.333a.667.667 0 0 0 .65-.817.667.667 0 1 1-.8.8.7.7 0 0 0 .15.017"
        clipRule="evenodd"
      />
      <Path d="M8.5 14.167c0-1.048.78-1.834 1.667-1.834a.5.5 0 0 0 0-1c-.907 0-1.689.473-2.167 1.183-.478-.71-1.26-1.183-2.167-1.183a.5.5 0 1 0 0 1c.887 0 1.667.786 1.667 1.834a.5.5 0 0 0 1 0" />
    </Svg>
  );
};
SvgGolfBallFill.displayName = 'GolfBallFill';
const Memo = memo(SvgGolfBallFill);
export default Memo;
