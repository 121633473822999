// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStarFavoriteAwardFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.908 1.237a1.007 1.007 0 0 0-1.814 0l-1.58 3.286q0 .002-.004.003l-3.633.476c-.838.11-1.179 1.142-.56 1.725l2.656 2.504v.003l-.666 3.577c-.156.835.728 1.466 1.467 1.067l3.223-1.737a.01.01 0 0 1 .008 0l3.223 1.737c.74.399 1.623-.232 1.467-1.067l-.667-3.577.001-.003 2.656-2.504c.619-.583.277-1.615-.56-1.725l-3.633-.476q-.005-.002-.005-.003z" />
    </Svg>
  );
};
SvgStarFavoriteAwardFill.displayName = 'StarFavoriteAwardFill';
const Memo = memo(SvgStarFavoriteAwardFill);
export default Memo;
