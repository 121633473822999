// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPrayOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.546 4.645c.394.407.913.63 1.468.63h-.006c.555 0 1.074-.223 1.468-.63s.609-.945.609-1.52c0-.574-.215-1.11-.609-1.518-.782-.81-2.148-.81-2.93 0a2.17 2.17 0 0 0-.609 1.519c0 .574.215 1.111.61 1.519m.722-2.291c.197-.21.466-.321.746-.321s.555.117.752.32c.203.205.31.476.31.773 0 .296-.113.568-.31.778-.4.413-1.098.413-1.498 0a1.11 1.11 0 0 1-.31-.778c0-.29.107-.568.31-.772M6.228 15h5.37c.37 0 .728-.154.99-.426a1.48 1.48 0 0 0 0-2.05 1.38 1.38 0 0 0-.99-.426h-.555l.244-.16c.442-.297.776-.742.943-1.254a2.45 2.45 0 0 0-.024-1.587L11.12 6.096a1.46 1.46 0 0 0-.352-.55 1.4 1.4 0 0 0-.549-.333 1.4 1.4 0 0 0-.638-.043 1.4 1.4 0 0 0-.59.253L6.92 6.954 5.535 5.231a1.397 1.397 0 0 0-.96-.574 1.4 1.4 0 0 0-.567.05 1.3 1.3 0 0 0-.502.277 1.47 1.47 0 0 0-.5 1.026 1.4 1.4 0 0 0 .077.58c.065.185.16.352.274.475l1.707 2.112a2.25 2.25 0 0 0 3.097.414l.972-.716.305.833-3.963 2.612a1.49 1.49 0 0 0-.596 1.637c.09.296.268.568.507.753s.537.29.841.29m3.3-7.447a.4.4 0 0 0-.15-.024l.006-.019a.52.52 0 0 0-.292.099L7.582 8.72a1.265 1.265 0 0 1-1.736-.228L4.12 6.355a.4.4 0 0 1-.084-.142c-.023-.05-.023-.105-.023-.16a.5.5 0 0 1 .041-.155c.03-.049.06-.098.102-.13a.5.5 0 0 1 .137-.073q.073-.02.155-.013a.4.4 0 0 1 .15.05c.047.03.089.068.137.136l1.706 2.118c.173.21.472.253.686.092l2.453-1.815a.4.4 0 0 1 .167-.068.33.33 0 0 1 .173.012.38.38 0 0 1 .245.247l1.086 3.001c.101.284.107.6.012.89a1.32 1.32 0 0 1-.525.697l-1.713 1.13c-.191.13-.28.37-.215.593a.515.515 0 0 0 .49.377h2.297c.101 0 .203.043.274.117a.415.415 0 0 1 0 .568.39.39 0 0 1-.274.117H6.22a.4.4 0 0 1-.232-.08.44.44 0 0 1-.138-.21.46.46 0 0 1 .012-.253.4.4 0 0 1 .155-.198l4.309-2.84a.53.53 0 0 0 .203-.624l-.675-1.87a.54.54 0 0 0-.328-.316"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPrayOutline.displayName = 'PrayOutline';
const Memo = memo(SvgPrayOutline);
export default Memo;
