// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgKeyboardMidiKeysPianoFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 2C2.821 2 2 2.82 2 3.833v9c0 1.013.82 1.834 1.833 1.834h8.334c1.012 0 1.833-.821 1.833-1.834v-9C14 2.821 13.18 2 12.167 2zm.5 1h-.5A.833.833 0 0 0 3 3.833v9c0 .46.373.834.833.834h1.5V9h-.5a.5.5 0 0 1-.5-.5zm2 10.667h3.334V9h-.5a.5.5 0 0 1-.5-.5V3H7.333v5.5a.5.5 0 0 1-.5.5h-.5zM11.667 3v5.5a.5.5 0 0 1-.5.5h-.5v4.667h1.5c.46 0 .833-.373.833-.834v-9A.833.833 0 0 0 12.167 3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgKeyboardMidiKeysPianoFill.displayName = 'KeyboardMidiKeysPianoFill';
const Memo = memo(SvgKeyboardMidiKeysPianoFill);
export default Memo;
