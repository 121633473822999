// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBurgerHamburgerCheeseburgerSandwichFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.886 3.416c1.265-.517 2.833-.75 4.113-.75s2.847.233 4.113.75c1.228.502 2.387 1.372 2.387 2.75a.5.5 0 0 1-.5.5h-12a.5.5 0 0 1-.5-.5c0-1.378 1.159-2.248 2.387-2.75M3.191 8.712a2.1 2.1 0 0 1 1.615 0 3.1 3.1 0 0 0 2.385 0 2.1 2.1 0 0 1 1.615 0 3.1 3.1 0 0 0 2.385 0 2.1 2.1 0 0 1 1.615 0c.404.168.983.238 1.359.238a.5.5 0 1 0 0-1c-.303 0-.737-.063-.974-.162a3.1 3.1 0 0 0-2.385 0 2.1 2.1 0 0 1-1.615 0 3.1 3.1 0 0 0-2.385 0 2.1 2.1 0 0 1-1.615 0 3.1 3.1 0 0 0-2.385 0c-.236.1-.67.162-.974.162a.5.5 0 0 0 0 1c.377 0 .956-.07 1.359-.238M12.065 13.333c.35 0 .643 0 .882-.019.249-.02.483-.063.705-.174.355-.178.643-.465.82-.82.11-.222.154-.456.174-.706.02-.238.02-.53.02-.88V10.5a.5.5 0 0 0-.5-.5H1.831a.5.5 0 0 0-.5.5v.233c0 .35 0 .643.02.881.02.25.063.484.173.705.178.356.466.643.82.821.223.11.457.154.706.174.239.02.531.02.881.02z" />
    </Svg>
  );
};
SvgBurgerHamburgerCheeseburgerSandwichFill.displayName = 'BurgerHamburgerCheeseburgerSandwichFill';
const Memo = memo(SvgBurgerHamburgerCheeseburgerSandwichFill);
export default Memo;
