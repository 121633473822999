// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicStickStarFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.15 2.648a.833.833 0 0 0-1.372.698l.162 2.101-1.795 1.104a.833.833 0 0 0 .24 1.52l1.48.357-4.719 4.718a.5.5 0 1 0 .708.708l4.718-4.719.357 1.48a.833.833 0 0 0 1.52.24l1.104-1.795 2.1.162a.833.833 0 0 0 .7-1.371l-1.367-1.604.804-1.949a.833.833 0 0 0-1.088-1.088l-1.948.804z" />
    </Svg>
  );
};
SvgMagicStickStarFill.displayName = 'MagicStickStarFill';
const Memo = memo(SvgMagicStickStarFill);
export default Memo;
