// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettings2GearPreferencesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.085 1.783a1.83 1.83 0 0 1 1.83 0l4.166 2.4c.569.326.919.932.919 1.588v4.458c0 .656-.35 1.262-.919 1.589l-4.166 2.399a1.83 1.83 0 0 1-1.83 0L2.92 11.818A1.83 1.83 0 0 1 2 10.23V5.771c0-.656.35-1.262.919-1.589zm1.33.867a.83.83 0 0 0-.83 0L3.417 5.049A.83.83 0 0 0 3 5.77v4.458c0 .298.16.574.418.723l4.166 2.398a.83.83 0 0 0 .832 0l4.166-2.399A.83.83 0 0 0 13 10.23V5.771a.83.83 0 0 0-.418-.722zM8 6.333a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334M5.333 8a2.667 2.667 0 1 1 5.334 0 2.667 2.667 0 0 1-5.334 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettings2GearPreferencesOutline.displayName = 'Settings2GearPreferencesOutline';
const Memo = memo(SvgSettings2GearPreferencesOutline);
export default Memo;
