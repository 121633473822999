// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNotificationOffBellActivityFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8 1.333a5.2 5.2 0 0 0-3.297 1.18l-.038.031 9.486 9.486-1.02-6.327A5.2 5.2 0 0 0 8 1.333" />
      <Path
        fillRule="evenodd"
        d="M2.354 1.646a.5.5 0 0 0-.708.708l1.755 1.754a5.2 5.2 0 0 0-.532 1.595l-.78 4.838a1.833 1.833 0 0 0 1.81 2.126h1.106c.566 1.167 1.675 2 2.995 2s2.429-.833 2.995-2h.965l1.686 1.687a.5.5 0 0 0 .708-.707zm7.482 11.02H6.164c.452.626 1.12 1 1.836 1s1.384-.374 1.836-1"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNotificationOffBellActivityFill.displayName = 'NotificationOffBellActivityFill';
const Memo = memo(SvgNotificationOffBellActivityFill);
export default Memo;
