// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgQrCodeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3 4.528C3 3.684 3.684 3 4.528 3h1.389c.843 0 1.527.684 1.527 1.528v1.389c0 .843-.684 1.527-1.527 1.527h-1.39A1.53 1.53 0 0 1 3 5.917zm1.528-.695a.694.694 0 0 0-.695.695v1.389c0 .383.311.694.695.694h1.389c.383 0 .694-.31.694-.694v-1.39a.694.694 0 0 0-.694-.694zm4.028.695C8.556 3.684 9.24 3 10.083 3h1.39C12.315 3 13 3.684 13 4.528v1.389c0 .843-.684 1.527-1.528 1.527h-1.389a1.53 1.53 0 0 1-1.527-1.527zm1.527-.695a.694.694 0 0 0-.694.695v1.389c0 .383.31.694.694.694h1.39c.383 0 .694-.31.694-.694v-1.39a.694.694 0 0 0-.695-.694zM3 10.083c0-.843.684-1.527 1.528-1.527h1.389c.843 0 1.527.684 1.527 1.527v1.39c0 .843-.684 1.527-1.527 1.527h-1.39A1.53 1.53 0 0 1 3 11.472zm1.528-.694a.694.694 0 0 0-.695.694v1.39c0 .383.311.694.695.694h1.389c.383 0 .694-.311.694-.695v-1.389a.694.694 0 0 0-.694-.694zM8.972 8.556c.23 0 .417.186.417.416v.972h.972a.417.417 0 0 1 0 .834H8.972a.417.417 0 0 1-.416-.417V8.972c0-.23.186-.416.416-.416m1.806.416c0-.23.186-.416.416-.416h1.39a.417.417 0 1 1 0 .833h-1.39a.417.417 0 0 1-.416-.417m0 2.222c0-.23.186-.416.416-.416h1.39a.417.417 0 1 1 0 .833h-.973v.972a.417.417 0 1 1-.833 0z"
        clipRule="evenodd"
      />
      <Path d="M8.556 12.444a.556.556 0 1 1 1.11 0 .556.556 0 0 1-1.11 0M4.389 10.222c0-.153.124-.278.278-.278h1.11c.154 0 .279.125.279.278v1.111a.28.28 0 0 1-.278.278H4.667a.28.28 0 0 1-.278-.278zM4.389 4.667c0-.154.124-.278.278-.278h1.11c.154 0 .279.124.279.278v1.11a.28.28 0 0 1-.278.279H4.667a.28.28 0 0 1-.278-.278zM9.944 4.667c0-.154.125-.278.278-.278h1.111c.154 0 .278.124.278.278v1.11a.28.28 0 0 1-.278.279h-1.11a.28.28 0 0 1-.279-.278z" />
    </Svg>
  );
};
SvgQrCodeFill.displayName = 'QrCodeFill';
const Memo = memo(SvgQrCodeFill);
export default Memo;
