// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLineChartStatisticsGraphOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.5 2a.5.5 0 0 1 .5.5v9.667c0 .46.373.833.833.833h10.334a.5.5 0 0 1 0 1H3.833A1.833 1.833 0 0 1 2 12.167V2.5a.5.5 0 0 1 .5-.5m7 .667a.5.5 0 0 1 .5.5v7.666a.5.5 0 1 1-1 0V3.167a.5.5 0 0 1 .5-.5m-3.333 4a.5.5 0 0 1 .5.5v3.666a.5.5 0 1 1-1 0V7.167a.5.5 0 0 1 .5-.5M12.833 8a.5.5 0 0 1 .5.5v2.333a.5.5 0 1 1-1 0V8.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLineChartStatisticsGraphOutline.displayName = 'LineChartStatisticsGraphOutline';
const Memo = memo(SvgLineChartStatisticsGraphOutline);
export default Memo;
