// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHand4FingerSelectFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.28 1.333c-.608 0-1.101.498-1.101 1.111V6.52c0 .204-.165.37-.368.37a.37.37 0 0 1-.367-.37V3.185c0-.613-.493-1.11-1.102-1.11-.609-.001-1.102.497-1.102 1.11V7.63a.37.37 0 0 1-.227.342.365.365 0 0 1-.4-.08l-.676-.682a1.096 1.096 0 0 0-1.64.092L2 7.676l2.125 4.285a4.85 4.85 0 0 0 4.342 2.706c2.681 0 4.855-2.192 4.855-4.895V6.89q0-.048.011-.093a.4.4 0 0 1-.011-.092V3.74c0-.614-.494-1.111-1.102-1.111s-1.102.497-1.102 1.11v3.334c0 .205-.165.37-.368.37a.37.37 0 0 1-.367-.37v-4.63c0-.613-.494-1.11-1.102-1.11"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHand4FingerSelectFill.displayName = 'Hand4FingerSelectFill';
const Memo = memo(SvgHand4FingerSelectFill);
export default Memo;
