// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPauseOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.833C2.668 2.821 3.488 2 4.501 2h.334c1.012 0 1.833.82 1.833 1.833v8.334c0 1.012-.82 1.833-1.833 1.833H4.5a1.833 1.833 0 0 1-1.833-1.833zM4.501 3a.833.833 0 0 0-.833.833v8.334c0 .46.373.833.833.833h.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 4.835 3zm4.834.833c0-1.012.82-1.833 1.833-1.833h.333c1.013 0 1.834.82 1.834 1.833v8.334c0 1.012-.821 1.833-1.834 1.833h-.333a1.833 1.833 0 0 1-1.833-1.833zM11.168 3a.833.833 0 0 0-.833.833v8.334c0 .46.373.833.833.833h.333c.46 0 .834-.373.834-.833V3.833A.833.833 0 0 0 11.5 3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPauseOutline.displayName = 'PauseOutline';
const Memo = memo(SvgPauseOutline);
export default Memo;
