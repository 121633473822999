// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLink3ChainOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.607 3.392a3.613 3.613 0 0 0-5.11 0l-.645.644a.5.5 0 1 1-.707-.707l.645-.644a4.613 4.613 0 0 1 6.524 6.524l-.646.646a.5.5 0 0 1-.707-.707l.646-.646a3.613 3.613 0 0 0 0-5.11M10.019 5.98a.5.5 0 0 1 0 .707L6.686 10.02a.5.5 0 0 1-.708-.707L9.312 5.98a.5.5 0 0 1 .707 0m-5.98.163a.5.5 0 0 1 0 .707l-.649.648a3.614 3.614 0 0 0 5.11 5.11l.644-.643a.5.5 0 0 1 .707.707l-.643.644A4.614 4.614 0 0 1 2.683 6.79l.648-.648a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLink3ChainOutline.displayName = 'Link3ChainOutline';
const Memo = memo(SvgLink3ChainOutline);
export default Memo;
