// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedRealityScan3DViewCubeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.833 13A.833.833 0 0 1 3 12.167V10.5a.5.5 0 0 0-1 0v1.667C2 13.179 2.82 14 3.833 14H5.5a.5.5 0 0 0 0-1zM2 5.5a.5.5 0 0 0 1 0V3.833c0-.46.373-.833.833-.833H5.5a.5.5 0 0 0 0-1H3.833C2.821 2 2 2.82 2 3.833zM10.5 13a.5.5 0 0 0 0 1h1.667C13.179 14 14 13.18 14 12.167V10.5a.5.5 0 0 0-1 0v1.667c0 .46-.373.833-.833.833zM10.5 2a.5.5 0 0 0 0 1h1.667c.46 0 .833.373.833.833V5.5a.5.5 0 0 0 1 0V3.833C14 2.821 13.18 2 12.167 2zM8.563 4.925c-.349-.2-.777-.2-1.126 0L5.603 5.972l-.069.043L8 7.425l2.466-1.41-.07-.043zM5.031 6.958q0-.038.003-.077L7.5 8.291v2.818l-.063-.034-1.834-1.047a1.14 1.14 0 0 1-.572-.986zM8.563 11.075l-.063.034V8.29l2.466-1.409q.003.038.003.077v2.084c0 .407-.219.783-.572.986z" />
    </Svg>
  );
};
SvgArAugmentedRealityScan3DViewCubeFill.displayName = 'ArAugmentedRealityScan3DViewCubeFill';
const Memo = memo(SvgArAugmentedRealityScan3DViewCubeFill);
export default Memo;
