// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStoreShopBusinessOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.091 2.714C3.438 2.264 3.975 2 4.543 2h6.91c.57 0 1.106.264 1.453.714l1.655 2.149a.5.5 0 0 1 .104.305v.667c0 .66-.261 1.26-.685 1.704v4.628c0 1.012-.82 1.833-1.833 1.833H3.85a1.833 1.833 0 0 1-1.834-1.833V7.539a2.47 2.47 0 0 1-.685-1.704v-.667a.5.5 0 0 1 .104-.305zm-.074 5.473v3.98c0 .46.373.833.834.833h2.277v-1.5c0-1.026.85-1.835 1.87-1.835s1.871.809 1.871 1.834V13h2.278c.46 0 .833-.373.833-.833v-3.98a2.6 2.6 0 0 1-2.926-.866A2.58 2.58 0 0 1 8 8.336 2.58 2.58 0 0 1 5.943 7.32a2.601 2.601 0 0 1-2.926.865m3.426-2.352c0 .816.684 1.5 1.556 1.5s1.555-.684 1.555-1.5v-.167h-3.11zm-1-.167v.167c0 .816-.683 1.5-1.555 1.5a1.58 1.58 0 0 1-1.042-.386 1.47 1.47 0 0 1-.514-1.114v-.167zm7.706-1-1.035-1.343a.83.83 0 0 0-.66-.325h-6.91a.83.83 0 0 0-.66.325L2.847 4.668zm.516 1h-3.11v.167c0 .816.683 1.5 1.555 1.5a1.58 1.58 0 0 0 1.042-.386c.318-.277.513-.675.513-1.114zM8.87 13v-1.5c0-.449-.377-.835-.87-.835-.494 0-.87.386-.87.834V13z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStoreShopBusinessOutline.displayName = 'StoreShopBusinessOutline';
const Memo = memo(SvgStoreShopBusinessOutline);
export default Memo;
