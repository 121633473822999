// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowPathRightFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.034 2.909a.833.833 0 0 0-1.369.639v1.785h-5.5c-1.012 0-1.833.821-1.833 1.834v1.666c0 1.013.82 1.834 1.833 1.834h5.5v1.786a.833.833 0 0 0 1.369.638l4.704-3.941a1.5 1.5 0 0 0 0-2.3z" />
    </Svg>
  );
};
SvgArrowPathRightFill.displayName = 'ArrowPathRightFill';
const Memo = memo(SvgArrowPathRightFill);
export default Memo;
