// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBulletListFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.833 3a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667M7.833 4.333a.5.5 0 0 0 0 1H13.5a.5.5 0 0 0 0-1zM3.833 9.333a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667M7.833 10.667a.5.5 0 1 0 0 1H13.5a.5.5 0 0 0 0-1z" />
    </Svg>
  );
};
SvgBulletListFill.displayName = 'BulletListFill';
const Memo = memo(SvgBulletListFill);
export default Memo;
