// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWorldGlobusInternetWebOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.064 5.213A5.64 5.64 0 0 0 2.332 8c0 1.385.497 2.654 1.322 3.638l2.194-2.195c-1.06-1.117-1.892-2.267-2.392-3.267-.165-.331-.3-.655-.392-.963m2.148-2.148c.308.093.632.227.963.392 1 .5 2.15 1.332 3.267 2.392l2.195-2.194a5.64 5.64 0 0 0-3.638-1.322 5.64 5.64 0 0 0-2.787.732m7.134-.12a6.667 6.667 0 0 0-9.402 9.402l-.484.485a.5.5 0 1 0 .707.707l.484-.484a6.667 6.667 0 0 0 9.402-9.402l.485-.485a.5.5 0 1 0-.707-.707zm-.002 1.417-2.195 2.195c1.06 1.117 1.892 2.267 2.393 3.267.165.331.299.655.392.963A5.64 5.64 0 0 0 13.665 8a5.64 5.64 0 0 0-1.321-3.638m-1.558 8.573a6 6 0 0 1-.963-.392c-1-.5-2.15-1.332-3.268-2.392l-2.194 2.194a5.64 5.64 0 0 0 3.638 1.322 5.64 5.64 0 0 0 2.787-.732M7.263 9.443c1.056 1 2.12 1.761 3.007 2.205.497.249.913.385 1.227.422.32.038.447-.034.492-.08.045-.045.118-.171.08-.492-.038-.314-.173-.73-.422-1.227-.444-.887-1.205-1.95-2.205-3.007zm1.472-2.886c-1.057-1-2.12-1.761-3.008-2.205-.496-.249-.912-.385-1.226-.422-.321-.038-.447.034-.493.08-.045.045-.117.171-.079.492.037.314.173.73.422 1.227.443.887 1.205 1.95 2.205 3.007z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWorldGlobusInternetWebOutline.displayName = 'WorldGlobusInternetWebOutline';
const Memo = memo(SvgWorldGlobusInternetWebOutline);
export default Memo;
