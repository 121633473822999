// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSaunaFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m1.694 6.647.79-.488v7.425c0 .23.2.416.447.416h9.83c.247 0 .447-.186.447-.416V5.969l1.098.678a.467.467 0 0 0 .62-.117.4.4 0 0 0-.126-.577L8.732 2.205A1.4 1.4 0 0 0 8 2c-.26 0-.515.071-.732.205L1.2 5.953a.4.4 0 0 0-.126.577.467.467 0 0 0 .62.117m5.208.522a.397.397 0 0 0-.067-.585.47.47 0 0 0-.628.061 2.4 2.4 0 0 0-.59 1.448c0 .434.14.856.257 1.21l.008.025c.125.378.223.684.225.977-.023.343-.16.67-.39.937a.397.397 0 0 0 .066.585.47.47 0 0 0 .629-.063 2.4 2.4 0 0 0 .59-1.447c0-.434-.14-.855-.257-1.208l-.009-.026c-.125-.379-.223-.685-.225-.979.023-.342.16-.669.39-.935m3.132 0a.397.397 0 0 0-.066-.585.47.47 0 0 0-.628.061 2.4 2.4 0 0 0-.59 1.448c0 .434.14.856.257 1.21l.008.025c.125.378.223.684.225.977-.023.343-.16.67-.39.937a.397.397 0 0 0 .066.585.47.47 0 0 0 .629-.063 2.4 2.4 0 0 0 .589-1.447c0-.434-.14-.855-.256-1.208l-.009-.026c-.125-.379-.223-.685-.225-.979.023-.342.16-.669.39-.935"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSaunaFill.displayName = 'SaunaFill';
const Memo = memo(SvgSaunaFill);
export default Memo;
