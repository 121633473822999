// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGiftSideViewPresentOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.167 2.944c0-.89.721-1.61 1.61-1.61.918 0 1.73.453 2.223 1.149a2.72 2.72 0 0 1 2.222-1.15c.89 0 1.611.722 1.611 1.611 0 .654-.23 1.254-.614 1.723h.948c1.012 0 1.833.82 1.833 1.833v.167c0 .57-.26 1.078-.667 1.414v4.086c0 1.012-.82 1.833-1.833 1.833h-7a1.833 1.833 0 0 1-1.833-1.833V8.08A1.83 1.83 0 0 1 2 6.667V6.5c0-1.012.82-1.833 1.833-1.833h.948a2.7 2.7 0 0 1-.614-1.723m2.722 1.723H7.5v-.611c0-.952-.771-1.723-1.722-1.723a.61.61 0 0 0-.611.611c0 .952.77 1.723 1.722 1.723M8.5 4.056v.61h.611c.951 0 1.722-.77 1.722-1.722a.61.61 0 0 0-.61-.61c-.952 0-1.723.77-1.723 1.722m-1 1.61H3.833A.833.833 0 0 0 3 6.5v.167a.833.833 0 0 0 .833.833H7.5zm1 1.834V5.667h3.667c.46 0 .833.373.833.833v.167a.833.833 0 0 1-.833.833zm-1 1H3.833q-.084 0-.166-.007v3.674c0 .46.373.833.833.833h3zm1 4.5V8.5h3.667q.084 0 .166-.007v3.674c0 .46-.373.833-.833.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGiftSideViewPresentOutline.displayName = 'GiftSideViewPresentOutline';
const Memo = memo(SvgGiftSideViewPresentOutline);
export default Memo;
