// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgItSupportAgentOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.076 6.188a.453.453 0 0 0-.372-.712.712.712 0 1 0 0 1.424.453.453 0 0 0 .372-.712"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M6.258 5.929c0-.25.203-.453.453-.453a.712.712 0 1 1 0 1.424.453.453 0 0 1-.372-.712.45.45 0 0 1-.081-.26M9.67 6.188a.453.453 0 0 0-.372-.712.712.712 0 1 0 0 1.424.453.453 0 0 0 .372-.712"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M8.844 5.929c0-.25.203-.453.453-.453a.712.712 0 0 1 0 1.424.453.453 0 0 1-.372-.712.45.45 0 0 1-.081-.26"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M4.835 5.41a3.172 3.172 0 1 1 6.343 0v.46l-.004.059v4.088l-.003.054v1.036c0 .596-.323 1.117-.803 1.398a1.49 1.49 0 0 0-1.329-.816H6.97a1.489 1.489 0 0 0 0 2.977h2.07a1.49 1.49 0 0 0 1.465-1.22 2.525 2.525 0 0 0 1.573-2.34v-.582h1.103c.823 0 1.49-.666 1.49-1.488V6.965c0-.823-.667-1.49-1.49-1.49h-1.096v-.064a4.078 4.078 0 1 0-8.155 0v.065H2.825c-.823 0-1.49.666-1.49 1.489v2.07c0 .823.667 1.49 1.49 1.49h1.553c.25 0 .453-.203.453-.454V5.986l.004-.057zm4.204 7.185c.297 0 .542.222.578.51q0 .034.005.072c0 .322-.26.583-.583.583H6.97a.583.583 0 1 1 0-1.165zm-2.186-4.28a.453.453 0 1 0-.81.405C6.41 9.457 7.27 9.747 8 9.747s1.59-.29 1.959-1.027a.453.453 0 1 0-.81-.405C9 8.614 8.564 8.84 8 8.84s-.999-.227-1.148-.526m5.227-1.933v3.236h1.1c.322 0 .583-.26.583-.582V6.965a.583.583 0 0 0-.583-.583zm-9.838.583c0-.322.26-.583.583-.583h1.1v3.236h-1.1a.583.583 0 0 1-.583-.582z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgItSupportAgentOutline.displayName = 'ItSupportAgentOutline';
const Memo = memo(SvgItSupportAgentOutline);
export default Memo;
