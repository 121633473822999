// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCursor3ArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.923 3.435c-.37-.948.564-1.883 1.512-1.512l9.131 3.573c.784.307.995 1.317.4 1.912l-.926.925 2.411 2.411a.833.833 0 0 1 0 1.179l-2.528 2.528a.833.833 0 0 1-1.179 0l-2.41-2.41-.926.925a1.167 1.167 0 0 1-1.912-.4zm1.147-.58a.167.167 0 0 0-.216.215l3.573 9.132a.167.167 0 0 0 .274.057l1.043-1.044a.833.833 0 0 1 1.179 0l2.41 2.411 2.293-2.293-2.41-2.41a.833.833 0 0 1 0-1.179l1.043-1.043a.167.167 0 0 0-.057-.274z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCursor3ArrowOutline.displayName = 'Cursor3ArrowOutline';
const Memo = memo(SvgCursor3ArrowOutline);
export default Memo;
