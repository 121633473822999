// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSupportFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.332 7.5a1.5 1.5 0 0 1 1.344-1.492C2.856 3.37 5.201 1.333 8 1.333c2.797 0 5.143 2.037 5.322 4.675A1.5 1.5 0 0 1 14.665 7.5v2.333a1.5 1.5 0 0 1-1.169 1.464A3.5 3.5 0 0 1 10 14.667H8.665A1.167 1.167 0 0 1 7.5 13.5v-.405a.5.5 0 1 1 1 0v.405c0 .092.074.167.166.167H10a2.5 2.5 0 0 0 2.494-2.334h-.328a.5.5 0 0 1-.5-.5V6.5a.5.5 0 0 1 .5-.5h.152c-.183-2.026-2.016-3.667-4.318-3.667C5.696 2.333 3.863 3.974 3.68 6h.152a.5.5 0 0 1 .5.5v4.333a.5.5 0 0 1-.5.5h-1a1.5 1.5 0 0 1-1.5-1.5z" />
    </Svg>
  );
};
SvgSupportFill.displayName = 'SupportFill';
const Memo = memo(SvgSupportFill);
export default Memo;
