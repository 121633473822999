// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedReality3DViewCube2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.5 2.668v5.04l4.5 2.53V5.687a.83.83 0 0 0-.425-.726zm3.98 8.426L8 8.574l-4.48 2.52 4.071 2.29a.83.83 0 0 0 .818 0zM3 10.239l4.5-2.531v-5.04L3.425 4.96A.83.83 0 0 0 3 5.686zm4.101-8.495a1.83 1.83 0 0 1 1.798 0l4.167 2.344c.577.325.934.936.934 1.598v4.628c0 .662-.357 1.273-.934 1.598l-4.167 2.344a1.83 1.83 0 0 1-1.798 0l-4.166-2.344A1.83 1.83 0 0 1 2 10.314V5.686c0-.662.357-1.273.935-1.598z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArAugmentedReality3DViewCube2Outline.displayName = 'ArAugmentedReality3DViewCube2Outline';
const Memo = memo(SvgArAugmentedReality3DViewCube2Outline);
export default Memo;
