// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSunbedOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.961 1.39c.2.114.268.37.152.568l-.416.722a.417.417 0 1 1-.722-.417l.417-.721c.115-.2.37-.268.569-.153M5.086 2.97a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334m-.59 1.078a.833.833 0 1 1 1.18 1.178.833.833 0 0 1-1.18-1.178m8.807 3.563a1.67 1.67 0 0 0-1.247.267l-2.52 1.789H4.253a1.667 1.667 0 0 0-.116 3.329l-.289 1.153a.417.417 0 0 0 .809.202L4.995 13h5.091q.078 0 .155-.007l.722 1.443a.417.417 0 0 0 .746-.372l-.68-1.36a2 2 0 0 0 .164-.129l2.759-1.956a1.666 1.666 0 0 0-.65-3.008m-8.626 4.556h5.409c.208 0 .408-.08.56-.222l.044-.035 2.786-1.975a.833.833 0 0 0-.945-1.372l-2.62 1.86a.4.4 0 0 1-.242.077H4.253a.833.833 0 1 0 0 1.667h.424m2.492-7.53c0-.23.187-.417.417-.417h.833a.417.417 0 0 1 0 .833h-.833a.417.417 0 0 1-.417-.416M1.753 4.22a.417.417 0 0 0 0 .833h.833a.417.417 0 1 0 0-.833zm2.444 2.79a.417.417 0 0 0-.722-.417l-.417.722a.417.417 0 1 0 .722.417zM3.21 1.39a.417.417 0 0 1 .57.152l.416.721a.417.417 0 0 1-.722.417l-.417-.722a.417.417 0 0 1 .153-.569m3.486 5.203a.417.417 0 1 0-.722.417l.417.722a.417.417 0 1 0 .721-.417z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSunbedOff.displayName = 'SunbedOff';
const Memo = memo(SvgSunbedOff);
export default Memo;
