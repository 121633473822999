// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayoutGridListSearchFindMagifierFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.283 2.02c.253.02.491.065.716.18.345.176.625.456.801.801.115.225.16.463.18.716.02.243.02.54.02.896v2.22a.5.5 0 0 1-.5.5H9.167a.5.5 0 0 1-.5-.5V2.5a.5.5 0 0 1 .5-.5h2.22c.356 0 .653 0 .896.02M4.613 2h2.22a.5.5 0 0 1 .5.5v4.333a.5.5 0 0 1-.5.5H2.5a.5.5 0 0 1-.5-.5v-2.22c0-.356 0-.653.02-.896.02-.253.065-.491.18-.716.176-.345.456-.625.801-.801.225-.115.463-.16.716-.18.243-.02.54-.02.896-.02M2 9.167a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 .5.5V13.5a.5.5 0 0 1-.5.5h-2.22c-.356 0-.653 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.2 13a1.9 1.9 0 0 1-.18-.716C2 12.04 2 11.743 2 11.387z" />
      <Path
        fillRule="evenodd"
        d="M11.333 8.672a2.667 2.667 0 1 0 1.502 4.87l.897.897a.5.5 0 1 0 .707-.707l-.898-.898a2.667 2.667 0 0 0-2.208-4.162m-1.666 2.666a1.667 1.667 0 1 1 3.333 0 1.667 1.667 0 0 1-3.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLayoutGridListSearchFindMagifierFill.displayName = 'LayoutGridListSearchFindMagifierFill';
const Memo = memo(SvgLayoutGridListSearchFindMagifierFill);
export default Memo;
