// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBnoozeBellFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.17 5.914A4.83 4.83 0 0 1 8 1.334a4.83 4.83 0 0 1 4.83 4.58l.124 2.44a.8.8 0 0 0 .091.34l.825 1.599A1.17 1.17 0 0 1 12.83 12h-1.703a3.167 3.167 0 0 1-6.254 0H3.17a1.17 1.17 0 0 1-1.04-1.707l.824-1.6a.8.8 0 0 0 .091-.34zM5.89 12a2.168 2.168 0 0 0 4.218 0zm.942-7a.5.5 0 0 0 0 1h1.311L6.44 8.193A.5.5 0 0 0 6.833 9h2.334a.5.5 0 0 0 0-1H7.856L9.56 5.807A.5.5 0 0 0 9.167 5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBnoozeBellFill.displayName = 'BnoozeBellFill';
const Memo = memo(SvgBnoozeBellFill);
export default Memo;
