// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFire2FlameHotHeatOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.586 1.821c.342-.486 1.019-.656 1.547-.297.64.437 1.763 1.29 2.73 2.49.965 1.198 1.802 2.777 1.802 4.653 0 3.244-2.506 6-5.666 6s-5.667-2.756-5.667-6c0-1.35.586-3.09 1.735-4.482.443-.536 1.212-.499 1.655-.058a.168.168 0 0 0 .256-.02zm-1.868 11.2c-1.416-.86-2.386-2.49-2.386-4.354 0-1.101.494-2.62 1.506-3.845a.09.09 0 0 1 .07-.032c.035 0 .076.013.11.047.51.508 1.36.438 1.778-.155l1.608-2.286a.15.15 0 0 1 .088-.061.1.1 0 0 1 .078.016c.597.407 1.632 1.196 2.513 2.29.882 1.094 1.582 2.457 1.582 4.026 0 1.864-.97 3.494-2.385 4.354a3 3 0 0 0 .219-1.137c0-1.648-1.297-2.791-1.875-3.226a1.035 1.035 0 0 0-1.25 0c-.579.435-1.875 1.578-1.875 3.226 0 .398.077.785.219 1.137m2.28-3.574a.04.04 0 0 0-.023.01c-.555.417-1.476 1.297-1.476 2.427 0 1.049.731 1.783 1.5 1.783.768 0 1.5-.734 1.5-1.783 0-1.13-.922-2.01-1.476-2.427a.04.04 0 0 0-.024-.01"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFire2FlameHotHeatOutline.displayName = 'Fire2FlameHotHeatOutline';
const Memo = memo(SvgFire2FlameHotHeatOutline);
export default Memo;
