// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddedPeopleAddUserAddedPersonFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.001 1.333a3 3 0 1 0 0 6 3 3 0 0 0 0-6M11.77 9.804c-.99-.933-2.316-1.47-3.77-1.47-2.252 0-4.362 1.447-5.146 3.568a1.48 1.48 0 0 0 .26 1.483c.32.38.815.615 1.351.615h5.003l-.534-.4a1.5 1.5 0 1 1 1.8-2.4l.136.102z" />
      <Path d="M13.93 10.09a.5.5 0 0 0-.858-.514l-1.914 3.191L10.134 12a.5.5 0 1 0-.6.8l1.467 1.1a.5.5 0 0 0 .729-.143z" />
    </Svg>
  );
};
SvgAddedPeopleAddUserAddedPersonFill.displayName = 'AddedPeopleAddUserAddedPersonFill';
const Memo = memo(SvgAddedPeopleAddUserAddedPersonFill);
export default Memo;
