// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWalletOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.999 4.333A2.333 2.333 0 0 1 4.332 2h5.52c.817 0 1.48.663 1.48 1.48v1.853h.833c1.013 0 1.834.821 1.834 1.834v5c0 1.012-.821 1.833-1.834 1.833h-4a.5.5 0 0 1 0-1h4c.46 0 .834-.373.834-.833v-5a.833.833 0 0 0-.834-.834H4a2 2 0 0 1-1-.267V7.5a.5.5 0 0 1-1 0zm1 0a1 1 0 0 0 1 1h6.333V3.48a.48.48 0 0 0-.48-.48h-5.52c-.736 0-1.333.597-1.333 1.333m-.667 6.19v1.954l1.667.972 1.666-.972v-1.954L4 9.551zM3.747 8.54a.5.5 0 0 1 .504 0l2.166 1.264a.5.5 0 0 1 .248.432v2.528a.5.5 0 0 1-.248.432L4.251 14.46a.5.5 0 0 1-.504 0L1.58 13.196a.5.5 0 0 1-.248-.432v-2.528a.5.5 0 0 1 .248-.432z"
        clipRule="evenodd"
      />
      <Path d="M10.332 10.333a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333" />
    </Svg>
  );
};
SvgWalletOutline.displayName = 'WalletOutline';
const Memo = memo(SvgWalletOutline);
export default Memo;
