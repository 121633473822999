// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBooksLibraryOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.961 1.333H3.85c-.482 0-.945.19-1.29.528s-.546.8-.559 1.284v9.66c0 .494.195.967.542 1.316.346.35.817.546 1.307.546h9.066c.231 0 .418-.189.418-.42a.42.42 0 0 0-.417-.421h-.54v-2.107a1.386 1.386 0 0 0 .957-1.317V2.715c0-.367-.144-.718-.402-.977a1.37 1.37 0 0 0-.97-.405m-.42 10.45H3.85a1 1 0 0 0-.717.3 1.024 1.024 0 0 0 0 1.444c.19.191.448.299.717.299h7.693zm-7.692-.84a1.84 1.84 0 0 0-1.014.305V3.163c.008-.264.118-.515.306-.7.19-.185.444-.289.708-.289h8.112a.535.535 0 0 1 .537.54v7.688a.54.54 0 0 1-.537.54z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBooksLibraryOff.displayName = 'BooksLibraryOff';
const Memo = memo(SvgBooksLibraryOff);
export default Memo;
