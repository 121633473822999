// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderSharedUserFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.335 3.833C1.335 2.821 2.155 2 3.168 2h2.786c.613 0 1.186.306 1.526.816l.542.813A.83.83 0 0 0 8.715 4h4.12c1.012 0 1.833.82 1.833 1.833V11.5c0 1.012-.82 1.833-1.833 1.833H7.999a1.98 1.98 0 0 0-.2-1.323 4.5 4.5 0 0 0-1.832-1.855 2.667 2.667 0 1 0-4.614-1.822z" />
      <Path
        fillRule="evenodd"
        d="M2.335 8.333a1.667 1.667 0 1 1 3.333 0 1.667 1.667 0 0 1-3.333 0M4 7.667a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333M4.002 10.667c-1.256 0-2.33.745-2.897 1.813a.99.99 0 0 0 .073 1.063c.204.281.543.457.915.457H5.91c.373 0 .711-.176.915-.457A.99.99 0 0 0 6.9 12.48c-.568-1.068-1.64-1.813-2.897-1.813m-2.014 2.282c.417-.784 1.174-1.282 2.014-1.282s1.597.498 2.014 1.282l.002.004-.002.003c-.012.017-.048.044-.106.044H2.093c-.057 0-.093-.027-.105-.044l-.002-.003z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolderSharedUserFill.displayName = 'FolderSharedUserFill';
const Memo = memo(SvgFolderSharedUserFill);
export default Memo;
