// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile3DocumentCloudSyncOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.665 3.167c0-1.013.821-1.834 1.834-1.834h3.447c.487 0 .953.193 1.297.537l3.552 3.553c.344.343.537.81.537 1.296v6.114c0 1.013-.82 1.834-1.833 1.834H8.832a.5.5 0 0 1 0-1h2.667c.46 0 .833-.373.833-.834V6.72q0-.027-.002-.052H9.832a1.833 1.833 0 0 1-1.833-1.834V2.335l-.053-.002H4.5a.833.833 0 0 0-.834.834v5a.5.5 0 1 1-1 0zM9 3.04v1.793c0 .46.373.834.833.834h1.793zm-5.167 7.627a1.5 1.5 0 0 0 0 3h1.833a1 1 0 1 0-.066-1.998.5.5 0 0 1-.466-.25 1.5 1.5 0 0 0-1.301-.752m-2.5 1.5a2.5 2.5 0 0 1 4.505-1.493 2 2 0 0 1-.172 3.993H3.832a2.5 2.5 0 0 1-2.5-2.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFile3DocumentCloudSyncOutline.displayName = 'File3DocumentCloudSyncOutline';
const Memo = memo(SvgFile3DocumentCloudSyncOutline);
export default Memo;
