// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddReactionReactionEmojiOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.666.667a.5.5 0 0 1 .5.5v1.666h1.666a.5.5 0 0 1 0 1h-1.666V5.5a.5.5 0 1 1-1 0V3.833h-1.667a.5.5 0 1 1 0-1h1.667V1.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
      <Path d="M7 6.333c0 .553-.374 1-.834 1s-.834-.447-.834-1 .373-1 .834-1 .833.448.833 1M10.666 6.333c0 .553-.373 1-.834 1S9 6.886 9 6.333s.373-1 .833-1 .834.448.834 1" />
      <Path
        fillRule="evenodd"
        d="M5.76 9.532a.5.5 0 0 1 .707 0 2.167 2.167 0 0 0 3.064 0 .5.5 0 1 1 .707.707 3.167 3.167 0 0 1-4.478 0 .5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7.999 2.333A5.667 5.667 0 1 0 13.665 8a.5.5 0 1 1 1 0A6.667 6.667 0 1 1 8 1.333a.5.5 0 1 1 0 1"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAddReactionReactionEmojiOutline.displayName = 'AddReactionReactionEmojiOutline';
const Memo = memo(SvgAddReactionReactionEmojiOutline);
export default Memo;
