// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudSimpleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 8c0-2.94 2.36-5.333 5.283-5.333 1.944 0 3.64 1.06 4.557 2.632a.38.38 0 0 0 .373.167q.264-.037.537-.037c2.168 0 3.917 1.774 3.917 3.952s-1.749 3.952-3.917 3.952H5.951C3.03 13.333.668 10.941.668 8m5.283-4.333c-2.36 0-4.283 1.935-4.283 4.333s1.923 4.333 4.283 4.333h5.467c1.606 0 2.917-1.317 2.917-2.952s-1.311-2.952-2.917-2.952q-.204 0-.4.027a1.38 1.38 0 0 1-1.374-.654A4.27 4.27 0 0 0 5.95 3.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudSimpleOutline.displayName = 'CloudSimpleOutline';
const Memo = memo(SvgCloudSimpleOutline);
export default Memo;
