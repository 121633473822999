// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFocusCameraExposureMacroFlowerOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 3A.833.833 0 0 0 3 3.833v1.5a.5.5 0 1 1-1 0v-1.5C2 2.821 2.82 2 3.833 2h1.5a.5.5 0 1 1 0 1zm6.334-.5a.5.5 0 0 1 .5-.5h1.5C13.179 2 14 2.82 14 3.833v1.5a.5.5 0 0 1-1 0v-1.5A.833.833 0 0 0 12.167 3h-1.5a.5.5 0 0 1-.5-.5m-2.52 2.487a.5.5 0 0 1 .707 0l.663.663.953-.408a.5.5 0 0 1 .697.46v1.624A2.67 2.67 0 0 1 8.5 9.946v.887a.5.5 0 1 1-1 0v-.887a2.67 2.67 0 0 1-2.167-2.62V5.701a.5.5 0 0 1 .697-.46l.953.41zM8 6.047l-.55.55a.5.5 0 0 1-.55.106l-.567-.243v.866a1.667 1.667 0 0 0 3.334 0V6.46l-.567.243a.5.5 0 0 1-.55-.106zm-5.5 4.12a.5.5 0 0 1 .5.5v1.5c0 .46.373.833.833.833h1.5a.5.5 0 0 1 0 1h-1.5A1.833 1.833 0 0 1 2 12.167v-1.5a.5.5 0 0 1 .5-.5m11 0a.5.5 0 0 1 .5.5v1.5C14 13.179 13.18 14 12.167 14h-1.5a.5.5 0 1 1 0-1h1.5c.46 0 .833-.373.833-.833v-1.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFocusCameraExposureMacroFlowerOutline.displayName = 'FocusCameraExposureMacroFlowerOutline';
const Memo = memo(SvgFocusCameraExposureMacroFlowerOutline);
export default Memo;
