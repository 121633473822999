// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgThumbsDownThumbHandNoContraFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12 3v5.333H13.5a.167.167 0 0 0 .166-.166v-5A.167.167 0 0 0 13.5 3zm-.28 6.333h1.779c.644 0 1.166-.522 1.166-1.166v-5C14.666 2.522 14.144 2 13.5 2h-9.11a2.5 2.5 0 0 0-2.47 2.124L1.36 7.79a2.5 2.5 0 0 0 2.47 2.876h2.91l-.294 1.884a1.833 1.833 0 0 0 1.811 2.116c.434 0 .84-.242 1.041-.633z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgThumbsDownThumbHandNoContraFill.displayName = 'ThumbsDownThumbHandNoContraFill';
const Memo = memo(SvgThumbsDownThumbHandNoContraFill);
export default Memo;
