// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBitcoinFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.915 10a.75.75 0 1 0 0-1.5H7V10zM6.999 7.5V6h1.916a.75.75 0 1 1 0 1.5z" />
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m4.167-3a.5.5 0 0 0 0 1h.5v4h-.5a.5.5 0 0 0 0 1h2v.5a.5.5 0 0 0 1 0V11h.416a1.75 1.75 0 0 0 1.225-3 1.75 1.75 0 0 0-1.225-3H8.5v-.5a.5.5 0 0 0-1 0V5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBitcoinFill.displayName = 'BitcoinFill';
const Memo = memo(SvgBitcoinFill);
export default Memo;
