// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCursor1ArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.986 2.772a.167.167 0 0 0-.212.213l3.741 10.552c.051.143.252.149.311.008l1.794-4.3.461.193-.461-.192c.118-.284.343-.51.627-.628l4.3-1.793a.167.167 0 0 0-.01-.311zm-1.155.547c-.328-.925.564-1.818 1.49-1.49l10.551 3.742c1.006.357 1.045 1.766.06 2.177l-4.3 1.793a.17.17 0 0 0-.09.09L7.75 13.93c-.411.985-1.82.947-2.176-.06z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCursor1ArrowOutline.displayName = 'Cursor1ArrowOutline';
const Memo = memo(SvgCursor1ArrowOutline);
export default Memo;
