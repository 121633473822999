// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShHomeSparkleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.834 2.506c-.268.034-.478.16-.548.233-.109.113-.421.365-.784.657-.383.306-.861.678-1.34 1.05-.478.372-.956.75-1.332 1.049q-.288.228-.472.385-.096.078-.153.126-.027.03-.045.04s-.012.007-.012.013a.8.8 0 0 0-.134.293 1.6 1.6 0 0 0-.051.352v5.976c0 .598.14.744.178.77.051.047.128.06.3.06h2.391V9.97c0-1.268.854-1.825 1.435-1.825h1.517c.58 0 1.435.557 1.435 1.826v3.54h1.836c.051 0 .274-.047.479-.2a.73.73 0 0 0 .318-.63V6.696c0-.272.217-.498.479-.498.26 0 .478.226.478.498v5.977c0 .69-.345 1.162-.72 1.44-.351.266-.772.386-1.034.386h-8.64c-.159 0-.548 0-.88-.272C2.173 13.922 2 13.41 2 12.674V6.697c0-.153.02-.379.077-.611.057-.226.165-.518.376-.737.108-.113.42-.365.784-.658.383-.305.86-.677 1.339-1.049.478-.372.956-.75 1.333-1.05.19-.152.35-.278.471-.384q.096-.078.153-.127c.02-.02.377-.278.472-.332.16-.093.28-.199.714-.239.466-.046 1.02.053 1.467.518a.516.516 0 0 1 0 .704.47.47 0 0 1-.676 0 .79.79 0 0 0-.682-.232zm1.422 10.998v-3.54c0-.724-.42-.83-.478-.83H7.26c-.057 0-.478.106-.478.83v3.54z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M11.316 6.737a.55.55 0 0 1-.491-.28l-.638-1.155-1.243-.551a.51.51 0 0 1-.313-.458c0-.193.121-.372.313-.458l1.268-.578.606-1.122a.56.56 0 0 1 .491-.28.57.57 0 0 1 .498.28l.637 1.155 1.244.551A.52.52 0 0 1 14 4.3a.51.51 0 0 1-.312.459l-1.27.577-.605 1.123a.56.56 0 0 1-.497.279m-.587-2.059.587 1.043.56-1.016.874-.412-.848-.385-.586-1.043-.562 1.016-.873.412z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShHomeSparkleOutline.displayName = 'ShHomeSparkleOutline';
const Memo = memo(SvgShHomeSparkleOutline);
export default Memo;
