// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWriteEditListListFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.501 2a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1zM10.196 5.756a1.833 1.833 0 0 1 2.593 0l.79.79a1.833 1.833 0 0 1 0 2.593L8.197 14.52a.5.5 0 0 1-.353.147H5.168a.5.5 0 0 1-.5-.5V11.49a.5.5 0 0 1 .146-.354zM2.001 5.167a.5.5 0 0 1 .5-.5h4.334a.5.5 0 1 1 0 1H2.5a.5.5 0 0 1-.5-.5M2.5 7.333a.5.5 0 0 0 0 1h2.335a.5.5 0 1 0 0-1z" />
    </Svg>
  );
};
SvgWriteEditListListFill.displayName = 'WriteEditListListFill';
const Memo = memo(SvgWriteEditListListFill);
export default Memo;
