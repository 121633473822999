// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbles2MessagesChatCommunicateFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.836 2c1.012 0 1.833.82 1.833 1.833v4.334c0 1.012-.82 1.833-1.833 1.833h-.833v.833c0 1.013-.821 1.834-1.834 1.834H7.132L4.245 14.27a.5.5 0 0 1-.742-.437v-1.166h-.334a1.833 1.833 0 0 1-1.833-1.834V6.5c0-1.013.82-1.833 1.833-1.833h1v-.834C4.17 2.821 4.99 2 6.003 2zM5.169 4.667h5c1.013 0 1.834.82 1.834 1.833V9h.833c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.836 3H6.003a.833.833 0 0 0-.834.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubbles2MessagesChatCommunicateFill.displayName = 'Bubbles2MessagesChatCommunicateFill';
const Memo = memo(SvgBubbles2MessagesChatCommunicateFill);
export default Memo;
