// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLoginEnterDoorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.167 3H9.833a.5.5 0 0 1 0-1h2.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H9.833a.5.5 0 0 1 0-1h2.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3M7.313 5.313a.5.5 0 0 1 .707 0l2.334 2.333a.5.5 0 0 1 0 .708L8.02 10.687a.5.5 0 0 1-.707-.707l1.48-1.48H2.5a.5.5 0 0 1 0-1h6.293l-1.48-1.48a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLoginEnterDoorOutline.displayName = 'LoginEnterDoorOutline';
const Memo = memo(SvgLoginEnterDoorOutline);
export default Memo;
