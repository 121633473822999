// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGasFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.999 3.833C1.999 2.821 2.819 2 3.832 2h4.333C9.178 2 10 2.82 10 3.833V6h.5c1.012 0 1.833.82 1.833 1.833v2.5a.667.667 0 0 0 1.333 0V5.927c0-.236-.1-.461-.275-.62l-1.226-1.102a.5.5 0 0 1 .67-.743l1.225 1.102c.386.348.606.843.606 1.363v4.406a1.667 1.667 0 1 1-3.333 0v-2.5A.833.833 0 0 0 10.499 7h-.5v6h.166a.5.5 0 0 1 0 1H1.832a.5.5 0 0 1 0-1h.167zm2 2.667a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGasFill.displayName = 'GasFill';
const Memo = memo(SvgGasFill);
export default Memo;
