// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRepeatFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3a.833.833 0 0 0-.833.833v7c0 .46.373.834.833.834h1.667a.5.5 0 1 1 0 1H3.165a1.833 1.833 0 0 1-1.833-1.834v-7C1.332 2.821 2.152 2 3.165 2h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.834-1.833 1.834H9.111l1.227 1.132a.5.5 0 1 1-.678.735l-2.167-2a.5.5 0 0 1-.015-.72l2.167-2.168a.5.5 0 0 1 .707.708L9.04 11.667h3.793c.46 0 .833-.373.833-.834v-7A.833.833 0 0 0 12.832 3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRepeatFill.displayName = 'RepeatFill';
const Memo = memo(SvgRepeatFill);
export default Memo;
