// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDocumentWithLinesListPageFileOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        stroke="#0D1011"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.835 4.5h4.333M5.835 7.167h4.333M5.835 9.833H7.5m-3 4.334h7c.737 0 1.334-.597 1.334-1.334V3.167c0-.737-.597-1.334-1.334-1.334h-7c-.736 0-1.333.597-1.333 1.334v9.666c0 .737.597 1.334 1.333 1.334"
      />
    </Svg>
  );
};
SvgDocumentWithLinesListPageFileOutline.displayName = 'DocumentWithLinesListPageFileOutline';
const Memo = memo(SvgDocumentWithLinesListPageFileOutline);
export default Memo;
