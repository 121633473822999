// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAirconFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.336 2.762c0-.79.64-1.429 1.429-1.429H13.24c.789 0 1.428.64 1.428 1.429v4.762c0 .789-.64 1.428-1.428 1.428H2.765c-.79 0-1.43-.64-1.43-1.428zm1.429-.476a.476.476 0 0 0-.477.476v4.762c0 .263.214.476.477.476h.782V6.094c.005-.383.155-.738.422-1.01a1.4 1.4 0 0 1 1.011-.417h5.717c.383 0 .739.15 1.011.416.272.273.417.628.417 1.011V8h1.116a.476.476 0 0 0 .476-.476V2.762a.476.476 0 0 0-.476-.476zm2.85 8.571a.476.476 0 1 0-.952 0v1.048a.857.857 0 0 1-.857.857H3.71a.476.476 0 1 0 0 .952h.095c1 0 1.81-.81 1.81-1.81zm5.244-.476c.263 0 .476.213.476.476v1.048c0 .473.384.857.857.857h.096a.476.476 0 1 1 0 .952h-.096c-.999 0-1.81-.81-1.81-1.81v-1.047c0-.263.214-.476.477-.476m-2.383.476a.476.476 0 1 0-.953 0v3.334a.476.476 0 1 0 .953 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAirconFill.displayName = 'AirconFill';
const Memo = memo(SvgAirconFill);
export default Memo;
