// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSecurityAccessControlOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.922 3.556c0-.246.186-.445.417-.445h.833c.23 0 .417.2.417.445v.889c0 .353.131.692.366.942s.552.391.884.391.649-.14.883-.39c.235-.25.367-.59.367-.943v-.89c0-.245.186-.444.416-.444s.417.2.417.445v.889a2.3 2.3 0 0 1-.61 1.571c-.391.417-.92.65-1.473.65a2.02 2.02 0 0 1-1.474-.65 2.3 2.3 0 0 1-.61-1.571V4H6.34c-.23 0-.417-.199-.417-.444"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M6.437 1.487a.4.4 0 0 1 .357-.152l4.167.445c.126.013.24.087.308.2a.47.47 0 0 1 .046.382l-.417 1.334a.42.42 0 0 1-.395.304H7.169a.42.42 0 0 1-.404-.337l-.417-1.778a.47.47 0 0 1 .09-.398m.863.796.195.828h2.707l.157-.502zM7.167 7.555a.4.4 0 0 0-.295.13m-2.917 3.111 2.917-3.111zm0 0a.46.46 0 0 0-.122.314c0 .118.044.231.122.315.079.083.185.13.295.13s.217-.047.295-.13l2.327-2.483a.4.4 0 0 1 .454-.096c.156.068.257.23.257.41v4.966c0 .245-.186.444-.416.444s-.417-.199-.417-.444v-3.893l-1.616 1.724c-.234.25-.552.39-.884.39s-.65-.14-.884-.39A1.38 1.38 0 0 1 3 11.11c0-.353.132-.692.366-.942l2.917-3.112c.116-.123.254-.222.405-.289s.315-.101.479-.101H8c.14 0 .27.074.347.198l.486.779.487-.78a.41.41 0 0 1 .347-.197c.773 0 1.515.328 2.062.911a3.22 3.22 0 0 1 .854 2.2v2.667c0 .353-.131.693-.366.943s-.552.39-.884.39-.65-.14-.883-.39a1.38 1.38 0 0 1-.367-.943V9.777c0-.245.187-.444.417-.444s.417.199.417.444v2.667c0 .118.044.23.122.314s.184.13.294.13.217-.047.295-.13a.46.46 0 0 0 .122-.314V9.777a2.3 2.3 0 0 0-.61-1.571 2.03 2.03 0 0 0-1.258-.64L9.18 8.69a.41.41 0 0 1-.347.198.41.41 0 0 1-.346-.198l-.71-1.135h-.61"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M1.336 14.222c0-.246.186-.445.417-.445h12.5c.23 0 .416.2.416.445s-.186.444-.416.444h-12.5c-.23 0-.417-.199-.417-.444"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSecurityAccessControlOutline.displayName = 'SecurityAccessControlOutline';
const Memo = memo(SvgSecurityAccessControlOutline);
export default Memo;
