// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRainyRainFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.281 1.333C3.911 1.333 2 3.28 2 5.667S3.91 10 6.281 10h4.469C12.551 10 14 8.522 14 6.712s-1.449-3.288-3.25-3.288q-.232 0-.456.032a.46.46 0 0 1-.442-.18 4.26 4.26 0 0 0-3.57-1.943M4.994 12.027a.5.5 0 1 0-.922-.387l-.7 1.666a.5.5 0 0 0 .922.388zM8.343 12.026a.5.5 0 1 0-.922-.386l-.697 1.667a.5.5 0 1 0 .922.386zM11.677 12.026a.5.5 0 1 0-.923-.386l-.697 1.667a.5.5 0 0 0 .922.386z" />
    </Svg>
  );
};
SvgRainyRainFill.displayName = 'RainyRainFill';
const Memo = memo(SvgRainyRainFill);
export default Memo;
