// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPin2LocationOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.177 2.821A1.667 1.667 0 1 0 6.82 5.18a1.667 1.667 0 0 0 2.357-2.358m-3.064-.707A2.667 2.667 0 1 1 8.5 6.62v4.88a.5.5 0 1 1-1 0V6.62a2.667 2.667 0 0 1-1.386-4.506m.38 7.118a.5.5 0 0 1-.426.564c-1.173.163-2.15.467-2.817.84-.697.388-.918.766-.918 1.03 0 .167.08.373.33.613.252.24.641.48 1.164.692 1.043.423 2.518.696 4.173.696s3.13-.273 4.172-.696c.523-.212.913-.451 1.164-.692s.33-.446.33-.612c0-.265-.22-.643-.918-1.031-.667-.373-1.644-.677-2.817-.84a.5.5 0 0 1 .138-.99c1.25.174 2.357.504 3.167.956.78.436 1.43 1.073 1.43 1.905 0 .524-.264.975-.638 1.334-.373.357-.887.656-1.48.897-1.19.482-2.797.769-4.548.769s-3.36-.287-4.549-.77c-.593-.24-1.106-.539-1.48-.896-.374-.359-.638-.81-.638-1.334 0-.832.65-1.47 1.43-1.905.81-.452 1.917-.782 3.168-.956a.5.5 0 0 1 .564.426"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPin2LocationOutline.displayName = 'Pin2LocationOutline';
const Memo = memo(SvgPin2LocationOutline);
export default Memo;
