// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicBookMagicianOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834h8.334a.5.5 0 0 1 .5.5v12.334a.5.5 0 0 1-.5.5H4.5a1.833 1.833 0 0 1-1.83-1.943l-.003-.057zm1 9.666c0 .46.373.834.833.834h7.834V12H4.5a.833.833 0 0 0-.833.833M12.335 11H4.5c-.3 0-.583.072-.833.2V3.167c0-.46.373-.834.833-.834h7.834z"
        clipRule="evenodd"
      />
      <Path d="M8.124 4.939a.33.33 0 0 0 .15-.15l.43-.86a.333.333 0 0 1 .596 0l.43.86a.33.33 0 0 0 .149.15l.86.43a.333.333 0 0 1 0 .596l-.86.43a.33.33 0 0 0-.15.149l-.43.86a.333.333 0 0 1-.596 0l-.43-.86a.33.33 0 0 0-.149-.15l-.86-.43a.333.333 0 0 1 0-.595zM5.72 7.808a.2.2 0 0 0 .09-.09l.346-.694a.2.2 0 0 1 .358 0l.347.694a.2.2 0 0 0 .089.09l.694.346a.2.2 0 0 1 0 .358l-.694.347a.2.2 0 0 0-.09.09l-.346.693a.2.2 0 0 1-.358 0l-.347-.693a.2.2 0 0 0-.09-.09l-.693-.347a.2.2 0 0 1 0-.358z" />
    </Svg>
  );
};
SvgMagicBookMagicianOutline.displayName = 'MagicBookMagicianOutline';
const Memo = memo(SvgMagicBookMagicianOutline);
export default Memo;
