// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbleMessageQuestionMarkTooltipFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v7c0 1.013-.82 1.834-1.833 1.834H10.25a.17.17 0 0 0-.107.038l-1.399 1.159a1.17 1.17 0 0 1-1.493-.004l-1.377-1.154a.17.17 0 0 0-.107-.04H3.833A1.833 1.833 0 0 1 2 10.834zm4.582 2.452c0-.83.667-1.51 1.5-1.51.834 0 1.5.68 1.5 1.51 0 .665-.433 1.014-.713 1.204a1 1 0 0 0-.236.214.7.7 0 0 0-.124.313.5.5 0 0 1-.989-.148c.096-.64.47-.99.787-1.206a.9.9 0 0 0 .218-.19.3.3 0 0 0 .057-.187.505.505 0 0 0-.5-.51c-.271 0-.5.224-.5.51a.5.5 0 0 1-1 0M8 10.167a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubbleMessageQuestionMarkTooltipFill.displayName = 'BubbleMessageQuestionMarkTooltipFill';
const Memo = memo(SvgBubbleMessageQuestionMarkTooltipFill);
export default Memo;
