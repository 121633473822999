// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPoliceFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.894 1.967c.708-.37 1.45-.634 2.109-.634s1.4.264 2.108.634c.719.375 1.452.885 2.11 1.432.656.547 1.25 1.144 1.686 1.699.217.277.402.554.534.816.128.255.228.536.228.814 0 .603-.182 1.227-.425 1.79-.17.395-.378.775-.591 1.117.104.164.165.359.165.568v.569c0 .472-.308.87-.731 1.004a6 6 0 0 1-2.068 2.06 5.9 5.9 0 0 1-6.033 0 6 6 0 0 1-2.064-2.054 1.05 1.05 0 0 1-.75-1.01v-.569c0-.214.064-.414.173-.58-.512-.822-1.01-1.9-1.01-2.898 0-.277.101-.559.23-.813.132-.262.317-.538.534-.815.435-.555 1.029-1.151 1.686-1.698a12.3 12.3 0 0 1 2.11-1.432m6.813 8.204a1 1 0 0 1-.062-.053H3.36a.5.5 0 0 1-.233.13v.524c0 .01.03.067.085.075l4.128.004h.085l5.35.006h.004a.085.085 0 0 0 .081-.085v-.528a.5.5 0 0 1-.154-.073m-6.13-6.747a.75.75 0 0 1 .46.07l.956.483.956-.482a.75.75 0 0 1 .876.144l.494.5a.77.77 0 0 1 .22.596.78.78 0 0 1-.3.56l-.013.008a.43.43 0 0 0-.18.35v.002c0 .216.065.355.163.527l.03.054c.097.164.258.439.258.783a.9.9 0 0 1-.121.427 1.7 1.7 0 0 1-.228.307c-.17.185-.392.365-.624.521a4.4 4.4 0 0 1-.747.407c-.245.103-.524.186-.784.186s-.538-.083-.783-.186a4.4 4.4 0 0 1-.748-.407 3.6 3.6 0 0 1-.623-.52 1.7 1.7 0 0 1-.228-.308.9.9 0 0 1-.121-.427c0-.344.16-.62.257-.783q.017-.03.03-.054c.098-.172.163-.311.163-.527v-.002a.43.43 0 0 0-.18-.35l-.011-.008a.77.77 0 0 1-.302-.56.78.78 0 0 1 .22-.596l.494-.5a.76.76 0 0 1 .415-.215"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPoliceFill.displayName = 'PoliceFill';
const Memo = memo(SvgPoliceFill);
export default Memo;
