// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDonutFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.154 4.226a.88.88 0 0 1 1.19.244l.294.422c.226.326.55.572.924.703l.486.17a.88.88 0 0 1 .552 1.083l-.147.492a1.88 1.88 0 0 0 .026 1.162l.17.485a.88.88 0 0 1-.502 1.107l-.477.192a1.88 1.88 0 0 0-.891.745l-.274.435a.88.88 0 0 1-1.178.298l-.448-.254a1.88 1.88 0 0 0-1.138-.232l-.51.058a.88.88 0 0 1-.968-.736l-.08-.508a1.88 1.88 0 0 0-.529-1.035l-.364-.363a.88.88 0 0 1-.028-1.215l.347-.38a1.88 1.88 0 0 0 .48-1.058l.057-.511a.88.88 0 0 1 .933-.78l.513.035A1.88 1.88 0 0 0 7.718 4.5zM7.999 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m8.834-4.1a1.88 1.88 0 0 0-2.545-.52l-.435.274a.88.88 0 0 1-.527.133l-.513-.034a1.88 1.88 0 0 0-1.993 1.666l-.058.51a.88.88 0 0 1-.224.496l-.347.38a1.88 1.88 0 0 0 .06 2.596l.363.364a.88.88 0 0 1 .248.484l.08.508a1.88 1.88 0 0 0 2.068 1.572l.51-.058a.88.88 0 0 1 .533.11l.448.253a1.88 1.88 0 0 0 2.518-.636l.274-.436a.88.88 0 0 1 .417-.348l.477-.192a1.88 1.88 0 0 0 1.073-2.366l-.17-.485a.88.88 0 0 1-.013-.544l.148-.492a1.88 1.88 0 0 0-1.18-2.314l-.486-.17a.88.88 0 0 1-.433-.329z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDonutFill.displayName = 'DonutFill';
const Memo = memo(SvgDonutFill);
export default Memo;
