// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSuitcaseLuggageCaseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.167 2.333a.833.833 0 0 0-.834.834v.166h3.334v-.166a.833.833 0 0 0-.834-.834zm3.5 1v-.166c0-1.013-.821-1.834-1.834-1.834H7.167c-1.013 0-1.834.821-1.834 1.834v.166h-1.5C2.821 3.333 2 4.154 2 5.167v7C2 13.179 2.82 14 3.833 14H4v.167a.5.5 0 0 0 1 0V14h6v.167a.5.5 0 0 0 1 0V14h.167C13.179 14 14 13.18 14 12.167v-7c0-1.013-.82-1.834-1.833-1.834zM5.833 6a.5.5 0 0 1 .5.5v4.333a.5.5 0 0 1-1 0V6.5a.5.5 0 0 1 .5-.5m4.334 0a.5.5 0 0 1 .5.5v4.333a.5.5 0 0 1-1 0V6.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSuitcaseLuggageCaseFill.displayName = 'SuitcaseLuggageCaseFill';
const Memo = memo(SvgSuitcaseLuggageCaseFill);
export default Memo;
