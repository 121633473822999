// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.165 2c-1.012 0-1.833.82-1.833 1.833V11.5c0 1.012.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833V5.833c0-1.012-.82-1.833-1.833-1.833h-4.12a.83.83 0 0 1-.693-.371l-.542-.813A1.83 1.83 0 0 0 5.952 2z" />
    </Svg>
  );
};
SvgFolderFill.displayName = 'FolderFill';
const Memo = memo(SvgFolderFill);
export default Memo;
