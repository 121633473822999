// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAlignTop2ArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 2.5a.5.5 0 0 1 .5-.5h9.667a.5.5 0 1 1 0 1H8.363c.17.055.329.15.463.285l2.362 2.361a.5.5 0 1 1-.707.708l-1.98-1.98V13.5a.5.5 0 0 1-1 0V4.374l-1.98 1.98a.5.5 0 1 1-.707-.708l2.362-2.361c.135-.135.294-.23.464-.285H3.168a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAlignTop2ArrowFill.displayName = 'AlignTop2ArrowFill';
const Memo = memo(SvgAlignTop2ArrowFill);
export default Memo;
