// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrendsDownwardFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.2 10.75a.45.45 0 0 1-.45.45h-3.3a.45.45 0 0 1-.45-.45v-3.3a.45.45 0 1 1 .9 0v2.208l3.334-3.33a1.65 1.65 0 0 1 2.332.002l.704.703a.75.75 0 0 0 1.06 0l2.902-2.901a.45.45 0 0 1 .636.636L10.967 7.67a1.65 1.65 0 0 1-2.334 0l-.703-.704a.75.75 0 0 0-1.06 0L3.53 10.3h2.22a.45.45 0 0 1 .45.45"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrendsDownwardFill.displayName = 'TrendsDownwardFill';
const Memo = memo(SvgTrendsDownwardFill);
export default Memo;
