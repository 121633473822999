// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgKeyboardDownCloseDownOpenArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 3.167c0-1.013.82-1.834 1.833-1.834h11c1.013 0 1.834.821 1.834 1.834v5.666c0 1.013-.821 1.834-1.834 1.834h-11A1.833 1.833 0 0 1 .668 8.833zm1.833-.834a.833.833 0 0 0-.833.834v5.666c0 .46.373.834.833.834h11c.46 0 .834-.374.834-.834V3.167a.833.833 0 0 0-.834-.834zm3.5 5.167a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m.313 4.813a.5.5 0 0 1 .708 0l.98.98.98-.98a.5.5 0 0 1 .706.707l-1.333 1.334a.5.5 0 0 1-.707 0L6.314 13.02a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
      <Path d="M4.167 4.5a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0M4.167 7.5a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0M12.5 4.5a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0M12.5 7.5a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0M6.833 4.5a.333.333 0 1 1-.666 0 .333.333 0 0 1 .666 0M9.833 4.5a.333.333 0 1 1-.666 0 .333.333 0 0 1 .666 0" />
      <Path
        fillRule="evenodd"
        d="M4.501 4.5a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m0 3a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m8.334-3a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m0 3a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m-5.667-3a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m3 0a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m-6.333.333a.333.333 0 1 0 0-.666.333.333 0 0 0 0 .666m0 3a.333.333 0 1 0 0-.666.333.333 0 0 0 0 .666M12.502 4.5a.333.333 0 1 1-.667 0 .333.333 0 0 1 .666 0m-.334 3.333a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667M6.835 4.5a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0m2.666.333a.333.333 0 1 0 0-.666.333.333 0 0 0 0 .666"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgKeyboardDownCloseDownOpenArrowOutline.displayName = 'KeyboardDownCloseDownOpenArrowOutline';
const Memo = memo(SvgKeyboardDownCloseDownOpenArrowOutline);
export default Memo;
