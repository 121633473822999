// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShareleftFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8 2.977v2.196c2.659.062 4.506.646 5.659 2.043 1.155 1.4 1.508 3.497 1.508 6.284a.5.5 0 0 1-.947.224l-.016-.031c-.492-.985-.806-1.614-1.567-2.062-.778-.46-2.084-.76-4.636-.794v2.186c0 .872-1.04 1.327-1.68.734L.892 8.734a1 1 0 0 1 0-1.468l5.43-5.023c.64-.592 1.68-.138 1.68.734" />
    </Svg>
  );
};
SvgShareleftFill.displayName = 'ShareleftFill';
const Memo = memo(SvgShareleftFill);
export default Memo;
