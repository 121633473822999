// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3a.833.833 0 0 0-.833.833V11.5c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833V5.833A.833.833 0 0 0 12.832 5h-4.12a1.83 1.83 0 0 1-1.525-.816l-.542-.813A.83.83 0 0 0 5.952 3zm-1.833.833C1.332 2.821 2.152 2 3.165 2h2.787c.613 0 1.185.306 1.525.816l.542.813A.83.83 0 0 0 8.712 4h4.12c1.013 0 1.833.82 1.833 1.833V11.5c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolderOutline.displayName = 'FolderOutline';
const Memo = memo(SvgFolderOutline);
export default Memo;
