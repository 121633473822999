// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPrivatePoolFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m3.665 2.772-.303-.56A.82.82 0 0 1 4.113 1h.01a7 7 0 0 1 1.398.225 3.04 3.04 0 0 1 1.608 1.338 2.3 2.3 0 0 1 .59-.38l.008-.004a2.93 2.93 0 0 1 3.035.459.818.818 0 0 1-.108 1.34l-1.244.74A2.83 2.83 0 0 1 10.6 7.324a.863.863 0 0 1-1.312.69L6.022 6.017a18.05 18.05 0 0 0-1.091 8.497.438.438 0 0 1-.87.097c-.315-2.81.003-5.65.927-8.313-2.857 2.778-3.038 6.637-3.103 8.04l-.013.25a.438.438 0 0 1-.874-.051l.012-.247c.061-1.37.242-5.456 3.225-8.477H1.848a.886.886 0 0 1-.788-1.191l.002-.005a2.87 2.87 0 0 1 2.603-1.845M6.93 8.92a.44.44 0 0 1 .585.199l.526 1.069h2.585a.438.438 0 1 1 0 .874H8.47l.539 1.095a.44.44 0 0 0 .378.218h5.174a.437.437 0 1 1 0 .875h-1.183l.301 1.206a.438.438 0 1 1-.849.212l-.355-1.418H9.388q-.119 0-.234-.021l-.765 1.529a.438.438 0 0 1-.783-.391l.787-1.574a1.3 1.3 0 0 1-.163-.238l-1.5-3.05a.437.437 0 0 1 .2-.585"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPrivatePoolFill.displayName = 'PrivatePoolFill';
const Memo = memo(SvgPrivatePoolFill);
export default Memo;
