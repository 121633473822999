// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPopsicleBIceCreamSweetsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.371 8c-.82.233-1.497.064-2.067-.143a11 11 0 0 1-.39-.15" />
      <Path
        fillRule="evenodd"
        d="M7.165.667A3.833 3.833 0 0 0 3.332 4.5v5.667c0 1.012.82 1.833 1.833 1.833h1.167v1.667a1.667 1.667 0 1 0 3.333 0V12h1.167c1.013 0 1.833-.82 1.833-1.833V4.5A3.833 3.833 0 0 0 8.832.667zm-1.34 6.877c-.548.228-1.066.65-1.493 1.13v1.493c0 .46.373.833.833.833h5.667c.46 0 .833-.373.833-.833V7.338c-.3.204-.67.428-1.035.574a3 3 0 0 1-.259.088m-1.706 5.667V12H7.332v1.667a.667.667 0 1 0 1.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPopsicleBIceCreamSweetsFill.displayName = 'PopsicleBIceCreamSweetsFill';
const Memo = memo(SvgPopsicleBIceCreamSweetsFill);
export default Memo;
