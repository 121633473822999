// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMedalTrophyBadgeWinnerWinFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001.667a5.333 5.333 0 0 0-3.333 9.496v4.216a1 1 0 0 0 1.426.904L8 14.386l1.908.897a1 1 0 0 0 1.426-.904v-4.216A5.333 5.333 0 0 0 8.002.666m-2.333 10.13a5.3 5.3 0 0 0 2.333.536c.837 0 1.629-.193 2.334-.536v3.582l-1.908-.898a1 1 0 0 0-.851 0l-1.908.898z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMedalTrophyBadgeWinnerWinFill.displayName = 'MedalTrophyBadgeWinnerWinFill';
const Memo = memo(SvgMedalTrophyBadgeWinnerWinFill);
export default Memo;
