// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLoadingBayOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.222 1.333h1.032c.229 0 .414.187.414.417s-.185.416-.414.416H13.24a.69.69 0 0 0-.647.528l-1.774 8.003a2.09 2.09 0 0 1 .584 3.36 2.063 2.063 0 0 1-3.434-.836L3.7 12.298a.69.69 0 0 0-.6.2l-.391.4a.41.41 0 0 1-.586.004.42.42 0 0 1-.004-.59l.392-.4a1.52 1.52 0 0 1 1.353-.431l4.019.869c.052-.467.26-.905.593-1.24a2.06 2.06 0 0 1 1.538-.609l1.774-8c.078-.325.261-.617.52-.828.26-.211.58-.33.914-.34M8.738 12.898a.4.4 0 0 0-.035-.194 1.26 1.26 0 0 1 .358-1.005 1.24 1.24 0 0 1 1.756 0 1.254 1.254 0 0 1 0 1.768 1.238 1.238 0 0 1-2.08-.569M5.178 2.594a.417.417 0 0 0-.501.31L3.236 8.982a.414.414 0 0 0 .304.5h.003l3.283.789a.417.417 0 0 1-.192.81l-3.28-.788h-.002a1.23 1.23 0 0 1-.774-.561 1.25 1.25 0 0 1-.146-.949l1.44-6.078c.079-.321.28-.598.56-.771a1.24 1.24 0 0 1 .935-.152h.002l1.052.253.048.008 2.045.492q.023.006.045.014l.834.2a1.24 1.24 0 0 1 .77.562 1.26 1.26 0 0 1 .149.946L9.258 8.702a.414.414 0 1 1-.806-.194l1.055-4.445A.42.42 0 0 0 9.2 3.56l-.476-.114-.597 2.515a.417.417 0 0 1-.498.309l-2.046-.492a.414.414 0 0 1-.306-.502l.597-2.515-.695-.167m1.5.36-.5 2.11 1.24.299.5-2.11z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLoadingBayOutline.displayName = 'LoadingBayOutline';
const Memo = memo(SvgLoadingBayOutline);
export default Memo;
