// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDeliveryOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3h-1.5v2.167a1.5 1.5 0 0 1-1.5 1.5H6.833a1.5 1.5 0 0 1-1.5-1.5V3zm0-1C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h8.334C13.179 14 14 13.18 14 12.167V3.833C14 2.821 13.18 2 12.167 2zm2.5 1v2.167a.5.5 0 0 0 .5.5h2.334a.5.5 0 0 0 .5-.5V3zm2.334 7.833a.5.5 0 0 1 .5-.5h1.666a.5.5 0 1 1 0 1H9.167a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDeliveryOutline.displayName = 'DeliveryOutline';
const Memo = memo(SvgDeliveryOutline);
export default Memo;
