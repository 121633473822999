// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile1DocumentCloudSyncOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834H7.95c.486 0 .953.193 1.296.537l3.553 3.553c.344.343.537.81.537 1.296v6.114c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834zm1.833-.834a.833.833 0 0 0-.833.834v9.666c0 .46.373.834.833.834h7c.46 0 .834-.373.834-.834V6.72q0-.027-.002-.052H9.835A1.833 1.833 0 0 1 8 4.833V2.335l-.052-.002zm4.5.707v1.793c0 .46.373.834.834.834h1.792z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFile1DocumentCloudSyncOutline.displayName = 'File1DocumentCloudSyncOutline';
const Memo = memo(SvgFile1DocumentCloudSyncOutline);
export default Memo;
