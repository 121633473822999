// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDotHorizontalMenuGrabOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.999 8.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333M13.499 8.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333M2.499 8.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333" />
      <Path
        fillRule="evenodd"
        d="M2.499 7.833a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333M1.332 8a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0m6.667-.167a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333M6.832 8a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0m6.667-.167a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333M12.332 8a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDotHorizontalMenuGrabOutline.displayName = 'DotHorizontalMenuGrabOutline';
const Memo = memo(SvgDotHorizontalMenuGrabOutline);
export default Memo;
