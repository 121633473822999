// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCupCoffeeTeaMilkOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.668 1.333a.5.5 0 0 1 .5.5V3.5a.5.5 0 0 1-1 0V1.833a.5.5 0 0 1 .5-.5m2.667 0a.5.5 0 0 1 .5.5V3.5a.5.5 0 0 1-1 0V1.833a.5.5 0 0 1 .5-.5m2.666 0a.5.5 0 0 1 .5.5V3.5a.5.5 0 0 1-1 0V1.833a.5.5 0 0 1 .5-.5m-7.333 4.5c0-.644.522-1.166 1.167-1.166h7c.644 0 1.166.522 1.166 1.166V6h.334a2.333 2.333 0 1 1 0 4.667H12v2.166c0 1.013-.82 1.834-1.833 1.834H4.501a1.833 1.833 0 0 1-1.833-1.834zm8.333 0a.167.167 0 0 0-.166-.166h-7a.167.167 0 0 0-.167.166v7c0 .46.373.834.833.834h5.667c.46 0 .833-.373.833-.834zm1 3.834h.334a1.333 1.333 0 1 0 0-2.667H12z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCupCoffeeTeaMilkOutline.displayName = 'CupCoffeeTeaMilkOutline';
const Memo = memo(SvgCupCoffeeTeaMilkOutline);
export default Memo;
