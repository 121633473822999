// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArchiveFolderBoxOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.5 2.333a.167.167 0 0 0-.167.167v2.833h7.334v-1.5a.167.167 0 0 0-.167-.166H8.61c-.31 0-.607-.123-.825-.342l-.748-.748a.83.83 0 0 0-.59-.244zm8.167 3.001v-1.5c0-.645-.523-1.167-1.167-1.167H8.61a.17.17 0 0 1-.118-.05l-.748-.747a1.83 1.83 0 0 0-1.296-.537H4.5c-.644 0-1.167.523-1.167 1.167v2.834a4 4 0 0 0-.245.01 1.2 1.2 0 0 0-.451.117c-.22.111-.398.29-.51.51a1.2 1.2 0 0 0-.116.45C2 6.553 2 6.711 2 6.883v4.504c0 .356 0 .653.02.896.02.253.065.491.18.716.176.345.456.625.801.801.225.115.463.16.716.18.243.02.54.02.896.02h6.777c.356 0 .654 0 .896-.02.254-.02.492-.065.716-.18.345-.176.626-.456.801-.801.115-.225.16-.463.18-.716.02-.243.02-.54.02-.896V6.883c0-.172 0-.33-.01-.462a1.2 1.2 0 0 0-.117-.45 1.17 1.17 0 0 0-.51-.51 1.2 1.2 0 0 0-.45-.117 4 4 0 0 0-.25-.01m-9.58 1.02.001-.001m.002-.001a.4.4 0 0 1 .08-.012c.087-.007.203-.008.398-.008h8.87c.195 0 .31 0 .397.008a.4.4 0 0 1 .08.012q.045.024.07.07a.4.4 0 0 1 .012.08c.007.086.007.202.007.397v4.467c0 .381 0 .637-.016.835-.016.191-.044.283-.075.343a.83.83 0 0 1-.364.364c-.06.031-.152.059-.343.074-.197.017-.453.017-.835.017H4.633c-.381 0-.637 0-.835-.017-.191-.015-.283-.043-.343-.074a.83.83 0 0 1-.364-.364c-.03-.06-.059-.152-.074-.343A12 12 0 0 1 3 11.367V6.9c0-.195 0-.311.007-.397a.4.4 0 0 1 .012-.08.17.17 0 0 1 .07-.07M4.666 8.5a.5.5 0 0 1 .5-.5h2.667a.5.5 0 1 1 0 1H5.167a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArchiveFolderBoxOutline.displayName = 'ArchiveFolderBoxOutline';
const Memo = memo(SvgArchiveFolderBoxOutline);
export default Memo;
