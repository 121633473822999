// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTagSaleSellForSaleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.167c0-1.013.82-1.834 1.833-1.834H7.44c.486 0 .952.193 1.296.537l5.34 5.34a1.833 1.833 0 0 1 0 2.593l-4.274 4.274a1.833 1.833 0 0 1-2.593 0l-5.34-5.34a1.83 1.83 0 0 1-.537-1.296zM4.999 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTagSaleSellForSaleFill.displayName = 'TagSaleSellForSaleFill';
const Memo = memo(SvgTagSaleSellForSaleFill);
export default Memo;
