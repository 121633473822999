// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronGrabberHorizontalFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.353 4.98a.5.5 0 0 1 0 .707L4.158 7.882l-.353-.353.353.353a.167.167 0 0 0 0 .236l2.195 2.195a.5.5 0 1 1-.707.707L3.451 8.825a1.167 1.167 0 0 1 0-1.65L5.646 4.98a.5.5 0 0 1 .707 0m3.293 0a.5.5 0 0 1 .707 0l2.196 2.195a1.167 1.167 0 0 1 0 1.65l-2.195 2.195a.5.5 0 1 1-.708-.707l2.196-2.195a.167.167 0 0 0 0-.236L9.646 5.687a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronGrabberHorizontalFill.displayName = 'ChevronGrabberHorizontalFill';
const Memo = memo(SvgChevronGrabberHorizontalFill);
export default Memo;
