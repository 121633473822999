// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHideEyeOffSeeLookNotVisibleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.48 1.48a.5.5 0 0 1 .708 0L14.52 13.813a.5.5 0 1 1-.707.707l-2.233-2.232c-1.682 1.036-3.58 1.307-5.383.79-1.927-.552-3.699-1.987-4.984-4.242a1.69 1.69 0 0 1 0-1.671c.674-1.185 1.483-2.143 2.379-2.866L1.48 2.187a.5.5 0 0 1 0-.707m2.824 3.53c-.82.644-1.58 1.524-2.222 2.65-.12.21-.12.47 0 .681 1.181 2.07 2.76 3.308 4.391 3.776 1.449.415 2.978.235 4.378-.56L9.5 10.207A2.667 2.667 0 0 1 5.794 6.5zM6.523 7.23a1.667 1.667 0 0 0 2.25 2.25zm7.396.43c-1.922-3.373-4.892-4.545-7.52-3.754a.5.5 0 1 1-.288-.958c3.168-.953 6.576.531 8.677 4.217a1.69 1.69 0 0 1 0 1.67 11 11 0 0 1-1.276 1.804.5.5 0 0 1-.75-.66 10 10 0 0 0 1.157-1.638c.12-.21.12-.47 0-.68"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHideEyeOffSeeLookNotVisibleOutline.displayName = 'HideEyeOffSeeLookNotVisibleOutline';
const Memo = memo(SvgHideEyeOffSeeLookNotVisibleOutline);
export default Memo;
