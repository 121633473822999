// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronLargeDownChevDownOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.402 6.245a.5.5 0 0 1 .686-.175l5.485 3.262c.262.156.59.156.852 0L13.91 6.07a.5.5 0 1 1 .511.86L8.936 10.19a1.83 1.83 0 0 1-1.874 0L1.577 6.93a.5.5 0 0 1-.175-.685"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronLargeDownChevDownOutline.displayName = 'ChevronLargeDownChevDownOutline';
const Memo = memo(SvgChevronLargeDownChevDownOutline);
export default Memo;
