// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSpaceStarFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m10.507 4.167a5.65 5.65 0 0 0-3.84-1.5c-1.482 0-2.83.568-3.84 1.5a5.65 5.65 0 0 0 3.84 1.5c1.482 0 2.83-.569 3.84-1.5M7.086 5.242l-.895.448a.347.347 0 0 0 0 .62l.895.448a.35.35 0 0 1 .155.155l.447.895a.347.347 0 0 0 .621 0l.448-.895a.35.35 0 0 1 .155-.155l.895-.447a.347.347 0 0 0 0-.621l-.895-.448a.35.35 0 0 1-.155-.155l-.448-.895a.347.347 0 0 0-.62 0l-.448.895a.35.35 0 0 1-.155.155"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSpaceStarFill.displayName = 'SpaceStarFill';
const Memo = memo(SvgSpaceStarFill);
export default Memo;
