// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLoaderOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a.5.5 0 0 1 .5.5v2.333a.5.5 0 1 1-1 0V1.833a.5.5 0 0 1 .5-.5M3.285 3.285a.5.5 0 0 1 .707 0l1.65 1.65a.5.5 0 0 1-.707.708l-1.65-1.65a.5.5 0 0 1 0-.708m9.428.001a.5.5 0 0 1 0 .707l-1.65 1.65a.5.5 0 0 1-.707-.707l1.65-1.65a.5.5 0 0 1 .707 0M1.332 8a.5.5 0 0 1 .5-.5h2.333a.5.5 0 1 1 0 1H1.832a.5.5 0 0 1-.5-.5m10 0a.5.5 0 0 1 .5-.5h2.333a.5.5 0 0 1 0 1h-2.333a.5.5 0 0 1-.5-.5m-.976 2.357a.5.5 0 0 1 .707 0l1.65 1.65a.5.5 0 0 1-.707.707l-1.65-1.65a.5.5 0 0 1 0-.707m-4.714 0a.5.5 0 0 1 0 .707l-1.65 1.65a.5.5 0 1 1-.707-.707l1.65-1.65a.5.5 0 0 1 .707 0m2.357.976a.5.5 0 0 1 .5.5v2.333a.5.5 0 1 1-1 0v-2.333a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLoaderOutline.displayName = 'LoaderOutline';
const Memo = memo(SvgLoaderOutline);
export default Memo;
