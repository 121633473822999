// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCrownVipOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 2a.5.5 0 0 1 .419.226l2.573 3.936 3.59-2.094a.5.5 0 0 1 .742.532l-1.49 7.268a1.83 1.83 0 0 1-1.796 1.465H3.964c-.87 0-1.621-.612-1.796-1.465L.678 4.6a.5.5 0 0 1 .742-.532l3.59 2.094 2.573-3.936A.5.5 0 0 1 8 2m0 1.414L5.586 7.107a.5.5 0 0 1-.67.158l-3.034-1.77 1.266 6.172c.08.388.42.666.816.666h8.075a.83.83 0 0 0 .816-.666l1.265-6.171-3.033 1.77a.5.5 0 0 1-.67-.159z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCrownVipOutline.displayName = 'CrownVipOutline';
const Memo = memo(SvgCrownVipOutline);
export default Memo;
