// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFour50LuxLightingLightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.157 1.873a1.97 1.97 0 0 1 1.362-.54c.51 0 1 .194 1.362.54q.063.06.12.127.057-.066.12-.127a1.97 1.97 0 0 1 1.363-.54c.51 0 1 .194 1.361.54.362.346.564.815.564 1.304v6.1h.147c.245 0 .444.19.444.425a3.75 3.75 0 0 1-1.172 2.708 4 4 0 0 1-1.197.789v1.042a.435.435 0 0 1-.444.425h-2.37a.435.435 0 0 1-.445-.425V13.2a4 4 0 0 1-1.2-.79A3.75 3.75 0 0 1 4 9.702c0-.235.199-.426.444-.426h.149V3.177c0-.49.203-.958.564-1.304m5.364 7.403V3.177a.97.97 0 0 0-.304-.702 1.06 1.06 0 0 0-.942-.27c-.197.038-.38.131-.525.27a.97.97 0 0 0-.303.702v6.1zm-2.963 0V3.177h-.002a.97.97 0 0 0-.304-.702 1.06 1.06 0 0 0-.942-.27c-.197.038-.38.131-.524.27a.97.97 0 0 0-.304.702v6.1zM5.8 11.808a2.93 2.93 0 0 1-.88-1.68h6.16a2.93 2.93 0 0 1-.88 1.68 3.18 3.18 0 0 1-2.2.873 3.18 3.18 0 0 1-2.2-.873m1.46 2.007v-.283h1.482v.283z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFour50LuxLightingLightOutline.displayName = 'Four50LuxLightingLightOutline';
const Memo = memo(SvgFour50LuxLightingLightOutline);
export default Memo;
