// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbleMessageAnnotationExclamationMarkAlertTooltipFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 2C2.821 2 2 2.82 2 3.833v7c0 1.013.82 1.834 1.833 1.834h1.935q.06 0 .107.039l1.377 1.154c.431.361 1.06.363 1.493.004l1.4-1.159a.17.17 0 0 1 .106-.038h1.916c1.012 0 1.833-.821 1.833-1.834v-7C14 2.821 13.18 2 12.167 2zM8 4.667a.5.5 0 0 1 .5.5V7.5a.5.5 0 0 1-1 0V5.167a.5.5 0 0 1 .5-.5m0 5.166A.667.667 0 1 0 8 8.5a.667.667 0 0 0 0 1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubbleMessageAnnotationExclamationMarkAlertTooltipFill.displayName =
  'BubbleMessageAnnotationExclamationMarkAlertTooltipFill';
const Memo = memo(SvgBubbleMessageAnnotationExclamationMarkAlertTooltipFill);
export default Memo;
