// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLocationExploreCompassFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.707 1.849a1.167 1.167 0 0 1 1.442 1.442l-2.133 7.468a1.83 1.83 0 0 1-1.26 1.259L3.29 14.15a1.167 1.167 0 0 1-1.442-1.442l2.134-7.467a1.83 1.83 0 0 1 1.259-1.26zM6.415 8a1.583 1.583 0 1 1 3.167 0 1.583 1.583 0 0 1-3.167 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLocationExploreCompassFill.displayName = 'LocationExploreCompassFill';
const Memo = memo(SvgLocationExploreCompassFill);
export default Memo;
