// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDelivery2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 2.5a.5.5 0 0 1 .5-.5h.667C4.179 2 5 2.82 5 3.833V9.05c.98.199 1.751.97 1.95 1.95h6.55a.5.5 0 0 1 0 1H6.95A2.5 2.5 0 1 1 4 9.05V3.833A.833.833 0 0 0 3.167 3H2.5a.5.5 0 0 1-.5-.5m6.5 1.167a.833.833 0 0 0-.833.833v3c0 .46.373.833.833.833h3.667c.46 0 .833-.373.833-.833v-3a.833.833 0 0 0-.833-.833h-1.334V5.5a.5.5 0 1 1-1 0V3.667zM6.667 4.5c0-1.013.82-1.833 1.833-1.833h3.667c1.012 0 1.833.82 1.833 1.833v3c0 1.013-.82 1.833-1.833 1.833H8.5A1.833 1.833 0 0 1 6.667 7.5zM4.5 10a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDelivery2Outline.displayName = 'Delivery2Outline';
const Memo = memo(SvgDelivery2Outline);
export default Memo;
