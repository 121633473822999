// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBalconyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1 1.439A.44.44 0 0 1 1.441 1H10.5a.44.44 0 0 1 .441.439.44.44 0 0 1-.441.438H4.998l-.025.203a5.6 5.6 0 0 1-.374 1.385c-.216.51-.55 1.043-1.066 1.45q-.125.098-.26.183c.431.217.865.553 1.194 1.071.347.547.56 1.265.56 2.199a.44.44 0 0 1-.442.439.44.44 0 0 1-.441-.439c0-.799-.18-1.348-.423-1.73a2.1 2.1 0 0 0-.897-.782 3 3 0 0 0-.938-.254h-.004v2.504a.44.44 0 0 1-.44.439A.44.44 0 0 1 1 8.106V1.439m.882 3.243a2.25 2.25 0 0 0 1.103-.455c.367-.29.623-.684.8-1.102a4.7 4.7 0 0 0 .325-1.248H1.882zm7.298.112c0-1.082.882-1.96 1.97-1.96s1.97.878 1.97 1.96c0 .557-.233 1.06-.607 1.416.704.36 1.26.983 1.52 1.713a.442.442 0 0 1-.832.292c-.294-.828-1.134-1.461-2.055-1.461-.973 0-1.758.627-2.054 1.461a.442.442 0 0 1-.832-.292 3.1 3.1 0 0 1 1.516-1.725 1.95 1.95 0 0 1-.596-1.404M1.44 9.736a.44.44 0 0 0-.441.439v4.386a.44.44 0 0 0 .441.439H9v.001h3V15h2.559a.44.44 0 0 0 .441-.439v-4.386a.44.44 0 0 0-.441-.439H1.44m11.027 4.387v-3.51h1.65v3.51zm-3.523 0v-3.51H7.18v3.51zm-5.476 0v-3.51H1.882v3.51z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBalconyFill.displayName = 'BalconyFill';
const Memo = memo(SvgBalconyFill);
export default Memo;
