// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBackupGeneratorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.776 2.31c.126-.531.603-.977 1.167-.977h6.056c.564 0 1.042.448 1.167.981l1.43 4.962v.001c.106.36.115.769-.124 1.177a.5.5 0 0 1-.052.071c-.103.117-.236.24-.393.337a1.14 1.14 0 0 1-.594.183h-.366v3.28c0 .733-.627 1.358-1.364 1.358H9.665a.464.464 0 0 1-.465-.463c0-.256.208-.463.465-.463h2.038c.223 0 .434-.21.434-.432v-3.28H4.43c-.373 0-.781-.153-1.04-.591-.239-.409-.23-.818-.124-1.178l.002-.004zm4.93 5.81h3.727a.24.24 0 0 0 .103-.044.8.8 0 0 0 .153-.126c.06-.123.063-.248.014-.414v-.003l-.635-2.202a.5.5 0 0 1-.105.012H9.706zm0-3.702h3.099l-.536-1.861-.008-.029c-.04-.184-.195-.27-.262-.27H9.706zm-.93-2.16v2.16h-3.67l.565-1.855.01-.035c.04-.184.195-.27.262-.27zm-4.618 5.28.67-2.2a1 1 0 0 0 .072.005h3.876V8.12H4.429a.3.3 0 0 1-.134-.027.25.25 0 0 1-.102-.105c-.079-.134-.088-.267-.035-.45M2.4 9.864c-.286 0-.56.097-.764.3a1.06 1.06 0 0 0-.301.76v2.683c0 .284.097.557.301.76s.478.3.764.3h3.896c.286 0 .56-.098.764-.3.164-.164.259-.372.29-.596a1.06 1.06 0 0 0 .729-.299c.204-.203.3-.475.3-.76v-.894c0-.284-.096-.557-.3-.76a1.06 1.06 0 0 0-.73-.299 1.03 1.03 0 0 0-.29-.595 1.07 1.07 0 0 0-.763-.3zm-.135 1.06c0-.074.023-.1.029-.106s.031-.028.106-.028h3.896c.074 0 .1.022.106.028s.029.032.029.106v.298c0 .255.208.462.465.462h.42c.073 0 .1.023.105.029.006.006.029.031.029.105v.895c0 .073-.023.099-.029.105s-.032.028-.106.028h-.42a.464.464 0 0 0-.464.463v.298c0 .074-.023.1-.03.105-.005.006-.03.029-.105.029H2.401c-.075 0-.1-.023-.106-.029s-.029-.031-.029-.105z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBackupGeneratorOutline.displayName = 'BackupGeneratorOutline';
const Memo = memo(SvgBackupGeneratorOutline);
export default Memo;
