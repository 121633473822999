// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAdCreditFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
      <Path d="M7.937 13.898a5.93 5.93 0 1 0 0-11.859 5.93 5.93 0 0 0 0 11.859" />
      <Path d="M5.582 11.126a.64.64 0 0 1-.388-.111.5.5 0 0 1-.186-.299.7.7 0 0 1 .076-.417l2.16-4.782q.136-.29.322-.418a.7.7 0 0 1 .43-.136q.245 0 .43.136.187.127.313.418l2.178 4.782a.7.7 0 0 1 .076.426.44.44 0 0 1-.169.298.63.63 0 0 1-.371.103q-.287 0-.448-.137a1.15 1.15 0 0 1-.27-.426L9.23 9.378l.523.367h-3.52l.523-.367-.498 1.185a1.3 1.3 0 0 1-.27.435q-.135.128-.405.128m2.397-4.68-1.08 2.6-.228-.333h2.642l-.228.333-1.089-2.6z" />
    </Svg>
  );
};
SvgAdCreditFill.displayName = 'AdCreditFill';
const Memo = memo(SvgAdCreditFill);
export default Memo;
