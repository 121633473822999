// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBrainAiThinkingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.505 1.453a3.243 3.243 0 0 0-3.921 2.008A2.9 2.9 0 0 0 1.892 8a2.898 2.898 0 0 0 1.02 4.295 3.585 3.585 0 0 0 4.605 2.154.5.5 0 0 1-.018-.135v-2.981c0-.92-.746-1.666-1.667-1.666a.5.5 0 0 1 0-1c.63 0 1.21.219 1.667.585V1.533q0-.04.006-.08M8.48 14.45a3.585 3.585 0 0 0 4.605-2.154 2.898 2.898 0 0 0 1.02-4.296 2.9 2.9 0 0 0-1.691-4.539 3.24 3.24 0 0 0-3.922-2.008 1 1 0 0 1 .007.08V4.67c.001.92.747 1.664 1.666 1.664a.5.5 0 0 1 0 1c-.63 0-1.21-.219-1.666-.585v7.566a.5.5 0 0 1-.019.135" />
    </Svg>
  );
};
SvgBrainAiThinkingFill.displayName = 'BrainAiThinkingFill';
const Memo = memo(SvgBrainAiThinkingFill);
export default Memo;
