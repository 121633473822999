// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSurpriseBunnyHatMagicHatFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.83 7.333a15 15 0 0 0-.16-1.755c-.201-1.406-.748-2.59-1.239-3.416a10 10 0 0 0-.655-.97C5.525.862 5.241.57 4.961.264c-.17.385-.345.762-.485 1.159-.1.284-.228.689-.344 1.18-.23.976-.417 2.314-.217 3.718.056.391.124.708.199 1.011H1.832a.5.5 0 0 0 0 1h.833v4.5c0 1.013.821 1.834 1.834 1.834h7c1.012 0 1.833-.821 1.833-1.834v-4.5h.833a.5.5 0 0 0 0-1H11.91c.248-.629.267-1.177.262-1.619l2.541-.691-.535-.604-.002-.002-.007-.008-.025-.028a11 11 0 0 0-.43-.448c-.28-.281-.67-.65-1.097-.987-.42-.332-.91-.661-1.388-.833-.47-.169-1.083-.232-1.546.23-1.105 1.104-1.465 2.833-1.511 4.99zm-1 0a14 14 0 0 0-.15-1.614c-.177-1.236-.662-2.293-1.109-3.047a9 9 0 0 0-.297-.468 11 11 0 0 0-.169.63c-.213.904-.377 2.109-.2 3.347.064.449.144.782.24 1.152zm2.341 0h1.632c.38-.672.373-1.21.365-1.76l-.003-.24v-.382l1.691-.46c-.243-.236-.542-.51-.86-.76-.393-.311-.78-.56-1.106-.677-.332-.12-.449-.055-.502-.003-.813.813-1.169 2.188-1.217 4.282"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSurpriseBunnyHatMagicHatFill.displayName = 'SurpriseBunnyHatMagicHatFill';
const Memo = memo(SvgSurpriseBunnyHatMagicHatFill);
export default Memo;
