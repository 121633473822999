// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBookmarkCheckAddCheckFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      fill={color}
      viewBox="0 0 17 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.725 1.02h5.549c.363 0 .666 0 .914.02.258.021.5.067.73.184.352.179.638.465.817.817.117.23.163.472.184.73.02.248.02.55.02.914v8.886c0 .315 0 .586-.02.8s-.064.465-.224.675a1.19 1.19 0 0 1-.888.466c-.264.013-.496-.092-.683-.198-.188-.105-.411-.26-.67-.438L9.444 12.49c-.48-.331-.613-.413-.742-.445a.85.85 0 0 0-.407 0c-.13.032-.263.114-.742.445l-2.009 1.385c-.26.179-.483.333-.67.438-.187.106-.42.211-.683.198a1.19 1.19 0 0 1-.888-.466c-.16-.21-.205-.46-.225-.674s-.02-.486-.02-.801V3.685c0-.363 0-.666.021-.914.021-.258.067-.5.184-.73a1.87 1.87 0 0 1 .817-.817 1.9 1.9 0 0 1 .73-.184c.248-.02.55-.02.914-.02m5.073 4.542a.51.51 0 0 0-.721-.721L7.819 7.098l-.76-.761a.51.51 0 0 0-.722.721L7.459 8.18a.51.51 0 0 0 .721 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBookmarkCheckAddCheckFill.displayName = 'BookmarkCheckAddCheckFill';
const Memo = memo(SvgBookmarkCheckAddCheckFill);
export default Memo;
