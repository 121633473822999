// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierCircleVectorNodesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.165 1.333C6.521 1.333 6 1.856 6 2.5v.735A5.18 5.18 0 0 0 3.234 6h-.735c-.645 0-1.167.522-1.167 1.167v1.666c0 .645.522 1.167 1.167 1.167h.735a5.18 5.18 0 0 0 2.765 2.765v.735c0 .644.522 1.167 1.166 1.167h1.667c.644 0 1.167-.523 1.167-1.167v-.735A5.18 5.18 0 0 0 12.764 10h.735c.644 0 1.166-.522 1.166-1.167V7.167C14.665 6.522 14.143 6 13.5 6h-.735a5.18 5.18 0 0 0-2.765-2.765V2.5c0-.644-.523-1.167-1.167-1.167zm-2.83 4.68a4.2 4.2 0 0 1 1.676-1.676c.082.564.568.996 1.154.996h1.667c.587 0 1.072-.432 1.154-.996.707.385 1.291.968 1.676 1.675a1.17 1.17 0 0 0-.997 1.155v1.666c0 .587.433 1.072.997 1.155a4.2 4.2 0 0 1-1.676 1.675 1.17 1.17 0 0 0-1.154-.996H7.165c-.586 0-1.072.433-1.154.996a4.2 4.2 0 0 1-1.675-1.675c.563-.083.996-.568.996-1.155V7.167c0-.587-.433-1.072-.996-1.155"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBezierCircleVectorNodesFill.displayName = 'BezierCircleVectorNodesFill';
const Memo = memo(SvgBezierCircleVectorNodesFill);
export default Memo;
