// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFacebookColour: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.67 8a6.667 6.667 0 1 0-7.71 6.586V9.927H5.269V8h1.693V6.531c0-1.67.995-2.594 2.518-2.594.73 0 1.492.13 1.492.13v1.641h-.84c-.828 0-1.087.514-1.087 1.042V8h1.85l-.296 1.927H9.044v4.659A6.67 6.67 0 0 0 14.67 8" />
      <Path d="M10.595 9.927 10.891 8H9.04V6.75c0-.527.259-1.042 1.087-1.042h.84v-1.64s-.762-.13-1.492-.13c-1.522 0-2.518.922-2.518 2.593V8H5.266v1.927h1.692v4.659c.69.108 1.393.108 2.084 0V9.927z" />
    </Svg>
  );
};
SvgFacebookColour.displayName = 'FacebookColour';
const Memo = memo(SvgFacebookColour);
export default Memo;
