// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWatch2ClockTimeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m4.706 2.298-.185 1.66A5.32 5.32 0 0 0 2.668 8c0 1.616.718 3.064 1.853 4.042l.185 1.66c.103.929.888 1.631 1.822 1.631h2.947c.934 0 1.719-.702 1.822-1.63l.184-1.661A5.32 5.32 0 0 0 13.335 8a5.32 5.32 0 0 0-1.854-4.042l-.184-1.66A1.833 1.833 0 0 0 9.475.667H6.528c-.934 0-1.719.702-1.822 1.63m1.822-.631a.833.833 0 0 0-.828.741l-.092.825a5.3 5.3 0 0 1 2.393-.566c.86 0 1.673.204 2.394.566l-.092-.825a.833.833 0 0 0-.828-.741zm3.867 11.1A5.3 5.3 0 0 1 8 13.334c-.86 0-1.673-.203-2.393-.565l.092.824a.833.833 0 0 0 .828.741h2.947c.424 0 .781-.319.828-.74zM8.5 5.834a.5.5 0 1 0-1 0V8a.5.5 0 0 0 .147.354l1 1a.5.5 0 0 0 .707-.707L8.5 7.793z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWatch2ClockTimeFill.displayName = 'Watch2ClockTimeFill';
const Memo = memo(SvgWatch2ClockTimeFill);
export default Memo;
