// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChecklistBoxCheckListOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3zm3.493 1.768a.5.5 0 0 1 .1.7L6.18 7.132a.5.5 0 0 1-.678.116l-.75-.5a.5.5 0 1 1 .555-.832l.356.237.964-1.285a.5.5 0 0 1 .7-.1M8.706 6a.5.5 0 0 1 .5-.5h1.666a.5.5 0 0 1 0 1H9.205a.5.5 0 0 1-.5-.5m-1.38 2.769a.5.5 0 0 1 .1.7L6.18 11.133a.5.5 0 0 1-.678.116l-.75-.5a.5.5 0 1 1 .555-.832l.356.237.964-1.285a.5.5 0 0 1 .7-.1M8.666 10a.5.5 0 0 1 .5-.5h1.667a.5.5 0 1 1 0 1H9.167a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChecklistBoxCheckListOutline.displayName = 'ChecklistBoxCheckListOutline';
const Memo = memo(SvgChecklistBoxCheckListOutline);
export default Memo;
