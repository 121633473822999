// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalendarDaysFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 2C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h8.334C13.179 14 14 13.18 14 12.167V3.833C14 2.821 13.18 2 12.167 2zm1.5 5.333a.667.667 0 1 0 0 1.334.667.667 0 0 0 0-1.334m0 2.667a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333m2 .667a.667.667 0 1 1 1.334 0 .667.667 0 0 1-1.334 0M8 7.333a.667.667 0 1 0 0 1.334.667.667 0 0 0 0-1.334M10 8a.667.667 0 1 1 1.333 0A.667.667 0 0 1 10 8M3.833 3A.833.833 0 0 0 3 3.833v.834h10v-.834A.833.833 0 0 0 12.167 3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCalendarDaysFill.displayName = 'CalendarDaysFill';
const Memo = memo(SvgCalendarDaysFill);
export default Memo;
