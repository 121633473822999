// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLinkDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.501 1.334c-1.012 0-1.833.82-1.833 1.833v9.667c0 1.012.82 1.833 1.833 1.833h4.984A3.66 3.66 0 0 1 8.335 12v-2a3.667 3.667 0 0 1 5-3.417V3.167c0-1.013-.821-1.833-1.834-1.833z" />
      <Path d="M10.335 10a1.667 1.667 0 1 1 3.333 0 .5.5 0 1 0 1 0 2.667 2.667 0 1 0-5.333 0 .5.5 0 0 0 1 0" />
      <Path d="M12.501 10.5a.5.5 0 1 0-1 0v1a.5.5 0 0 0 1 0z" />
      <Path d="M10.335 12a.5.5 0 1 0-1 0 2.667 2.667 0 0 0 5.333 0 .5.5 0 1 0-1 0 1.667 1.667 0 0 1-3.333 0" />
    </Svg>
  );
};
SvgLinkDocumentListPageFileFill.displayName = 'LinkDocumentListPageFileFill';
const Memo = memo(SvgLinkDocumentListPageFileFill);
export default Memo;
