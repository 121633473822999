// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBasket2ShoppingBagOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.684 2.036a.5.5 0 0 1 .278.65L4.903 5.333h6.19l-1.06-2.647a.5.5 0 0 1 .93-.372l1.207 3.02h1.202c.724 0 1.274.652 1.15 1.366l-1.006 5.78A1.83 1.83 0 0 1 11.71 14H4.286a1.83 1.83 0 0 1-1.806-1.52L1.475 6.7a1.167 1.167 0 0 1 1.149-1.367h1.202l1.208-3.019a.5.5 0 0 1 .65-.278m-3.06 4.297a.167.167 0 0 0-.164.196l1.005 5.78c.07.4.416.691.821.691h7.424a.83.83 0 0 0 .82-.69l1.006-5.781a.167.167 0 0 0-.164-.196z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBasket2ShoppingBagOutline.displayName = 'Basket2ShoppingBagOutline';
const Memo = memo(SvgBasket2ShoppingBagOutline);
export default Memo;
