// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRentKeyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.602 6.16c0-2.52 2.04-4.56 4.56-4.56s4.56 2.04 4.56 4.56-2.04 4.56-4.56 4.56c-.32 0-.68-.04-1-.12l-1.28 1.28c-.12.12-.24.16-.4.16h-.76v.76c0 .32-.24.56-.56.56h-.76v.76c0 .32-.24.56-.56.56h-2c-.68 0-1.24-.56-1.24-1.24v-1.72c0-.32.12-.64.36-.88l3.76-3.76c-.08-.24-.12-.56-.12-.92m4-1.32c0-.32.24-.56.56-.56 1.04 0 1.92.84 1.92 1.92 0 .32-.24.56-.56.56s-.56-.24-.56-.56a.76.76 0 0 0-.76-.76c-.36-.04-.6-.28-.6-.6"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRentKeyFill.displayName = 'RentKeyFill';
const Memo = memo(SvgRentKeyFill);
export default Memo;
