// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgQuestionmarkFaqHelpQuestionaireOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.334a5.667 5.667 0 1 0 0 11.333 5.667 5.667 0 0 0 0-11.333M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7.499 5.667a.5.5 0 0 0-.5.5.5.5 0 0 1-1 0 1.5 1.5 0 0 1 1.5-1.5h.805a1.694 1.694 0 0 1 .94 3.104l-.374.25a.83.83 0 0 0-.371.693v.12a.5.5 0 0 1-1 0v-.12c0-.613.306-1.186.816-1.526l.375-.25a.694.694 0 0 0-.386-1.271z"
        clipRule="evenodd"
      />
      <Path d="M7.332 10.667a.667.667 0 1 1 1.333 0 .667.667 0 0 1-1.333 0" />
    </Svg>
  );
};
SvgQuestionmarkFaqHelpQuestionaireOutline.displayName = 'QuestionmarkFaqHelpQuestionaireOutline';
const Memo = memo(SvgQuestionmarkFaqHelpQuestionaireOutline);
export default Memo;
