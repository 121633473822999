import type {TextStyle} from 'react-native';

import type {Typographies} from '@propertyguru/hive-theme';

type Weight = '600' | '400';
type Family = 'Poppins' | 'Inter';

interface Attribute {
  size: number;
  weight: Weight;
  family: Family;
}

/**
 * We map the weight base on MSDN https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
 */
const getFontWeight = (weight: Weight) => {
  switch (weight) {
    case '600':
      return 'SemiBold';
    default:
      return 'Regular';
  }
};

const createTextStyle = ({size, weight, family}: Attribute): TextStyle => {
  return {
    fontFamily: `${family}-${getFontWeight(weight)}`,
    fontSize: size,
    lineHeight: size * 1.4,
    letterSpacing: (size * 2.5) / -100,
  };
};

/**
 * @see https://propertyguru.atlassian.net/wiki/spaces/XD/pages/33681244729/Typography+WIP
 *
 * TODO: need to create a AccessibilityTypography ?
 */
export const BaseTypography: Typographies = {
  display: createTextStyle({size: 68, weight: '600', family: 'Poppins'}),

  headline: createTextStyle({size: 56, weight: '600', family: 'Poppins'}),
  title: {
    xl: createTextStyle({size: 48, weight: '600', family: 'Poppins'}),
    l: createTextStyle({size: 40, weight: '600', family: 'Poppins'}),
    m: createTextStyle({size: 34, weight: '600', family: 'Poppins'}),
    s: createTextStyle({size: 28, weight: '600', family: 'Poppins'}),
    xs: createTextStyle({size: 24, weight: '600', family: 'Poppins'}),
  },
  body: {
    l: createTextStyle({size: 24, weight: '400', family: 'Inter'}),
    m: createTextStyle({size: 18, weight: '400', family: 'Inter'}),
    s: createTextStyle({size: 16, weight: '400', family: 'Inter'}),
    xs: createTextStyle({size: 14, weight: '400', family: 'Inter'}),
  },
  label: {
    l: createTextStyle({size: 20, weight: '600', family: 'Poppins'}),
    m: createTextStyle({size: 16, weight: '600', family: 'Poppins'}),
    s: createTextStyle({size: 14, weight: '600', family: 'Poppins'}),
    xs: createTextStyle({size: 14, weight: '400', family: 'Poppins'}),
  },
  caption: {
    m: createTextStyle({size: 14, weight: '600', family: 'Poppins'}),
    s: createTextStyle({size: 12, weight: '600', family: 'Poppins'}),
    xs: createTextStyle({size: 12, weight: '400', family: 'Poppins'}),
  },
};
