// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble2AnnotationMessageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        stroke="#0D1011"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.17 2.5h9.666c.736 0 1.333.597 1.333 1.333v7c0 .737-.597 1.334-1.333 1.334H8.003L4.669 14v-1.833h-1.5a1.333 1.333 0 0 1-1.333-1.334v-7c0-.736.597-1.333 1.333-1.333Z"
      />
      <Path
        stroke="#0D1011"
        strokeLinecap="square"
        strokeWidth={0.5}
        d="M4.418 7.333a.583.583 0 1 0 1.167 0 .583.583 0 0 0-1.167 0Zm3 0a.583.583 0 1 0 1.167 0 .583.583 0 0 0-1.167 0Zm3 0a.583.583 0 1 0 1.167 0 .583.583 0 0 0-1.167 0Z"
      />
    </Svg>
  );
};
SvgBubble2AnnotationMessageOutline.displayName = 'Bubble2AnnotationMessageOutline';
const Memo = memo(SvgBubble2AnnotationMessageOutline);
export default Memo;
