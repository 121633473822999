// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNumberedListOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        stroke="#0D1011"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.833 11.167H13.5M7.833 4.833H13.5M4.167 6.5V3.167L2.5 4.333m.167 5.621S3.149 9.5 3.739 9.5s1.069.415 1.069.926c0 1.2-2.308 1.407-2.308 2.407H5"
      />
    </Svg>
  );
};
SvgNumberedListOutline.displayName = 'NumberedListOutline';
const Memo = memo(SvgNumberedListOutline);
export default Memo;
