// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSignDirectionRouteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.5 3.167C7.5 2.522 8.024 2 8.668 2h3.31c.595 0 1.154.29 1.497.776l1.06 1.5c.447.634.447 1.48 0 2.115l-1.06 1.5a1.83 1.83 0 0 1-1.497.776h-3.31q-.084 0-.166-.012V13.5a.5.5 0 0 1-1 0v-2.167H4.025c-.596 0-1.154-.29-1.498-.776l-1.059-1.5a1.83 1.83 0 0 1 0-2.114l1.06-1.5a1.83 1.83 0 0 1 1.497-.776h3.31q.084 0 .166.011zm1 4.333c0 .092.075.167.167.167h3.31c.27 0 .524-.132.68-.353l1.06-1.5a.83.83 0 0 0 0-.961l-1.06-1.5a.83.83 0 0 0-.68-.353h-3.31a.167.167 0 0 0-.166.167zm-1-1.667a.167.167 0 0 0-.166-.166H4.025a.83.83 0 0 0-.68.352l-1.06 1.5a.83.83 0 0 0 0 .962l1.06 1.5c.155.22.41.352.68.352h3.476z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSignDirectionRouteOutline.displayName = 'SignDirectionRouteOutline';
const Memo = memo(SvgSignDirectionRouteOutline);
export default Memo;
