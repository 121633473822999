// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRoadOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.786 1.352a.547.547 0 0 1 .37.666l-3.13 12.244a.52.52 0 0 1-.639.386.547.547 0 0 1-.37-.666l3.13-12.245a.52.52 0 0 1 .64-.385M10.208 1.352a.52.52 0 0 1 .638.385l3.13 12.245a.547.547 0 0 1-.37.666.52.52 0 0 1-.638-.386L9.838 2.018a.547.547 0 0 1 .37-.666M7.998 10.313c.288 0 .522.243.522.544v3.265c0 .3-.234.544-.522.544a.533.533 0 0 1-.521-.544v-3.265c0-.3.233-.544.521-.544M7.998 5.415c.288 0 .522.244.522.544v2.45c0 .3-.234.543-.522.543a.533.533 0 0 1-.521-.544V5.96c0-.3.233-.544.521-.544M7.998 1.334c.288 0 .522.243.522.544V3.51c0 .3-.234.545-.522.545a.533.533 0 0 1-.521-.545V1.878c0-.3.233-.544.521-.544"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRoadOff.displayName = 'RoadOff';
const Memo = memo(SvgRoadOff);
export default Memo;
