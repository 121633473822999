// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMicrophoneMicSoundOffPodcastOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.365 2.625a3.333 3.333 0 0 1 5.968 2.042v3a.5.5 0 1 1-1 0v-3a2.333 2.333 0 0 0-4.178-1.429.5.5 0 0 1-.79-.613m-3.719-.979a.5.5 0 0 1 .708 0l9.01 9.01c.312-.351.57-.752.762-1.19a.5.5 0 1 1 .916.4 5.5 5.5 0 0 1-.97 1.498l2.282 2.282a.5.5 0 0 1-.708.708l-2.312-2.313A5.47 5.47 0 0 1 8.5 13.144v1.023a.5.5 0 0 1-1 0v-1.023a5.5 5.5 0 0 1-4.542-3.277.5.5 0 0 1 .916-.4 4.5 4.5 0 0 0 6.745 1.86l-.84-.84a3.333 3.333 0 0 1-5.112-2.82V5.374l-3.02-3.02a.5.5 0 0 1 0-.708m4.02 4.728v1.293a2.333 2.333 0 0 0 3.38 2.086z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMicrophoneMicSoundOffPodcastOutline.displayName = 'MicrophoneMicSoundOffPodcastOutline';
const Memo = memo(SvgMicrophoneMicSoundOffPodcastOutline);
export default Memo;
