// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBitcoinOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.667-4a.5.5 0 0 1 .5.5V5h.416a1.75 1.75 0 0 1 1.225 3 1.75 1.75 0 0 1-1.225 3H8.5v.5a.5.5 0 0 1-1 0V11h-2a.5.5 0 0 1 0-1h.5V6h-.5a.5.5 0 1 1 0-1h2v-.5a.5.5 0 0 1 .5-.5m-1 2v1.5h1.916a.75.75 0 1 0 0-1.5zm1.916 2.5H7V10h1.916a.75.75 0 1 0 0-1.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBitcoinOutline.displayName = 'BitcoinOutline';
const Memo = memo(SvgBitcoinOutline);
export default Memo;
