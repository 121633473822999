// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSearchPageFindOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.004 3.163c0-1.012.82-1.833 1.833-1.833h7.664c1.012 0 1.833.82 1.833 1.833v5.17a.5.5 0 0 1-1 0v-5.17a.833.833 0 0 0-.833-.833H3.837a.833.833 0 0 0-.833.833v9.668c0 .46.373.833.833.833h4.164a.5.5 0 1 1 0 1H3.837a1.833 1.833 0 0 1-1.833-1.833zM4.667 4.5a.5.5 0 0 1 .5-.5h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5h2.334a.5.5 0 1 1 0 1H5.167a.5.5 0 0 1-.5-.5m0 2.666a.5.5 0 0 1 .5-.5h1a.5.5 0 1 1 0 1h-1a.5.5 0 0 1-.5-.5m7.894 1.268a1.5 1.5 0 1 0-2.121 2.121 1.5 1.5 0 0 0 2.121-2.12m-2.828-.707a2.5 2.5 0 0 1 3.854 3.147l.934.934a.5.5 0 1 1-.707.707l-.934-.934a2.5 2.5 0 0 1-3.147-3.854"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSearchPageFindOutline.displayName = 'SearchPageFindOutline';
const Memo = memo(SvgSearchPageFindOutline);
export default Memo;
