// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCornerUnitFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.437 2.414A.426.426 0 0 0 8 2a.426.426 0 0 0-.437.414v1.802L3.02 6.169a.409.409 0 0 0-.27.382v4.71l-1.508.714a.404.404 0 0 0-.196.555.45.45 0 0 0 .587.185l1.564-.74 4.621 1.988a.46.46 0 0 0 .363 0l4.621-1.987 1.565.74a.45.45 0 0 0 .586-.185.404.404 0 0 0-.195-.556l-1.509-.713v-4.71a.41.41 0 0 0-.275-.385l-4.537-1.95zM8 4.937l3.755 1.615L8 8.166 4.245 6.552z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCornerUnitFill.displayName = 'CornerUnitFill';
const Memo = memo(SvgCornerUnitFill);
export default Memo;
