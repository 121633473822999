// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHourglassTimeWatchClockFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.333 2.333H2.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-.833v2.27c0 .626-.319 1.208-.845 1.545L8.928 8l2.894 1.852c.526.337.845.92.845 1.544v2.27h.833a.5.5 0 1 1 0 1h-11a.5.5 0 0 1 0-1h.833v-2.27c0-.625.319-1.207.845-1.544L7.072 8 4.178 6.148a1.83 1.83 0 0 1-.845-1.544zm1 0v2.27q0 .033.003.064h7.328l.003-.063v-2.27zM11.667 12v-.604a.83.83 0 0 0-.384-.702L8 8.594l-3.283 2.1a.83.83 0 0 0-.384.702V12z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHourglassTimeWatchClockFill.displayName = 'HourglassTimeWatchClockFill';
const Memo = memo(SvgHourglassTimeWatchClockFill);
export default Memo;
