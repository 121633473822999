// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTargetArrowGoalAimFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.394 1.46a.667.667 0 0 0-1.122-.263L9.5 3.071C9.178 3.41 9 3.86 9 4.33v1.962L7.645 7.646a.5.5 0 0 0 .707.707L9.706 7h1.962c.469 0 .92-.18 1.26-.502L14.8 4.726a.667.667 0 0 0-.262-1.121L12.9 3.1z" />
      <Path d="M2.332 8a5.667 5.667 0 0 1 5.973-5.659.5.5 0 1 0 .054-.998q-.18-.01-.36-.01a6.667 6.667 0 1 0 6.657 6.307.5.5 0 1 0-.999.053q.008.153.008.307A5.667 5.667 0 0 1 2.332 8" />
      <Path d="M7.428 5.038a.5.5 0 0 0-.192-.982 4.01 4.01 0 1 0 4.706 4.706.5.5 0 0 0-.982-.192 3.01 3.01 0 1 1-3.532-3.532" />
    </Svg>
  );
};
SvgTargetArrowGoalAimFill.displayName = 'TargetArrowGoalAimFill';
const Memo = memo(SvgTargetArrowGoalAimFill);
export default Memo;
