// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddSlideAddPageAddCopyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834v.907h.833c1.013 0 1.833.82 1.833 1.833v6.926c0 1.013-.82 1.834-1.833 1.834h-7a1.833 1.833 0 0 1-1.833-1.834V12h-.834a1.833 1.833 0 0 1-1.833-1.833zm3.667 9.666c0 .46.373.834.833.834h7c.46 0 .833-.373.833-.834V5.907a.833.833 0 0 0-.833-.833h-7a.833.833 0 0 0-.833.833zm6-8.759H5.832c-1.012 0-1.833.82-1.833 1.833V11h-.834a.833.833 0 0 1-.833-.833v-7c0-.46.373-.834.833-.834h7c.46 0 .834.373.834.834zm-1.63 2.593a.5.5 0 0 1 .5.5V8.87h1.63a.5.5 0 0 1 0 1h-1.63v1.63a.5.5 0 1 1-1 0V9.87H7.165a.5.5 0 0 1 0-1H8.87V7.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAddSlideAddPageAddCopyOutline.displayName = 'AddSlideAddPageAddCopyOutline';
const Memo = memo(SvgAddSlideAddPageAddCopyOutline);
export default Memo;
