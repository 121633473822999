// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBusinessShopStoreOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 3A.833.833 0 0 0 3 3.833V4.8c0 .486.394.88.875.88a.88.88 0 0 0 .875-.88V3zm0-1C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h8.334C13.179 14 14 13.18 14 12.167V3.833C14 2.821 13.18 2 12.167 2zM5.75 3v1.8c0 .486.394.88.875.88A.88.88 0 0 0 7.5 4.8V3zM8.5 3v1.8c0 .486.394.88.875.88a.88.88 0 0 0 .875-.88V3zm2.75 0v1.8c0 .486.394.88.875.88A.88.88 0 0 0 13 4.8v-.967A.833.833 0 0 0 12.167 3zM13 6.463a1.866 1.866 0 0 1-2.25-.386 1.866 1.866 0 0 1-2.75 0 1.866 1.866 0 0 1-2.75 0A1.866 1.866 0 0 1 3 6.463v5.704c0 .46.373.833.833.833h1.5v-2.5c0-1.013.821-1.833 1.834-1.833h1.666c1.013 0 1.834.82 1.834 1.833V13h1.5c.46 0 .833-.373.833-.833zM9.667 13v-2.5a.833.833 0 0 0-.834-.833H7.167a.833.833 0 0 0-.834.833V13z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBusinessShopStoreOutline.displayName = 'BusinessShopStoreOutline';
const Memo = memo(SvgBusinessShopStoreOutline);
export default Memo;
