// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAttachmentAttachDocumentAttachementListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.332 2.833a.5.5 0 0 1 1 0v3a1.5 1.5 0 1 1-3 0V3.167a.5.5 0 1 0-1 0v2.666a2.5 2.5 0 0 0 5 0v-3a1.5 1.5 0 1 0-3 0v3a.5.5 0 0 0 1 0z" />
      <Path d="M3.832 9.333c-.409 0-.802-.07-1.167-.199v3.7c0 1.012.821 1.833 1.834 1.833h7c1.012 0 1.833-.821 1.833-1.834V3.167c0-1.013-.82-1.834-1.833-1.834H6.832c.314.418.5.937.5 1.5v3a3.5 3.5 0 0 1-3.5 3.5" />
    </Svg>
  );
};
SvgAttachmentAttachDocumentAttachementListPageFileFill.displayName =
  'AttachmentAttachDocumentAttachementListPageFileFill';
const Memo = memo(SvgAttachmentAttachDocumentAttachementListPageFileFill);
export default Memo;
