// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgServerRoomOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.003 2.5c-.614 0-1.184.184-1.586.486-.4.3-.581.67-.581 1.014s.181.715.581 1.014c.402.302.972.486 1.586.486h8c.614 0 1.183-.184 1.585-.486l.263.35-.263-.35c.4-.3.581-.67.581-1.014s-.181-.715-.58-1.014c-.403-.302-.973-.486-1.586-.486zM1.817 5.814q.134.1.278.186-.145.086-.278.186C1.217 6.636.836 7.283.836 8s.38 1.364.981 1.814q.134.1.278.186-.145.086-.278.186c-.6.45-.981 1.097-.981 1.814s.38 1.364.981 1.814c.598.449 1.385.686 2.186.686h8c.8 0 1.587-.237 2.185-.686.6-.45.981-1.097.981-1.814s-.38-1.364-.98-1.814a3 3 0 0 0-.28-.186q.145-.086.28-.186c.6-.45.98-1.097.98-1.814s-.38-1.364-.98-1.814a3 3 0 0 0-.28-.186q.145-.086.28-.186c.6-.45.98-1.097.98-1.814s-.38-1.364-.98-1.814c-.599-.449-1.386-.686-2.186-.686h-8c-.801 0-1.588.237-2.186.686-.6.45-.981 1.097-.981 1.814s.38 1.364.981 1.814m.6 1.172c.402-.302.972-.486 1.586-.486h8c.614 0 1.183.184 1.585.486.4.3.581.67.581 1.014s-.181.715-.58 1.014c-.403.302-.973.486-1.586.486h-8c-.614 0-1.184-.184-1.586-.486-.4-.3-.581-.67-.581-1.014s.181-.715.581-1.014M4.003 10.5c-.614 0-1.184.184-1.586.486-.4.3-.581.67-.581 1.014s.181.715.581 1.014c.402.302.972.486 1.586.486h8c.614 0 1.183-.184 1.585-.486.4-.3.581-.67.581-1.014s-.181-.714-.58-1.014c-.403-.302-.973-.486-1.586-.486zm5.333-7a.5.5 0 0 0 0 1h2.667a.5.5 0 0 0 0-1zm-.5 4.5a.5.5 0 0 1 .5-.5h2.667a.5.5 0 0 1 0 1H9.336a.5.5 0 0 1-.5-.5m.5 3.5a.5.5 0 0 0 0 1h2.667a.5.5 0 0 0 0-1zM2.46 4.137a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0m0 4a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M3.21 12.333a.25.25 0 1 0 0-.5v-.5a.75.75 0 1 0 0 1.5z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M3.21 11.333a.75.75 0 0 1 0 1.5v-.5a.25.25 0 1 1 0-.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgServerRoomOutline.displayName = 'ServerRoomOutline';
const Memo = memo(SvgServerRoomOutline);
export default Memo;
