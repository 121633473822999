// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFloorPlanOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.069 1.848V7.03h6.073V1.848zM3.02 1.769v5.262H1.766A.97.97 0 0 0 .796 8v6.231a.97.97 0 0 0 .97.969h6.676a.97.97 0 0 0 .97-.969v-3.037h4.816a.97.97 0 0 0 .969-.969v-5.34a.97.97 0 0 0-.97-.97H11.19V1.77A.97.97 0 0 0 10.221.8H3.99a.97.97 0 0 0-.97.969M1.845 12.372v1.78h2.067v-1.78zm2.067 0H1.845v-.733h2.067zm1.048.524v1.256h3.403V8.08H1.845v2.512h2.591c.29 0 .524.235.524.524zm6.23-7.933V7.11a.97.97 0 0 1-.969.969h-.81v2.067h4.738V4.963z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFloorPlanOutline.displayName = 'FloorPlanOutline';
const Memo = memo(SvgFloorPlanOutline);
export default Memo;
