// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChecklistListOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.131 3.098a.5.5 0 0 1 .104.7l-2.222 3a.5.5 0 0 1-.682.116l-1.11-.75a.5.5 0 1 1 .559-.828l.714.482 1.938-2.616a.5.5 0 0 1 .699-.104m1.202 2.069a.5.5 0 0 1 .5-.5H13.5a.5.5 0 0 1 0 1H7.833a.5.5 0 0 1-.5-.5M6.131 9.098a.5.5 0 0 1 .104.7l-2.222 3a.5.5 0 0 1-.682.117l-1.11-.75a.5.5 0 0 1 .559-.83l.714.483 1.938-2.616a.5.5 0 0 1 .699-.104m1.202 1.735a.5.5 0 0 1 .5-.5H13.5a.5.5 0 1 1 0 1H7.833a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChecklistListOutline.displayName = 'ChecklistListOutline';
const Memo = memo(SvgChecklistListOutline);
export default Memo;
