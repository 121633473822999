// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowPathUpFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.907 5.965a.833.833 0 0 0 .639 1.368h1.786v5.5c0 1.013.82 1.834 1.833 1.834h1.667c1.012 0 1.833-.821 1.833-1.834v-5.5h1.786a.833.833 0 0 0 .639-1.368L9.148 1.26a1.5 1.5 0 0 0-2.3 0z" />
    </Svg>
  );
};
SvgArrowPathUpFill.displayName = 'ArrowPathUpFill';
const Memo = memo(SvgArrowPathUpFill);
export default Memo;
