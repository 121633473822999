// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBatteryLowPowerFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.501 3.334c-1.012 0-1.833.82-1.833 1.833v5.667c0 1.012.82 1.833 1.833 1.833h9c1.013 0 1.834-.821 1.834-1.833v-.167h.833c.644 0 1.167-.522 1.167-1.167v-3c0-.644-.523-1.166-1.167-1.166h-.833v-.167c0-1.013-.821-1.833-1.834-1.833zm10.834 3v3.333h.833a.167.167 0 0 0 .167-.167v-3a.167.167 0 0 0-.167-.167zM4 6a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBatteryLowPowerFill.displayName = 'BatteryLowPowerFill';
const Memo = memo(SvgBatteryLowPowerFill);
export default Memo;
