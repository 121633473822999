// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVisitPageOpenAppOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.333 6a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M6.667 6a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M9 6a.333.333 0 1 1 0-.667A.333.333 0 0 1 9 6" />
      <Path
        fillRule="evenodd"
        d="M4.335 6.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m2.333 0a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m2.333 0a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333M4.335 6a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667m2.333 0a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667m2-.333a.333.333 0 1 0 .667 0 .333.333 0 0 0-.667 0"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v3.667a.5.5 0 0 1-1 0V4.5a.833.833 0 0 0-.833-.833H3.165a.833.833 0 0 0-.833.833v7.667c0 .46.373.833.833.833h5a.5.5 0 0 1 0 1h-5a1.833 1.833 0 0 1-1.833-1.833zm7.646 4.48a.5.5 0 0 1 .501-.124l4.667 1.436a.5.5 0 0 1 .076.925l-2.005 1.002-1.002 2.005a.5.5 0 0 1-.925-.077L8.854 9.481a.5.5 0 0 1 .124-.5m1.11 1.11.79 2.57.52-1.037a.5.5 0 0 1 .223-.224l1.038-.519z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVisitPageOpenAppOutline.displayName = 'VisitPageOpenAppOutline';
const Memo = memo(SvgVisitPageOpenAppOutline);
export default Memo;
