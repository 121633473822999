// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSpeakerFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.322 1C3.592 1 3 1.65 3 2.45v11.1c0 .8.592 1.45 1.322 1.45h7.356c.73 0 1.322-.65 1.322-1.45V2.45c0-.8-.592-1.45-1.322-1.45zM9 4.001a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3.106 6a2.106 2.106 0 1 1 4.212 0 2.106 2.106 0 0 1-4.212 0m2.106-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6m-.067 3a.067.067 0 1 1 .134 0 .067.067 0 0 1-.134 0m.067-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSpeakerFill.displayName = 'SpeakerFill';
const Memo = memo(SvgSpeakerFill);
export default Memo;
