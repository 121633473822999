// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUndoBackTopUpwardOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.685 2.98a.5.5 0 0 1 0 .707L2.872 5.5H11.5a3.167 3.167 0 0 1 3.166 3.167V9.5a3.167 3.167 0 0 1-3.166 3.167H7.832a.5.5 0 1 1 0-1h3.667c1.196 0 2.166-.97 2.166-2.167v-.833c0-1.197-.97-2.167-2.166-2.167H2.872l1.813 1.813a.5.5 0 1 1-.707.707L1.783 6.825a1.167 1.167 0 0 1 0-1.65l.354.354-.354-.354L3.978 2.98a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUndoBackTopUpwardOutline.displayName = 'UndoBackTopUpwardOutline';
const Memo = memo(SvgUndoBackTopUpwardOutline);
export default Memo;
