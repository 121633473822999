// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPerformanceSpeedScaleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.999 1.333a.5.5 0 0 0-.5.5V3.5a.5.5 0 1 0 1 0V2.355a5.667 5.667 0 1 1-5.611 3.194.5.5 0 0 0-.902-.432A6.667 6.667 0 1 0 8 1.333" />
      <Path d="M3.312 3.313a.5.5 0 0 1 .707 0L6.74 6.034a2.333 2.333 0 1 1-.707.707L3.312 4.02a.5.5 0 0 1 0-.707" />
    </Svg>
  );
};
SvgPerformanceSpeedScaleFill.displayName = 'PerformanceSpeedScaleFill';
const Memo = memo(SvgPerformanceSpeedScaleFill);
export default Memo;
