// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path, Rect} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgInfoCircleTooltipOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.334a5.667 5.667 0 1 0 0 11.333 5.667 5.667 0 0 0 0-11.333M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m5.333-.667a.5.5 0 0 1 .5-.5H8a.5.5 0 0 1 .5.5v3.5a.5.5 0 0 1-1 0v-3h-.334a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <Rect
        width={0.917}
        height={0.917}
        x={7.543}
        y={4.875}
        fill="#0D1011"
        stroke="#0D1011"
        strokeWidth={0.25}
        rx={0.458}
      />
    </Svg>
  );
};
SvgInfoCircleTooltipOutline.displayName = 'InfoCircleTooltipOutline';
const Memo = memo(SvgInfoCircleTooltipOutline);
export default Memo;
