// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAttachmentAttachDocumentAttachementListPageFileOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.832 2.333a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3a1.5 1.5 0 1 1 3 0v3a2.5 2.5 0 0 1-5 0V3.167a.5.5 0 1 1 1 0v2.666a1.5 1.5 0 1 0 3 0v-3a.5.5 0 0 0-.5-.5m2.5-.5a.5.5 0 0 1 .5-.5h3.667c1.012 0 1.833.821 1.833 1.834v9.666c0 1.013-.82 1.834-1.833 1.834h-7a1.833 1.833 0 0 1-1.834-1.834v-3a.5.5 0 0 1 1 0v3c0 .46.373.834.834.834h7c.46 0 .833-.373.833-.834V3.167a.833.833 0 0 0-.833-.834H7.832a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAttachmentAttachDocumentAttachementListPageFileOutline.displayName =
  'AttachmentAttachDocumentAttachementListPageFileOutline';
const Memo = memo(SvgAttachmentAttachDocumentAttachementListPageFileOutline);
export default Memo;
