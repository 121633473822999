// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleCircleUserPersonAvatar2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 0 0-4.15 9.527C4.83 10.716 6.278 10 8 10c1.721 0 3.168.716 4.149 1.86a5.667 5.667 0 0 0-4.149-9.527m3.405 10.197C10.61 11.592 9.433 11 8 11s-2.612.592-3.406 1.53a5.64 5.64 0 0 0 3.406 1.137 5.64 5.64 0 0 0 3.405-1.137M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.667-3a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333M5.332 6.667a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPeopleCircleUserPersonAvatar2Outline.displayName = 'PeopleCircleUserPersonAvatar2Outline';
const Memo = memo(SvgPeopleCircleUserPersonAvatar2Outline);
export default Memo;
