// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBowlingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.405 7.844a4.9 4.9 0 0 0-1.658-1.752 4.8 4.8 0 0 0-2.3-.724 4.9 4.9 0 0 0-2.4.496l-1.121.684a7 7 0 0 0-.672-.917V2.733c0-.467-.18-.91-.513-1.238-.66-.66-1.816-.66-2.476 0a1.74 1.74 0 0 0-.514 1.238v2.903A7.13 7.13 0 0 0 1 10.193a7.13 7.13 0 0 0 1.559 4.31A1.282 1.282 0 0 0 3.587 15h1.827c.198.001.397-.046.572-.128.18-.087.338-.21.455-.362.175-.222.333-.455.485-.695l1.056.631c.03.03.065.047.1.07a4.8 4.8 0 0 0 2.107.485c.082 0 .17 0 .251-.006a4.8 4.8 0 0 0 2.3-.724A4.82 4.82 0 0 0 15 10.18c0-.818-.21-1.624-.601-2.337zM3.627 5.362v-.876H5.38v.876zm.257-3.248a.875.875 0 0 1 1.495.62v.876H3.627v-.877c0-.233.094-.455.257-.619M5.758 13.96a.415.415 0 0 1-.339.163H3.593a.45.45 0 0 1-.344-.169 6.23 6.23 0 0 1-1.366-3.767A6.28 6.28 0 0 1 3.4 6.232h2.218a6.26 6.26 0 0 1 1.518 3.937 6.25 6.25 0 0 1-1.372 3.785zm7.882-1.869a3.9 3.9 0 0 1-1.36 1.431 3.923 3.923 0 0 1-3.736.24c-.007 0-.019-.012-.024-.018l-1.174-.707a7.1 7.1 0 0 0 .66-2.868 7.1 7.1 0 0 0-.666-2.85l1.127-.684a3.95 3.95 0 0 1 1.932-.391c.666.035 1.314.24 1.88.596a3.95 3.95 0 0 1 1.857 3.341c0 .666-.17 1.326-.496 1.91" />
      <Path d="M10.404 10.62a.654.654 0 1 0 0-1.309.654.654 0 0 0 0 1.308M10.857 8.429a.654.654 0 1 0 0-1.308.654.654 0 0 0 0 1.308M12.607 10.181a.654.654 0 1 0 0-1.308.654.654 0 0 0 0 1.308" />
    </Svg>
  );
};
SvgBowlingOutline.displayName = 'BowlingOutline';
const Memo = memo(SvgBowlingOutline);
export default Memo;
