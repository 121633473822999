// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgInstagramBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.007 2.534c1.782 0 1.993.008 2.694.04.651.028 1.003.137 1.237.228.31.12.535.266.767.498.234.234.377.456.497.766.091.234.2.588.23 1.237.03.703.039.914.039 2.693 0 1.782-.008 1.993-.04 2.693-.028.652-.137 1.003-.229 1.238-.12.31-.265.534-.497.765a2.05 2.05 0 0 1-.767.498c-.234.091-.588.2-1.237.229-.704.031-.915.04-2.694.04-1.783 0-1.994-.009-2.695-.04-.651-.029-1.003-.138-1.237-.23a2.1 2.1 0 0 1-.767-.497 2.05 2.05 0 0 1-.497-.765c-.091-.235-.2-.59-.23-1.238-.03-.703-.039-.914-.039-2.693 0-1.781.008-1.992.04-2.693.028-.651.138-1.003.229-1.237.12-.31.266-.534.497-.766.235-.235.456-.378.767-.498.234-.09.588-.2 1.237-.229.701-.031.912-.039 2.695-.039m0-1.2c-1.811 0-2.038.007-2.75.038-.708.032-1.196.146-1.618.31-.44.172-.813.399-1.183.771-.372.37-.599.743-.77 1.18-.165.425-.28.91-.311 1.618-.031.713-.04.94-.04 2.75s.009 2.037.04 2.748c.031.709.146 1.196.31 1.618.172.44.399.812.771 1.182.37.37.743.6 1.18.769.425.164.91.278 1.619.31.711.03.938.039 2.749.039 1.81 0 2.038-.008 2.749-.04.709-.03 1.196-.145 1.618-.31.438-.169.81-.398 1.18-.768s.6-.742.77-1.18c.163-.424.278-.909.31-1.617.03-.711.038-.938.038-2.748s-.008-2.037-.039-2.748c-.031-.708-.146-1.196-.31-1.618a3.1 3.1 0 0 0-.763-1.185c-.37-.37-.743-.599-1.18-.768-.426-.164-.91-.279-1.619-.31-.714-.034-.94-.042-2.751-.042" />
      <Path d="M8.005 4.576a3.427 3.427 0 0 0-3.427 3.425 3.427 3.427 0 0 0 6.853 0 3.427 3.427 0 0 0-3.426-3.425m0 5.647a2.223 2.223 0 1 1 0-4.445 2.223 2.223 0 0 1 0 4.445M12.366 4.44a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0" />
    </Svg>
  );
};
SvgInstagramBw.displayName = 'InstagramBw';
const Memo = memo(SvgInstagramBw);
export default Memo;
