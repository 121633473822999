// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgColumnFreeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.67 10.572V2.583c0-.333-.129-.65-.367-.883a1.27 1.27 0 0 0-.884-.367H5.43a1.3 1.3 0 0 0-.5.106c-.01 0-.016.01-.022.01a1.2 1.2 0 0 0-.361.245l-2.844 2.85c-.234.239-.367.55-.367.883v7.99a1.249 1.249 0 0 0 1.25 1.25h7.989c.328 0 .65-.134.883-.367l2.845-2.845c.1-.1.177-.222.238-.35.006-.01.017-.022.023-.039.066-.155.105-.322.105-.494m-.956-8.283a.42.42 0 0 1 .122.294V10.5H5.503V2.166h7.916c.111 0 .217.045.295.123M2.29 5.133 4.67 2.755v7.99l-2.5 2.5V5.426c0-.11.045-.216.122-.294m8.578 8.578a.42.42 0 0 1-.294.122H2.758l2.5-2.5h7.989z" />
    </Svg>
  );
};
SvgColumnFreeOutline.displayName = 'ColumnFreeOutline';
const Memo = memo(SvgColumnFreeOutline);
export default Memo;
