// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRulerFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.37 1.756a1.833 1.833 0 0 1 2.593 0l2.28 2.281a1.833 1.833 0 0 1 0 2.593L6.63 14.244a1.833 1.833 0 0 1-2.592 0l-2.281-2.28a1.833 1.833 0 0 1 0-2.594l1.12-1.12 1.437 1.437a.5.5 0 0 0 .707-.707L3.583 7.543 5.21 5.917 7.313 8.02a.5.5 0 0 0 .707-.707L5.917 5.21l1.626-1.627L8.98 5.02a.5.5 0 0 0 .707-.707L8.25 2.876z" />
    </Svg>
  );
};
SvgRulerFill.displayName = 'RulerFill';
const Memo = memo(SvgRulerFill);
export default Memo;
