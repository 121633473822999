// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNewProjectDeveloperOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.823 2.283 4.25 4.639h6.579zm5.256 3.293H6.818v8.154h1.598a.468.468 0 1 1 0 .937H1.804a.468.468 0 0 1 0-.937H3.4V5.576H1.804a.468.468 0 1 1 0-.937h1.059L6.226 1.56l.005-.005a.88.88 0 0 1 1.023-.106l.005.003 5.417 3.187h1.525a.468.468 0 1 1 0 .937h-1.186v1.356a.36.36 0 0 0 .215.328 1.708 1.708 0 1 1-2.391 1.566.468.468 0 1 1 .936 0 .771.771 0 1 0 1.08-.707 1.3 1.3 0 0 1-.777-1.187zm-7.742 7.952v.202h1.544v-2.261zm1.544-3.62-1.544 2.059V7.339l1.544 2.059zm-1.544-4.13 1.544 2.059V5.576H4.337z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNewProjectDeveloperOff.displayName = 'NewProjectDeveloperOff';
const Memo = memo(SvgNewProjectDeveloperOff);
export default Memo;
