// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTextIndentRightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.67 6.52v2.96c0 .557-.61.882-1.049.558l-2.007-1.48a.7.7 0 0 1 0-1.116l2.007-1.48c.44-.324 1.048 0 1.048.558" />
      <Path
        fillRule="evenodd"
        d="M10.003 12.167a.5.5 0 0 1-.5.5H1.836a.5.5 0 0 1 0-1h7.667a.5.5 0 0 1 .5.5m0-4.167a.5.5 0 0 1-.5.5H1.836a.5.5 0 0 1 0-1h7.667a.5.5 0 0 1 .5.5m0-4.167a.5.5 0 0 1-.5.5H1.836a.5.5 0 1 1 0-1h7.667a.5.5 0 0 1 .5.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTextIndentRightOutline.displayName = 'TextIndentRightOutline';
const Memo = memo(SvgTextIndentRightOutline);
export default Memo;
