// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCourtOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.053 2.378a.17.17 0 0 0-.106 0L3.114 3.989A.17.17 0 0 0 3 4.147V5.5c0 .092.075.167.167.167h9.666A.167.167 0 0 0 13 5.5V4.147a.17.17 0 0 0-.114-.158zM12.5 6.667h.333C13.478 6.667 14 6.144 14 5.5V4.147c0-.502-.321-.948-.798-1.107L8.37 1.43c-.24-.08-.498-.08-.738 0L2.798 3.04A1.17 1.17 0 0 0 2 4.147V5.5c0 .644.522 1.167 1.167 1.167H3.5v5.666h1V6.667h1.667v5.666h1V6.667h1.666v5.666h1V6.667H11.5v5.666h1zM2 13h12v1H2z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCourtOutline.displayName = 'CourtOutline';
const Memo = memo(SvgCourtOutline);
export default Memo;
