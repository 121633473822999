// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFastForward5SFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.64.05a.52.52 0 0 0-.308.474v.842a6.668 6.668 0 1 0 5.529 2.072.5.5 0 0 0-.73.685 5.667 5.667 0 1 1-4.8-1.75v.77c.001.203.12.388.308.474a.54.54 0 0 0 .568-.072l1.6-1.31a.52.52 0 0 0 0-.804L8.207.12A.54.54 0 0 0 7.639.05" />
      <Path d="M6.29 9.508c0 .548.688 1.096 1.659 1.096 1.11 0 1.858-.698 1.858-1.74 0-.946-.641-1.59-1.572-1.59-.419 0-.816.157-.985.386H7.2l.1-1.264h1.852c.29 0 .454-.14.454-.39s-.168-.397-.454-.397H7.125c-.394 0-.598.164-.623.505l-.133 1.84c-.021.33.18.54.473.54.133 0 .236-.042.448-.221.211-.165.454-.254.694-.254.516 0 .885.358.885.88 0 .541-.383.917-.924.917-.365 0-.652-.168-.877-.505-.118-.168-.233-.233-.383-.233-.24 0-.394.19-.394.43" />
    </Svg>
  );
};
SvgFastForward5SFill.displayName = 'FastForward5SFill';
const Memo = memo(SvgFastForward5SFill);
export default Memo;
