// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFlag2PriorityFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.19 10.188a8 8 0 0 0-.522.193v3.786a.5.5 0 0 1-1 0V2.933c0-.44.252-.867.693-1.044.525-.21 1.537-.556 2.569-.556.806 0 1.588.388 2.241.712l.052.025c.721.357 1.302.634 1.85.634.56 0 1.138-.13 1.613-.277.747-.233 1.649.286 1.649 1.164v6.05c0 .44-.252.867-.694 1.044-.524.21-1.536.556-2.568.556-.806 0-1.589-.388-2.241-.712l-.053-.025c-.72-.357-1.302-.634-1.85-.634-.611 0-1.245.154-1.739.318" />
    </Svg>
  );
};
SvgFlag2PriorityFill.displayName = 'Flag2PriorityFill';
const Memo = memo(SvgFlag2PriorityFill);
export default Memo;
