// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMoveOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.176 1.618a1.167 1.167 0 0 1 1.65 0l1.529 1.529a.5.5 0 0 1-.707.707L8.5 2.707V7.5h4.793l-1.146-1.146a.5.5 0 0 1 .707-.707l1.528 1.528a1.167 1.167 0 0 1 0 1.65l-1.528 1.529a.5.5 0 0 1-.707-.707L13.294 8.5H8.501v4.793l1.147-1.146a.5.5 0 1 1 .707.707l-1.529 1.528a1.167 1.167 0 0 1-1.65 0l-1.528-1.528a.5.5 0 0 1 .707-.707L7.5 13.293V8.5H2.708l1.147 1.147a.5.5 0 0 1-.707.707L1.619 8.825a1.167 1.167 0 0 1 0-1.65l.352.352-.352-.352 1.529-1.528a.5.5 0 1 1 .707.707L2.708 7.5h4.793V2.707L6.355 3.854a.5.5 0 1 1-.707-.707zl.352.352.002.002m-.354-.354.352.352z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMoveOutline.displayName = 'MoveOutline';
const Memo = memo(SvgMoveOutline);
export default Memo;
