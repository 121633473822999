// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBranchesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.001 2.333a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667M2.668 3.667a2.333 2.333 0 1 1 2.833 2.28V7.7c.25-.128.534-.2.834-.2h3.333c.46 0 .833-.373.833-.833v-.72a2.334 2.334 0 1 1 1 0v.72c0 1.012-.82 1.833-1.833 1.833H6.335a.833.833 0 0 0-.834.833v.72a2.334 2.334 0 1 1-1 0V5.947a2.334 2.334 0 0 1-1.833-2.28m8.333-1.334a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667m-6 8.667a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBranchesOutline.displayName = 'BranchesOutline';
const Memo = memo(SvgBranchesOutline);
export default Memo;
