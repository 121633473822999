// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChopsticksOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.135 1.057c.24.128.33.426.203.666L9.902 6.29a24 24 0 0 1 1.036.105l3.21-3.501a.492.492 0 1 1 .725.664L12.1 6.584c.643.13 1.25.305 1.749.537.303.14.575.307.779.507.205.201.373.473.373.807L15 8.488c0 3.629-3.166 6.513-7 6.513s-7-2.885-7-6.513v-.054c0-.466.322-.805.646-1.029.34-.235.801-.43 1.33-.59 1.066-.321 2.52-.535 4.139-.593l1.595.213-1.595-.213a24 24 0 0 1 1.705.007l2.65-4.97a.49.49 0 0 1 .665-.202m-1.472 5.639-.913-.122zm-.55.6q-.365-.033-.718-.055l-.529.99a.492.492 0 0 1-.868-.462l.305-.571c-.45-.009-.845-.004-1.152.007-1.565.056-2.93.262-3.89.552-.483.146-.835.305-1.056.457-.178.124-.213.2-.22.22.007.02.048.113.28.26.27.17.693.342 1.26.493 1.126.3 2.708.492 4.476.492s3.35-.191 4.476-.492c.566-.15.99-.322 1.259-.492.237-.15.275-.244.28-.261a.3.3 0 0 0-.078-.105c-.092-.09-.254-.2-.502-.315h-.002c-.551-.258-1.302-.443-2.12-.572l-1.215 1.324a.492.492 0 0 1-.725-.664zm3.905 1.144v-.002zm-1.288 1.698c-1.23.328-2.902.525-4.73.525-1.827 0-3.499-.198-4.729-.526a7 7 0 0 1-1.132-.396C2.753 12.177 5.12 14.025 8 14.025s5.246-1.847 5.86-4.283a7 7 0 0 1-1.13.396"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChopsticksOutline.displayName = 'ChopsticksOutline';
const Memo = memo(SvgChopsticksOutline);
export default Memo;
