// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBikeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.293 1c-.272 0-.492.234-.492.524s.22.524.492.524h3.472c.33 0 .646.14.88.389.233.248.364.586.364.937s-.131.69-.364.938c-.234.249-.55.388-.88.388h-1.736c-.357 0-.705.125-.989.356-.283.231-.488.555-.582.922v.001L8.613 9.24l-.573-.814a2.3 2.3 0 0 0-.786-.7c-.31-.164-.65-.25-.996-.25h-.603l-1.08-1.727h1.256c.272 0 .492-.235.492-.524S6.103 4.7 5.83 4.7H2.359c-.272 0-.492.235-.492.525s.22.524.492.524h1.034l1.08 1.727a3.43 3.43 0 0 0-2.447 1.102 3.9 3.9 0 0 0-1.034 2.66c0 .998.372 1.955 1.034 2.66A3.42 3.42 0 0 0 4.522 15h8.68a1.74 1.74 0 0 0 1.268-.56c.336-.358.525-.844.525-1.352 0-.507-.188-.993-.525-1.352a1.74 1.74 0 0 0-1.268-.56h-2.604a1.2 1.2 0 0 1-.557-.14 1.3 1.3 0 0 1-.439-.39L9.36 10.3l1.047-4.043v-.002a.7.7 0 0 1 .231-.365.62.62 0 0 1 .392-.141h1.736c.59 0 1.157-.25 1.575-.696s.652-1.049.652-1.679-.234-1.234-.652-1.679A2.16 2.16 0 0 0 12.765 1zm-4.635 9.356a.47.47 0 0 1 .688.105l1.302 1.85a.547.547 0 0 1-.098.734.47.47 0 0 1-.689-.104L4.56 11.09a.547.547 0 0 1 .099-.735"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBikeFill.displayName = 'BikeFill';
const Memo = memo(SvgBikeFill);
export default Memo;
