// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAroundSpatialOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.332 5.153v6.817c.013.02.055.067.167.14.197.127.516.261.96.387.411.116.873-.215.873-.751V6.44c0-.402-.302-.772-.748-.878a6.8 6.8 0 0 1-1.252-.409M2.357 4a1 1 0 0 0 .222.158c.264.149.68.3 1.235.43.841.2 1.518.93 1.518 1.852v3.704A24 24 0 0 1 7.999 10c.95 0 1.853.051 2.666.144V6.44c0-.921.677-1.652 1.519-1.851.555-.132.97-.282 1.234-.431A1 1 0 0 0 13.64 4a1 1 0 0 0-.209-.15c-.253-.146-.652-.294-1.19-.425C11.174 3.165 9.674 3 7.999 3s-3.176.166-4.243.425c-.537.13-.937.279-1.19.425a1 1 0 0 0-.209.15m11.308 1.153v6.817a.6.6 0 0 1-.167.14c-.197.127-.516.261-.959.387-.412.116-.874-.215-.874-.751V6.44c0-.402.302-.772.749-.878a6.8 6.8 0 0 0 1.251-.409m1-1.153v8c0 .454-.334.763-.626.951-.316.203-.742.37-1.228.508-1.153.326-2.146-.624-2.146-1.713v-.595C9.865 11.055 8.96 11 8 11s-1.867.055-2.667.15v.595c0 1.09-.993 2.04-2.146 1.714-.486-.137-.912-.305-1.228-.508-.292-.188-.626-.497-.626-.951V4c0-.5.402-.826.735-1.017.371-.214.875-.389 1.453-.53C4.685 2.17 6.268 2 7.999 2s3.314.17 4.478.454c.579.14 1.082.315 1.453.529.333.191.735.517.735 1.017"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAroundSpatialOutline.displayName = 'AroundSpatialOutline';
const Memo = memo(SvgAroundSpatialOutline);
export default Memo;
