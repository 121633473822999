// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVideoGameGamingGameFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.729 1a.505.505 0 0 0-.518.492c0 .271.232.492.518.492h1.03c.654 0 1.292.347 1.785.923s.79 1.325.79 2.012V5.2h-3.27c-.813 0-1.592.301-2.166.838A2.77 2.77 0 0 0 1 8.059v4.688c-.012.485.147.961.453 1.354.307.395.744.684 1.244.824l.014.004c.526.13 1.084.081 1.575-.136a2.23 2.23 0 0 0 1.104-1.04l1.162-2.018h2.894l1.123 1.94c.153.325.386.612.681.835.3.228.656.384 1.036.455l.01.002c.331.054.67.04.995-.042.325-.081.627-.228.884-.43.258-.2.465-.453.607-.737.143-.284.216-.594.217-.907l-.001-4.792c0-.758-.323-1.485-.897-2.021a3.18 3.18 0 0 0-2.166-.838H8.369V4.92c0-.936-.395-1.9-1.022-2.632C6.721 1.555 5.813 1 4.76 1zM5 7.502a.5.5 0 1 0-1 0v.499h-.498a.5.5 0 1 0 0 1H4v.498a.5.5 0 1 0 1 0v-.498h.498a.5.5 0 1 0 0-1H5zm8-.001a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVideoGameGamingGameFill.displayName = 'VideoGameGamingGameFill';
const Memo = memo(SvgVideoGameGamingGameFill);
export default Memo;
