// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgServer2StorageDataCoinsMoneyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.869 3.263c-.185.154-.201.253-.201.283s.016.129.2.284c.183.152.476.312.883.456.808.287 1.957.473 3.25.473 1.294 0 2.443-.186 3.251-.473.406-.144.7-.304.882-.456.184-.155.2-.253.2-.284s-.016-.129-.2-.283c-.182-.153-.476-.313-.882-.457-.808-.286-1.957-.473-3.25-.473-1.294 0-2.443.187-3.251.473-.407.144-.7.304-.882.457m8.466 1.633a5 5 0 0 1-.749.333c-.941.333-2.209.53-3.585.53s-2.643-.197-3.584-.53a5 5 0 0 1-.749-.333v1.557c0 .03.016.129.2.284.183.152.476.312.883.456.808.286 1.957.473 3.25.473 1.294 0 2.443-.187 3.251-.473.406-.144.7-.304.882-.456.184-.155.2-.253.2-.284zm1-1.35c0-.442-.255-.795-.56-1.05-.306-.257-.72-.466-1.189-.632-.941-.334-2.209-.53-3.585-.53s-2.643.196-3.584.53c-.469.166-.883.375-1.19.632-.305.255-.559.608-.559 1.05v8.908c0 .442.254.794.559 1.05.307.257.721.466 1.19.632.94.334 2.208.53 3.584.53s2.644-.196 3.585-.53c.468-.166.883-.375 1.19-.632.304-.255.559-.608.559-1.05zm-1 4.257a5 5 0 0 1-.749.333c-.941.333-2.209.53-3.585.53s-2.643-.197-3.584-.53a5 5 0 0 1-.749-.333v1.483c0 .031.016.13.2.284.183.152.476.312.883.456.808.287 1.957.473 3.25.473 1.294 0 2.443-.186 3.251-.473.406-.144.7-.304.882-.456.184-.155.2-.253.2-.284zm0 2.833a5 5 0 0 1-.749.333c-.941.334-2.209.53-3.585.53s-2.643-.197-3.584-.53a5 5 0 0 1-.749-.333v1.818c0 .03.016.128.2.283.183.153.476.313.883.457.808.286 1.957.473 3.25.473 1.294 0 2.443-.187 3.251-.473.406-.144.7-.304.882-.457.184-.155.2-.253.2-.283z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgServer2StorageDataCoinsMoneyOutline.displayName = 'Server2StorageDataCoinsMoneyOutline';
const Memo = memo(SvgServer2StorageDataCoinsMoneyOutline);
export default Memo;
