// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLightBulbSimpleIdeaOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M7.998 2.333a4.334 4.334 0 0 0-2.265 8.03c.325.2.572.55.596.97h3.338c.024-.42.27-.77.596-.97q.194-.12.374-.258a4.334 4.334 0 0 0-2.64-7.772m1.667 10H6.331v.333a1.667 1.667 0 0 0 3.334 0zm-7-5.666a5.334 5.334 0 1 1 8.121 4.548c-.087.053-.12.13-.12.185v1.266a2.667 2.667 0 0 1-5.335 0V11.4c0-.056-.034-.132-.121-.185a5.34 5.34 0 0 1-2.546-4.548"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgLightBulbSimpleIdeaOutline.displayName = 'LightBulbSimpleIdeaOutline';
const Memo = memo(SvgLightBulbSimpleIdeaOutline);
export default Memo;
