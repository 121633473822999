// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLocationExploreCompassOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.707 1.849a1.167 1.167 0 0 1 1.442 1.442l-2.133 7.467a1.83 1.83 0 0 1-1.26 1.26L3.29 14.15a1.167 1.167 0 0 1-1.442-1.442l2.134-7.467a1.83 1.83 0 0 1 1.259-1.26zm.48 1.167a.167.167 0 0 0-.205-.206L5.514 4.944a.83.83 0 0 0-.572.572L2.81 12.984a.167.167 0 0 0 .206.206l7.467-2.134a.83.83 0 0 0 .572-.572zm-5.189 4.15a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.666M6.165 8a1.833 1.833 0 1 1 3.667 0 1.833 1.833 0 0 1-3.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLocationExploreCompassOutline.displayName = 'LocationExploreCompassOutline';
const Memo = memo(SvgLocationExploreCompassOutline);
export default Memo;
