// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEmailEnvelopeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.427 3.936a2.3 2.3 0 0 0-.075.448c-.02.242-.02.54-.02.896v5.44c0 .356 0 .654.02.896.02.254.065.492.18.716.176.345.456.626.801.801.225.115.463.16.716.18.243.02.54.02.896.02h8.107c.356 0 .654 0 .896-.02.254-.02.492-.065.716-.18.345-.175.626-.456.802-.8.114-.226.159-.463.18-.716.02-.243.02-.54.02-.897V5.28c0-.356 0-.654-.02-.896a2.3 2.3 0 0 0-.076-.448L9.16 8.363a1.83 1.83 0 0 1-2.322 0z" />
      <Path d="M14.014 3.099a1.8 1.8 0 0 0-.35-.233 1.9 1.9 0 0 0-.716-.18c-.242-.02-.54-.02-.896-.02H3.945c-.356 0-.653 0-.896.02-.253.021-.491.066-.716.18a1.8 1.8 0 0 0-.35.233l5.488 4.49a.83.83 0 0 0 1.055 0z" />
    </Svg>
  );
};
SvgEmailEnvelopeFill.displayName = 'EmailEnvelopeFill';
const Memo = memo(SvgEmailEnvelopeFill);
export default Memo;
