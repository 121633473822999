// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudUploadFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.855 5.416a4.501 4.501 0 0 1 8.636 1.442 3.25 3.25 0 0 1-.406 6.475H8.5V9.04l.813.814a.5.5 0 1 0 .707-.708L8.356 7.48a.5.5 0 0 0-.707 0L5.98 9.146a.5.5 0 1 0 .707.708l.813-.814v4.293H4.668a4 4 0 0 1-.813-7.917" />
    </Svg>
  );
};
SvgCloudUploadFill.displayName = 'CloudUploadFill';
const Memo = memo(SvgCloudUploadFill);
export default Memo;
