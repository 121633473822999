import {type Colors, Palette} from '@propertyguru/hive-theme';

/**
 * BasePalette defines the default theme, that follows light theme for HIVE.
 * You can check other themes at https://propertyguru.atlassian.net/wiki/spaces/XD/pages/33668497539/Colour+WIP#Usage-Guidelines
 */
export const BaseColors: Colors = {
  surface: '#FFFFFF',
  fill: {
    active: {
      primary: Palette.red[60],
      secondary: Palette.red[10],
      tertiary: Palette.gray[80],
    },
    inactive: {
      primary: Palette.gray[10],
      secondary: Palette.gray[70],
      tertiary: Palette.gray[30],
    },
    pressed: {
      primary: Palette.transparent.white[20],
    },
    hover: {
      primary: Palette.transparent.black[20],
    },
    error: {
      primary: Palette.red[60],
      secondary: Palette.red[10],
      tertiary: Palette.red[80],
    },
    warning: {
      primary: Palette.orange[50],
      secondary: Palette.orange[10],
      tertiary: Palette.orange[70],
    },
    success: {
      primary: Palette.green[60],
      secondary: Palette.green[10],
      tertiary: Palette.green[70],
    },
    neutral: {
      primary: Palette.gray[10],
      secondary: Palette.gray[0],
      tertiary: Palette.gray[20],
      quaternary: Palette.gray[80],
    },
    static: {
      primary: Palette.white[100],
      secondary: Palette.black[100],
    },
    focus: {
      primary: Palette.blue[60],
    },
    brand: {
      red: Palette.red[50],
      green: Palette.green[50],
      blue: Palette.blue[50],
      teal: Palette.teal[50],
      orange: Palette.orange[50],
      purple: Palette.purple[50],
    },
  },
  text: {
    active: {
      primary: Palette.gray[90],
      secondary: Palette.gray[60],
      tertiary: Palette.gray[0],
    },
    inactive: {
      primary: Palette.gray[40],
      secondary: Palette.gray[70],
      tertiary: Palette.gray[20],
    },
    pressed: {
      primary: Palette.transparent.white[20],
    },
    hover: {
      primary: Palette.transparent.black[20],
    },
    error: {
      primary: Palette.red[60],
      secondary: Palette.red[10],
      tertiary: Palette.red[70],
    },
    warning: {
      primary: Palette.orange[60],
      secondary: Palette.orange[10],
      tertiary: Palette.orange[70],
    },
    success: {
      primary: Palette.green[70],
      secondary: Palette.green[10],
      tertiary: Palette.green[80],
    },
    neutral: {
      primary: Palette.gray[50],
    },
    static: {
      primary: Palette.white[100],
      secondary: Palette.blue[100],
    },
    focus: {
      primary: Palette.blue[60],
    },
    brand: {
      red: Palette.red[50],
      green: Palette.green[50],
      blue: Palette.blue[50],
      teal: Palette.teal[50],
      orange: Palette.orange[50],
      purple: Palette.purple[50],
    },
  },
  border: {
    active: {
      primary: Palette.gray[20],
      secondary: Palette.gray[10],
      tertiary: Palette.gray[70],
      quaternary: Palette.red[70],
    },
    inactive: {
      primary: Palette.gray[20],
      secondary: Palette.gray[70],
      tertiary: Palette.gray[30],
    },
    pressed: {
      primary: Palette.transparent.white[20],
    },
    hover: {
      primary: Palette.transparent.black[20],
    },
    error: {
      primary: Palette.red[60],
      secondary: Palette.red[10],
      tertiary: Palette.red[70],
    },
    warning: {
      primary: Palette.orange[60],
      secondary: Palette.orange[10],
      tertiary: Palette.orange[70],
    },
    success: {
      primary: Palette.green[60],
      secondary: Palette.green[10],
      tertiary: Palette.green[70],
    },
    neutral: {
      primary: Palette.gray[50],
    },
    static: {
      primary: Palette.white[100],
      secondary: Palette.black[100],
    },
    focus: {
      primary: Palette.blue[60],
    },
    brand: {
      red: Palette.red[50],
      green: Palette.green[50],
      blue: Palette.blue[50],
      teal: Palette.teal[50],
      orange: Palette.orange[50],
      purple: Palette.purple[50],
    },
  },
  icon: {
    active: {
      primary: Palette.gray[90],
      secondary: Palette.gray[60],
      tertiary: Palette.gray[0],
    },
    inactive: {
      primary: Palette.gray[20],
      secondary: Palette.gray[70],
      tertiary: Palette.gray[30],
    },
    pressed: {
      primary: Palette.transparent.white[20],
    },
    hover: {
      primary: Palette.transparent.black[20],
    },
    error: {
      primary: Palette.red[60],
      secondary: Palette.red[10],
      tertiary: Palette.red[80],
    },
    warning: {
      primary: Palette.orange[50],
      secondary: Palette.orange[10],
      tertiary: Palette.orange[70],
    },
    success: {
      primary: Palette.green[60],
      secondary: Palette.green[10],
      tertiary: Palette.green[70],
    },
    neutral: {
      primary: Palette.gray[50],
    },
    static: {
      primary: Palette.white[100],
      secondary: Palette.black[100],
    },
    focus: {
      primary: Palette.blue[50],
    },
    brand: {
      red: Palette.red[50],
      green: Palette.green[50],
      blue: Palette.blue[50],
      teal: Palette.teal[50],
      orange: Palette.orange[50],
      purple: Palette.purple[50],
    },
  },
};
