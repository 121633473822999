// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLink1ChainOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.37 2.256a1.833 1.833 0 0 1 2.593 0l2.78 2.78a1.833 1.833 0 0 1 0 2.594l-1.556 1.557a.5.5 0 1 1-.707-.707l1.557-1.558a.833.833 0 0 0 0-1.178l-2.781-2.781a.833.833 0 0 0-1.179 0L7.52 4.52a.5.5 0 0 1-.707-.707zm1.983 3.39a.5.5 0 0 1 0 .707l-4 4a.5.5 0 0 1-.707-.707l4-4a.5.5 0 0 1 .707 0M4.52 6.813a.5.5 0 0 1 0 .707L2.963 9.077a.833.833 0 0 0 0 1.179l-.354.353.354-.353 2.78 2.78a.833.833 0 0 0 1.18 0L8.48 11.48a.5.5 0 1 1 .707.707l-1.558 1.557a1.833 1.833 0 0 1-2.592 0l-2.781-2.781a1.833 1.833 0 0 1 0-2.593l1.557-1.557a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLink1ChainOutline.displayName = 'Link1ChainOutline';
const Memo = memo(SvgLink1ChainOutline);
export default Memo;
