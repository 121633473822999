import {Platform, type StyleProp, type ViewStyle} from 'react-native';

import type {ColorValue, ElevationValue} from '@propertyguru/hive';

export const toRGBA = (hexCode: ColorValue, alpha = 1): ColorValue => {
  if (typeof hexCode !== 'string' || !hexCode.startsWith('#')) {
    return hexCode;
  }

  let hex = hexCode.replace('#', '');

  if (hex.length === 3 || hex.length === 4) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${alpha})`;
};

/**
 * box-shadow = [x offset] [y offset] [blur radius] [optional spread radius] [color]
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow#syntax
 */
export const createBowShadow = (elevation: ElevationValue): string | undefined => {
  const {offset, blur, color, alpha} = elevation;

  return `${offset.x}px ${offset.y}px ${blur}px 0px ${toRGBA(color, alpha) as string}`;
};

export const elevationStyle = (
  elevation: ElevationValue | undefined,
): StyleProp<ViewStyle> | undefined => {
  if (!elevation) {
    return undefined;
  }

  return Platform.select({
    android: {
      elevation: elevation.blur,
      shadowColor: elevation.color,
    },
    ios: {
      shadowColor: elevation.color,
      shadowOffset: {width: elevation.offset.x, height: elevation.offset.y},
      shadowOpacity: elevation.alpha,
      shadowRadius: elevation.blur,
    },
    web: {
      boxShadow: createBowShadow(elevation),
    },
  });
};
