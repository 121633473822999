// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgErrorWarningAlertFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.425 2.172a1.833 1.833 0 0 1 3.152 0l4.99 8.39c.726 1.223-.155 2.771-1.576 2.771H3.01c-1.42 0-2.302-1.548-1.575-2.77zm1.576 3.161a.5.5 0 0 1 .5.5V8.5a.5.5 0 0 1-1 0V5.833a.5.5 0 0 1 .5-.5m.667 5a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgErrorWarningAlertFill.displayName = 'ErrorWarningAlertFill';
const Memo = memo(SvgErrorWarningAlertFill);
export default Memo;
