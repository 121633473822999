// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgParkingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2M4.464 4.464a5 5 0 1 1 7.072 7.071 5 5 0 0 1-7.072-7.07M6.5 5q.034 0 .066.004A1 1 0 0 1 6.647 5h1.166c.713 0 1.396.237 1.9.659s.787.994.787 1.591-.283 1.169-.787 1.591-1.187.659-1.9.659H7v1.02a.49.49 0 0 1-.5.48.49.49 0 0 1-.5-.48V5.48A.49.49 0 0 1 6.5 5M7 8.416V6.084h.813c.37 0 .723.123.984.342.261.218.408.515.408.824 0 .31-.147.606-.408.824a1.54 1.54 0 0 1-.984.342z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgParkingOutline.displayName = 'ParkingOutline';
const Memo = memo(SvgParkingOutline);
export default Memo;
