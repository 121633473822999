// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAccessibilityA11YOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
      <Path d="M5.336 6.937a.5.5 0 0 1 .559-.433h.003c.696.087 1.4.163 2.1.163.702 0 1.406-.076 2.101-.163h.003a.5.5 0 1 1 .126.992h-.004c-.566.071-1.136.127-1.706.156.093 1.103.518 1.959 1.345 2.839a.5.5 0 0 1-.729.685c-.48-.512-.853-1.031-1.12-1.59-.264.564-.644 1.09-1.164 1.603a.5.5 0 0 1-.703-.711c.864-.854 1.252-1.71 1.335-2.826-.571-.03-1.141-.085-1.708-.155H5.77a.5.5 0 0 1-.434-.56M7.999 6.333a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
    </Svg>
  );
};
SvgAccessibilityA11YOutline.displayName = 'AccessibilityA11YOutline';
const Memo = memo(SvgAccessibilityA11YOutline);
export default Memo;
