// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddPeopleAddUserAddPerson2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.333 4.333a3 3 0 1 1 6 0 3 3 0 0 1-6 0M2.186 11.902c.77-2.085 2.666-3.569 5.147-3.569s4.376 1.484 5.147 3.569a1.48 1.48 0 0 1-.26 1.483c-.32.38-.815.615-1.352.615h-7.07a1.77 1.77 0 0 1-1.352-.615 1.48 1.48 0 0 1-.26-1.483M13.5 5.833a.5.5 0 0 0-1 0v1.334h-1.334a.5.5 0 1 0 0 1H12.5V9.5a.5.5 0 0 0 1 0V8.167h1.333a.5.5 0 1 0 0-1H13.5z" />
    </Svg>
  );
};
SvgAddPeopleAddUserAddPerson2Fill.displayName = 'AddPeopleAddUserAddPerson2Fill';
const Memo = memo(SvgAddPeopleAddUserAddPerson2Fill);
export default Memo;
