// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHousingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.098 1.333c.246 0 .445.2.445.445v.34h.64a1.23 1.23 0 0 1 1.101.68l.96 1.922a.445.445 0 0 1-.397.644H8.51v7.746h2.246v-.763a1.23 1.23 0 0 1-.784-1.188 8.2 8.2 0 0 1 .83-2.906.445.445 0 0 1 .796 0c.454.908.736 1.894.83 2.906l.002.04a1.23 1.23 0 0 1-.785 1.147v.764h1.913a.445.445 0 0 1 0 .89H1.78a.445.445 0 0 1 0-.89h.34V5.364h-.34a.445.445 0 0 1-.398-.644l.96-1.922a1.23 1.23 0 0 1 1.1-.68h2.21v-.34c0-.246.2-.445.445-.445M3.01 13.11h1.071v-1.125a1.23 1.23 0 1 1 2.46 0v1.125H7.62V5.364H3.01zm1.126-3.925a.445.445 0 1 0 0 .89h2.356a.445.445 0 0 0 0-.89zm-.445-1.126c0-.245.2-.445.445-.445h2.356a.445.445 0 0 1 0 .89H4.137a.445.445 0 0 1-.445-.445m.445-2.015a.445.445 0 1 0 0 .89h2.356a.445.445 0 0 0 0-.89z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHousingFill.displayName = 'HousingFill';
const Memo = memo(SvgHousingFill);
export default Memo;
