// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCrossedLargeCloseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.11 4.11a.375.375 0 0 1 .53 0L8 7.47l3.36-3.36a.375.375 0 0 1 .53.53L8.53 8l3.36 3.36a.375.375 0 0 1-.53.53L8 8.53l-3.36 3.36a.375.375 0 0 1-.53-.53L7.47 8 4.11 4.64a.375.375 0 0 1 0-.53"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCrossedLargeCloseFill.displayName = 'CrossedLargeCloseFill';
const Memo = memo(SvgCrossedLargeCloseFill);
export default Memo;
