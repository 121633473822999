// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgColorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4 3.833C4 2.821 4.82 2 5.833 2h6.334C13.179 2 14 2.82 14 3.833V5.5c0 1.013-.82 1.833-1.833 1.833H5.833A1.833 1.833 0 0 1 4 5.5v-.333h-.833A.167.167 0 0 0 3 5.333V7.5c0 .46.373.833.833.833H8c.644 0 1.167.523 1.167 1.167v.73a2 2 0 0 1 1.5 1.937v2a.5.5 0 0 1-1 0v-2a1 1 0 1 0-2 0v2a.5.5 0 0 1-1 0v-2a2 2 0 0 1 1.5-1.937V9.5A.167.167 0 0 0 8 9.333H3.833A1.833 1.833 0 0 1 2 7.5V5.333c0-.644.522-1.166 1.167-1.166H4zM5.833 3A.833.833 0 0 0 5 3.833V5.5c0 .46.373.833.833.833h6.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgColorOutline.displayName = 'ColorOutline';
const Memo = memo(SvgColorOutline);
export default Memo;
