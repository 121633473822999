// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCirclePlaceholderOffFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.653 11.533a6.667 6.667 0 0 0-9.188-9.188zM2.944 3.652l-.966-.965a.5.5 0 0 1 .708-.708l1.298 1.298.008.008 8.72 8.721.009.009 1.298 1.298a.5.5 0 1 1-.707.707l-.966-.966a6.667 6.667 0 0 1-9.402-9.402" />
    </Svg>
  );
};
SvgCirclePlaceholderOffFill.displayName = 'CirclePlaceholderOffFill';
const Memo = memo(SvgCirclePlaceholderOffFill);
export default Memo;
