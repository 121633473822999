// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUmbrellaSecurityFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.499 1.833a.5.5 0 1 0-1 0v.85c-2.61.164-4.856 1.607-6.005 3.685a1.46 1.46 0 0 0 .093 1.595c.31.43.836.706 1.42.706h9.983c.586 0 1.11-.276 1.421-.706.318-.44.402-1.037.094-1.595-1.15-2.078-3.397-3.521-6.006-3.686zM8.5 10.167a.5.5 0 0 0-1 0v2.717a.782.782 0 0 1-1.55.154l-.127-.636a.5.5 0 0 0-.98.196l.126.636a1.782 1.782 0 0 0 3.53-.35z" />
    </Svg>
  );
};
SvgUmbrellaSecurityFill.displayName = 'UmbrellaSecurityFill';
const Memo = memo(SvgUmbrellaSecurityFill);
export default Memo;
