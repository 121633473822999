// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgH2HeadingHeadlineOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.836 2.667a.5.5 0 0 1 .5.5V7.5h6V3.167a.5.5 0 0 1 1 0v9.666a.5.5 0 1 1-1 0V8.5h-6v4.333a.5.5 0 1 1-1 0V3.167a.5.5 0 0 1 .5-.5m10.632 5.462a1 1 0 0 0-.078.214.5.5 0 0 1-.97-.241 2 2 0 0 1 .19-.488 2.02 2.02 0 0 1 1.726-.947c1.21 0 2 .969 2 2 0 .442-.163.988-.504 1.358l-1.927 2.308h1.93a.5.5 0 1 1 0 1h-3a.5.5 0 0 1-.383-.82l2.621-3.14.02-.021c.14-.149.243-.438.243-.685 0-.534-.396-1-1-1-.38 0-.7.192-.868.462"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgH2HeadingHeadlineOutline.displayName = 'H2HeadingHeadlineOutline';
const Memo = memo(SvgH2HeadingHeadlineOutline);
export default Memo;
