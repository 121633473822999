// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronGrabberVerticalFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.117 4.158a.167.167 0 0 0-.236 0L5.686 6.353a.5.5 0 0 1-.708-.707l2.196-2.195.353.354-.353-.354a1.167 1.167 0 0 1 1.65 0l2.195 2.195a.5.5 0 1 1-.707.707zM4.978 9.646a.5.5 0 0 1 .708 0l2.195 2.196c.065.065.17.065.236 0l2.195-2.196a.5.5 0 1 1 .707.707L8.824 12.55a1.167 1.167 0 0 1-1.65 0l-2.196-2.195a.5.5 0 0 1 0-.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronGrabberVerticalFill.displayName = 'ChevronGrabberVerticalFill';
const Memo = memo(SvgChevronGrabberVerticalFill);
export default Memo;
