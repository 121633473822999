// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWatch1ClockTimeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.815 1.667a.17.17 0 0 0-.158.114l-.295.886h5.279l-.295-.886a.17.17 0 0 0-.159-.114zm5.883 1.01-.404-1.213a1.17 1.17 0 0 0-1.107-.797H5.816c-.502 0-.948.321-1.107.797l-.404 1.213A1.834 1.834 0 0 0 2.668 4.5v7c0 .946.716 1.725 1.636 1.823l.404 1.213c.16.476.605.797 1.107.797h4.373c.502 0 .947-.32 1.106-.797l.404-1.213a1.833 1.833 0 0 0 1.637-1.823v-7c0-.946-.717-1.724-1.637-1.823m-1.057 10.656H5.36l.296.886a.17.17 0 0 0 .158.114h4.373a.17.17 0 0 0 .158-.114zm.86-1c.46 0 .834-.373.834-.833v-7a.833.833 0 0 0-.834-.833h-7a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833zm-3.5-7a.5.5 0 0 1 .5.5v1.96l.854.854a.5.5 0 1 1-.707.707l-1-1A.5.5 0 0 1 7.5 8V5.833a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWatch1ClockTimeOutline.displayName = 'Watch1ClockTimeOutline';
const Memo = memo(SvgWatch1ClockTimeOutline);
export default Memo;
