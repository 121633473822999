// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgParkingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.003 1.333a6.667 6.667 0 1 0 0 13.334 6.667 6.667 0 0 0 0-13.334M6.337 4.667q.038 0 .074.004a1 1 0 0 1 .09-.004h1.295c.791 0 1.55.263 2.11.732s.875 1.105.875 1.768-.314 1.299-.874 1.767c-.56.47-1.32.733-2.111.733h-.904V10.8a.545.545 0 0 1-.555.533.545.545 0 0 1-.556-.533V5.2c0-.295.249-.533.556-.533m.555 3.795v-2.59h.904c.41 0 .803.136 1.093.379s.453.572.453.916c0 .343-.163.673-.453.915-.29.243-.683.38-1.093.38z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgParkingFill.displayName = 'ParkingFill';
const Memo = memo(SvgParkingFill);
export default Memo;
