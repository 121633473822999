// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAirplayFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.052 2H3.945c-.356 0-.653 0-.896.02-.253.02-.491.065-.716.18A1.83 1.83 0 0 0 1.532 3c-.115.225-.16.463-.18.716-.02.243-.02.54-.02.896v4.774c0 .356 0 .653.02.896.02.253.065.491.18.716.176.345.456.625.801.801.225.115.463.16.716.18.243.02.54.02.896.02h.22a.5.5 0 0 0 0-1h-.2c-.381 0-.637 0-.835-.017-.191-.015-.283-.043-.343-.074a.83.83 0 0 1-.364-.364c-.03-.06-.059-.152-.074-.343a12 12 0 0 1-.017-.835V4.633c0-.381 0-.637.017-.835.015-.191.043-.283.074-.343a.83.83 0 0 1 .364-.364c.06-.03.152-.059.343-.074C3.328 3 3.584 3 3.965 3h8.067c.382 0 .638 0 .835.017.191.015.283.043.343.074.157.08.285.207.364.364.031.06.06.152.075.343.016.198.016.454.016.835v4.734c0 .381 0 .637-.016.835-.016.191-.044.283-.074.343a.83.83 0 0 1-.365.364c-.06.03-.152.059-.343.074a12 12 0 0 1-.835.017h-.2a.5.5 0 0 0 0 1h.22c.356 0 .654 0 .896-.02.254-.02.492-.065.716-.18.345-.176.626-.456.802-.801.114-.225.159-.463.18-.716.02-.243.02-.54.02-.896V4.613c0-.356 0-.653-.02-.896a1.9 1.9 0 0 0-.18-.716 1.83 1.83 0 0 0-.802-.801 1.9 1.9 0 0 0-.716-.18c-.242-.02-.54-.02-.896-.02" />
      <Path d="M7.999 9.5a.5.5 0 0 1 .397.197l2.667 3.5a.5.5 0 0 1-.398.803H5.332a.5.5 0 0 1-.398-.803l2.667-3.5a.5.5 0 0 1 .398-.197" />
    </Svg>
  );
};
SvgAirplayFill.displayName = 'AirplayFill';
const Memo = memo(SvgAirplayFill);
export default Memo;
