// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDonutOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.29-4.62a1.88 1.88 0 0 1 2.544.52l.293.422a.88.88 0 0 0 .433.33l.486.169a1.88 1.88 0 0 1 1.18 2.314l-.148.492a.88.88 0 0 0 .013.544l.17.485a1.88 1.88 0 0 1-1.073 2.366l-.477.191a.88.88 0 0 0-.417.35l-.274.435a1.88 1.88 0 0 1-2.518.636l-.448-.254a.88.88 0 0 0-.532-.109l-.511.058a1.88 1.88 0 0 1-2.068-1.572l-.08-.508a.88.88 0 0 0-.248-.484L3.585 9.4a1.88 1.88 0 0 1-.06-2.597l.347-.38a.9.9 0 0 0 .224-.494l.058-.511a1.88 1.88 0 0 1 1.993-1.666l.513.034a.88.88 0 0 0 .527-.133zm1.723 1.09a.88.88 0 0 0-1.191-.244l-.436.274a1.88 1.88 0 0 1-1.126.285L6.08 4.75a.88.88 0 0 0-.933.78l-.057.511a1.88 1.88 0 0 1-.48 1.058l-.347.38a.88.88 0 0 0 .028 1.215l.364.363c.281.28.466.643.528 1.035l.08.508a.88.88 0 0 0 .968.736l.511-.058a1.88 1.88 0 0 1 1.138.232l.448.254a.88.88 0 0 0 1.178-.298l.274-.435a1.88 1.88 0 0 1 .891-.745l.477-.192a.88.88 0 0 0 .502-1.107l-.17-.485a1.88 1.88 0 0 1-.026-1.162l.147-.492a.88.88 0 0 0-.552-1.083l-.486-.17a1.88 1.88 0 0 1-.924-.703zM7.999 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDonutOutline.displayName = 'DonutOutline';
const Memo = memo(SvgDonutOutline);
export default Memo;
