// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTaxiFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.043 2c.233 0 .42.2.42.44v1.58h1.346c.554 0 1.054.347 1.267.88l1.08 2.653h2.127c.76 0 1.38.654 1.38 1.454v2.02c0 .8-.62 1.453-1.38 1.453h-.114c-.193.867-.933 1.513-1.813 1.513s-1.627-.646-1.813-1.513H6.449c-.193.867-.933 1.513-1.813 1.513s-1.627-.646-1.813-1.513h-.107c-.76 0-1.38-.653-1.38-1.453v-2.02c0-.8.62-1.454 1.38-1.454h.2L3.996 4.9c.22-.533.72-.88 1.267-.88h1.346V2.44c0-.247.187-.44.42-.44zm0 2.907H5.276a.545.545 0 0 0-.5.346l-.94 2.307h6.413l-.94-2.307a.55.55 0 0 0-.5-.346zm3.293 7.18c.027.573.473 1.026 1.02 1.026s1.02-.48 1.02-1.073-.46-1.073-1.02-1.073-1 .453-1.02 1.026v.094m-5.7 1.026c-.547 0-.993-.453-1.02-1.02v-.106c.027-.574.473-1.027 1.02-1.027s1.02.48 1.02 1.073c0 .594-.46 1.074-1.02 1.074z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTaxiFill.displayName = 'TaxiFill';
const Memo = memo(SvgTaxiFill);
export default Memo;
