// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCmdCommandAppleKeyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 4.333a2.333 2.333 0 0 1 4.667 0v1.334h2.666V4.333a2.333 2.333 0 1 1 2.334 2.334h-1.334v2.666h1.334a2.333 2.333 0 1 1-2.334 2.334v-1.334H6.667v1.334a2.333 2.333 0 1 1-2.334-2.334h1.334V6.667H4.333A2.333 2.333 0 0 1 2 4.333m3.667 1.334V4.333a1.333 1.333 0 1 0-1.334 1.334zm1 1v2.666h2.666V6.667zm-1 3.666H4.333a1.333 1.333 0 1 0 1.334 1.334zm4.666 0v1.334a1.333 1.333 0 1 0 1.334-1.334zm0-4.666h1.334a1.333 1.333 0 1 0-1.334-1.334z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCmdCommandAppleKeyFill.displayName = 'CmdCommandAppleKeyFill';
const Memo = memo(SvgCmdCommandAppleKeyFill);
export default Memo;
