// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGraduateCapStudyEducationAcademicStudentOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.773 2.055a.5.5 0 0 1 .456 0l6.834 3.5a.5.5 0 0 1 .272.445v4.167a.5.5 0 1 1-1 0v-3.35l-1.667.855v2.936c0 .671-.367 1.289-.957 1.61l-2.834 1.541a1.83 1.83 0 0 1-1.752 0l-2.833-1.541a1.83 1.83 0 0 1-.957-1.61V7.672L.94 6.445a.5.5 0 0 1 0-.89zM4.335 8.184v2.424c0 .305.167.586.435.731l2.833 1.542a.83.83 0 0 0 .797 0l2.833-1.542a.83.83 0 0 0 .435-.732V8.185L8.229 9.945a.5.5 0 0 1-.456 0zM13.738 6 8 8.938 2.265 6 8 3.062z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGraduateCapStudyEducationAcademicStudentOutline.displayName =
  'GraduateCapStudyEducationAcademicStudentOutline';
const Memo = memo(SvgGraduateCapStudyEducationAcademicStudentOutline);
export default Memo;
