// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleCircleUserPersonAvatar2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a6.667 6.667 0 1 0 0 13.334 6.667 6.667 0 0 0 0-13.334M3.983 11.998C4.865 10.783 6.273 10 7.999 10s3.134.783 4.016 1.998a5.65 5.65 0 0 1-4.016 1.669c-1.57 0-2.99-.638-4.016-1.67m6.016-5.331a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPeopleCircleUserPersonAvatar2Fill.displayName = 'PeopleCircleUserPersonAvatar2Fill';
const Memo = memo(SvgPeopleCircleUserPersonAvatar2Fill);
export default Memo;
