// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSpoonForkUtensilsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.104 1.446c0-.246-.247-.445-.552-.445S2 1.2 2 1.446v3.538c.002.535.266 1.047.734 1.424.349.282.79.472 1.266.551v6.585c0 .252.224.457.5.457s.5-.205.5-.457V6.959c.476-.08.917-.27 1.266-.55.468-.378.732-.89.734-1.425V1.446c0-.246-.247-.445-.552-.445s-.552.199-.552.445v3.536c-.001.3-.149.585-.41.796A1.5 1.5 0 0 1 5 6.034V1.457c0-.252-.224-.456-.5-.456s-.5.204-.5.456v4.577a1.5 1.5 0 0 1-.485-.256c-.262-.21-.41-.497-.411-.796zM12 7.001h.15C13.17 7 14 6.332 14 5.507V2.494c0-.825-.829-1.493-1.85-1.493h-1.3C9.83 1 9 1.669 9 2.494v3.013c0 .825.829 1.494 1.85 1.494H11v6.529c0 .26.224.47.5.47s.5-.21.5-.47zm-1.896-4.507c0-.333.334-.603.747-.603h1.298c.413 0 .747.27.747.603v3.013c0 .333-.334.603-.747.603h-1.298c-.413 0-.747-.27-.747-.603z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSpoonForkUtensilsOutline.displayName = 'SpoonForkUtensilsOutline';
const Memo = memo(SvgSpoonForkUtensilsOutline);
export default Memo;
