// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDateTimeCalendarTimeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.782 3.848a.833.833 0 0 0-.965-.675l-6.237 1.1a.833.833 0 0 0-.676.965l.144.82L9.927 4.67zM7.251 6.156l-5.03.887.667 3.775c.08.454.512.756.965.676l2.478-.437a4.7 4.7 0 0 1-.328-1.724A4.65 4.65 0 0 1 7.25 6.156m-.422 5.829-2.802.494a1.833 1.833 0 0 1-2.124-1.487l-.984-5.58a1.833 1.833 0 0 1 1.487-2.125l6.237-1.1a1.833 1.833 0 0 1 2.124 1.488l.176 1a4.667 4.667 0 1 1-4.114 7.31m3.84-6.318a3.667 3.667 0 1 0 0 7.333 3.667 3.667 0 0 0 0-7.333m0 1.666a.5.5 0 0 1 .5.5v1.293l1.187 1.187a.5.5 0 1 1-.707.707l-1.333-1.333a.5.5 0 0 1-.147-.354v-1.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDateTimeCalendarTimeOutline.displayName = 'DateTimeCalendarTimeOutline';
const Memo = memo(SvgDateTimeCalendarTimeOutline);
export default Memo;
