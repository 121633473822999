import type {Theme} from '@propertyguru/hive-theme';

import {BaseBorderRadius} from './BaseBorderRadius';
import {BaseBreakpoints} from './BaseBreakpoints';
import {BaseColors} from './BaseColors';
import {BaseElevation} from './BaseElevation';
import {BaseSpacing} from './BaseSpacing';
import {BaseTypography} from './BaseTypography';
import {DarkColors} from './DarkColors';
import {HighContrastColors} from './HighContrastColors';

export const BaseTheme: Theme = {
  colors: BaseColors,
  typography: BaseTypography,
  dimension: {
    spacing: BaseSpacing,
    breakpoint: BaseBreakpoints,
  },
  shape: {
    borderRadius: BaseBorderRadius,
  },
  elevation: BaseElevation,
};

export const DarkTheme: Theme = {
  ...BaseTheme,
  colors: DarkColors,
};

export const HighContrastTheme: Theme = {
  ...BaseTheme,
  colors: HighContrastColors,
};
