// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDualPowerSourceOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.106 13.848q.065.025.139.026a.4.4 0 0 0 .304-.136L9.48 8.08a.695.695 0 0 0 .107-.75.74.74 0 0 0-.261-.293.7.7 0 0 0-.384-.11H7.15V2.391a.4.4 0 0 0-.261-.367.4.4 0 0 0-.443.11L1.51 7.788a.7.7 0 0 0-.165.362.65.65 0 0 0 .059.388.73.73 0 0 0 .65.409h1.792v4.535c0 .162.107.31.261.367m.139-5.688H2.24L6.35 3.45v3.875a.4.4 0 0 0 .4.393h2.005l-4.111 4.703v-3.87a.4.4 0 0 0-.4-.392M9.259 14h.003-.005zm-.136-.026A.4.4 0 0 0 9.26 14a.4.4 0 0 0 .302-.136l4.937-5.652a.69.69 0 0 0 .107-.75.74.74 0 0 0-.261-.293.7.7 0 0 0-.384-.11h-1.792V2.524a.403.403 0 0 0-.704-.257l-2.469 2.82a.39.39 0 0 0 .043.556c.17.142.421.12.565-.042l1.765-2.018v3.864a.4.4 0 0 0 .4.393h2.005l-4.111 4.703v-1.4a.4.4 0 0 0-.8 0v2.464c0 .163.107.31.261.367"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDualPowerSourceOutline.displayName = 'DualPowerSourceOutline';
const Memo = memo(SvgDualPowerSourceOutline);
export default Memo;
