import type {Elevations} from '@propertyguru/hive-theme';
import {Palette} from '@propertyguru/hive-theme';

export const BaseElevation: Elevations = {
  soft: {
    down: {
      large: {
        offset: {
          x: 0,
          y: 8,
        },
        color: Palette.gray[70],
        alpha: 0.12,
        blur: 16,
      },
      medium: {
        offset: {
          x: 0,
          y: 4,
        },
        color: Palette.gray[70],
        alpha: 0.12,
        blur: 8,
      },
      small: {
        offset: {
          x: 0,
          y: 2,
        },
        color: Palette.gray[70],
        alpha: 0.12,
        blur: 4,
      },
    },
    up: {
      large: {
        offset: {
          x: 0,
          y: -8,
        },
        color: Palette.gray[70],
        alpha: 0.12,
        blur: 16,
      },
      medium: {
        offset: {
          x: 0,
          y: -4,
        },
        color: Palette.gray[70],
        alpha: 0.12,
        blur: 8,
      },
      small: {
        offset: {
          x: 0,
          y: -2,
        },
        color: Palette.gray[70],
        alpha: 0.12,
        blur: 4,
      },
    },
  },
  hard: {
    down: {
      large: {
        offset: {
          x: 0,
          y: 8,
        },
        color: Palette.gray[70],
        alpha: 0.2,
        blur: 16,
      },
      medium: {
        offset: {
          x: 0,
          y: 4,
        },
        color: Palette.gray[70],
        alpha: 0.2,
        blur: 8,
      },
      small: {
        offset: {
          x: 0,
          y: 2,
        },
        color: Palette.gray[70],
        alpha: 0.2,
        blur: 4,
      },
    },
    up: {
      large: {
        offset: {
          x: 0,
          y: -8,
        },
        color: Palette.gray[70],
        alpha: 0.2,
        blur: 16,
      },
      medium: {
        offset: {
          x: 0,
          y: -4,
        },
        color: Palette.gray[70],
        alpha: 0.2,
        blur: 8,
      },
      small: {
        offset: {
          x: 0,
          y: -2,
        },
        color: Palette.gray[70],
        alpha: 0.2,
        blur: 4,
      },
    },
  },
};
