// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgImages1PhotosPicturesShotOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM3.833 3A.833.833 0 0 0 3 3.833V8.6l1.275-.954.01-.007a1.83 1.83 0 0 1 2.361.278C7.63 8.975 8.654 9.803 10 9.803c1.286 0 2.125-.462 2.98-1.316l.02-.02V3.834A.833.833 0 0 0 12.167 3zM13 9.803c-.82.63-1.75 1-3 1-1.798 0-3.08-1.121-4.086-2.206a.83.83 0 0 0-1.063-.133L3 9.85v2.318c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833zM10.167 5a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667m-1.834.833a1.833 1.833 0 1 1 3.667 0 1.833 1.833 0 0 1-3.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgImages1PhotosPicturesShotOutline.displayName = 'Images1PhotosPicturesShotOutline';
const Memo = memo(SvgImages1PhotosPicturesShotOutline);
export default Memo;
