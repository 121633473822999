// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSendEmailPaperPlaneArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.477 2.065a1.167 1.167 0 0 1 1.46 1.46L10.52 14.54c-.35 1.127-1.96 1.082-2.246-.062L6.947 9.176a.17.17 0 0 0-.121-.121L1.524 7.729C.38 7.443.334 5.833 1.462 5.483zm.505 1.163a.167.167 0 0 0-.208-.208L1.758 6.438a.16.16 0 0 0-.09.062.2.2 0 0 0-.027.102.2.2 0 0 0 .032.1c.016.021.042.044.094.057l5.301 1.326c.418.104.745.43.85.848l1.325 5.302a.16.16 0 0 0 .056.094c.024.018.06.031.1.032a.2.2 0 0 0 .103-.027.16.16 0 0 0 .062-.09z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSendEmailPaperPlaneArrowOutline.displayName = 'SendEmailPaperPlaneArrowOutline';
const Memo = memo(SvgSendEmailPaperPlaneArrowOutline);
export default Memo;
