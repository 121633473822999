// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFingerPrintTouchIdFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.007 2.333a5 5 0 0 0-2.188.503.5.5 0 1 1-.438-.899 6.002 6.002 0 0 1 8.628 5.398 23.3 23.3 0 0 1-.39 4.258.5.5 0 0 1-.983-.182c.245-1.321.373-2.683.373-4.076a5 5 0 0 0-5.002-5.002M3.828 3.722a.5.5 0 0 1 .115.698 4.98 4.98 0 0 0-.938 2.915c0 .994-.119 1.96-.343 2.886a.5.5 0 1 1-.972-.236 11.2 11.2 0 0 0 .315-2.65c0-1.305.418-2.514 1.126-3.499a.5.5 0 0 1 .697-.114m4.18 1.362a2.25 2.25 0 0 0-2.252 2.251q-.001 1.274-.206 2.49a.5.5 0 0 1-.986-.165 14 14 0 0 0 .192-2.325 3.25 3.25 0 1 1 6.502 0q0 .652-.04 1.294a.5.5 0 1 1-.998-.062q.038-.611.038-1.232a2.25 2.25 0 0 0-2.25-2.25m0 1.751a.5.5 0 0 1 .5.5 17.7 17.7 0 0 1-1.406 6.922.5.5 0 1 1-.92-.391q.262-.616.475-1.256c.552-1.657.85-3.43.85-5.275a.5.5 0 0 1 .5-.5m2.514 3.62a.5.5 0 0 1 .402.582 20 20 0 0 1-.836 3.131.5.5 0 0 1-.942-.334 19 19 0 0 0 .794-2.977.5.5 0 0 1 .582-.402m-5.856 1.012a.5.5 0 0 1 .314.633q-.165.49-.36.962a.5.5 0 1 1-.924-.384q.183-.441.337-.896a.5.5 0 0 1 .633-.315"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFingerPrintTouchIdFill.displayName = 'FingerPrintTouchIdFill';
const Memo = memo(SvgFingerPrintTouchIdFill);
export default Memo;
