// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShieldProtectSecurityCheckOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v7.209c0 .67-.367 1.288-.955 1.61l-4.167 2.272a1.83 1.83 0 0 1-1.756 0l-4.167-2.273A1.83 1.83 0 0 1 2 11.041zM3.833 3A.833.833 0 0 0 3 3.833v7.209c0 .305.167.585.434.731l4.167 2.273-.24.44.24-.44c.249.136.55.136.798 0l4.167-2.273a.83.83 0 0 0 .434-.731V3.833A.833.833 0 0 0 12.167 3zm6.187 3.48a.5.5 0 0 1 0 .707L7.687 9.52a.5.5 0 0 1-.707 0l-1-1a.5.5 0 0 1 .707-.707l.646.647 1.98-1.98a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShieldProtectSecurityCheckOutline.displayName = 'ShieldProtectSecurityCheckOutline';
const Memo = memo(SvgShieldProtectSecurityCheckOutline);
export default Memo;
