// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderCloud1Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.168 2c-1.013 0-1.833.82-1.833 1.833v4.685A3.5 3.5 0 0 1 3.168 8a3.5 3.5 0 0 1 2.53 1.081 3 3 0 0 1 1.991 4.252h5.146c1.012 0 1.833-.82 1.833-1.833V5.833c0-1.012-.82-1.833-1.833-1.833h-4.12a.83.83 0 0 1-.693-.371l-.542-.813A1.83 1.83 0 0 0 5.954 2z" />
      <Path
        fillRule="evenodd"
        d="M3.168 9a2.5 2.5 0 0 0 0 5h1.833a2 2 0 0 0 .172-3.993A2.5 2.5 0 0 0 3.168 9m-1.5 2.5a1.5 1.5 0 0 1 2.8-.747.5.5 0 0 0 .467.25L5 11a1 1 0 1 1 0 2H3.168a1.5 1.5 0 0 1-1.5-1.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolderCloud1Fill.displayName = 'FolderCloud1Fill';
const Memo = memo(SvgFolderCloud1Fill);
export default Memo;
