// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSchoolBigBuildingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.001 4.5c0-1.013.821-1.833 1.834-1.833h4.333c1.013 0 1.833.82 1.833 1.833v.833h.834c1.012 0 1.833.821 1.833 1.834v5.166h.167a.5.5 0 1 1 0 1H1.168a.5.5 0 0 1 0-1h.167V7.167c0-1.013.82-1.834 1.833-1.834h.833zm0 1.833h-.833a.833.833 0 0 0-.833.834v5.166H4zm8 6h1.667V7.167a.833.833 0 0 0-.833-.834H12zm-2.666 0v-1.5A.833.833 0 0 0 8.5 10h-1a.833.833 0 0 0-.833.833v1.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSchoolBigBuildingFill.displayName = 'SchoolBigBuildingFill';
const Memo = memo(SvgSchoolBigBuildingFill);
export default Memo;
