// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierCurvesAnimationMotionSpringFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.75 2A2.75 2.75 0 0 0 2 4.75v6.5A2.75 2.75 0 0 0 4.75 14h6.5A2.75 2.75 0 0 0 14 11.25v-6.5A2.75 2.75 0 0 0 11.25 2zm6.048 2.417c-.994 0-1.71.526-2.231 1.158-.493.598-.879 1.37-1.217 2.048l-.021.042c-.364.73-.68 1.353-1.053 1.806-.355.43-.685.612-1.074.612h-.035a.75.75 0 0 0 0 1.5h.035c.994 0 1.71-.525 2.231-1.158.493-.597.879-1.37 1.217-2.047l.021-.043c.364-.729.68-1.353 1.053-1.805.355-.43.685-.613 1.074-.613h.035a.75.75 0 0 0 0-1.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBezierCurvesAnimationMotionSpringFill.displayName = 'BezierCurvesAnimationMotionSpringFill';
const Memo = memo(SvgBezierCurvesAnimationMotionSpringFill);
export default Memo;
