// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgOpenQuoteBlockquoteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.664 11.5c0 1.012-.82 1.833-1.833 1.833h-2.334a1.833 1.833 0 0 1-1.833-1.832V7.309c0-1.714.742-2.873 1.485-3.595a5.3 5.3 0 0 1 1.354-.95l.131-.057.01-.004.003-.002h.002l.182.466-.181-.466a.5.5 0 0 1 .68.466v3.642h.5c1.013 0 1.834.821 1.834 1.834zm-3.333-7.474a4 4 0 0 0-.485.406c-.59.573-1.182 1.485-1.182 2.877v4.192c0 .46.373.832.833.832h2.334c.46 0 .833-.373.833-.833V8.643a.833.833 0 0 0-.833-.834h-1a.5.5 0 0 1-.5-.5zm-4 7.474c0 1.012-.821 1.833-1.834 1.833H3.164a1.833 1.833 0 0 1-1.833-1.832V7.309c0-1.714.742-2.873 1.485-3.595a5.3 5.3 0 0 1 1.354-.95l.131-.057.01-.004.003-.002h.001l.182.466-.18-.466a.5.5 0 0 1 .68.466v3.642h.5c1.013 0 1.834.821 1.834 1.834zM3.997 4.026c-.153.11-.319.245-.485.406-.59.573-1.181 1.485-1.181 2.877v4.192c0 .46.372.832.833.832h2.333c.46 0 .834-.373.834-.833V8.643a.833.833 0 0 0-.834-.834h-1a.5.5 0 0 1-.5-.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgOpenQuoteBlockquoteOutline.displayName = 'OpenQuoteBlockquoteOutline';
const Memo = memo(SvgOpenQuoteBlockquoteOutline);
export default Memo;
