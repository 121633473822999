// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGifFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm2.5 3.745c0 1.096.64 1.755 1.727 1.755.967 0 1.618-.575 1.618-1.422v-.234c0-.358-.167-.519-.545-.519h-.703c-.252 0-.387.116-.387.327 0 .214.137.333.387.333h.353v.163c0 .352-.276.589-.686.589-.528 0-.817-.352-.817-.995v-.464c0-.651.284-.992.826-.992.362 0 .575.21.802.433l.015.015c.084.082.17.118.282.118.226 0 .384-.152.384-.374 0-.223-.17-.465-.428-.648C6.374 6.118 5.98 6 5.536 6c-1.056 0-1.704.668-1.704 1.732zm4.426 1.699c.302 0 .47-.18.47-.502V6.544c0-.319-.171-.502-.475-.502s-.474.18-.474.502v2.898c0 .319.175.502.48.502m2.18-.502c0 .321-.166.502-.464.502-.307 0-.485-.186-.485-.502v-2.84c0-.343.192-.532.548-.532h1.753c.218 0 .375.158.375.378 0 .217-.157.369-.375.369h-1.351v.924h1.204c.227 0 .376.146.376.363s-.152.364-.376.364h-1.205z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGifFill.displayName = 'GifFill';
const Memo = memo(SvgGifFill);
export default Memo;
