// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble5MessageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.167 2C13.179 2 14 2.82 14 3.833V11.5c0 1.012-.82 1.833-1.833 1.833h-6.81a.17.17 0 0 0-.087.026l-2.505 1.565A.5.5 0 0 1 2 14.5V3.833C2 2.821 2.82 2 3.833 2z" />
    </Svg>
  );
};
SvgBubble5MessageFill.displayName = 'Bubble5MessageFill';
const Memo = memo(SvgBubble5MessageFill);
export default Memo;
