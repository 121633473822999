// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTelevision1TvMonitorVideoScreenDisplayOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2h9.667c1.013 0 1.833.82 1.833 1.833V9.5c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 9.5zM3.165 3a.833.833 0 0 0-.833.833V9.5c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.832 3zM8 13.333c-1.342 0-2.632.225-3.838.64a.5.5 0 1 1-.325-.946A12.8 12.8 0 0 1 8 12.333c1.454 0 2.853.244 4.162.694a.5.5 0 1 1-.325.946A11.8 11.8 0 0 0 8 13.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTelevision1TvMonitorVideoScreenDisplayOutline.displayName =
  'Television1TvMonitorVideoScreenDisplayOutline';
const Memo = memo(SvgTelevision1TvMonitorVideoScreenDisplayOutline);
export default Memo;
