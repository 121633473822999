// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWallet2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 4.333A2.333 2.333 0 0 1 4.333 2h5.52c.818 0 1.48.663 1.48 1.48v1.853h.834c1.012 0 1.833.821 1.833 1.834v5C14 13.179 13.18 14 12.167 14H4.5A2.5 2.5 0 0 1 2 11.5zm1 1.733V11.5A1.5 1.5 0 0 0 4.5 13h7.667c.46 0 .833-.373.833-.833v-5a.833.833 0 0 0-.833-.834H4a2 2 0 0 1-1-.267m0-1.733a1 1 0 0 0 1 1h6.333V3.48a.48.48 0 0 0-.48-.48h-5.52C3.597 3 3 3.597 3 4.333"
        clipRule="evenodd"
      />
      <Path d="M10.667 9.667a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0" />
      <Path
        fillRule="evenodd"
        d="M11.001 9.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m-.666.333a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWallet2Outline.displayName = 'Wallet2Outline';
const Memo = memo(SvgWallet2Outline);
export default Memo;
