// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettings1SwitchPreferencesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 8a4 4 0 0 1 4-4h6.667a4 4 0 1 1 0 8H4.668a4 4 0 0 1-4-4m10.667 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6M8.689 5a4 4 0 0 0-1.354 3 4 4 0 0 0 1.354 3H4.668a3 3 0 1 1 0-6z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettings1SwitchPreferencesOutline.displayName = 'Settings1SwitchPreferencesOutline';
const Memo = memo(SvgSettings1SwitchPreferencesOutline);
export default Memo;
