// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble2MessageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.336 3.833C1.336 2.821 2.156 2 3.169 2h9.667c1.012 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.834-1.833 1.834H8.13l-3.22 1.771A.5.5 0 0 1 4.168 14v-1.333h-1a1.833 1.833 0 0 1-1.833-1.834zM3.169 3a.833.833 0 0 0-.833.833v7c0 .46.373.834.833.834h1.5a.5.5 0 0 1 .5.5v.987l2.593-1.425a.5.5 0 0 1 .24-.062h4.834c.46 0 .833-.373.833-.834v-7A.833.833 0 0 0 12.836 3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble2MessageOutline.displayName = 'Bubble2MessageOutline';
const Memo = memo(SvgBubble2MessageOutline);
export default Memo;
