// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHandymanWrenchOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.673 13.333c-.303 0-.607-.113-.844-.34l-.455-.434A1.2 1.2 0 0 1 2 11.69c0-.33.123-.637.355-.869l4.17-4.155a3.459 3.459 0 0 1 .507-3.291c.4-.52.93-.916 1.546-1.148a3.5 3.5 0 0 1 1.915-.16c.18.052.312.127.426.226.114.1.2.227.26.364a.41.41 0 0 1-.085.444L9.47 4.72a.4.4 0 0 0-.114.278.42.42 0 0 0 .114.283l.597.534a.412.412 0 0 0 .607.024l1.63-1.625a.41.41 0 0 1 .497-.061 1.01 1.01 0 0 1 .47.684 3.47 3.47 0 0 1-1.294 3.414 3.46 3.46 0 0 1-1.768.709c-.488.052-.986 0-1.455-.151l-4.222 4.17c-.237.235-.55.353-.858.353M9.824 2.808a2.7 2.7 0 0 0-2.142 1.053 2.64 2.64 0 0 0-.535 1.37 2.7 2.7 0 0 0 .232 1.36.4.4 0 0 1-.08.453l-4.365 4.349a.41.41 0 0 0 .004.58l.455.435a.413.413 0 0 0 .569 0l4.407-4.354a.41.41 0 0 1 .441-.085c.412.17.867.236 1.313.189.493-.052.962-.24 1.36-.543.393-.302.697-.708.872-1.171.156-.41.209-.85.152-1.284l-1.251 1.246a1.21 1.21 0 0 1-1.346.27 1.3 1.3 0 0 1-.403-.275l-.597-.528a1.23 1.23 0 0 1-.37-.87c0-.32.123-.623.346-.85l1.318-1.312a2.6 2.6 0 0 0-.384-.028z" />
    </Svg>
  );
};
SvgHandymanWrenchOff.displayName = 'HandymanWrenchOff';
const Memo = memo(SvgHandymanWrenchOff);
export default Memo;
