// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMoneyBillDollarEuroOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.501 4a.833.833 0 0 0-.833.833v6.334c0 .46.373.833.833.833h11c.46 0 .834-.373.834-.833V4.833A.833.833 0 0 0 13.5 4zm-1.833.833C.668 3.821 1.488 3 2.501 3h11c1.013 0 1.834.82 1.834 1.833v6.334c0 1.012-.821 1.833-1.834 1.833h-11a1.833 1.833 0 0 1-1.833-1.833zm2 .667a.5.5 0 0 1 .5-.5h.667a.5.5 0 0 1 0 1h-.667a.5.5 0 0 1-.5-.5m5.333 1.667a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666M6.168 8a1.833 1.833 0 1 1 3.667 0 1.833 1.833 0 0 1-3.667 0m5.5 2.5a.5.5 0 0 1 .5-.5h.667a.5.5 0 1 1 0 1h-.667a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMoneyBillDollarEuroOutline.displayName = 'MoneyBillDollarEuroOutline';
const Memo = memo(SvgMoneyBillDollarEuroOutline);
export default Memo;
