// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEducationSchoolOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.85 3.362a.42.42 0 0 1 .305 0l6.242 2.42a.429.429 0 0 1-.015.805L8.14 8.74a.42.42 0 0 1-.275 0L1.624 6.587a.428.428 0 0 1-.015-.805zM3.007 6.158 8.003 7.88l4.995-1.722-4.995-1.937z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M14.238 5.905c.235 0 .426.192.426.428v3.429c0 .237-.191.428-.426.428a.427.427 0 0 1-.425-.428V6.333c0-.236.19-.428.425-.428M4.597 6.762c.235 0 .426.192.426.428v3.53c0 .105.023.201.061.275a.3.3 0 0 0 .107.124l2.759 1.34.013.006c.028.015.05.015.078 0l.013-.007 2.759-1.339a.3.3 0 0 0 .106-.124.6.6 0 0 0 .061-.274V7.19c0-.236.19-.428.426-.428.235 0 .425.192.425.428v3.527c.002.232-.05.466-.155.671a1.13 1.13 0 0 1-.486.5l-2.76 1.34a.92.92 0 0 1-.855 0l-2.76-1.34-.012-.006c-.21-.11-.37-.288-.475-.493a1.46 1.46 0 0 1-.156-.671v-.002l.425.003h-.425V7.19c0-.236.19-.428.425-.428"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEducationSchoolOff.displayName = 'EducationSchoolOff';
const Memo = memo(SvgEducationSchoolOff);
export default Memo;
