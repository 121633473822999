// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowTriangleLeftOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.685 2.862a1.83 1.83 0 0 1 2.65 1.637V11.5a1.83 1.83 0 0 1-2.65 1.637l-7.005-3.5c-1.35-.675-1.35-2.6 0-3.275zm1.65 1.637a.83.83 0 0 0-1.203-.743L4.127 7.257a.83.83 0 0 0 0 1.486l7.005 3.5a.83.83 0 0 0 1.203-.742z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowTriangleLeftOutline.displayName = 'ArrowTriangleLeftOutline';
const Memo = memo(SvgArrowTriangleLeftOutline);
export default Memo;
