// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWatch2ClockTimeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.528 1.667a.833.833 0 0 0-.828.741l-.092.825a5.3 5.3 0 0 1 2.393-.566c.86 0 1.673.204 2.393.566l-.091-.825a.833.833 0 0 0-.828-.741zm4.953 2.291-.184-1.66A1.833 1.833 0 0 0 9.475.667H6.528c-.934 0-1.719.702-1.822 1.63L4.52 3.959A5.32 5.32 0 0 0 2.668 8c0 1.616.718 3.064 1.853 4.042l.185 1.66c.103.929.888 1.631 1.822 1.631h2.947c.934 0 1.719-.702 1.822-1.63l.184-1.661A5.32 5.32 0 0 0 13.335 8a5.32 5.32 0 0 0-1.854-4.042m-1.087 8.81a5.3 5.3 0 0 1-2.393.565c-.86 0-1.673-.203-2.393-.565l.092.824a.833.833 0 0 0 .828.741h2.947c.424 0 .78-.319.828-.74zM8.001 3.667A4.332 4.332 0 0 0 3.668 8a4.333 4.333 0 1 0 4.333-4.333m0 1.666a.5.5 0 0 1 .5.5v1.96l.854.854a.5.5 0 1 1-.707.707l-1-1A.5.5 0 0 1 7.5 8V5.833a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWatch2ClockTimeOutline.displayName = 'Watch2ClockTimeOutline';
const Memo = memo(SvgWatch2ClockTimeOutline);
export default Memo;
