// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCornerUpLeftFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.185 2.98a.5.5 0 0 1 0 .707L3.372 5.5h7.46a3.167 3.167 0 0 1 3.167 3.167v4.167a.5.5 0 0 1-1 0V8.667c0-1.197-.97-2.167-2.167-2.167h-7.46l1.813 1.813a.5.5 0 1 1-.707.707L2.283 6.825a1.167 1.167 0 0 1 0-1.65L4.478 2.98a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCornerUpLeftFill.displayName = 'CornerUpLeftFill';
const Memo = memo(SvgCornerUpLeftFill);
export default Memo;
