// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAi2Stars180SparklesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G
        fill="#0D1011"
        clipPath="url(#a)"
      >
        <Path d="M5.501 10.833a.5.5 0 1 0 1 0c0-1.617.358-2.667 1.012-3.321S9.217 6.5 10.835 6.5a.5.5 0 1 0 0-1c-1.618 0-2.668-.357-3.322-1.012C6.859 3.834 6.5 2.784 6.5 1.167a.5.5 0 1 0-1 0c0 1.617-.357 2.667-1.011 3.321C3.835 5.143 2.785 5.5 1.168 5.5a.5.5 0 0 0 0 1c1.617 0 2.667.357 3.322 1.012.654.654 1.011 1.704 1.011 3.321M11.168 14.833a.5.5 0 1 0 1 0c0-1.038.23-1.672.612-2.054.383-.382 1.016-.612 2.055-.612a.5.5 0 1 0 0-1c-1.039 0-1.673-.23-2.055-.613-.382-.382-.612-1.016-.612-2.054a.5.5 0 0 0-1 0c0 1.038-.23 1.672-.612 2.054-.383.383-1.016.613-2.055.613a.5.5 0 1 0 0 1c1.039 0 1.672.23 2.055.612.382.382.612 1.016.612 2.054" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgAi2Stars180SparklesFill.displayName = 'Ai2Stars180SparklesFill';
const Memo = memo(SvgAi2Stars180SparklesFill);
export default Memo;
