// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettingsSliderHorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.713 4.167a2.667 2.667 0 1 1 0 1H2.5a.5.5 0 0 1 0-1zM11.333 3a1.666 1.666 0 1 0 0 3.332 1.666 1.666 0 0 0 0-3.332M3.38 10.833a2.667 2.667 0 0 1 5.24 0h4.88a.5.5 0 0 1 0 1H8.62a2.667 2.667 0 0 1-5.24 0H2.5a.5.5 0 1 1 0-1zM6 9.667a1.666 1.666 0 1 0-.001 3.332A1.666 1.666 0 0 0 6 9.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettingsSliderHorOutline.displayName = 'SettingsSliderHorOutline';
const Memo = memo(SvgSettingsSliderHorOutline);
export default Memo;
