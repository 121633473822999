// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSunsetOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.997 3a5 5 0 0 0-5 5v.167a.5.5 0 0 1-1 0V8a6 6 0 1 1 12 0v.167a.5.5 0 0 1-1 0V8a5 5 0 0 0-5-5M.664 10.833a.5.5 0 0 1 .5-.5h13.667a.5.5 0 1 1 0 1H1.164a.5.5 0 0 1-.5-.5M3.997 13.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSunsetOutline.displayName = 'SunsetOutline';
const Memo = memo(SvgSunsetOutline);
export default Memo;
