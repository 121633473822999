// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMovieOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5 6.001a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6m.509.843a1.8 1.8 0 0 1 1.063-.328h3.908c.382 0 .765.11 1.064.328.244.179.42.425.48.706l1.232-.3a.65.65 0 0 1 .511.088c.146.1.232.253.232.417v2.86a.5.5 0 0 1-.232.417.64.64 0 0 1-.511.089l-1.232-.3a1.2 1.2 0 0 1-.48.705 1.8 1.8 0 0 1-1.064.328h-.073l.42 1.494c.078.281-.114.566-.428.636-.315.07-.634-.1-.713-.381l-.451-1.61a.5.5 0 0 1-.018-.139h-1.53a.5.5 0 0 1-.062.247l-.901 1.609c-.146.259-.499.364-.789.234s-.408-.444-.263-.703l.78-1.39c-.341-.021-.676-.13-.943-.325-.302-.22-.501-.545-.501-.91V8.754c0-.366.2-.69.5-.911m1.063.72c-.136 0-.25.04-.318.09-.064.048-.07.088-.07.1v2.861c0 .014.006.053.07.1.068.05.182.09.318.09h3.908c.137 0 .25-.04.318-.09.065-.047.071-.086.071-.1v-.477c0-.163.086-.317.232-.416a.65.65 0 0 1 .511-.09l1.211.296V9.442l-1.21.295a.65.65 0 0 1-.512-.089.5.5 0 0 1-.232-.416v-.477c0-.013-.006-.053-.07-.1a.55.55 0 0 0-.319-.09zM12 5.001a1 1 0 1 1-2 0 1 1 0 0 1 2 0m1 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMovieOutline.displayName = 'MovieOutline';
const Memo = memo(SvgMovieOutline);
export default Memo;
