// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAlignRight2ArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.5 2.667a.5.5 0 0 1 .5.5v9.667a.5.5 0 0 1-1 0V8.362c-.055.17-.15.329-.284.463l-2.362 2.362a.5.5 0 1 1-.708-.707l1.98-1.98H2.5a.5.5 0 1 1 0-1h9.126l-1.98-1.98a.5.5 0 1 1 .708-.707l2.362 2.362c.134.135.229.294.284.464V3.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAlignRight2ArrowOutline.displayName = 'AlignRight2ArrowOutline';
const Memo = memo(SvgAlignRight2ArrowOutline);
export default Memo;
