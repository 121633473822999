// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble5AnnotationMessageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833V11.5c0 1.012-.82 1.833-1.833 1.833h-6.81a.17.17 0 0 0-.087.026l-2.505 1.565A.5.5 0 0 1 2 14.5zM3.833 3A.833.833 0 0 0 3 3.833v9.765l1.74-1.087c.185-.116.4-.178.618-.178h6.809c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3z"
        clipRule="evenodd"
      />
      <Path d="M5.001 8a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M8.001 8a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M11.001 8a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667" />
      <Path
        fillRule="evenodd"
        d="M4.999 8.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m3 0a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m3 0a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m-6-.333a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667m3 0a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667m2.666-.333a.333.333 0 1 0 .667 0 .333.333 0 0 0-.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble5AnnotationMessageOutline.displayName = 'Bubble5AnnotationMessageOutline';
const Memo = memo(SvgBubble5AnnotationMessageOutline);
export default Memo;
