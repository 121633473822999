// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFenceOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.592 2.773a.845.845 0 0 1 1.125.29l.893 1.463c.039.063.06.136.06.21v1.229h1.666V4.736c0-.074.02-.147.06-.21l.892-1.462a.83.83 0 0 1 .715-.397.85.85 0 0 1 .714.397l.893 1.462c.039.063.06.136.06.21v1.229h1.666V4.736c0-.074.02-.147.06-.21l.892-1.462a.83.83 0 0 1 .715-.397.85.85 0 0 1 .714.397l.893 1.462c.039.063.06.136.06.21v7.37c0 .325-.132.637-.367.868s-.552.36-.884.36h-.833c-.332 0-.65-.13-.884-.36a1.22 1.22 0 0 1-.366-.869v-.41H9.669v.41c0 .326-.131.638-.366.869s-.552.36-.884.36h-.833c-.332 0-.65-.13-.884-.36a1.22 1.22 0 0 1-.366-.869v-.41H4.669v.41c0 .326-.131.638-.366.869s-.552.36-.884.36h-.833c-.332 0-.65-.13-.884-.36a1.22 1.22 0 0 1-.366-.869V4.736c0-.074.02-.147.06-.21l.892-1.462a.8.8 0 0 1 .304-.291m7.077 8.104h1.667V6.783H9.669zm2.5.409v.819c0 .109.044.213.122.29a.42.42 0 0 0 .295.12h.833a.42.42 0 0 0 .295-.12.4.4 0 0 0 .122-.29V4.85l-.833-1.365-.834 1.365v6.436m-3.333 0V4.85l-.833-1.365-.834 1.365v7.255c0 .109.044.213.122.29a.42.42 0 0 0 .295.12h.833a.42.42 0 0 0 .295-.12.4.4 0 0 0 .122-.29zm-4.167-.409h1.667V6.783H4.669zM3.003 3.485 2.169 4.85v7.255c0 .109.044.213.122.29a.42.42 0 0 0 .295.12h.833a.42.42 0 0 0 .295-.12.4.4 0 0 0 .122-.29V4.85z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFenceOff.displayName = 'FenceOff';
const Memo = memo(SvgFenceOff);
export default Memo;
