// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRotateClockwiseRotateOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.984 3A5 5 0 1 0 12.7 9.667a.5.5 0 1 1 .943.333A6.002 6.002 0 0 1 1.984 8a6 6 0 0 1 6-6c1.866 0 3.173.736 4.348 1.938V2.667a.5.5 0 0 1 1 0v2.166a1 1 0 0 1-1 1h-2.167a.5.5 0 0 1 0-1h1.638C10.703 3.642 9.6 3 7.984 3"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRotateClockwiseRotateOutline.displayName = 'RotateClockwiseRotateOutline';
const Memo = memo(SvgRotateClockwiseRotateOutline);
export default Memo;
