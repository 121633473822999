// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDrowningFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.621 13.983a2.03 2.03 0 0 1-1.047-.65.49.49 0 0 0-.382-.168.49.49 0 0 0-.376.186c-.162.209-.37.377-.607.493a2 2 0 0 1-.648.186q-.033-.002-.075-.012h-.018V11.31L13.8 8.716c.196-.22.312-.498.335-.794a1.33 1.33 0 0 0-1.03-1.392 1.33 1.33 0 0 0-.971.156l-.434.261.55-3.84a1.35 1.35 0 0 0-.215-.94 1.32 1.32 0 0 0-.787-.55 1.333 1.333 0 0 0-1.041.168 1.4 1.4 0 0 0-.278.232 1.35 1.35 0 0 0-.353-.656c-.48-.481-1.348-.481-1.828 0a1.3 1.3 0 0 0-.347.644 1.34 1.34 0 0 0-.77-.418 1.2 1.2 0 0 0-.526.024 1.3 1.3 0 0 0-.793.574c-.179.29-.243.632-.179.963l.012.052a1.22 1.22 0 0 0-.978-.255 1.2 1.2 0 0 0-.48.191 1.239 1.239 0 0 0-.457 1.49L4.433 7.65c.093.226.139.47.139.714v1.142c0 .447.104.894.307 1.3.162.324.387.614.653.858v2.007a1.5 1.5 0 0 1-.26.168 2 2 0 0 1-.648.186q-.033-.002-.075-.012a2 2 0 0 1-.764-.197 1.9 1.9 0 0 1-.619-.487.48.48 0 0 0-.382-.174.49.49 0 0 0-.376.185c-.26.325-.625.557-1.03.65a.49.49 0 0 0-.364.592.49.49 0 0 0 .59.365c.44-.104.856-.313 1.198-.609.167.14.352.255.555.354.324.157.665.255 1.018.284.052.018.11.03.168.024.405-.012.81-.11 1.169-.296.202-.099.387-.227.56-.372q.253.208.556.354c.324.157.666.255 1.019.284a.4.4 0 0 0 .167.024c.405-.012.81-.11 1.169-.296.202-.099.388-.226.561-.371q.252.207.556.354c.324.156.665.255 1.018.284a.5.5 0 0 0 .156.023h.012c.405-.012.81-.11 1.168-.296.203-.099.388-.226.562-.371.34.278.74.481 1.174.586a.49.49 0 0 0 .596-.36.49.49 0 0 0-.359-.597z" />
    </Svg>
  );
};
SvgDrowningFill.displayName = 'DrowningFill';
const Memo = memo(SvgDrowningFill);
export default Memo;
