// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble5AnnotationMessageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14 3.833C14 2.821 13.18 2 12.167 2H3.833C2.821 2 2 2.82 2 3.833V14.5a.5.5 0 0 0 .765.424l2.505-1.565a.17.17 0 0 1 .088-.026h6.809c1.012 0 1.833-.82 1.833-1.833zM4.332 7.667a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0m3 0a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0m3.667.666a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble5AnnotationMessageFill.displayName = 'Bubble5AnnotationMessageFill';
const Memo = memo(SvgBubble5AnnotationMessageFill);
export default Memo;
