// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHand5FingerSelectOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.835 2.333a.5.5 0 0 0-.5.5v4.5a.5.5 0 0 1-1 0V3.5a.5.5 0 0 0-1 0v3.912a.5.5 0 0 1-.877.328l-.727-.832a.656.656 0 0 0-1.014.032l1.538 4.037a4.176 4.176 0 0 0 8.08-1.487V4.833a.5.5 0 1 0-1 0V8a.5.5 0 1 1-1 0V3.5a.5.5 0 1 0-1 0v4.167a.5.5 0 0 1-1 0V2.833a.5.5 0 0 0-.5-.5m1.337-.18a1.5 1.5 0 0 0-2.675 0A1.5 1.5 0 0 0 4.335 3.5v2.6a1.656 1.656 0 0 0-2.63.615.5.5 0 0 0-.004.367l1.62 4.252A5.176 5.176 0 0 0 13.335 9.49V4.833a1.5 1.5 0 0 0-2.003-1.414 1.5 1.5 0 0 0-2.16-1.265"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHand5FingerSelectOutline.displayName = 'Hand5FingerSelectOutline';
const Memo = memo(SvgHand5FingerSelectOutline);
export default Memo;
