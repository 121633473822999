// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAccessibilityEyeA11YOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.48 1.48a.5.5 0 0 1 .708 0l4.314 4.314a2.668 2.668 0 0 1 3.705 3.705l4.314 4.314a.5.5 0 0 1-.707.707l-2.233-2.232a7 7 0 0 1-2.122.88c-2.94.675-6.02-.713-8.033-3.974l-.02-.032c-.156-.254-.283-.46-.351-.76a2.1 2.1 0 0 1 0-.804c.067-.3.195-.506.351-.76l.02-.032q.385-.624.82-1.156.63-.774 1.347-1.351L1.48 2.187a.5.5 0 0 1 0-.707m2.824 3.53a8 8 0 0 0-.99.93L9.5 12.124a6 6 0 0 0 1.351-.567zm3.982 7.315L2.683 6.723q-.21.29-.406.608c-.18.293-.219.363-.246.486-.02.09-.02.277 0 .366.027.122.066.193.246.486 1.6 2.591 3.842 3.768 6.009 3.656m1.193-3.554a1.667 1.667 0 0 0-2.25-2.25zm4.246-1.44C11.801 4.212 8.943 3.139 6.4 3.905a.5.5 0 1 1-.288-.958c3.06-.921 6.351.434 8.464 3.859l.02.031c.157.254.284.46.351.76.053.234.053.572 0 .805-.067.3-.194.506-.35.76q-.01.014-.02.032a10.7 10.7 0 0 1-1.063 1.444.5.5 0 0 1-.751-.66q.511-.58.963-1.31c.18-.292.218-.363.246-.485.02-.09.02-.277 0-.366-.028-.123-.066-.193-.247-.486"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAccessibilityEyeA11YOutline.displayName = 'AccessibilityEyeA11YOutline';
const Memo = memo(SvgAccessibilityEyeA11YOutline);
export default Memo;
