// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble6AnnotationMessageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2c2.068 0 3.818.537 5.06 1.578C14.315 4.628 15 6.148 15 8s-.686 3.373-1.94 4.422C11.819 13.463 10.069 14 8 14c-1.129 0-2.38-.108-3.49-.615q-.022.014-.045.027c-.186.107-.452.241-.77.352-.627.219-1.521.366-2.41-.057a.5.5 0 0 1-.184-.753c.962-1.275.676-2.054.664-2.086q.001 0-.004-.01l-.005-.013-.008-.018a15 15 0 0 1-.405-1.11C1.176 9.196 1 8.53 1 8c0-1.851.686-3.372 1.94-4.422C4.181 2.537 5.931 2 8 2M4.333 8a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0m3 0a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0M11 8.667a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble6AnnotationMessageFill.displayName = 'Bubble6AnnotationMessageFill';
const Memo = memo(SvgBubble6AnnotationMessageFill);
export default Memo;
