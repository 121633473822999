// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNotificationOffBellActivityOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.154 2.182a5.197 5.197 0 0 1 7.976 3.521l.775 4.8a.5.5 0 1 1-.987.16l-.775-4.801a4.197 4.197 0 0 0-6.44-2.844.5.5 0 1 1-.549-.836m-3.508-.536a.5.5 0 0 1 .708 0l12 12a.5.5 0 0 1-.708.708l-1.686-1.687h-.965c-.566 1.166-1.675 2-2.995 2s-2.429-.834-2.995-2H3.9a1.833 1.833 0 0 1-1.81-2.126l.78-4.838a5.2 5.2 0 0 1 .532-1.595L1.646 2.354a.5.5 0 0 1 0-.708M4.15 4.857a4.2 4.2 0 0 0-.293 1.005l-.78 4.839a.833.833 0 0 0 .822.966h7.061zm5.686 7.81H6.164c.452.625 1.12 1 1.836 1s1.384-.375 1.836-1"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNotificationOffBellActivityOutline.displayName = 'NotificationOffBellActivityOutline';
const Memo = memo(SvgNotificationOffBellActivityOutline);
export default Memo;
