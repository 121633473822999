// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRenovatedFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.16 1h8.152a1 1 0 0 1 .19.02c.115.018.274.056.439.136.166.08.346.208.484.408.14.203.217.453.217.749v.875h.906c.25 0 .452.196.452.437a.445.445 0 0 1-.452.438h-.906v.875h-.451l.451.001v.023l-.003.044a1.479 1.479 0 0 1-.158.564 1.2 1.2 0 0 1-.421.47 1.4 1.4 0 0 1-.773.21H4.158l.001-.437v.437h-.025a1 1 0 0 1-.19-.02 1.6 1.6 0 0 1-.438-.136 1.23 1.23 0 0 1-.485-.408 1.3 1.3 0 0 1-.216-.748v-.875h-.45a.46.46 0 0 0-.32.128.43.43 0 0 0-.132.31v2.187a.43.43 0 0 0 .132.309c.085.082.2.128.32.128h4.967c.36 0 .704.138.958.385.254.246.397.58.397.928v.494c.308.078.592.234.82.456.34.328.53.773.53 1.237v3.063c0 .348-.143.681-.397.928a1.38 1.38 0 0 1-.958.384h-.903c-.36 0-.704-.138-.958-.384a1.3 1.3 0 0 1-.397-.928v-3.063c0-.464.19-.91.53-1.237.23-.224.518-.382.83-.459v-.491a.43.43 0 0 0-.133-.31.46.46 0 0 0-.32-.128H2.356c-.36 0-.704-.138-.958-.384A1.3 1.3 0 0 1 1 6.688V4.5c0-.348.143-.682.397-.928s.598-.384.958-.384h.45v-.876h.451l-.451-.001v-.023a1 1 0 0 1 .02-.184c.019-.11.058-.265.14-.425s.215-.336.422-.469A1.4 1.4 0 0 1 4.159 1"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRenovatedFill.displayName = 'RenovatedFill';
const Memo = memo(SvgRenovatedFill);
export default Memo;
