// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPatioOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.798 2.22a.42.42 0 0 1 .408 0l.039.02 5.588 2.427H2.172L7.76 2.239zm.204-.887c-.206 0-.41.052-.592.15L1.812 3.914l-.026.013A.833.833 0 0 0 2.17 5.5h5.417v2.917h-2.5a.417.417 0 0 0 0 .833h2.5v5a.417.417 0 0 0 .833 0v-5h2.5a.417.417 0 0 0 0-.833h-2.5V5.5h5.417a.833.833 0 0 0 .357-1.586L8.594 1.483a1.25 1.25 0 0 0-.592-.15M2.08 7.177a.417.417 0 0 1 .497.317l.761 3.424h1.332a1.25 1.25 0 0 1 1.25 1.25v2.083a.417.417 0 0 1-.833 0v-2.083a.417.417 0 0 0-.417-.417H3.317l-.747 2.615a.417.417 0 1 1-.801-.23l.804-2.815-.81-3.646a.417.417 0 0 1 .316-.498m12.164.498a.417.417 0 0 0-.814-.181l-.76 3.424h-1.333a1.25 1.25 0 0 0-1.25 1.25v2.083a.417.417 0 1 0 .833 0v-2.083a.416.416 0 0 1 .417-.417h1.352l.747 2.615a.417.417 0 1 0 .802-.23l-.805-2.815z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPatioOutline.displayName = 'PatioOutline';
const Memo = memo(SvgPatioOutline);
export default Memo;
