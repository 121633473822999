// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEmail2NotificationBadgeEnvelopeMailOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M13.332 2.333a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334M10.665 4A2.667 2.667 0 1 1 16 4a2.667 2.667 0 0 1-5.334 0m-7.5-.333a.833.833 0 0 0-.833.833v1.293a9.46 9.46 0 0 0 5.666 1.874c.994 0 1.952-.153 2.85-.435a.5.5 0 1 1 .3.954c-.995.312-2.053.48-3.15.48-2.086 0-4.031-.608-5.666-1.658V11.5c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833V8.167a.5.5 0 1 1 1 0V11.5c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5v-7c0-1.013.82-1.833 1.833-1.833h6a.5.5 0 1 1 0 1z"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgEmail2NotificationBadgeEnvelopeMailOutline.displayName =
  'Email2NotificationBadgeEnvelopeMailOutline';
const Memo = memo(SvgEmail2NotificationBadgeEnvelopeMailOutline);
export default Memo;
