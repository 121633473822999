// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLink3ChainFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.148 3.853a3.25 3.25 0 0 0-4.6 0l-.58.58a.45.45 0 1 1-.636-.637l.58-.58a4.152 4.152 0 0 1 5.872 5.872l-.581.581a.45.45 0 0 1-.637-.636l.582-.581a3.25 3.25 0 0 0 0-4.6m-2.33 2.329a.45.45 0 0 1 0 .636l-3 3a.45.45 0 0 1-.636-.636l3-3a.45.45 0 0 1 .636 0m-5.382.147a.45.45 0 0 1 0 .636l-.583.583a3.252 3.252 0 0 0 4.599 4.6l.578-.58a.45.45 0 0 1 .637.637l-.579.579a4.152 4.152 0 0 1-5.872-5.872l.583-.583a.45.45 0 0 1 .637 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLink3ChainFill.displayName = 'Link3ChainFill';
const Memo = memo(SvgLink3ChainFill);
export default Memo;
