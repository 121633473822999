// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAiTextTextGenerationOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5M11.333 6.5a.5.5 0 0 1 .447.276l.926 1.851 1.85.926a.5.5 0 0 1 0 .894l-1.85.926-.925 1.85a.5.5 0 0 1-.895 0l-.925-1.85-1.851-.926a.5.5 0 0 1 0-.894l1.85-.926.926-1.85a.5.5 0 0 1 .447-.277m0 1.618-.553 1.106a.5.5 0 0 1-.223.223L9.45 10l1.106.553a.5.5 0 0 1 .223.223l.553 1.106.553-1.106a.5.5 0 0 1 .224-.223L13.215 10l-1.105-.553a.5.5 0 0 1-.224-.223zM2 8a.5.5 0 0 1 .5-.5h3.667a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8m0 4.167a.5.5 0 0 1 .5-.5h2.333a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAiTextTextGenerationOutline.displayName = 'AiTextTextGenerationOutline';
const Memo = memo(SvgAiTextTextGenerationOutline);
export default Memo;
