// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBackwardDeleteRemoveKeyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.182 3.533a1.83 1.83 0 0 1 1.558-.866h8.092c1.012 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H4.74a1.83 1.83 0 0 1-1.558-.866l-2.172-3.5a1.83 1.83 0 0 1 0-1.934zm1.558.134a.83.83 0 0 0-.708.394L1.86 7.56a.83.83 0 0 0 0 .878l2.172 3.5c.152.245.42.394.708.394h8.092c.46 0 .833-.373.833-.833v-7a.833.833 0 0 0-.833-.833zm1.905 2.48a.5.5 0 0 1 .707 0l1.146 1.146 1.147-1.147a.5.5 0 0 1 .707.708L9.206 8l1.146 1.146a.5.5 0 0 1-.707.708L8.498 8.707 7.352 9.854a.5.5 0 0 1-.707-.708L7.79 8 6.645 6.854a.5.5 0 0 1 0-.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBackwardDeleteRemoveKeyOutline.displayName = 'BackwardDeleteRemoveKeyOutline';
const Memo = memo(SvgBackwardDeleteRemoveKeyOutline);
export default Memo;
