// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVideoClipFilmMovieFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM3.833 3A.833.833 0 0 0 3 3.833v.917h1.667V3zm7.5 0v1.75H13v-.917A.833.833 0 0 0 12.167 3zM13 5.75h-1.667V7.5H13zm0 2.75h-1.667v2.056H13zm0 3.056h-1.667V13h.834c.46 0 .833-.373.833-.833zM10.333 8.5v-1H5.667v1zM4.667 13v-1.75H3v.917c0 .46.373.833.833.833zM3 10.25h1.667V8.5H3zM3 7.5h1.667V5.75H3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVideoClipFilmMovieFill.displayName = 'VideoClipFilmMovieFill';
const Memo = memo(SvgVideoClipFilmMovieFill);
export default Memo;
