// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgImageWallpaperLandscapeOsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.165 2.667c-1.012 0-1.833.82-1.833 1.833V8.77q.305-.165.708-.39l.378-.21c.54-.299 1.16-.636 1.801-.953.64-.317 1.311-.618 1.95-.84C6.801 6.156 7.437 6 8 6c.561 0 1.197.156 1.83.376.638.222 1.308.523 1.95.84.64.317 1.261.654 1.8.953l.379.21q.403.225.707.39V4.5c0-1.012-.82-1.833-1.833-1.833z" />
      <Path d="m14.665 9.895-.227-.116c-.26-.133-.599-.322-.98-.534l-.363-.201a43 43 0 0 0-1.76-.932A17 17 0 0 0 9.5 7.32c-.477-.166-.902-.272-1.257-.307a8 8 0 0 0-.569.938c-.16.317-.288.64-.34.924-.053.29-.016.47.054.575.036.055.144.14.499.166.283.02.609-.002.98-.028l.245-.016c.44-.028.952-.051 1.387.057.226.055.458.152.655.323.203.176.344.409.413.69.12.483.028.905-.217 1.249-.226.317-.559.535-.86.698-.21.113-.448.22-.659.315l-.247.112c-.267.125-.442.225-.544.317h3.792c1.013 0 1.833-.82 1.833-1.833z" />
      <Path d="M7.793 13.333c.126-.209.306-.474.519-.687.236-.235.566-.404.848-.536l.302-.137c.194-.087.375-.169.553-.264.261-.141.43-.27.52-.399.072-.1.113-.22.062-.428a.3.3 0 0 0-.098-.174.6.6 0 0 0-.24-.109c-.263-.065-.626-.059-1.083-.03l-.214.015c-.372.027-.787.056-1.149.03-.422-.032-.953-.149-1.26-.612-.272-.412-.278-.895-.202-1.307.076-.417.249-.837.43-1.196q.087-.168.175-.324-.22.062-.458.145c-.585.204-1.214.485-1.836.792-.62.307-1.224.635-1.76.932l-.362.201c-.382.212-.72.4-.98.534l-.228.116V11.5c0 1.013.82 1.833 1.833 1.833z" />
    </Svg>
  );
};
SvgImageWallpaperLandscapeOsFill.displayName = 'ImageWallpaperLandscapeOsFill';
const Memo = memo(SvgImageWallpaperLandscapeOsFill);
export default Memo;
