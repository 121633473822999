// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgYenCurrencyMoneyCoinOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m4.004-3.043a.5.5 0 0 1 .706.047l1.957 2.237 1.957-2.237a.5.5 0 1 1 .752.659l-2.19 2.504h.98a.5.5 0 1 1 0 1h-1V11.5a.5.5 0 1 1-1 0V9.167h-1a.5.5 0 0 1 0-1h.982L5.29 5.663a.5.5 0 0 1 .046-.706"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgYenCurrencyMoneyCoinOutline.displayName = 'YenCurrencyMoneyCoinOutline';
const Memo = memo(SvgYenCurrencyMoneyCoinOutline);
export default Memo;
