// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWalletFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.332 2a2.333 2.333 0 0 0-2.333 2.333v4.07l1.244-.727a1.5 1.5 0 0 1 1.512 0L6.92 8.94a1.5 1.5 0 0 1 .744 1.296V14h4.5C13.178 14 14 13.18 14 12.167v-5c0-1.013-.821-1.834-1.834-1.834h-.833V3.48A1.48 1.48 0 0 0 9.852 2zm6 3.333V3.48a.48.48 0 0 0-.48-.48h-5.52c-.736 0-1.333.597-1.333 1.333a1 1 0 0 0 1 1zm0 5a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M3.747 8.54a.5.5 0 0 1 .504 0l2.166 1.264a.5.5 0 0 1 .248.432v2.528a.5.5 0 0 1-.248.432L4.251 14.46a.5.5 0 0 1-.504 0L1.58 13.196a.5.5 0 0 1-.248-.432v-2.528a.5.5 0 0 1 .248-.432zm-1.415 1.983v1.954l1.667.972 1.666-.972v-1.954L4 9.551z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWalletFill.displayName = 'WalletFill';
const Memo = memo(SvgWalletFill);
export default Memo;
