// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHdFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.163 9.52H9.47V6.445h.693c.866 0 1.355.54 1.355 1.524 0 1.016-.476 1.55-1.355 1.55" />
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm2.292 5.384c0 .322.182.511.491.511.31 0 .492-.189.492-.511v-1.53h2.116v1.53c0 .322.182.511.491.511.31 0 .492-.189.492-.511V6.085c0-.325-.183-.514-.492-.514-.31 0-.491.189-.491.514v1.458H4.607V6.085c0-.325-.183-.514-.492-.514s-.491.189-.491.514zm4.863-.065c0 .325.182.514.492.514h1.302c1.41 0 2.24-.875 2.24-2.366s-.83-2.331-2.24-2.331H8.979c-.31 0-.492.189-.492.511z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHdFill.displayName = 'HdFill';
const Memo = memo(SvgHdFill);
export default Memo;
