// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudySunCloudsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G
        fill="#0D1011"
        fillRule="evenodd"
        clipPath="url(#a)"
        clipRule="evenodd"
      >
        <Path d="M6.996 5.52a4.6 4.6 0 0 0-1.049-.12c-2.55 0-4.611 2.077-4.611 4.633s2.062 4.634 4.611 4.634h5.139a3.59 3.59 0 0 0 3.583-3.6 3.6 3.6 0 0 0-1.693-3.06c.207-.429.323-.91.323-1.418a3.25 3.25 0 0 0-3.24-3.256c-1.42 0-2.623.915-3.063 2.188m.943.334a4.64 4.64 0 0 1 1.814 1.563.44.44 0 0 0 .459.158 3.6 3.6 0 0 1 1.849.027c.152-.305.238-.648.238-1.013a2.25 2.25 0 0 0-2.24-2.256c-.98 0-1.817.635-2.12 1.52M10.17.667a.5.5 0 0 1 .5.5v.666a.5.5 0 0 1-1 0v-.666a.5.5 0 0 1 .5-.5m4.124 1.708a.5.5 0 0 1 0 .707l-.471.472a.5.5 0 1 1-.707-.707l.471-.472a.5.5 0 0 1 .707 0m-8.25 0a.5.5 0 0 1 .708 0l.471.472a.5.5 0 0 1-.707.707l-.471-.472a.5.5 0 0 1 0-.707M14.337 6.5a.5.5 0 0 1 .5-.5h.667a.5.5 0 0 1 0 1h-.667a.5.5 0 0 1-.5-.5" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgCloudySunCloudsFill.displayName = 'CloudySunCloudsFill';
const Memo = memo(SvgCloudySunCloudsFill);
export default Memo;
