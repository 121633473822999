// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFloorPlanFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.916 11.64h-2.08v.72h2.08z" />
      <Path d="M7.716 8.08h-5.88v2.52h2.6c.28 0 .52.24.52.52v3.04h3.4V8.08z" />
      <Path d="M3.916 12h-2.08v2.12h2.08zM10.158 1.84h-6.08v5.2h6.08z" />
      <Path d="M11.198 7.12c0 .24-.12.52-.28.68-.2.2-.44.28-.68.28h-.8v2.08h4.72v-5.2h-2.96z" />
      <Path d="M8.435 15.4h-6.68q-.48 0-.84-.36t-.36-.84V8q0-.48.36-.84t.84-.36h1.04V1.76q0-.48.36-.84c.24-.2.52-.32.84-.32h6.24q.48 0 .84.36t.36.84v1.96h2.84q.48 0 .84.36t.36.84v5.36q0 .48-.36.84c-.2.2-.52.36-.84.36h-4.68v2.84q0 .48-.36.84c-.2.08-.48.2-.8.2m-6.68-8.16c-.2 0-.4.08-.56.24a.76.76 0 0 0-.2.52v6.24c0 .2.08.4.24.56s.32.24.56.24h6.68c.2 0 .4-.08.56-.24a.8.8 0 0 0 .24-.56V11h5c.2 0 .4-.08.56-.24s.24-.32.24-.56V4.88c0-.2-.08-.4-.24-.56a.73.73 0 0 0-.56-.24h-3.24V1.76c0-.2-.08-.4-.24-.56a.73.73 0 0 0-.56-.24h-6.24c-.2 0-.4.08-.56.24-.12.16-.2.36-.2.56v5.48z" />
      <Path d="M8.438 15.52h-6.68c-.36 0-.68-.12-.92-.4-.24-.24-.4-.56-.4-.92V8c0-.36.12-.68.4-.92s.56-.4.92-.4h.92V1.76c0-.36.12-.68.4-.92.24-.24.56-.4.92-.4h6.24c.36 0 .68.12.92.4.24.24.4.56.4.92v1.8h2.68c.36 0 .68.12.92.4.24.24.4.56.4.92v5.36c0 .36-.12.68-.4.92-.24.24-.6.4-.92.4h-4.48v2.68c0 .36-.12.68-.4.92-.24.24-.56.36-.92.36m-6.68-8.56c-.28 0-.52.12-.72.32s-.28.44-.28.72v6.24c0 .28.12.52.32.72s.44.32.72.32h6.68c.28 0 .52-.12.72-.32s.32-.44.32-.72v-2.96h4.76c.28 0 .52-.12.72-.32s.32-.44.32-.72V4.88c0-.28-.12-.52-.32-.72s-.44-.32-.72-.32h-2.96V1.76c0-.28-.12-.52-.32-.72s-.44-.32-.72-.32h-6.28c-.28 0-.52.12-.72.32s-.32.44-.32.72v5.2zm6.68 8.16h-6.68c-.24 0-.48-.08-.64-.28a.94.94 0 0 1-.28-.64V8c0-.24.08-.48.28-.64.16-.16.4-.28.64-.28h1.32V1.76c0-.24.08-.48.28-.64.16-.16.4-.28.64-.28h6.24c.24 0 .48.08.64.28.16.16.28.4.28.64v2.2h3.08c.24 0 .48.08.64.28.16.16.28.4.28.64v5.36c0 .24-.08.48-.28.64-.16.16-.4.28-.64.28h-4.88v3.08c0 .24-.08.48-.28.64-.16.16-.4.24-.64.24m-6.68-7.76c-.16 0-.32.08-.44.2s-.16.28-.16.44v6.24c0 .16.08.32.2.44.08.12.24.2.4.2h6.68c.16 0 .32-.08.44-.2s.2-.28.2-.44v-3.36h5.16c.16 0 .32-.08.44-.2s.2-.28.2-.44V4.88c0-.16-.08-.32-.2-.44a.64.64 0 0 0-.44-.2h-3.36V1.76c0-.16-.08-.32-.2-.44a.6.6 0 0 0-.44-.16h-6.24c-.16 0-.32.04-.44.16s-.2.28-.2.44v5.6z" />
    </Svg>
  );
};
SvgFloorPlanFill.displayName = 'FloorPlanFill';
const Memo = memo(SvgFloorPlanFill);
export default Memo;
