// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowExpandHOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m13.792 7.5-1.813-1.813a.5.5 0 1 1 .707-.707l2.195 2.195-.35.35.35-.35a1.167 1.167 0 0 1 0 1.65l-2.195 2.195a.5.5 0 0 1-.707-.707L13.792 8.5H2.206l1.813 1.813a.5.5 0 1 1-.707.707L1.117 8.825a1.167 1.167 0 0 1 0-1.65L3.312 4.98a.5.5 0 0 1 .707.707L2.206 7.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowExpandHOutline.displayName = 'ArrowExpandHOutline';
const Memo = memo(SvgArrowExpandHOutline);
export default Memo;
