// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrainMrtOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.437 3.256V9.19c0 .565.3 1.058.743 1.316l-1.205 2.162a.5.5 0 0 0-.074.134l-.841 1.51c-.123.218-.05.499.16.626s.48.052.602-.167l.753-1.351h8.842l.758 1.352a.43.43 0 0 0 .603.165.47.47 0 0 0 .158-.627l-.844-1.506a.5.5 0 0 0-.078-.14l-1.2-2.142c.459-.252.772-.754.772-1.332V3.256C12.586 2.01 11.615 1 10.417 1H5.606C4.408 1 3.437 2.01 3.437 3.256m7.493 7.44H5.054a.5.5 0 0 1-.055.168l-.913 1.639h7.817l-.917-1.637a.5.5 0 0 1-.056-.17m.774-6.755H4.32v2.021l3.693.798 3.692-.798zm-1.287-2.024c.635 0 1.163.478 1.268 1.107H4.338c.105-.629.633-1.107 1.268-1.107zm1.287 4.982v2.29c0 .326-.253.59-.566.59H4.885a.58.58 0 0 1-.566-.59V6.9l3.603.779q.09.018.18 0zM5.091 8.576a.45.45 0 0 1 .44-.458h.792a.45.45 0 0 1 .44.458.45.45 0 0 1-.44.459h-.791a.45.45 0 0 1-.44-.459m4.59-.458a.45.45 0 0 0-.44.458.45.45 0 0 0 .44.459h.792a.45.45 0 0 0 .44-.459.45.45 0 0 0-.44-.458z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrainMrtOutline.displayName = 'TrainMrtOutline';
const Memo = memo(SvgTrainMrtOutline);
export default Memo;
