// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTicketAdmitVipFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v1.736a.5.5 0 0 1-.364.48 1.334 1.334 0 0 0 0 2.567.5.5 0 0 1 .364.481V11.5c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5V9.764a.5.5 0 0 1 .364-.48 1.334 1.334 0 0 0 0-2.567.5.5 0 0 1-.364-.481zm8.333.667a.5.5 0 0 1 1 0V5.5a.5.5 0 0 1-1 0zm0 2.666a.5.5 0 0 1 1 0v.334a.5.5 0 0 1-1 0zm.5 2.167a.5.5 0 0 0-.5.5v.333a.5.5 0 1 0 1 0V10.5a.5.5 0 0 0-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTicketAdmitVipFill.displayName = 'TicketAdmitVipFill';
const Memo = memo(SvgTicketAdmitVipFill);
export default Memo;
