// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgReadOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.2 8A5.2 5.2 0 1 1 2.8 8a5.2 5.2 0 0 1 10.4 0m.8 0A6 6 0 1 1 2 8a6 6 0 0 1 12 0M8.849 6.067a.34.34 0 0 1 .064.466L6.486 9.867a.319.319 0 0 1-.488.036l-.903-.765a.34.34 0 0 1 0-.471.317.317 0 0 1 .458 0l.639.492 2.203-3.026a.32.32 0 0 1 .454-.066m2.02 0a.34.34 0 0 1 .065.466L8.507 9.867a.318.318 0 0 1-.488.036l-.432-.432a.34.34 0 0 1 0-.471.317.317 0 0 1 .458 0l.168.16 2.203-3.027a.32.32 0 0 1 .454-.066"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgReadOutline.displayName = 'ReadOutline';
const Memo = memo(SvgReadOutline);
export default Memo;
