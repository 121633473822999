// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArchiveFolderBoxFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.333 5.334V2.5c0-.644.523-1.167 1.167-1.167h1.948c.486 0 .952.194 1.296.537l.748.748a.17.17 0 0 0 .117.049H11.5c.644 0 1.167.522 1.167 1.166v1.501q.135.001.248.01c.142.012.298.038.451.117.22.111.398.29.51.51.078.153.105.308.117.45.01.132.01.29.01.462v4.504c0 .356 0 .653-.02.896-.02.253-.065.491-.18.716a1.83 1.83 0 0 1-.8.801c-.226.115-.463.16-.717.18-.242.02-.54.02-.896.02H4.613c-.356 0-.653 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.2 13a1.9 1.9 0 0 1-.18-.716C2 12.04 2 11.743 2 11.387V6.883c0-.172 0-.33.01-.462.012-.142.039-.297.117-.45.112-.22.29-.399.51-.51.154-.079.309-.105.451-.117q.112-.009.245-.01m1-2.834c0-.092.075-.167.167-.167h1.948c.22 0 .433.088.589.244l.748.748c.218.219.515.342.824.342H11.5c.092 0 .167.074.167.166v1.5H4.333zm.334 6a.5.5 0 0 1 .5-.5h2.666a.5.5 0 1 1 0 1H5.167a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArchiveFolderBoxFill.displayName = 'ArchiveFolderBoxFill';
const Memo = memo(SvgArchiveFolderBoxFill);
export default Memo;
