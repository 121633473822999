// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDeleteRemoveGarbageWasteTrashCanFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.055 3.333a3.167 3.167 0 0 1 5.889 0h3.221a.5.5 0 0 1 0 1h-.864l-.556 8.618a1.833 1.833 0 0 1-1.83 1.716H5.082c-.967 0-1.767-.751-1.83-1.716l-.555-8.618h-.865a.5.5 0 0 1 0-1zm1.118 0a2.166 2.166 0 0 1 3.653 0zM7 7.167a.5.5 0 0 0-1 0v3.666a.5.5 0 0 0 1 0zm2.5-.5a.5.5 0 0 1 .5.5v3.666a.5.5 0 0 1-1 0V7.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDeleteRemoveGarbageWasteTrashCanFill.displayName = 'DeleteRemoveGarbageWasteTrashCanFill';
const Memo = memo(SvgDeleteRemoveGarbageWasteTrashCanFill);
export default Memo;
