// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSellCommercialSellCommercialOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.906 2.4c-.994 0-1.8.811-1.8 1.812v8.4h-.818a.49.49 0 0 0-.491.494c0 .273.22.494.49.494h13.419c.271 0 .49-.221.49-.494a.493.493 0 0 0-.49-.494h-.818v-5.6c0-1-.806-1.812-1.8-1.812H9.96v-.988c0-1-.806-1.812-1.8-1.812zm5.073 10.212H3.088v-8.4a.82.82 0 0 1 .818-.824h4.255a.82.82 0 0 1 .818.824zm3.927 0H9.961V6.188h2.127a.82.82 0 0 1 .818.824zm-8.81-6.894a.67.67 0 0 1 .205-.257l1.428-1.047a.67.67 0 0 1 .847 0l1.43 1.047.027.022a.7.7 0 0 1 .177.236l-.456.204.456-.205q.06.135.058.28v.008l-.5-.01h.5v5.038a.847.847 0 0 1-.845.846h-2.54a.847.847 0 0 1-.846-.846V6v.007l.5-.01h-.5v.003a.7.7 0 0 1 .06-.281m.941.443v4.719h2.231V6.16l-1.115-.818zm1.365.639a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSellCommercialSellCommercialOutline.displayName = 'SellCommercialSellCommercialOutline';
const Memo = memo(SvgSellCommercialSellCommercialOutline);
export default Memo;
