// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBaseballFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m2.475-3.969a.2.2 0 0 0 .275.002l.415-.404A.22.22 0 0 0 4.5 3.31a.22.22 0 0 0-.319.015l-.386.432a.2.2 0 0 0 .013.274m7.691-.721a.22.22 0 0 0 .002.32l.416.403a.2.2 0 0 0 .275-.002.2.2 0 0 0 .013-.274l-.387-.432a.22.22 0 0 0-.319-.015m-6.84 1.765c.113.18.345.252.533.151l.14-.074a.43.43 0 0 0 .173-.61.43.43 0 0 0-.624-.107l-.128.094a.407.407 0 0 0-.095.546m5.836-.533a.43.43 0 0 0 .172.61l.14.074c.188.101.42.029.534-.151a.407.407 0 0 0-.095-.546l-.128-.094a.43.43 0 0 0-.623.107M5.285 6.476a.41.41 0 0 0 .47.295l.156-.033a.433.433 0 0 0 .335-.539.43.43 0 0 0-.569-.275l-.15.055a.406.406 0 0 0-.242.497m4.467-.277a.433.433 0 0 0 .335.54l.155.032a.41.41 0 0 0 .47-.295.406.406 0 0 0-.242-.497l-.15-.055a.43.43 0 0 0-.568.275M5.87 7.587A.41.41 0 0 0 5.499 8c0 .213.159.398.372.413l.158.01c.25.018.47-.172.47-.423a.43.43 0 0 0-.47-.423zm4.098-.01a.43.43 0 0 0-.47.423c0 .251.219.44.47.423l.158-.01A.41.41 0 0 0 10.499 8a.41.41 0 0 0-.372-.413zM5.285 9.524a.406.406 0 0 0 .243.497l.15.055a.43.43 0 0 0 .568-.275.433.433 0 0 0-.335-.54l-.155-.032a.41.41 0 0 0-.471.295m4.467.277a.43.43 0 0 0 .568.275l.15-.055a.406.406 0 0 0 .243-.497.41.41 0 0 0-.471-.295l-.155.033a.433.433 0 0 0-.335.539m-5.095 1.124a.407.407 0 0 0 .095.546l.128.094c.203.149.49.106.624-.107a.43.43 0 0 0-.173-.61l-.14-.075a.41.41 0 0 0-.534.152m5.837.533c.134.213.42.256.623.107l.128-.094a.407.407 0 0 0 .095-.546.41.41 0 0 0-.534-.152l-.14.075a.43.43 0 0 0-.172.61m-6.687.51a.2.2 0 0 0-.013.275l.386.432a.22.22 0 0 0 .32.015.22.22 0 0 0-.003-.32l-.415-.403a.2.2 0 0 0-.275.002m7.691.722c.091.087.235.079.32-.015l.386-.432a.2.2 0 0 0-.013-.274.2.2 0 0 0-.275-.002l-.416.404a.22.22 0 0 0-.002.319"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBaseballFill.displayName = 'BaseballFill';
const Memo = memo(SvgBaseballFill);
export default Memo;
