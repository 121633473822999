// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLoungeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.906 3.156a4.375 4.375 0 0 1 7.469 3.094v1.794a2.19 2.19 0 0 0-1.706 1.706H5.331a2.19 2.19 0 0 0-1.706-1.706V6.25c0-1.16.46-2.273 1.281-3.094M2.75 8V6.25a5.25 5.25 0 1 1 10.5 0V8h.438a1.313 1.313 0 0 1 0 2.625.44.44 0 0 0-.438.438v1.75a1.31 1.31 0 0 1-.875 1.237v.513a.438.438 0 0 1-.875 0v-.438h-7v.438a.438.438 0 0 1-.875 0v-.513a1.31 1.31 0 0 1-.875-1.237v-1.75a.44.44 0 0 0-.437-.438 1.313 1.313 0 0 1 0-2.625zm4.066-2.934c.17-.171.447-.171.618 0L8 5.63l.566-.565a.438.438 0 0 1 .618.618l-.565.566.565.566a.438.438 0 0 1-.618.618L8 6.87l-.566.565a.438.438 0 0 1-.618-.618l.565-.566-.565-.566a.44.44 0 0 1 0-.618"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLoungeFill.displayName = 'LoungeFill';
const Memo = memo(SvgLoungeFill);
export default Memo;
