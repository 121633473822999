// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRainyRainOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 5.667C2 3.28 3.91 1.333 6.281 1.333c1.494 0 2.807.775 3.571 1.943.081.123.258.207.442.18q.224-.031.456-.032c1.801 0 3.25 1.479 3.25 3.288C14 8.522 12.551 10 10.75 10H6.281C3.911 10 2 8.053 2 5.667m4.281-3.334C4.475 2.333 3 3.82 3 5.667 3 7.514 4.475 9 6.281 9h4.469C11.986 9 13 7.982 13 6.712s-1.014-2.288-2.25-2.288q-.161 0-.315.023c-.53.075-1.105-.144-1.42-.623a3.27 3.27 0 0 0-2.734-1.49m-1.554 9.04a.5.5 0 0 1 .267.654l-.7 1.667a.5.5 0 1 1-.922-.388l.7-1.666a.5.5 0 0 1 .655-.268m3.348 0a.5.5 0 0 1 .268.653l-.697 1.667a.5.5 0 1 1-.922-.386l.697-1.667a.5.5 0 0 1 .654-.268m3.333 0a.5.5 0 0 1 .269.653l-.697 1.667a.5.5 0 1 1-.923-.386l.697-1.667a.5.5 0 0 1 .654-.268"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRainyRainOutline.displayName = 'RainyRainOutline';
const Memo = memo(SvgRainyRainOutline);
export default Memo;
