// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDotGridMenuGrabOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8 3.833A.667.667 0 1 0 8 2.5a.667.667 0 0 0 0 1.333M8 8.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M8 13.5a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333M12.833 3.833a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333M12.833 8.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M12.833 13.5a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333M3.167 8.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M3.167 13.5a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333M3.167 3.833a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333" />
      <Path
        fillRule="evenodd"
        d="M3.167 3a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333M2 3.167a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0M8 3a.167.167 0 1 0 0 .333A.167.167 0 0 0 8 3m-1.167.167a1.167 1.167 0 1 1 2.334 0 1.167 1.167 0 0 1-2.334 0m6-.167a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333m-1.166.167a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0m-8.5 4.666a.167.167 0 1 0 0 .334.167.167 0 0 0 0-.334M2 8a1.167 1.167 0 1 1 2.333 0A1.167 1.167 0 0 1 2 8m6-.167a.167.167 0 1 0 0 .334.167.167 0 0 0 0-.334M6.833 8a1.167 1.167 0 1 1 2.334 0 1.167 1.167 0 0 1-2.334 0m6-.167a.167.167 0 1 0 0 .334.167.167 0 0 0 0-.334M11.667 8A1.167 1.167 0 1 1 14 8a1.167 1.167 0 0 1-2.333 0m-8.5 4.667a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333M2 12.833a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0m6-.166A.167.167 0 1 0 8 13a.167.167 0 0 0 0-.333m-1.167.166a1.167 1.167 0 1 1 2.334 0 1.167 1.167 0 0 1-2.334 0m6-.166a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333m-1.166.166a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDotGridMenuGrabOutline.displayName = 'DotGridMenuGrabOutline';
const Memo = memo(SvgDotGridMenuGrabOutline);
export default Memo;
