// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEuroCurrencyMoneyCoinEuroFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a6.667 6.667 0 1 0 0 13.334 6.667 6.667 0 0 0 0-13.334M4.665 8a.5.5 0 0 1 .5-.5h.197a3.7 3.7 0 0 1 .707-1.807C6.534 5.085 7.212 4.667 8 4.667c.788 0 1.467.42 1.932 1.03a.5.5 0 1 1-.795.606c-.32-.42-.727-.636-1.137-.636s-.816.216-1.135.633a2.7 2.7 0 0 0-.493 1.2h.794a.5.5 0 1 1 0 1h-.794c.075.474.254.887.493 1.2.32.417.725.633 1.135.633s.817-.217 1.137-.636a.5.5 0 0 1 .795.606c-.465.61-1.144 1.03-1.932 1.03-.787 0-1.465-.418-1.93-1.026A3.7 3.7 0 0 1 5.362 8.5h-.197a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEuroCurrencyMoneyCoinEuroFill.displayName = 'EuroCurrencyMoneyCoinEuroFill';
const Memo = memo(SvgEuroCurrencyMoneyCoinEuroFill);
export default Memo;
