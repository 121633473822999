// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAsteriskPlaceholderFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 1.333a.5.5 0 0 1 .5.5v5.301l4.592-2.65a.5.5 0 0 1 .5.866L9 8l4.59 2.65a.5.5 0 1 1-.5.866l-4.59-2.65v5.3a.5.5 0 0 1-1 0v-5.3l-4.59 2.65a.5.5 0 0 1-.5-.866L7 8l-4.59-2.65a.5.5 0 1 1 .5-.866l4.59 2.65v-5.3a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAsteriskPlaceholderFill.displayName = 'AsteriskPlaceholderFill';
const Memo = memo(SvgAsteriskPlaceholderFill);
export default Memo;
