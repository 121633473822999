// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalenderRemoveFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.667 1.833a.5.5 0 0 0-1 0v.834h-.834C2.821 2.667 2 3.487 2 4.5v7.667C2 13.179 2.82 14 3.833 14H7.5a.5.5 0 0 0 0-1H3.833A.833.833 0 0 1 3 12.167v-5.5h10V7.5a.5.5 0 0 0 1 0v-3c0-1.013-.82-1.833-1.833-1.833h-.834v-.834a.5.5 0 1 0-1 0v.834H5.667z" />
      <Path d="M10.853 10.146a.5.5 0 0 0-.707.707L11.292 12l-1.146 1.146a.5.5 0 0 0 .707.707l1.146-1.146 1.147 1.146a.5.5 0 1 0 .707-.707L12.706 12l1.147-1.146a.5.5 0 1 0-.707-.708l-1.147 1.147z" />
    </Svg>
  );
};
SvgCalenderRemoveFill.displayName = 'CalenderRemoveFill';
const Memo = memo(SvgCalenderRemoveFill);
export default Memo;
