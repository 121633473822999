// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTennisBallFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.332 8c0-1.812.723-3.455 1.896-4.657A5.66 5.66 0 0 1 5.665 8a5.66 5.66 0 0 1-2.437 4.657A6.65 6.65 0 0 1 1.332 8" />
      <Path d="M6.665 8A6.66 6.66 0 0 0 4 2.666a6.64 6.64 0 0 1 4-1.333c1.5 0 2.885.496 4 1.333A6.66 6.66 0 0 0 9.332 8a6.66 6.66 0 0 0 2.667 5.334 6.64 6.64 0 0 1-4 1.333c-1.501 0-2.886-.496-4-1.333A6.66 6.66 0 0 0 6.665 8" />
      <Path d="M12.77 3.343A6.65 6.65 0 0 1 14.664 8a6.65 6.65 0 0 1-1.896 4.657A5.66 5.66 0 0 1 10.332 8a5.66 5.66 0 0 1 2.437-4.657" />
    </Svg>
  );
};
SvgTennisBallFill.displayName = 'TennisBallFill';
const Memo = memo(SvgTennisBallFill);
export default Memo;
