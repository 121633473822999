// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShipChangelogOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.165 2.333A.167.167 0 0 0 7 2.5V4h2V2.5a.167.167 0 0 0-.167-.167zM10 4V2.5c0-.644-.523-1.167-1.167-1.167H7.165C6.521 1.333 6 1.856 6 2.5V4h-1.5c-.645 0-1.167.522-1.167 1.167v2.97c-.771.298-1.303 1.106-1.093 1.987.213.893.545 1.69 1.095 2.54l-1.608.347a.5.5 0 1 0 .212.978l2.801-.606a.8.8 0 0 1 .353 0l2.52.545c.255.055.519.055.774 0l2.52-.545a.8.8 0 0 1 .352 0l2.802.606a.5.5 0 0 0 .211-.978l-1.607-.347c.55-.85.882-1.647 1.095-2.54.21-.881-.322-1.689-1.094-1.987v-2.97C12.666 4.522 12.144 4 11.5 4zm-5.5 1a.167.167 0 0 0-.167.167v2.71l3.233-.786c.285-.07.582-.07.867 0l3.233.786v-2.71A.167.167 0 0 0 11.5 5zm7.112 7.436c.626-.888.965-1.664 1.175-2.544.085-.357-.153-.75-.583-.855l-4.007-.975a.83.83 0 0 0-.394 0l-4.007.975c-.43.105-.668.498-.583.855.21.88.549 1.656 1.175 2.544l.141-.03c.255-.056.52-.056.775 0l2.52.544a.8.8 0 0 0 .352 0l2.52-.544c.255-.056.52-.056.775 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShipChangelogOutline.displayName = 'ShipChangelogOutline';
const Memo = memo(SvgShipChangelogOutline);
export default Memo;
