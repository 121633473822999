// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeRoofHouseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.714 1.19c.189-.049.386-.049.574 0 .217.054.405.19.58.316l.045.032 5.713 4.09a.5.5 0 1 1-.582.813l-.71-.508v5.454c0 .356 0 .653-.02.896-.02.253-.065.491-.18.716a1.83 1.83 0 0 1-.8.801c-.225.115-.463.16-.716.18-.243.02-.54.02-.896.02H5.28c-.356 0-.653 0-.896-.02a1.9 1.9 0 0 1-.716-.18A1.83 1.83 0 0 1 2.868 13a1.9 1.9 0 0 1-.18-.716c-.02-.243-.02-.54-.02-.896V5.933l-.709.508a.5.5 0 0 1-.582-.813l5.712-4.09.045-.032c.176-.127.363-.262.58-.317" />
    </Svg>
  );
};
SvgHomeRoofHouseFill.displayName = 'HomeRoofHouseFill';
const Memo = memo(SvgHomeRoofHouseFill);
export default Memo;
