// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTargetArrowGoalAimOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.272 1.197a.667.667 0 0 1 1.122.262l.505 1.64 1.64.506c.488.15.633.77.262 1.121l-1.873 1.772c-.34.322-.791.502-1.26.502H9.706L8.352 8.353a.5.5 0 0 1-.707-.707L9 6.293V4.33c0-.47.18-.92.501-1.26zM10 6h1.669a.83.83 0 0 0 .573-.228l1.458-1.38-1.347-.414a.5.5 0 0 1-.331-.331l-.415-1.348-1.38 1.459A.83.83 0 0 0 10 4.33zm-2-3.667a5.667 5.667 0 1 0 5.658 5.36.5.5 0 0 1 .999-.053q.01.18.01.36a6.667 6.667 0 1 1-6.307-6.657.5.5 0 0 1-.054.998A6 6 0 0 0 8 2.333m-.176 2.119a.5.5 0 0 1-.395.586 3.01 3.01 0 1 0 3.532 3.533.5.5 0 0 1 .982.191 4.01 4.01 0 1 1-4.706-4.705.5.5 0 0 1 .587.395"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTargetArrowGoalAimOutline.displayName = 'TargetArrowGoalAimOutline';
const Memo = memo(SvgTargetArrowGoalAimOutline);
export default Memo;
