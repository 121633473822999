// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChart4StatisticsGraph2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.667 3a1 1 0 0 1 1-1H13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-1.333a1 1 0 0 1-1-1zM7.333 5.667a1 1 0 0 0-1 1v6.334a1 1 0 0 0 1 1h1.334a1 1 0 0 0 1-1V6.667a1 1 0 0 0-1-1zM3 9.334a1 1 0 0 0-1 1V13a1 1 0 0 0 1 1h1.333a1 1 0 0 0 1-1v-2.667a1 1 0 0 0-1-1z" />
    </Svg>
  );
};
SvgChart4StatisticsGraph2Fill.displayName = 'Chart4StatisticsGraph2Fill';
const Memo = memo(SvgChart4StatisticsGraph2Fill);
export default Memo;
