// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderCloud2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.168 2c-1.013 0-1.833.82-1.833 1.833V8.26a3.65 3.65 0 0 1 2-.593h2a3.667 3.667 0 0 1 3.073 5.666h4.427c1.012 0 1.833-.82 1.833-1.833V5.833c0-1.012-.82-1.833-1.833-1.833h-4.12a.83.83 0 0 1-.693-.371l-.542-.813A1.83 1.83 0 0 0 5.954 2z" />
      <Path d="M3.335 13a1.667 1.667 0 0 1 0-3.333.5.5 0 0 0 0-1 2.667 2.667 0 1 0 0 5.333.5.5 0 0 0 0-1" />
      <Path d="M3.835 10.833a.5.5 0 0 0 0 1h1a.5.5 0 1 0 0-1z" />
      <Path d="M5.335 13a.5.5 0 0 0 0 1 2.667 2.667 0 0 0 0-5.333.5.5 0 1 0 0 1 1.667 1.667 0 0 1 0 3.333" />
    </Svg>
  );
};
SvgFolderCloud2Fill.displayName = 'FolderCloud2Fill';
const Memo = memo(SvgFolderCloud2Fill);
export default Memo;
