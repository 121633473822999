// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRecordVoicemailBandTapeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.665 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0M10.332 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.333 0c0 .364-.097.706-.267 1H8.6a2 2 0 1 1 1.732 1H5.665a2 2 0 1 1 2-2"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRecordVoicemailBandTapeFill.displayName = 'RecordVoicemailBandTapeFill';
const Memo = memo(SvgRecordVoicemailBandTapeFill);
export default Memo;
