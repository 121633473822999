// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAiTextTextGenerationFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.5 3.333a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1zM11.78 6.776a.5.5 0 0 0-.894 0l-.925 1.851-1.851.926a.5.5 0 0 0 0 .894l1.85.926.926 1.85a.5.5 0 0 0 .895 0l.925-1.85 1.85-.926a.5.5 0 0 0 0-.894l-1.85-.926zM2.5 7.5a.5.5 0 0 0 0 1h3.667a.5.5 0 0 0 0-1zM2.5 11.667a.5.5 0 0 0 0 1h2.333a.5.5 0 1 0 0-1z" />
    </Svg>
  );
};
SvgAiTextTextGenerationFill.displayName = 'AiTextTextGenerationFill';
const Memo = memo(SvgAiTextTextGenerationFill);
export default Memo;
