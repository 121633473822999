// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFourkFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.228 6.349v2.363H4.724v-.026c.38-.71.814-1.37 1.484-2.337z" />
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm4.876 5.423c0 .313.196.479.466.479s.469-.166.469-.479v-.452h.267c.257 0 .403-.16.403-.39 0-.232-.143-.395-.403-.395h-.267V6.274c0-.44-.313-.71-.811-.71-.397 0-.635.163-.96.661-.511.759-.98 1.475-1.302 2.054-.147.258-.205.44-.205.642 0 .328.237.55.592.55h1.751zm2.136-.039c0 .322.182.511.491.511.31 0 .492-.188.492-.51v-.987l.469-.492 1.4 1.722c.152.196.289.27.49.27.271 0 .476-.208.476-.475 0-.133-.065-.273-.212-.455l-1.409-1.713 1.296-1.357c.123-.13.172-.238.172-.374a.45.45 0 0 0-.462-.453c-.163 0-.287.062-.423.212l-1.768 1.93h-.03V6.085c0-.325-.181-.514-.49-.514-.31 0-.492.189-.492.514z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFourkFill.displayName = 'FourkFill';
const Memo = memo(SvgFourkFill);
export default Memo;
