// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCryptoCoinFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m7.167-3.5a.5.5 0 0 0-1 0v.71a2.834 2.834 0 0 0 0 5.58v.71a.5.5 0 1 0 1 0v-.71a2.83 2.83 0 0 0 1.726-1.037.5.5 0 0 0-.786-.619 1.833 1.833 0 1 1 0-2.268.5.5 0 0 0 .786-.62 2.83 2.83 0 0 0-1.726-1.035z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCryptoCoinFill.displayName = 'CryptoCoinFill';
const Memo = memo(SvgCryptoCoinFill);
export default Memo;
