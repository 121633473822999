// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLiveSignalFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.992 3.287a.5.5 0 0 1 0 .707A5.65 5.65 0 0 0 2.332 8c0 1.565.634 2.98 1.66 4.007a.5.5 0 1 1-.707.707A6.65 6.65 0 0 1 1.332 8c0-1.841.747-3.509 1.953-4.714a.5.5 0 0 1 .707 0m8.014 0a.5.5 0 0 1 .707 0A6.65 6.65 0 0 1 14.665 8c0 1.84-.746 3.508-1.952 4.714a.5.5 0 0 1-.707-.707A5.65 5.65 0 0 0 13.666 8c0-1.565-.634-2.981-1.66-4.007a.5.5 0 0 1 0-.707M5.93 5.225a.5.5 0 0 1 0 .707c-.53.53-.857 1.26-.857 2.069S5.4 9.54 5.93 10.07a.5.5 0 1 1-.707.707A3.92 3.92 0 0 1 4.073 8c0-1.084.44-2.067 1.15-2.776a.5.5 0 0 1 .707 0m4.138 0a.5.5 0 0 1 .707 0c.71.71 1.15 1.692 1.15 2.776s-.44 2.066-1.15 2.776a.5.5 0 0 1-.707-.707c.53-.53.857-1.261.857-2.07 0-.807-.327-1.538-.857-2.068a.5.5 0 0 1 0-.707M6.998 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLiveSignalFill.displayName = 'LiveSignalFill';
const Memo = memo(SvgLiveSignalFill);
export default Memo;
