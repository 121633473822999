// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMeetingRoomOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.294 13.226a2.9 2.9 0 0 0-.865-.251c.351-.05.67-.207.926-.458a1.657 1.657 0 0 0 0-2.333c-.625-.625-1.708-.625-2.327 0-.313.313-.48.726-.48 1.167 0 .44.173.854.48 1.166.223.224.502.369.798.441a2.87 2.87 0 0 0-1.797.765 3.3 3.3 0 0 0-.737-.491 3.1 3.1 0 0 0-.943-.268c.307-.067.592-.218.82-.447a1.657 1.657 0 0 0 0-2.333c-.625-.625-1.707-.625-2.327 0a1.656 1.656 0 0 0 0 2.333c.229.229.514.38.826.447-.324.039-.647.128-.943.268a3 3 0 0 0-.737.485 2.872 2.872 0 0 0-1.803-.765 1.6 1.6 0 0 0 .799-.44 1.656 1.656 0 0 0 0-2.333c-.625-.625-1.708-.625-2.328 0a1.656 1.656 0 0 0 0 2.333c.257.256.58.407.927.457q-.454.06-.865.251c-.397.19-.748.47-1.022.81a.39.39 0 0 0 .603.49c.201-.25.463-.457.754-.596a2.2 2.2 0 0 1 1.87 0c.29.14.552.346.753.597a.392.392 0 0 0 .602.005c.212-.25.48-.457.776-.597a2.24 2.24 0 0 1 2.68.597c.072.09.183.14.3.14a.4.4 0 0 0 .302-.145c.2-.251.463-.458.753-.597a2.2 2.2 0 0 1 1.87 0c.29.14.552.346.753.597.079.095.19.145.302.145a.38.38 0 0 0 .245-.09.39.39 0 0 0 .062-.546 2.95 2.95 0 0 0-1.022-.81zM2.958 11.351a.86.86 0 0 1 .865-.865.86.86 0 0 1 .865.865.87.87 0 0 1-.251.614.88.88 0 0 1-1.223 0 .86.86 0 0 1-.25-.614zm4.185 0a.86.86 0 0 1 .865-.865.86.86 0 0 1 .865.865.87.87 0 0 1-.25.614.88.88 0 0 1-1.223 0 .86.86 0 0 1-.251-.614zm4.186 0a.88.88 0 0 1 .251-.614.84.84 0 0 1 .614-.251.87.87 0 0 1 .614.25c.168.163.251.38.251.615a.87.87 0 0 1-.25.614.88.88 0 0 1-1.223 0 .86.86 0 0 1-.251-.614z" />
      <Path d="M8.204 9.453c.072.118.2.18.329.18a.4.4 0 0 0 .212-.062c.352-.224.76-.34 1.172-.34.458 0 .893.139 1.267.395a.392.392 0 0 0 .547-.095.39.39 0 0 0-.095-.54c-.29-.202-.608-.341-.943-.43a1.633 1.633 0 0 0 1.05-1.535c-.001-.441-.174-.854-.48-1.167-.62-.625-1.709-.62-2.328 0a1.656 1.656 0 0 0 0 2.333c.134.134.284.235.452.313-.38.067-.742.206-1.066.413a.39.39 0 0 0-.117.541zm1.289-3.041a.86.86 0 0 1 .614-.251.86.86 0 0 1 .865.865.87.87 0 0 1-.251.614.88.88 0 0 1-1.223 0 .86.86 0 0 1-.25-.614.87.87 0 0 1 .25-.614zM4.2 9.454a.39.39 0 0 0 .536.123 2.23 2.23 0 0 1 2.321 0q.099.06.207.061a.39.39 0 0 0 .33-.184.383.383 0 0 0-.124-.536 2.9 2.9 0 0 0-.92-.368 1.64 1.64 0 0 0 .892-.893 1.63 1.63 0 0 0 .006-1.262 1.64 1.64 0 0 0-.893-.892 1.646 1.646 0 0 0-2.154.892 1.63 1.63 0 0 0-.006 1.262 1.64 1.64 0 0 0 .876.887c-.335.073-.664.19-.954.374a.385.385 0 0 0-.123.536zm.854-2.428a.85.85 0 0 1 .256-.608.847.847 0 0 1 1.217 0 .847.847 0 0 1 0 1.217.855.855 0 0 1-1.222 0 .847.847 0 0 1-.257-.608z" />
      <Path d="M14.608 1.902a1 1 0 0 0-.207-.301A1 1 0 0 0 14.1 1.4a1 1 0 0 0-.352-.067H2.28a1 1 0 0 0-.357.067.936.936 0 0 0-.513.502.9.9 0 0 0-.073.357v6.463c0 .123.028.246.072.357q.076.168.201.302a.94.94 0 0 0 .653.268h.28c.217 0 .39-.173.39-.39a.39.39 0 0 0-.39-.392h-.28s-.039 0-.055-.01a.13.13 0 0 1-.05-.034.13.13 0 0 1-.034-.05.2.2 0 0 1-.011-.056V2.265s0-.039.01-.056c.006-.017.023-.033.034-.05.017-.017.034-.022.05-.033.017-.006.04-.017.062-.012h11.48s.04 0 .056.012a.13.13 0 0 1 .05.033.2.2 0 0 1 .034.045q.009.032.01.055v6.458s0 .039-.01.056a.13.13 0 0 1-.034.05.2.2 0 0 1-.05.033c-.017.006-.056.011-.056.011-.229.012-.39.173-.396.385 0 .218.173.391.385.397q.183 0 .357-.067a.9.9 0 0 0 .307-.201 1 1 0 0 0 .207-.302 1 1 0 0 0 .072-.357V2.26a1 1 0 0 0-.072-.357" />
    </Svg>
  );
};
SvgMeetingRoomOutline.displayName = 'MeetingRoomOutline';
const Memo = memo(SvgMeetingRoomOutline);
export default Memo;
