// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile2DocumentsCopyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.501 1.333c-1.012 0-1.833.821-1.833 1.834v.166h-.167c-1.012 0-1.833.821-1.833 1.834v7.666c0 1.013.82 1.834 1.833 1.834h5.667c1.013 0 1.833-.821 1.833-1.834v-.166h.167c1.013 0 1.833-.821 1.833-1.834V5.667h-2.5a1.833 1.833 0 0 1-1.833-1.834v-2.5zm4.5 11.334h-4.5a1.833 1.833 0 0 1-1.833-1.834v-6.5h-.167a.833.833 0 0 0-.833.834v7.666c0 .46.373.834.833.834h5.667c.46 0 .833-.373.833-.834z"
        clipRule="evenodd"
      />
      <Path d="m10.668 1.626 3.04 3.04h-2.207a.833.833 0 0 1-.833-.833z" />
    </Svg>
  );
};
SvgFile2DocumentsCopyFill.displayName = 'File2DocumentsCopyFill';
const Memo = memo(SvgFile2DocumentsCopyFill);
export default Memo;
