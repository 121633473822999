// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStapleNotebookCoverFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.665 1.667H3.5c-.645 0-1.167.522-1.167 1.166v10.334c0 .644.522 1.166 1.167 1.166h1.166z" />
      <Path
        fillRule="evenodd"
        d="M5.665 14.333H12.5c.644 0 1.166-.522 1.166-1.167V2.833c0-.644-.522-1.166-1.166-1.166H5.665zM8.5 4.667a.5.5 0 0 0 0 1h2.333a.5.5 0 1 0 0-1zm0 2.666a.5.5 0 0 0 0 1h2.333a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStapleNotebookCoverFill.displayName = 'StapleNotebookCoverFill';
const Memo = memo(SvgStapleNotebookCoverFill);
export default Memo;
