// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Circle,Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbleMessageAnnotationExclamationMarkAlertTooltipOutline: React.FC<SvgProps> = (
  props,
) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v7c0 1.013-.82 1.834-1.833 1.834H10.25a.17.17 0 0 0-.107.038l-1.399 1.159a1.17 1.17 0 0 1-1.493-.004l-1.377-1.154a.17.17 0 0 0-.107-.04H3.833A1.833 1.833 0 0 1 2 10.834zM3.833 3A.833.833 0 0 0 3 3.833v7c0 .46.373.834.833.834h1.935c.274 0 .54.096.75.272l1.376 1.154a.17.17 0 0 0 .213 0l1.4-1.158c.209-.174.472-.268.744-.268h1.916c.46 0 .833-.373.833-.834v-7A.833.833 0 0 0 12.167 3zM8 4.667a.5.5 0 0 1 .5.5V7.5a.5.5 0 0 1-1 0V5.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
      <Circle
        cx={7.75}
        cy={9.75}
        r={0.75}
        fill="#0D1011"
      />
    </Svg>
  );
};
SvgBubbleMessageAnnotationExclamationMarkAlertTooltipOutline.displayName =
  'BubbleMessageAnnotationExclamationMarkAlertTooltipOutline';
const Memo = memo(SvgBubbleMessageAnnotationExclamationMarkAlertTooltipOutline);
export default Memo;
