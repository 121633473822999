// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBikeBicycleOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.079 9.085a2.56 2.56 0 0 1 1.792-.729 2.56 2.56 0 0 1 1.793.729c.475.467.743 1.1.743 1.76s-.268 1.293-.743 1.76a2.56 2.56 0 0 1-1.793.728 2.56 2.56 0 0 1-1.792-.729 2.47 2.47 0 0 1-.743-1.76c0-.66.267-1.292.743-1.76m1.792.19c-.424 0-.83.166-1.13.46s-.468.694-.468 1.11.168.815.468 1.109.706.46 1.13.46.83-.166 1.13-.46.469-.693.469-1.11c0-.415-.169-.815-.469-1.109s-.706-.46-1.13-.46M10.336 9.085a2.56 2.56 0 0 1 1.793-.729 2.56 2.56 0 0 1 1.793.729c.475.467.742 1.1.742 1.76s-.267 1.293-.742 1.76a2.56 2.56 0 0 1-1.793.728 2.56 2.56 0 0 1-1.793-.729 2.47 2.47 0 0 1-.742-1.76c0-.66.267-1.292.742-1.76m1.793.19c-.424 0-.83.166-1.13.46s-.468.694-.468 1.11.168.815.468 1.109.706.46 1.13.46.83-.166 1.13-.46.468-.693.468-1.11c0-.415-.168-.815-.468-1.109s-.706-.46-1.13-.46"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M11.738 3.66a.46.46 0 0 1-.313.573l-1.584.47 2.722 5.953a.456.456 0 0 1-.235.608.47.47 0 0 1-.62-.231l-2.747-6.01a.85.85 0 0 1 .223-1.006.9.9 0 0 1 .326-.177l1.645-.487a.47.47 0 0 1 .583.307M2.984 5.57c0-.254.21-.46.469-.46h2.893c.26 0 .469.206.469.46s-.21.46-.469.46H3.453a.464.464 0 0 1-.469-.46"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M4.471 5.17a.473.473 0 0 1 .639.172l1.387 2.39c.128.22.05.5-.175.627a.473.473 0 0 1-.639-.172l-1.387-2.39a.455.455 0 0 1 .175-.626"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M10.76 6.715a.46.46 0 0 1-.329.564L6.366 8.362l-2.117 2.76a.474.474 0 0 1-.656.09.454.454 0 0 1-.092-.644l2.214-2.886a.47.47 0 0 1 .251-.167l4.22-1.123a.47.47 0 0 1 .575.323"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBikeBicycleOff.displayName = 'BikeBicycleOff';
const Memo = memo(SvgBikeBicycleOff);
export default Memo;
