// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalendarBOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.167 1.333a.5.5 0 0 1 .5.5v.834h4.666v-.834a.5.5 0 0 1 1 0v.834h.834c1.012 0 1.833.82 1.833 1.833v7.667C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167V4.5c0-1.012.82-1.833 1.833-1.833h.834v-.834a.5.5 0 0 1 .5-.5M3.833 3.667A.833.833 0 0 0 3 4.5v7.667c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V4.5a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCalendarBOutline.displayName = 'CalendarBOutline';
const Memo = memo(SvgCalendarBOutline);
export default Memo;
