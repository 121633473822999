// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSearchMenuOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.999 4.333a3.667 3.667 0 1 0 0 7.334 3.667 3.667 0 0 0 0-7.334M5.332 8a4.667 4.667 0 1 1 8.301 2.927l1.553 1.553a.5.5 0 0 1-.707.707l-1.553-1.553A4.667 4.667 0 0 1 5.332 8m-4-3.5a.5.5 0 0 1 .5-.5h2.333a.5.5 0 1 1 0 1H1.832a.5.5 0 0 1-.5-.5m0 3.5a.5.5 0 0 1 .5-.5h1.667a.5.5 0 0 1 0 1H1.832a.5.5 0 0 1-.5-.5m0 3.5a.5.5 0 0 1 .5-.5h2.333a.5.5 0 1 1 0 1H1.832a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSearchMenuOutline.displayName = 'SearchMenuOutline';
const Memo = memo(SvgSearchMenuOutline);
export default Memo;
