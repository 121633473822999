// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAppleFruitFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.192 1.057a.5.5 0 0 0-.465.886c.574.3.971.658 1.247 1.083.15.233.27.494.362.792-1.752-.509-3.12-.157-4.047.805-1.024 1.064-1.38 2.75-1.27 4.366.112 1.623.705 3.307 1.743 4.42.524.56 1.172.986 1.934 1.165.709.167 1.484.113 2.303-.197.82.31 1.595.364 2.304.197.762-.18 1.41-.604 1.934-1.166 1.04-1.112 1.631-2.796 1.743-4.42.112-1.614-.245-3.301-1.27-4.365-.974-1.012-2.437-1.348-4.319-.72a4.3 4.3 0 0 0-.578-1.421c-.383-.591-.92-1.057-1.621-1.425" />
    </Svg>
  );
};
SvgAppleFruitFill.displayName = 'AppleFruitFill';
const Memo = memo(SvgAppleFruitFill);
export default Memo;
