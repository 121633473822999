// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWallet2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.333 2A2.333 2.333 0 0 0 2 4.333V11.5A2.5 2.5 0 0 0 4.5 14h7.667C13.179 14 14 13.18 14 12.167v-5c0-1.013-.82-1.834-1.833-1.834h-.834V3.48A1.48 1.48 0 0 0 9.853 2zm6 3.333V3.48a.48.48 0 0 0-.48-.48h-5.52C3.597 3 3 3.597 3 4.333a1 1 0 0 0 1 1zm0 5a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWallet2Fill.displayName = 'Wallet2Fill';
const Memo = memo(SvgWallet2Fill);
export default Memo;
