// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgIntercomFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 10.501c0 .237-.252.5-.5.5H2v1.5c0 .344.258.5.5.5h4c.248 0 .5.263.5.5s-.252.5-.5.5h-4c-.364 0-.845-.139-1.1-.381-.255-.243-.4-.778-.4-1.119v-10.2c0-.347.145-.676.4-.919.26-.248.6-.381.964-.381h7.272c.358 0 .703.139.964.381.26.243.4.572.4.919v4.2c0 .237-.252.5-.5.5s-.5-.263-.5-.5v-4.2c0-.311-.045-.31-.275-.307h-.089L2.5 2.002c-.255 0-.5.24-.5.5v7.5h5.5c.248 0 .5.263.5.5m5.259.143c.49.077.937.324 1.255.698l-.01.016c.317.368.496.84.496 1.33v1.9a.418.418 0 0 1-.837 0v-1.9a1.24 1.24 0 0 0-.296-.802 1.3 1.3 0 0 0-.754-.418l-1.11-.181a.41.41 0 0 1-.352-.407V9.232a.4.4 0 0 0-.123-.291.437.437 0 0 0-.592 0 .4.4 0 0 0-.122.29v2.885a.42.42 0 0 1-.609.369l-.653-.325a.5.5 0 0 0-.212-.05.499.499 0 0 0-.491.368.5.5 0 0 0 .01.276q.052.133.163.225l1.635 1.286a.4.4 0 0 1 .067.577.435.435 0 0 1-.591.066l-1.636-1.286c-.2-.16-.346-.368-.43-.604a1.3 1.3 0 0 1-.028-.737 1.344 1.344 0 0 1 .737-.88c.184-.081.385-.114.58-.12.202 0 .397.05.576.138l.05.021V9.22c0-.323.134-.642.368-.873.47-.461 1.306-.461 1.775 0 .235.23.369.55.369.874v1.297zM4.57 4H3.43c-.235 0-.43.227-.43.5s.195.5.43.5h1.14c.235 0 .43-.227.43-.5s-.195-.5-.43-.5m1.86 0h1.14c.235 0 .43.227.43.5s-.195.5-.43.5H6.43c-.235 0-.43-.227-.43-.5s.195-.5.43-.5m-1.86 3H3.43c-.235 0-.43.227-.43.5s.195.5.43.5h1.14c.235 0 .43-.227.43-.5s-.195-.5-.43-.5m1.86 0h1.14c.235 0 .43.227.43.5s-.195.5-.43.5H6.43c-.235 0-.43-.227-.43-.5s.195-.5.43-.5"
        clipRule="evenodd"
      />
      <Path d="M6 13.001c0-1 0-2 1-2H2v2z" />
    </Svg>
  );
};
SvgIntercomFill.displayName = 'IntercomFill';
const Memo = memo(SvgIntercomFill);
export default Memo;
