// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFerryShipBoatOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.676 4.94c.378-.377.89-.589 1.425-.589h3.14a2.017 2.017 0 0 1 2.016 2.012v2.762a.445.445 0 0 1-.89 0V6.363A1.123 1.123 0 0 0 9.242 5.24h-3.14a1.126 1.126 0 0 0-1.126 1.123v2.762a.445.445 0 0 1-.89 0V6.363c0-.534.212-1.045.59-1.422"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7.21 7.574c.294-.117.62-.117.914 0l5.204 2.079a.444.444 0 1 1-.33.824L7.794 8.4a.34.34 0 0 0-.253 0l-5.204 2.079a.445.445 0 0 1-.33-.825z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M3.024 9.25a.445.445 0 0 1 .551.303l1.122 3.864a.444.444 0 1 1-.855.247L2.721 9.8a.444.444 0 0 1 .303-.55M7.664 7.487c.245 0 .445.198.445.444v5.349a.445.445 0 0 1-.89 0V7.93c0-.245.199-.443.445-.443M12.336 9.255c.233.078.359.33.281.562l-1.383 4.126a.445.445 0 0 1-.844-.282l1.383-4.126a.445.445 0 0 1 .563-.28"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M4.152 12.973c.12.004.234.057.314.146a1.936 1.936 0 0 0 2.873 0 .445.445 0 0 1 .66 0 1.936 1.936 0 0 0 2.873 0 .445.445 0 0 1 .639-.022 2.44 2.44 0 0 0 1.992.663.445.445 0 1 1 .11.882 3.34 3.34 0 0 1-2.394-.63 2.825 2.825 0 0 1-3.55.013 2.83 2.83 0 0 1-3.55-.013 3.33 3.33 0 0 1-2.393.63.444.444 0 1 1 .11-.882 2.44 2.44 0 0 0 1.992-.663.45.45 0 0 1 .324-.124M6.683 2.245A.84.84 0 0 1 7.275 2h.785a.84.84 0 0 1 .838.836v1.96c0 .245-.2.444-.445.444h-1.57a.445.445 0 0 1-.445-.445V2.836c0-.222.088-.434.245-.591m.644.643v1.463h.68V2.888z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFerryShipBoatOff.displayName = 'FerryShipBoatOff';
const Memo = memo(SvgFerryShipBoatOff);
export default Memo;
