// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLaunchRocketOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.092 2.94c-1.188.558-2.112 1.477-2.833 2.823l-.132.245h-1.31a1.24 1.24 0 0 0-.91.396L2.264 8.173m.004.002.002.001.005.003c.08.038.666.318 1.355.713.679.388 1.503.913 2.036 1.445v.001c.53.533 1.054 1.358 1.443 2.037.396.69.677 1.275.714 1.353l.002.002.003.005 1.767-1.644c.248-.226.393-.558.393-.9V9.88l.244-.132c1.35-.724 2.268-1.65 2.826-2.839.562-1.197.777-2.7.642-4.608h-.002c-1.907-.139-3.41.076-4.605.638m-1.27 11.727c-.009 0-.032 0-.058-.002h-.002c-.015-.001-.067-.004-.122-.016a.95.95 0 0 1-.647-.505l-.002-.005-.002-.003-.003-.006c-.034-.07-.303-.632-.682-1.293-.385-.672-.86-1.405-1.296-1.845-.438-.438-1.168-.91-1.84-1.295a21 21 0 0 0-1.294-.681l-.006-.003a.93.93 0 0 1-.516-.66.9.9 0 0 1 .236-.817l1.64-1.763c.41-.441.984-.693 1.59-.693h.759c.783-1.366 1.802-2.36 3.12-2.98 1.388-.651 3.065-.87 5.07-.723h.005a.92.92 0 0 1 .852.86c.142 2.003-.076 3.68-.727 5.067-.62 1.32-1.614 2.34-2.982 3.127v.76c0 .596-.25 1.179-.694 1.584l-1.767 1.644a.93.93 0 0 1-.625.248h-.006m.007-.92v.065zm0 .045v.02m2.319-8.77a.773.773 0 1 0 0 1.547.773.773 0 0 0 0-1.546m-1.701.774a1.7 1.7 0 1 1 3.401 0 1.7 1.7 0 0 1-3.401 0M3.368 11.142a.5.5 0 0 1 .01.707l-1.852 1.904a.5.5 0 1 1-.717-.697l1.852-1.904a.5.5 0 0 1 .707-.01m1.482 1.523a.5.5 0 0 1 .01.707l-1.111 1.143a.5.5 0 1 1-.717-.697l1.11-1.143a.5.5 0 0 1 .708-.01"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLaunchRocketOutline.displayName = 'LaunchRocketOutline';
const Memo = memo(SvgLaunchRocketOutline);
export default Memo;
