// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMicrophoneMicSoundOnPodcastFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.665 4.667a3.333 3.333 0 1 1 6.667 0v3a3.333 3.333 0 1 1-6.667 0z" />
      <Path d="M3.872 9.466a.5.5 0 0 0-.916.4 5.5 5.5 0 0 0 4.542 3.278v1.023a.5.5 0 1 0 1 0v-1.023a5.5 5.5 0 0 0 4.542-3.277.5.5 0 0 0-.916-.4 4.501 4.501 0 0 1-8.252 0" />
    </Svg>
  );
};
SvgMicrophoneMicSoundOnPodcastFill.displayName = 'MicrophoneMicSoundOnPodcastFill';
const Memo = memo(SvgMicrophoneMicSoundOnPodcastFill);
export default Memo;
