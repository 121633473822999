// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChecklistBoxCheckListFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zm5.426 1.635a.5.5 0 1 0-.8-.6l-.964 1.285-.356-.237a.5.5 0 1 0-.555.832l.75.5a.5.5 0 0 0 .678-.116zm1.78.032a.5.5 0 0 0 0 1h1.666a.5.5 0 0 0 0-1zm-1.78 3.969a.5.5 0 1 0-.8-.6l-.964 1.285-.356-.237a.5.5 0 0 0-.555.832l.75.5a.5.5 0 0 0 .678-.116zm1.74.031a.5.5 0 0 0 0 1h1.667a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChecklistBoxCheckListFill.displayName = 'ChecklistBoxCheckListFill';
const Memo = memo(SvgChecklistBoxCheckListFill);
export default Memo;
