// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPagesBoardsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834h4.334c1.012 0 1.833.821 1.833 1.834V4h.833c1.013 0 1.834.82 1.834 1.833v7c0 1.013-.821 1.834-1.834 1.834H7.168a1.833 1.833 0 0 1-1.833-1.834V12H4.5a1.833 1.833 0 0 1-1.833-1.833zm3.667 9.666c0 .46.373.834.833.834h4.333c.46 0 .834-.373.834-.834v-7A.833.833 0 0 0 11.5 5H7.168a.833.833 0 0 0-.833.833zM9.668 4h-2.5c-1.013 0-1.833.82-1.833 1.833V11H4.5a.833.833 0 0 1-.833-.833v-7c0-.46.373-.834.833-.834h4.334c.46 0 .833.373.833.834z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPagesBoardsOutline.displayName = 'PagesBoardsOutline';
const Memo = memo(SvgPagesBoardsOutline);
export default Memo;
