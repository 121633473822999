// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile3DocumentCloudSyncFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.999 1.333h-3.5c-1.013 0-1.834.821-1.834 1.834v5.699a3.49 3.49 0 0 1 3.696.882 3 3 0 0 1 1.54 4.919H11.5c1.012 0 1.833-.821 1.833-1.834V6.667h-3.5a1.833 1.833 0 0 1-1.833-1.834z" />
      <Path d="m13.036 5.667-.046-.05-3.942-3.942-.05-.046v3.204c0 .46.374.834.834.834z" />
      <Path
        fillRule="evenodd"
        d="M3.832 9.667a2.5 2.5 0 0 0 0 5h1.833a2 2 0 0 0 .172-3.993 2.5 2.5 0 0 0-2.005-1.007m-1.5 2.5a1.5 1.5 0 0 1 2.8-.748.5.5 0 0 0 .467.25l.066-.002a1 1 0 1 1 0 2H3.832a1.5 1.5 0 0 1-1.5-1.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFile3DocumentCloudSyncFill.displayName = 'File3DocumentCloudSyncFill';
const Memo = memo(SvgFile3DocumentCloudSyncFill);
export default Memo;
