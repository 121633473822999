// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNewspaperNewsPaperOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2H9.5c1.012 0 1.833.82 1.833 1.833V7.5h1.5c1.013 0 1.833.82 1.833 1.833v2.5a2.167 2.167 0 0 1-2.279 2.164l-.054.003H3.499a2.167 2.167 0 0 1-2.167-2.167zM10.672 13a2.16 2.16 0 0 1-.34-1.167v-8A.833.833 0 0 0 9.499 3H3.165a.833.833 0 0 0-.833.833v8c0 .645.522 1.167 1.167 1.167zm.66-4.5v3.333a1.167 1.167 0 0 0 2.333 0v-2.5a.833.833 0 0 0-.833-.833zM3.999 5.167a.5.5 0 0 1 .5-.5h3.666a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1-.5-.5zm1 .5v2h2.666v-2zm-1 4.833a.5.5 0 0 1 .5-.5h3.666a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNewspaperNewsPaperOutline.displayName = 'NewspaperNewsPaperOutline';
const Memo = memo(SvgNewspaperNewsPaperOutline);
export default Memo;
