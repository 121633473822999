// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSunLightModeDayOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M8.001.678a.5.5 0 0 1 .5.5v1.016a.5.5 0 1 1-1 0V1.178a.5.5 0 0 1 .5-.5M2.824 2.823a.5.5 0 0 1 .707 0l.718.718a.5.5 0 1 1-.707.707l-.718-.718a.5.5 0 0 1 0-.707m10.354 0a.5.5 0 0 1 0 .707l-.718.718a.5.5 0 0 1-.707-.707l.718-.718a.5.5 0 0 1 .707 0m-3.055 3.056A3 3 0 1 0 5.88 10.12a3 3 0 0 0 4.243-4.242m-4.95-.707a4 4 0 1 1 5.657 5.656 4 4 0 0 1-5.657-5.656M.68 8a.5.5 0 0 1 .5-.5h1.015a.5.5 0 1 1 0 1H1.18a.5.5 0 0 1-.5-.5m12.627 0a.5.5 0 0 1 .5-.5h1.016a.5.5 0 0 1 0 1h-1.016a.5.5 0 0 1-.5-.5m-1.554 3.752a.5.5 0 0 1 .707 0l.718.718a.5.5 0 1 1-.707.707l-.718-.718a.5.5 0 0 1 0-.707m-7.504 0a.5.5 0 0 1 0 .707l-.718.718a.5.5 0 0 1-.707-.707l.718-.718a.5.5 0 0 1 .707 0m3.752 1.554a.5.5 0 0 1 .5.5v1.016a.5.5 0 0 1-1 0v-1.016a.5.5 0 0 1 .5-.5"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgSunLightModeDayOutline.displayName = 'SunLightModeDayOutline';
const Memo = memo(SvgSunLightModeDayOutline);
export default Memo;
