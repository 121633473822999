// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleCopyMembersFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 1.333c-1.012 0-1.833.821-1.833 1.834v7c0 1.012.82 1.833 1.833 1.833H4v.833c0 1.013.82 1.834 1.833 1.834h7c1.013 0 1.833-.821 1.833-1.834v-7c0-1.012-.82-1.833-1.833-1.833h-.833v-.833c0-1.013-.821-1.834-1.834-1.834zM5.832 4h5.167v-.833a.833.833 0 0 0-.834-.834h-7a.833.833 0 0 0-.833.834v7c0 .46.373.833.833.833H4V5.833C3.999 4.821 4.819 4 5.832 4m3.5 7c-1.377 0-2.572.908-3.167 2.237-.093.207.067.43.295.43h5.744c.228 0 .388-.223.295-.43C11.904 11.907 10.71 11 9.332 11m0-1a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPeopleCopyMembersFill.displayName = 'PeopleCopyMembersFill';
const Memo = memo(SvgPeopleCopyMembersFill);
export default Memo;
