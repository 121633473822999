// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPrivatePoolOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.743 2.924 4.18 1.882q.541.042 1.067.175A2.16 2.16 0 0 1 6.573 3.46a.437.437 0 0 0 .555.377.44.44 0 0 0 .298-.273c.152-.255.379-.458.65-.58a2.05 2.05 0 0 1 2.07.28L8.454 4.27a4 4 0 0 0-.698-.141.438.438 0 1 0-.094.87q.334.036.651.144a.4.4 0 0 0 .089.032q.146.054.287.124a1.95 1.95 0 0 1 1.04 1.945v.013L6.04 5.002a.44.44 0 0 0-.253-.064H1.89l-.002-.001a.01.01 0 0 1-.006-.013 1.99 1.99 0 0 1 1.89-1.277 2.8 2.8 0 0 1 1.322.375.438.438 0 0 0 .438-.758 3.7 3.7 0 0 0-.79-.34m.247 3.374c-2.857 2.778-3.037 6.637-3.103 8.04q-.006.143-.013.25a.438.438 0 0 1-.873-.051l.012-.247c.06-1.37.241-5.456 3.224-8.477H1.85a.886.886 0 0 1-.788-1.191l.002-.005a2.87 2.87 0 0 1 2.603-1.845l-.302-.56A.82.82 0 0 1 4.115 1h.01a7 7 0 0 1 1.398.225 3.04 3.04 0 0 1 1.609 1.338 2.3 2.3 0 0 1 .59-.38l.007-.004a2.93 2.93 0 0 1 3.035.459.818.818 0 0 1-.108 1.34l-1.243.74A2.83 2.83 0 0 1 10.6 7.324a.863.863 0 0 1-1.312.69L6.025 6.017a18.05 18.05 0 0 0-1.092 8.497.438.438 0 0 1-.87.097c-.315-2.81.004-5.65.927-8.313M6.932 8.92a.44.44 0 0 1 .586.199l.525 1.069h2.585a.438.438 0 1 1 0 .874H8.474l.538 1.095a.44.44 0 0 0 .379.218h5.173a.437.437 0 1 1 0 .875h-1.183l.302 1.206a.438.438 0 1 1-.85.212l-.354-1.418H9.39q-.12 0-.235-.021l-.764 1.529a.438.438 0 0 1-.783-.391l.786-1.574a1.3 1.3 0 0 1-.162-.238l-1.5-3.05a.437.437 0 0 1 .2-.585"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPrivatePoolOutline.displayName = 'PrivatePoolOutline';
const Memo = memo(SvgPrivatePoolOutline);
export default Memo;
