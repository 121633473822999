// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Circle,Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPiggyBankSaveMoneyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.335 3.167c0-.613.528-1.24 1.27-1.074a4.2 4.2 0 0 1 2.156 1.24h2.576a4.667 4.667 0 0 1 4.404 6.216.833.833 0 0 0 1.078-1.236.5.5 0 0 1 .78-.626 1.833 1.833 0 0 1-2.29 2.766 4.7 4.7 0 0 1-.57.742.23.23 0 0 0-.071.154v1.484c0 .645-.522 1.167-1.167 1.167H9.835a1.167 1.167 0 0 1-1.167-1.167.167.167 0 0 0-.167-.166h-1a.167.167 0 0 0-.166.166c0 .645-.523 1.167-1.167 1.167H4.501a1.167 1.167 0 0 1-1.166-1.167v-1.49a.23.23 0 0 0-.071-.153c-.29-.311-.54-.662-.738-1.043-.055-.107-.142-.147-.201-.147h-.49A1.167 1.167 0 0 1 .668 8.833V7.167C.668 6.522 1.19 6 1.835 6h.49c.059 0 .146-.04.201-.147.198-.381.447-.732.738-1.042a.23.23 0 0 0 .07-.154zm3 9.666c0-.644.522-1.166 1.166-1.166h1c.645 0 1.167.522 1.167 1.166 0 .092.075.167.167.167H11.5a.167.167 0 0 0 .167-.167V11.35c0-.33.14-.625.342-.839A3.652 3.652 0 0 0 13.004 8a3.667 3.667 0 0 0-3.667-3.667H6.671a.8.8 0 0 1-.593-.265 3.2 3.2 0 0 0-1.693-1m-.009 0q0-.001-.008.005a.13.13 0 0 0-.033.094v1.49c0 .33-.14.623-.341.837q-.346.369-.58.82c-.198.382-.597.686-1.09.686h-.49a.167.167 0 0 0-.166.167v1.666c0 .092.075.167.167.167h.49c.492 0 .89.304 1.089.686q.234.451.58.82c.2.214.34.507.34.837v1.49c0 .092.075.167.167.167h1.667a.167.167 0 0 0 .167-.167m-1.96-9.765h.001"
        clipRule="evenodd"
      />
      <Circle
        cx={5.501}
        cy={7.167}
        r={0.833}
        fill="#0D1011"
      />
    </Svg>
  );
};
SvgPiggyBankSaveMoneyOutline.displayName = 'PiggyBankSaveMoneyOutline';
const Memo = memo(SvgPiggyBankSaveMoneyOutline);
export default Memo;
