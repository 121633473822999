// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble3MessageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m1.973 10.952-.54 2.22a1.167 1.167 0 0 0 1.39 1.414l2.314-.52a.2.2 0 0 1 .13.017 6.667 6.667 0 1 0-3.313-3.267c.025.052.027.1.019.136" />
    </Svg>
  );
};
SvgBubble3MessageFill.displayName = 'Bubble3MessageFill';
const Memo = memo(SvgBubble3MessageFill);
export default Memo;
