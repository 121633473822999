// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPrayFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.546 4.645c.393.407.911.629 1.465.63h-.003.006-.003c.554-.001 1.072-.223 1.465-.63.394-.408.609-.945.609-1.52 0-.574-.215-1.11-.609-1.518-.782-.81-2.148-.81-2.93 0a2.17 2.17 0 0 0-.609 1.519c0 .574.215 1.111.61 1.519M6.227 15h5.37c.37 0 .729-.154.991-.426a1.48 1.48 0 0 0 0-2.05 1.38 1.38 0 0 0-.99-.426h-.555l.244-.16c.442-.297.776-.742.943-1.254a2.45 2.45 0 0 0-.024-1.587L11.12 6.096a1.46 1.46 0 0 0-.352-.55 1.4 1.4 0 0 0-.549-.333 1.4 1.4 0 0 0-.638-.043 1.4 1.4 0 0 0-.59.253L6.92 6.954 5.535 5.231a1.397 1.397 0 0 0-.96-.574 1.4 1.4 0 0 0-.567.05 1.3 1.3 0 0 0-.502.277 1.47 1.47 0 0 0-.5 1.026 1.4 1.4 0 0 0 .077.58c.065.185.16.352.274.475l1.707 2.112a2.25 2.25 0 0 0 3.097.414l.972-.716.305.833-3.963 2.612a1.49 1.49 0 0 0-.596 1.637c.09.296.268.568.507.753s.537.29.841.29"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPrayFill.displayName = 'PrayFill';
const Memo = memo(SvgPrayFill);
export default Memo;
