// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTiktokColour: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.71 6.146a5.2 5.2 0 0 0 3.042.977v-2.19a3 3 0 0 1-.64-.067V6.59a5.2 5.2 0 0 1-3.042-.977v4.47a4.04 4.04 0 0 1-4.035 4.05 4 4 0 0 1-2.246-.685 4.02 4.02 0 0 0 2.885 1.219 4.04 4.04 0 0 0 4.035-4.05zm.787-2.21a3.06 3.06 0 0 1-.788-1.788v-.281h-.605a3.07 3.07 0 0 0 1.394 2.07M5.2 11.732a1.85 1.85 0 0 1-.377-1.121 1.85 1.85 0 0 1 2.406-1.766v-2.24a4 4 0 0 0-.639-.036v1.743a1.849 1.849 0 0 0-2.406 1.766c0 .723.413 1.35 1.016 1.654" />
      <Path d="M10.072 5.613a5.2 5.2 0 0 0 3.042.977V4.865a3.05 3.05 0 0 1-1.615-.929 3.07 3.07 0 0 1-1.394-2.07h-1.59v8.751a1.85 1.85 0 0 1-1.846 1.846c-.6 0-1.132-.287-1.469-.73a1.85 1.85 0 0 1-1.015-1.655A1.85 1.85 0 0 1 6.59 8.312V6.569a4.043 4.043 0 0 0-3.95 4.048c0 1.102.438 2.101 1.15 2.831a4 4 0 0 0 2.246.685 4.04 4.04 0 0 0 4.035-4.05z" />
      <Path d="M13.113 4.865V4.4c-.572.001-1.131-.16-1.616-.463.43.471.994.796 1.616.93m-3.01-2.998a3 3 0 0 1-.033-.252v-.282H7.874v8.75a1.85 1.85 0 0 1-2.676 1.65c.338.443.87.73 1.47.73a1.85 1.85 0 0 0 1.845-1.846v-8.75zM6.59 6.569v-.497A4.042 4.042 0 0 0 2 10.084a4.05 4.05 0 0 0 1.789 3.364 4.04 4.04 0 0 1-1.15-2.83 4.043 4.043 0 0 1 3.95-4.05" />
    </Svg>
  );
};
SvgTiktokColour.displayName = 'TiktokColour';
const Memo = memo(SvgTiktokColour);
export default Memo;
