// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEditSmallBoxPencilPenWriteDrawFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.09 2.796a.833.833 0 0 0-1.18 0L9 5.707V7h1.293l2.91-2.91a.833.833 0 0 0 0-1.18zm-1.886-.707a1.833 1.833 0 0 1 2.592 0l.115.115a1.833 1.833 0 0 1 0 2.592l-3.057 3.058A.5.5 0 0 1 10.5 8h-2a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .146-.354z"
        clipRule="evenodd"
      />
      <Path d="m9.879 2-2.44 2.44A1.5 1.5 0 0 0 7 5.5v2A1.5 1.5 0 0 0 8.5 9h2a1.5 1.5 0 0 0 1.06-.44L14 6.122v5.266c0 .356 0 .653-.02.896-.02.253-.065.491-.18.716A1.83 1.83 0 0 1 13 13.8c-.225.115-.463.16-.716.18-.243.02-.54.02-.896.02H4.613c-.356 0-.653 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.2 13a1.9 1.9 0 0 1-.18-.716C2 12.04 2 11.743 2 11.387V4.613c0-.356 0-.653.02-.896.02-.253.065-.491.18-.716.176-.345.456-.625.801-.801.225-.115.463-.16.716-.18.243-.02.54-.02.896-.02z" />
    </Svg>
  );
};
SvgEditSmallBoxPencilPenWriteDrawFill.displayName = 'EditSmallBoxPencilPenWriteDrawFill';
const Memo = memo(SvgEditSmallBoxPencilPenWriteDrawFill);
export default Memo;
