// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBusFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.003 1.333c-1.634 0-2.954.254-3.887.734-.947.486-1.533 1.233-1.533 2.193v2.307h-.814a.432.432 0 1 0 0 .866h.82v3.8a2.42 2.42 0 0 0 .994 1.96v1.027a.432.432 0 1 0 .866 0v-.627c.18.047.374.067.56.067h5.987c.187 0 .38-.02.56-.067v.627a.432.432 0 1 0 .867 0v-1.027a2.438 2.438 0 0 0 1-1.967v-3.8h.806a.432.432 0 1 0 0-.866h-.806V4.253c0-.96-.587-1.706-1.534-2.193-.933-.48-2.253-.733-3.886-.733zm4.546 5.614V5.2H3.456v3.613h9.093V6.947m-7.04 3.86a.432.432 0 1 0 0 .866h.5a.432.432 0 1 0 0-.866zm4.047.433c0-.24.193-.433.433-.433h.5a.432.432 0 1 1 0 .866h-.5a.43.43 0 0 1-.433-.433"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBusFill.displayName = 'BusFill';
const Memo = memo(SvgBusFill);
export default Memo;
