// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHourglassTimeWatchClockOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 1.833a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-.833v2.27c0 .626-.319 1.208-.845 1.545L8.928 8l2.894 1.852c.526.337.845.92.845 1.544v2.27h.833a.5.5 0 1 1 0 1h-11a.5.5 0 0 1 0-1h.833v-2.27c0-.625.319-1.207.845-1.544L7.072 8 4.178 6.148a1.83 1.83 0 0 1-.845-1.544v-2.27H2.5a.5.5 0 0 1-.5-.5m2.333.5v2.27c0 .285.145.55.384.702L8 7.406l3.283-2.1a.83.83 0 0 0 .384-.702v-2.27zM8 8.593l-3.283 2.101a.83.83 0 0 0-.384.702v2.27h7.334v-2.27a.83.83 0 0 0-.384-.702z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHourglassTimeWatchClockOutline.displayName = 'HourglassTimeWatchClockOutline';
const Memo = memo(SvgHourglassTimeWatchClockOutline);
export default Memo;
