// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSalonBarberShopHaircutOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.664 2.383c0-.212.166-.383.37-.383h5.926c.205 0 .37.171.37.383v2.043a.377.377 0 0 1-.37.383h-.123v6.382h.123c.205 0 .37.172.37.383v2.043a.377.377 0 0 1-.37.383H5.034a.377.377 0 0 1-.37-.383v-2.043c0-.211.166-.383.37-.383h.125V4.81h-.125a.377.377 0 0 1-.37-.383zm4.233 2.426H5.9v1.55zM5.9 7.215V8.4l4.196-2.17V5.045zm0 3.228V9.257l4.196-2.17v1.187zm.209.749h3.987V9.13zm-.704-8.426v1.277h5.185V2.766zm0 9.191v1.277h5.185v-1.277z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSalonBarberShopHaircutOff.displayName = 'SalonBarberShopHaircutOff';
const Memo = memo(SvgSalonBarberShopHaircutOff);
export default Memo;
