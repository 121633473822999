// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowBottomOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.501 12.834a1 1 0 0 0 .09-.078l3.223-3.224a.5.5 0 1 1 .707.707l-3.223 3.224a1.833 1.833 0 0 1-2.593 0l-3.224-3.224a.5.5 0 0 1 .707-.707l3.224 3.224a1 1 0 0 0 .09.078V2.552a.5.5 0 0 1 1 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowBottomOutline.displayName = 'ArrowBottomOutline';
const Memo = memo(SvgArrowBottomOutline);
export default Memo;
