// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBotFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 .667a.5.5 0 0 1 .5.5V2h2.98c1.012 0 1.832.82 1.832 1.833V7.5c0 .56-.25 1.06-.645 1.397v.396l1.187 1.187a.5.5 0 0 1-.708.707l-.526-.526a4.668 4.668 0 0 1-9.24 0l-.526.526a.5.5 0 0 1-.708-.707l1.187-1.187v-.396A1.83 1.83 0 0 1 2.687 7.5V3.833C2.688 2.821 3.508 2 4.522 2H7.5v-.833a.5.5 0 0 1 .5-.5M4.52 3a.833.833 0 0 0-.832.833V7.5c0 .46.373.833.833.833h6.958c.46 0 .834-.373.834-.833V3.833A.833.833 0 0 0 11.479 3zm1.313 1.667a.5.5 0 0 1 .5.5v1a.5.5 0 1 1-1 0v-1a.5.5 0 0 1 .5-.5m4.334 0a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0v-1a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBotFill.displayName = 'BotFill';
const Memo = memo(SvgBotFill);
export default Memo;
