// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFocusExposureMacroFlower2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.5 9.302A4 4 0 0 1 4 5.333V2.757a.877.877 0 0 1 1.203-.814l.738.295a.42.42 0 0 0 .413-.062l.709-.567a1.5 1.5 0 0 1 1.874 0l.709.567a.42.42 0 0 0 .413.062l.738-.295A.877.877 0 0 1 12 2.757v2.576a4 4 0 0 1-3.5 3.97v1.674a3.92 3.92 0 0 1 3.536-1.264.5.5 0 0 1 .417.418q.047.297.047.607a3.93 3.93 0 0 1-4.351 3.906.5.5 0 0 1-.298 0 3.929 3.929 0 0 1-4.304-4.514.5.5 0 0 1 .417-.417q.297-.046.607-.046c1.164 0 2.21.506 2.929 1.31zm-2.929 1.365A2.93 2.93 0 0 1 7.5 13.666h-.07A2.93 2.93 0 0 1 4.5 10.669zm3.93 2.999-.001-.07a2.93 2.93 0 0 1 3-2.928v.07a2.93 2.93 0 0 1-3 2.928"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFocusExposureMacroFlower2Fill.displayName = 'FocusExposureMacroFlower2Fill';
const Memo = memo(SvgFocusExposureMacroFlower2Fill);
export default Memo;
