// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShHomeSparkleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.752 2.24a.5.5 0 0 0-.434-.238.5.5 0 0 0-.435.239l-.628 1.129-1.257.564a.44.44 0 0 0-.266.392c0 .166.104.319.266.392l1.257.564.628 1.13a.5.5 0 0 0 .435.238.5.5 0 0 0 .434-.239l.628-1.129 1.257-.564a.44.44 0 0 0 .266-.392.44.44 0 0 0-.266-.392L12.38 3.37z" />
      <Path
        fillRule="evenodd"
        d="M7.929 2.506c-.272.034-.486.16-.558.233-.11.113-.427.365-.796.657-.39.306-.875.678-1.361 1.05s-.972.75-1.354 1.049q-.294.228-.48.385-.097.078-.155.126-.028.03-.046.04s-.013.007-.013.013c-.039.04-.09.14-.136.293a1.5 1.5 0 0 0-.052.352v5.976c0 .598.143.744.182.77.052.047.13.06.304.06h2.43V9.97c0-1.268.868-1.825 1.458-1.825h1.542c.59 0 1.458.557 1.458 1.826v3.54h1.866c.052 0 .279-.047.486-.2a.73.73 0 0 0 .324-.63V6.696c0-.272.22-.498.486-.498s.486.226.486.498v5.977c0 .69-.35 1.162-.732 1.44-.357.266-.784.386-1.05.386h-8.78c-.162 0-.557 0-.894-.272-.37-.306-.544-.817-.544-1.554V6.697c0-.153.02-.379.078-.611.058-.226.168-.518.382-.737.11-.113.428-.365.797-.658.389-.305.875-.677 1.36-1.049.487-.372.973-.75 1.355-1.05q.292-.226.48-.384.096-.078.155-.127c.02-.02.382-.278.48-.332.161-.093.284-.199.725-.239.473-.046 1.037.053 1.49.518a.51.51 0 0 1 0 .704.48.48 0 0 1-.686 0c-.195-.199-.441-.259-.694-.232z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShHomeSparkleFill.displayName = 'ShHomeSparkleFill';
const Memo = memo(SvgShHomeSparkleFill);
export default Memo;
