// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTarget2ZoomFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.499 1.333a.5.5 0 0 0-1 0v1.691A5 5 0 0 0 3.023 7.5H1.332a.5.5 0 0 0 0 1h1.691a4.98 4.98 0 0 0 1.44 3.035 5 5 0 0 0 3.036 1.44v1.691a.5.5 0 1 0 1 0v-1.691A5 5 0 0 0 12.974 8.5h1.691a.5.5 0 0 0 0-1h-1.69a4.98 4.98 0 0 0-1.593-3.182A4.98 4.98 0 0 0 8.5 3.024zm-.853 4.355a.498.498 0 0 0 .853-.355V3.024a5 5 0 0 0-1 0v2.31c0 .138.056.263.147.354M3.023 7.5a5 5 0 0 0 0 1h2.309a.5.5 0 0 0 0-1zm9.951 0a5 5 0 0 1 0 1h-2.309a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5zM8.37 10.33c.08.089.13.207.13.336v2.309a5 5 0 0 1-1 0v-2.309a.5.5 0 0 1 .87-.336"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTarget2ZoomFill.displayName = 'Target2ZoomFill';
const Memo = memo(SvgTarget2ZoomFill);
export default Memo;
