// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGamepadGamingJoystickGamesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.832 6.667A3.167 3.167 0 0 1 3.999 3.5h8a3.167 3.167 0 0 1 3.166 3.167v2.666A3.167 3.167 0 0 1 12 12.5h-8A3.167 3.167 0 0 1 .832 9.333zM3.999 4.5c-1.197 0-2.167.97-2.167 2.167v2.666c0 1.197.97 2.167 2.167 2.167h8c1.196 0 2.166-.97 2.166-2.167V6.667c0-1.197-.97-2.167-2.166-2.167zM5.332 6a.5.5 0 0 1 .5.5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
      <Path d="M10.001 9.5a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M12.001 7.167a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667" />
      <Path
        fillRule="evenodd"
        d="M9.999 9.833a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m2-2.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m-.334-.667a.333.333 0 1 0 .667 0 .333.333 0 0 0-.667 0m-2 2.334a.333.333 0 1 0 .667 0 .333.333 0 0 0-.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGamepadGamingJoystickGamesOutline.displayName = 'GamepadGamingJoystickGamesOutline';
const Memo = memo(SvgGamepadGamingJoystickGamesOutline);
export default Memo;
