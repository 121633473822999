// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgInfoCircleTooltipFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.334a6.667 6.667 0 1 0 0 13.333 6.667 6.667 0 0 0 0-13.333m-1.334 6a.5.5 0 0 1 .5-.5H8a.5.5 0 0 1 .5.5v3.5a.5.5 0 0 1-1 0v-3h-.334a.5.5 0 0 1-.5-.5M8 4.834a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgInfoCircleTooltipFill.displayName = 'InfoCircleTooltipFill';
const Memo = memo(SvgInfoCircleTooltipFill);
export default Memo;
