// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCctvFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.336 5.362a4.03 4.03 0 0 1 4.032-4.029h7.763c.85 0 1.538.689 1.538 1.538v1.134c0 .58-.325 1.105-.832 1.366v1.635c0 .85-.69 1.537-1.539 1.537h-.837a1.54 1.54 0 0 1-1.376.849H7.528l-.783 3.128a.51.51 0 0 1-.497.388H2.362v1.246a.513.513 0 0 1-1.026 0v-3.517a.513.513 0 0 1 1.026 0v1.246h3.485l.624-2.491H5.368a4.03 4.03 0 0 1-4.032-4.03m10.288 2.156h.674c.283 0 .513-.229.513-.512V5.789l-1.187.475zM4.874 6.26a.897.897 0 0 0 0-1.793H4.87a.897.897 0 0 0 0 1.793z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCctvFill.displayName = 'CctvFill';
const Memo = memo(SvgCctvFill);
export default Memo;
