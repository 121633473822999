// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCirclePlaceholderOnOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2.9a5.1 5.1 0 1 0 0 10.2A5.1 5.1 0 0 0 8 2.9M2 8a6 6 0 1 1 12 0A6 6 0 0 1 2 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCirclePlaceholderOnOutline.displayName = 'CirclePlaceholderOnOutline';
const Memo = memo(SvgCirclePlaceholderOnOutline);
export default Memo;
