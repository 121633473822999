// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile8DocumentCloudSyncFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.999 1.333h-3.5c-1.013 0-1.834.821-1.834 1.834v3.416a3.667 3.667 0 0 1 5 3.417v2c0 1.05-.442 1.998-1.15 2.667H11.5c1.012 0 1.833-.821 1.833-1.834V6.667h-3.5a1.833 1.833 0 0 1-1.833-1.834z" />
      <Path d="m13.036 5.667-.046-.05-3.942-3.942-.05-.046v3.204c0 .46.374.834.834.834zM2.332 10a1.667 1.667 0 1 1 3.333 0 .5.5 0 1 0 1 0 2.667 2.667 0 1 0-5.333 0 .5.5 0 1 0 1 0" />
      <Path d="M4.499 10.5a.5.5 0 0 0-1 0v1a.5.5 0 1 0 1 0z" />
      <Path d="M2.332 12a.5.5 0 0 0-1 0 2.667 2.667 0 1 0 5.333 0 .5.5 0 0 0-1 0 1.667 1.667 0 1 1-3.333 0" />
    </Svg>
  );
};
SvgFile8DocumentCloudSyncFill.displayName = 'File8DocumentCloudSyncFill';
const Memo = memo(SvgFile8DocumentCloudSyncFill);
export default Memo;
