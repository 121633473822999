// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBrowserWindowAppDesktopFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.832 4.667a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1M4.999 5.167a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0M7.165 4.667a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1" />
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm12.333 0v2.167H2.332V4.5c0-.46.373-.833.833-.833h9.667c.46 0 .833.373.833.833"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBrowserWindowAppDesktopFill.displayName = 'BrowserWindowAppDesktopFill';
const Memo = memo(SvgBrowserWindowAppDesktopFill);
export default Memo;
