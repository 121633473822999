// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgYoutubeColour: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.403 11.3c-.134.7-.7 1.233-1.4 1.333-1.1.167-2.934.367-5 .367-2.034 0-3.867-.2-5-.367-.7-.1-1.267-.633-1.4-1.333A19.5 19.5 0 0 1 1.336 8c0-1.4.133-2.533.267-3.3.133-.7.7-1.233 1.4-1.333C4.103 3.2 5.936 3 8.003 3c2.066 0 3.866.2 5 .367.7.1 1.266.633 1.4 1.333.133.767.3 1.9.3 3.3a23 23 0 0 1-.3 3.3" />
      <Path d="M6.664 10.333V5.667l4 2.333z" />
    </Svg>
  );
};
SvgYoutubeColour.displayName = 'YoutubeColour';
const Memo = memo(SvgYoutubeColour);
export default Memo;
