// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVisionProAppWindowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 4.5c0-1.013.82-1.833 1.833-1.833H13.5c1.012 0 1.833.82 1.833 1.833v5.619c0 1.013-.82 1.833-1.833 1.833h-.171a1.5 1.5 0 0 1-1.496 1.381H5.5a1.5 1.5 0 0 1-1.495-1.38h-.172A1.833 1.833 0 0 1 2 10.118zm2.286 6.452a1.5 1.5 0 0 1 1.214-.619h6.333c.5 0 .942.244 1.214.62h.453c.46 0 .833-.374.833-.834V4.5a.833.833 0 0 0-.833-.833H3.833A.833.833 0 0 0 3 4.5v5.619c0 .46.373.833.833.833zm8.047.881a.5.5 0 0 0-.5-.5H5.5a.5.5 0 0 0 0 1h6.333a.5.5 0 0 0 .5-.5M.5 5.333a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVisionProAppWindowOutline.displayName = 'VisionProAppWindowOutline';
const Memo = memo(SvgVisionProAppWindowOutline);
export default Memo;
