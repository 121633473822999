// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgZapLightningFlashFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.331 1.57c0-.817-1.055-1.146-1.519-.473L2.114 9.36a.833.833 0 0 0 .686 1.307h3.865v3.763c0 .817 1.055 1.146 1.52.473l5.698-8.263a.833.833 0 0 0-.686-1.307H9.33z" />
    </Svg>
  );
};
SvgZapLightningFlashFill.displayName = 'ZapLightningFlashFill';
const Memo = memo(SvgZapLightningFlashFill);
export default Memo;
