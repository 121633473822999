// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChart3StatisticsGraphSignalFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.447 3.777a1.777 1.777 0 0 1 3.553 0v8.446a1.777 1.777 0 1 1-3.553 0zM7.997 5.556c-.982 0-1.777.795-1.777 1.777v4.89a1.777 1.777 0 0 0 3.553 0v-4.89c0-.982-.795-1.777-1.776-1.777M3.777 8.518C2.795 8.518 2 9.314 2 10.295v1.928a1.777 1.777 0 1 0 3.553 0v-1.928c0-.981-.795-1.777-1.776-1.777" />
    </Svg>
  );
};
SvgChart3StatisticsGraphSignalFill.displayName = 'Chart3StatisticsGraphSignalFill';
const Memo = memo(SvgChart3StatisticsGraphSignalFill);
export default Memo;
