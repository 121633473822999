// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicHandsMagicRainbowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.355 6.143a5.002 5.002 0 0 1 9.288 0 .5.5 0 0 0 .928-.372 6.002 6.002 0 0 0-11.144 0 .5.5 0 0 0 .928.372" />
      <Path d="M4.903 6.762a3.335 3.335 0 0 1 6.192 0 .5.5 0 0 0 .928-.372 4.335 4.335 0 0 0-8.048 0 .5.5 0 0 0 .928.372" />
      <Path d="M6.45 7.381a1.667 1.667 0 0 1 3.097 0 .5.5 0 1 0 .928-.372 2.667 2.667 0 0 0-4.953 0 .5.5 0 1 0 .929.372M5.454 8.97l.016.042a1.36 1.36 0 0 1 1.473.88 2.5 2.5 0 0 1-1.494 3.203l-.314.114a2.5 2.5 0 0 1-3.204-1.494l-.47-1.292A2.125 2.125 0 0 1 5.454 8.97M13.269 7.7a2.125 2.125 0 0 0-2.724 1.27l-.015.042a1.36 1.36 0 0 0-1.473.88 2.5 2.5 0 0 0 1.494 3.203l.313.114a2.5 2.5 0 0 0 3.204-1.494l.47-1.292a2.125 2.125 0 0 0-1.27-2.723" />
    </Svg>
  );
};
SvgMagicHandsMagicRainbowFill.displayName = 'MagicHandsMagicRainbowFill';
const Memo = memo(SvgMagicHandsMagicRainbowFill);
export default Memo;
