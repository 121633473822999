// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBagShoppingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.59 3.722A1.833 1.833 0 0 1 4.42 2h7.157c.97 0 1.771.755 1.83 1.722l.505 8.334A1.833 1.833 0 0 1 12.082 14H3.915a1.833 1.833 0 0 1-1.83-1.944zM6.5 5.333a.5.5 0 0 0-1 0 2.5 2.5 0 1 0 5 0 .5.5 0 0 0-1 0 1.5 1.5 0 1 1-3 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBagShoppingFill.displayName = 'BagShoppingFill';
const Memo = memo(SvgBagShoppingFill);
export default Memo;
