// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCat5CablingCableWireOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.945 11.995a.6.6 0 0 1-.216-.038.61.61 0 0 1-.407-.456l-1.15-5.975-.713 2.481a.63.63 0 0 1-.611.44H1.76c-.233 0-.425-.183-.425-.402s.192-.402.425-.402h.922l.923-3.2a.62.62 0 0 1 .448-.424.67.67 0 0 1 .611.156c.09.086.153.198.176.316l1.126 5.88L7.36 4.56a.62.62 0 0 1 .43-.44.66.66 0 0 1 .617.134c.102.086.17.204.193.333l1.064 5.542 1.082-5.634a.61.61 0 0 1 .407-.455.65.65 0 0 1 .623.107c.096.075.17.182.204.3l.923 3.2h1.342c.232 0 .424.182.424.402s-.192.402-.424.402h-1.506a.63.63 0 0 1-.612-.445l-.713-2.476-1.127 5.858a.63.63 0 0 1-.21.348.65.65 0 0 1-.622.108.6.6 0 0 1-.408-.456L7.96 5.724l-1.393 5.81a.6.6 0 0 1-.21.327.67.67 0 0 1-.407.139z" />
    </Svg>
  );
};
SvgCat5CablingCableWireOutline.displayName = 'Cat5CablingCableWireOutline';
const Memo = memo(SvgCat5CablingCableWireOutline);
export default Memo;
