// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVideoGameGamingGameOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.729 1a.505.505 0 0 0-.518.492c0 .271.232.492.518.492h1.03c.654 0 1.292.347 1.785.923s.79 1.325.79 2.012V5.2h-3.27c-.813 0-1.592.301-2.166.837A2.77 2.77 0 0 0 1 8.06v4.688c-.012.485.147.96.453 1.354s.744.684 1.244.824l.014.004c.526.13 1.084.08 1.575-.136a2.23 2.23 0 0 0 1.104-1.04l1.162-2.019h2.894l1.123 1.941c.153.325.386.611.681.835.3.228.656.384 1.036.454l.01.002c.331.054.67.04.995-.041s.627-.228.884-.43c.258-.201.465-.453.607-.737.143-.284.216-.594.217-.908l-.001-4.791c0-.758-.323-1.485-.897-2.022a3.18 3.18 0 0 0-2.166-.837H8.369v-.281c0-.936-.395-1.9-1.022-2.632C6.721 1.555 5.813 1 4.76 1zM5 7.502a.5.5 0 1 0-1 0v.499h-.498a.5.5 0 1 0 0 1H4v.498a.5.5 0 1 0 1 0v-.498h.498a.5.5 0 1 0 0-1H5zm-2.481-.885c.41-.382.965-.597 1.545-.597h7.872c.579 0 1.135.215 1.544.597.41.383.64.901.64 1.442l.001 4.79c0 .194-.046.386-.134.562a1.35 1.35 0 0 1-.376.456c-.159.125-.345.216-.546.266-.2.05-.408.059-.611.026a1.55 1.55 0 0 1-.649-.286 1.4 1.4 0 0 1-.453-.571l-1.256-2.172a.45.45 0 0 0-.387-.216H6.29a.45.45 0 0 0-.387.216L4.61 13.377l-.012.024a1.38 1.38 0 0 1-.687.65 1.52 1.52 0 0 1-.968.087 1.5 1.5 0 0 1-.778-.518 1.34 1.34 0 0 1-.286-.859V8.06c0-.54.23-1.06.64-1.442M13 7.501a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVideoGameGamingGameOutline.displayName = 'VideoGameGamingGameOutline';
const Memo = memo(SvgVideoGameGamingGameOutline);
export default Memo;
