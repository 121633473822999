// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBasketballOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.997 1C3.88 1 1 3.522 1 7.132v1.302c0 .596.231 1.157.653 1.573.423.423.978.654 1.573.654H4.29l.625 3.078a.494.494 0 0 0 .48.393c.035 0 .063 0 .098-.011a.485.485 0 0 0 .382-.579l-.058-.277h.711l.11 1.284A.496.496 0 0 0 7.13 15h.04a.493.493 0 0 0 .451-.532l-.098-1.203h.954l-.098 1.203c-.023.272.179.509.45.532h.041c.255 0 .469-.19.492-.451l.11-1.284h.711l-.058.277a.49.49 0 0 0 .382.579.48.48 0 0 0 .578-.382l.625-3.078h1.064c.595 0 1.156-.231 1.573-.654.422-.422.653-.977.653-1.573V7.132C14.994 3.522 12.114 1 7.997 1m2.73 9.546H9.69l.064-.77h1.128zm-2.024 0H7.292l-.064-.758h1.538zm-2.458-.758.064.758H5.268l-.156-.753h1.133zm-.625 2.487-.156-.752h.92l.063.752h-.832zm1.816 0-.063-.752h1.243l-.064.752H7.43zm2.105 0 .064-.752h.92l-.151.752H9.54m4.47-3.847c0 .33-.127.642-.364.88-.237.237-.55.364-.88.364h-.901a.84.84 0 0 0-.208-.532.9.9 0 0 0-.319-.243.9.9 0 0 0-.393-.087H5.048a.9.9 0 0 0-.711.335.9.9 0 0 0-.185.36.6.6 0 0 0-.024.178h-.902A1.25 1.25 0 0 1 1.983 8.44V7.138c0-3.564 3.019-5.155 6.014-5.155s6.014 1.591 6.014 5.15v1.3z" />
      <Path d="M11.032 3.603H4.96a.49.49 0 0 0-.491.492v3.471c0 .272.22.492.491.492h6.072c.272 0 .492-.22.492-.492V4.095a.49.49 0 0 0-.492-.492m-.491 3.471H5.45V4.587h5.09z" />
    </Svg>
  );
};
SvgBasketballOutline.displayName = 'BasketballOutline';
const Memo = memo(SvgBasketballOutline);
export default Memo;
