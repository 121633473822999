// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPencil2PenToolWriteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G
        fill="#0D1011"
        clipPath="url(#a)"
      >
        <Path
          fillRule="evenodd"
          d="M1.65 14.45a.5.5 0 0 1-.145-.417l.86-6.865a1.83 1.83 0 0 1 1.053-1.437L7.51 3.846a1.68 1.68 0 0 1 2.688-1.938l3.99 3.99a1.679 1.679 0 0 1-1.943 2.686l-1.929 4.07c-.266.562-.8.95-1.416 1.032l-6.833.905a.5.5 0 0 1-.418-.141m9.806-6.537L8.18 4.638 3.836 6.639a.83.83 0 0 0-.478.654L2.68 12.71l3.636-3.635a.5.5 0 0 1 .707.707l-3.624 3.624 5.371-.712a.83.83 0 0 0 .644-.469zm2.025-.348a.68.68 0 0 0 0-.96l-3.99-3.99a.679.679 0 1 0-.96.96l3.99 3.99a.68.68 0 0 0 .96 0"
          clipRule="evenodd"
        />
        <Path d="M7.999 9.428a1.333 1.333 0 1 1-2.667 0 1.333 1.333 0 0 1 2.667 0" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgPencil2PenToolWriteOutline.displayName = 'Pencil2PenToolWriteOutline';
const Memo = memo(SvgPencil2PenToolWriteOutline);
export default Memo;
