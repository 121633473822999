// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbleMessageAnnotationInfoTooltipFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill={color}
      viewBox="0 0 24 24"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.75 3A2.75 2.75 0 0 0 3 5.75v10.5A2.75 2.75 0 0 0 5.75 19h2.902a.25.25 0 0 1 .16.058l2.066 1.731a1.75 1.75 0 0 0 2.24.007l2.098-1.738a.25.25 0 0 1 .16-.058h2.874A2.75 2.75 0 0 0 21 16.25V5.75A2.75 2.75 0 0 0 18.25 3zM12 14.75a.75.75 0 0 0 .75-.75v-3.5a.75.75 0 0 0-1.5 0V14c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubbleMessageAnnotationInfoTooltipFill.displayName = 'BubbleMessageAnnotationInfoTooltipFill';
const Memo = memo(SvgBubbleMessageAnnotationInfoTooltipFill);
export default Memo;
