// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGroundFloorLowFloorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.92 1.918a.15.15 0 0 1 .102-.043H5.25v3.287H1.876V2.02c0-.039.016-.076.043-.103M1 5.599V2.021A1.02 1.02 0 0 1 2.022 1h7.336a1.023 1.023 0 0 1 1.022 1.021v3.168a.438.438 0 0 1-.877 0V2.02a.146.146 0 0 0-.146-.146h-3.23v3.287h.689a.438.438 0 1 1 0 .875h-.69v3.288h.565a.438.438 0 1 1 0 .875H2.022A1.023 1.023 0 0 1 1 9.179zm4.25.438v3.288H2.022a.146.146 0 0 1-.146-.146V6.037zm3.745.281a1.4 1.4 0 0 0-.216-.116.7.7 0 0 0-.3-.054.54.54 0 0 0-.368.18.65.65 0 0 0-.138.268L7.665 7.75l-.004.015a3.58 3.58 0 0 0 1.14 3.5 1.023 1.023 0 0 0-1.012 1.022q0 .037.006.074l.287 1.663a1.02 1.02 0 0 0 1.022.979h4.381a1.023 1.023 0 0 0 1.022-.979l.286-1.663a.4.4 0 0 0 .007-.074 1.02 1.02 0 0 0-1.023-1.021h-.014a3.58 3.58 0 0 0 1.14-3.501l-.004-.015-.307-1.153a.65.65 0 0 0-.14-.27.54.54 0 0 0-.366-.18.7.7 0 0 0-.302.055 1.4 1.4 0 0 0-.215.116.44.44 0 0 0-.15.162l-.676 1.27q-.38-.682-.856-1.304l-.01-.012a.763.763 0 0 0-1.181.012q-.481.63-.865 1.322L9.144 6.48a.44.44 0 0 0-.15-.162m-.184 5.823h2.143q.163.009.327.003.165.006.329-.003h2.167a.146.146 0 0 1 .144.12l-.283 1.646a.4.4 0 0 0-.007.075.146.146 0 0 1-.146.145H9.104a.146.146 0 0 1-.146-.146.4.4 0 0 0-.007-.074l-.283-1.645a.146.146 0 0 1 .143-.121m1.52-3.435.952 1.785.96-1.803a10 10 0 0 0-.952-1.576 10 10 0 0 0-.96 1.594M8.657 7.43l2.027 3.801A2.71 2.71 0 0 1 8.52 9.192a2.7 2.7 0 0 1-.006-1.224zm5.25 0 .143.538a2.7 2.7 0 0 1-1.542 3.071q-.302.134-.626.191l1.189-2.231a.44.44 0 0 0 .09-.17z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGroundFloorLowFloorOutline.displayName = 'GroundFloorLowFloorOutline';
const Memo = memo(SvgGroundFloorLowFloorOutline);
export default Memo;
