// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRescueRingSwimBoyleHelpSupportFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.944 3.653A6.64 6.64 0 0 0 1.332 8a6.64 6.64 0 0 0 1.612 4.347l2.61-2.609A3 3 0 0 1 4.998 8c0-.648.205-1.248.554-1.738zM3.651 2.946l2.61 2.609C6.75 5.205 7.35 5 7.998 5s1.248.205 1.738.555l2.609-2.61a6.64 6.64 0 0 0-4.347-1.612A6.64 6.64 0 0 0 3.65 2.946M13.053 3.653l-2.609 2.609c.35.49.555 1.09.555 1.738s-.206 1.248-.555 1.738l2.61 2.61A6.64 6.64 0 0 0 14.664 8a6.64 6.64 0 0 0-1.612-4.347M12.346 13.055l-2.61-2.61c-.49.35-1.09.555-1.737.555a3 3 0 0 1-1.739-.555l-2.609 2.61A6.64 6.64 0 0 0 8 14.667a6.64 6.64 0 0 0 4.347-1.612" />
    </Svg>
  );
};
SvgRescueRingSwimBoyleHelpSupportFill.displayName = 'RescueRingSwimBoyleHelpSupportFill';
const Memo = memo(SvgRescueRingSwimBoyleHelpSupportFill);
export default Memo;
