// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLinkLinesDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v3.416a3.667 3.667 0 0 0-5 3.417v2c0 1.05.442 1.998 1.15 2.667H4.5a1.833 1.833 0 0 1-1.833-1.833zm2 .667a.5.5 0 0 1 .5-.5h4.333a.5.5 0 1 1 0 1H5.168a.5.5 0 0 1-.5-.5m0 2.666a.5.5 0 0 1 .5-.5h1.667a.5.5 0 0 1 0 1H5.168a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <Path d="M10.335 10a1.667 1.667 0 1 1 3.333 0 .5.5 0 1 0 1 0 2.667 2.667 0 1 0-5.333 0 .5.5 0 0 0 1 0" />
      <Path d="M12.501 10.5a.5.5 0 1 0-1 0v1a.5.5 0 0 0 1 0z" />
      <Path d="M10.335 12a.5.5 0 1 0-1 0 2.667 2.667 0 0 0 5.333 0 .5.5 0 1 0-1 0 1.667 1.667 0 0 1-3.333 0" />
    </Svg>
  );
};
SvgLinkLinesDocumentListPageFileFill.displayName = 'LinkLinesDocumentListPageFileFill';
const Memo = memo(SvgLinkLinesDocumentListPageFileFill);
export default Memo;
