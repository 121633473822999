// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPassportVisaFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.332 3.5c0-1.012.82-1.833 1.833-1.833h7.667c1.013 0 1.833.82 1.833 1.833v9c0 1.013-.82 1.833-1.833 1.833H4.165A1.833 1.833 0 0 1 2.332 12.5zm5.667 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M5.499 7a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0m.166 3.833a.5.5 0 0 1 .5-.5h3.667a.5.5 0 0 1 0 1H6.165a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPassportVisaFill.displayName = 'PassportVisaFill';
const Memo = memo(SvgPassportVisaFill);
export default Memo;
