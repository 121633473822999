import type {RequiredOption} from '../types';
import {BaseTheme, DarkTheme, HighContrastTheme} from './Themes';

export const DefaultOption: RequiredOption = {
  theme: {
    light: BaseTheme,
    dark: DarkTheme,
    highContrast: HighContrastTheme,
  },
};
