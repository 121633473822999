import React, {
  forwardRef,
  Fragment,
  type Ref,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import {
  type NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput as RNTextInput,
  type TextInputChangeEventData,
  type TextInputFocusEventData,
  type ViewStyle,
} from 'react-native';

import {
  type BorderColor,
  type FillColor,
  type IconColor,
  type TextColor,
  useHive,
} from '@propertyguru/hive';
import {Icon} from '@propertyguru/hive-icon';
import {type PressableState, Surface} from '@propertyguru/hive-surface';
import {Text} from '@propertyguru/hive-text';

import type {TextInputProps} from './types';

const ICON_SIZE = 24;

export const surfaceColor = (state: PressableState, error: boolean | undefined): FillColor => {
  switch (true) {
    case !!error:
      return state.pressed ? 'fill/pressed/primary' : 'fill/error/secondary';
    case state.disabled:
      return 'fill/inactive/primary';
    case state.pressed:
      return 'fill/pressed/primary';
    case state.hovered:
      return 'fill/hover/primary';
    default:
      return 'fill/neutral/secondary';
  }
};

const iconSurfaceColor = (state: PressableState): FillColor | undefined => {
  switch (true) {
    case state.pressed:
      return 'fill/pressed/primary';
    default:
      return undefined;
  }
};

const iconColor = (state: PressableState, error: boolean | undefined): IconColor => {
  switch (true) {
    case !!error:
      return 'icon/error/primary';
    case state.disabled:
      return 'icon/inactive/primary';
    case state.pressed:
    case state.hovered:
      return 'icon/inactive/secondary';
    default:
      return 'icon/active/primary';
  }
};

export const placeholderTextColor = (
  state: PressableState,
  error: boolean | undefined,
): TextColor => {
  switch (true) {
    case !!error:
      return 'text/error/primary';
    case state.focused:
      return 'text/active/secondary';
    default:
      return 'text/inactive/primary';
  }
};

export const textColor = (state: PressableState, error: boolean | undefined): TextColor => {
  switch (true) {
    case !!error:
      return 'text/error/primary';
    case state.disabled:
      return 'text/inactive/primary';
    default:
      return 'text/active/primary';
  }
};

const SupportiveText = ({
  style,
  text,
  onPress,
}: {
  style: ViewStyle;
  text: string;
  onPress?: () => void;
}) => (
  <Surface
    style={style}
    onPress={onPress}
    color={(state) => surfaceColor(state, undefined)}
    disabled={!onPress}
  >
    <Text typography="caption/xs">{text}</Text>
  </Surface>
);

export const TextInput: React.ForwardRefExoticComponent<
  TextInputProps & React.RefAttributes<RNTextInput>
> = forwardRef(
  (
    {
      style,
      disabled,
      startIcon,
      endIcon,
      onStartIconPress,
      onEndIconPress,
      value,
      onChange,
      onChangeText,
      onFocus,
      onBlur,
      onPress,
      error,
      elevation,
      children,
      size,
      supportiveText,
      supportiveTextAlignment = 'start',
      onSupportiveTextPress,
      ...rest
    },
    ref: Ref<RNTextInput | null>,
  ) => {
    const {color, spacing, borderRadius, typography} = useHive();
    const rootRef = React.useRef<RNTextInput>(null);

    useImperativeHandle(ref, () => rootRef?.current, []);

    const [borderColor, setBorderColor] = useState<BorderColor>('border/active/primary');

    const endIconVisibility = !!value && !disabled && endIcon;
    const surfacePaddingLeft = startIcon ? 12 : 16;
    const surfacePaddingRight = endIconVisibility ? 12 : 16;

    const startIconStyle: ViewStyle = {
      pointerEvents: !onStartIconPress ? 'none' : undefined,
    };

    const largeContainerStyle: ViewStyle =
      size === 'large'
        ? {
            minHeight: 84,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            paddingVertical: spacing('x2'),
          }
        : {};

    const largeInputStyle: ViewStyle = {
      marginHorizontal: size === 'large' ? 0 : 4,
    };

    const supportiveTextContainerStyle: ViewStyle = {
      backgroundColor: color('fill/neutral/primary'),
      paddingVertical: spacing('x1'),
      paddingHorizontal: spacing('x2'),
      borderRadius: borderRadius('small'),
    };

    const handleInputOnChangeText = useCallback(
      (text: string) => {
        onChangeText?.(text);
      },
      [onChangeText],
    );

    const handleInputOnChange = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
      setBorderColor('border/active/tertiary');
      onChange?.(e);
    };

    const handleInputOnFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setBorderColor('border/focus/primary');
      onFocus?.(e);
    };

    const handleInputOnBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setBorderColor('border/active/primary');
      onBlur?.(e);
    };

    const handleEndIconPress = () => {
      onEndIconPress ? onEndIconPress() : onChangeText?.('');
    };

    return (
      <Surface
        style={[
          styles.container,
          {
            paddingRight: surfacePaddingRight,
            paddingLeft: surfacePaddingLeft,
          },
          largeContainerStyle,
          style,
        ]}
        disabled={disabled}
        accessible={false}
        color={(state) => surfaceColor(state, error)}
        borderColor={error ? 'border/error/primary' : borderColor}
        borderRadius={size === 'large' ? 'medium' : 'large'}
        elevation={elevation}
        onPress={onPress}
      >
        {(state: PressableState) => {
          return (
            <Fragment>
              {!!startIcon && (
                <Surface
                  style={[styles.startIcon, startIconStyle]}
                  color={iconSurfaceColor}
                  borderRadius={'full'}
                  onPress={onStartIconPress}
                >
                  <Icon
                    icon={startIcon}
                    color={iconColor(state, error)}
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                  />
                </Surface>
              )}
              {supportiveText && supportiveTextAlignment === 'start' && (
                <SupportiveText
                  style={supportiveTextContainerStyle}
                  text={supportiveText}
                  onPress={onSupportiveTextPress}
                />
              )}
              <RNTextInput
                style={[
                  typography('body/xs'),
                  styles.textInput,
                  {
                    color: color(textColor(state, error)),
                  },
                  largeInputStyle,
                ]}
                placeholderTextColor={color(placeholderTextColor(state, error))}
                editable={!disabled}
                value={value}
                selectionColor={color('fill/focus/primary')}
                onChange={handleInputOnChange}
                onChangeText={handleInputOnChangeText}
                onFocus={handleInputOnFocus}
                onBlur={handleInputOnBlur}
                ref={rootRef}
                multiline={size === 'large'}
                {...rest}
              >
                {children}
              </RNTextInput>
              {supportiveText && supportiveTextAlignment === 'end' && (
                <SupportiveText
                  style={supportiveTextContainerStyle}
                  text={supportiveText}
                  onPress={onSupportiveTextPress}
                />
              )}
              {endIconVisibility && (
                <Surface
                  testID="endIcon"
                  style={styles.endIcon}
                  color={iconSurfaceColor}
                  borderRadius={'full'}
                  onPress={handleEndIconPress}
                >
                  <Icon
                    icon={endIcon}
                    color={iconColor(state, error)}
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                  />
                </Surface>
              )}
            </Fragment>
          );
        }}
      </Surface>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    minHeight: 48,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  startIcon: {
    justifyContent: 'center',
    padding: 4,
  },
  textInput: {
    flex: 1,
    ...Platform.select({
      // lineHeight issue with RN TextInput:
      // https://github.com/facebook/react-native/issues/28012
      ios: {
        lineHeight: 0,
      },
      web: {
        outlineStyle: 'none',
      },
    }),
    marginHorizontal: 4,
  },
  endIcon: {
    justifyContent: 'center',
    padding: 4,
  },
});
