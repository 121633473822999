// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMapPaperOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.332 3.677v7.62l3.333 1.026v-7.62zm4.333 1.017v7.612l2.659-.886a.5.5 0 0 0 .341-.474V4.387a.5.5 0 0 0-.658-.474zm-5.333 6.612V3.694l-2.658.886a.5.5 0 0 0-.342.474v6.559a.5.5 0 0 0 .658.474zm.038-8.679a1.5 1.5 0 0 1 .915-.01l3.721 1.144a.5.5 0 0 0 .305-.003l2.38-.794a1.5 1.5 0 0 1 1.974 1.423v6.559a1.5 1.5 0 0 1-1.025 1.423l-3.013 1.004a1.5 1.5 0 0 1-.915.01l-3.72-1.144a.5.5 0 0 0-.306.003l-2.38.794a1.5 1.5 0 0 1-1.974-1.423V5.054a1.5 1.5 0 0 1 1.026-1.423z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMapPaperOutline.displayName = 'MapPaperOutline';
const Memo = memo(SvgMapPaperOutline);
export default Memo;
