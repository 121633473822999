// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAi2StarsSparklesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M4.335.667a.5.5 0 0 1 .5.5c0 1.038.23 1.672.612 2.054s1.016.612 2.054.612a.5.5 0 0 1 0 1c-1.038 0-1.672.23-2.054.613-.382.382-.612 1.015-.612 2.054a.5.5 0 0 1-1 0c0-1.039-.23-1.672-.613-2.054-.382-.383-1.016-.613-2.054-.613a.5.5 0 1 1 0-1c1.038 0 1.672-.23 2.054-.612.383-.382.613-1.016.613-2.054a.5.5 0 0 1 .5-.5m0 2.737a2.6 2.6 0 0 1-.93.93 2.6 2.6 0 0 1 .93.929 2.6 2.6 0 0 1 .929-.93 2.6 2.6 0 0 1-.93-.929M10 4.667a.5.5 0 0 1 .5.5c0 1.617.358 2.667 1.012 3.321S13.217 9.5 14.835 9.5a.5.5 0 1 1 0 1c-1.618 0-2.668.357-3.322 1.011-.654.655-1.012 1.705-1.012 3.322a.5.5 0 1 1-1 0c0-1.617-.357-2.667-1.011-3.322-.655-.654-1.705-1.011-3.322-1.011a.5.5 0 0 1 0-1c1.617 0 2.667-.357 3.322-1.012C9.144 7.834 9.5 6.784 9.5 5.167a.5.5 0 0 1 .5-.5m0 3.339a3.9 3.9 0 0 1-.804 1.19 3.9 3.9 0 0 1-1.19.804q.682.299 1.19.804.505.509.804 1.19c.201-.453.466-.851.805-1.19a3.9 3.9 0 0 1 1.19-.804 3.9 3.9 0 0 1-1.19-.804A3.9 3.9 0 0 1 10 8.006"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgAi2StarsSparklesOutline.displayName = 'Ai2StarsSparklesOutline';
const Memo = memo(SvgAi2StarsSparklesOutline);
export default Memo;
