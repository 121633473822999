// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicHatOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.333 8.776a4 4 0 0 1-.368-.15 2 2 0 0 1-.562-.376A1.28 1.28 0 0 1 2 7.333c0-.432.224-.741.403-.916.177-.174.385-.293.562-.377.36-.17.818-.297 1.303-.394.987-.198 2.306-.312 3.732-.312s2.745.114 3.732.312c.485.097.942.224 1.303.394.177.084.385.203.562.377.18.175.403.484.403.917 0 .432-.224.741-.403.916a2 2 0 0 1-.562.377 4 4 0 0 1-.368.149v3.89c0 .41-.232.735-.504.966-.273.232-.639.419-1.047.566-.822.296-1.923.469-3.116.469s-2.294-.173-3.116-.469c-.408-.147-.774-.334-1.047-.566s-.504-.555-.504-.965zm8.889-.906.002-.001c.491-.155.776-.339.776-.535 0-.197-.285-.381-.776-.536h-.002c-.888-.28-2.447-.465-4.222-.465s-3.334.186-4.221.464l-.003.001c-.491.155-.776.339-.776.535 0 .197.285.381.776.536h.003c.887.28 2.446.465 4.221.465s3.334-.186 4.222-.464m-.555 1.164c-.98.19-2.272.3-3.667.3s-2.687-.11-3.667-.3v3.633c0 .004.001.075.152.203.148.126.393.263.738.387.687.247 1.669.41 2.777.41s2.09-.163 2.777-.41c.345-.124.59-.26.738-.387.15-.128.152-.199.152-.203z"
        clipRule="evenodd"
      />
      <Path d="M9.035 1.263a.333.333 0 0 1 .596 0c.33.661.445.775 1.106 1.106a.333.333 0 0 1 0 .596c-.66.33-.775.445-1.106 1.106a.333.333 0 0 1-.596 0c-.33-.661-.444-.776-1.105-1.106a.333.333 0 0 1 0-.596c.66-.33.775-.445 1.105-1.106M5.702 2.596a.333.333 0 0 1 .596 0 .81.81 0 0 0 .439.44.333.333 0 0 1 0 .596.81.81 0 0 0-.439.439.333.333 0 0 1-.596 0 .81.81 0 0 0-.439-.44.333.333 0 0 1 0-.596.81.81 0 0 0 .439-.439" />
    </Svg>
  );
};
SvgMagicHatOutline.displayName = 'MagicHatOutline';
const Memo = memo(SvgMagicHatOutline);
export default Memo;
