// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNfcFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.552 1.326a.5.5 0 0 1 .707.015c1.7 1.771 2.739 4.102 2.739 6.66s-1.039 4.887-2.739 6.659a.5.5 0 0 1-.721-.693c1.537-1.601 2.46-3.689 2.46-5.967s-.923-4.365-2.46-5.967a.5.5 0 0 1 .014-.707M6.566 2.78a.5.5 0 0 1 .705.053 7.9 7.9 0 0 1 0 10.337.5.5 0 0 1-.758-.652 6.9 6.9 0 0 0 0-9.033.5.5 0 0 1 .053-.705M4.307 4.437a.5.5 0 0 1 .706.031 5.216 5.216 0 0 1 0 7.065.5.5 0 0 1-.737-.675 4.216 4.216 0 0 0 0-5.715.5.5 0 0 1 .031-.706"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNfcFill.displayName = 'NfcFill';
const Memo = memo(SvgNfcFill);
export default Memo;
