// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierCurveBezierVectorFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.168 2.667c-.644 0-1.167.522-1.167 1.166v.334H3.238a1.334 1.334 0 1 0 0 1H4.5a5.83 5.83 0 0 0-2.312 4.166h-.354c-.645 0-1.167.523-1.167 1.167v1.667c0 .644.522 1.166 1.167 1.166H3.5c.645 0 1.167-.522 1.167-1.166V10.5c0-.644-.522-1.167-1.167-1.167h-.307A4.84 4.84 0 0 1 6 5.432V5.5c0 .644.523 1.167 1.167 1.167h1.667C9.479 6.667 10 6.144 10 5.5v-.068a4.84 4.84 0 0 1 2.808 3.901h-.308c-.644 0-1.166.523-1.166 1.167v1.667c0 .644.522 1.166 1.166 1.166h1.667c.644 0 1.167-.522 1.167-1.166V10.5c0-.644-.523-1.167-1.167-1.167h-.354a5.83 5.83 0 0 0-2.312-4.166h1.263a1.334 1.334 0 1 0 0-1H10v-.334c0-.644-.522-1.166-1.166-1.166z" />
    </Svg>
  );
};
SvgBezierCurveBezierVectorFill.displayName = 'BezierCurveBezierVectorFill';
const Memo = memo(SvgBezierCurveBezierVectorFill);
export default Memo;
