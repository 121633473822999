// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRemoveRowDeleteRowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.165 2.667c-1.012 0-1.833.82-1.833 1.833v7c0 1.013.82 1.833 1.833 1.833h4.167a.5.5 0 1 0 0-1H3.165a.833.833 0 0 1-.833-.833v-3h11.833a.5.5 0 0 0 .5-.5V4.5c0-1.012-.82-1.833-1.833-1.833z" />
      <Path d="M11.519 10.813a.5.5 0 1 0-.707.707l1.146 1.147-1.146 1.146a.5.5 0 1 0 .707.707l1.146-1.146 1.147 1.146a.5.5 0 1 0 .707-.707l-1.146-1.146 1.146-1.147a.5.5 0 1 0-.707-.707l-1.147 1.147z" />
    </Svg>
  );
};
SvgRemoveRowDeleteRowFill.displayName = 'RemoveRowDeleteRowFill';
const Memo = memo(SvgRemoveRowDeleteRowFill);
export default Memo;
