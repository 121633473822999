// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShakaCallMeHangTenOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.47 2.823a.5.5 0 0 1 .362-.156h.378a2.56 2.56 0 0 1 2.34 1.517l.533 1.199.515-.514.687-.686a1.473 1.473 0 0 1 2.453.63 1.47 1.47 0 0 1 1.717 1.027 1.47 1.47 0 0 1 1.622.785l.722-.72a1.486 1.486 0 0 1 2.098 0c.568.567.584 1.485.022 2.067-.844.871-2.336 2.392-3.48 3.442a64 64 0 0 1-1.93 1.685c-1.623 1.37-4.03 1.143-5.412-.448L2.57 10.892a3.92 3.92 0 0 1-.956-2.365l-.281-5.334a.5.5 0 0 1 .136-.37m9.626 4.782-.69.689-.005.004-.339.338a.473.473 0 0 1-.667 0 .467.467 0 0 1 0-.662l1.03-1.028a.473.473 0 0 1 .667 0 .467.467 0 0 1 .004.659M8.26 8.4a1.473 1.473 0 0 0 2.508.943l1.03-1.028.006-.006 1.7-1.697a.485.485 0 0 1 .686 0c.186.185.187.48.009.663-.843.872-2.317 2.374-3.436 3.401a63 63 0 0 1-1.9 1.658c-1.193 1.007-2.977.85-4.012-.34l-1.527-1.759a2.92 2.92 0 0 1-.712-1.762l-.254-4.8a1.56 1.56 0 0 1 1.277.917l.83 1.862a.5.5 0 0 0 .81.15l.044-.044q.104.21.279.387c.365.364.873.497 1.343.399A1.466 1.466 0 0 0 8.26 8.4m-.602-2.848a.467.467 0 0 0 0-.662.473.473 0 0 0-.667 0l-.687.685a.467.467 0 0 0 0 .662.473.473 0 0 0 .667 0zm.363 1.05a.467.467 0 0 0 0 .663.473.473 0 0 0 .667 0l.686-.685a.467.467 0 0 0 0-.663.473.473 0 0 0-.667 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShakaCallMeHangTenOutline.displayName = 'ShakaCallMeHangTenOutline';
const Memo = memo(SvgShakaCallMeHangTenOutline);
export default Memo;
