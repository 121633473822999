// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCheckSmallCheckmarkFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.32 4.932a.5.5 0 0 1 .103.7l-4.207 5.666a.5.5 0 0 1-.72.088L4.35 9.616a.5.5 0 0 1 .636-.772l1.74 1.436 3.894-5.245a.5.5 0 0 1 .7-.103"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCheckSmallCheckmarkFill.displayName = 'CheckSmallCheckmarkFill';
const Memo = memo(SvgCheckSmallCheckmarkFill);
export default Memo;
