// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRainbowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 5a7 7 0 0 0-6.985 6.533.5.5 0 1 1-.998-.066 8 8 0 0 1 15.965 0 .5.5 0 0 1-.998.066A7 7 0 0 0 7.999 5m0 2a5 5 0 0 0-4.98 4.545.5.5 0 1 1-.996-.09 6 6 0 0 1 11.95 0 .5.5 0 1 1-.995.09A5 5 0 0 0 7.999 7m0 2a3 3 0 0 0-2.97 2.57.5.5 0 1 1-.99-.14 4 4 0 0 1 7.919 0 .5.5 0 1 1-.99.14A3 3 0 0 0 7.999 9m0 2a1 1 0 0 0-.944.667.5.5 0 0 1-.943-.334 2 2 0 0 1 3.772 0 .5.5 0 1 1-.943.334A1 1 0 0 0 8 11"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRainbowOutline.displayName = 'RainbowOutline';
const Memo = memo(SvgRainbowOutline);
export default Memo;
