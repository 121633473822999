// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPostcardCardNewsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833v-7a.833.833 0 0 0-.833-.833zM8 7.167C7.999 6.522 8.52 6 9.165 6h1.667c.644 0 1.167.522 1.167 1.167v1.666c0 .644-.523 1.167-1.167 1.167H9.165A1.167 1.167 0 0 1 8 8.833zM9.165 7A.167.167 0 0 0 9 7.167v1.666c0 .092.074.167.166.167h1.667a.167.167 0 0 0 .167-.167V7.167A.167.167 0 0 0 10.832 7zM4 6.833a.5.5 0 0 1 .5-.5h1.666a.5.5 0 1 1 0 1H4.5a.5.5 0 0 1-.5-.5m0 2.333a.5.5 0 0 1 .5-.5h1.666a.5.5 0 1 1 0 1H4.5a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPostcardCardNewsOutline.displayName = 'PostcardCardNewsOutline';
const Memo = memo(SvgPostcardCardNewsOutline);
export default Memo;
