// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCornerLeftUpFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        stroke="#0D1011"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.501 13.5h-5.5a2.667 2.667 0 0 1-2.666-2.667v-8m2.666 2L5.806 2.638a.667.667 0 0 0-.943 0L2.668 4.833"
      />
    </Svg>
  );
};
SvgCornerLeftUpFill.displayName = 'CornerLeftUpFill';
const Memo = memo(SvgCornerLeftUpFill);
export default Memo;
