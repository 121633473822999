// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBulletListOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 4a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667M2 4.833a1.833 1.833 0 1 1 3.667 0 1.833 1.833 0 0 1-3.667 0m5.333 0a.5.5 0 0 1 .5-.5H13.5a.5.5 0 0 1 0 1H7.833a.5.5 0 0 1-.5-.5m-3.5 5.5a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667M2 11.167a1.833 1.833 0 1 1 3.667 0 1.833 1.833 0 0 1-3.667 0m5.333 0a.5.5 0 0 1 .5-.5H13.5a.5.5 0 1 1 0 1H7.833a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBulletListOutline.displayName = 'BulletListOutline';
const Memo = memo(SvgBulletListOutline);
export default Memo;
