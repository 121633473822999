// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSignatureSignOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.585 3.008c-.248-.03-.514.021-.797.213-.441.3-.658.653-.743 1.03-.088.39-.042.845.124 1.344.282.85.88 1.727 1.51 2.406.691-.666 1.388-1.102 2.08-1.221a9.6 9.6 0 0 0-.2-1.458c-.172-.745-.623-1.5-1.172-1.94-.27-.218-.544-.342-.802-.374m3.18 3.836a11 11 0 0 0-.232-1.746c-.216-.938-.776-1.9-1.52-2.497-.376-.301-.82-.526-1.307-.586-.497-.06-1.004.056-1.48.379-.64.434-1.013 1-1.156 1.636-.14.623-.052 1.272.15 1.88.346 1.043 1.057 2.061 1.774 2.823q-.116.135-.233.281c-.282.351-.55.84-.75 1.357a7 7 0 0 0-.108.296H1.168a.5.5 0 1 0 0 1h3.496a4 4 0 0 0-.031.345c-.025.526.066 1.114.465 1.528.423.438 1.053.545 1.795.396 1.149-.23 1.854-1.183 2.274-2.27h5.668a.5.5 0 1 0 0-1H9.473c.206-.865.3-1.809.315-2.7.353.248.713.73 1.046 1.55.078.192.208.438.452.564.318.163.608.03.767-.095.148-.116.265-.28.353-.418.094-.148.185-.318.269-.48l.118-.232q.106-.212.203-.39l-.003.138-.002.063v.003c-.004.197-.01.489.068.734a.87.87 0 0 0 .278.419c.16.128.349.178.534.178h.964a.5.5 0 1 0 0-1h-.84a3 3 0 0 1-.004-.312l.002-.067a4 4 0 0 0-.023-.653c-.037-.248-.147-.605-.507-.797-.3-.16-.588-.063-.77.064a1.6 1.6 0 0 0-.377.417c-.15.225-.3.524-.433.785q-.05.1-.097.19a8 8 0 0 1-.136.255c-.457-1.022-1.071-1.78-1.885-2.038m3.192 1.179.002.004zM8.79 7.797c-.38.103-.84.374-1.382.89q.161.129.304.224a.5.5 0 0 1-.55.834 5 5 0 0 1-.439-.326l-.183.223c-.192.238-.4.603-.571 1.025H8.44c.239-.882.343-1.902.349-2.87m-.71 3.87H5.675a3 3 0 0 0-.045.39c-.019.419.07.667.187.789.094.097.316.223.877.11.568-.114 1.033-.558 1.384-1.29M.813 6.813a.5.5 0 0 1 .708 0l.813.813.813-.813a.5.5 0 0 1 .707.707l-.813.813.813.813a.5.5 0 0 1-.707.708l-.813-.814-.813.814a.5.5 0 0 1-.708-.708l.814-.813-.814-.813a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSignatureSignOutline.displayName = 'SignatureSignOutline';
const Memo = memo(SvgSignatureSignOutline);
export default Memo;
