// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVolleyballOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.008 1.333h-.02a6.65 6.65 0 0 0-4.703 1.953m4.723-1.953a6.667 6.667 0 1 1-4.724 11.38l.354-.353-.353.354a6.667 6.667 0 0 1 0-9.428m4.408-.944c.262.582.425 1.286.48 2.026-1.852.156-3.846.895-5.592 1.967a5.64 5.64 0 0 1 1.41-2.342 5.65 5.65 0 0 1 3.702-1.651M2.34 7.692a5.7 5.7 0 0 0 .404 2.433c.54-.629 1.237-1.184 1.988-1.617.922-.533 1.964-.904 2.95-.99.28-.612.45-1.362.497-2.147-1.91.18-4.05 1.054-5.839 2.32m5.4.824c-.67.063-1.404.291-2.107.642 1.117 1.57 2.97 2.982 4.975 3.874a5.7 5.7 0 0 0 1.398-1.025l.314.314-.314-.314q.247-.249.459-.518c-.813-.154-1.642-.48-2.393-.914-.923-.532-1.765-1.248-2.332-2.059m5.314 2.048a5.67 5.67 0 0 0-.93-6.45c.073 2.054-.274 4.165-1.065 5.853.667.323 1.36.534 1.995.597m-2.868-1.093c.8-1.75 1.1-4.058.874-6.24-.7-.45-1.477-.732-2.273-.843.274.781.405 1.662.405 2.529 0 1.065-.198 2.153-.617 3.05.39.548.955 1.07 1.611 1.504m-.874 4.042c-1.819-.96-3.478-2.317-4.545-3.848-.614.417-1.143.911-1.516 1.43q.316.486.74.912l-.322.323.323-.323a5.66 5.66 0 0 0 5.32 1.506"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVolleyballOutline.displayName = 'VolleyballOutline';
const Memo = memo(SvgVolleyballOutline);
export default Memo;
