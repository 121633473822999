// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShieldSecurityProtectionOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.42 1.5a1.83 1.83 0 0 1 1.16 0l4.166 1.388c.749.25 1.254.95 1.254 1.74v3.314c0 1.824-.789 3.171-1.906 4.231-1.098 1.042-2.534 1.825-3.857 2.539a.5.5 0 0 1-.474 0c-1.323-.714-2.76-1.497-3.857-2.539C2.789 11.113 2 9.766 2 7.942V4.628c0-.79.505-1.49 1.254-1.74zm.844.948a.83.83 0 0 0-.528 0L3.57 3.838a.83.83 0 0 0-.57.79v3.314c0 1.49.628 2.589 1.594 3.506.927.88 2.146 1.573 3.406 2.256 1.26-.683 2.479-1.376 3.406-2.256C12.372 10.53 13 9.432 13 7.942V4.628a.83.83 0 0 0-.57-.79z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShieldSecurityProtectionOutline.displayName = 'ShieldSecurityProtectionOutline';
const Memo = memo(SvgShieldSecurityProtectionOutline);
export default Memo;
