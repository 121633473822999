// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCreditCard2CardPaymentFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.332 4.502c0-1.013.82-1.833 1.833-1.833h9.666c1.01 0 1.83.817 1.832 1.829V8h-1.831a1.5 1.5 0 0 0-1.5 1.5v.5h-.5a1.5 1.5 0 0 0-1.5 1.5v1.83H3.165a1.833 1.833 0 0 1-1.833-1.833V7h13.33V6H1.333z" />
      <Path d="M13.332 9.5a.5.5 0 1 0-1 0V11h-1.5a.5.5 0 1 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V12h1.5a.5.5 0 1 0 0-1h-1.5z" />
    </Svg>
  );
};
SvgCreditCard2CardPaymentFill.displayName = 'CreditCard2CardPaymentFill';
const Memo = memo(SvgCreditCard2CardPaymentFill);
export default Memo;
