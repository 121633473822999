// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCookiesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 0 1 7.436-6.623.5.5 0 0 1 .433.595 1.834 1.834 0 0 0 1.552 2.178.5.5 0 0 1 .429.43A1.834 1.834 0 0 0 13.762 6a.5.5 0 0 1 .692.33A6.667 6.667 0 1 1 1.332 8m6.667-5.667a5.667 5.667 0 1 0 5.596 4.77 2.834 2.834 0 0 1-3.333-2.034 2.834 2.834 0 0 1-2.097-2.733z"
        clipRule="evenodd"
      />
      <Path d="M6.665 5.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0M9.332 7.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0M11.999 9.333a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M8.665 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0M5.332 9.333a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0" />
    </Svg>
  );
};
SvgCookiesOutline.displayName = 'CookiesOutline';
const Memo = memo(SvgCookiesOutline);
export default Memo;
