// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedReality3DViewCubeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.409 2.616a.83.83 0 0 0-.818 0L3.52 4.906 8 7.426l4.48-2.52zM13 5.761 8.5 8.292v5.04l4.075-2.292a.83.83 0 0 0 .425-.726zm-5.5 7.572v-5.04L3 5.76v4.553c0 .301.162.579.425.726zM7.101 1.744a1.83 1.83 0 0 1 1.798 0l4.167 2.344c.577.325.934.936.934 1.598v4.628c0 .662-.357 1.273-.934 1.598l-4.167 2.344a1.83 1.83 0 0 1-1.798 0l-4.166-2.344A1.83 1.83 0 0 1 2 10.314V5.686c0-.662.357-1.273.935-1.598z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArAugmentedReality3DViewCubeOutline.displayName = 'ArAugmentedReality3DViewCubeOutline';
const Memo = memo(SvgArAugmentedReality3DViewCubeOutline);
export default Memo;
