// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVisionProGogglesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8 3.333c-1.702 0-3.664.083-5.21.685-.782.305-1.486.753-1.994 1.415C.283 6.102 0 6.95 0 8c0 1.114.272 2.266.902 3.154.644.908 1.645 1.513 3.007 1.513.675 0 1.205-.15 1.644-.386.43-.232.75-.538 1.014-.806l.109-.111c.484-.496.762-.78 1.324-.78s.84.284 1.324.78l.109.111c.263.268.583.574 1.014.806.44.237.969.386 1.644.386 1.366 0 2.367-.62 3.008-1.531C15.727 10.243 16 9.093 16 8c0-1.05-.283-1.898-.796-2.567-.508-.662-1.212-1.11-1.994-1.415-1.546-.602-3.508-.685-5.21-.685" />
    </Svg>
  );
};
SvgVisionProGogglesFill.displayName = 'VisionProGogglesFill';
const Memo = memo(SvgVisionProGogglesFill);
export default Memo;
