// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClinicFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.8 1.73a.4.4 0 0 0-.4-.397c-.22 0-.4.178-.4.396v11.875c0 .219.18.396.4.396s.4-.177.4-.396v-2.77h1.598v.659q.001.303.15.525a.87.87 0 0 0 .338.29 1.1 1.1 0 0 0 .426.108h.018s.002 0 .002-.395v.396h7.733a.95.95 0 0 0 .53-.15.86.86 0 0 0 .294-.333 1 1 0 0 0 .11-.422v-.017s0-.002-.4-.002h.4V3.84a.865.865 0 0 0-.488-.816 1.1 1.1 0 0 0-.427-.107h-.017s-.002 0-.002.395v-.395H5.332a.95.95 0 0 0-.53.149.86.86 0 0 0-.295.333 1 1 0 0 0-.108.422v.018l.4.001h-.4v.66H2.8zm1.598 3.562H2.8v4.75h1.598zm3.436-.56A.8.8 0 0 1 8.4 4.5H10c.212 0 .416.083.566.232.15.148.234.35.234.56v.791h.8c.212 0 .416.084.566.232s.234.35.234.56v1.583c0 .21-.084.412-.234.56a.8.8 0 0 1-.566.232h-.8v.792c0 .21-.084.411-.234.56a.8.8 0 0 1-.566.231H8.4a.8.8 0 0 1-.566-.232.8.8 0 0 1-.234-.56V9.25h-.8a.8.8 0 0 1-.566-.232.8.8 0 0 1-.234-.56V6.875c0-.21.084-.411.234-.56a.8.8 0 0 1 .566-.232h.8v-.791c0-.21.084-.412.234-.56m2.166.56H8.4v1.187c0 .219-.18.396-.4.396H6.8v1.583H8c.22 0 .4.178.4.396v1.188H10V8.854c0-.218.18-.396.4-.396h1.2V6.875h-1.2c-.22 0-.4-.177-.4-.396z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClinicFill.displayName = 'ClinicFill';
const Memo = memo(SvgClinicFill);
export default Memo;
