// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBackwardDeleteRemoveKeyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.74 2.667c-.634 0-1.223.327-1.558.866l-2.172 3.5a1.83 1.83 0 0 0 0 1.934l2.172 3.5c.335.539.924.866 1.558.866h8.092c1.012 0 1.833-.82 1.833-1.833v-7c0-1.013-.82-1.833-1.833-1.833zm2.612 3.48a.5.5 0 0 0-.707.707L7.792 8 6.645 9.146a.5.5 0 1 0 .707.708L8.5 8.707l1.146 1.147a.5.5 0 0 0 .707-.708L9.206 8l1.146-1.146a.5.5 0 0 0-.707-.708L8.5 7.293z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBackwardDeleteRemoveKeyFill.displayName = 'BackwardDeleteRemoveKeyFill';
const Memo = memo(SvgBackwardDeleteRemoveKeyFill);
export default Memo;
