// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderDownloadFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2h2.787c.613 0 1.185.306 1.525.816l.74 1.11c.03.046.083.074.138.074h4.477c1.013 0 1.833.82 1.833 1.833V11.5c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm8.687 6.187-1.667 1.667a.5.5 0 0 1-.707 0L5.978 10.02a.5.5 0 1 1 .708-.707l.813.813v-2.96a.5.5 0 1 1 1 0v2.96l.813-.813a.5.5 0 1 1 .707.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolderDownloadFill.displayName = 'FolderDownloadFill';
const Memo = memo(SvgFolderDownloadFill);
export default Memo;
