// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSidebarMenuListWindowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 2.667c-1.012 0-1.833.82-1.833 1.833v7c0 1.012.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833v-7c0-1.013-.82-1.833-1.833-1.833zM2.332 4.5c0-.46.373-.833.833-.833H7v8.666H3.165a.833.833 0 0 1-.833-.833z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M4.665 6.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m0 2.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m0 2.334a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSidebarMenuListWindowFill.displayName = 'SidebarMenuListWindowFill';
const Memo = memo(SvgSidebarMenuListWindowFill);
export default Memo;
