// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFacebookBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.67 8.04c0-3.704-2.985-6.707-6.667-6.707S1.336 4.336 1.336 8.041c0 3.348 2.438 6.123 5.625 6.626V9.98H5.268V8.04h1.693V6.564c0-1.681.995-2.61 2.518-2.61.73 0 1.492.131 1.492.131v1.651h-.84c-.828 0-1.087.517-1.087 1.048v1.258h1.85l-.296 1.939H9.044v4.687c3.187-.503 5.625-3.278 5.625-6.626" />
    </Svg>
  );
};
SvgFacebookBw.displayName = 'FacebookBw';
const Memo = memo(SvgFacebookBw);
export default Memo;
