// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgScanFocusFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3 4.528C3 3.684 3.684 3 4.528 3h1.389a.417.417 0 0 1 0 .833h-1.39a.694.694 0 0 0-.694.695v1.389a.417.417 0 1 1-.833 0zm6.667-1.111c0-.23.186-.417.416-.417h1.39C12.315 3 13 3.684 13 4.528v1.389a.417.417 0 1 1-.833 0v-1.39a.694.694 0 0 0-.695-.694h-1.389a.417.417 0 0 1-.416-.416m-6.25 6.25c.23 0 .416.186.416.416v1.39c0 .383.311.694.695.694h1.389a.417.417 0 1 1 0 .833h-1.39A1.53 1.53 0 0 1 3 11.472v-1.389c0-.23.187-.416.417-.416m9.166 0c.23 0 .417.186.417.416v1.39c0 .843-.684 1.527-1.528 1.527h-1.389a.417.417 0 0 1 0-.833h1.39c.383 0 .694-.311.694-.695v-1.389c0-.23.186-.416.416-.416"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgScanFocusFill.displayName = 'ScanFocusFill';
const Memo = memo(SvgScanFocusFill);
export default Memo;
