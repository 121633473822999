// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTestTubeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.479 2.147a.5.5 0 0 1 .707 0l4.666 4.666a.5.5 0 1 1-.707.707l-.313-.313-6.646 6.647a2.857 2.857 0 1 1-4.04-4.04l6.646-6.647-.313-.313a.5.5 0 0 1 0-.707m1.02 1.727L5.373 8h5.252l1.5-1.5z"
        clipRule="evenodd"
      />
      <Path d="M14.001 4a.667.667 0 1 1-1.333 0A.667.667 0 0 1 14 4M13.335 1.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
    </Svg>
  );
};
SvgTestTubeFill.displayName = 'TestTubeFill';
const Memo = memo(SvgTestTubeFill);
export default Memo;
