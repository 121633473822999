// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPoolOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.494 2a1.5 1.5 0 0 0-1.5 1.5.5.5 0 0 1-1 0 2.5 2.5 0 0 1 5 0V5h4.01V3.5a1.5 1.5 0 0 0-1.5-1.5.5.5 0 1 1 0-1 2.5 2.5 0 0 1 2.5 2.5v7a.5.5 0 1 1-1 0V9h-4.01v1.5a.5.5 0 0 1-1 0v-7a1.5 1.5 0 0 0-1.5-1.5m2.5 6h4.01V6h-4.01zM4 12a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 1 1 1 0 1.5 1.5 0 0 0 3 0 .5.5 0 1 1 1 0 1.5 1.5 0 0 0 1.5 1.501h.5a.5.5 0 1 1 0 1H14a2.5 2.5 0 0 1-2-1q-.105.141-.232.268A2.5 2.5 0 0 1 8 14a2.5 2.5 0 0 1-2 1 2.5 2.5 0 0 1-2-1 2.5 2.5 0 0 1-2 1h-.5a.5.5 0 1 1 0-1H2a1.5 1.5 0 0 0 1.5-1.5.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPoolOutline.displayName = 'PoolOutline';
const Memo = memo(SvgPoolOutline);
export default Memo;
