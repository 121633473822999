// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Defs, Path, RadialGradient, Stop} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgInstagramColour: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.005 1.333c-2.783 0-3.597.003-3.756.016-.57.048-.926.138-1.314.33-.298.149-.533.32-.766.561q-.634.66-.772 1.622c-.045.312-.058.376-.06 1.969-.002.53 0 1.23 0 2.166 0 2.782.002 3.596.016 3.754.046.556.133.905.318 1.288a2.8 2.8 0 0 0 1.82 1.487c.275.07.578.11.968.128.165.008 1.848.013 3.532.013s3.367-.002 3.528-.01c.451-.022.713-.057 1.003-.132a2.79 2.79 0 0 0 1.82-1.49c.181-.374.273-.737.314-1.264.01-.115.013-1.946.013-3.775 0-1.83-.004-3.658-.013-3.773-.042-.535-.134-.895-.32-1.276a2.6 2.6 0 0 0-.571-.782 2.88 2.88 0 0 0-1.623-.77c-.312-.046-.374-.059-1.967-.062z" />
      <Path d="M8.005 1.333c-2.783 0-3.597.003-3.756.016-.57.048-.926.138-1.314.33-.298.149-.533.32-.766.561q-.634.66-.772 1.622c-.045.312-.058.376-.06 1.969-.002.53 0 1.23 0 2.166 0 2.782.002 3.596.016 3.754.046.556.133.905.318 1.288a2.8 2.8 0 0 0 1.82 1.487c.275.07.578.11.968.128.165.008 1.848.013 3.532.013s3.367-.002 3.528-.01c.451-.022.713-.057 1.003-.132a2.79 2.79 0 0 0 1.82-1.49c.181-.374.273-.737.314-1.264.01-.115.013-1.946.013-3.775 0-1.83-.004-3.658-.013-3.773-.042-.535-.134-.895-.32-1.276a2.6 2.6 0 0 0-.571-.782 2.88 2.88 0 0 0-1.623-.77c-.312-.046-.374-.059-1.967-.062z" />
      <Path d="M8 3c-1.358 0-1.528.006-2.061.03-.533.025-.896.109-1.214.233a2.45 2.45 0 0 0-.886.576 2.46 2.46 0 0 0-.577.886c-.124.318-.208.681-.232 1.213C3.006 6.472 3 6.642 3 8s.006 1.528.03 2.061c.025.532.109.896.233 1.214.127.329.298.607.576.885s.557.45.886.578c.318.123.681.207 1.213.232.534.024.704.03 2.062.03s1.528-.006 2.061-.03c.532-.025.896-.109 1.214-.232.329-.128.608-.3.885-.578.278-.277.45-.556.578-.885.122-.318.207-.682.232-1.214.024-.533.03-.703.03-2.061s-.006-1.528-.03-2.062c-.025-.532-.11-.895-.232-1.213a2.45 2.45 0 0 0-.578-.886 2.44 2.44 0 0 0-.885-.576c-.319-.124-.682-.208-1.215-.233C9.527 3.006 9.357 3 8 3zm-.448.901H8c1.335 0 1.494.005 2.02.029.488.022.753.104.93.172.232.09.399.199.574.374s.283.342.374.575c.068.176.15.44.172.928.024.527.03.685.03 2.02s-.006 1.493-.03 2.02c-.022.487-.104.752-.172.928-.09.233-.2.4-.374.574a1.55 1.55 0 0 1-.575.374c-.176.069-.44.15-.928.172-.527.024-.686.03-2.02.03-1.336 0-1.494-.006-2.021-.03-.488-.022-.752-.104-.929-.172-.233-.09-.4-.199-.575-.374a1.55 1.55 0 0 1-.374-.575c-.068-.176-.15-.44-.172-.928-.024-.527-.029-.685-.029-2.02 0-1.336.005-1.493.03-2.02.021-.488.103-.752.171-.929.09-.233.2-.4.374-.575.175-.175.342-.283.575-.374.177-.068.441-.15.929-.172.461-.02.64-.027 1.572-.028zm3.117.83a.6.6 0 1 0 0 1.2.6.6 0 0 0 0-1.2M8 5.432a2.568 2.568 0 1 0 0 5.136 2.568 2.568 0 0 0 0-5.136m0 .901a1.667 1.667 0 1 1 0 3.334 1.667 1.667 0 0 1 0-3.334" />
      <Defs>
        <RadialGradient
          id="a"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(0 -13.2143 12.2904 0 4.878 15.694)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FD5" />
          <Stop
            offset={0.1}
            stopColor="#FD5"
          />
          <Stop
            offset={0.5}
            stopColor="#FF543E"
          />
          <Stop
            offset={1}
            stopColor="#C837AB"
          />
        </RadialGradient>
        <RadialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(78.681 -1.848 .6)scale(5.90685 24.3483)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#3771C8" />
          <Stop
            offset={0.128}
            stopColor="#3771C8"
          />
          <Stop
            offset={1}
            stopColor="#60F"
            stopOpacity={0}
          />
        </RadialGradient>
      </Defs>
    </Svg>
  );
};
SvgInstagramColour.displayName = 'InstagramColour';
const Memo = memo(SvgInstagramColour);
export default Memo;
