// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolder2OpenFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 2c-1.012 0-1.833.82-1.833 1.833v7.952c0 .855.693 1.548 1.549 1.548H12c.814 0 1.53-.536 1.76-1.316l1.133-3.854A1.167 1.167 0 0 0 14 6.688v-.855c0-1.012-.821-1.833-1.834-1.833h-3.81a.17.17 0 0 1-.138-.074l-.74-1.11A1.83 1.83 0 0 0 5.952 2zM13 6.667v-.834A.833.833 0 0 0 12.165 5h-3.81c-.39 0-.754-.195-.97-.52l-.74-1.109A.83.83 0 0 0 5.952 3H3.165a.833.833 0 0 0-.833.833v7.952a.549.549 0 0 0 1.075.155l1.164-3.957c.23-.78.945-1.316 1.759-1.316z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolder2OpenFill.displayName = 'Folder2OpenFill';
const Memo = memo(SvgFolder2OpenFill);
export default Memo;
