// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgErrorWarningAlertOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.285 2.683a.833.833 0 0 1 1.433 0l4.989 8.391a.833.833 0 0 1-.716 1.26H3.01a.833.833 0 0 1-.715-1.26zm7.281 7.88L9.577 2.172a1.833 1.833 0 0 0-3.152 0l-4.989 8.39c-.727 1.223.154 2.771 1.576 2.771h9.979c1.421 0 2.302-1.548 1.575-2.77m-6.065-4.73a.5.5 0 0 0-1 0V8.5a.5.5 0 0 0 1 0zM8 11a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgErrorWarningAlertOutline.displayName = 'ErrorWarningAlertOutline';
const Memo = memo(SvgErrorWarningAlertOutline);
export default Memo;
