// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWalkingWalkFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.997 4.667c.734 0 1.334-.6 1.334-1.334C9.33 2.6 8.73 2 7.997 2c-.733 0-1.333.6-1.333 1.333 0 .734.6 1.334 1.333 1.334M11.813 7.98c-.186 0-.38-.033-.553-.107a1.2 1.2 0 0 1-.447-.32A4.35 4.35 0 0 0 9.3 6.413 4.7 4.7 0 0 0 7.407 6C6.5 6 5.633 6.34 5 6.94A3.1 3.1 0 0 0 4 9.2c0 .213.087.413.247.567a.89.89 0 0 0 1.2 0 .77.77 0 0 0 .246-.567c0-.353.127-.7.36-.98.234-.28.554-.48.92-.567a8.4 8.4 0 0 1-1.56 5.1.75.75 0 0 0-.126.6.8.8 0 0 0 .36.507c.186.12.42.16.64.12s.42-.167.54-.34c.533-.767.953-1.6 1.253-2.467a4.34 4.34 0 0 1 1.893 2.24c.074.2.234.367.44.46.207.094.44.107.654.034a.86.86 0 0 0 .486-.414.73.73 0 0 0 .034-.613c-.52-1.453-1.62-2.667-3.08-3.367.093-.553.14-1.12.14-1.68.32.16.6.38.826.647.274.333.627.6 1.034.787.406.186.846.28 1.293.28.227 0 .44-.087.6-.234a.778.778 0 0 0 0-1.133.9.9 0 0 0-.6-.233v.026z" />
    </Svg>
  );
};
SvgWalkingWalkFill.displayName = 'WalkingWalkFill';
const Memo = memo(SvgWalkingWalkFill);
export default Memo;
