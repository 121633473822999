// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHeartBeatHeartRatePulseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.347 4.564c1.046 2.614-.287 6.44-6.1 9.705a.5.5 0 0 1-.49 0c-5.813-3.266-7.146-7.09-6.1-9.705C2.17 3.285 3.241 2.373 4.5 2.09c1.146-.256 2.409.018 3.502.942 1.094-.924 2.356-1.198 3.502-.942 1.26.282 2.332 1.194 2.843 2.473M7.45 5.776a.5.5 0 0 0-.886-.017L5.606 7.5H4.5a.5.5 0 0 0 0 1h1.4a.5.5 0 0 0 .438-.259l.641-1.165 1.574 3.148a.5.5 0 0 0 .885.017l.958-1.741H11.5a.5.5 0 0 0 0-1h-1.4a.5.5 0 0 0-.438.259l-.64 1.165z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHeartBeatHeartRatePulseFill.displayName = 'HeartBeatHeartRatePulseFill';
const Memo = memo(SvgHeartBeatHeartRatePulseFill);
export default Memo;
