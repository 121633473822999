// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCodeInsertFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.829 1.167a.5.5 0 0 1 .5.5v1h1.5c1.012 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833h-1.5v1a.5.5 0 1 1-1 0V1.667a.5.5 0 0 1 .5-.5" />
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h6.167v10.666H3.165A1.833 1.833 0 0 1 1.332 11.5zm4.02 1.48a.5.5 0 0 0-.707.707L5.958 8 4.645 9.313a.5.5 0 1 0 .707.707L7.02 8.354a.5.5 0 0 0 0-.708z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCodeInsertFill.displayName = 'CodeInsertFill';
const Memo = memo(SvgCodeInsertFill);
export default Memo;
