// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNotificationsBadgeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.926 2A3.668 3.668 0 0 0 14 7.074v5.093C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167V3.833C2 2.821 2.82 2 3.833 2z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M13.179 2.821a1.667 1.667 0 1 0-2.357 2.357 1.667 1.667 0 0 0 2.357-2.357m.707-.707a2.667 2.667 0 1 0-3.772 3.771 2.667 2.667 0 0 0 3.772-3.77"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNotificationsBadgeFill.displayName = 'NotificationsBadgeFill';
const Memo = memo(SvgNotificationsBadgeFill);
export default Memo;
