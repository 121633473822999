// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAttachment2AttachPaperClipOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.999 4.666a3.333 3.333 0 1 1 6.666 0v5.5a4.5 4.5 0 0 1-4.5 4.5h-.333a4.5 4.5 0 0 1-4.5-4.5v-3a.5.5 0 0 1 1 0v3a3.5 3.5 0 0 0 3.5 3.5h.333a3.5 3.5 0 0 0 3.5-3.5v-5.5a2.333 2.333 0 0 0-4.666 0v5.25a.917.917 0 1 0 1.833 0v-4.75a.5.5 0 0 1 1 0v4.75a1.917 1.917 0 1 1-3.833 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAttachment2AttachPaperClipOutline.displayName = 'Attachment2AttachPaperClipOutline';
const Memo = memo(SvgAttachment2AttachPaperClipOutline);
export default Memo;
