// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayoutTopbarGridWindowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.613 2h6.774c.356 0 .653 0 .896.02.253.02.491.065.716.18.345.176.625.456.801.801.115.225.16.463.18.716.02.243.02.54.02.896V6H2V4.613c0-.356 0-.653.02-.896.02-.253.065-.491.18-.716.176-.345.456-.625.801-.801.225-.115.463-.16.716-.18.243-.02.54-.02.896-.02M2 7v4.387c0 .356 0 .653.02.896.02.253.065.491.18.716.176.345.456.625.801.801.225.115.463.16.716.18.243.02.54.02.896.02H7.5V7zM8.5 14h2.887c.356 0 .653 0 .896-.02.253-.02.491-.065.716-.18.345-.176.625-.456.801-.801.115-.225.16-.463.18-.716.02-.243.02-.54.02-.896V7H8.5z" />
    </Svg>
  );
};
SvgLayoutTopbarGridWindowFill.displayName = 'LayoutTopbarGridWindowFill';
const Memo = memo(SvgLayoutTopbarGridWindowFill);
export default Memo;
