// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgYogaFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m10.088 10.2 1.335 1.733c.706.855-.009 2.068-1.125 2.068a1.5 1.5 0 0 1-1.125-.52L7.91 11.903l-1.06.347v.504c0 .653-.567 1.247-1.353 1.247-.746 0-1.45-.555-1.455-1.285-.01-.195-.023-.367-.036-.525l-.01-.131a5 5 0 0 1-.018-.538c.016-.357.12-.66.431-.947.25-.23.687-.455 1-.616l.003-.002.038-.019c.154-.08.282-.145.374-.199l.005-.002V8.354H3.277C2.572 8.354 2 7.827 2 7.177s.572-1.176 1.277-1.176h9.446c.705 0 1.277.527 1.277 1.176 0 .65-.572 1.177-1.277 1.177H10.1l-.083 1.534.002.016.008.052a2 2 0 0 0 .06.245"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M6 4.001a2 2 0 1 1 4 0 2 2 0 0 1-4 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgYogaFill.displayName = 'YogaFill';
const Memo = memo(SvgYogaFill);
export default Memo;
