// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowPathLeftFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.964 2.908a.833.833 0 0 1 1.368.64v1.785h5.5c1.013 0 1.834.82 1.834 1.833v1.667c0 1.012-.821 1.833-1.834 1.833h-5.5v1.786a.833.833 0 0 1-1.368.639L1.259 9.149a1.5 1.5 0 0 1 0-2.3z" />
    </Svg>
  );
};
SvgArrowPathLeftFill.displayName = 'ArrowPathLeftFill';
const Memo = memo(SvgArrowPathLeftFill);
export default Memo;
