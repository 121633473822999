// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPinLocationBookmarkOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.335 3.167c0-1.013.82-1.833 1.833-1.833h3.667c1.012 0 1.833.82 1.833 1.833v1.498c0 1.15.457 2.251 1.27 3.064l.25.25a.5.5 0 0 1 .147.355v1.833a.5.5 0 0 1-.5.5H8.5v3.5a.5.5 0 1 1-1 0v-3.5H3.168a.5.5 0 0 1-.5-.5V8.334a.5.5 0 0 1 .146-.354l.251-.251a4.33 4.33 0 0 0 1.27-3.064zm1.833-.833a.833.833 0 0 0-.833.833v1.498a5.33 5.33 0 0 1-1.562 3.771l-.105.105v1.126h8.667V8.54l-.105-.105c-1-1-1.562-2.357-1.562-3.771V3.167a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPinLocationBookmarkOutline.displayName = 'PinLocationBookmarkOutline';
const Memo = memo(SvgPinLocationBookmarkOutline);
export default Memo;
