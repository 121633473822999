// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWeb3CryptoSpaceFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.176 11.187c1.436 2.64 4.529 4.062 7.55 3.252s4.99-3.587 4.913-6.592q-.38.212-.8.41c-1.35.64-3.212 1.301-5.272 1.853s-4.004.91-5.494 1.032q-.462.037-.897.045M6.276 1.56c2.895-.776 5.856.497 7.361 2.928.395.001.745.03 1.03.094.175.04.354.1.506.196a.84.84 0 0 1 .378.49c.076.283-.027.536-.14.709a2.1 2.1 0 0 1-.464.48c-.367.29-.899.594-1.536.896-1.281.607-3.083 1.25-5.103 1.791-2.02.542-3.902.886-5.316 1-.702.058-1.314.06-1.778-.008a2.1 2.1 0 0 1-.641-.183c-.185-.094-.4-.26-.477-.545a.84.84 0 0 1 .083-.612c.084-.16.209-.3.341-.423.214-.198.503-.398.844-.596.088-2.858 2.016-5.44 4.912-6.217" />
    </Svg>
  );
};
SvgWeb3CryptoSpaceFill.displayName = 'Web3CryptoSpaceFill';
const Memo = memo(SvgWeb3CryptoSpaceFill);
export default Memo;
