// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile4DocumentCloudSyncFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 1.333h3.5v3.5c0 1.013.821 1.834 1.834 1.834h3.5v6.166c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834V3.167c0-1.013.82-1.834 1.833-1.834m1.667 9.834a.5.5 0 1 0-1 0v1a.5.5 0 0 0 1 0zm1.833-2.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5m2.834 1.833a.5.5 0 1 0-1 0v1.667a.5.5 0 0 0 1 0z"
        clipRule="evenodd"
      />
      <Path d="m12.993 5.618.046.049H9.835A.833.833 0 0 1 9 4.833V1.63l.05.046z" />
    </Svg>
  );
};
SvgFile4DocumentCloudSyncFill.displayName = 'File4DocumentCloudSyncFill';
const Memo = memo(SvgFile4DocumentCloudSyncFill);
export default Memo;
