// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgExplosionBoomBangPopFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.443 1.605a.5.5 0 0 0-.889 0L6.747 3.17a.833.833 0 0 1-1.12.36L2.709 2.038a.5.5 0 0 0-.673.672L3.53 5.628c.21.41.049.91-.36 1.12l-1.566.808a.5.5 0 0 0 0 .889l1.745.899a.83.83 0 0 1 .412.995l-.598 1.87a.5.5 0 0 0 .629.628l1.87-.598a.83.83 0 0 1 .994.412l.9 1.745a.5.5 0 0 0 .888 0l.807-1.566a.833.833 0 0 1 1.12-.36l2.918 1.493a.5.5 0 0 0 .673-.673l-1.493-2.918a.833.833 0 0 1 .36-1.12l1.566-.807a.5.5 0 0 0 0-.89l-1.745-.898a.83.83 0 0 1-.412-.995l.598-1.87a.5.5 0 0 0-.628-.629l-1.87.599a.83.83 0 0 1-.995-.412z" />
    </Svg>
  );
};
SvgExplosionBoomBangPopFill.displayName = 'ExplosionBoomBangPopFill';
const Memo = memo(SvgExplosionBoomBangPopFill);
export default Memo;
