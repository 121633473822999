// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBasket1CartShoppingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.168 2a.5.5 0 0 0 0 1h.863c.41 0 .758.297.823.702l.974 6.088c.142.889.91 1.543 1.81 1.543h5.724c.902 0 1.67-.656 1.81-1.547l.701-4.437A1.167 1.167 0 0 0 12.722 4H3.914l-.073-.456A1.83 1.83 0 0 0 2.031 2zM5.501 14a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667M11.501 14a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667" />
    </Svg>
  );
};
SvgBasket1CartShoppingFill.displayName = 'Basket1CartShoppingFill';
const Memo = memo(SvgBasket1CartShoppingFill);
export default Memo;
