// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLawLegalTermsImprintBalanceOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a.5.5 0 0 1 .5.5v.834h.518c.285 0 .566.066.82.193l.77.385a.8.8 0 0 0 .373.088h3.185a.5.5 0 0 1 0 1h-1.15l1.469 4.654c.248.787-.053 1.806-.993 2.091a3.9 3.9 0 0 1-2.317 0c-.941-.285-1.242-1.304-.994-2.091l1.47-4.654h-.67c-.285 0-.565-.066-.82-.193l-.77-.385a.8.8 0 0 0-.373-.088H8.5V13h3a.5.5 0 0 1 0 1h-7a.5.5 0 1 1 0-1h3V3.667H6.98c-.13 0-.257.03-.373.088l-.77.385a1.8 1.8 0 0 1-.82.193h-.67l1.47 4.654c.249.787-.052 1.806-.993 2.091a3.9 3.9 0 0 1-2.317 0c-.941-.285-1.242-1.304-.993-2.091l1.47-4.654H1.831a.5.5 0 1 1 0-1h3.185q.197 0 .373-.088l.77-.385c.255-.127.536-.193.82-.193H7.5v-.834a.5.5 0 0 1 .5-.5m-4.334 4.16L2.467 9.289c-.13.413.067.753.33.833a2.9 2.9 0 0 0 1.736 0c.264-.08.46-.42.33-.833zm8.667 0-1.198 3.795c-.13.413.066.753.33.833a2.9 2.9 0 0 0 1.736 0c.264-.08.46-.42.33-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLawLegalTermsImprintBalanceOutline.displayName = 'LawLegalTermsImprintBalanceOutline';
const Memo = memo(SvgLawLegalTermsImprintBalanceOutline);
export default Memo;
