// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgColorPickerColorFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m7.02 4.313.647.647 2.37-2.37a1.834 1.834 0 0 1 2.593 0l.78.78a1.834 1.834 0 0 1 0 2.593l-2.37 2.37.647.647a.5.5 0 0 1-.707.707l-.647-.647-4.618 4.618a1.17 1.17 0 0 1-.824.342H3.167A1.167 1.167 0 0 1 2 12.833V11.11c0-.309.123-.606.342-.825L6.96 5.667l-.647-.647a.5.5 0 0 1 .707-.707m.647 2.06 1.96 1.96L7.96 10H4.04z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgColorPickerColorFill.displayName = 'ColorPickerColorFill';
const Memo = memo(SvgColorPickerColorFill);
export default Memo;
