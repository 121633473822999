// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBankFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.665 5.31c0 .741-.594 1.344-1.333 1.356v4.515c.437.21.784.591.945 1.073l.07.21A1.167 1.167 0 0 1 13.24 14H2.757c-.796 0-1.359-.78-1.107-1.536l.07-.21c.161-.482.508-.863.945-1.073V6.666a1.36 1.36 0 0 1-1.238-.857h-.095v-.5c0-.512.288-.98.745-1.211l5.095-2.574a1.83 1.83 0 0 1 1.653 0l5.095 2.574c.457.23.745.7.745 1.211m-4 1.357h1.667V11h-1.667zM5.332 11H3.665V6.667h1.667zm1 0V6.667h3.333V11z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBankFill.displayName = 'BankFill';
const Memo = memo(SvgBankFill);
export default Memo;
