// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBookGuideInfoFaqFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 1.334c-1.012 0-1.833.82-1.833 1.833v9.667c0 1.012.82 1.833 1.833 1.833h7c1.013 0 1.834-.821 1.834-1.833V3.167c0-1.013-.821-1.833-1.834-1.833zm-.833 11.333v.167c0 .46.373.833.833.833h7c.46 0 .834-.373.834-.833v-1.367c-.25.128-.534.2-.834.2H4.668a1 1 0 0 0-1 1m2.167-8.5a.5.5 0 1 0 0 1h4.333a.5.5 0 0 0 0-1zm-.5 3.167a.5.5 0 0 1 .5-.5h2.333a.5.5 0 1 1 0 1H5.835a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBookGuideInfoFaqFill.displayName = 'BookGuideInfoFaqFill';
const Memo = memo(SvgBookGuideInfoFaqFill);
export default Memo;
