// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgOriginalConditionFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1 4.062a3.063 3.063 0 1 1 4.999 2.374l.431 3.167a.876.876 0 0 1-1.482.765l-.887-.887-.886.887a.876.876 0 0 1-1.482-.765l.434-3.167A3.06 3.06 0 0 1 1 4.062m4-.061a1 1 0 1 1-2 0 1 1 0 0 1 2 0m6.757-1.542c-.204.34-.253.72-.253.822V4.5h2.625V3.187a1.313 1.313 0 0 0-1.312-1.312c-.565 0-.873.27-1.06.584m-.752-.448q.042-.07.088-.136H7.566a.438.438 0 1 1 0-.875h5.25a2.19 2.19 0 0 1 2.188 2.187v1.75a.44.44 0 0 1-.437.438h-3.063v7.876A1.75 1.75 0 0 1 9.754 15H3.628a.438.438 0 1 1 0-.875.875.875 0 0 0 .875-.875v-1.313a.438.438 0 1 1 .875 0v1.313c0 .31-.082.611-.234.875h4.61a.876.876 0 0 0 .875-.875V3.28c0-.23.078-.771.376-1.27"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgOriginalConditionFill.displayName = 'OriginalConditionFill';
const Memo = memo(SvgOriginalConditionFill);
export default Memo;
