// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettings3GearPreferencesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.334a.65.65 0 0 0-.543.29l-.416-.277.416.277-.38.57a1.83 1.83 0 0 1-1.938.77l-.469-.109a.68.68 0 0 0-.815.816l.108.469a1.83 1.83 0 0 1-.77 1.937l-.57.38a.652.652 0 0 0 0 1.086l.57.38c.635.423.941 1.194.77 1.938l-.108.468a.68.68 0 0 0 .182.634l-.354.354.354-.354a.68.68 0 0 0 .633.182l.47-.108.112.487-.113-.487a1.83 1.83 0 0 1 1.938.77l.38.57a.652.652 0 0 0 1.085 0l.38-.57a1.83 1.83 0 0 1 1.938-.77l.469.108a.68.68 0 0 0 .816-.816l-.109-.468a1.83 1.83 0 0 1 .77-1.938l.57-.38a.652.652 0 0 0 0-1.086l-.57-.38a1.83 1.83 0 0 1-.77-1.937l.488.112-.488-.112.109-.47a.68.68 0 0 0-.182-.633l.353-.353-.353.353a.68.68 0 0 0-.634-.182l-.469.108a1.83 1.83 0 0 1-1.938-.769l-.38-.57.416-.277-.416.277A.65.65 0 0 0 8 2.334m-1.375-.265a1.652 1.652 0 0 1 2.75 0l-.41.273.41-.273.38.57a.83.83 0 0 0 .88.35l.47-.108a1.68 1.68 0 0 1 2.014 2.015l-.108.469a.83.83 0 0 0 .35.88l.57.38-.278.416.278-.416a1.652 1.652 0 0 1 0 2.75l-.57.38a.83.83 0 0 0-.35.88l.108.47a1.68 1.68 0 0 1-2.015 2.014l-.469-.108a.83.83 0 0 0-.88.35l-.38.57a1.652 1.652 0 0 1-2.75 0l.416-.277-.416.277-.38-.57a.83.83 0 0 0-.88-.35l-.47.108a1.68 1.68 0 0 1-2.015-2.014l.109-.47a.83.83 0 0 0-.35-.88l-.57-.38a1.652 1.652 0 0 1 0-2.75l.277.416-.277-.416.57-.38a.83.83 0 0 0 .35-.88l-.109-.47a1.68 1.68 0 0 1 2.015-2.014l.47.108a.83.83 0 0 0 .88-.35zl.356.238zM5.665 8a2.333 2.333 0 1 1 4.667 0 2.333 2.333 0 0 1-4.667 0M8 6.667a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettings3GearPreferencesOutline.displayName = 'Settings3GearPreferencesOutline';
const Memo = memo(SvgSettings3GearPreferencesOutline);
export default Memo;
