import React from 'react';
import {Pressable, StyleSheet, View} from 'react-native';

import {useHive} from '@propertyguru/hive';
import {Button} from '@propertyguru/hive-button';
import {Icon} from '@propertyguru/hive-icon';
import {CrossedSmallDeleteRemoveOutline as CancelIcon} from '@propertyguru/hive-icons';
import {Text} from '@propertyguru/hive-text';

import {type EducationBannerProps} from './types';

// For Pressable area around the icon.
const HitSlop = {top: 24, bottom: 24, left: 24, right: 24};

export const EducationBanner = ({
  title,
  body,
  actionText,
  onClosePress,
  onActionPress,
  icon,
  actionType = 'link',
  numberOfLinesBody = 1,
  titleTrailingIcon,
  ...props
}: EducationBannerProps) => {
  const hive = useHive();
  const x2 = hive.spacing('x2');
  const x3 = hive.spacing('x3');
  const x4 = hive.spacing('x4');

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: hive.color('fill/neutral/quaternary'),
          borderRadius: hive.borderRadius('medium'),
          padding: x4,
        },
      ]}
      {...props}
    >
      {icon && (
        <View style={{marginRight: x2, marginTop: hive.spacing('x1')}}>
          <Icon
            icon={icon}
            color={'icon/active/tertiary'}
            width={24}
            height={24}
          />
        </View>
      )}
      <View style={styles.descriptionContainer}>
        <View style={styles.titleWrapper}>
          <Text
            typography="label/m"
            style={{color: hive.color('text/active/tertiary')}}
            numberOfLines={1}
          >
            {title}
          </Text>
          {titleTrailingIcon && (
            <View style={{marginLeft: x2}}>
              <Icon
                icon={titleTrailingIcon}
                color={'icon/active/tertiary'}
                width={14}
                height={14}
              />
            </View>
          )}
        </View>
        <Text
          typography="body/xs"
          style={{marginTop: x2, color: hive.color('text/active/tertiary')}}
          numberOfLines={numberOfLinesBody}
        >
          {body}
        </Text>
        {actionType === 'link' && actionText && (
          <Text
            typography="body/xs"
            style={{marginTop: x2, color: hive.color('text/active/tertiary')}}
            underline
            onPress={onActionPress}
            numberOfLines={1}
          >
            {actionText}
          </Text>
        )}
        {actionType === 'button' && actionText && (
          <View style={[styles.button, {marginTop: x3}]}>
            <Button
              onPress={onActionPress}
              type="light"
              text={actionText}
              size="small"
            />
          </View>
        )}
      </View>
      <Pressable
        hitSlop={HitSlop}
        style={{marginLeft: x3}}
        onPress={onClosePress}
      >
        <Icon
          icon={CancelIcon}
          color={'icon/active/tertiary'}
          width={24}
          height={24}
        />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {flexDirection: 'row'},
  descriptionContainer: {flex: 1},
  button: {alignSelf: 'flex-start'},
  titleWrapper: {flexDirection: 'row', alignItems: 'center'},
});
