// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMapPaperFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m2.358 3.631 2.974-.991v9.72l-2.026.676a1.5 1.5 0 0 1-1.974-1.423V5.054a1.5 1.5 0 0 1 1.026-1.423M9.665 13.37l-3.333-1.026V2.63l3.333 1.025zM10.665 13.36l2.975-.991a1.5 1.5 0 0 0 1.025-1.423V4.387a1.5 1.5 0 0 0-1.974-1.423l-2.026.676z" />
    </Svg>
  );
};
SvgMapPaperFill.displayName = 'MapPaperFill';
const Memo = memo(SvgMapPaperFill);
export default Memo;
