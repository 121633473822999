// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMicrophoneMicSoundOffPodcastFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.333 7.667c0 .456-.091.891-.257 1.287l-5.96-5.96a3.333 3.333 0 0 1 6.218 1.672zM2.354 1.646a.5.5 0 0 0-.708.708l3.02 3.02v2.293a3.333 3.333 0 0 0 5.113 2.82l.84.84a4.501 4.501 0 0 1-6.745-1.86.5.5 0 0 0-.916.4A5.5 5.5 0 0 0 7.5 13.144v1.023a.5.5 0 0 0 1 0v-1.023a5.48 5.48 0 0 0 2.834-1.103l2.312 2.313a.5.5 0 0 0 .708-.707z" />
    </Svg>
  );
};
SvgMicrophoneMicSoundOffPodcastFill.displayName = 'MicrophoneMicSoundOffPodcastFill';
const Memo = memo(SvgMicrophoneMicSoundOffPodcastFill);
export default Memo;
