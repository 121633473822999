// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgActivityNotificationPulseHeartbeatBeatFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.996 2.8 4.762 7.014a1.83 1.83 0 0 1-1.76 1.318H1.168a.5.5 0 0 1 0-1h1.834c.37 0 .696-.244.8-.6l1.396-4.768c.234-.801 1.371-.798 1.6.006L9.997 13.16l1.252-4.486a1.83 1.83 0 0 1 1.766-1.341h1.82a.5.5 0 1 1 0 1h-1.82a.83.83 0 0 0-.803.61l-1.41 5.054c-.227.81-1.374.813-1.605.005zm.161-.553-.16-.047z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgActivityNotificationPulseHeartbeatBeatFill.displayName =
  'ActivityNotificationPulseHeartbeatBeatFill';
const Memo = memo(SvgActivityNotificationPulseHeartbeatBeatFill);
export default Memo;
