// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMacinthoshMacFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834v7.666a1.83 1.83 0 0 1-.674 1.42 1 1 0 0 1 .007.08V13.5c0 .644-.522 1.167-1.167 1.167h-7A1.167 1.167 0 0 1 3.335 13.5v-1.167q0-.04.006-.08a1.83 1.83 0 0 1-.673-1.42zm1.833-.834a.833.833 0 0 0-.833.834v7.666c0 .46.373.834.833.834h7c.46 0 .834-.373.834-.834V3.167a.833.833 0 0 0-.834-.834zm.167 1.5a.5.5 0 0 1 .5-.5h5.667a.5.5 0 0 1 .5.5v4.334a.5.5 0 0 1-.5.5H5.168a.5.5 0 0 1-.5-.5zm4 6.334a.5.5 0 0 1 .5-.5h1.667a.5.5 0 1 1 0 1H9.168a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMacinthoshMacFill.displayName = 'MacinthoshMacFill';
const Memo = memo(SvgMacinthoshMacFill);
export default Memo;
