// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCancelCallOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.216 8.077a8 8 0 0 1-.745-.982c-.16-.247-.12-.605.142-.856a1.8 1.8 0 0 0 .479-1.802l-.33-1.121A1.83 1.83 0 0 0 5.003 2H3.815c-.983 0-1.863.804-1.75 1.864a11.3 11.3 0 0 0 1.46 4.482c.411.71.898 1.371 1.449 1.973l-2.828 2.827a.5.5 0 0 0 .708.708l11-11a.5.5 0 0 0-.708-.708zM5.682 9.61c-.49-.54-.924-1.132-1.293-1.767a10.3 10.3 0 0 1-1.33-4.086C3.018 3.37 3.34 3 3.815 3h1.188c.37 0 .695.244.8.598l.33 1.122a.8.8 0 0 1-.213.798c-.557.534-.745 1.415-.289 2.12q.394.61.877 1.147z"
        clipRule="evenodd"
      />
      <Path d="M8.156 11.611a10 10 0 0 1-.992-.655l-.714.714q.575.441 1.204.806a11.3 11.3 0 0 0 4.46 1.448c1.066.118 1.887-.762 1.887-1.757v-1.17c0-.813-.536-1.529-1.316-1.758l-1.122-.33a1.8 1.8 0 0 0-1.801.479c-.251.261-.609.302-.857.142l-.188-.126-.718.717q.178.13.363.248c.706.456 1.586.269 2.121-.288a.8.8 0 0 1 .798-.213l1.121.33c.355.104.599.43.599.8v1.169c0 .477-.378.807-.776.763a10.3 10.3 0 0 1-4.069-1.319" />
    </Svg>
  );
};
SvgCancelCallOutline.displayName = 'CancelCallOutline';
const Memo = memo(SvgCancelCallOutline);
export default Memo;
