// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile7DocumentLockedPasswordOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.667 3.167c0-1.013.82-1.834 1.833-1.834h3.448c.486 0 .952.193 1.296.537l3.552 3.553c.344.343.537.81.537 1.296v6.114c0 1.013-.82 1.834-1.833 1.834H8.833a.5.5 0 0 1 0-1H11.5c.46 0 .833-.373.833-.834V6.72l-.001-.052H9.833A1.833 1.833 0 0 1 8 4.833V2.335l-.052-.002H4.5a.833.833 0 0 0-.833.834v4a.5.5 0 1 1-1 0zM9 3.04v1.793c0 .46.373.834.833.834h1.793zm-6.333 7.627a2 2 0 1 1 4 0v.112c.394.187.666.589.666 1.054V13.5c0 .644-.522 1.167-1.166 1.167h-3A1.167 1.167 0 0 1 2 13.5v-1.667c0-.465.273-.867.667-1.054zm1 0h2a1 1 0 1 0-2 0m-.5 1a.167.167 0 0 0-.167.166V13.5c0 .092.075.167.167.167h3a.167.167 0 0 0 .166-.167v-1.667a.167.167 0 0 0-.166-.166z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFile7DocumentLockedPasswordOutline.displayName = 'File7DocumentLockedPasswordOutline';
const Memo = memo(SvgFile7DocumentLockedPasswordOutline);
export default Memo;
