// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShiftOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.117 2.337a.167.167 0 0 0-.238 0L2.296 8.05a.167.167 0 0 0 .12.283h2.161a.5.5 0 0 1 .5.5v3.334c0 .46.373.833.833.833h4.176c.46 0 .833-.373.833-.833V8.833a.5.5 0 0 1 .5-.5h2.162a.167.167 0 0 0 .12-.283zm-.953-.699a1.167 1.167 0 0 1 1.668 0l5.583 5.713c.722.739.199 1.982-.834 1.982H11.92v2.834c0 1.012-.82 1.833-1.833 1.833H5.91a1.833 1.833 0 0 1-1.833-1.833V9.333H2.415c-1.033 0-1.556-1.243-.834-1.982z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShiftOutline.displayName = 'ShiftOutline';
const Memo = memo(SvgShiftOutline);
export default Memo;
