// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMoonStarNightFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m10.83 3.345.54-1.082a.333.333 0 0 1 .597 0l.54 1.082a.33.33 0 0 0 .15.15l1.082.54a.333.333 0 0 1 0 .596l-1.082.542a.33.33 0 0 0-.15.149l-.54 1.082a.333.333 0 0 1-.596 0l-.542-1.082a.33.33 0 0 0-.149-.15l-1.082-.54a.333.333 0 0 1 0-.597l1.082-.54a.33.33 0 0 0 .15-.15" />
      <Path d="M8.003 1.333a6.667 6.667 0 1 0 6.665 6.531.5.5 0 0 0-.789-.398 3.833 3.833 0 0 1-5.343-5.343.5.5 0 0 0-.398-.788z" />
    </Svg>
  );
};
SvgMoonStarNightFill.displayName = 'MoonStarNightFill';
const Memo = memo(SvgMoonStarNightFill);
export default Memo;
