// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierRemoveDeleteMinusVectorNodesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.167C2 2.522 2.522 2 3.167 2h1.666C5.478 2 6 2.522 6 3.167V3.5h4v-.333C10 2.522 10.522 2 11.167 2h1.666C13.478 2 14 2.522 14 3.167v1.666C14 5.478 13.478 6 12.833 6H12.5v4h.333c.645 0 1.167.522 1.167 1.167v1.666c0 .645-.522 1.167-1.167 1.167h-1.666A1.167 1.167 0 0 1 10 12.833V12.5H5.833v.333c0 .645-.522 1.167-1.166 1.167h-1.5A1.167 1.167 0 0 1 2 12.833v-1.666C2 10.522 2.522 10 3.167 10H3.5V6h-.333A1.167 1.167 0 0 1 2 4.833zM4.5 6v4h.167c.644 0 1.166.522 1.166 1.167v.333H10v-.333c0-.645.522-1.167 1.167-1.167h.333V6h-.333A1.167 1.167 0 0 1 10 4.833V4.5H6v.333C6 5.478 5.478 6 4.833 6zM5 4.833A.167.167 0 0 1 4.833 5H3.167A.167.167 0 0 1 3 4.833V3.167C3 3.075 3.075 3 3.167 3h1.666c.092 0 .167.075.167.167zM11.167 11a.167.167 0 0 0-.167.167v1.666c0 .092.075.167.167.167h1.666a.167.167 0 0 0 .167-.167v-1.666a.167.167 0 0 0-.167-.167zm-6.334.167A.167.167 0 0 0 4.667 11h-1.5a.167.167 0 0 0-.167.167v1.666c0 .092.075.167.167.167h1.5a.167.167 0 0 0 .166-.167zM11.167 3a.167.167 0 0 0-.167.167v1.666c0 .092.075.167.167.167h1.666A.167.167 0 0 0 13 4.833V3.167A.167.167 0 0 0 12.833 3zM6 8a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 6 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBezierRemoveDeleteMinusVectorNodesOutline.displayName =
  'BezierRemoveDeleteMinusVectorNodesOutline';
const Memo = memo(SvgBezierRemoveDeleteMinusVectorNodesOutline);
export default Memo;
