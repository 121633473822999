// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPointerHandFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.457 6.082h3.199c1.57 0 2.843 1.309 2.843 2.923v.423c0 2.893-2.282 5.239-5.097 5.239-1.893 0-3.63-1.078-4.51-2.8L1.868 7.919a.34.34 0 0 1 .034-.362l.401-.515a1.396 1.396 0 0 1 1.998-.229l.667.549V2.61c0-.705.557-1.278 1.244-1.278s1.244.573 1.244 1.279z" />
    </Svg>
  );
};
SvgPointerHandFill.displayName = 'PointerHandFill';
const Memo = memo(SvgPointerHandFill);
export default Memo;
