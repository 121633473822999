import {type Colors, Palette} from '@propertyguru/hive-theme';

import {BaseColors} from './BaseColors';

export const HighContrastColors: Colors = {
  ...BaseColors,
  surface: '#16130E',
  fill: {
    ...BaseColors.fill,
    active: {
      primary: Palette.red[30],
      secondary: Palette.red[100],
      tertiary: Palette.gray[10],
    },
    inactive: {
      primary: Palette.gray[0],
      secondary: Palette.gray[90],
      tertiary: Palette.gray[10],
    },
    pressed: {
      primary: Palette.transparent.black[40],
    },
    hover: {
      primary: Palette.transparent.white[40],
    },
    error: {
      primary: Palette.red[30],
      secondary: Palette.red[100],
      tertiary: Palette.red[10],
    },
    warning: {
      primary: Palette.orange[30],
      secondary: Palette.orange[100],
      tertiary: Palette.orange[10],
    },
    success: {
      primary: Palette.green[30],
      secondary: Palette.green[90],
      tertiary: Palette.green[40],
    },
    neutral: {
      primary: Palette.gray[100],
      secondary: Palette.gray[90],
      tertiary: Palette.gray[80],
      quaternary: Palette.gray[0],
    },
    focus: {
      primary: Palette.blue[30],
    },
  },
  text: {
    ...BaseColors.text,
    active: {
      primary: Palette.gray[0],
      secondary: Palette.gray[20],
      tertiary: Palette.gray[100],
    },
    inactive: {
      primary: Palette.gray[90],
      secondary: Palette.gray[0],
      tertiary: Palette.gray[80],
    },
    pressed: {
      primary: Palette.transparent.white[20],
    },
    hover: {
      primary: Palette.transparent.black[20],
    },
    error: {
      primary: Palette.red[30],
      secondary: Palette.red[100],
      tertiary: Palette.red[40],
    },
    warning: {
      primary: Palette.orange[30],
      secondary: Palette.orange[10],
      tertiary: Palette.orange[40],
    },
    success: {
      primary: Palette.green[30],
      secondary: Palette.green[10],
      tertiary: Palette.green[40],
    },
    neutral: {
      primary: Palette.gray[30],
    },
    focus: {
      primary: Palette.blue[50],
    },
  },
  border: {
    ...BaseColors.border,
    active: {
      primary: Palette.gray[20],
      secondary: Palette.gray[40],
      tertiary: Palette.gray[70],
      quaternary: Palette.red[40],
    },
    inactive: {
      primary: Palette.gray[10],
      secondary: Palette.gray[70],
      tertiary: Palette.gray[30],
    },
    pressed: {
      primary: Palette.transparent.white[20],
    },
    hover: {
      primary: Palette.transparent.black[20],
    },
    error: {
      primary: Palette.red[50],
      secondary: Palette.red[60],
      tertiary: Palette.red[30],
    },
    warning: {
      primary: Palette.orange[60],
      secondary: Palette.orange[10],
      tertiary: Palette.orange[70],
    },
    success: {
      primary: Palette.green[50],
      secondary: Palette.green[70],
      tertiary: Palette.green[30],
    },
    neutral: {
      primary: Palette.gray[50],
    },
    focus: {
      primary: Palette.blue[50],
    },
  },
  icon: {
    ...BaseColors.icon,
    active: {
      primary: Palette.gray[0],
      secondary: Palette.gray[40],
      tertiary: Palette.gray[100],
    },
    inactive: {
      primary: Palette.gray[10],
      secondary: Palette.gray[50],
      tertiary: Palette.gray[10],
    },
    pressed: {
      primary: Palette.transparent.white[20],
    },
    hover: {
      primary: Palette.transparent.black[20],
    },
    error: {
      primary: Palette.red[30],
      secondary: Palette.red[10],
      tertiary: Palette.red[60],
    },
    warning: {
      primary: Palette.orange[50],
      secondary: Palette.orange[10],
      tertiary: Palette.orange[70],
    },
    success: {
      primary: Palette.green[30],
      secondary: Palette.green[10],
      tertiary: Palette.green[90],
    },
    neutral: {
      primary: Palette.gray[50],
    },
    focus: {
      primary: Palette.blue[50],
    },
  },
};
