// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPauseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.501 2c-1.012 0-1.833.82-1.833 1.833v8.334c0 1.012.82 1.833 1.833 1.833h.334c1.012 0 1.833-.82 1.833-1.833V3.833C6.668 2.821 5.848 2 4.835 2zM11.168 2c-1.013 0-1.833.82-1.833 1.833v8.334c0 1.012.82 1.833 1.833 1.833h.333c1.013 0 1.834-.82 1.834-1.833V3.833C13.335 2.821 12.514 2 11.5 2z" />
    </Svg>
  );
};
SvgPauseFill.displayName = 'PauseFill';
const Memo = memo(SvgPauseFill);
export default Memo;
