// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGoogleBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8 6.788V9.37h3.588a3.07 3.07 0 0 1-1.34 2.006l2.164 1.679c1.26-1.164 1.988-2.873 1.988-4.903a7.7 7.7 0 0 0-.121-1.364z" />
      <Path d="m4.27 9.269-.488.373-1.727 1.346a6.67 6.67 0 0 0 5.951 3.679c1.8 0 3.309-.594 4.412-1.612l-2.163-1.68c-.594.4-1.352.643-2.249.643-1.733 0-3.206-1.17-3.733-2.745z" />
      <Path d="M2.051 5.012A6.6 6.6 0 0 0 1.336 8c0 1.079.26 2.09.715 2.988 0 .006 2.218-1.721 2.218-1.721A4 4 0 0 1 4.057 8c0-.443.079-.867.212-1.267zM8.006 3.988c.982 0 1.855.34 2.552.994l1.909-1.91c-1.158-1.078-2.66-1.739-4.46-1.739a6.66 6.66 0 0 0-5.952 3.68l2.218 1.72c.527-1.575 2-2.745 3.733-2.745" />
    </Svg>
  );
};
SvgGoogleBw.displayName = 'GoogleBw';
const Memo = memo(SvgGoogleBw);
export default Memo;
