// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleShadowUserAvatarGroupOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.167 2.333a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667M3.334 4.167a2.833 2.833 0 1 1 5.666 0 2.833 2.833 0 0 1-5.666 0M9.5 1.833a.5.5 0 0 1 .5-.5A2.833 2.833 0 0 1 10 7a.5.5 0 0 1 0-1 1.833 1.833 0 0 0 0-3.667.5.5 0 0 1-.5-.5M6.167 9c-2.318 0-4.241 1.472-4.82 3.316a.42.42 0 0 0 .1.434c.118.132.32.23.553.23h8.334a.75.75 0 0 0 .553-.23.42.42 0 0 0 .1-.434C10.408 10.472 8.485 9 6.167 9M.393 12.017C1.11 9.73 3.439 8 6.167 8s5.056 1.73 5.774 4.017a1.42 1.42 0 0 1-.313 1.405c-.317.35-.792.557-1.294.557H2c-.502 0-.977-.207-1.294-.557a1.42 1.42 0 0 1-.313-1.405m11.303-3.178a.5.5 0 0 1 .638-.304c1.653.585 2.934 1.92 3.436 3.502a1.42 1.42 0 0 1-.31 1.406c-.316.35-.79.557-1.293.557h-.333a.5.5 0 1 1 0-1h.333a.75.75 0 0 0 .552-.228.42.42 0 0 0 .098-.433C14.412 11.063 13.363 9.96 12 9.477a.5.5 0 0 1-.304-.638"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPeopleShadowUserAvatarGroupOutline.displayName = 'PeopleShadowUserAvatarGroupOutline';
const Memo = memo(SvgPeopleShadowUserAvatarGroupOutline);
export default Memo;
