// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMouse2ScrollDownFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4 4.667a4 4 0 1 1 8 0V8a4 4 0 0 1-8 0zm4.5-.834a.5.5 0 0 0-1 0V5.5a.5.5 0 0 0 1 0zM5.584 13.223a.5.5 0 0 1 .693-.139l1.26.84c.28.187.645.187.925 0l1.26-.84a.5.5 0 1 1 .555.832l-1.26.84c-.616.41-1.418.41-2.034 0l-1.26-.84a.5.5 0 0 1-.139-.693"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMouse2ScrollDownFill.displayName = 'Mouse2ScrollDownFill';
const Memo = memo(SvgMouse2ScrollDownFill);
export default Memo;
