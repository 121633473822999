// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShieldBreakNotSecureFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m11.381 10.079-9.9-7.969a.5.5 0 0 0-.627.78L2.17 3.948A1.8 1.8 0 0 0 2 4.716v3.95a6 6 0 0 0 10.687 3.748l1.833 1.476a.5.5 0 0 0 .627-.78l-1.9-1.53.009-.017zM14.001 8.667a6 6 0 0 1-.33 1.97L3.836 2.723l3.448-1.462a1.83 1.83 0 0 1 1.432 0l4.167 1.767a1.83 1.83 0 0 1 1.117 1.688z" />
    </Svg>
  );
};
SvgShieldBreakNotSecureFill.displayName = 'ShieldBreakNotSecureFill';
const Memo = memo(SvgShieldBreakNotSecureFill);
export default Memo;
