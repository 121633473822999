// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSmilingFaceHeartEyesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
      <Path d="M6.234 8.086C7.5 7.082 7.684 6.12 7.31 5.533a1.02 1.02 0 0 0-.818-.471 1.04 1.04 0 0 0-.812.353 1.04 1.04 0 0 0-.883-.054 1.02 1.02 0 0 0-.607.722c-.152.68.351 1.52 1.884 2.032a.17.17 0 0 0 .16-.029M9.926 8.115c1.533-.511 2.036-1.352 1.884-2.032a1.02 1.02 0 0 0-.607-.722 1.04 1.04 0 0 0-.883.054 1.04 1.04 0 0 0-.812-.353 1.02 1.02 0 0 0-.818.47c-.374.588-.19 1.55 1.076 2.554a.17.17 0 0 0 .16.029M8 12c1.347 0 2.461-1 2.64-2.298.028-.196-.145-.35-.342-.333-1.54.137-3.058.137-4.598 0-.196-.018-.37.137-.342.333A2.67 2.67 0 0 0 7.999 12" />
    </Svg>
  );
};
SvgSmilingFaceHeartEyesOutline.displayName = 'SmilingFaceHeartEyesOutline';
const Memo = memo(SvgSmilingFaceHeartEyesOutline);
export default Memo;
