// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTennisBallOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.77 3.343A6.65 6.65 0 0 1 6.664 8a6.65 6.65 0 0 1-1.896 4.657A5.64 5.64 0 0 0 8 13.667a5.64 5.64 0 0 0 3.23-1.01A6.65 6.65 0 0 1 9.331 8c0-1.812.724-3.456 1.896-4.657a5.64 5.64 0 0 0-3.23-1.01c-1.2 0-2.313.373-3.229 1.01m7.229.643A5.65 5.65 0 0 0 10.332 8c0 1.568.637 2.987 1.667 4.014A5.65 5.65 0 0 0 13.665 8 5.65 5.65 0 0 0 12 3.986m-8 8.028A5.65 5.65 0 0 0 5.665 8 5.65 5.65 0 0 0 4 3.986 5.65 5.65 0 0 0 2.332 8c0 1.568.637 2.987 1.667 4.014m-.325-9.088A6.64 6.64 0 0 1 8 1.333c1.65 0 3.16.6 4.324 1.593A6.65 6.65 0 0 1 14.665 8c0 2.032-.91 3.852-2.342 5.074A6.64 6.64 0 0 1 8 14.667c-1.65 0-3.16-.6-4.325-1.593A6.65 6.65 0 0 1 1.332 8c0-2.032.91-3.852 2.342-5.074"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTennisBallOutline.displayName = 'TennisBallOutline';
const Memo = memo(SvgTennisBallOutline);
export default Memo;
