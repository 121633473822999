// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShowEyeSeeRevealLookVisibleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 3.667c-2.154 0-4.375 1.284-5.919 3.993-.12.21-.12.47 0 .68 1.544 2.709 3.765 3.993 5.919 3.993s4.375-1.284 5.919-3.993c.12-.21.12-.47 0-.68C12.376 4.951 10.155 3.667 8 3.667m6.788 3.498-.435.248za1.69 1.69 0 0 1 0 1.67c-1.683 2.952-4.202 4.498-6.788 4.498s-5.105-1.546-6.788-4.498a1.69 1.69 0 0 1 0-1.67c1.683-2.952 4.202-4.498 6.788-4.498s5.105 1.546 6.788 4.498M8 6.333a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334M5.334 8a2.667 2.667 0 1 1 5.334 0 2.667 2.667 0 0 1-5.334 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShowEyeSeeRevealLookVisibleOutline.displayName = 'ShowEyeSeeRevealLookVisibleOutline';
const Memo = memo(SvgShowEyeSeeRevealLookVisibleOutline);
export default Memo;
