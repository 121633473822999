// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBellConciergeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.001 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 1 1 0 1h-1v1.021c3.124.268 5.5 3.037 5.5 6.838v.641a.5.5 0 0 1-.5.5h-5v1H13.5a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1h5.001v-1h-5a.5.5 0 0 1-.5-.5v-.64c0-3.802 2.377-6.57 5.5-6.839V3h-1a.5.5 0 0 1-.5-.5m2 2.5c-2.708 0-5 2.298-5 5.86V11h10v-.14c0-3.562-2.29-5.86-5-5.86"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBellConciergeOutline.displayName = 'BellConciergeOutline';
const Memo = memo(SvgBellConciergeOutline);
export default Memo;
