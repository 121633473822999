// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLightBulbSimpleIdeaFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G
        fill="#0D1011"
        clipPath="url(#a)"
      >
        <Path d="M2.664 6.667a5.334 5.334 0 1 1 8.001 4.62v.046H5.331v-.046a5.35 5.35 0 0 1-2.667-4.62M5.33 12.333v.333a2.667 2.667 0 0 0 5.335 0v-.333z" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgLightBulbSimpleIdeaFill.displayName = 'LightBulbSimpleIdeaFill';
const Memo = memo(SvgLightBulbSimpleIdeaFill);
export default Memo;
