// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWasherOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 2.36C2 1.609 2.596 1 3.33 1h9.34C13.404 1 14 1.61 14 2.36v11.28c0 .751-.596 1.36-1.33 1.36H3.33C2.596 15 2 14.391 2 13.64V2.36M2.962 6v7.64c0 .208.165.377.368.377h9.34a.37.37 0 0 0 .368-.376V6zm10.076-1H2.962V2.36c0-.208.165-.376.368-.376h9.34c.203 0 .368.168.368.376zM4 3.5c0-.276.243-.5.543-.5h1.914c.3 0 .543.224.543.5s-.243.5-.543.5H4.543C4.243 4 4 3.776 4 3.5m6 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m1.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M8 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-.5-3a.6.6 0 0 1 .106-.333c.057-.08.157-.167.394-.167a.5.5 0 0 0 0-1c-.563 0-.963.246-1.206.583-.224.312-.294.67-.294.917a.5.5 0 1 0 1 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWasherOutline.displayName = 'WasherOutline';
const Memo = memo(SvgWasherOutline);
export default Memo;
