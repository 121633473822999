// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStocksFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.5 6.667A2 2 0 0 1 9 4.73V2H3.833C2.821 2 2 2.82 2 3.833v6.793l2.313-2.313a.5.5 0 0 1 .707 0l.98.98 1.718-1.718a2 2 0 0 1-.218-.908" />
      <Path d="M2 12.04v.127C2 13.179 2.82 14 3.833 14H9V8.604a2 2 0 0 1-.618-.279l-2.028 2.029a.5.5 0 0 1-.708 0l-.98-.98zM10 14h2.167C13.179 14 14 13.18 14 12.167V3.833C14 2.821 13.18 2 12.167 2H10v2.73a2 2 0 0 1 0 3.874z" />
      <Path d="M8.5 6.667a1 1 0 1 1 2 0 1 1 0 0 1-2 0" />
    </Svg>
  );
};
SvgStocksFill.displayName = 'StocksFill';
const Memo = memo(SvgStocksFill);
export default Memo;
