// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMailpostMailOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.913 5.074a.42.42 0 0 0 .418-.421.42.42 0 0 0-.418-.422h-.728a2.8 2.8 0 0 0-.157-.595 2.7 2.7 0 0 0-.57-.862 2.6 2.6 0 0 0-.855-.576A2.7 2.7 0 0 0 9.595 2h-3.2c-.344 0-.683.07-1.003.198a2.63 2.63 0 0 0-1.425 1.438 2.5 2.5 0 0 0-.158.595h-.727a.42.42 0 0 0-.418.422.42.42 0 0 0 .418.421h.688v8.083h-.32a.42.42 0 0 0-.417.422.42.42 0 0 0 .418.421h9.093a.42.42 0 0 0 .418-.421.42.42 0 0 0-.418-.422h-.32V5.074zM4.743 3.96a1.79 1.79 0 0 1 .974-.977c.22-.09.437-.12.688-.139h3.2q.353-.001.683.139c.216.09.413.223.58.392.167.168.3.366.388.59q.052.132.089.272h-6.69q.03-.14.088-.272zm1.829 9.198V9.612c0-.085.034-.169.093-.229a.32.32 0 0 1 .226-.094h2.212a.323.323 0 0 1 .32.323v3.545zm3.686 0V9.612c0-.308-.122-.605-.339-.824a1.15 1.15 0 0 0-.816-.342H6.891c-.31 0-.6.12-.816.342-.216.219-.339.516-.339.824v3.545h-1.13V5.074h6.783v8.083z" />
      <Path d="M9.477 6.463h-2.95a.42.42 0 0 0-.418.421.42.42 0 0 0 .418.422h2.95a.42.42 0 0 0 .417-.422.42.42 0 0 0-.418-.421" />
    </Svg>
  );
};
SvgMailpostMailOff.displayName = 'MailpostMailOff';
const Memo = memo(SvgMailpostMailOff);
export default Memo;
