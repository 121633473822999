// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCamera1OffPictureImageCamOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.478 1.48a.5.5 0 0 1 .708 0l12.333 12.333a.5.5 0 1 1-.707.707l-.52-.52H3.165a1.833 1.833 0 0 1-1.833-1.833V5.833C1.332 4.821 2.152 4 3.165 4h.127L1.478 2.187a.5.5 0 0 1 0-.707M4.292 5H3.165a.833.833 0 0 0-.833.833v6.334c0 .46.373.833.833.833h9.127l-2.428-2.428a2.667 2.667 0 0 1-3.77-3.771zM6.8 7.509a1.667 1.667 0 0 0 2.357 2.357zM6 2.5a.5.5 0 0 1 .5-.5h2.453c.613 0 1.185.306 1.525.816l.542.813a.83.83 0 0 0 .693.371h1.12c1.013 0 1.833.82 1.833 1.833v5a.5.5 0 1 1-1 0v-5A.833.833 0 0 0 12.832 5h-1.12a1.83 1.83 0 0 1-1.525-.816l-.542-.813A.83.83 0 0 0 8.952 3H6.499a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCamera1OffPictureImageCamOutline.displayName = 'Camera1OffPictureImageCamOutline';
const Memo = memo(SvgCamera1OffPictureImageCamOutline);
export default Memo;
