// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShuffleRandomFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.812 2.146a.5.5 0 0 1 .707 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 1 1-.707-.708L12.958 5h-1.574a.83.83 0 0 0-.589.244l-6.849 6.844c-.343.344-.81.537-1.296.537h-.818a.5.5 0 0 1 0-1h.818c.221 0 .433-.088.59-.244l6.848-6.844c.344-.344.81-.537 1.296-.537h1.574l-1.146-1.146a.5.5 0 0 1 0-.708M1.332 3.833a.5.5 0 0 1 .5-.5h.781c.486 0 .953.193 1.296.537l2.11 2.11a.5.5 0 0 1-.707.707l-2.11-2.11a.83.83 0 0 0-.589-.244h-.781a.5.5 0 0 1-.5-.5m10.425 5.188a.5.5 0 0 1 .707.001l2.056 2.063a.5.5 0 0 1 0 .705l-2.056 2.063a.5.5 0 0 1-.708-.706l1.205-1.21h-1.663c-.491 0-.962-.197-1.307-.548l-1.349-1.372a.5.5 0 0 1 .713-.7l1.35 1.371c.156.16.37.25.593.25h1.663l-1.205-1.21a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShuffleRandomFill.displayName = 'ShuffleRandomFill';
const Memo = memo(SvgShuffleRandomFill);
export default Memo;
