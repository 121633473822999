// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVideoCamera2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833H9.5c1.012 0 1.833.82 1.833 1.833v.488l1.604-.883a1.167 1.167 0 0 1 1.73 1.023v5.745c0 .887-.952 1.45-1.73 1.022l-1.604-.883v.488c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833H9.5c.46 0 .833-.373.833-.833V9.321l3.086 1.698a.167.167 0 0 0 .247-.146V5.128a.167.167 0 0 0-.247-.146l-3.086 1.697V4.5a.833.833 0 0 0-.833-.833zm3.167 3a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666M3.999 8a2.333 2.333 0 1 1 4.666 0A2.333 2.333 0 0 1 4 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVideoCamera2Outline.displayName = 'VideoCamera2Outline';
const Memo = memo(SvgVideoCamera2Outline);
export default Memo;
