// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChart3StatisticsGraphSignalOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.447 3.777a1.777 1.777 0 1 1 3.553 0v8.446a1.777 1.777 0 1 1-3.553 0zM12.223 3a.777.777 0 0 0-.776.777v8.446a.777.777 0 1 0 1.553 0V3.777A.777.777 0 0 0 12.223 3M6.22 7.333a1.777 1.777 0 1 1 3.553 0v4.89a1.777 1.777 0 0 1-3.553 0zm1.777-.777a.777.777 0 0 0-.777.777v4.89a.777.777 0 0 0 1.553 0v-4.89a.777.777 0 0 0-.776-.777M2 10.295a1.777 1.777 0 1 1 3.553 0v1.928a1.777 1.777 0 1 1-3.553 0zm1.777-.777a.777.777 0 0 0-.777.777v1.928a.777.777 0 1 0 1.553 0v-1.928a.777.777 0 0 0-.776-.777"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChart3StatisticsGraphSignalOutline.displayName = 'Chart3StatisticsGraphSignalOutline';
const Memo = memo(SvgChart3StatisticsGraphSignalOutline);
export default Memo;
