// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPictureInPictureOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3.667a.833.833 0 0 0-.833.833v5.666c0 .46.373.834.833.834h3a.5.5 0 0 1 0 1h-3a1.833 1.833 0 0 1-1.833-1.834V4.5c0-1.013.82-1.833 1.833-1.833H11.5c1.012 0 1.833.82 1.833 1.833v3a.5.5 0 1 1-1 0v-3a.833.833 0 0 0-.833-.833zM8 10.833a1.5 1.5 0 0 1 1.5-1.5h3.666a1.5 1.5 0 0 1 1.5 1.5V12.5a1.5 1.5 0 0 1-1.5 1.5H9.5a1.5 1.5 0 0 1-1.5-1.5zm1.5-.5a.5.5 0 0 0-.5.5V12.5a.5.5 0 0 0 .5.5h3.666a.5.5 0 0 0 .5-.5v-1.667a.5.5 0 0 0-.5-.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPictureInPictureOutline.displayName = 'PictureInPictureOutline';
const Memo = memo(SvgPictureInPictureOutline);
export default Memo;
