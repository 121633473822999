// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClockCircleTimeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.003 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.336 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.336 8m6.667-3.333a.5.5 0 0 1 .5.5v2.626L10.19 9.48a.5.5 0 1 1-.708.707L7.65 8.354A.5.5 0 0 1 7.503 8V5.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClockCircleTimeOutline.displayName = 'ClockCircleTimeOutline';
const Memo = memo(SvgClockCircleTimeOutline);
export default Memo;
