// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUsbConnectionConnectSaveDataFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.167 3.833a.5.5 0 0 0-1 0v1a.5.5 0 1 0 1 0zM9.333 3.333a.5.5 0 0 1 .5.5v1a.5.5 0 1 1-1 0v-1a.5.5 0 0 1 .5-.5" />
      <Path
        fillRule="evenodd"
        d="M3.833 1.333a.5.5 0 0 0-.5.5v4.5H2.5a.5.5 0 0 0-.5.5v6c0 1.013.82 1.834 1.833 1.834h8.334c1.012 0 1.833-.821 1.833-1.834v-6a.5.5 0 0 0-.5-.5h-.833v-4.5a.5.5 0 0 0-.5-.5zm7.834 5v-4H4.333v4z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUsbConnectionConnectSaveDataFill.displayName = 'UsbConnectionConnectSaveDataFill';
const Memo = memo(SvgUsbConnectionConnectSaveDataFill);
export default Memo;
