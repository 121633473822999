// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBag2LuggageBuggageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.334 3a.5.5 0 0 0-.5.5v1.167h4.331v-.334h.002V3.5a.5.5 0 0 0-.5-.5zm4.833 1.667V3.5a1.5 1.5 0 0 0-1.5-1.5H6.334a1.5 1.5 0 0 0-1.5 1.5v.833h-.002v.334H3.165c-1.012 0-1.833.82-1.833 1.833v5.667c0 1.012.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833V6.5c0-1.013-.82-1.833-1.833-1.833zm-.002 1V13h1.667c.46 0 .833-.373.833-.833V6.5a.833.833 0 0 0-.833-.833zm-1 7.333V5.667H5.832V13zm-5.333 0V5.667H3.165a.833.833 0 0 0-.833.833v5.667c0 .46.373.833.833.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBag2LuggageBuggageOutline.displayName = 'Bag2LuggageBuggageOutline';
const Memo = memo(SvgBag2LuggageBuggageOutline);
export default Memo;
