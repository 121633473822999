// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.001 2.667a4.5 4.5 0 0 0-4.146 2.75 4.001 4.001 0 0 0 .813 7.916h7.417a3.25 3.25 0 0 0 .406-6.475A4.5 4.5 0 0 0 8 2.667" />
    </Svg>
  );
};
SvgCloudFill.displayName = 'CloudFill';
const Memo = memo(SvgCloudFill);
export default Memo;
