// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCropFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.332 5.333h-5v5c0 .184.15.334.333.334h5v-5a.333.333 0 0 0-.333-.334" />
      <Path
        fillRule="evenodd"
        d="M3.832 1.333a.5.5 0 0 1 .5.5v1.5h6.5c1.013 0 1.833.821 1.833 1.834v6.5h1.5a.5.5 0 1 1 0 1h-1.5v1.5a.5.5 0 0 1-1 0v-1.5h-6.5a1.833 1.833 0 0 1-1.833-1.834v-6.5h-1.5a.5.5 0 0 1 0-1h1.5v-1.5a.5.5 0 0 1 .5-.5m.5 9.5v-6.5h6.5c.46 0 .833.373.833.834v6.5h-6.5a.833.833 0 0 1-.833-.834"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCropFill.displayName = 'CropFill';
const Memo = memo(SvgCropFill);
export default Memo;
