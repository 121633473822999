// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClockAlertTimerOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.69 1.146a.5.5 0 0 1 0 .708l-2 2a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .707 0m8.626 0a.5.5 0 0 1 .707 0l2 2a.5.5 0 1 1-.707.708l-2-2a.5.5 0 0 1 0-.708M8.003 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.336 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.336 8m6.667-3.167a.5.5 0 0 1 .5.5v2.46l1.52 1.52a.5.5 0 1 1-.707.707L7.649 8.354A.5.5 0 0 1 7.503 8V5.333a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClockAlertTimerOutline.displayName = 'ClockAlertTimerOutline';
const Memo = memo(SvgClockAlertTimerOutline);
export default Memo;
