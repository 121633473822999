// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgServer1StorageDataCoinsMoneyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.775 2.5c.306.253.56.607.56 1.047v3.58c0 .173-.08.386-.314.62-.24.24-.6.473-1.093.686-.98.42-2.367.694-3.927.694s-2.946-.274-3.926-.694c-.494-.213-.86-.446-1.094-.686-.233-.234-.313-.447-.313-.62v-3.58c0-.44.253-.794.56-1.047.307-.26.72-.467 1.187-.633.94-.334 2.206-.534 3.586-.534s2.647.2 3.587.534c.467.166.88.373 1.187.633m-8.027.313c-.407.14-.7.3-.88.454-.187.16-.2.253-.2.286 0 .034.013.134.2.287.18.147.473.307.88.453.813.287 1.96.474 3.253.474s2.447-.187 3.254-.474c.406-.14.7-.3.88-.453.186-.16.2-.253.2-.287s-.014-.133-.2-.286c-.18-.147-.474-.307-.88-.454C10.44 2.527 9.295 2.34 8 2.34c-1.293 0-2.446.187-3.253.473"
        clipRule="evenodd"
      />
      <Path d="M3.681 9.354c1.127.486 2.66.773 4.32.773s3.187-.287 4.32-.773q.572-.24 1.014-.56v3.66c0 .446-.254.8-.56 1.053-.307.26-.72.466-1.187.633-.94.333-2.207.534-3.587.534s-2.646-.2-3.586-.534c-.467-.167-.88-.373-1.187-.633s-.56-.614-.56-1.053v-3.66c.293.213.64.4 1.013.56" />
    </Svg>
  );
};
SvgServer1StorageDataCoinsMoneyFill.displayName = 'Server1StorageDataCoinsMoneyFill';
const Memo = memo(SvgServer1StorageDataCoinsMoneyFill);
export default Memo;
