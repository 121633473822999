// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCopy1LayersPagesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 2.667c0-.737.597-1.334 1.333-1.334h6.667c.736 0 1.333.597 1.333 1.334v2.666h2.667c.736 0 1.333.597 1.333 1.334v6.666c0 .737-.597 1.334-1.333 1.334H6.665a1.333 1.333 0 0 1-1.333-1.334v-2.666H2.665a1.333 1.333 0 0 1-1.333-1.334zm8.333 2.666h-3c-.736 0-1.333.597-1.333 1.334v3H2.665a.333.333 0 0 1-.333-.334V2.667c0-.185.15-.334.333-.334h6.667c.184 0 .333.15.333.334z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCopy1LayersPagesFill.displayName = 'Copy1LayersPagesFill';
const Memo = memo(SvgCopy1LayersPagesFill);
export default Memo;
