// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChartStatisticsFlipchartPresentationGraph2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3a.833.833 0 0 0-.833.833v7c0 .46.373.834.833.834h9.667c.46 0 .833-.373.833-.834v-7A.833.833 0 0 0 12.832 3zm-1.833.833C1.332 2.821 2.152 2 3.165 2h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.834-1.833 1.834h-2.17l.484 1.696a.5.5 0 0 1-.961.274l-.563-1.97H6.376l-.563 1.97a.5.5 0 0 1-.962-.274l.485-1.696h-2.17a1.833 1.833 0 0 1-1.834-1.834zm6.667.834a.5.5 0 0 1 .5.5V9.5a.5.5 0 0 1-1 0V5.167a.5.5 0 0 1 .5-.5m2.833 2a.5.5 0 0 1 .5.5V9.5a.5.5 0 1 1-1 0V7.167a.5.5 0 0 1 .5-.5M5.165 8a.5.5 0 0 1 .5.5v1a.5.5 0 1 1-1 0v-1a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChartStatisticsFlipchartPresentationGraph2Outline.displayName =
  'ChartStatisticsFlipchartPresentationGraph2Outline';
const Memo = memo(SvgChartStatisticsFlipchartPresentationGraph2Outline);
export default Memo;
