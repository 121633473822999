// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCryptoOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.417-2.55L5.915 6.51a.5.5 0 0 0-.25.433v2.116a.5.5 0 0 0 .25.433L7.75 10.55a.5.5 0 0 0 .5 0l1.833-1.059a.5.5 0 0 0 .25-.433V6.942a.5.5 0 0 0-.25-.433L8.249 5.45a.5.5 0 0 0-.5 0m-.5-.866a1.5 1.5 0 0 1 1.5 0l1.833 1.059a1.5 1.5 0 0 1 .75 1.299v2.116a1.5 1.5 0 0 1-.75 1.3l-1.833 1.058a1.5 1.5 0 0 1-1.5 0l-1.834-1.059a1.5 1.5 0 0 1-.75-1.299V6.942a1.5 1.5 0 0 1 .75-1.3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCryptoOutline.displayName = 'CryptoOutline';
const Memo = memo(SvgCryptoOutline);
export default Memo;
