// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgYoutubeBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.003 12.633c.7-.1 1.266-.633 1.4-1.333.133-.767.266-1.9.3-3.3 0-1.4-.167-2.533-.3-3.3-.134-.7-.7-1.233-1.4-1.333a35 35 0 0 0-5-.367c-2.067 0-3.9.2-5 .367-.7.1-1.267.633-1.4 1.333A19.5 19.5 0 0 0 1.336 8c0 1.4.133 2.533.267 3.3.133.7.7 1.233 1.4 1.333 1.133.167 2.966.367 5 .367 2.066 0 3.9-.2 5-.367M10.664 8l-4-2.333v4.666z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgYoutubeBw.displayName = 'YoutubeBw';
const Memo = memo(SvgYoutubeBw);
export default Memo;
