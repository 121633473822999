// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCreditCard3CardPaymentFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 2.669c-1.012 0-1.833.82-1.833 1.833V6h13.33v1H1.333v4.497c0 1.013.82 1.834 1.833 1.834h9.664c1.013 0 1.834-.821 1.834-1.834V4.498c-.001-1.012-.822-1.83-1.832-1.83zM4.5 8.333a.5.5 0 0 0 0 1h2a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCreditCard3CardPaymentFill.displayName = 'CreditCard3CardPaymentFill';
const Memo = memo(SvgCreditCard3CardPaymentFill);
export default Memo;
