// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMegaphoneLoudSpeakPromoteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.291 1.889a1.833 1.833 0 0 1 2.374 1.751v1.243a2.5 2.5 0 0 1 0 4.9v1.243a1.833 1.833 0 0 1-2.374 1.752l-1.435-.443a2.5 2.5 0 0 1-4.857-.835v-.665l-1.374-.424a1.83 1.83 0 0 1-1.293-1.752V6.008c0-.805.524-1.515 1.293-1.752l1.717-.53.021-.007zM5 11.144v.356a1.5 1.5 0 0 0 2.9.54zm8.666-3.81a1.5 1.5 0 0 1-1 1.414v-2.83a1.5 1.5 0 0 1 1 1.415M4 4.877v4.911l-1.08-.334a.83.83 0 0 1-.588-.796V6.008c0-.366.238-.689.588-.797z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMegaphoneLoudSpeakPromoteFill.displayName = 'MegaphoneLoudSpeakPromoteFill';
const Memo = memo(SvgMegaphoneLoudSpeakPromoteFill);
export default Memo;
