import React from 'react';
import {Pressable} from 'react-native';

import {elevationStyle} from '../compatStyle';
import type {ElevationProps} from './ElevationProps';

export const ElevationCompat: React.FC<ElevationProps> = ({elevation, style, ...props}) => {
  return (
    <Pressable
      style={(state) => [
        elevationStyle(elevation),
        typeof style === 'function' ? style(state) : style,
      ]}
      {...props}
    />
  );
};
