// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddColumnAddPageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.832 3.667H8.499v9.166a.5.5 0 0 1-.5.5H3.165A1.833 1.833 0 0 1 1.332 11.5v-7c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v2.333a.5.5 0 0 1-1 0V4.5a.833.833 0 0 0-.833-.833" />
      <Path d="M13.165 9.167a.5.5 0 0 0-1 0v1.666H10.5a.5.5 0 0 0 0 1h1.666V13.5a.5.5 0 1 0 1 0v-1.667h1.667a.5.5 0 1 0 0-1h-1.667z" />
    </Svg>
  );
};
SvgAddColumnAddPageFill.displayName = 'AddColumnAddPageFill';
const Memo = memo(SvgAddColumnAddPageFill);
export default Memo;
