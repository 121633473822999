// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBroadbandInternetModemOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.828 1.345c.243.057.395.3.338.544l-1.165 5.01h1.18c.822 0 1.488.667 1.488 1.489v3.236c0 .822-.666 1.489-1.488 1.489h-.327v1.1a.453.453 0 0 1-.906 0v-1.1H4.056v1.1a.453.453 0 0 1-.906 0v-1.1h-.325a1.49 1.49 0 0 1-1.49-1.489V8.388c0-.822.667-1.489 1.49-1.489H4L2.835 1.89a.453.453 0 1 1 .883-.205L4.93 6.899h6.14l1.213-5.215a.453.453 0 0 1 .544-.34m-.414 10.862H2.825a.583.583 0 0 1-.583-.583V8.388c0-.322.26-.582.583-.582H13.18c.321 0 .582.26.582.582v3.236c0 .322-.26.583-.582.583zm-5.693-2.2c0-.25.203-.454.453-.454h4.519a.453.453 0 0 1 0 .906H7.174a.453.453 0 0 1-.453-.453m-1.488 0a.71.71 0 0 0-.71-.713h-.002a.712.712 0 0 0-.002 1.424h.002a.71.71 0 0 0 .712-.712"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBroadbandInternetModemOutline.displayName = 'BroadbandInternetModemOutline';
const Memo = memo(SvgBroadbandInternetModemOutline);
export default Memo;
