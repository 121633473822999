// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHand4FingerSelectOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.168 2.333a.833.833 0 0 0-.833.834v3.666a.5.5 0 1 1-1 0v-3a.833.833 0 0 0-1.667 0v2.834a1 1 0 0 1-.007.083 1 1 0 0 1 .007.083V7.5a.5.5 0 0 1-.812.39l-.626-.5a.833.833 0 0 0-1.171.13l-.296.37L4.6 11.38a4.28 4.28 0 0 0 8.068-1.994V7.5a.5.5 0 0 1 .028-.167.5.5 0 0 1-.028-.166V4.5a.833.833 0 0 0-1.667 0v2.833a.5.5 0 0 1-1 0V3.167a.833.833 0 0 0-.833-.834m1.81.545a1.833 1.833 0 0 1 2.69 1.622v2.667a.5.5 0 0 1-.028.166.5.5 0 0 1 .028.167v1.886a5.28 5.28 0 0 1-9.953 2.46l-1.99-3.78a.5.5 0 0 1 .053-.545l.5-.626a1.834 1.834 0 0 1 2.39-.417V3.833a1.833 1.833 0 0 1 2.872-1.51 1.833 1.833 0 0 1 3.439.555"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHand4FingerSelectOutline.displayName = 'Hand4FingerSelectOutline';
const Memo = memo(SvgHand4FingerSelectOutline);
export default Memo;
