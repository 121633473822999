// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChecklistBoxCheckListNotificationOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v3.334a.5.5 0 0 1-1 0V3.833A.833.833 0 0 0 12.167 3H3.833A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h4a.5.5 0 0 1 0 1h-4A1.833 1.833 0 0 1 2 12.167zm5.326.935a.5.5 0 0 1 .1.7L6.18 7.132a.5.5 0 0 1-.678.116l-.75-.5a.5.5 0 1 1 .555-.832l.356.237.964-1.285a.5.5 0 0 1 .7-.1M8.706 6a.5.5 0 0 1 .5-.5h1.666a.5.5 0 0 1 0 1H9.205a.5.5 0 0 1-.5-.5m.627 5.333a2.667 2.667 0 0 1 5.334 0V13.5a.5.5 0 0 1-.5.5H9.833a.5.5 0 0 1-.5-.5zM12 9.667c-.92 0-1.667.746-1.667 1.666V13h3.334v-1.667c0-.92-.746-1.666-1.667-1.666m-4.674-.898a.5.5 0 0 1 .1.7L6.18 11.133a.5.5 0 0 1-.678.116l-.75-.5a.5.5 0 1 1 .555-.832l.356.237.964-1.285a.5.5 0 0 1 .7-.1"
        clipRule="evenodd"
      />
      <Path d="M12.001 14.666c.737 0 1.334-.597 1.334-1.333h-2.667c0 .736.597 1.333 1.333 1.333" />
    </Svg>
  );
};
SvgChecklistBoxCheckListNotificationOutline.displayName =
  'ChecklistBoxCheckListNotificationOutline';
const Memo = memo(SvgChecklistBoxCheckListNotificationOutline);
export default Memo;
