// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudSimpleArrowDownDownloadOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 8c0-2.94 2.36-5.333 5.283-5.333 1.944 0 3.64 1.06 4.557 2.632a.38.38 0 0 0 .373.166q.264-.036.537-.036c2.168 0 3.917 1.774 3.917 3.952a3.95 3.95 0 0 1-2.865 3.808.5.5 0 1 1-.27-.963 2.95 2.95 0 0 0 2.135-2.845c0-1.636-1.311-2.952-2.917-2.952q-.204 0-.4.027a1.38 1.38 0 0 1-1.374-.654A4.27 4.27 0 0 0 5.95 3.667c-2.36 0-4.283 1.935-4.283 4.333a4.34 4.34 0 0 0 2.24 3.81.5.5 0 0 1-.481.876A5.34 5.34 0 0 1 .668 8m7.333.667a.5.5 0 0 1 .5.5v2.96l.813-.814a.5.5 0 1 1 .707.707l-1.666 1.667a.5.5 0 0 1-.707 0L5.98 12.02a.5.5 0 1 1 .707-.707l.813.813v-2.96a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudSimpleArrowDownDownloadOutline.displayName = 'CloudSimpleArrowDownDownloadOutline';
const Memo = memo(SvgCloudSimpleArrowDownDownloadOutline);
export default Memo;
