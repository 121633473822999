// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCornerRightUpOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.167 3.374v7.46A3.167 3.167 0 0 1 8 14H2.5a.5.5 0 0 1 0-1H8c1.197 0 2.167-.97 2.167-2.167v-7.46L8.354 5.188a.5.5 0 1 1-.708-.707l2.196-2.195a1.167 1.167 0 0 1 1.65 0l-.354.353.354-.353 2.195 2.195a.5.5 0 0 1-.707.707z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCornerRightUpOutline.displayName = 'CornerRightUpOutline';
const Memo = memo(SvgCornerRightUpOutline);
export default Memo;
