// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgConnectDevicesMacbookIphonePhoneOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.001 3.833C2.001 2.821 2.822 2 3.835 2h8.333c1.013 0 1.833.82 1.833 1.833v1.57c.77.217 1.334.924 1.334 1.764v5.666c0 1.013-.821 1.834-1.834 1.834h-2.666A1.83 1.83 0 0 1 9.42 14H2.501a1.833 1.833 0 0 1-1.833-1.833v-1a.5.5 0 0 1 .5-.5h.833zm1 6.834h6v-3.5c0-1.013.821-1.834 1.834-1.834H13v-1.5A.833.833 0 0 0 12.168 3H3.835A.833.833 0 0 0 3 3.833zm6 1H1.668v.5c0 .46.373.833.833.833H9.01A2 2 0 0 1 9 12.833zm1-4.5c0-.46.373-.834.834-.834H13.5c.46 0 .834.373.834.834v5.666c0 .46-.374.834-.834.834h-2.666a.833.833 0 0 1-.834-.834zm1 5a.5.5 0 0 1 .5-.5h1.5a.5.5 0 1 1 0 1h-1.5a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgConnectDevicesMacbookIphonePhoneOutline.displayName = 'ConnectDevicesMacbookIphonePhoneOutline';
const Memo = memo(SvgConnectDevicesMacbookIphonePhoneOutline);
export default Memo;
