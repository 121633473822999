// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPoundCurrencyMoneyCoinGbpOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m7.057-3c-.75 0-1.326.581-1.326 1.26 0 .357.143.7.31.973.072.117.144.214.202.286h1.91a.5.5 0 0 1 0 1H7.663l-.727 1.814h2.549a.5.5 0 1 1 0 1H6.197a.5.5 0 0 1-.464-.685l.853-2.13h-.754a.5.5 0 0 1 0-1h.556a2.8 2.8 0 0 1-.325-1.259C6.063 4.994 7.123 4 8.389 4c.627 0 1.199.241 1.619.637a.5.5 0 0 1-.685.728A1.36 1.36 0 0 0 8.389 5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPoundCurrencyMoneyCoinGbpOutline.displayName = 'PoundCurrencyMoneyCoinGbpOutline';
const Memo = memo(SvgPoundCurrencyMoneyCoinGbpOutline);
export default Memo;
