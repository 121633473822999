// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedReality3DViewCube2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.456 11.64 8 8.57l-5.456 3.07q.17.166.383.285l4.167 2.344c.562.317 1.25.317 1.812 0l4.167-2.344q.212-.12.383-.286M13.958 10.774 8.5 7.704V1.562q.211.06.406.169l4.167 2.344c.582.327.943.943.943 1.611v4.628q0 .237-.059.46M7.5 1.562q-.21.06-.406.169L2.927 4.075a1.85 1.85 0 0 0-.943 1.611v4.628q0 .237.059.46L7.5 7.704z" />
    </Svg>
  );
};
SvgArAugmentedReality3DViewCube2Fill.displayName = 'ArAugmentedReality3DViewCube2Fill';
const Memo = memo(SvgArAugmentedReality3DViewCube2Fill);
export default Memo;
