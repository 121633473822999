// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettings8Switch2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 8a4.667 4.667 0 0 1 4.667-4.666h5.333a4.667 4.667 0 0 1 0 9.333H5.335A4.667 4.667 0 0 1 .668 8m7.583 0a2.417 2.417 0 1 1 4.834 0A2.417 2.417 0 0 1 8.25 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettings8Switch2Fill.displayName = 'Settings8Switch2Fill';
const Memo = memo(SvgSettings8Switch2Fill);
export default Memo;
