// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMedalTrophyBadgeWinnerWinOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 1.667a4.333 4.333 0 1 0 0 8.666 4.333 4.333 0 0 0 0-8.667M2.668 6a5.333 5.333 0 1 1 8.667 4.163v4.216a1 1 0 0 1-1.426.904L8 14.386l-1.907.897a1 1 0 0 1-1.426-.904v-4.216c-1.22-.977-2-2.479-2-4.163m3 4.797v3.582l1.907-.898a1 1 0 0 1 .852 0l1.908.898v-3.582A5.3 5.3 0 0 1 8 11.333a5.3 5.3 0 0 1-2.333-.536"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMedalTrophyBadgeWinnerWinOutline.displayName = 'MedalTrophyBadgeWinnerWinOutline';
const Memo = memo(SvgMedalTrophyBadgeWinnerWinOutline);
export default Memo;
