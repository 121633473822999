// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalculatorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834v9.666c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834zm1.833-.834a.833.833 0 0 0-.833.834v1.5h8.667v-1.5a.833.833 0 0 0-.834-.834zm7.834 3.334H3.668v7.166c0 .46.373.834.833.834h7c.46 0 .834-.373.834-.834z"
        clipRule="evenodd"
      />
      <Path d="M6.667 8.167a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0M6.667 11.167a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0M10 8.167a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0M10 11.167a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0" />
      <Path
        fillRule="evenodd"
        d="M7.001 8.167a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m0 3a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m3.334-3a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m0 3a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m-4-2.667a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667m0 3a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667M10 8.167a.333.333 0 1 1-.666 0 .333.333 0 0 1 .666 0M9.668 11.5a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCalculatorOutline.displayName = 'CalculatorOutline';
const Memo = memo(SvgCalculatorOutline);
export default Memo;
