// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCamera4ActionCamGoproOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3.667a.833.833 0 0 0-.833.833v7.667c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833v-1.334a.5.5 0 1 1 1 0v1.334c0 1.012-.82 1.833-1.833 1.833H3.165a1.833 1.833 0 0 1-1.833-1.833V4.5c0-1.013.82-1.833 1.833-1.833H6.5a.5.5 0 1 1 0 1zM8 4.5c0-1.013.82-1.833 1.833-1.833h3c1.013 0 1.833.82 1.833 1.833v3.002c0 1.012-.82 1.833-1.833 1.833h-3a1.833 1.833 0 0 1-1.833-1.833zm1.833-.833a.833.833 0 0 0-.833.833v3.002c0 .46.373.833.833.833h3c.46 0 .833-.373.833-.833V4.5a.833.833 0 0 0-.833-.833zm-5.833 7.166a.5.5 0 0 1 .5-.5h1.666a.5.5 0 1 1 0 1H4.5a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <Path d="M11 6a.333.333 0 1 1 .667 0A.333.333 0 0 1 11 6" />
      <Path
        fillRule="evenodd"
        d="M11.335 6.667a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334m0-1a.333.333 0 1 0 0 .666.333.333 0 0 0 0-.666"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCamera4ActionCamGoproOutline.displayName = 'Camera4ActionCamGoproOutline';
const Memo = memo(SvgCamera4ActionCamGoproOutline);
export default Memo;
