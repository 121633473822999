// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPrinterPrintOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill={color}
      viewBox="0 0 24 24"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6 4.75A2.75 2.75 0 0 1 8.75 2h6.5A2.75 2.75 0 0 1 18 4.75V6h1.25A2.75 2.75 0 0 1 22 8.75v6.5A2.75 2.75 0 0 1 19.25 18H18v1.25A2.75 2.75 0 0 1 15.25 22h-6.5A2.75 2.75 0 0 1 6 19.25V18H4.75A2.75 2.75 0 0 1 2 15.25v-6.5A2.75 2.75 0 0 1 4.75 6H6zM7.5 6h9V4.75c0-.69-.56-1.25-1.25-1.25h-6.5c-.69 0-1.25.56-1.25 1.25zM4.75 7.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25H6v-2.75a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 .75.75v2.75h1.25c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25zm2.75 7v4.75c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V14.5zM6 10.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPrinterPrintOutline.displayName = 'PrinterPrintOutline';
const Memo = memo(SvgPrinterPrintOutline);
export default Memo;
