// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMoonStarNightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.246 2.383a5.668 5.668 0 1 0 6.373 6.374 4.833 4.833 0 0 1-6.374-6.374M1.336 8a6.667 6.667 0 0 1 6.803-6.665.5.5 0 0 1 .398.788 3.833 3.833 0 0 0 5.343 5.343.5.5 0 0 1 .788.398l.001.136A6.667 6.667 0 0 1 1.336 8"
        clipRule="evenodd"
      />
      <Path d="m10.83 3.345.54-1.082a.333.333 0 0 1 .597 0l.541 1.082a.33.33 0 0 0 .149.15l1.082.54a.333.333 0 0 1 0 .596l-1.082.542a.33.33 0 0 0-.149.149l-.541 1.082a.333.333 0 0 1-.596 0l-.541-1.082a.33.33 0 0 0-.15-.15l-1.082-.54a.333.333 0 0 1 0-.597l1.082-.54a.33.33 0 0 0 .15-.15" />
    </Svg>
  );
};
SvgMoonStarNightOutline.displayName = 'MoonStarNightOutline';
const Memo = memo(SvgMoonStarNightOutline);
export default Memo;
