// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgKeyboardMidiKeysPianoOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v9c0 1.013-.82 1.834-1.833 1.834H3.833A1.833 1.833 0 0 1 2 12.833zM10.667 3h-1v5h1zm-2 0H7.333v5.5a.5.5 0 0 1-.5.5h-.5v4.667h3.334V9h-.5a.5.5 0 0 1-.5-.5zm2 6h.5a.5.5 0 0 0 .5-.5V3h.5c.46 0 .833.373.833.833v9c0 .46-.373.834-.833.834h-1.5zm-5.334 4.667V9h-.5a.5.5 0 0 1-.5-.5V3h-.5A.833.833 0 0 0 3 3.833v9c0 .46.373.834.833.834zM5.333 3v5h1V3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgKeyboardMidiKeysPianoOutline.displayName = 'KeyboardMidiKeysPianoOutline';
const Memo = memo(SvgKeyboardMidiKeysPianoOutline);
export default Memo;
