// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSnowCloudFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.667 12a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M4.667 12a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M6.667 13.333a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M12.667 12a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M10.667 13.333a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M6.281 2C3.911 2 2 3.947 2 6.333s1.91 4.334 4.281 4.334h4.469c1.801 0 3.25-1.479 3.25-3.288 0-1.81-1.449-3.288-3.25-3.288q-.39 0-.753.089A4.27 4.27 0 0 0 6.281 2" />
    </Svg>
  );
};
SvgSnowCloudFill.displayName = 'SnowCloudFill';
const Memo = memo(SvgSnowCloudFill);
export default Memo;
