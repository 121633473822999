// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBasket1CartShoppingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 2.5a.5.5 0 0 1 .5-.5h.863c.9 0 1.668.654 1.81 1.544L3.914 4h8.807c.717 0 1.264.64 1.152 1.349l-.7 4.437a1.83 1.83 0 0 1-1.811 1.547H5.638c-.9 0-1.668-.654-1.81-1.543l-.974-6.088A.83.83 0 0 0 2.03 3h-.863a.5.5 0 0 1-.5-.5M4.074 5l.741 4.632a.83.83 0 0 0 .823.701h5.724c.41 0 .76-.298.823-.703l.7-4.437A.167.167 0 0 0 12.722 5z"
        clipRule="evenodd"
      />
      <Path
        stroke="#0D1011"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.25}
        d="M5.499 13.958a.792.792 0 1 0 0-1.583.792.792 0 0 0 0 1.583m6 0a.792.792 0 1 0 0-1.583.792.792 0 0 0 0 1.583"
      />
    </Svg>
  );
};
SvgBasket1CartShoppingOutline.displayName = 'Basket1CartShoppingOutline';
const Memo = memo(SvgBasket1CartShoppingOutline);
export default Memo;
