// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPinchOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.946 3.457c-.247-.444-.775-.58-1.182-.337-.418.251-.575.825-.323 1.28l2.06 3.71a.5.5 0 0 1-.515.737l-.815-.129c-.458-.072-.916.254-.992.776l-.078.532 3.454 2.266c1.387.91 3.14.945 4.559.093 2.117-1.27 2.856-4.103 1.623-6.324l-.628-1.131c-.437-.786-1.386-1.04-2.12-.598L8.013 6.117a.5.5 0 0 1-.694-.186zM4.25 2.263c.907-.545 2.059-.213 2.57.709l1.123 2.021 2.53-1.519c1.237-.742 2.808-.293 3.51.97l-.41.228.41-.228.628 1.132c1.487 2.677.612 6.11-1.982 7.667a5.26 5.26 0 0 1-5.622-.115l-3.722-2.44a.5.5 0 0 1-.22-.49l.124-.85c.143-.973.982-1.69 1.946-1.638L3.567 4.885c-.506-.91-.213-2.085.683-2.622M1.689 4.61a.5.5 0 0 1 .287.646c-.41 1.07-.41 2.01 0 3.08a.5.5 0 0 1-.933.358c-.5-1.301-.5-2.495 0-3.796a.5.5 0 0 1 .646-.288"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPinchOutline.displayName = 'PinchOutline';
const Memo = memo(SvgPinchOutline);
export default Memo;
