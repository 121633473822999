// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrophyWinChampionFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.165 1.334c-.839 0-1.546.563-1.764 1.333h-.236c-1.012 0-1.833.82-1.833 1.833v.333A2.5 2.5 0 0 0 3.478 7.31a4.5 4.5 0 0 0 4.023 3.346v.679H5.665a1.667 1.667 0 0 0 0 3.333h4.667a1.667 1.667 0 1 0 0-3.333h-1.83v-.68a4.5 4.5 0 0 0 4.017-3.345 2.5 2.5 0 0 0 2.146-2.476V4.5c0-1.012-.82-1.833-1.833-1.833h-.236a1.834 1.834 0 0 0-1.764-1.333zm-2 2.333h.167v2.581a1.5 1.5 0 0 1-1-1.415V4.5c0-.46.373-.833.833-.833m10.5 1.167a1.5 1.5 0 0 1-1 1.414V3.667h.167c.46 0 .833.373.833.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrophyWinChampionFill.displayName = 'TrophyWinChampionFill';
const Memo = memo(SvgTrophyWinChampionFill);
export default Memo;
