// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDotVerticalMenuGrabFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.999 3.166a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333M7.999 8.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333M7.999 14.166a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333" />
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333M6.832 2.5a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0m1.167 5.333a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333M6.832 8a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0m1.167 5.333a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333m-1.167.167a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDotVerticalMenuGrabFill.displayName = 'DotVerticalMenuGrabFill';
const Memo = memo(SvgDotVerticalMenuGrabFill);
export default Memo;
