// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLinkedinColour: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.685 1.333H2.32a.984.984 0 0 0-.984.985v11.364a.984.984 0 0 0 .984.985h11.365a.985.985 0 0 0 .984-.985V2.318a.984.984 0 0 0-.984-.985M5.31 12.692H3.305V6.324H5.31zm-1.004-7.25a1.15 1.15 0 1 1 1.158-1.15 1.134 1.134 0 0 1-1.158 1.15m8.393 7.255h-2.004V9.22c0-1.026-.436-1.343-.999-1.343-.594 0-1.178.448-1.178 1.368v3.453H6.514V6.33H8.44v.882h.026c.194-.392.872-1.061 1.906-1.061 1.119 0 2.327.664 2.327 2.608z" />
    </Svg>
  );
};
SvgLinkedinColour.displayName = 'LinkedinColour';
const Memo = memo(SvgLinkedinColour);
export default Memo;
