// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCallPlusAddOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.422 1.145a.5.5 0 0 1 .5.5V3.31h1.667a.5.5 0 0 1 0 1h-1.667v1.667a.5.5 0 0 1-1 0V4.31H9.255a.5.5 0 1 1 0-1h1.667V1.645a.5.5 0 0 1 .5-.5m-9.358 2.72C1.952 2.803 2.832 2 3.815 2h1.188c.813 0 1.53.536 1.759 1.316l.33 1.122c.189.642.004 1.337-.48 1.801-.26.251-.301.609-.141.856A8.2 8.2 0 0 0 8.905 9.53c.248.16.606.12.857-.142a1.8 1.8 0 0 1 1.801-.479l1.122.33c.78.23 1.316.945 1.316 1.759v1.169c0 .995-.82 1.875-1.886 1.757a11.3 11.3 0 0 1-4.46-1.448 11.4 11.4 0 0 1-4.13-4.13 11.3 11.3 0 0 1-1.46-4.482M3.815 3c-.474 0-.797.37-.756.758a10.3 10.3 0 0 0 1.33 4.087 10.4 10.4 0 0 0 3.767 3.766 10.3 10.3 0 0 0 4.069 1.32c.398.044.776-.287.776-.764v-1.17a.83.83 0 0 0-.599-.799l-1.121-.33a.8.8 0 0 0-.798.213c-.535.557-1.415.745-2.12.289A9.2 9.2 0 0 1 5.63 7.638c-.456-.705-.268-1.585.29-2.12a.8.8 0 0 0 .211-.798l-.33-1.122A.83.83 0 0 0 5.003 3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCallPlusAddOutline.displayName = 'CallPlusAddOutline';
const Memo = memo(SvgCallPlusAddOutline);
export default Memo;
