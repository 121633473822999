// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAppleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.66 11.724q-.302.698-.717 1.289-.566.806-.924 1.116-.551.508-1.187.523-.456 0-1.096-.262t-1.18-.261q-.565 0-1.214.26-.65.264-1.048.277-.608.025-1.214-.537-.386-.337-.966-1.156a8 8 0 0 1-1.021-2.028q-.429-1.248-.429-2.418 0-1.341.58-2.31a3.4 3.4 0 0 1 1.214-1.229A3.27 3.27 0 0 1 6.1 4.525q.483.001 1.27.296.785.295 1.007.296.166 0 1.117-.349.898-.323 1.518-.27 1.683.136 2.525 1.33-1.505.913-1.49 2.551.015 1.278.925 2.121.41.392.923.606-.11.322-.235.618M11.087 1.6q0 1.001-.73 1.866c-.585.685-1.294 1.08-2.063 1.018a2 2 0 0 1-.015-.252c0-.64.278-1.326.773-1.886q.371-.425.943-.707.57-.279 1.078-.306.015.135.014.267" />
    </Svg>
  );
};
SvgAppleFill.displayName = 'AppleFill';
const Memo = memo(SvgAppleFill);
export default Memo;
