// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShield2CheckFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.284 1.26a1.83 1.83 0 0 1 1.432 0l4.167 1.768A1.83 1.83 0 0 1 14 4.716v3.95a6 6 0 0 1-12 0v-3.95c0-.736.44-1.4 1.117-1.688zm3.23 4.88a.5.5 0 0 1 .013.707l-2.89 3a.5.5 0 0 1-.72 0l-1.444-1.5a.5.5 0 0 1 .72-.694L7.278 8.78l2.529-2.626a.5.5 0 0 1 .707-.013"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShield2CheckFill.displayName = 'Shield2CheckFill';
const Memo = memo(SvgShield2CheckFill);
export default Memo;
