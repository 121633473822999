// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDashboardFastOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.006 3.993A5.667 5.667 0 0 0 2.694 10h5l1.86-3.565a.5.5 0 0 1 .887.463L8.823 10h4.48a5.67 5.67 0 0 0-1.298-6.007M12.808 11H3.19a5.667 5.667 0 0 0 9.618 0M3.285 3.286a6.667 6.667 0 1 1 9.428 9.428 6.667 6.667 0 0 1-9.428-9.428"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDashboardFastOutline.displayName = 'DashboardFastOutline';
const Memo = memo(SvgDashboardFastOutline);
export default Memo;
