// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSunDownOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.003 1.333a.5.5 0 0 1 .5.5V4.46l.813-.814a.5.5 0 1 1 .707.708L8.356 6.02a.5.5 0 0 1-.707 0L5.982 4.354a.5.5 0 1 1 .707-.708l.814.814V1.833a.5.5 0 0 1 .5-.5M2.896 6.381A.5.5 0 0 1 3.6 6.32l.766.643a.5.5 0 1 1-.643.766l-.766-.643a.5.5 0 0 1-.061-.705m10.214 0a.5.5 0 0 1-.062.705l-.766.643a.5.5 0 1 1-.643-.766l.767-.643a.5.5 0 0 1 .704.061M8.003 8.333a2.333 2.333 0 0 0-2.334 2.334v.166a.5.5 0 0 1-1 0v-.166a3.333 3.333 0 0 1 6.667 0v.166a.5.5 0 1 1-1 0v-.166a2.333 2.333 0 0 0-2.333-2.334m-6.667 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 1 1 0 1h-1a.5.5 0 0 1-.5-.5m11.333 0a.5.5 0 0 1 .5-.5h1a.5.5 0 1 1 0 1h-1a.5.5 0 0 1-.5-.5M1.34 13.5a.5.5 0 0 1 .5-.5h12.333a.5.5 0 0 1 0 1H1.839a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSunDownOutline.displayName = 'SunDownOutline';
const Memo = memo(SvgSunDownOutline);
export default Memo;
