// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRentResidentialRentResidentialOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.34 3.024a.54.54 0 0 0-.68 0L4 6.078v6.51c0 .26.224.47.5.47h7c.276 0 .5-.21.5-.47v-6.51zM1.84 7.88 3 6.912v5.676C3 13.368 3.672 14 4.5 14h7c.828 0 1.5-.632 1.5-1.412V6.912l1.16.968c.204.17.52.158.707-.025a.422.422 0 0 0-.028-.639L9.017 2.36a1.62 1.62 0 0 0-2.034 0L1.16 7.217a.422.422 0 0 0-.028.639.54.54 0 0 0 .706.025m7.35-.164a.394.394 0 1 0 .557-.558.394.394 0 0 0-.558.558m1.264.707a1.395 1.395 0 0 1-1.585.273l-2.355 2.356a.463.463 0 0 1-.66 0l-.985-.986a.465.465 0 0 1 .657-.657l.658.658.657-.657-.33-.33a.465.465 0 1 1 .657-.657l.33.33.713-.713a1.395 1.395 0 1 1 2.243.383"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRentResidentialRentResidentialOutline.displayName = 'RentResidentialRentResidentialOutline';
const Memo = memo(SvgRentResidentialRentResidentialOutline);
export default Memo;
