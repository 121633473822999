// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowLeftCircleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.332 8a5.667 5.667 0 1 0 11.333 0A5.667 5.667 0 0 0 2.332 8m5.667 6.667a6.667 6.667 0 1 1 0-13.334 6.667 6.667 0 0 1 0 13.334M6.372 7.5l1.314-1.313a.5.5 0 1 0-.708-.707L5.283 7.175a1.167 1.167 0 0 0 0 1.65l1.695 1.695a.5.5 0 1 0 .708-.707L6.372 8.5h4.46a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowLeftCircleOutline.displayName = 'ArrowLeftCircleOutline';
const Memo = memo(SvgArrowLeftCircleOutline);
export default Memo;
