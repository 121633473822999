// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCompassSquareBrowserSafariWebInternetNavigationOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3zm5.788 3.38-2.455.669a.17.17 0 0 0-.117.117l-.67 2.455 2.455-.67a.17.17 0 0 0 .117-.117zm.018-1.042a.833.833 0 0 1 1.023 1.023l-.746 2.736c-.109.399-.42.71-.819.819l-2.736.746A.833.833 0 0 1 5.338 9.64l.746-2.736c.109-.399.42-.71.819-.819z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCompassSquareBrowserSafariWebInternetNavigationOutline.displayName =
  'CompassSquareBrowserSafariWebInternetNavigationOutline';
const Memo = memo(SvgCompassSquareBrowserSafariWebInternetNavigationOutline);
export default Memo;
