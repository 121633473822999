// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSmileyFaceEmojiSmileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m5-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m4.333-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-6.5 2a.5.5 0 1 0-1 0 4.833 4.833 0 0 0 9.667 0 .5.5 0 1 0-1 0 3.833 3.833 0 1 1-7.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSmileyFaceEmojiSmileFill.displayName = 'SmileyFaceEmojiSmileFill';
const Memo = memo(SvgSmileyFaceEmojiSmileFill);
export default Memo;
