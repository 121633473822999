// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMenu1GridCircleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.667 8A.333.333 0 1 1 5 8a.333.333 0 0 1 .667 0" />
      <Path
        fillRule="evenodd"
        d="M6.001 8a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m-.666.333a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667"
        clipRule="evenodd"
      />
      <Path d="M8.335 8a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0" />
      <Path
        fillRule="evenodd"
        d="M8.665 8a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M8 8.333a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667"
        clipRule="evenodd"
      />
      <Path d="M10.999 8a.333.333 0 1 1-.667 0A.333.333 0 0 1 11 8" />
      <Path
        fillRule="evenodd"
        d="M11.333 8A.667.667 0 1 1 10 8a.667.667 0 0 1 1.333 0m-.666.333a.333.333 0 1 0 0-.667.333.333 0 0 0 0 .667"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.333 5.667 5.667 0 0 0 0-11.333M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMenu1GridCircleOutline.displayName = 'Menu1GridCircleOutline';
const Memo = memo(SvgMenu1GridCircleOutline);
export default Memo;
