// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMarkdownFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 2.667c-1.012 0-1.833.82-1.833 1.833v7c0 1.013.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833v-7c0-1.012-.82-1.833-1.833-1.833zm1.687 3.48A.5.5 0 0 0 4 6.5v3a.5.5 0 0 0 1 0V7.707l.646.647a.5.5 0 0 0 .707 0L7 7.707V9.5a.5.5 0 0 0 1 0v-3a.5.5 0 0 0-.854-.353L6 7.293zM11 6.5a.5.5 0 0 0-1 0v1.793l-.313-.313a.5.5 0 1 0-.708.707l1.167 1.167a.5.5 0 0 0 .707 0l1.167-1.167a.5.5 0 1 0-.707-.707l-.313.313z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMarkdownFill.displayName = 'MarkdownFill';
const Memo = memo(SvgMarkdownFill);
export default Memo;
