// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMinusCircleRemoveFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m9.495.5a.5.5 0 1 0 0-1H5.17a.5.5 0 1 0 0 1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMinusCircleRemoveFill.displayName = 'MinusCircleRemoveFill';
const Memo = memo(SvgMinusCircleRemoveFill);
export default Memo;
