// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCursor2ArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.923 3.435c-.37-.948.564-1.883 1.512-1.512l9.131 3.573c.784.307.995 1.317.4 1.912L10.54 9.833l3.98 3.98a.5.5 0 0 1-.707.707l-3.98-3.98-2.425 2.426a1.167 1.167 0 0 1-1.912-.4zm1.147-.58a.167.167 0 0 0-.216.215l3.573 9.132a.167.167 0 0 0 .274.057l5.558-5.558a.167.167 0 0 0-.057-.274z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCursor2ArrowOutline.displayName = 'Cursor2ArrowOutline';
const Memo = memo(SvgCursor2ArrowOutline);
export default Memo;
