// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbleMessageSparkleAiTooltipFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v7c0 1.013-.82 1.834-1.833 1.834H10.25a.17.17 0 0 0-.107.038l-1.399 1.159a1.17 1.17 0 0 1-1.493-.004l-1.377-1.154a.17.17 0 0 0-.107-.04H3.833A1.833 1.833 0 0 1 2 10.834zm6 .834c.21 0 .381.17.381.38 0 .748.165 1.199.436 1.47.27.27.722.435 1.469.435a.381.381 0 1 1 0 .762c-.747 0-1.198.166-1.469.436-.27.27-.436.722-.436 1.469a.381.381 0 0 1-.762 0c0-.747-.165-1.198-.436-1.469-.27-.27-.722-.436-1.469-.436a.381.381 0 1 1 0-.762c.747 0 1.198-.165 1.469-.436.27-.27.436-.721.436-1.468 0-.21.17-.381.381-.381"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubbleMessageSparkleAiTooltipFill.displayName = 'BubbleMessageSparkleAiTooltipFill';
const Memo = memo(SvgBubbleMessageSparkleAiTooltipFill);
export default Memo;
