// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHeartBeatHeartRatePulseOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.282 3.067c-.914-.204-1.985.04-2.927.998a.5.5 0 0 1-.713 0c-.942-.958-2.013-1.202-2.927-.998-.927.207-1.74.885-2.133 1.868-.753 1.881-.008 5.191 5.416 8.323 5.425-3.132 6.17-6.442 5.417-8.323-.393-.983-1.206-1.66-2.133-1.868m3.061 1.497c1.046 2.614-.287 6.44-6.1 9.705a.5.5 0 0 1-.49 0C1.94 11.003.609 7.18 1.654 4.564c.512-1.279 1.584-2.191 2.844-2.473 1.145-.256 2.408.018 3.501.942 1.094-.924 2.357-1.198 3.502-.942 1.26.282 2.332 1.194 2.843 2.473M7.008 5.5a.5.5 0 0 1 .438.276l1.573 3.148.641-1.165a.5.5 0 0 1 .438-.259h1.4a.5.5 0 0 1 0 1h-1.104l-.958 1.741a.5.5 0 0 1-.885-.017L6.977 7.076l-.64 1.165a.5.5 0 0 1-.439.26h-1.4a.5.5 0 1 1 0-1h1.105l.957-1.742a.5.5 0 0 1 .448-.259"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHeartBeatHeartRatePulseOutline.displayName = 'HeartBeatHeartRatePulseOutline';
const Memo = memo(SvgHeartBeatHeartRatePulseOutline);
export default Memo;
