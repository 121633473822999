// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAltAltTextAltTagFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.387 8.392h-.944l.454-1.538h.022z" />
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.736 5.076c0 .302.196.485.517.485.272 0 .424-.139.52-.476l.136-.426h1.367l.136.442c.088.327.24.46.534.46.296 0 .509-.202.509-.487 0-.105-.02-.2-.067-.33l-.993-2.706c-.152-.415-.396-.592-.813-.592-.396 0-.642.186-.789.592L3.14 9.244a1.1 1.1 0 0 0-.072.332m4.209-.107c0 .337.188.53.509.53h1.757c.274 0 .45-.149.45-.409s-.18-.41-.45-.41H8.292V6.476c0-.338-.185-.529-.506-.529s-.51.191-.51.529zm3.594.063c0 .338.185.529.506.529s.506-.191.506-.529V6.826h.703c.269 0 .451-.15.451-.41s-.177-.409-.45-.409H10.17c-.277 0-.454.15-.454.41s.183.41.454.41h.7z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAltAltTextAltTagFill.displayName = 'AltAltTextAltTagFill';
const Memo = memo(SvgAltAltTextAltTagFill);
export default Memo;
