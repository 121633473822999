// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSmileEmojiOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.999 6.333c0 .553-.373 1-.834 1s-.833-.447-.833-1 .373-1 .833-1 .834.448.834 1M10.665 6.333c0 .553-.373 1-.833 1s-.833-.447-.833-1 .373-1 .833-1 .833.448.833 1" />
      <Path
        fillRule="evenodd"
        d="M12.006 3.993a5.667 5.667 0 1 0-8.014 8.014 5.667 5.667 0 0 0 8.014-8.014m-8.721-.707a6.667 6.667 0 1 1 9.428 9.428 6.667 6.667 0 0 1-9.428-9.428"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M5.76 9.532a.5.5 0 0 1 .707 0 2.167 2.167 0 0 0 3.064 0 .5.5 0 1 1 .707.707 3.167 3.167 0 0 1-4.478 0 .5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSmileEmojiOutline.displayName = 'SmileEmojiOutline';
const Memo = memo(SvgSmileEmojiOutline);
export default Memo;
