// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBbqBarbecuePitFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.202 1.135a.46.46 0 0 1-.002.646 1.14 1.14 0 0 0-.32.617c.011.16.114.308.35.593l.027.033c.208.25.532.64.532 1.172a1 1 0 0 1-.005.062A2.05 2.05 0 0 1 6.2 5.433a.453.453 0 0 1-.643-.003.46.46 0 0 1 .002-.645 1.14 1.14 0 0 0 .32-.618c-.011-.16-.114-.308-.35-.593l-.027-.033c-.208-.25-.532-.64-.532-1.171q0-.031.005-.063c.061-.445.267-.858.584-1.175a.453.453 0 0 1 .643.003M4.67 5.87a.755.755 0 0 0-.712.497.8.8 0 0 0-.044.322A4.13 4.13 0 0 0 5.442 9.53l-2.394 4.81a.457.457 0 0 0 .203.612.454.454 0 0 0 .61-.204l.632-1.27h7.014l.632 1.27a.454.454 0 0 0 .61.204.457.457 0 0 0 .203-.613L10.56 9.534q.107-.086.209-.18a4.13 4.13 0 0 0 1.325-2.684.76.76 0 0 0-.459-.739.8.8 0 0 0-.299-.061zm6.382 6.695-1.266-2.543a4.1 4.1 0 0 1-3.57-.003l-1.268 2.546zm-.617-10.784a.46.46 0 0 0 .003-.646.453.453 0 0 0-.643-.003c-.318.317-.523.73-.584 1.175a1 1 0 0 0-.005.063c0 .531.324.921.532 1.171l.028.033c.235.285.338.433.348.593-.04.234-.15.45-.319.618a.46.46 0 0 0-.002.645c.176.18.464.18.643.003.317-.317.523-.73.584-1.175a1 1 0 0 0 .005-.062c0-.532-.325-.922-.532-1.172l-.028-.033c-.236-.285-.338-.433-.349-.593.04-.234.151-.45.32-.617M8.33 1.135a.46.46 0 0 1-.003.646 1.14 1.14 0 0 0-.319.617c.01.16.113.308.349.593l.027.033c.208.25.532.64.532 1.172a1 1 0 0 1-.004.062 2.05 2.05 0 0 1-.585 1.175.453.453 0 0 1-.643-.003.46.46 0 0 1 .003-.645 1.14 1.14 0 0 0 .32-.618c-.012-.16-.114-.308-.35-.593l-.027-.033c-.208-.25-.532-.64-.532-1.171q0-.031.004-.063c.062-.445.267-.858.585-1.175a.453.453 0 0 1 .643.003"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBbqBarbecuePitFill.displayName = 'BbqBarbecuePitFill';
const Memo = memo(SvgBbqBarbecuePitFill);
export default Memo;
