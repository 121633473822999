// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWalkingWalkOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.833 5a1.832 1.832 0 1 1 0-3.667 1.832 1.832 0 1 1 0 3.667m0-2.667a.834.834 0 1 0 .001 1.668.834.834 0 0 0 0-1.668M5.956 14.333c-.26 0-.513-.073-.733-.213a1.3 1.3 0 0 1-.587-.833c-.073-.347 0-.707.207-.994a7.9 7.9 0 0 0 1.446-3.94s-.006.014-.013.02c-.153.187-.24.427-.24.66 0 .347-.147.687-.407.927-.506.473-1.38.473-1.886 0-.26-.24-.407-.58-.407-.927 0-.98.42-1.94 1.153-2.626a3.99 3.99 0 0 1 2.754-1.074c.726 0 1.446.16 2.1.454.653.3 1.24.74 1.693 1.273.067.073.16.14.26.18q.1.04.22.06a.5.5 0 0 1 .14-.02c.353 0 .693.133.947.367.26.24.406.58.406.933s-.146.687-.406.927a1.37 1.37 0 0 1-.947.366c-.52 0-1.04-.113-1.5-.326a3.5 3.5 0 0 1-1.193-.907 9 9 0 0 1-.047.42c1.42.78 2.473 2 3 3.473.12.32.1.694-.06 1.007s-.433.547-.78.66a1.37 1.37 0 0 1-1.013-.053 1.31 1.31 0 0 1-.7-.74 3.75 3.75 0 0 0-1.18-1.64 10.6 10.6 0 0 1-1.087 1.98c-.207.293-.513.48-.867.546-.086.014-.173.027-.26.027zm.853-7.346a.49.49 0 0 1 .494.48c.06 1.933-.514 3.8-1.654 5.406-.046.06-.06.14-.046.207a.3.3 0 0 0 .14.193c.08.054.186.067.28.054a.35.35 0 0 0 .226-.14c.5-.727.9-1.514 1.194-2.34a.5.5 0 0 1 .3-.307c.14-.053.3-.04.426.04a4.82 4.82 0 0 1 2.107 2.5.34.34 0 0 0 .173.173c.087.04.194.047.294.014a.33.33 0 0 0 .2-.167.25.25 0 0 0 .013-.207c-.48-1.346-1.48-2.44-2.827-3.086a.49.49 0 0 1-.273-.534 10 10 0 0 0 .133-1.6.51.51 0 0 1 .24-.426.5.5 0 0 1 .487-.02q.572.288.973.766c.234.28.527.507.86.654a2.6 2.6 0 0 0 1.087.233c.1 0 .193-.033.26-.1a.27.27 0 0 0 .093-.2.27.27 0 0 0-.093-.2.4.4 0 0 0-.153-.087c-.04.014-.087.014-.134.014-.246 0-.493-.054-.72-.147-.24-.1-.46-.26-.626-.447a3.9 3.9 0 0 0-1.347-1.02 4.1 4.1 0 0 0-1.687-.366c-.773 0-1.526.293-2.066.8-.54.506-.84 1.18-.84 1.9a.27.27 0 0 0 .093.2.4.4 0 0 0 .52 0 .27.27 0 0 0 .093-.2c0-.467.167-.927.474-1.3.3-.36.72-.62 1.186-.734q.06-.011.12-.013z" />
    </Svg>
  );
};
SvgWalkingWalkOutline.displayName = 'WalkingWalkOutline';
const Memo = memo(SvgWalkingWalkOutline);
export default Memo;
