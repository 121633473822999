// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBasketballNbaOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.184 3.81A6.64 6.64 0 0 1 5.647 7.5h1.852V2.355A5.64 5.64 0 0 0 4.184 3.81m4.315-1.455V7.5h1.851a6.64 6.64 0 0 1 1.463-3.69A5.64 5.64 0 0 0 8.5 2.355m4 2.2A5.64 5.64 0 0 0 11.354 7.5h2.29a5.64 5.64 0 0 0-1.145-2.945M13.644 8.5h-2.29a5.64 5.64 0 0 0 1.145 2.944A5.64 5.64 0 0 0 13.644 8.5m-1.83 3.69A6.64 6.64 0 0 1 10.35 8.5H8.499v5.145a5.64 5.64 0 0 0 3.314-1.455m-4.315 1.455V8.5H5.647a6.64 6.64 0 0 1-1.463 3.69 5.64 5.64 0 0 0 3.315 1.455m-4-2.2A5.64 5.64 0 0 0 4.644 8.5h-2.29a5.64 5.64 0 0 0 1.145 2.944M2.354 7.5h2.29a5.64 5.64 0 0 0-1.145-2.945A5.64 5.64 0 0 0 2.354 7.5m.78-4.058a6.65 6.65 0 0 1 4.865-2.109 6.65 6.65 0 0 1 4.865 2.109A6.65 6.65 0 0 1 14.665 8a6.65 6.65 0 0 1-1.801 4.558 6.65 6.65 0 0 1-4.865 2.109 6.65 6.65 0 0 1-4.865-2.109A6.65 6.65 0 0 1 1.332 8c0-1.763.685-3.367 1.802-4.558"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBasketballNbaOutline.displayName = 'BasketballNbaOutline';
const Memo = memo(SvgBasketballNbaOutline);
export default Memo;
