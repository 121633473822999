// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCryptoFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.915 6.509 7.75 5.45a.5.5 0 0 1 .5 0l1.833 1.059a.5.5 0 0 1 .25.433v2.116a.5.5 0 0 1-.25.433L8.249 10.55a.5.5 0 0 1-.5 0L5.915 9.49a.5.5 0 0 1-.25-.433V6.942a.5.5 0 0 1 .25-.433" />
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m9.25-2.357L8.749 4.584a1.5 1.5 0 0 0-1.5 0L5.415 5.643a1.5 1.5 0 0 0-.75 1.299v2.116a1.5 1.5 0 0 0 .75 1.3l1.834 1.058a1.5 1.5 0 0 0 1.5 0l1.833-1.059a1.5 1.5 0 0 0 .75-1.299V6.942a1.5 1.5 0 0 0-.75-1.3"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCryptoFill.displayName = 'CryptoFill';
const Memo = memo(SvgCryptoFill);
export default Memo;
