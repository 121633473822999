// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLaunchRocketFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.039 14.567c.093.093.22.14.346.14.134 0 .26-.054.36-.154l1.114-1.14a.497.497 0 1 0-.714-.693l-1.113 1.14a.497.497 0 0 0 .007.707M7.83 14.707h.002-.007z" />
      <Path
        fillRule="evenodd"
        d="M7.739 14.7a.496.496 0 0 0 .427-.127l1.906-1.773c.373-.34.587-.833.587-1.34v-1.113c3.066-1.647 4.3-4.287 4-8.507a.495.495 0 0 0-.46-.46c-4.22-.307-6.86.933-8.5 3.993H4.585c-.513 0-1 .214-1.346.587L1.465 7.867a.47.47 0 0 0-.126.433.5.5 0 0 0 .289.364c.166.08 2.47 1.183 3.517 2.23 1.042 1.047 2.153 3.358 2.23 3.518l.004.008a.53.53 0 0 0 .36.28M10.332 7a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667"
        clipRule="evenodd"
      />
      <Path d="M.819 13.807c.093.093.22.14.346.14.134 0 .26-.054.36-.154l1.854-1.906a.497.497 0 1 0-.714-.694L.812 13.1a.497.497 0 0 0 .007.707" />
    </Svg>
  );
};
SvgLaunchRocketFill.displayName = 'LaunchRocketFill';
const Memo = memo(SvgLaunchRocketFill);
export default Memo;
