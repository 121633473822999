// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCancelCallFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.216 8.077a8 8 0 0 1-.745-.982c-.16-.247-.12-.605.142-.856a1.8 1.8 0 0 0 .479-1.802l-.33-1.121A1.83 1.83 0 0 0 5.003 2H3.815c-.983 0-1.863.804-1.75 1.864a11.3 11.3 0 0 0 1.46 4.482c.411.71.898 1.371 1.449 1.973l-2.828 2.827a.5.5 0 0 0 .708.708l11-11a.5.5 0 0 0-.708-.708zM7.654 12.476q-.629-.365-1.204-.806l2.267-2.266q.093.064.188.126c.248.16.606.12.857-.142a1.8 1.8 0 0 1 1.801-.479l1.122.33c.78.23 1.316.945 1.316 1.759v1.169c0 .995-.82 1.875-1.886 1.757a11.3 11.3 0 0 1-4.46-1.448" />
    </Svg>
  );
};
SvgCancelCallFill.displayName = 'CancelCallFill';
const Memo = memo(SvgCancelCallFill);
export default Memo;
