// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGarage2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.017 2.419 2.17 6.149c-.222.167-.556.111-.668-.111a.53.53 0 0 1 .112-.724l6.124-3.897a.42.42 0 0 1 .5 0l6.124 3.897c.223.167.335.445.168.668-.056.167-.223.223-.39.223-.111 0-.223 0-.278-.056zM12.916 9.6c.445.055.89.278 1.224.612.39.445.613.946.724 1.503v.835c0 .39-.111.724-.39.947-.278.222-.612.39-.946.39h-.501a1.7 1.7 0 0 1-.278.389c-.223.278-.557.39-.891.39s-.668-.167-.89-.39a1.2 1.2 0 0 1-.28-.445H6.347a1.2 1.2 0 0 1-.278.445c-.223.278-.557.39-.89.39-.335 0-.669-.167-.891-.39-.112-.111-.223-.223-.279-.39H2.672c-.39 0-.724-.167-.946-.39-.223-.278-.39-.612-.39-.946v-.835c0-.557.223-1.113.612-1.503.39-.39.947-.612 1.503-.612h.947l.89-1.782c.267-.4.67-.724 1.17-.724h4.398c.446 0 .972.33 1.169.724zM6.179 8.18a.3.3 0 0 1-.055.083l-.668 1.337h6.29l-.668-1.337c0-.055-.055-.11-.11-.167-.056-.055-.112-.055-.168-.055H6.402c-.056 0-.111 0-.167.055a.3.3 0 0 0-.056.084m-1.391 5.54a.437.437 0 0 0 .612 0c.056-.112.111-.223.111-.335a.43.43 0 0 0-.111-.278c-.056-.056-.111-.112-.278-.112s-.223.056-.334.168c-.112.111-.112.226-.112.377v.012a.3.3 0 0 1 .056.084.3.3 0 0 0 .056.083m6.569-.28c0 .112.056.224.111.28.167.166.446.166.557 0a.39.39 0 0 0 0-.557.43.43 0 0 0-.279-.112.43.43 0 0 0-.278.112.43.43 0 0 0-.111.278"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGarage2Fill.displayName = 'Garage2Fill';
const Memo = memo(SvgGarage2Fill);
export default Memo;
