// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDeleteRemoveBackspaceOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.868 3.667a.83.83 0 0 0-.686.36l-2.414 3.5a.83.83 0 0 0 0 .946l2.414 3.5c.156.226.412.36.686.36h6.967c.46 0 .833-.372.833-.833v-7a.833.833 0 0 0-.833-.833zM4.36 3.459a1.83 1.83 0 0 1 1.51-.792h6.966c1.012 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.834-1.833 1.834H5.868a1.83 1.83 0 0 1-1.509-.793l-2.414-3.5a1.83 1.83 0 0 1 0-2.082l.412.284-.412-.284zm2.788 2.854a.5.5 0 0 1 .707 0l.98.981.982-.98a.5.5 0 0 1 .707.707L9.542 8l.98.98a.5.5 0 1 1-.707.707l-.98-.98-.98.98a.5.5 0 1 1-.707-.707l.98-.98-.981-.98a.5.5 0 0 1 0-.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDeleteRemoveBackspaceOutline.displayName = 'DeleteRemoveBackspaceOutline';
const Memo = memo(SvgDeleteRemoveBackspaceOutline);
export default Memo;
