// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStarAiSparkleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a.5.5 0 0 1 .5.5c0 2.08.459 3.464 1.33 4.336.873.872 2.256 1.331 4.336 1.331a.5.5 0 0 1 0 1c-2.08 0-3.463.46-4.335 1.331-.872.872-1.331 2.255-1.331 4.336a.5.5 0 1 1-1 0c0-2.08-.46-3.464-1.331-4.336C5.296 8.959 3.912 8.5 1.832 8.5a.5.5 0 0 1 0-1c2.08 0 3.464-.46 4.336-1.331.872-.872 1.33-2.256 1.33-4.336a.5.5 0 0 1 .5-.5m0 3.757a4.9 4.9 0 0 1-1.124 1.786A4.9 4.9 0 0 1 5.089 8a4.9 4.9 0 0 1 1.786 1.124c.492.492.862 1.09 1.124 1.785a4.9 4.9 0 0 1 1.124-1.785A4.9 4.9 0 0 1 10.908 8a4.9 4.9 0 0 1-1.785-1.124A4.9 4.9 0 0 1 7.999 5.09"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStarAiSparkleOutline.displayName = 'StarAiSparkleOutline';
const Memo = memo(SvgStarAiSparkleOutline);
export default Memo;
