// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCheckLargeCheckmarkOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.463 2.099a.5.5 0 0 1 .104.7l-8.167 11a.5.5 0 0 1-.72.087l-4.166-3.438a.5.5 0 1 1 .636-.771l3.761 3.102 7.853-10.577a.5.5 0 0 1 .7-.103"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCheckLargeCheckmarkOutline.displayName = 'CheckLargeCheckmarkOutline';
const Memo = memo(SvgCheckLargeCheckmarkOutline);
export default Memo;
