// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleTogetherUserAvatarGroup2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5 3a1.667 1.667 0 1 0 0 3.333A1.667 1.667 0 0 0 5 3M2.333 4.667a2.667 2.667 0 1 1 5.334 0 2.667 2.667 0 0 1-5.334 0M11 3a1.667 1.667 0 1 0 0 3.333A1.667 1.667 0 0 0 11 3M8.333 4.667a2.667 2.667 0 1 1 5.334 0 2.667 2.667 0 0 1-5.334 0m6.29 7.549c-.737-2.814-3.04-3.704-4.815-2.967a.5.5 0 1 1-.383-.924c2.405-.999 5.294.313 6.165 3.637.297 1.133-.66 2.038-1.687 2.038h-3.07a.5.5 0 0 1 0-1h3.07c.52 0 .815-.42.72-.784M4.953 9c-1.46 0-2.998 1.013-3.575 3.213-.096.365.202.787.72.787h5.71c.517 0 .815-.422.719-.787C7.949 10.013 6.412 9 4.952 9m0-1c1.97 0 3.863 1.377 4.541 3.959C9.791 13.089 8.837 14 7.807 14h-5.71C1.068 14 .113 13.09.41 11.959 1.088 9.377 2.981 8 4.952 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPeopleTogetherUserAvatarGroup2Outline.displayName = 'PeopleTogetherUserAvatarGroup2Outline';
const Memo = memo(SvgPeopleTogetherUserAvatarGroup2Outline);
export default Memo;
