// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAirplanePlaneAirportOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.085 2.667c-.168 0-.333.038-.483.112l-1.082.527-.003.001a1.08 1.08 0 0 0-.593.857 1.06 1.06 0 0 0 .423.951l.949.712-1.74.868-.93-.692a.44.44 0 0 0-.442-.05l-1.23.534-.019.01a1.08 1.08 0 0 0-.59.825 1.06 1.06 0 0 0 .37.943l2.34 1.965a1.73 1.73 0 0 0 1.887.233l1.485-.73v2.528a1.05 1.05 0 0 0 .503.91 1.09 1.09 0 0 0 1.052.051l1.1-.54a1.08 1.08 0 0 0 .572-.708l1.048-4.334 1.832-.897a2.04 2.04 0 0 0 1.029-1.165 2 2 0 0 0-.596-2.167 2.05 2.05 0 0 0-1.485-.492c-.268.018-.53.088-.77.206l.195.388-.198-.386-1.725.86L7.571 2.78h-.002a1.1 1.1 0 0 0-.484-.113m3.073 2.205.05-.025 1.894-.945h.002a1.18 1.18 0 0 1 1.286.163 1.16 1.16 0 0 1 .398.79 1.14 1.14 0 0 1-.285.837c-.1.114-.223.207-.36.275l-2.016.986a.44.44 0 0 0-.232.288L9.8 11.772a.2.2 0 0 1-.108.134l-1.102.54a.197.197 0 0 1-.284-.176V9.034c0-.15-.079-.29-.208-.369a.44.44 0 0 0-.427-.02L5.55 9.687a.85.85 0 0 1-.925-.114l-2.34-1.966a.2.2 0 0 1-.07-.178.2.2 0 0 1 .105-.153l.982-.426.941.7c.134.1.314.116.463.042zm-1.147-.4-1.835-.917a.2.2 0 0 0-.183 0l-.001.001-1.082.527a.2.2 0 0 0-.111.162.2.2 0 0 0 .08.18l1.29.966z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAirplanePlaneAirportOff.displayName = 'AirplanePlaneAirportOff';
const Memo = memo(SvgAirplanePlaneAirportOff);
export default Memo;
