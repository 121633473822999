// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSchoolBigBuildingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.001 4.5c0-1.013.821-1.833 1.834-1.833h4.333c1.013 0 1.833.82 1.833 1.833v.833h.834c1.012 0 1.833.821 1.833 1.834v5.166h.167a.5.5 0 1 1 0 1H1.168a.5.5 0 0 1 0-1h.167V7.167c0-1.013.82-1.834 1.833-1.834h.833zm0 1.833h-.833a.833.833 0 0 0-.833.834v5.166H4zm1 6h1v-1.166c0-1.013.821-1.834 1.834-1.834h.333c1.012 0 1.833.821 1.833 1.834v1.166h1V4.5a.833.833 0 0 0-.833-.833H5.835A.833.833 0 0 0 5 4.5zm7 0h1.667V7.167a.833.833 0 0 0-.833-.834H12zm-3 0v-1.166a.833.833 0 0 0-.833-.834h-.333a.833.833 0 0 0-.834.834v1.166z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSchoolBigBuildingOutline.displayName = 'SchoolBigBuildingOutline';
const Memo = memo(SvgSchoolBigBuildingOutline);
export default Memo;
