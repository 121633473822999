// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPrimeCreditOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14m8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7.96 12.898a4.93 4.93 0 1 0 0-9.859 4.93 4.93 0 0 0 0 9.859m5.93-4.93a5.93 5.93 0 1 1-11.859 0 5.93 5.93 0 0 1 11.86 0"
        clipRule="evenodd"
      />
      <Path d="M6.648 11q-.314 0-.482-.176Q6 10.648 6 10.33V5.68q0-.326.166-.494.175-.168.49-.167h2.02q.982 0 1.513.502.532.495.532 1.382 0 .888-.532 1.39-.532.503-1.512.503H7.288v1.533q0 .318-.166.494-.158.176-.474.176m.64-3.208H8.46q.5 0 .757-.226.266-.226.266-.662 0-.435-.266-.653-.258-.218-.757-.218H7.288z" />
    </Svg>
  );
};
SvgPrimeCreditOutline.displayName = 'PrimeCreditOutline';
const Memo = memo(SvgPrimeCreditOutline);
export default Memo;
