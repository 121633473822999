// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgParkOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.311 4.034 5.67 2.078l1.358 1.956H7a.65.65 0 0 0-.35.102.7.7 0 0 0-.246.277.7.7 0 0 0 .063.718l1.722 2.36a.436.436 0 0 0 .623.092.465.465 0 0 0 .088-.64L7.445 4.948h.022a.65.65 0 0 0 .345-.103.7.7 0 0 0 .243-.273.7.7 0 0 0-.053-.709L8 3.862 6.21 1.287A.67.67 0 0 0 5.67 1a.65.65 0 0 0-.543.287l-1.79 2.576a.697.697 0 0 0 .19.982.65.65 0 0 0 .346.103h.016L2.022 7.506a.697.697 0 0 0 .183.995.65.65 0 0 0 .35.102H3l-1.867 2.559a.697.697 0 0 0 .183.994.65.65 0 0 0 .35.102H5.22v2.285a.45.45 0 0 0 .444.457.45.45 0 0 0 .445-.457v-2.684l.003-.057a.45.45 0 0 0-.44-.457h-3.56l1.867-2.56a.697.697 0 0 0-.183-.993.65.65 0 0 0-.35-.103H3L4.867 5.13a.697.697 0 0 0-.183-.994.65.65 0 0 0-.35-.102zm4.913 5.483a.44.44 0 0 0-.314.134.46.46 0 0 0-.13.323v2.284h4.444V9.974a.46.46 0 0 0-.13-.323.44.44 0 0 0-.314-.134zm4 3.655h1.332a.45.45 0 0 0 .444-.457.45.45 0 0 0-.444-.457h-.443V9.974c0-.364-.14-.712-.39-.97a1.32 1.32 0 0 0-.944-.401H9.225c-.354 0-.693.144-.943.402a1.4 1.4 0 0 0-.39.969v2.284h-.447a.45.45 0 0 0-.444.457.45.45 0 0 0 .444.457h1.337v1.371a.45.45 0 0 0 .445.457.45.45 0 0 0 .444-.457v-1.37h2.666v1.37a.45.45 0 0 0 .444.457.45.45 0 0 0 .445-.457z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgParkOutline.displayName = 'ParkOutline';
const Memo = memo(SvgParkOutline);
export default Memo;
