// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSecuredLinesLockedSecurityDocumentListPageFileOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 2.334a.833.833 0 0 0-.833.833v9.667c0 .46.373.833.833.833h2.667a.5.5 0 0 1 0 1H4.501a1.833 1.833 0 0 1-1.833-1.833V3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v4a.5.5 0 1 1-1 0v-4a.833.833 0 0 0-.834-.833zM5.335 4.5a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5h2.333a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m4 3.5a2 2 0 0 1 4 0v.112c.394.187.666.59.666 1.055V13.5c0 .645-.522 1.167-1.166 1.167h-3A1.167 1.167 0 0 1 8.668 13.5v-1.666c0-.466.272-.868.667-1.055zm1 0h2a1 1 0 1 0-2 0m-.5 1a.167.167 0 0 0-.167.167V13.5c0 .092.075.167.167.167h3A.167.167 0 0 0 13 13.5v-1.666a.167.167 0 0 0-.166-.167z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSecuredLinesLockedSecurityDocumentListPageFileOutline.displayName =
  'SecuredLinesLockedSecurityDocumentListPageFileOutline';
const Memo = memo(SvgSecuredLinesLockedSecurityDocumentListPageFileOutline);
export default Memo;
