// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalculatorFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 1.333c-1.012 0-1.833.821-1.833 1.834v9.666c0 1.013.82 1.834 1.833 1.834h7c1.013 0 1.834-.821 1.834-1.834V3.167c0-1.013-.821-1.834-1.834-1.834zm7.834 3.334v-1.5a.833.833 0 0 0-.834-.834h-7a.833.833 0 0 0-.833.834v1.5zm-6 4.166a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m0 3a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m4-3.666a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m-.667 3.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCalculatorFill.displayName = 'CalculatorFill';
const Memo = memo(SvgCalculatorFill);
export default Memo;
