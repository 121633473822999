// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClipboard2CopyListOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.736 2.666A1.834 1.834 0 0 1 6.5 1.333h3c.84 0 1.547.564 1.764 1.333h.903C13.179 2.666 14 3.487 14 4.5v8.333c0 1.012-.82 1.833-1.833 1.833H3.833A1.833 1.833 0 0 1 2 12.833V4.5c0-1.013.82-1.834 1.833-1.834zm-.07 1h-.833A.833.833 0 0 0 3 4.5v8.333c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V4.5a.833.833 0 0 0-.833-.834h-.834v.5c0 .645-.522 1.167-1.166 1.167H5.833a1.167 1.167 0 0 1-1.166-1.167zM6.5 2.333a.833.833 0 0 0-.833.833v1c0 .092.074.167.166.167h4.334a.167.167 0 0 0 .166-.167v-1a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClipboard2CopyListOutline.displayName = 'Clipboard2CopyListOutline';
const Memo = memo(SvgClipboard2CopyListOutline);
export default Memo;
