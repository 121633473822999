// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCameraPictureImageCamCloseCrossAutoFocusOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.52 2.816C5.86 2.306 6.433 2 7.046 2h1.906c.613 0 1.185.306 1.525.816l.542.813a.83.83 0 0 0 .693.371h1.12c1.013 0 1.833.82 1.833 1.833v6.334c0 1.012-.82 1.833-1.833 1.833H3.165a1.833 1.833 0 0 1-1.833-1.833V5.833C1.332 4.821 2.152 4 3.165 4h1.12c.279 0 .539-.14.693-.371zM7.046 3a.83.83 0 0 0-.694.371l-.541.813c-.34.51-.913.816-1.526.816h-1.12a.833.833 0 0 0-.833.833v6.334c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833V5.833A.833.833 0 0 0 12.832 5h-1.12a1.83 1.83 0 0 1-1.525-.816l-.542-.813A.83.83 0 0 0 8.952 3zm.953 3.317a.5.5 0 0 1 .455.293l1.833 4.033a.5.5 0 1 1-.91.414l-.367-.807H6.987l-.366.807a.5.5 0 0 1-.91-.414L7.543 6.61a.5.5 0 0 1 .455-.293M7.442 9.25h1.114l-.557-1.225z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCameraPictureImageCamCloseCrossAutoFocusOutline.displayName =
  'CameraPictureImageCamCloseCrossAutoFocusOutline';
const Memo = memo(SvgCameraPictureImageCamCloseCrossAutoFocusOutline);
export default Memo;
