// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRestroomToiletOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.023 5.178c.978 0 1.78-.807 1.78-1.806 0-.998-.796-1.806-1.78-1.806-.985 0-1.78.808-1.78 1.806 0 .999.795 1.806 1.78 1.806m0-2.918c.607 0 1.096.496 1.096 1.112s-.49 1.112-1.096 1.112a1.1 1.1 0 0 1-1.097-1.112c0-.616.49-1.112 1.097-1.112M13.895 7.02a2.64 2.64 0 0 0-2.877-.58 2.6 2.6 0 0 0-.855.58 2.7 2.7 0 0 0-.572.866c-.136.33-.2.67-.2 1.023v2.332c0 .19.153.346.342.346h.854l.342 2.62a.34.34 0 0 0 .336.298h1.533a.34.34 0 0 0 .336-.299l.342-2.619h.855a.345.345 0 0 0 .342-.346V8.909c0-.718-.277-1.387-.772-1.896zm.094 3.88h-.808a.34.34 0 0 0-.336.299l-.342 2.619h-.937l-.342-2.62a.34.34 0 0 0-.336-.298h-.808V8.915c0-.263.053-.52.148-.76.1-.239.242-.46.424-.645a1.94 1.94 0 0 1 2.771 0c.371.376.572.873.572 1.405V10.9zM3.976 5.178c.978 0 1.78-.808 1.78-1.806 0-.999-.802-1.8-1.78-1.8-.979 0-1.78.807-1.78 1.8 0 .992.795 1.806 1.78 1.806m0-2.918c.607 0 1.096.496 1.096 1.112s-.489 1.112-1.096 1.112a1.1 1.1 0 0 1-1.097-1.112c0-.616.49-1.112 1.097-1.112M5.731 7.63a1.86 1.86 0 0 0-.642-1.005 1.845 1.845 0 0 0-2.235 0c-.318.25-.548.61-.642 1.004l-.867 3.534a.38.38 0 0 0 .059.299.34.34 0 0 0 .271.131h.85l.341 2.62a.34.34 0 0 0 .336.298h1.533a.34.34 0 0 0 .336-.299l.342-2.619h.849a.34.34 0 0 0 .271-.131.34.34 0 0 0 .059-.3L5.719 7.63zm-.607 3.27a.34.34 0 0 0-.336.299l-.342 2.619h-.937l-.342-2.62a.34.34 0 0 0-.336-.298h-.72l.767-3.103c.059-.246.206-.473.4-.628.396-.311.997-.311 1.392 0 .2.155.342.377.4.628l.767 3.103z" />
      <Path
        fillRule="evenodd"
        d="M8.204 1.333c.211 0 .383.174.383.389v12.556a.386.386 0 0 1-.383.388.386.386 0 0 1-.384-.388V1.722c0-.215.172-.389.384-.389"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRestroomToiletOutline.displayName = 'RestroomToiletOutline';
const Memo = memo(SvgRestroomToiletOutline);
export default Memo;
