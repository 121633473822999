// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPinFlagLocationOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.75 1.4a.5.5 0 0 1 .5.001l4 2.334a.5.5 0 0 1 0 .864L8.5 6.787V11.5a.5.5 0 1 1-1 0V1.833a.5.5 0 0 1 .25-.433m.749 4.23 2.507-1.463L8.5 2.704zM6.494 9.232a.5.5 0 0 1-.426.564c-1.174.163-2.15.467-2.818.84-.697.388-.918.766-.918 1.03 0 .166.08.373.33.613.252.24.641.48 1.164.692 1.043.423 2.518.695 4.173.695s3.13-.272 4.172-.695c.523-.212.913-.452 1.164-.692s.33-.447.33-.613c0-.264-.22-.642-.918-1.03-.667-.373-1.644-.677-2.817-.84a.5.5 0 0 1 .138-.99c1.25.173 2.357.504 3.167.956.78.435 1.43 1.072 1.43 1.904 0 .525-.264.976-.638 1.335-.373.357-.887.656-1.48.897-1.19.482-2.797.768-4.548.768s-3.36-.286-4.549-.768c-.593-.241-1.106-.54-1.48-.897-.374-.36-.638-.81-.638-1.335 0-.832.65-1.469 1.43-1.904.81-.452 1.917-.783 3.168-.956a.5.5 0 0 1 .564.426"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPinFlagLocationOutline.displayName = 'PinFlagLocationOutline';
const Memo = memo(SvgPinFlagLocationOutline);
export default Memo;
