// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEarthGlobeWorldOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m3.148 5.07 1.624 1.217c.2.15.488.083.6-.14.183-.364.515-.63.91-.73l1.489-.374a.83.83 0 0 0 .605-.606l.508-2.035a5.663 5.663 0 0 0-5.736 2.667m6.706-2.426-.508 2.035a1.83 1.83 0 0 1-1.331 1.334l-1.49.375a.4.4 0 0 0-.26.208 1.402 1.402 0 0 1-2.092.491L2.7 5.984a5.667 5.667 0 1 0 7.153-3.34m-7.8 2.337a6.666 6.666 0 0 1 7.562-3.45 6.667 6.667 0 1 1-7.563 3.45m4.738 3.523a1.32 1.32 0 0 1 1.016-.383l.566.036c.324.021.637.127.906.308l.94.632a1.495 1.495 0 0 1-.835 2.736h-.67a1.83 1.83 0 0 1-1.526-.816l-.564-.846a1.32 1.32 0 0 1 .167-1.667m.952.615a.32.32 0 0 0-.287.498l.564.845a.83.83 0 0 0 .693.371h.671a.496.496 0 0 0 .277-.906l-.94-.632a.83.83 0 0 0-.412-.14z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEarthGlobeWorldOutline.displayName = 'EarthGlobeWorldOutline';
const Memo = memo(SvgEarthGlobeWorldOutline);
export default Memo;
