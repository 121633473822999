// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPlayListOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.168 2.667a.5.5 0 0 1 .5.5v8c0 1.278-1.208 2.166-2.5 2.166-1.291 0-2.5-.888-2.5-2.166S10.877 9 12.168 9c.542 0 1.07.157 1.5.433V3.167a.5.5 0 0 1 .5-.5m-.5 8.5c0-.563-.582-1.167-1.5-1.167s-1.5.604-1.5 1.167.582 1.166 1.5 1.166 1.5-.603 1.5-1.166M1.332 3.833a.5.5 0 0 1 .5-.5h9.007a.5.5 0 0 1 0 1H1.832a.5.5 0 0 1-.5-.5m0 4.167a.5.5 0 0 1 .5-.5h5.667a.5.5 0 0 1 0 1H1.832a.5.5 0 0 1-.5-.5m0 4.167a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H1.832a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPlayListOutline.displayName = 'PlayListOutline';
const Memo = memo(SvgPlayListOutline);
export default Memo;
