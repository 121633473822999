// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLocationMapRouteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.167C1.332 2.97 2.302 2 3.499 2h1.666v2h2a.5.5 0 1 1 0 1h-2v5.167a.5.5 0 0 1-.5.5H3.5a1.167 1.167 0 1 0 0 2.333h8.666c.46 0 .834-.373.834-.833V8.5a.5.5 0 0 1 1 0v3.667c0 1.012-.821 1.833-1.834 1.833H3.5a2.167 2.167 0 0 1-2.167-2.167zm1 5.84c.337-.215.737-.34 1.167-.34h.666V3H3.5c-.645 0-1.167.522-1.167 1.167zm9-7.007c-.926 0-1.667.74-1.667 1.643 0 .683.418 1.273.92 1.728a5 5 0 0 0 .747.555 5 5 0 0 0 .747-.555c.502-.455.92-1.045.92-1.728 0-.902-.741-1.643-1.667-1.643m0 4.5-.222.448h-.001l-.002-.002-.008-.003-.023-.012-.08-.043a5.983 5.983 0 0 1-1.084-.776c-.58-.528-1.247-1.367-1.247-2.47C8.665 3.179 9.865 2 11.332 2c1.468 0 2.667 1.178 2.667 2.643 0 1.102-.666 1.941-1.248 2.469a6 6 0 0 1-1.163.819l-.023.012-.007.003-.003.001s-.001.001-.223-.447m0 0 .222.448a.5.5 0 0 1-.444 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLocationMapRouteOutline.displayName = 'LocationMapRouteOutline';
const Memo = memo(SvgLocationMapRouteOutline);
export default Memo;
