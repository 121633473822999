// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCompassRoundBrowserSafariWebInternetNavigationOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m8.287-1.62-2.455.669a.17.17 0 0 0-.116.117l-.67 2.455 2.455-.67a.17.17 0 0 0 .117-.117zm.019-1.042A.833.833 0 0 1 10.66 6.36l-.746 2.736c-.109.399-.42.71-.819.819l-2.736.746A.833.833 0 0 1 5.336 9.64l.747-2.736c.108-.399.42-.71.818-.819z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCompassRoundBrowserSafariWebInternetNavigationOutline.displayName =
  'CompassRoundBrowserSafariWebInternetNavigationOutline';
const Memo = memo(SvgCompassRoundBrowserSafariWebInternetNavigationOutline);
export default Memo;
