// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCreditCard2CardPaymentOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3.669a.833.833 0 0 0-.833.833V6h11.33V4.498a.83.83 0 0 0-.831-.83zM13.663 7v.499a.5.5 0 1 0 1 0V4.498c-.001-1.012-.822-1.83-1.832-1.83H3.165c-1.012 0-1.833.821-1.833 1.834v6.995c0 1.013.82 1.834 1.833 1.834h5.831a.5.5 0 1 0 0-1h-5.83a.833.833 0 0 1-.834-.834V7zm-.831 2a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1h-1.5v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1h1.5V9.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCreditCard2CardPaymentOutline.displayName = 'CreditCard2CardPaymentOutline';
const Memo = memo(SvgCreditCard2CardPaymentOutline);
export default Memo;
