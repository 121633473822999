// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTruckDeliveryFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.401 4h-1.37c-.602 0-1.165.295-1.508.79l-.865 1.25c-.212.306-.326.67-.326 1.043v2.44a1.81 1.81 0 0 0 1.336 1.748 2.167 2.167 0 0 0 4.324.062h2.013a2.167 2.167 0 0 0 4.32 0h.007c.736 0 1.333-.597 1.333-1.333V4.5c0-1.012-.82-1.833-1.833-1.833H7.165c-.839 0-1.546.564-1.764 1.333m-.069 1H4.031a.83.83 0 0 0-.686.36L2.48 6.608a.83.83 0 0 0-.148.474v2.44a.81.81 0 0 0 .522.758 2.17 2.17 0 0 1 2.478-1.223zm6.737 5.429q.073.214.21.39.052.165.053.348a1.167 1.167 0 1 1-.263-.738M4.832 10a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTruckDeliveryFill.displayName = 'TruckDeliveryFill';
const Memo = memo(SvgTruckDeliveryFill);
export default Memo;
