import React from 'react';

type StaticProps = {
  [key in string]: React.FC<any>;
};

export const withStaticProperties = <C extends React.FC<any>, S extends StaticProps>(
  component: C,
  staticProps: S,
): C & S => {
  // might need to do special care for ForwardRef component
  return Object.assign(component, staticProps);
};
