// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDragGrabOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5 3.834a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0M8.667 3.834a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0M5 12.167a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0M8.667 12.167a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0M5 7.934a1.167 1.167 0 1 1 2.333 0V8A1.167 1.167 0 1 1 5 8zM8.667 7.934a1.167 1.167 0 1 1 2.333 0V8a1.167 1.167 0 1 1-2.333 0z" />
    </Svg>
  );
};
SvgDragGrabOutline.displayName = 'DragGrabOutline';
const Memo = memo(SvgDragGrabOutline);
export default Memo;
