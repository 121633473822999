// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCamera4ActionCamGoproFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.338 2.667A2.82 2.82 0 0 0 7.665 4.5v2.335A2.833 2.833 0 0 0 10.5 9.668h2.333c.7 0 1.34-.253 1.833-.673v3.172c0 1.012-.82 1.833-1.833 1.833H3.165a1.833 1.833 0 0 1-1.833-1.833V4.5c0-1.013.82-1.833 1.833-1.833zM3.332 11.5a.5.5 0 0 1 .5-.5h1.667a.5.5 0 1 1 0 1H3.832a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M10.499 2.667c-1.013 0-1.834.82-1.834 1.833v2.335c0 1.013.821 1.833 1.834 1.833h2.333c1.013 0 1.833-.82 1.833-1.833V4.5c0-1.013-.82-1.833-1.833-1.833zm.5 3a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCamera4ActionCamGoproFill.displayName = 'Camera4ActionCamGoproFill';
const Memo = memo(SvgCamera4ActionCamGoproFill);
export default Memo;
