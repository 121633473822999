// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPercentSalesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m9.687-3.02a.5.5 0 0 1 0 .707L5.686 11.02a.5.5 0 0 1-.708-.707l5.334-5.333a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
      <Path
        stroke="#0D1011"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M5.418 6a.583.583 0 1 0 1.167 0 .583.583 0 0 0-1.167 0M9.418 10a.583.583 0 1 0 1.167 0 .583.583 0 0 0-1.167 0"
      />
    </Svg>
  );
};
SvgPercentSalesOutline.displayName = 'PercentSalesOutline';
const Memo = memo(SvgPercentSalesOutline);
export default Memo;
