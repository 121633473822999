// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDebugDebuggerFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.216 3.005a.167.167 0 0 0-.198.129l-.243 1.14 1.305.278.242-1.141a.167.167 0 0 0-.128-.198zM9.058 4.76 9.3 3.62a1.167 1.167 0 0 0-.898-1.384l-.978-.208a1.167 1.167 0 0 0-1.384.899l-.243 1.141-.489-.104a1.833 1.833 0 0 0-2.174 1.412l-1.248 5.87c-.21.99.422 1.963 1.412 2.174l4.239.9c.99.211 1.964-.421 2.174-1.412l1.248-5.869a1.833 1.833 0 0 0-1.412-2.174zM5.224 7.497a.5.5 0 0 1 .689.158l.616.984.964-.648a.5.5 0 1 1 .558.83l-.977.657.627.93a.5.5 0 1 1-.83.56l-.636-.946-.966.605a.5.5 0 0 1-.53-.848l.952-.596-.625-.997a.5.5 0 0 1 .158-.69"
        clipRule="evenodd"
      />
      <Path d="M11.665 4a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M14.999 4a.667.667 0 1 1-1.334 0A.667.667 0 0 1 15 4M13.332 3.167a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M13.332 4.833a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M14.999 2.333a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M14.999 5.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0" />
    </Svg>
  );
};
SvgDebugDebuggerFill.displayName = 'DebugDebuggerFill';
const Memo = memo(SvgDebugDebuggerFill);
export default Memo;
