// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEmail2NotificationBadgeEnvelopeMailFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G
        fill="#0D1011"
        clipPath="url(#a)"
      >
        <Path
          fillRule="evenodd"
          d="M13.332 1.333a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.334M11.665 4A1.667 1.667 0 1 1 15 4a1.667 1.667 0 0 1-3.334 0"
          clipRule="evenodd"
        />
        <Path d="M13.332 7.708q-.399 0-.773-.08a10.46 10.46 0 0 1-4.56 1.039c-2.532 0-4.854-.896-6.667-2.388V11.5c0 1.012.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833V7.461a3.7 3.7 0 0 1-1.333.247" />
        <Path d="M9.624 4c0-.47.087-.92.247-1.333H3.165c-1.012 0-1.833.82-1.833 1.833v.435a9.47 9.47 0 0 0 6.667 2.732 9.5 9.5 0 0 0 3.277-.58A3.7 3.7 0 0 1 9.624 4" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgEmail2NotificationBadgeEnvelopeMailFill.displayName = 'Email2NotificationBadgeEnvelopeMailFill';
const Memo = memo(SvgEmail2NotificationBadgeEnvelopeMailFill);
export default Memo;
