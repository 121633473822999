// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCallIncomingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.064 3.864C1.952 2.804 2.832 2 3.815 2h1.188c.813 0 1.53.536 1.759 1.316l.33 1.121c.189.643.004 1.338-.48 1.802-.26.25-.301.609-.141.856A8.2 8.2 0 0 0 8.905 9.53c.248.16.606.12.857-.142a1.8 1.8 0 0 1 1.801-.479l1.122.33c.78.23 1.316.945 1.316 1.759v1.169c0 .995-.82 1.875-1.886 1.757a11.3 11.3 0 0 1-4.46-1.448 11.4 11.4 0 0 1-4.13-4.13 11.3 11.3 0 0 1-1.46-4.482M3.815 3c-.474 0-.797.37-.756.758a10.3 10.3 0 0 0 1.33 4.086 10.4 10.4 0 0 0 3.767 3.767 10.3 10.3 0 0 0 4.069 1.32c.398.043.776-.287.776-.764v-1.17a.83.83 0 0 0-.599-.799l-1.121-.33a.8.8 0 0 0-.798.213c-.535.557-1.415.744-2.12.288A9.2 9.2 0 0 1 5.63 7.64c-.456-.706-.268-1.587.29-2.121a.8.8 0 0 0 .211-.798l-.33-1.122A.83.83 0 0 0 5.003 3zm10.04-.854a.5.5 0 0 1 0 .708L11.04 5.667h1.793a.5.5 0 1 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 1 1 1 0V4.96l2.813-2.814a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCallIncomingOutline.displayName = 'CallIncomingOutline';
const Memo = memo(SvgCallIncomingOutline);
export default Memo;
