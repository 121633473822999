// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBuildingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.21 2h7.58c.32 0 .628.131.855.364.228.233.355.55.355.88v10.378a.373.373 0 0 1-.368.378H10v-2.654c0-.249-.232-.451-.519-.451H6.52c-.287 0-.519.202-.519.451V14H3.368A.374.374 0 0 1 3 13.622V3.243c0-.33.128-.646.355-.879S3.889 2 4.21 2m2.827 9.798v2.194h1.926v-2.194zM4.757 5c0-.262.198-.474.442-.474h1.765c.243 0 .44.212.44.474s-.197.474-.44.474H5.199c-.244 0-.441-.212-.441-.474M8.29 5c0-.262.198-.474.441-.474h1.765c.244 0 .441.212.441.474s-.197.474-.441.474H8.73c-.243 0-.44-.212-.44-.474M5.2 6.42c-.244 0-.441.212-.441.474 0 .261.197.473.441.473h1.765c.243 0 .44-.212.44-.473 0-.262-.197-.474-.44-.474zm3.09.474c0-.262.198-.474.441-.474h1.765c.244 0 .441.212.441.474 0 .261-.197.473-.441.473H8.73c-.243 0-.44-.212-.44-.473M5.2 8.315c-.244 0-.441.213-.441.474 0 .262.197.474.441.474h1.765c.243 0 .44-.212.44-.474 0-.261-.197-.473-.44-.473zm3.09.474c0-.261.198-.473.441-.473h1.765c.244 0 .441.212.441.473 0 .262-.197.474-.441.474H8.73c-.243 0-.44-.212-.44-.474"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBuildingFill.displayName = 'BuildingFill';
const Memo = memo(SvgBuildingFill);
export default Memo;
