// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLineChart3StatisticsGraphDescendingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.832 2.667a.5.5 0 0 1 .5.5v9.666a.5.5 0 1 1-1 0V3.167a.5.5 0 0 1 .5-.5m4.11 2.666a.5.5 0 0 1 .5.5v7a.5.5 0 1 1-1 0v-7a.5.5 0 0 1 .5-.5M10.054 8a.5.5 0 0 1 .5.5v4.333a.5.5 0 1 1-1 0V8.5a.5.5 0 0 1 .5-.5m4.111 2.667a.5.5 0 0 1 .5.5v1.666a.5.5 0 1 1-1 0v-1.666a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLineChart3StatisticsGraphDescendingFill.displayName = 'LineChart3StatisticsGraphDescendingFill';
const Memo = memo(SvgLineChart3StatisticsGraphDescendingFill);
export default Memo;
