// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFurnishedFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.087 1c-.29 0-.524.22-.524.492 0 .271.234.491.524.491h.397v5.805a1 1 0 0 0-.005.067c0 .215-.09.421-.253.573a.9.9 0 0 1-.61.237.9.9 0 0 1-.611-.237.79.79 0 0 1-.253-.573V6.521c.187-.068.36-.172.504-.308.272-.255.425-.6.425-.961 0-.326-.127-.827-.312-1.238a2.4 2.4 0 0 0-.374-.608c-.144-.164-.4-.381-.764-.381s-.62.217-.764.381c-.157.177-.28.398-.374.608-.184.411-.312.912-.312 1.238 0 .36.153.706.425.961.143.134.312.237.497.305v1.337c0 .476.202.932.56 1.268a2.016 2.016 0 0 0 2.221.33v2.409l-.002.043c0 .56-.237 1.097-.66 1.493a2.33 2.33 0 0 1-1.592.619 2.33 2.33 0 0 1-1.592-.619 2.05 2.05 0 0 1-.66-1.493v-1.337a1.5 1.5 0 0 0 .497-.305c.272-.255.425-.6.425-.96 0-.327-.128-.827-.312-1.239a2.4 2.4 0 0 0-.374-.608c-.145-.163-.4-.381-.764-.381s-.62.218-.764.381c-.157.177-.28.398-.374.608C1.127 8.476 1 8.976 1 9.302c0 .36.153.707.425.961.145.136.317.24.505.308v1.334a3 3 0 0 0 .966 2.189c.62.58 1.459.906 2.334.906.876 0 1.715-.326 2.334-.906a3 3 0 0 0 .444-.517q.19.279.443.517c.62.58 1.459.906 2.334.906.876 0 1.715-.326 2.334-.906a3 3 0 0 0 .967-2.189v-1.34c.182-.067.35-.17.49-.302.272-.255.425-.6.425-.96 0-.327-.127-.827-.311-1.239a2.4 2.4 0 0 0-.374-.608c-.145-.163-.4-.381-.765-.381-.363 0-.62.218-.764.381-.157.177-.28.398-.374.608-.184.412-.311.912-.311 1.238 0 .36.152.707.424.961.147.138.321.243.511.31v1.332c0 .56-.237 1.097-.66 1.493a2.33 2.33 0 0 1-1.592.619 2.33 2.33 0 0 1-1.592-.619 2.05 2.05 0 0 1-.66-1.493v-2.45a2.02 2.02 0 0 0 2.216-.332c.359-.336.56-.792.56-1.268V6.517c.184-.068.352-.17.494-.304.272-.255.425-.6.425-.961 0-.326-.127-.827-.312-1.238a2.4 2.4 0 0 0-.374-.608c-.144-.164-.4-.381-.764-.381s-.62.217-.764.381c-.157.177-.28.398-.374.608-.185.411-.312.912-.312 1.238 0 .36.153.706.425.961.145.137.319.241.508.309v1.333c0 .215-.091.421-.253.573a.9.9 0 0 1-.611.237.9.9 0 0 1-.61-.237.79.79 0 0 1-.254-.573V1.983h.405c.29 0 .524-.22.524-.491 0-.272-.235-.492-.524-.492H7.087"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFurnishedFill.displayName = 'FurnishedFill';
const Memo = memo(SvgFurnishedFill);
export default Memo;
