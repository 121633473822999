// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicStickTopFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.74 7.328c-.307.196-.405.406-.405.56 0 .156.098.366.404.562.3.192.745.328 1.262.328.518 0 .962-.136 1.263-.328.305-.196.404-.406.404-.561s-.099-.365-.404-.561C8.964 7.135 8.519 7 8 7s-.962.135-1.262.328m2.928 2.045c-.465.26-1.049.405-1.667.405S6.8 9.633 6.335 9.373v2.683c0 .155.098.365.404.56.3.193.745.328 1.262.328.518 0 .962-.135 1.263-.327.305-.196.404-.406.404-.562zm1-1.484c0-.612-.386-1.096-.865-1.403C9.32 6.176 8.681 6 8.001 6s-1.318.176-1.802.486c-.478.307-.864.79-.864 1.403v6.944a.5.5 0 0 0 .499.5h4.335a.5.5 0 0 0 .499-.5z"
        clipRule="evenodd"
      />
      <Path d="M3.096 3.478a.267.267 0 0 1 .477 0c.503 1.005.611 1.113 1.617 1.616a.266.266 0 0 1 0 .476c-1.006.503-1.114.61-1.617 1.616a.267.267 0 0 1-.477 0C2.593 6.181 2.485 6.073 1.48 5.57a.266.266 0 0 1 0-.476c1.006-.503 1.114-.611 1.617-1.616M7.76.814a.267.267 0 0 1 .477 0c.503 1.005.611 1.113 1.617 1.616a.266.266 0 0 1 0 .476c-1.006.503-1.114.611-1.617 1.616a.267.267 0 0 1-.477 0C7.257 3.517 7.15 3.41 6.143 2.906a.266.266 0 0 1 0-.476C7.15 1.927 7.257 1.82 7.76.814M12.424 3.478a.267.267 0 0 1 .477 0c.503 1.005.611 1.113 1.617 1.616a.266.266 0 0 1 0 .476c-1.006.503-1.114.61-1.617 1.616a.267.267 0 0 1-.477 0c-.502-1.005-.611-1.113-1.617-1.616a.266.266 0 0 1 0-.476c1.006-.503 1.114-.611 1.617-1.616" />
    </Svg>
  );
};
SvgMagicStickTopFill.displayName = 'MagicStickTopFill';
const Memo = memo(SvgMagicStickTopFill);
export default Memo;
