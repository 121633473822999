// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStarFavoriteAwardOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.094 1.237c.365-.76 1.449-.76 1.814 0l1.58 3.286q0 .002.004.003l3.633.476c.837.11 1.179 1.142.56 1.725L12.03 9.231l-.001.003.667 3.577c.156.835-.728 1.466-1.467 1.067l-3.223-1.737a.01.01 0 0 0-.008 0l-3.223 1.737c-.74.399-1.623-.232-1.467-1.067l.667-3.577-.001-.003-2.656-2.504c-.619-.583-.278-1.615.56-1.725l3.633-.476q.004 0 .005-.003zm.902.43-.001.003-1.58 3.286c-.146.307-.439.518-.775.562l-3.634.475-.003.001L2 5.998l.002.001 2.656 2.505c.248.233.36.577.298.913l-.667 3.578v.001l.001.002.005.002.004-.002 3.223-1.737c.298-.161.658-.161.956 0l3.223 1.737.004.002.004-.002.002-.002v-.001l-.667-3.578c-.063-.336.05-.68.298-.913L14 5.999l.002-.001v-.001l-.002-.003h-.004l-3.633-.476a1 1 0 0 1-.776-.562L8.006 1.67v-.003h-.01"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStarFavoriteAwardOutline.displayName = 'StarFavoriteAwardOutline';
const Memo = memo(SvgStarFavoriteAwardOutline);
export default Memo;
