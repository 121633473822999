// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPointerHandOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.335 2.333A.667.667 0 0 0 5.668 3v4.333a.5.5 0 0 1-.812.39l-.626-.5a.833.833 0 0 0-1.171.13l-.296.37 1.837 3.49a4.28 4.28 0 0 0 8.068-1.994v-.386c0-1.196-.97-2.166-2.167-2.166h-3a.5.5 0 0 1-.5-.5V3a.667.667 0 0 0-.666-.667M4.668 3a1.667 1.667 0 0 1 3.333 0v2.667h2.5a3.167 3.167 0 0 1 3.167 3.166v.386a5.28 5.28 0 0 1-9.953 2.46l-1.99-3.78a.5.5 0 0 1 .053-.545l.5-.625a1.834 1.834 0 0 1 2.39-.418z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPointerHandOutline.displayName = 'PointerHandOutline';
const Memo = memo(SvgPointerHandOutline);
export default Memo;
