// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGarageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.072 2.296a1.83 1.83 0 0 1 1.853 0l4.833 2.83c.562.33.907.932.907 1.583V11.5c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5V6.709c0-.651.345-1.253.907-1.582zM5 12.333h6v-1.666h-6zm0-2.666h6v-.834A.833.833 0 0 0 10.165 8H5.832a.833.833 0 0 0-.833.833zm7 2.666v-3.5c0-1.012-.821-1.833-1.834-1.833H5.832c-1.012 0-1.833.82-1.833 1.833v3.5h-.834a.833.833 0 0 1-.833-.833V6.709c0-.296.157-.57.412-.72l4.834-2.83a.83.83 0 0 1 .842 0l4.833 2.83c.255.15.412.424.412.72V11.5c0 .46-.373.833-.833.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGarageOutline.displayName = 'GarageOutline';
const Memo = memo(SvgGarageOutline);
export default Memo;
