// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMedicinePharmacyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.04 2.38a3.54 3.54 0 0 1 2.509-1.047c.94 0 1.843.377 2.509 1.048a3.58 3.58 0 0 1 1.04 2.528 3.6 3.6 0 0 1-1.04 2.53 3.54 3.54 0 0 1-2.51 1.047A3.54 3.54 0 0 1 3.04 7.438 3.58 3.58 0 0 1 2 4.91a3.6 3.6 0 0 1 1.04-2.528m.015 3.721a2.75 2.75 0 0 0 2.494 1.59A2.75 2.75 0 0 0 7.5 6.876a2.8 2.8 0 0 0 .542-.775zm-.238-.794a2.8 2.8 0 0 1 0-.795H8.28a2.8 2.8 0 0 1 0 .795zM7.5 2.943c.226.227.408.49.542.774H3.055a2.8 2.8 0 0 1 .542-.774 2.75 2.75 0 0 1 1.952-.815 2.75 2.75 0 0 1 1.951.815m5.39 3.827a1.57 1.57 0 0 0-1.744.39l-.01.01-1.392 1.404 2.229 2.246 1.392-1.403.01-.01a1.6 1.6 0 0 0 .502-1.135 1.6 1.6 0 0 0-.462-1.152c-.15-.15-.328-.27-.524-.35m-.609 4.863 1.637-1.65a2.38 2.38 0 0 0 .747-1.697 2.4 2.4 0 0 0-.692-1.728 2.37 2.37 0 0 0-1.715-.698 2.35 2.35 0 0 0-1.684.754l-1.65 1.662-.033.034-1.658 1.67-.01.01a2.395 2.395 0 0 0 .058 3.312 2.357 2.357 0 0 0 3.286.058l.01-.01 1.645-1.657a.4.4 0 0 0 .06-.06m-.866-.251L9.186 9.136l-1.39 1.401a1.596 1.596 0 0 0 .043 2.203 1.57 1.57 0 0 0 2.186.043z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMedicinePharmacyOutline.displayName = 'MedicinePharmacyOutline';
const Memo = memo(SvgMedicinePharmacyOutline);
export default Memo;
