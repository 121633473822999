// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayers2StackOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.071 3.017a.16.16 0 0 0-.145 0l-5.5 2.737a.174.174 0 0 0 0 .31l5.5 2.736c.046.023.1.023.145 0l5.5-2.737a.174.174 0 0 0 0-.31zm-.59-.895c.326-.163.71-.163 1.036 0l5.5 2.736c.865.43.865 1.67 0 2.1L11.922 8l2.093 1.041c.866.431.866 1.67 0 2.1l-5.499 2.737c-.327.163-.71.163-1.037 0l-5.499-2.736c-.865-.43-.865-1.67 0-2.1L4.074 8 1.981 6.959c-.865-.431-.865-1.67 0-2.1zM5.195 8.558l-2.77 1.379a.174.174 0 0 0 0 .31l5.5 2.736c.046.023.1.023.145 0l5.5-2.737a.174.174 0 0 0 0-.31L10.8 8.559 8.517 9.695c-.327.162-.71.162-1.037 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLayers2StackOutline.displayName = 'Layers2StackOutline';
const Memo = memo(SvgLayers2StackOutline);
export default Memo;
