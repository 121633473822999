// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBrokenLinkOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.364 2a.41.41 0 0 1 .409.41v.817a.41.41 0 1 1-.818 0V2.41A.41.41 0 0 1 6.364 2m-3.426.938a.41.41 0 0 1 .579 0l.818.818a.41.41 0 0 1-.579.579l-.818-.818a.41.41 0 0 1 0-.579m5.365.362a1.5 1.5 0 0 1 2.121 0L12.7 5.576a1.5 1.5 0 0 1 0 2.121l-1.274 1.274a.409.409 0 1 1-.579-.578l1.274-1.275a.68.68 0 0 0 0-.964L9.846 3.88a.68.68 0 0 0-.964 0L7.607 5.153a.41.41 0 1 1-.578-.579zM2 6.364a.41.41 0 0 1 .41-.41h.817a.41.41 0 1 1 0 .819H2.41A.41.41 0 0 1 2 6.363m3.153.665a.41.41 0 0 1 0 .578L3.879 8.882a.68.68 0 0 0 0 .964l2.275 2.275a.68.68 0 0 0 .964 0l1.275-1.274a.409.409 0 1 1 .578.579L7.697 12.7a1.5 1.5 0 0 1-2.121 0L3.3 10.424a1.5 1.5 0 0 1 0-2.121l1.274-1.274a.41.41 0 0 1 .579 0m7.21 2.607a.41.41 0 0 1 .41-.409h.818a.41.41 0 0 1 0 .819h-.818a.41.41 0 0 1-.41-.41m-.698 2.03c.16-.16.42-.16.579 0l.818.817a.409.409 0 1 1-.578.579l-.819-.818a.41.41 0 0 1 0-.579m-2.029.698a.41.41 0 0 1 .41.409v.818a.41.41 0 0 1-.819 0v-.818a.41.41 0 0 1 .41-.41"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBrokenLinkOutline.displayName = 'BrokenLinkOutline';
const Memo = memo(SvgBrokenLinkOutline);
export default Memo;
