// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayout3RowsGridWindowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.613 1.984h6.774c.356 0 .654 0 .897.02.255.021.495.066.722.182.348.177.63.46.808.808.116.227.161.467.182.722.02.243.02.54.02.897v6.774c0 .356 0 .654-.02.897-.021.255-.066.495-.182.722a1.85 1.85 0 0 1-.808.808c-.227.116-.467.161-.722.182-.243.02-.54.02-.897.02H4.613c-.356 0-.654 0-.897-.02a1.9 1.9 0 0 1-.722-.182 1.85 1.85 0 0 1-.808-.808 1.9 1.9 0 0 1-.182-.722c-.02-.243-.02-.54-.02-.897V4.613c0-.356 0-.654.02-.897.021-.255.066-.495.182-.722.177-.348.46-.63.808-.808.227-.116.467-.161.722-.182.243-.02.54-.02.897-.02M3.016 6.68v2.64h9.968V6.68zm9.968-1.03H3.016V4.632c0-.382 0-.637.016-.833.016-.19.043-.28.073-.338a.8.8 0 0 1 .357-.357c.058-.03.147-.057.338-.073.196-.016.451-.016.833-.016h6.734c.381 0 .637 0 .833.016.19.016.28.043.338.073a.8.8 0 0 1 .357.357c.03.058.057.147.073.338.016.196.016.451.016.833zm0 4.7H3.016v1.017c0 .381 0 .637.016.833.016.19.043.28.073.338a.8.8 0 0 0 .357.357c.058.03.147.057.338.073.196.016.451.016.833.016h6.734c.381 0 .637 0 .833-.016.19-.016.28-.043.338-.073a.8.8 0 0 0 .357-.357c.03-.058.057-.147.073-.338.016-.196.016-.451.016-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLayout3RowsGridWindowOutline.displayName = 'Layout3RowsGridWindowOutline';
const Memo = memo(SvgLayout3RowsGridWindowOutline);
export default Memo;
