// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile5DocumentCloudSyncFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 1.333h3.5v3.5c0 1.013.821 1.834 1.834 1.834h3.5v6.166c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834V3.167c0-1.013.82-1.834 1.833-1.834m5.52 10.02L8.356 13.02a.5.5 0 0 1-.707 0L5.98 11.354a.5.5 0 1 1 .707-.708l.813.813V8.5a.5.5 0 0 1 1 0v2.96l.813-.813a.5.5 0 1 1 .707.707"
        clipRule="evenodd"
      />
      <Path d="m12.993 5.618.046.049H9.835A.833.833 0 0 1 9 4.833V1.63l.05.046z" />
    </Svg>
  );
};
SvgFile5DocumentCloudSyncFill.displayName = 'File5DocumentCloudSyncFill';
const Memo = memo(SvgFile5DocumentCloudSyncFill);
export default Memo;
