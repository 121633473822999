// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArchiveBoxInboxFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.832 2a.5.5 0 0 0-.5.5v1.667a.5.5 0 0 0 .5.5h12.333a.5.5 0 0 0 .5-.5V2.5a.5.5 0 0 0-.5-.5z" />
      <Path
        fillRule="evenodd"
        d="M1.999 11.387v-5.72h12v5.72c0 .356 0 .653-.02.896-.02.253-.066.491-.18.716a1.83 1.83 0 0 1-.801.801c-.225.115-.463.16-.716.18-.243.02-.54.02-.896.02H4.612c-.356 0-.654 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.199 13a1.9 1.9 0 0 1-.18-.716c-.02-.243-.02-.54-.02-.896m4.666-4.054a.5.5 0 0 0 0 1h2.667a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArchiveBoxInboxFileFill.displayName = 'ArchiveBoxInboxFileFill';
const Memo = memo(SvgArchiveBoxInboxFileFill);
export default Memo;
