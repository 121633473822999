// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVideoCameraMoviePlayConferenceFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h5.667c1.013 0 1.833.82 1.833 1.833v1.358l2.312-1.156a1.167 1.167 0 0 1 1.688 1.044v4.509c0 .867-.912 1.431-1.688 1.043l-2.312-1.155V11.5c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm9.333 4.525 2.76 1.38a.167.167 0 0 0 .24-.15v-4.51a.167.167 0 0 0-.24-.148l-2.76 1.38z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgVideoCameraMoviePlayConferenceFill.displayName = 'VideoCameraMoviePlayConferenceFill';
const Memo = memo(SvgVideoCameraMoviePlayConferenceFill);
export default Memo;
