// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBagLuggageBuggageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.834 4.667V3.5a1.5 1.5 0 0 1 1.5-1.5h3.333a1.5 1.5 0 0 1 1.5 1.5v1.167h1.665c1.013 0 1.833.82 1.833 1.833V8H1.332V6.5c0-1.013.82-1.833 1.833-1.833zm1-1.167a.5.5 0 0 1 .5-.5h3.333a.5.5 0 0 1 .5.5v1.167H5.834z"
        clipRule="evenodd"
      />
      <Path d="M1.332 12.167V9h6.167l.001 1.167a.5.5 0 0 0 1 0V9h6.165v3.167c0 1.012-.82 1.833-1.833 1.833H3.165a1.833 1.833 0 0 1-1.833-1.833" />
    </Svg>
  );
};
SvgBagLuggageBuggageFill.displayName = 'BagLuggageBuggageFill';
const Memo = memo(SvgBagLuggageBuggageFill);
export default Memo;
