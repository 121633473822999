// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCrownVipFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.42 2.226a.5.5 0 0 0-.837 0L5.01 6.162 1.42 4.068a.5.5 0 0 0-.742.532l1.49 7.268a1.83 1.83 0 0 0 1.796 1.465h8.075c.87 0 1.62-.612 1.796-1.465l1.49-7.268a.5.5 0 0 0-.742-.532l-3.59 2.094z" />
    </Svg>
  );
};
SvgCrownVipFill.displayName = 'CrownVipFill';
const Memo = memo(SvgCrownVipFill);
export default Memo;
