// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPencilPenToolWriteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.038 2.256a1.833 1.833 0 0 1 2.593 0l2.114 2.114a1.833 1.833 0 0 1 0 2.593l-1.312 1.312-1.041 2.864a1.83 1.83 0 0 1-1.35 1.169L2.77 13.823a.5.5 0 0 1-.592-.592L3.694 5.96a1.83 1.83 0 0 1 1.168-1.35l2.864-1.041zM7.873 4.579l-2.67.97a.83.83 0 0 0-.53.614l-1.174 5.632 2.679-2.68a1.667 1.667 0 1 1 .707.707l-2.679 2.68 5.633-1.173a.83.83 0 0 0 .613-.531l.97-2.67zM12 7.293l1.037-1.037a.833.833 0 0 0 0-1.179l-2.114-2.114a.833.833 0 0 0-1.179 0L8.708 4zm-4.345.385a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPencilPenToolWriteOutline.displayName = 'PencilPenToolWriteOutline';
const Memo = memo(SvgPencilPenToolWriteOutline);
export default Memo;
