// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowBottomLeftOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.671 12.167a.5.5 0 0 1-.5.5H5.168a1.833 1.833 0 0 1-1.833-1.834V5.17a.5.5 0 1 1 1 0v5.664q0 .06.008.118l6.971-6.971a.5.5 0 1 1 .707.707L5.05 11.658a1 1 0 0 0 .118.009h5.003a.5.5 0 0 1 .5.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowBottomLeftOutline.displayName = 'ArrowBottomLeftOutline';
const Memo = memo(SvgArrowBottomLeftOutline);
export default Memo;
