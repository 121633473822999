// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSandboxSafeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.46 2.91c.13-.925.986-1.57 1.912-1.44l.939.132a1.693 1.693 0 0 1 .168 3.32l-.63.154-.377 2.676L14.002 10h.163a.5.5 0 0 1 .5.5v1.667c0 1.012-.82 1.833-1.833 1.833H3.165a1.833 1.833 0 0 1-1.833-1.833V10.5a.5.5 0 0 1 .5-.5h.094l1.212-1.347-.624-2.836c-.13-.59.212-1.168.762-1.355l-.003-.023a1.833 1.833 0 0 1 3.631-.51l.003.023c.58.028 1.068.489 1.106 1.092l.193 3.064 2.278-.506.374-2.665-.563-.322A1.69 1.69 0 0 1 9.46 2.91m2.015 1.228.765-.188a.693.693 0 0 0-.069-1.358l-.938-.132a.693.693 0 0 0-.441 1.287zm-5.56-.068-.001-.002a.833.833 0 1 0-1.65.232v.002zM12.495 10H3.271l1.452-1.612a.83.83 0 0 1 .957-.205l1.85.822c.27.12.572.15.861.087l2.467-.548z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSandboxSafeFill.displayName = 'SandboxSafeFill';
const Memo = memo(SvgSandboxSafeFill);
export default Memo;
