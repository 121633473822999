// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicStickStarOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m7.806 3.669.14 1.803a.83.83 0 0 1-.395.774l-1.54.947 1.955.472a.5.5 0 0 1 .368.369l.473 1.956.947-1.54a.83.83 0 0 1 .774-.395l1.803.139-1.172-1.377a.83.83 0 0 1-.136-.858l.69-1.672-1.672.69a.83.83 0 0 1-.858-.136zm-1.028-.323a.833.833 0 0 1 1.371-.699l1.605 1.367 1.948-.804a.833.833 0 0 1 1.088 1.088l-.804 1.948 1.366 1.605a.833.833 0 0 1-.698 1.37l-2.101-.161-1.104 1.795a.833.833 0 0 1-1.52-.24l-.357-1.48-4.718 4.718a.5.5 0 1 1-.708-.707l4.719-4.718-1.48-.357a.833.833 0 0 1-.24-1.52L6.94 5.447z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMagicStickStarOutline.displayName = 'MagicStickStarOutline';
const Memo = memo(SvgMagicStickStarOutline);
export default Memo;
