// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFingerPrint2TouchIdFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.332 6.972c0-3.117 2.54-5.639 5.667-5.639q.43.001.843.062a.5.5 0 1 1-.147.99A5 5 0 0 0 8 2.333c-2.58 0-4.667 2.08-4.667 4.64v.542a.5.5 0 0 1-1 0zm8.573-4.278a.5.5 0 0 1 .703-.069 5.62 5.62 0 0 1 2.057 4.347v2.056c0 .912-.217 1.774-.605 2.537a.5.5 0 1 1-.891-.452 4.6 4.6 0 0 0 .496-2.085V6.972a4.62 4.62 0 0 0-1.692-3.575.5.5 0 0 1-.068-.703m-4.34 1.71c.433-.211.92-.33 1.434-.33a3.25 3.25 0 0 1 3.255 3.24v1.371q0 .069-.003.136a.5.5 0 1 1-.999-.041q.003-.047.002-.095v-1.37A2.25 2.25 0 0 0 8 5.075c-.358 0-.696.082-.995.228a.5.5 0 0 1-.44-.898M5.265 6.7a.5.5 0 0 1 .48.52l-.003.095v1.37A2.25 2.25 0 0 0 8 10.926c.358 0 .695-.083.995-.23a.5.5 0 0 1 .439.9c-.433.211-.92.33-1.434.33a3.25 3.25 0 0 1-3.256-3.24V7.314q0-.069.003-.136a.5.5 0 0 1 .52-.479M8 6.733a.5.5 0 0 1 .5.5v1.6a.5.5 0 1 1-1 0v-1.6a.5.5 0 0 1 .5-.5M2.863 9.786a.5.5 0 0 1 .606.364A4.664 4.664 0 0 0 8 13.667c.911 0 1.76-.26 2.478-.708a.5.5 0 0 1 .53.848 5.66 5.66 0 0 1-3.008.86 5.66 5.66 0 0 1-5.5-4.275.5.5 0 0 1 .364-.606"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFingerPrint2TouchIdFill.displayName = 'FingerPrint2TouchIdFill';
const Memo = memo(SvgFingerPrint2TouchIdFill);
export default Memo;
