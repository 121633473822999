// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronBottomFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.314 5.646a.5.5 0 0 1 .708 0l4.39 4.391a.833.833 0 0 0 1.179 0l4.39-4.39a.5.5 0 1 1 .707.707l-4.39 4.39a1.833 1.833 0 0 1-2.593 0l-4.39-4.39a.5.5 0 0 1 0-.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronBottomFill.displayName = 'ChevronBottomFill';
const Memo = memo(SvgChevronBottomFill);
export default Memo;
