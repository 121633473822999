// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSpaceStarOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m6.192 5.69.895-.448a.35.35 0 0 0 .155-.155l.448-.895a.347.347 0 0 1 .62 0l.448.895a.35.35 0 0 0 .155.155l.895.448a.347.347 0 0 1 0 .62l-.895.448a.35.35 0 0 0-.155.155l-.448.895a.347.347 0 0 1-.62 0l-.448-.895a.35.35 0 0 0-.155-.155l-.895-.448a.347.347 0 0 1 0-.62" />
      <Path
        fillRule="evenodd"
        d="M7.999 2.334a5.667 5.667 0 0 0-4.514 9.093A6.65 6.65 0 0 1 8 9.667c1.74 0 3.326.668 4.513 1.76A5.667 5.667 0 0 0 8 2.334m3.84 9.833a5.65 5.65 0 0 0-3.84-1.5c-1.482 0-2.83.568-3.84 1.5a5.65 5.65 0 0 0 3.84 1.5c1.482 0 2.83-.569 3.84-1.5M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSpaceStarOutline.displayName = 'SpaceStarOutline';
const Memo = memo(SvgSpaceStarOutline);
export default Memo;
