// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicPencilMagicBrushOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.963 2.578a1.508 1.508 0 0 1 2.127 2.128l-6.089 6.958v.003a3 3 0 0 1-3 3h-2.5a.5.5 0 0 1-.5-.5v-2.5a3 3 0 0 1 3-3h.003zM6.225 8.927a3 3 0 0 1 1.516 1.516l5.597-6.396a.508.508 0 0 0-.717-.716zm-1.224.74a2 2 0 0 0-2 2v2h2a2 2 0 1 0 0-4"
        clipRule="evenodd"
      />
      <Path d="M2.79 4.939a.33.33 0 0 0 .15-.15l.43-.86a.333.333 0 0 1 .596 0l.43.86a.33.33 0 0 0 .149.15l.86.43a.333.333 0 0 1 0 .596l-.86.43a.33.33 0 0 0-.15.149l-.43.86a.333.333 0 0 1-.596 0l-.43-.86a.33.33 0 0 0-.149-.15l-.86-.43a.333.333 0 0 1 0-.595zM5.708 2.48a.24.24 0 0 0 .106-.106l.307-.615a.238.238 0 0 1 .426 0l.307.615q.035.07.107.106l.614.307a.238.238 0 0 1 0 .426l-.614.307a.24.24 0 0 0-.107.107l-.307.614a.238.238 0 0 1-.426 0l-.307-.614a.24.24 0 0 0-.106-.107l-.615-.307a.238.238 0 0 1 0-.426z" />
    </Svg>
  );
};
SvgMagicPencilMagicBrushOutline.displayName = 'MagicPencilMagicBrushOutline';
const Memo = memo(SvgMagicPencilMagicBrushOutline);
export default Memo;
