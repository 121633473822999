// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGhostHiddenUnknownOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 2.333c-2.397 0-4.333 1.927-4.333 4.297v6.838c0 .13.143.21.255.142l.775-.482a1.83 1.83 0 0 1 1.764-.093l1.177.568c.229.11.496.11.725 0l1.177-.568a1.83 1.83 0 0 1 1.763.093l.776.482a.167.167 0 0 0 .255-.142V6.63c0-2.37-1.937-4.297-4.334-4.297M2.668 6.63c0-2.93 2.391-5.297 5.333-5.297s5.334 2.368 5.334 5.297v6.838a1.167 1.167 0 0 1-1.782.991l-.776-.481a.83.83 0 0 0-.802-.043l-1.177.569a1.83 1.83 0 0 1-1.594 0l-1.177-.569a.83.83 0 0 0-.801.043l-.776.481a1.167 1.167 0 0 1-1.782-.99zM5.335 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0m3.333 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGhostHiddenUnknownOutline.displayName = 'GhostHiddenUnknownOutline';
const Memo = memo(SvgGhostHiddenUnknownOutline);
export default Memo;
