// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAi2StarsSparklesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G
        fill="#0D1011"
        clipPath="url(#a)"
      >
        <Path d="M10.501 5.167a.5.5 0 0 0-1 0c0 1.617-.357 2.667-1.011 3.321C7.835 9.143 6.785 9.5 5.168 9.5a.5.5 0 0 0 0 1c1.617 0 2.667.357 3.322 1.011.654.655 1.011 1.705 1.011 3.322a.5.5 0 1 0 1 0c0-1.617.358-2.667 1.012-3.322.654-.654 1.704-1.011 3.322-1.011a.5.5 0 1 0 0-1c-1.618 0-2.668-.357-3.322-1.012-.654-.654-1.012-1.704-1.012-3.321M4.835 1.167a.5.5 0 1 0-1 0c0 1.038-.23 1.672-.613 2.054-.382.382-1.016.612-2.054.612a.5.5 0 0 0 0 1c1.038 0 1.672.23 2.054.613.383.382.613 1.015.613 2.054a.5.5 0 1 0 1 0c0-1.039.23-1.672.612-2.054.382-.383 1.016-.613 2.054-.613a.5.5 0 1 0 0-1c-1.038 0-1.672-.23-2.054-.612s-.612-1.016-.612-2.054" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgAi2StarsSparklesFill.displayName = 'Ai2StarsSparklesFill';
const Memo = memo(SvgAi2StarsSparklesFill);
export default Memo;
