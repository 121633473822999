// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChart7StatisticsGraph5Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3 1.167a.5.5 0 1 0-1 0v13.667a.5.5 0 0 0 1 0V14h10a1 1 0 0 0 1-1v-1.334a1 1 0 0 0-1-1H3v-1h6.333a1 1 0 0 0 1-1V7.334a1 1 0 0 0-1-1H3v-1h2.666a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z" />
    </Svg>
  );
};
SvgChart7StatisticsGraph5Fill.displayName = 'Chart7StatisticsGraph5Fill';
const Memo = memo(SvgChart7StatisticsGraph5Fill);
export default Memo;
