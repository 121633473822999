// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAnchor1Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667M5.665 3.666A2.333 2.333 0 1 1 8.5 5.946v7.72h2.83a.83.83 0 0 0 .801-.601l1.37-4.732h-1.668a.5.5 0 0 1 0-1h2.333a.5.5 0 0 1 .48.639l-1.554 5.37a1.83 1.83 0 0 1-1.761 1.324H4.667c-.816 0-1.534-.54-1.76-1.323L1.351 7.972a.5.5 0 0 1 .48-.639h2.333a.5.5 0 0 1 0 1H2.497l1.37 4.732c.103.356.43.601.8.601H7.5v-7.72a2.334 2.334 0 0 1-1.834-2.28"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAnchor1Outline.displayName = 'Anchor1Outline';
const Memo = memo(SvgAnchor1Outline);
export default Memo;
