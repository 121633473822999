// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSendEmailPaperPlaneArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.937 3.525a1.167 1.167 0 0 0-1.46-1.46L1.462 5.483c-1.127.35-1.082 1.96.062 2.246l5.302 1.326c.06.015.106.061.121.121l1.326 5.302c.286 1.144 1.896 1.19 2.246.062z" />
    </Svg>
  );
};
SvgSendEmailPaperPlaneArrowFill.displayName = 'SendEmailPaperPlaneArrowFill';
const Memo = memo(SvgSendEmailPaperPlaneArrowFill);
export default Memo;
