// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgParkFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.67 2.078 4.31 4.034h.022c.124 0 .245.035.35.102.106.067.191.163.247.277a.7.7 0 0 1-.063.717L3 7.69h.444c.124 0 .246.035.35.102.106.067.191.162.247.276a.7.7 0 0 1-.063.718L2.11 11.345h3.561a.45.45 0 0 1 .436.514v2.684a.45.45 0 0 1-.445.457.45.45 0 0 1-.444-.457v-2.284H1.667a.65.65 0 0 1-.35-.103.7.7 0 0 1-.247-.276.7.7 0 0 1 .063-.718L3 8.603h-.444a.65.65 0 0 1-.35-.102.7.7 0 0 1-.247-.277.7.7 0 0 1 .063-.718L3.89 4.948h-.016a.65.65 0 0 1-.346-.103.7.7 0 0 1-.243-.273.7.7 0 0 1 .054-.709l1.79-2.576a.652.652 0 0 1 1.085 0L8 3.862v.001a.697.697 0 0 1-.189.982.65.65 0 0 1-.345.103h-.022L8.9 6.943a.465.465 0 0 1-.088.64.436.436 0 0 1-.623-.092l-1.722-2.36a.697.697 0 0 1 .183-.995.65.65 0 0 1 .35-.102h.028zm8.443 7.896v2.284h.443a.45.45 0 0 1 .444.457.45.45 0 0 1-.444.457h-1.331v1.371a.45.45 0 0 1-.445.457.45.45 0 0 1-.444-.457v-1.37H9.67v1.37a.45.45 0 0 1-.444.457.45.45 0 0 1-.445-.457v-1.37H7.445A.45.45 0 0 1 7 12.714a.45.45 0 0 1 .444-.457h.447V9.974c0-.364.14-.712.39-.97.25-.257.59-.401.943-.401h3.556c.353 0 .692.144.942.402.25.257.39.605.39.969"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgParkFill.displayName = 'ParkFill';
const Memo = memo(SvgParkFill);
export default Memo;
