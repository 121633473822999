// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderAddPlusOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.335 3.833C1.335 2.821 2.155 2 3.168 2h2.786c.613 0 1.186.306 1.526.816l.542.813A.83.83 0 0 0 8.715 4h4.12c1.012 0 1.833.82 1.833 1.833V11.5c0 1.012-.82 1.833-1.833 1.833H7.168a.5.5 0 1 1 0-1h5.667c.46 0 .833-.373.833-.833V5.833A.833.833 0 0 0 12.835 5h-4.12a1.83 1.83 0 0 1-1.526-.816l-.541-.813A.83.83 0 0 0 5.954 3H3.168a.833.833 0 0 0-.833.833V7.5a.5.5 0 0 1-1 0zM3.168 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 0 1 0 1h-1.5v1.5a.5.5 0 0 1-1 0V12h-1.5a.5.5 0 0 1 0-1h1.5V9.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolderAddPlusOutline.displayName = 'FolderAddPlusOutline';
const Memo = memo(SvgFolderAddPlusOutline);
export default Memo;
