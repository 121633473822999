// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWhiteboardArtboardOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a.5.5 0 0 1 .5.5v.834h4.333c1.013 0 1.833.82 1.833 1.833v6.333c0 1.013-.82 1.834-1.833 1.834h-1.473l.447 1.342a.5.5 0 0 1-.948.316l-.553-1.658H8.499v.833a.5.5 0 1 1-1 0v-.833H5.692l-.552 1.658a.5.5 0 0 1-.949-.316l.447-1.342H3.165a1.833 1.833 0 0 1-1.833-1.834V4.5c0-1.013.82-1.833 1.833-1.833H7.5v-.834a.5.5 0 0 1 .5-.5M3.165 3.667a.833.833 0 0 0-.833.833v6.333c0 .46.373.834.833.834h9.667c.46 0 .833-.373.833-.834V4.5a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWhiteboardArtboardOutline.displayName = 'WhiteboardArtboardOutline';
const Memo = memo(SvgWhiteboardArtboardOutline);
export default Memo;
