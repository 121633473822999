// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDirectorChairRegieChairOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.499 2.333a.833.833 0 0 0-.834.834v4.166h8.667V3.167a.833.833 0 0 0-.833-.834zm8.833 5V3.167c0-1.013-.82-1.834-1.833-1.834h-7c-1.013 0-1.834.821-1.834 1.834v4.166h-.833a.5.5 0 0 0 0 1h1.184L7.086 11l-4.195 2.748a.5.5 0 1 0 .548.837L8 11.598l4.559 2.987a.5.5 0 0 0 .548-.837L8.911 11l4.07-2.667h1.184a.5.5 0 0 0 0-1zm-2.176 1H4.841l3.158 2.07z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDirectorChairRegieChairOutline.displayName = 'DirectorChairRegieChairOutline';
const Memo = memo(SvgDirectorChairRegieChairOutline);
export default Memo;
