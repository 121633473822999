// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble6MessageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.06 3.578C11.819 2.537 10.069 2 8 2s-3.818.537-5.06 1.578C1.685 4.628 1 6.148 1 8c0 .53.176 1.196.343 1.717.17.537.354.987.405 1.11l.008.018.005.013.004.01c.012.032.298.811-.664 2.086a.5.5 0 0 0 .184.753c.889.423 1.783.276 2.41.057a4.6 4.6 0 0 0 .816-.379C5.62 13.892 6.87 14 8 14c2.068 0 3.818-.537 5.06-1.578C14.315 11.372 15 9.852 15 8s-.686-3.372-1.94-4.422"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble6MessageFill.displayName = 'Bubble6MessageFill';
const Memo = memo(SvgBubble6MessageFill);
export default Memo;
