// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRightGamepadBaseControllsGameJoystickOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.168 9.5v-3a.667.667 0 0 0-.667-.667h-1.724a.67.67 0 0 0-.471.196L9.688 7.646a.5.5 0 0 0 0 .708l1.618 1.617a.67.67 0 0 0 .471.196h1.724a.667.667 0 0 0 .667-.667" />
      <Path
        fillRule="evenodd"
        d="M13.501 5.333c.645 0 1.167.523 1.167 1.167v3c0 .644-.522 1.167-1.167 1.167h-1.724c-.309 0-.606-.123-.824-.342L9.335 8.707a1 1 0 0 1 0-1.414l1.617-1.618c.22-.219.516-.342.825-.342zm.167 1.167a.167.167 0 0 0-.167-.167h-1.724a.17.17 0 0 0-.117.05L10.042 8l1.618 1.618a.17.17 0 0 0 .117.049h1.724a.167.167 0 0 0 .167-.167zM5.332 2.5c0-.644.522-1.167 1.167-1.167h3c.644 0 1.166.523 1.166 1.167v1.724c0 .31-.123.606-.341.825L8.706 6.667a1 1 0 0 1-1.414 0L5.674 5.049a1.17 1.17 0 0 1-.342-.825zm1.167-.167a.167.167 0 0 0-.167.167v1.724c0 .044.018.086.049.118L7.999 5.96l1.618-1.618a.17.17 0 0 0 .048-.118V2.5a.167.167 0 0 0-.166-.167zm-4 4a.167.167 0 0 0-.167.167v3c0 .092.075.167.167.167h1.724a.17.17 0 0 0 .117-.05L5.958 8 4.34 6.382a.17.17 0 0 0-.117-.049zM1.332 6.5c0-.644.522-1.167 1.167-1.167h1.724c.309 0 .606.123.825.342l1.617 1.618a1 1 0 0 1 0 1.414l-1.617 1.618a1.17 1.17 0 0 1-.825.342H2.499A1.167 1.167 0 0 1 1.332 9.5zm8.285 5.158-1.618-1.617-1.618 1.617a.17.17 0 0 0-.049.118V13.5c0 .092.075.167.167.167h3a.167.167 0 0 0 .166-.167v-1.724a.17.17 0 0 0-.048-.118M7.292 9.333a1 1 0 0 1 1.414 0l1.618 1.618c.218.219.341.516.341.825V13.5c0 .644-.522 1.167-1.166 1.167h-3A1.167 1.167 0 0 1 5.332 13.5v-1.724c0-.31.123-.606.342-.825z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRightGamepadBaseControllsGameJoystickOutline.displayName =
  'RightGamepadBaseControllsGameJoystickOutline';
const Memo = memo(SvgRightGamepadBaseControllsGameJoystickOutline);
export default Memo;
