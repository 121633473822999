// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWeb3CryptoSpaceOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.917 5.236c-1.234-2.212-3.841-3.39-6.383-2.71-2.541.682-4.21 3.006-4.172 5.538q.007.553.127 1.112.2-.009.422-.027c1.337-.11 3.157-.44 5.138-.97s3.722-1.155 4.934-1.73q.201-.094.38-.187a5.7 5.7 0 0 0-.446-1.026m.674 2.031-.18.087c-1.281.607-3.083 1.25-5.103 1.79-2.02.542-3.902.886-5.316 1.001l-.198.015c1.068 2.594 3.91 4.054 6.674 3.314s4.495-3.426 4.123-6.207M1.733 10.182c1.133 3.272 4.608 5.165 7.994 4.258 3.385-.907 5.448-4.284 4.793-7.684q.24-.15.427-.299a2.1 2.1 0 0 0 .464-.48c.113-.173.216-.425.14-.71a.84.84 0 0 0-.378-.488 1.6 1.6 0 0 0-.507-.196 5 5 0 0 0-1.029-.094C12.132 2.058 9.171.785 6.276 1.56c-2.896.776-4.824 3.358-4.912 6.216-.34.198-.63.398-.844.596a1.6 1.6 0 0 0-.34.423.84.84 0 0 0-.084.613c.076.284.292.45.477.544.187.095.411.15.64.184q.237.034.52.045m-.267-1.02a7 7 0 0 1-.038-.24 2.4 2.4 0 0 0-.238.194 2 2 0 0 0 .276.045m12.689-3.65q.044.112.086.226a3 3 0 0 0 .216-.177l-.013-.003a2 2 0 0 0-.29-.045"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWeb3CryptoSpaceOutline.displayName = 'Web3CryptoSpaceOutline';
const Memo = memo(SvgWeb3CryptoSpaceOutline);
export default Memo;
