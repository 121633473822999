// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRemoveRowDeleteRowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833V8a.5.5 0 0 1-.5.5H2.332v3c0 .46.373.833.833.833h4.167a.5.5 0 0 1 0 1H3.165A1.833 1.833 0 0 1 1.332 11.5zm1 3h11.333v-3a.833.833 0 0 0-.833-.833H3.165a.833.833 0 0 0-.833.833zm8.48 3.313a.5.5 0 0 1 .707 0l1.146 1.147 1.147-1.147a.5.5 0 1 1 .707.707l-1.146 1.147 1.146 1.146a.5.5 0 1 1-.707.707l-1.147-1.146-1.146 1.146a.5.5 0 1 1-.707-.707l1.146-1.146-1.146-1.147a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRemoveRowDeleteRowOutline.displayName = 'RemoveRowDeleteRowOutline';
const Memo = memo(SvgRemoveRowDeleteRowOutline);
export default Memo;
