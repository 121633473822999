// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLabFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.668 4.5a.5.5 0 0 1 .5-.5h5.667a.5.5 0 1 1 0 1h-.167v2.155q.24.285.528.602l.062.069c.215.239.443.492.663.755.48.572.956 1.228 1.22 1.965.12.337.194.693.194 1.065a3.056 3.056 0 0 1-3.056 3.056H5.724a3.056 3.056 0 0 1-3.056-3.056c0-.372.074-.728.194-1.065.263-.737.74-1.393 1.22-1.965.22-.263.448-.516.663-.755l.062-.069q.287-.316.528-.602V5h-.167a.5.5 0 0 1-.5-.5m1.667.5V7.51l-.11.137c-.206.258-.44.52-.675.78l-.06.068c-.218.24-.434.48-.642.73-.23.273-.44.546-.619.822l.066-.01c.596-.091 1.368-.183 1.993-.167.758.02 1.333.202 1.85.364l.013.004c.52.164.98.306 1.59.323.531.014 1.23-.066 1.816-.156q.213-.032.394-.064a7.3 7.3 0 0 0-.796-1.117 27 27 0 0 0-.642-.73l-.06-.067c-.234-.26-.47-.522-.675-.78l-.11-.137V5z"
        clipRule="evenodd"
      />
      <Path d="M7.335 2.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M10.001 1.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
    </Svg>
  );
};
SvgLabFill.displayName = 'LabFill';
const Memo = memo(SvgLabFill);
export default Memo;
