// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTarget2ZoomOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M8.001.667a.5.5 0 0 1 .5.5V2.69a5.335 5.335 0 0 1 4.81 4.81h1.524a.5.5 0 1 1 0 1H13.31a5.335 5.335 0 0 1-4.81 4.81v1.524a.5.5 0 0 1-1 0V13.31a5.335 5.335 0 0 1-4.81-4.81H1.168a.5.5 0 0 1 0-1h1.523a5.335 5.335 0 0 1 4.81-4.81V1.167a.5.5 0 0 1 .5-.5m-.5 3.029A4.335 4.335 0 0 0 3.696 7.5h1.805a.5.5 0 1 1 0 1H3.696a4.335 4.335 0 0 0 3.805 3.805V10.5a.5.5 0 0 1 1 0v1.805A4.335 4.335 0 0 0 12.306 8.5h-1.805a.5.5 0 0 1 0-1h1.805a4.335 4.335 0 0 0-3.805-3.804V5.5a.5.5 0 0 1-1 0z"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgTarget2ZoomOutline.displayName = 'Target2ZoomOutline';
const Memo = memo(SvgTarget2ZoomOutline);
export default Memo;
