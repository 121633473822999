// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBluetoothFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.638 2.568a1 1 0 0 1 1.64-.769l3.049 2.54-.28.335.28-.335a1 1 0 0 1-.007 1.543L8.73 8l2.59 2.118a1 1 0 0 1 .007 1.543L8.279 14.2a1 1 0 0 1-1.64-.769v-3.72l-2.795 2.286a.5.5 0 0 1-.633-.774L6.638 8.42v-.84L3.211 4.776a.5.5 0 0 1 .633-.774l2.794 2.286zm1.303 4.786-.303-.247v-4.54l3.049 2.54zm0 1.292-.303.248v4.538l3.049-2.54z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBluetoothFill.displayName = 'BluetoothFill';
const Memo = memo(SvgBluetoothFill);
export default Memo;
