// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHousingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.543 1.778a.445.445 0 1 0-.89 0v.34h-2.21a1.23 1.23 0 0 0-1.1.68l-.96 1.922a.445.445 0 0 0 .398.644h.34v7.746h-.34a.445.445 0 0 0 0 .89h11.777a.445.445 0 0 0 0-.89h-1.913v-.764a1.23 1.23 0 0 0 .785-1.146h-.445l.443-.041a8.2 8.2 0 0 0-.83-2.906.445.445 0 0 0-.796 0A8.2 8.2 0 0 0 9.97 11.2a1.23 1.23 0 0 0 .785 1.147v.763H8.51V5.364h.337a.445.445 0 0 0 .398-.644l-.96-1.922a1.23 1.23 0 0 0-1.101-.68h-.641zM4.082 13.11h-1.07V5.364H7.62v7.746H6.542v-1.125a1.23 1.23 0 1 0-2.46 0zm1.57-1.125v1.125h-.68v-1.125a.34.34 0 0 1 .68 0m2.475-7.511H2.5l.64-1.278a.34.34 0 0 1 .304-.188h3.74a.34.34 0 0 1 .305.188zM11.2 9.612q.26.782.34 1.604a.34.34 0 0 1-.68 0 7.3 7.3 0 0 1 .34-1.604m-7.063-.427a.445.445 0 1 0 0 .89h2.356a.445.445 0 0 0 0-.89zm-.445-1.126c0-.245.2-.445.445-.445h2.356a.445.445 0 0 1 0 .89H4.137a.445.445 0 0 1-.445-.445m.445-2.015a.445.445 0 1 0 0 .89h2.356a.445.445 0 0 0 0-.89z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHousingOutline.displayName = 'HousingOutline';
const Memo = memo(SvgHousingOutline);
export default Memo;
