// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGarage2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.015 2.14 1.877 6.056c-.234.176-.584.117-.701-.117a.554.554 0 0 1 .117-.76l6.43-4.091a.44.44 0 0 1 .526 0l6.43 4.091c.234.176.35.468.175.702-.058.175-.233.234-.409.234-.117 0-.234 0-.292-.059zm5.144 7.54c.467.06.935.293 1.286.644.41.467.643.993.76 1.578v.877c0 .409-.117.76-.41.994-.292.233-.642.409-.993.409h-.526a1.8 1.8 0 0 1-.293.409c-.233.292-.584.41-.935.41-.35 0-.701-.176-.935-.41a1.3 1.3 0 0 1-.292-.468H6.26c-.058.176-.175.351-.292.468-.234.292-.585.41-.936.41-.35 0-.701-.176-.935-.41-.117-.117-.234-.234-.292-.41H2.403c-.41 0-.76-.175-.994-.408C1.175 13.48 1 13.13 1 12.779v-.877c0-.585.234-1.17.643-1.578.41-.41.994-.643 1.578-.643h.994L5.15 7.81c.117-.175.293-.41.526-.526.176-.175.468-.234.702-.234h4.618c.234 0 .468.117.701.234q.351.175.527.526zM6.086 8.19a.3.3 0 0 1-.059.088L5.326 9.68h6.605l-.701-1.403c0-.059-.059-.117-.117-.176s-.117-.058-.175-.058H6.32c-.059 0-.117 0-.176.058-.03.03-.044.059-.058.088m-1.462 5.816a.46.46 0 0 0 .643 0c.059-.117.117-.233.117-.35a.45.45 0 0 0-.117-.293c-.058-.058-.117-.117-.292-.117s-.234.059-.35.176c-.06.058-.118.117-.118.175.059 0 .059.117.059.117s-.059.059-.059.117c.03.03.044.059.059.088s.029.058.058.087m6.898-.292c0 .117.059.234.117.292.175.176.468.176.585 0 .058-.058.117-.117.117-.175v-.234a.3.3 0 0 1-.059-.088c-.015-.029-.03-.058-.058-.087a.45.45 0 0 0-.293-.117.45.45 0 0 0-.292.117.45.45 0 0 0-.117.292m2.501-.727c.04-.068.071-.123.071-.208h-.058v-.877q0-.526-.351-.877a1.2 1.2 0 0 0-.877-.35H3.104c-.292 0-.643.116-.876.35a1.2 1.2 0 0 0-.351.877v.877c0 .117.058.234.117.292q.046.024.083.046c.07.04.123.071.209.071h1.461c.059-.117.176-.292.293-.41.526-.467 1.403-.467 1.87 0 .117.118.234.293.293.468h4.5c.06-.175.176-.35.293-.467.526-.468 1.403-.468 1.87 0 .117.117.234.234.293.409h.526c.117 0 .234 0 .292-.117z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGarage2Outline.displayName = 'Garage2Outline';
const Memo = memo(SvgGarage2Outline);
export default Memo;
