// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierCurvesAnimationMotionSpringOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3zM8.76 5.734c.494-.599 1.145-1.067 2.038-1.067h.035a.5.5 0 1 1 0 1h-.035c-.49 0-.884.24-1.267.704-.394.478-.723 1.13-1.084 1.853l-.015.03c-.342.686-.717 1.437-1.192 2.012-.494.599-1.145 1.067-2.038 1.067h-.035a.5.5 0 1 1 0-1h.035c.49 0 .884-.24 1.267-.703.394-.479.723-1.13 1.084-1.853l.015-.03c.342-.687.717-1.437 1.192-2.013"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBezierCurvesAnimationMotionSpringOutline.displayName =
  'BezierCurvesAnimationMotionSpringOutline';
const Memo = memo(SvgBezierCurvesAnimationMotionSpringOutline);
export default Memo;
