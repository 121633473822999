// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDualTelcomsRisersOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.864 1.333a.42.42 0 0 0-.354.198l-.767 1.246H8.03a.417.417 0 0 0 0 .834h1.945c.144 0 .279-.075.354-.199l.767-1.246h3.045a.417.417 0 0 0 0-.833zm-8.084.528c0-.23.187-.417.417-.417h1.778c.145 0 .28.076.355.2l1.39 2.278a.417.417 0 0 1-.712.434L4.74 2.278H3.197a.417.417 0 0 1-.417-.417m7.463 10.181a.417.417 0 0 0-.646.526l1.223 1.5c.079.098.197.154.322.154h2.167a.417.417 0 1 0 0-.833h-1.968zM7.77 13.044a.417.417 0 1 0-.59-.589l-1.378 1.378h-4.05a.417.417 0 1 0 0 .833h4.223c.11 0 .216-.043.294-.122zm2.676-8.461c0-.23.186-.417.417-.417h3.277a.417.417 0 1 1 0 .834h-3.277a.417.417 0 0 1-.417-.417m-8.693 7.25a.417.417 0 0 0 0 .833h3a.417.417 0 0 0 0-.833zm-.417-7.75c0-.23.186-.417.417-.417h2.389a.417.417 0 0 1 0 .834h-2.39a.417.417 0 0 1-.416-.417m10.582 6.806a.417.417 0 0 0 0 .833h2.334a.417.417 0 1 0 0-.833zM9.6 6.749a.556.556 0 0 0-.556.556V9.25c0 .307.25.555.556.555h.648a.556.556 0 0 0 .556-.555v-.232h-.556a.417.417 0 0 1 0-.833h.972c.23 0 .417.186.417.417v.648c0 .767-.622 1.389-1.389 1.389H9.6a1.39 1.39 0 0 1-1.389-1.39V7.306c0-.767.622-1.389 1.389-1.389h.648c.605 0 1.12.387 1.31.926a.417.417 0 0 1-.786.278.56.56 0 0 0-.524-.37zm-4.607-.833a.42.42 0 0 0-.41.349l-.325 1.944a.417.417 0 0 0 .598.441l.237-.119c.212-.106.446-.16.683-.16h.514c.307 0 .555.248.555.555v.324a.556.556 0 0 1-.555.555h-.648a.56.56 0 0 1-.525-.37.417.417 0 0 0-.785.278c.19.539.704.926 1.31.926h.648c.767 0 1.389-.622 1.389-1.39v-.323c0-.767-.622-1.39-1.39-1.39h-.513q-.291.001-.573.071l.143-.857H7.1a.417.417 0 1 0 0-.834z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDualTelcomsRisersOutline.displayName = 'DualTelcomsRisersOutline';
const Memo = memo(SvgDualTelcomsRisersOutline);
export default Memo;
