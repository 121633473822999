// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRunRunningFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.817 1c-1.215 0-2.2.993-2.2 2.218A2.21 2.21 0 0 0 5.164 5.41a5 5 0 0 0-.27.235l-.116.106c-.224.206-.51.47-.932.809l-.911-.46c-.656-.369-1.442-.063-1.777.6-.369.665-.06 1.464.604 1.799l1.047.528.035.016.022.008c.28.113.64.259 1.077.259.51 0 1.002-.17 1.432-.44l.108.389-.637.214-.04.016c-.622.279-1.131.779-1.352 1.447-.2.607-.216 1.315.072 1.913l.28.735c.2.588.767.83 1.242.83h.017c.106 0 .306.001.512-.096.734-.29.952-1.108.711-1.755l-.008-.02-.317-.745.572-.213 1.61 2.778.004.005c.274.46.732.633 1.145.633.227 0 .42-.072.544-.117l.065-.024a.5.5 0 0 0 .082-.037 1.325 1.325 0 0 0 .455-1.867l-1.324-2.28.033-.588q.196.024.403-.023c.697-.104 1.234-.803 1.06-1.544l-.171-.918a2.505 2.505 0 0 0-1.988-2.076H8.45c-.41-.08-.751-.152-1.038-.213l-.133-.029q-.34-.073-.59-.122a3.3 3.3 0 0 0-.68-.079 2.22 2.22 0 0 0 1.007-1.865A2.21 2.21 0 0 0 4.816 1m6.378 4.022c0-.253.204-.458.455-.458h2.894c.251 0 .454.205.454.458a.456.456 0 0 1-.454.459H11.65a.457.457 0 0 1-.455-.459m.455 2.46a.457.457 0 0 0-.455.46c0 .253.204.458.455.458h2.026c.25 0 .454-.205.454-.459a.457.457 0 0 0-.454-.458zm-.455 3.379c0-.253.204-.459.455-.459h.868c.252 0 .455.206.455.459a.457.457 0 0 1-.455.459h-.868a.457.457 0 0 1-.455-.459"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRunRunningFill.displayName = 'RunRunningFill';
const Memo = memo(SvgRunRunningFill);
export default Memo;
