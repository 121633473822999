// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMapOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.45 3.225c.142.214.22.468.22.727h-.006v5.702c0 .056-.011.1-.027.15q.004.066.013.132.012.086.014.176c0 1.107-1.223 2.903-2.253 4.22a.9.9 0 0 1-.309.248.9.9 0 0 1-.777 0 .9.9 0 0 1-.303-.248c-.634-.815-1.344-1.812-1.796-2.722L6.42 12.795a1.303 1.303 0 0 1-.97 0l-3.305-1.322a1.27 1.27 0 0 1-.59-.474 1.3 1.3 0 0 1-.22-.728V2.355c0-.143.033-.287.1-.408a.87.87 0 0 1 .682-.468.86.86 0 0 1 .425.055L5.804 2.84a.4.4 0 0 0 .133.027.4.4 0 0 0 .132-.027l3.52-1.41c.31-.127.65-.127.96 0l3.31 1.316c.238.1.447.265.59.48m-.778.528a.4.4 0 0 0-.165-.132l-2.97-1.19v4.98c.37-.164.772-.252 1.185-.252a2.93 2.93 0 0 1 2.01.804V3.952a.37.37 0 0 0-.06-.199M2.333 10.47q.067.09.165.132l2.97 1.19V3.71h-.01L2.272 2.431v7.84q.002.108.06.199m4.072-6.76v8.071l2.48-1.047c-.067-.22-.11-.44-.11-.628 0-.766.297-1.487.826-2.038V2.43L6.416 3.71zm3.306 6.396c0 .623.771 1.995 2.016 3.593 1.229-1.587 2.006-2.97 2.006-3.593 0-.534-.21-1.041-.59-1.421s-.887-.59-1.421-.59a2 2 0 0 0-1.334.515l-.016.014q-.017.016-.036.03l-.036.03c-.38.38-.59.882-.59 1.422m2.68.006a.672.672 0 1 1-1.344 0 .672.672 0 0 1 1.344 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMapOutline.displayName = 'MapOutline';
const Memo = memo(SvgMapOutline);
export default Memo;
