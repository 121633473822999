// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMegaphoneLoudSpeakPromoteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.291 1.889a1.833 1.833 0 0 1 2.374 1.751v1.243a2.5 2.5 0 0 1 0 4.9v1.243a1.833 1.833 0 0 1-2.374 1.752l-1.435-.443a2.5 2.5 0 0 1-4.857-.835v-.665l-1.374-.424a1.83 1.83 0 0 1-1.293-1.752V6.008c0-.805.524-1.515 1.293-1.752l1.57-.485a.5.5 0 0 1 .335-.103zm-6.29 2.989L2.92 5.21a.83.83 0 0 0-.588.797v2.651c0 .366.238.689.588.796L4 9.79zm1 5.22V4.569l5.585-1.725a.833.833 0 0 1 1.08.797v7.385a.833.833 0 0 1-1.08.797zm-.002 1.046 2.9.895a1.5 1.5 0 0 1-2.9-.539zm7.666-2.396a1.5 1.5 0 0 0 0-2.83z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMegaphoneLoudSpeakPromoteOutline.displayName = 'MegaphoneLoudSpeakPromoteOutline';
const Memo = memo(SvgMegaphoneLoudSpeakPromoteOutline);
export default Memo;
