// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBug1IssueOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.408 2.172c.288-.398.797-.839 1.592-.839 1.226 0 1.976.972 2.43 1.907l.119.26a6.3 6.3 0 0 1 2.902 0q.059-.13.12-.26c.454-.935 1.204-1.907 2.43-1.907.794 0 1.303.44 1.591.839a2.7 2.7 0 0 1 .384.77l.005.017.002.007v.003l-.483.128.484-.127a.5.5 0 0 1-.967.258v-.004l-.008-.025a1.675 1.675 0 0 0-.226-.44c-.17-.234-.41-.426-.783-.426-.608 0-1.108.474-1.53 1.343l-.061.133C12.513 4.682 14 6.663 14 9c0 3.159-2.716 5.667-6 5.667S2 12.159 2 9c0-2.337 1.488-4.318 3.592-5.19l-.063-.134C5.108 2.806 4.608 2.333 4 2.333c-.372 0-.613.192-.783.426a1.7 1.7 0 0 0-.233.465l-.001.004a.5.5 0 0 1-.966-.258l.483.127-.483-.128v-.003l.002-.007.006-.017.017-.056q.022-.068.067-.179c.06-.144.156-.338.299-.535m3.536 2.572C4.198 5.48 3 7.124 3 9c0 2.548 2.209 4.667 5 4.667S13 11.548 13 9c0-1.877-1.198-3.52-2.944-4.256-.174.55-.3 1.096-.402 1.535a.5.5 0 1 1-.974-.225l.004-.018c.098-.425.23-.996.418-1.59a5.4 5.4 0 0 0-2.203 0c.188.594.32 1.165.417 1.59l.005.018a.5.5 0 1 1-.975.225c-.101-.44-.228-.986-.402-1.535"
        clipRule="evenodd"
      />
      <Path d="M7 9.167c0 .644-.448 1.166-1 1.166s-1-.522-1-1.166C5 8.522 5.448 8 6 8s1 .522 1 1.167M9 11.333c0 .368-.448.667-1 .667s-1-.299-1-.667.448-.666 1-.666 1 .298 1 .666M11 9.167c0 .644-.448 1.166-1 1.166s-1-.522-1-1.166C9 8.522 9.448 8 10 8s1 .522 1 1.167" />
    </Svg>
  );
};
SvgBug1IssueOutline.displayName = 'Bug1IssueOutline';
const Memo = memo(SvgBug1IssueOutline);
export default Memo;
