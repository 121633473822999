// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFoldersFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.168 2c-1.013 0-1.833.82-1.833 1.833v.834H2.5C1.49 4.667.668 5.487.668 6.5v5.667c0 1.012.82 1.833 1.833 1.833h8.334c1.012 0 1.833-.82 1.833-1.833v-.834h.833c1.013 0 1.834-.82 1.834-1.833V5.167c0-1.013-.821-1.834-1.834-1.834H9.887a.83.83 0 0 1-.59-.244l-.552-.552A1.83 1.83 0 0 0 7.45 2zm7.5 8.333h.833c.46 0 .834-.373.834-.833V5.167a.833.833 0 0 0-.834-.834H9.887c-.486 0-.953-.193-1.296-.537l-.553-.552A.83.83 0 0 0 7.45 3H5.168a.833.833 0 0 0-.833.833v.834h.447c.487 0 .953.193 1.297.537l.552.552a.83.83 0 0 0 .59.244h3.614c1.012 0 1.833.82 1.833 1.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFoldersFill.displayName = 'FoldersFill';
const Memo = memo(SvgFoldersFill);
export default Memo;
