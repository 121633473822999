// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSaunaOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.762 2.899a.45.45 0 0 1 .476 0l4.076 2.518v7.75H3.378v-7.56zm-5.278 3.26-.79.488a.467.467 0 0 1-.62-.117.4.4 0 0 1 .126-.577l6.068-3.748C7.485 2.071 7.74 2 8 2s.515.071.732.205L14.8 5.953a.4.4 0 0 1 .125.577.467.467 0 0 1-.619.117l-1.098-.678v7.615c0 .23-.2.416-.447.416H2.93c-.247 0-.447-.186-.447-.416zm7.484.425a.397.397 0 0 1 .066.585 1.6 1.6 0 0 0-.39.935c.002.294.1.6.225.979l.009.026c.116.353.256.774.256 1.208v.023a2.4 2.4 0 0 1-.59 1.425.47.47 0 0 1-.628.062.397.397 0 0 1-.067-.585c.231-.267.368-.594.391-.937-.002-.293-.1-.599-.225-.977l-.008-.026c-.117-.353-.257-.775-.257-1.21V8.07c.032-.52.238-1.02.59-1.425a.47.47 0 0 1 .628-.061m-3.066.585a.397.397 0 0 0-.067-.585.47.47 0 0 0-.628.061 2.4 2.4 0 0 0-.59 1.448c0 .434.14.856.257 1.21l.008.025c.125.378.223.684.225.977-.023.343-.16.67-.39.937a.397.397 0 0 0 .066.585.47.47 0 0 0 .629-.062 2.4 2.4 0 0 0 .59-1.448c0-.434-.14-.855-.257-1.208l-.009-.026c-.125-.379-.223-.685-.225-.979.023-.342.16-.669.39-.935"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSaunaOutline.displayName = 'SaunaOutline';
const Memo = memo(SvgSaunaOutline);
export default Memo;
