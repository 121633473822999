// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettingsSliderVerSliderTwoFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.833 8.713a2.667 2.667 0 1 1-1 0V2.5a.5.5 0 0 1 1 0zM4.667 14a.5.5 0 0 1-.5-.5V8.62a2.667 2.667 0 0 1 0-5.24V2.5a.5.5 0 1 1 1 0v.88a2.667 2.667 0 0 1 0 5.24v4.88a.5.5 0 0 1-.5.5" />
    </Svg>
  );
};
SvgSettingsSliderVerSliderTwoFill.displayName = 'SettingsSliderVerSliderTwoFill';
const Memo = memo(SvgSettingsSliderVerSliderTwoFill);
export default Memo;
