// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrending4ChartFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.832 2.667a.5.5 0 0 1 .5.5V11.5c0 .46.373.833.833.833h11a.5.5 0 0 1 0 1h-11A1.833 1.833 0 0 1 1.332 11.5V3.167a.5.5 0 0 1 .5-.5m7.5 1.833a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0V5.707L9.686 8.354a1.443 1.443 0 0 1-2.04 0 .443.443 0 0 0-.627 0L4.852 10.52a.5.5 0 0 1-.707-.707l2.167-2.167a1.443 1.443 0 0 1 2.04 0 .443.443 0 0 0 .626 0L11.625 5H9.832a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrending4ChartFill.displayName = 'Trending4ChartFill';
const Memo = memo(SvgTrending4ChartFill);
export default Memo;
