// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAi2Stars180SparklesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M6.001.667a.5.5 0 0 1 .5.5c0 1.617.358 2.667 1.012 3.321S9.217 5.5 10.835 5.5a.5.5 0 1 1 0 1c-1.618 0-2.668.357-3.322 1.012-.654.654-1.012 1.704-1.012 3.321a.5.5 0 1 1-1 0c0-1.617-.357-2.667-1.011-3.321C3.835 6.857 2.785 6.5 1.168 6.5a.5.5 0 0 1 0-1c1.617 0 2.667-.357 3.322-1.012C5.144 3.834 5.5 2.784 5.5 1.167a.5.5 0 0 1 .5-.5m0 3.339a3.9 3.9 0 0 1-.804 1.19A3.9 3.9 0 0 1 4.007 6q.682.299 1.19.804.506.509.804 1.19.298-.681.805-1.19A3.9 3.9 0 0 1 7.996 6a3.9 3.9 0 0 1-1.19-.804A3.9 3.9 0 0 1 6 4.006M11.668 8a.5.5 0 0 1 .5.5c0 1.038.23 1.672.612 2.054.383.383 1.016.613 2.055.613a.5.5 0 1 1 0 1c-1.039 0-1.673.23-2.055.612s-.612 1.016-.612 2.054a.5.5 0 1 1-1 0c0-1.038-.23-1.672-.612-2.054-.383-.382-1.016-.612-2.055-.612a.5.5 0 0 1 0-1c1.039 0 1.672-.23 2.055-.613.382-.382.612-1.016.612-2.054a.5.5 0 0 1 .5-.5m0 2.737a2.6 2.6 0 0 1-.93.93 2.6 2.6 0 0 1 .93.929 2.6 2.6 0 0 1 .93-.93 2.6 2.6 0 0 1-.93-.929"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgAi2Stars180SparklesOutline.displayName = 'Ai2Stars180SparklesOutline';
const Memo = memo(SvgAi2Stars180SparklesOutline);
export default Memo;
