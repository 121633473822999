// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgOptOptionKeyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.167a.5.5 0 0 1 .5-.5h2.046c.668 0 1.283.363 1.605.947l4.573 8.289c.147.265.426.43.73.43H13.5a.5.5 0 0 1 0 1h-2.046a1.83 1.83 0 0 1-1.605-.947L5.276 4.097a.83.83 0 0 0-.73-.43H2.5a.5.5 0 0 1-.5-.5m8 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgOptOptionKeyFill.displayName = 'OptOptionKeyFill';
const Memo = memo(SvgOptOptionKeyFill);
export default Memo;
