// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPizzaOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.558 2.326a1.833 1.833 0 0 1 2.117 2.116l-1.453 8.477c-.17.99-1.143 1.72-2.18 1.406a12.5 12.5 0 0 1-5.226-3.14 12.5 12.5 0 0 1-3.14-5.227c-.315-1.036.415-2.01 1.406-2.18zm1.131 1.947a.833.833 0 0 0-.962-.962l-.531.091a1.875 1.875 0 0 1-1.813 2.343c-.98 0-1.784-.753-1.866-1.712l-2.7.463a9.1 9.1 0 0 0 2.415 4.272q.476.475.998.866a2.56 2.56 0 0 1 3.845-1.778zm-.805 4.7a1.559 1.559 0 0 0-2.67 1.302c.726.41 1.498.713 2.29.91zm-.549 3.2a10.1 10.1 0 0 1-4.81-2.698 10.1 10.1 0 0 1-2.697-4.81l-.577.1c-.475.08-.736.515-.619.902a11.5 11.5 0 0 0 2.891 4.81 11.5 11.5 0 0 0 4.81 2.891c.388.118.822-.143.903-.618zm-2.824-8.31v.01a.873.873 0 1 0 1.692-.3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPizzaOutline.displayName = 'PizzaOutline';
const Memo = memo(SvgPizzaOutline);
export default Memo;
