// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowTopLeftOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.832 10.67a.5.5 0 0 1-.5-.5V5.167c0-1.013.82-1.834 1.833-1.834h5.664a.5.5 0 1 1 0 1H5.165a1 1 0 0 0-.118.009l6.972 6.971a.5.5 0 1 1-.707.707L4.34 5.05a1 1 0 0 0-.008.118v5.003a.5.5 0 0 1-.5.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowTopLeftOutline.displayName = 'ArrowTopLeftOutline';
const Memo = memo(SvgArrowTopLeftOutline);
export default Memo;
