// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgItalicOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.997 2.5a.5.5 0 0 1 .5-.5h6.334a.5.5 0 1 1 0 1h-2.796l-3.03 10h2.5a.5.5 0 0 1 0 1h-6.34a.5.5 0 0 1 0-1H5.96L8.99 3H6.497a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgItalicOutline.displayName = 'ItalicOutline';
const Memo = memo(SvgItalicOutline);
export default Memo;
