// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronLeftSmallFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.412 4.98a.5.5 0 0 1 0 .707L7.217 7.882a.167.167 0 0 0 0 .236l2.195 2.195a.5.5 0 1 1-.707.707L6.51 8.825a1.167 1.167 0 0 1 0-1.65L8.705 4.98a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronLeftSmallFill.displayName = 'ChevronLeftSmallFill';
const Memo = memo(SvgChevronLeftSmallFill);
export default Memo;
