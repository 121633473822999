// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChart4StatisticsGraph2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.333 3.167C9.333 2.522 9.856 2 10.5 2h2.333C13.478 2 14 2.522 14 3.167v10c0 .46-.373.833-.833.833h-10A1.167 1.167 0 0 1 2 12.833V10.5c0-.644.522-1.167 1.167-1.167h2.5v-2.5c0-.644.522-1.166 1.166-1.166h2.5zm1 9.833H13V3.167A.167.167 0 0 0 12.833 3H10.5a.167.167 0 0 0-.167.167zm-1 0V6.667h-2.5a.167.167 0 0 0-.166.166V13zm-3.666 0v-2.667h-2.5A.167.167 0 0 0 3 10.5v2.333c0 .092.075.167.167.167z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChart4StatisticsGraph2Outline.displayName = 'Chart4StatisticsGraph2Outline';
const Memo = memo(SvgChart4StatisticsGraph2Outline);
export default Memo;
