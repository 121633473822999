// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBurgerHamburgerCheeseburgerSandwichOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.602 5.667h10.794c-.219-.514-.782-.965-1.663-1.325-1.118-.457-2.55-.675-3.734-.675s-2.616.218-3.735.675c-.88.36-1.444.811-1.662 1.325m1.284-2.25c1.265-.518 2.833-.75 4.113-.75s2.847.232 4.113.75c1.228.501 2.387 1.371 2.387 2.75a.5.5 0 0 1-.5.5h-12a.5.5 0 0 1-.5-.5c0-1.379 1.159-2.249 2.387-2.75m.92 5.295a2.1 2.1 0 0 0-1.615 0c-.403.168-.982.238-1.359.238a.5.5 0 1 1 0-1c.303 0 .738-.063.974-.162a3.1 3.1 0 0 1 2.385 0 2.1 2.1 0 0 0 1.615 0 3.1 3.1 0 0 1 2.385 0 2.1 2.1 0 0 0 1.615 0 3.1 3.1 0 0 1 2.385 0c.237.1.67.162.974.162a.5.5 0 0 1 0 1c-.376 0-.955-.07-1.359-.238a2.1 2.1 0 0 0-1.615 0 3.1 3.1 0 0 1-2.385 0 2.1 2.1 0 0 0-1.615 0 3.1 3.1 0 0 1-2.385 0M1.332 10.5a.5.5 0 0 1 .5-.5h12.333a.5.5 0 0 1 .5.5v.233c0 .35 0 .643-.019.881-.02.25-.063.484-.174.705a1.83 1.83 0 0 1-.82.821c-.222.11-.456.154-.705.174-.24.02-.532.02-.882.02H3.932c-.35 0-.642 0-.881-.02a1.9 1.9 0 0 1-.705-.174 1.83 1.83 0 0 1-.82-.82 1.9 1.9 0 0 1-.175-.706c-.019-.238-.019-.53-.019-.88zm1 .5c.001.225.005.395.016.534.015.189.042.279.072.338.08.162.211.293.373.373.06.03.15.057.338.072.194.016.446.016.821.016h8.093c.376 0 .627 0 .821-.016.189-.015.279-.042.338-.072a.83.83 0 0 0 .373-.372c.03-.06.057-.15.072-.339.012-.14.015-.31.016-.534z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBurgerHamburgerCheeseburgerSandwichOutline.displayName =
  'BurgerHamburgerCheeseburgerSandwichOutline';
const Memo = memo(SvgBurgerHamburgerCheeseburgerSandwichOutline);
export default Memo;
