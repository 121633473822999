// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChecklistBoxCheckListSearchFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 2C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h5.223l-.03-.03A3.5 3.5 0 1 1 14 9.046V3.833C14 2.821 13.18 2 12.167 2zm3.493 2.768a.5.5 0 0 1 .1.7L6.18 7.132a.5.5 0 0 1-.678.116l-.75-.5a.5.5 0 1 1 .555-.832l.356.237.964-1.285a.5.5 0 0 1 .7-.1M8.706 6a.5.5 0 0 1 .5-.5h1.666a.5.5 0 0 1 0 1H9.205a.5.5 0 0 1-.5-.5m-1.38 2.769a.5.5 0 0 1 .1.7L6.18 11.133a.5.5 0 0 1-.678.116l-.75-.5a.5.5 0 1 1 .555-.832l.356.237.964-1.285a.5.5 0 0 1 .7-.1"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M12.56 10.434a1.5 1.5 0 1 0-2.12 2.122 1.5 1.5 0 0 0 2.12-2.122m-2.828-.707a2.5 2.5 0 0 1 3.854 3.147l.934.934a.5.5 0 1 1-.707.707l-.934-.934a2.501 2.501 0 0 1-3.147-3.854"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChecklistBoxCheckListSearchFill.displayName = 'ChecklistBoxCheckListSearchFill';
const Memo = memo(SvgChecklistBoxCheckListSearchFill);
export default Memo;
