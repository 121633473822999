// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgConstructionWorkOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.67 2.175a.42.42 0 0 0-.418.421c0 .233.187.421.417.421s.417-.188.417-.42a.42.42 0 0 0-.417-.422m-1.25.421c0-.697.559-1.263 1.25-1.263.69 0 1.249.566 1.249 1.263 0 .49-.275.913-.677 1.123h5.515a1.26 1.26 0 0 1-.676-1.123c0-.697.56-1.263 1.25-1.263s1.25.566 1.25 1.263c0 .49-.275.913-.677 1.123h1.791c.537 0 .972.44.972.983v1.09a.4.4 0 0 1 0 .065v3.617a.977.977 0 0 1-.972.982h-.693v3.79a.42.42 0 0 1-.416.42.42.42 0 0 1-.417-.42v-3.79H3.836v3.79a.42.42 0 0 1-.417.42.42.42 0 0 1-.416-.42v-3.79h-.695a.977.977 0 0 1-.972-.982V4.7c0-.542.435-.982.972-.982h1.788a1.26 1.26 0 0 1-.677-1.123m7.672 7.018h2.603a.14.14 0 0 0 .14-.14V6.842zm2.742-3.963-3.92 3.963H7.757l5-5.053h.937a.14.14 0 0 1 .14.14zm-4.41-1.09h2.155L6.58 9.614H4.425zm-1.178 0H6.09L2.17 8.525v.949c0 .077.062.14.139.14h.939zm-5.938 0h2.605L2.169 7.334V4.702a.14.14 0 0 1 .139-.14m8.606-1.965c0-.232.187-.42.417-.42s.416.188.416.42a.42.42 0 0 1-.416.421.42.42 0 0 1-.417-.42"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgConstructionWorkOff.displayName = 'ConstructionWorkOff';
const Memo = memo(SvgConstructionWorkOff);
export default Memo;
