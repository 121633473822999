// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAtFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333A5.667 5.667 0 1 0 10.98 12.82a.5.5 0 1 1 .527.85A6.667 6.667 0 1 1 14.666 8c0 .73-.18 1.515-.616 2.113-.453.624-1.168 1.015-2.116.929a2.7 2.7 0 0 1-2.023-1.219c-.6.903-1.604 1.463-2.705 1.308-1.61-.226-2.6-1.862-2.365-3.53S6.478 4.633 8.087 4.86c.71.1 1.3.474 1.717 1.004l.048-.334a.5.5 0 0 1 .99.144l-.353 2.43a1.71 1.71 0 0 0 1.536 1.942c.582.053.96-.169 1.217-.521.275-.379.423-.936.423-1.525A5.667 5.667 0 0 0 8 2.333M7.948 5.85c-.943-.132-1.942.641-2.117 1.89-.176 1.25.571 2.269 1.514 2.401.942.133 1.941-.641 2.117-1.89C9.637 7 8.89 5.982 7.948 5.85"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAtFill.displayName = 'AtFill';
const Memo = memo(SvgAtFill);
export default Memo;
