// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRemovePeopleRemoveUserRemovePersonOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 2.333a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3 5a5 5 0 0 0-.25.006c-1.914.094-3.352 1.266-3.96 2.91a.48.48 0 0 0 .09.494.77.77 0 0 0 .584.257h3.703a.5.5 0 1 1 0 1H4.465a1.76 1.76 0 0 1-1.351-.615 1.48 1.48 0 0 1-.26-1.483c.76-2.054 2.61-3.526 5.04-3.568H8q.566 0 1.093.1a.5.5 0 1 1-.186.983 5 5 0 0 0-.907-.084m2.313.98a.5.5 0 0 1 .707 0l.98.98.98-.98a.5.5 0 0 1 .707.707l-.98.98.98.98a.5.5 0 1 1-.707.707l-.98-.98-.98.98a.5.5 0 0 1-.707-.707l.98-.98-.98-.98a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRemovePeopleRemoveUserRemovePersonOutline.displayName =
  'RemovePeopleRemoveUserRemovePersonOutline';
const Memo = memo(SvgRemovePeopleRemoveUserRemovePersonOutline);
export default Memo;
