// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCursor3ArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.435 1.923c-.948-.37-1.883.564-1.512 1.512l3.573 9.131a1.167 1.167 0 0 0 1.912.4l.925-.926 2.411 2.411a.833.833 0 0 0 1.179 0l2.528-2.528a.833.833 0 0 0 0-1.179l-2.41-2.41.925-.926a1.167 1.167 0 0 0-.4-1.912z" />
    </Svg>
  );
};
SvgCursor3ArrowFill.displayName = 'Cursor3ArrowFill';
const Memo = memo(SvgCursor3ArrowFill);
export default Memo;
