// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPlayCircleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334m-.813-8.812a.333.333 0 0 0-.52.276v3.738c0 .268.3.427.52.276l2.742-1.87a.333.333 0 0 0 0-.55z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPlayCircleFill.displayName = 'PlayCircleFill';
const Memo = memo(SvgPlayCircleFill);
export default Memo;
