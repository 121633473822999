// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCatImageAnimalCuteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.326 9.66c.122.456-.087.909-.467 1.01-.38.102-.788-.185-.91-.641s.087-.909.467-1.01c.38-.102.787.185.91.641M11.366 11.769c.457-.122.76-.468.679-.772-.082-.304-.518-.452-.974-.33-.456.123-.76.469-.678.773s.517.451.973.329" />
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zm7.66 2.271a6.3 6.3 0 0 0-1.559.648l-2.274-.528a.333.333 0 0 0-.404.384l.483 2.672a3.2 3.2 0 0 0-.082 1.892c.2.749.668 1.371 1.31 1.828h5.033c.46 0 .834-.373.834-.833V9.56l-.013.004c-.38.102-.787-.186-.91-.642-.122-.456.087-.908.468-1.01a.64.64 0 0 1 .455.053V4.661a.167.167 0 0 0-.283-.12l-1.383 1.345a6.3 6.3 0 0 0-1.674.218"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCatImageAnimalCuteFill.displayName = 'CatImageAnimalCuteFill';
const Memo = memo(SvgCatImageAnimalCuteFill);
export default Memo;
