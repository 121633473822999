// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPetrolGasOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.308 2.167a.14.14 0 0 0-.139.139v11.389c0 .076.062.138.14.138H9.53a.14.14 0 0 0 .14-.139V2.307a.14.14 0 0 0-.14-.14zm-.972.139c0-.537.435-.973.972-.973H9.53c.537 0 .973.436.973.973v11.389a.97.97 0 0 1-.973.972H2.308a.97.97 0 0 1-.972-.973z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M3.366 3.366A1.25 1.25 0 0 1 4.25 3h3.333a1.25 1.25 0 0 1 0 2.5H4.25a1.25 1.25 0 0 1-.884-2.134m.884.467a.417.417 0 1 0 0 .834h3.333a.417.417 0 1 0 0-.834zM1.336 6.75c0-.23.186-.417.417-.417h8.333a.417.417 0 1 1 0 .834H1.753a.417.417 0 0 1-.417-.417"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M9.672 4.25c0-.23.186-.417.416-.417h.834a1.25 1.25 0 0 1 1.25 1.25v8.334a.417.417 0 1 0 .833 0v-5c0-.626.185-1.238.532-1.76l.372-.555a.417.417 0 0 1 .693.463l-.371.555a2.34 2.34 0 0 0-.393 1.297v5a1.25 1.25 0 0 1-2.5 0V5.083a.417.417 0 0 0-.416-.416h-.834a.417.417 0 0 1-.416-.417"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M13 8.417c0-.23.187-.417.417-.417h.833a.417.417 0 0 1 0 .833h-.833A.417.417 0 0 1 13 8.417"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPetrolGasOff.displayName = 'PetrolGasOff';
const Memo = memo(SvgPetrolGasOff);
export default Memo;
