// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicStickFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.312 2.313a1.679 1.679 0 0 1 2.374 2.374l-9 9a1.679 1.679 0 0 1-2.374-2.374zm1.667.707a.68.68 0 0 0-.96 0l-1.813 1.813.96.96 1.813-1.813a.68.68 0 0 0 0-.96"
        clipRule="evenodd"
      />
      <Path d="M6.566 1.383a.33.33 0 0 0 .149-.15l.318-.637a.333.333 0 0 1 .597 0l.319.638a.33.33 0 0 0 .149.149l.637.319a.333.333 0 0 1 0 .596l-.637.319a.33.33 0 0 0-.15.149l-.318.638a.333.333 0 0 1-.597 0l-.318-.638a.33.33 0 0 0-.15-.15l-.637-.318a.333.333 0 0 1 0-.596zM12.566 8.716a.33.33 0 0 0 .149-.149l.319-.637a.333.333 0 0 1 .596 0l.319.637a.33.33 0 0 0 .149.15l.637.318a.333.333 0 0 1 0 .596l-.637.32a.33.33 0 0 0-.15.148l-.318.638a.333.333 0 0 1-.597 0l-.318-.638a.33.33 0 0 0-.15-.149l-.637-.319a.333.333 0 0 1 0-.596zM3.232 4.716a.33.33 0 0 0 .15-.149L3.7 3.93a.333.333 0 0 1 .596 0l.32.637a.33.33 0 0 0 .148.15l.638.318a.333.333 0 0 1 0 .596l-.638.32a.33.33 0 0 0-.149.148l-.319.638a.333.333 0 0 1-.596 0l-.319-.638a.33.33 0 0 0-.149-.149l-.637-.319a.333.333 0 0 1 0-.596z" />
    </Svg>
  );
};
SvgMagicStickFill.displayName = 'MagicStickFill';
const Memo = memo(SvgMagicStickFill);
export default Memo;
