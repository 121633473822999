// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRadarLocationSearchFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.999 1.333a6.64 6.64 0 0 0-2.993.708l2.976 4.96h.033l2.976-4.96a6.64 6.64 0 0 0-2.992-.708" />
      <Path d="M7.124 7.514a1 1 0 1 0 1.749 0L9.96 5.705a3 3 0 1 1-3.92 0z" />
      <Path d="m11.848 2.556-1.369 2.282a4 4 0 1 1-4.96 0l-1.37-2.282a6.667 6.667 0 1 0 7.699 0" />
    </Svg>
  );
};
SvgRadarLocationSearchFill.displayName = 'RadarLocationSearchFill';
const Memo = memo(SvgRadarLocationSearchFill);
export default Memo;
