// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFeaturesToTheMoonOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.332 1.192a1.167 1.167 0 0 1 2 0l.874 1.452a.17.17 0 0 0 .105.076l1.651.383a1.167 1.167 0 0 1 .618 1.901l-1.111 1.28a.17.17 0 0 0-.04.124l.146 1.689a1.167 1.167 0 0 1-1.617 1.175l-1.561-.661a.17.17 0 0 0-.13 0l-1.56.661a1.167 1.167 0 0 1-1.618-1.175l.146-1.689a.17.17 0 0 0-.04-.124l-1.111-1.28a1.167 1.167 0 0 1 .618-1.901l1.651-.383a.17.17 0 0 0 .105-.076zm1.143.515a.167.167 0 0 0-.286 0L9.315 3.16c-.163.27-.428.463-.736.535l-1.652.382a.167.167 0 0 0-.088.272l1.111 1.28c.207.238.309.55.282.866l-.147 1.688a.167.167 0 0 0 .231.168l1.56-.661c.292-.123.62-.123.911 0l1.561.661a.167.167 0 0 0 .231-.168l-.147-1.688a1.17 1.17 0 0 1 .282-.866l1.111-1.28a.167.167 0 0 0-.088-.272l-1.652-.382a1.17 1.17 0 0 1-.736-.535zm-5.29 5.106a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707l3-3a.5.5 0 0 1 .708 0m1.334 3.334a.5.5 0 0 1 0 .707l-3 3a.5.5 0 0 1-.707-.707l3-3a.5.5 0 0 1 .707 0m4.667 0a.5.5 0 0 1 0 .707l-3 3a.5.5 0 0 1-.708-.707l3-3a.5.5 0 0 1 .708 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFeaturesToTheMoonOutline.displayName = 'FeaturesToTheMoonOutline';
const Memo = memo(SvgFeaturesToTheMoonOutline);
export default Memo;
