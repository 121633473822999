// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgReceiptTicketFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 1.334c-1.012 0-1.833.82-1.833 1.833v10.102a.833.833 0 0 0 1.236.73l1.62-.895a.17.17 0 0 1 .154-.004l1.816.877c.32.155.694.155 1.015 0l1.816-.877a.17.17 0 0 1 .153.004l1.62.895a.833.833 0 0 0 1.237-.73V3.167c0-1.013-.821-1.833-1.834-1.833zm1.5 7.833a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m3.687-3.78a.5.5 0 0 0-.707-.707L7.468 6.193l-.446-.446a.5.5 0 0 0-.708.707l.8.8a.5.5 0 0 0 .708 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgReceiptTicketFill.displayName = 'ReceiptTicketFill';
const Memo = memo(SvgReceiptTicketFill);
export default Memo;
