// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgKeyboardDownCloseDownOpenArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 3.167c0-1.013.82-1.834 1.833-1.834h11c1.013 0 1.834.821 1.834 1.834v5.666c0 1.013-.821 1.834-1.834 1.834h-11A1.833 1.833 0 0 1 .668 8.833zM6.501 7a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM3.835 5.167a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334m0 3a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334m9-3.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m-.667 3.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334m-5-3.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m2.333.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334"
        clipRule="evenodd"
      />
      <Path d="M7.022 12.313a.5.5 0 0 0-.708.707l1.334 1.334a.5.5 0 0 0 .707 0l1.333-1.334a.5.5 0 1 0-.707-.707l-.98.98z" />
    </Svg>
  );
};
SvgKeyboardDownCloseDownOpenArrowFill.displayName = 'KeyboardDownCloseDownOpenArrowFill';
const Memo = memo(SvgKeyboardDownCloseDownOpenArrowFill);
export default Memo;
