// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGlobusMapEarthGlobeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.354 7.5h2.32c.056-1.563.37-2.988.867-4.07.113-.246.24-.484.382-.704A5.67 5.67 0 0 0 2.353 7.5m5.645-6.167a6.667 6.667 0 1 0 0 13.334 6.667 6.667 0 0 0 0-13.334m0 1c-.469 0-1.053.435-1.55 1.515-.427.93-.719 2.208-.774 3.652h4.648c-.056-1.444-.348-2.721-.775-3.652-.496-1.08-1.08-1.515-1.55-1.515M11.323 7.5c-.055-1.563-.37-2.988-.867-4.07a6 6 0 0 0-.381-.704A5.67 5.67 0 0 1 13.644 7.5zm-1 1H5.675c.055 1.444.347 2.721.775 3.652.496 1.08 1.08 1.515 1.549 1.515s1.053-.435 1.549-1.515c.427-.93.72-2.208.774-3.652m-.248 4.774c.14-.22.268-.458.382-.705.496-1.08.81-2.506.866-4.069h2.32a5.67 5.67 0 0 1-3.568 4.774m-4.152 0a6 6 0 0 1-.382-.705c-.497-1.08-.811-2.506-.867-4.069h-2.32a5.67 5.67 0 0 0 3.569 4.774"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGlobusMapEarthGlobeOutline.displayName = 'GlobusMapEarthGlobeOutline';
const Memo = memo(SvgGlobusMapEarthGlobeOutline);
export default Memo;
