// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBaseballOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M5.192 5.226a.41.41 0 0 1-.534-.151.407.407 0 0 1 .095-.546l.128-.094a.43.43 0 0 1 .623.107c.134.213.05.49-.172.61zm5.475-.075a.43.43 0 0 1-.173-.609.43.43 0 0 1 .624-.107l.128.094a.407.407 0 0 1 .095.546.41.41 0 0 1-.534.151zm-4.911 1.62a.41.41 0 0 1-.47-.295.406.406 0 0 1 .242-.497l.15-.055a.43.43 0 0 1 .568.275.433.433 0 0 1-.335.54zm4.331-.033a.433.433 0 0 1-.335-.539.43.43 0 0 1 .569-.275l.15.055c.199.073.3.292.242.497a.41.41 0 0 1-.47.295zM5.499 8c0-.213.16-.398.372-.413l.158-.01c.251-.018.47.172.47.423s-.219.44-.47.423l-.158-.01A.41.41 0 0 1 5.5 8m4 0c0-.251.22-.44.47-.423l.158.01c.213.015.372.2.372.413s-.159.398-.372.412l-.158.011A.43.43 0 0 1 9.5 8m-3.97 2.021a.406.406 0 0 1-.244-.497.41.41 0 0 1 .471-.295l.155.033a.433.433 0 0 1 .335.539.43.43 0 0 1-.568.275zm4.792.055a.43.43 0 0 1-.569-.275.433.433 0 0 1 .335-.54l.155-.032a.41.41 0 0 1 .471.295.406.406 0 0 1-.243.497zM4.753 11.47a.407.407 0 0 1-.095-.546.41.41 0 0 1 .534-.152l.14.075a.43.43 0 0 1 .172.61.43.43 0 0 1-.623.107zm6.365.094a.43.43 0 0 1-.624-.107.43.43 0 0 1 .173-.61l.14-.075c.188-.1.42-.028.534.152.113.18.077.42-.095.546z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBaseballOutline.displayName = 'BaseballOutline';
const Memo = memo(SvgBaseballOutline);
export default Memo;
