// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVolleyballFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.767 1.337A6.67 6.67 0 0 0 1.607 6.1c2.095-1.287 4.49-2.166 6.7-2.333-.056-.878-.238-1.722-.54-2.43M1.354 7.46a6.647 6.647 0 0 0 .49 3.104c.629-.728 1.441-1.372 2.317-1.878 1.106-.638 2.352-1.078 3.523-1.17.367-.778.581-1.743.63-2.746-2.271.187-4.817 1.204-6.96 2.69M2.344 11.533A6.66 6.66 0 0 0 8 14.667c.553 0 1.091-.068 1.605-.195-2.182-1.152-4.167-2.785-5.423-4.622-.734.487-1.374 1.068-1.837 1.683M10.908 14a6.7 6.7 0 0 0 2.387-1.95c-.944-.183-1.908-.564-2.783-1.07-1.106-.637-2.11-1.497-2.775-2.465-.856.071-1.798.368-2.691.827C6.35 11.22 8.533 12.914 10.908 14M13.886 11.13c.497-.933.78-1.998.78-3.13a6.64 6.64 0 0 0-1.856-4.615c.088 2.464-.336 4.994-1.298 6.998.788.392 1.61.655 2.374.748M11.75 2.489a6.6 6.6 0 0 0-2.892-1.1c.314.908.466 1.933.466 2.944 0 1.277-.242 2.577-.748 3.636.49.707 1.219 1.375 2.064 1.92.974-2.068 1.352-4.801 1.11-7.4" />
    </Svg>
  );
};
SvgVolleyballFill.displayName = 'VolleyballFill';
const Memo = memo(SvgVolleyballFill);
export default Memo;
