// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPencil2PenToolWriteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.242 8.584a1.68 1.68 0 0 0 1.943-2.686l-3.99-3.99a1.679 1.679 0 0 0-2.689 1.938L3.414 5.73a1.83 1.83 0 0 0-1.051 1.437l-.84 6.695 3.914-3.914a1.333 1.333 0 1 1 .707.707l-3.912 3.912 6.665-.882a1.83 1.83 0 0 0 1.416-1.033zm1.236-1.02a.68.68 0 0 1-.96 0l-3.99-3.99a.679.679 0 0 1 .96-.959l3.99 3.99a.68.68 0 0 1 0 .96"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPencil2PenToolWriteFill.displayName = 'Pencil2PenToolWriteFill';
const Memo = memo(SvgPencil2PenToolWriteFill);
export default Memo;
