// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAvocadoFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.541 1.97a15 15 0 0 0-.922.38c-1.731.781-3.436 1.91-4.594 3.04C.889 7.472.678 11.048 2.86 13.167l.001.001c2.146 2.15 5.718 1.935 7.767-.18 1.321-1.364 2.647-3.488 3.404-5.52.38-1.016.628-2.04.648-2.953.02-.91-.185-1.782-.797-2.395s-1.483-.82-2.391-.8c-.913.021-1.935.27-2.95.65M6.46 11.875a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAvocadoFill.displayName = 'AvocadoFill';
const Memo = memo(SvgAvocadoFill);
export default Memo;
