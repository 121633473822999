// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicBoxFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.901 2.05a.33.33 0 0 0 .15-.15l.318-.637a.333.333 0 0 1 .597 0l.318.638a.33.33 0 0 0 .15.149l.637.318a.333.333 0 0 1 0 .597l-.638.319a.33.33 0 0 0-.149.149l-.318.637a.333.333 0 0 1-.597 0l-.319-.637a.33.33 0 0 0-.149-.15l-.637-.318a.333.333 0 0 1 0-.597zM4.346 3.16a.33.33 0 0 0 .149-.148l.208-.416a.333.333 0 0 1 .596 0l.208.416a.33.33 0 0 0 .149.149l.415.207a.333.333 0 0 1 0 .597l-.415.207a.33.33 0 0 0-.15.15l-.207.415a.333.333 0 0 1-.596 0l-.208-.415a.33.33 0 0 0-.15-.15l-.415-.207a.333.333 0 0 1 0-.597z" />
      <Path
        fillRule="evenodd"
        d="M13.283 2.587a.5.5 0 0 1 .132.694L11.562 6h1.273a.5.5 0 0 1 .5.5v6.333c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834V6.5a.5.5 0 0 1 .5-.5h7.184l2.236-3.282a.5.5 0 0 1 .695-.131M6.5 8.667a.5.5 0 0 0 0 1h3a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMagicBoxFill.displayName = 'MagicBoxFill';
const Memo = memo(SvgMagicBoxFill);
export default Memo;
