// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFastForward10SFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.64.05a.52.52 0 0 0-.308.474v.842a6.668 6.668 0 1 0 5.529 2.072.5.5 0 0 0-.73.685 5.667 5.667 0 1 1-4.8-1.75v.77c.001.203.12.388.308.474a.54.54 0 0 0 .568-.072l1.6-1.31a.52.52 0 0 0 0-.804L8.207.12A.54.54 0 0 0 7.639.05" />
      <Path d="M5.836 10.024c0 .326.175.519.476.519.305 0 .48-.19.48-.52V6.183c0-.362-.233-.602-.58-.602-.2 0-.405.072-.663.247l-.64.448q-.275.18-.276.404c0 .197.147.344.344.344.103 0 .193-.032.347-.132l.49-.348h.022zM7.637 8.176c0 1.475.691 2.428 1.851 2.428s1.87-.963 1.87-2.428v-.23c0-1.478-.688-2.427-1.855-2.427-1.164 0-1.866.96-1.866 2.428zm.967-.225c0-1.032.336-1.644.891-1.644.556 0 .892.616.892 1.644v.218c0 1.031-.337 1.647-.892 1.647S8.604 9.2 8.604 8.17z" />
    </Svg>
  );
};
SvgFastForward10SFill.displayName = 'FastForward10SFill';
const Memo = memo(SvgFastForward10SFill);
export default Memo;
