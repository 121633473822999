// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayoutLeftGridWindowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.613 2H6v12H4.613c-.356 0-.653 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.2 13a1.9 1.9 0 0 1-.18-.716C2 12.04 2 11.743 2 11.387V4.613c0-.356 0-.653.02-.896.02-.253.065-.491.18-.716.176-.345.456-.625.801-.801.225-.115.463-.16.716-.18.243-.02.54-.02.896-.02M7 14h4.387c.356 0 .653 0 .896-.02.253-.02.491-.065.716-.18.345-.176.625-.456.801-.801.115-.225.16-.463.18-.716.02-.243.02-.54.02-.896V4.613c0-.356 0-.653-.02-.896A1.9 1.9 0 0 0 13.8 3 1.83 1.83 0 0 0 13 2.2a1.9 1.9 0 0 0-.716-.18C12.04 2 11.743 2 11.387 2H7z" />
    </Svg>
  );
};
SvgLayoutLeftGridWindowFill.displayName = 'LayoutLeftGridWindowFill';
const Memo = memo(SvgLayoutLeftGridWindowFill);
export default Memo;
