// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBillPurchaceInvoiceFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.668 3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834v11a.5.5 0 0 1-.829.377l-1.376-1.2-1.226 1.183a.5.5 0 0 1-.694 0L8 13.36l-1.208 1.165a.5.5 0 0 1-.694 0l-1.226-1.181-1.377 1.199a.5.5 0 0 1-.828-.377zm2.667 2a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m0 2.666a.5.5 0 0 1 .5-.5H7.5a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBillPurchaceInvoiceFill.displayName = 'BillPurchaceInvoiceFill';
const Memo = memo(SvgBillPurchaceInvoiceFill);
export default Memo;
