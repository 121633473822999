// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleLikeLoveHeartOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2.333a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3 5c-2.04 0-3.575 1.202-4.208 2.916a.48.48 0 0 0 .088.493.77.77 0 0 0 .585.258h3.71a.5.5 0 1 1 0 1h-3.71a1.77 1.77 0 0 1-1.35-.615 1.48 1.48 0 0 1-.261-1.483C3.624 9.817 5.52 8.333 8 8.333q.468 0 .91.07a.5.5 0 0 1-.153.988A5 5 0 0 0 8 9.333m2.575 1.703c-.126.135-.24.367-.24.742 0 .457.34.93.833 1.337a4.5 4.5 0 0 0 .833.54 4.492 4.492 0 0 0 .833-.54c.492-.407.834-.88.834-1.337 0-.375-.115-.607-.241-.742a.63.63 0 0 0-.463-.203.97.97 0 0 0-.613.219l-.002.001a.5.5 0 0 1-.698-.001l-.024-.02a.972.972 0 0 0-.589-.199.63.63 0 0 0-.463.203m1.426-.952-.016-.01a2 2 0 0 0-.947-.24c-.435 0-.87.174-1.192.518-.326.348-.512.837-.512 1.426 0 .927.652 1.657 1.197 2.108.287.237.578.424.812.555q.179.098.316.157.068.03.135.051c.029.01.112.036.207.036s.178-.026.206-.035q.068-.023.136-.052.137-.059.316-.157c.234-.13.525-.319.811-.555.546-.45 1.198-1.181 1.198-2.108 0-.589-.186-1.078-.512-1.426a1.63 1.63 0 0 0-1.192-.519 2 2 0 0 0-.963.25m-.098 3.613h.002zm.194 0q.014.004.002 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPeopleLikeLoveHeartOutline.displayName = 'PeopleLikeLoveHeartOutline';
const Memo = memo(SvgPeopleLikeLoveHeartOutline);
export default Memo;
