// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRemoveColumnDeleteColumnOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3.667a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833H7.5V3.667zm5.334 0h4.333c.46 0 .833.373.833.833v2.333a.5.5 0 1 0 1 0V4.5c0-1.012-.82-1.833-1.833-1.833H3.165c-1.012 0-1.833.82-1.833 1.833v7c0 1.013.82 1.833 1.833 1.833H8a.5.5 0 0 0 .5-.5zm2.313 5.813a.5.5 0 0 1 .707 0l1.146 1.146 1.147-1.146a.5.5 0 1 1 .707.707l-1.146 1.146 1.146 1.147a.5.5 0 1 1-.707.707l-1.147-1.146-1.146 1.146a.5.5 0 0 1-.707-.707l1.146-1.147-1.146-1.146a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRemoveColumnDeleteColumnOutline.displayName = 'RemoveColumnDeleteColumnOutline';
const Memo = memo(SvgRemoveColumnDeleteColumnOutline);
export default Memo;
