// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgReadingListGlassesSteveJobsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4 5.667A2.33 2.33 0 0 0 1.667 8 2.333 2.333 0 1 0 4 5.667M1.333 6A3.33 3.33 0 0 1 4 4.667c.96 0 1.827.407 2.435 1.056.467-.249 1-.39 1.565-.39a3.3 3.3 0 0 1 1.565.39A3.33 3.33 0 0 1 12 4.667c1.091 0 2.06.524 2.667 1.333h.833a.5.5 0 0 1 0 1h-.32a3.333 3.333 0 1 1-6.185-.445A2.3 2.3 0 0 0 8 6.334c-.356 0-.694.08-.995.223A3.333 3.333 0 1 1 .82 7H.5a.5.5 0 0 1 0-1zM12 5.667A2.33 2.33 0 0 0 9.667 8 2.333 2.333 0 1 0 12 5.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgReadingListGlassesSteveJobsOutline.displayName = 'ReadingListGlassesSteveJobsOutline';
const Memo = memo(SvgReadingListGlassesSteveJobsOutline);
export default Memo;
