// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowBottomCircleOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 13.913a5.913 5.913 0 1 0 0-11.826 5.913 5.913 0 0 0 0 11.826M14.955 8A6.957 6.957 0 1 1 1.042 8a6.957 6.957 0 0 1 13.913 0M7.477 9.697l-1.37-1.37a.522.522 0 0 0-.738.738l1.769 1.769a1.217 1.217 0 0 0 1.721 0l1.77-1.77a.522.522 0 1 0-.738-.737l-1.37 1.37V5.043a.522.522 0 1 0-1.044 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowBottomCircleOutline.displayName = 'ArrowBottomCircleOutline';
const Memo = memo(SvgArrowBottomCircleOutline);
export default Memo;
