// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronLargeTopChevTopFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.425 6.668a.83.83 0 0 0-.852 0L2.088 9.929a.5.5 0 1 1-.511-.86l5.485-3.26a1.83 1.83 0 0 1 1.874 0l5.485 3.26a.5.5 0 0 1-.511.86z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronLargeTopChevTopFill.displayName = 'ChevronLargeTopChevTopFill';
const Memo = memo(SvgChevronLargeTopChevTopFill);
export default Memo;
