// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddPeopleAddUserAddPersonFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.001 1.333a3 3 0 1 0 0 6 3 3 0 0 0 0-6M11.013 9.21c-.216.26-.345.593-.345.957v.5h-.5a1.5 1.5 0 1 0 0 3h.5V14H4.465a1.77 1.77 0 0 1-1.35-.615 1.48 1.48 0 0 1-.261-1.483C3.638 9.782 5.748 8.333 8 8.333c1.109 0 2.144.314 3.012.877" />
      <Path d="M12.668 10.167a.5.5 0 0 0-1 0v1.5h-1.5a.5.5 0 1 0 0 1h1.5v1.5a.5.5 0 1 0 1 0v-1.5h1.5a.5.5 0 0 0 0-1h-1.5z" />
    </Svg>
  );
};
SvgAddPeopleAddUserAddPersonFill.displayName = 'AddPeopleAddUserAddPersonFill';
const Memo = memo(SvgAddPeopleAddUserAddPersonFill);
export default Memo;
