// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgColorSwatchPaletteColoursOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.167c0-1.013.82-1.834 1.833-1.834h3.667c.254 0 .497.052.718.146.238.03.475.106.695.233l3.175 1.833c.22.127.404.293.549.485.192.145.358.329.485.549l1.833 3.175a1.833 1.833 0 0 1-.67 2.505L6.88 14.147A3.667 3.667 0 0 1 1.331 11zM6.366 13.29l-.034.02a2.667 2.667 0 0 1-4-2.31V3.167c0-.46.373-.834.833-.834h3.667q.2.001.366.085c.278.136.467.42.467.749V11c0 .472-.122.915-.337 1.3l-.02.033a2.7 2.7 0 0 1-.942.957m2.317-1.338 4.433-2.56a.833.833 0 0 0 .305-1.138L12.12 6l-.028.05zm-.018-1.97 2.56-4.433a.833.833 0 0 0-.305-1.138L8.664 3.109l.001.058zM5 10.334a.667.667 0 1 0 0 1.334.667.667 0 0 0 0-1.334M3.332 11a1.667 1.667 0 1 1 3.333 0 1.667 1.667 0 0 1-3.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgColorSwatchPaletteColoursOutline.displayName = 'ColorSwatchPaletteColoursOutline';
const Memo = memo(SvgColorSwatchPaletteColoursOutline);
export default Memo;
