// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrending3TrendsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.8 4.438c0-.242.201-.438.45-.438h3.3c.248 0 .45.196.45.438v3.208a.444.444 0 0 1-.45.437.444.444 0 0 1-.45-.437V5.5L9.767 8.736a1.684 1.684 0 0 1-2.333-.001l-.704-.684a.765.765 0 0 0-1.06 0l-2.902 2.82a.46.46 0 0 1-.636 0 .43.43 0 0 1 0-.618l2.901-2.82a1.684 1.684 0 0 1 2.334 0l.703.683a.765.765 0 0 0 1.06 0l3.34-3.241h-2.22a.444.444 0 0 1-.45-.437"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrending3TrendsFill.displayName = 'Trending3TrendsFill';
const Memo = memo(SvgTrending3TrendsFill);
export default Memo;
