// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRocketStartupLaunchOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.641 2.46a.093.093 0 0 0-.103-.102c-2.237.177-3.997 1.31-5.673 3.134L5.242 8.795l1.962 1.962 3.303-2.623c1.824-1.676 2.957-3.436 3.134-5.674m-3.309 7.09-2.667 2.117v1.043c0 .127.136.207.247.147l1.986-1.083a.83.83 0 0 0 .434-.732zm-3.667 2.083-2.3-2.3H3.289a1.167 1.167 0 0 1-1.024-1.725l1.083-1.986a1.83 1.83 0 0 1 1.61-.955h2.31c1.74-1.851 3.681-3.108 6.193-3.306a1.093 1.093 0 0 1 1.178 1.178c-.199 2.511-1.455 4.454-3.306 6.193v2.31c0 .67-.366 1.288-.955 1.61L8.39 13.734a1.167 1.167 0 0 1-1.726-1.025zM6.45 5.667H4.957a.83.83 0 0 0-.732.434L3.142 8.087c-.06.111.02.246.146.246h1.043zm-5.117 7.13a1.87 1.87 0 1 1 1.87 1.87H2.5A1.167 1.167 0 0 1 1.332 13.5zm1.87-.87a.87.87 0 0 0-.87.87v.703c0 .092.075.167.167.167h.703a.87.87 0 0 0 0-1.74"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRocketStartupLaunchOutline.displayName = 'RocketStartupLaunchOutline';
const Memo = memo(SvgRocketStartupLaunchOutline);
export default Memo;
