// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile7DocumentLockedPasswordFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.5 1.333H8v3.5c0 1.013.82 1.834 1.833 1.834h3.5v6.166c0 1.013-.82 1.834-1.833 1.834H7.993c.215-.337.34-.737.34-1.167v-1.667c0-.713-.344-1.344-.874-1.739a2.917 2.917 0 0 0-4.792-1.71V3.168c0-1.013.82-1.834 1.833-1.834" />
      <Path d="m12.992 5.618.046.049H9.833A.833.833 0 0 1 9 4.833V1.63l.049.046z" />
      <Path
        fillRule="evenodd"
        d="M6.667 10.779v-.112a2 2 0 1 0-4 0v.112A1.17 1.17 0 0 0 2 11.833V13.5c0 .644.522 1.167 1.167 1.167h3c.644 0 1.166-.523 1.166-1.167v-1.667c0-.465-.272-.867-.666-1.054m-3-.112h2a1 1 0 1 0-2 0m2.5 1h-3a.167.167 0 0 0-.167.166V13.5c0 .092.075.167.167.167h3a.167.167 0 0 0 .166-.167v-1.667a.167.167 0 0 0-.166-.166"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFile7DocumentLockedPasswordFill.displayName = 'File7DocumentLockedPasswordFill';
const Memo = memo(SvgFile7DocumentLockedPasswordFill);
export default Memo;
