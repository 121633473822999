// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSunLightModeDayFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G
        fill="#0D1011"
        clipPath="url(#a)"
      >
        <Path d="M8.501 1.178a.5.5 0 1 0-1 0v1.016a.5.5 0 1 0 1 0zM2.824 12.47a.5.5 0 1 0 .707.707l.718-.718a.5.5 0 1 0-.707-.707zM8.001 13.306a.5.5 0 0 1 .5.5v1.016a.5.5 0 0 1-1 0v-1.016a.5.5 0 0 1 .5-.5M11.753 3.541a.5.5 0 0 0 .707.707l.718-.718a.5.5 0 1 0-.707-.707zM13.307 8a.5.5 0 0 1 .5-.5h1.016a.5.5 0 0 1 0 1h-1.016a.5.5 0 0 1-.5-.5M12.46 11.752a.5.5 0 1 0-.707.707l.718.718a.5.5 0 1 0 .707-.707zM.68 8a.5.5 0 0 1 .5-.5h1.015a.5.5 0 1 1 0 1H1.18a.5.5 0 0 1-.5-.5M3.531 2.823a.5.5 0 1 0-.707.707l.718.718a.5.5 0 0 0 .707-.707zM5.173 5.172a4 4 0 1 1 5.657 5.656 4 4 0 0 1-5.657-5.656" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgSunLightModeDayFill.displayName = 'SunLightModeDayFill';
const Memo = memo(SvgSunLightModeDayFill);
export default Memo;
