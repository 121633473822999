// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMoonDarkModeNightFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.003 1.333a6.667 6.667 0 1 0 6.665 6.531.5.5 0 0 0-.789-.398 3.833 3.833 0 0 1-5.343-5.343.5.5 0 0 0-.398-.788z" />
    </Svg>
  );
};
SvgMoonDarkModeNightFill.displayName = 'MoonDarkModeNightFill';
const Memo = memo(SvgMoonDarkModeNightFill);
export default Memo;
