// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCupCoffeeTeaMilkFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.668 1.333a.5.5 0 0 1 .5.5V3.5a.5.5 0 0 1-1 0V1.833a.5.5 0 0 1 .5-.5M7.335 1.333a.5.5 0 0 1 .5.5V3.5a.5.5 0 0 1-1 0V1.833a.5.5 0 0 1 .5-.5M10.001 1.333a.5.5 0 0 1 .5.5V3.5a.5.5 0 0 1-1 0V1.833a.5.5 0 0 1 .5-.5" />
      <Path
        fillRule="evenodd"
        d="M3.168 4.667a.5.5 0 0 0-.5.5v7.666c0 1.013.82 1.834 1.833 1.834h5.667c1.013 0 1.833-.821 1.833-1.834v-2.166h.334a2.333 2.333 0 1 0 0-4.667H12v-.833a.5.5 0 0 0-.5-.5zM12.001 7v2.667h.334a1.333 1.333 0 1 0 0-2.667z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCupCoffeeTeaMilkFill.displayName = 'CupCoffeeTeaMilkFill';
const Memo = memo(SvgCupCoffeeTeaMilkFill);
export default Memo;
