// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAmericanFootballNflFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.59 1.632a8.52 8.52 0 0 0-5.96 5.96l6.777 6.776a8.52 8.52 0 0 0 5.96-5.96zM9.686 7.02a.5.5 0 1 0-.708-.707L6.312 8.98a.5.5 0 0 0 .707.707z"
        clipRule="evenodd"
      />
      <Path d="M1.332 9.833q0-.539.066-1.06L7.226 14.6a9 9 0 0 1-1.06.066H2.498A1.167 1.167 0 0 1 1.332 13.5zM14.6 7.227q.065-.521.065-1.06V2.5c0-.644-.522-1.167-1.166-1.167H9.832q-.54 0-1.06.066z" />
    </Svg>
  );
};
SvgAmericanFootballNflFill.displayName = 'AmericanFootballNflFill';
const Memo = memo(SvgAmericanFootballNflFill);
export default Memo;
