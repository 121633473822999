// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLargeFloorPlatesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.71 1.333h6.022c.295 0 .58.115.799.323s.356.494.386.802v.002L14 14.21a.43.43 0 0 1-.101.32.4.4 0 0 1-.295.136H1.733a.4.4 0 0 1-.294-.136.43.43 0 0 1-.101-.32l.379-4.107a.4.4 0 0 1 .01-.12L2.1 5.961a.4.4 0 0 1 .015-.169l.308-3.334c.03-.308.167-.594.386-.802a1.16 1.16 0 0 1 .799-.323H5.71M5.866 5.5l.212-3.334h3.226L9.516 5.5h-3.65m-.429.833h1.857v3.333h-4.74l.308-3.333zM5.07 5.5H2.94l.272-2.959a.42.42 0 0 1 .129-.267.4.4 0 0 1 .266-.108h1.675zm4.866.833h2.54l.308 3.333H8.089V6.333zm.376-.833L10.1 2.166h1.632c.098 0 .193.039.266.108.073.07.118.164.128.267L12.4 5.5zm-.265 8.333H5.335l.211-3.333h4.29zm-5.508 0L4.75 10.5H2.478l-.308 3.333zm8.63 0h-2.326l-.211-3.333h2.229z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLargeFloorPlatesOutline.displayName = 'LargeFloorPlatesOutline';
const Memo = memo(SvgLargeFloorPlatesOutline);
export default Memo;
