// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedRealityCardBox3DVirtualRealityVrOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 5.167c0-1.013.82-1.834 1.833-1.834h11c1.013 0 1.834.821 1.834 1.834V11.5c0 1.012-.821 1.833-1.834 1.833h-2.256c-.452 0-.888-.166-1.224-.468l-1.908-1.71a.167.167 0 0 0-.223 0l-1.908 1.71a1.83 1.83 0 0 1-1.224.468H2.5A1.833 1.833 0 0 1 .668 11.5zm1.833-.834a.833.833 0 0 0-.833.834V11.5c0 .46.373.833.833.833h2.257a.83.83 0 0 0 .556-.213l1.909-1.71a1.166 1.166 0 0 1 1.557 0l1.909 1.71c.152.138.35.213.556.213H13.5c.46 0 .834-.373.834-.833V5.167a.833.833 0 0 0-.834-.834zM5.001 7a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333m-1.666.667a1.667 1.667 0 1 1 3.333 0 1.667 1.667 0 0 1-3.333 0M11 7a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333m-1.666.667a1.667 1.667 0 1 1 3.333 0 1.667 1.667 0 0 1-3.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArAugmentedRealityCardBox3DVirtualRealityVrOutline.displayName =
  'ArAugmentedRealityCardBox3DVirtualRealityVrOutline';
const Memo = memo(SvgArAugmentedRealityCardBox3DVirtualRealityVrOutline);
export default Memo;
