// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBroomBrushFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.935 2.082a.5.5 0 1 0-.868-.497L9.659 7.543l-.552-.2c-1.356-.49-2.718.26-3.416 1.35-.624.976-1.453 1.645-2.466 2.316a.5.5 0 0 0 .083.878l1.498.627c.04.017.085.013.122-.01l1.968-1.23c.117-.073.255.057.188.178l-.836 1.505a.133.133 0 0 0 .065.187l3.546 1.484a.5.5 0 0 0 .549-.11c1.281-1.3 1.961-2.45 2.186-4.04.16-1.133-.61-2.094-1.59-2.449l-.39-.14zM6.374 2.48a.24.24 0 0 0 .107-.107l.305-.61a.24.24 0 0 1 .43 0l.305.61a.24.24 0 0 0 .107.107l.61.305a.24.24 0 0 1 0 .43l-.61.305a.24.24 0 0 0-.107.107l-.306.61a.24.24 0 0 1-.429 0l-.305-.61a.24.24 0 0 0-.107-.107l-.61-.305a.24.24 0 0 1 0-.43zM2.79 4.939a.33.33 0 0 0 .15-.15l.43-.86a.333.333 0 0 1 .596 0l.43.86a.33.33 0 0 0 .149.15l.86.43a.333.333 0 0 1 0 .596l-.86.43a.33.33 0 0 0-.15.149l-.43.86a.333.333 0 0 1-.596 0l-.43-.86a.33.33 0 0 0-.149-.15l-.86-.43a.333.333 0 0 1 0-.595z" />
    </Svg>
  );
};
SvgBroomBrushFill.displayName = 'BroomBrushFill';
const Memo = memo(SvgBroomBrushFill);
export default Memo;
