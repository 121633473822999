// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSpatialSpatialCaptureOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.414 1.982a.83.83 0 0 0-.83 0L5.664 3.086v2.578l4.567-2.636zm2.82 1.622-2.236 1.29 4.44 2.564V5.355a.83.83 0 0 0-.417-.723zm2.205 5.009-2.107-1.216v4.942l1.689-.971a.83.83 0 0 0 .418-.723zm-3.107 4.301v-2.246l-4.278 2.47 1.53.88a.83.83 0 0 0 .83 0zm-5.28-.352 1.946-1.124-2.084-1.203L2.56 8.868v1.777c0 .299.159.574.417.723zm-2.493-4.85 2.106 1.222V3.661l-1.689.971a.83.83 0 0 0-.417.723zm3.106 1.802 2.333 1.347 2.334-1.348V6.82L7.998 5.472 5.665 6.819zm1.42-8.398a1.83 1.83 0 0 1 1.828 0l4.606 2.65c.569.326.92.932.92 1.589v5.29c0 .656-.351 1.263-.92 1.59l-4.606 2.65a1.83 1.83 0 0 1-1.828 0l-4.607-2.65a1.83 1.83 0 0 1-.92-1.59v-5.29c0-.657.351-1.263.92-1.59z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSpatialSpatialCaptureOutline.displayName = 'SpatialSpatialCaptureOutline';
const Memo = memo(SvgSpatialSpatialCaptureOutline);
export default Memo;
