// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRentKeyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.996 2.567a3.433 3.433 0 0 0-3.286 4.43c.06.2.006.418-.142.566l-3.976 3.976a.1.1 0 0 0-.029.07v1.724a.1.1 0 0 0 .1.1h1.433v-.766c0-.313.254-.567.567-.567h.767v-.767c0-.313.253-.566.566-.566h1.099l1.339-1.34a.57.57 0 0 1 .565-.14 3.433 3.433 0 1 0 .998-6.72M5.43 6a4.567 4.567 0 1 1 3.576 4.459L7.73 11.734a.57.57 0 0 1-.4.166h-.767v.767a.567.567 0 0 1-.567.566H5.23V14a.567.567 0 0 1-.567.567h-2a1.233 1.233 0 0 1-1.233-1.234V11.61c0-.328.13-.641.36-.873l3.748-3.746A4.6 4.6 0 0 1 5.43 6m4-1.333c0-.313.253-.567.566-.567 1.05 0 1.9.85 1.9 1.9a.567.567 0 0 1-1.133 0 .767.767 0 0 0-.767-.767.567.567 0 0 1-.566-.566"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRentKeyOutline.displayName = 'RentKeyOutline';
const Memo = memo(SvgRentKeyOutline);
export default Memo;
