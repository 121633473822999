// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgIconIconsShapesGamesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.667 2a.5.5 0 0 1 .5.5v1.667h1.666a.5.5 0 1 1 0 1H5.167v1.666a.5.5 0 1 1-1 0V5.167H2.5a.5.5 0 0 1 0-1h1.667V2.5a.5.5 0 0 1 .5-.5m4 2.667a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0M11.333 3a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333M2 10.167a1.5 1.5 0 0 1 1.5-1.5h2.333a1.5 1.5 0 0 1 1.5 1.5V12.5a1.5 1.5 0 0 1-1.5 1.5H3.5A1.5 1.5 0 0 1 2 12.5zm1.5-.5a.5.5 0 0 0-.5.5V12.5a.5.5 0 0 0 .5.5h2.333a.5.5 0 0 0 .5-.5v-2.333a.5.5 0 0 0-.5-.5zm5.948-.22a.5.5 0 0 1 .707 0l1.178 1.18 1.179-1.18a.5.5 0 0 1 .707.708l-1.179 1.178 1.179 1.179a.5.5 0 0 1-.707.707l-1.179-1.179-1.178 1.179a.5.5 0 0 1-.707-.707l1.178-1.179-1.178-1.178a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgIconIconsShapesGamesOutline.displayName = 'IconIconsShapesGamesOutline';
const Memo = memo(SvgIconIconsShapesGamesOutline);
export default Memo;
