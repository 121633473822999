// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedRealityScan3DViewCubeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 2.969a.865.865 0 0 0-.864.864V5.5a.469.469 0 0 1-.938 0V3.833c0-.995.807-1.802 1.802-1.802H5.5a.469.469 0 0 1 0 .938zm6.198-.469c0-.259.21-.469.469-.469h1.667c.995 0 1.802.807 1.802 1.802V5.5a.469.469 0 0 1-.938 0V3.833a.865.865 0 0 0-.864-.864H10.5a.47.47 0 0 1-.469-.469M8.098 5.739a.2.2 0 0 0-.196 0l-1.566.895L8 7.474l1.664-.84zm1.933 1.76-1.562.79v1.76l1.463-.836c.061-.035.1-.1.1-.171zm-2.5 2.55V8.29l-1.562-.79v1.543c0 .07.038.136.1.171zm-.094-5.124c.349-.2.777-.2 1.126 0l1.834 1.047c.353.203.572.579.572.986v2.084c0 .407-.219.783-.572.986l-1.834 1.047c-.349.2-.777.2-1.126 0l-1.834-1.047a1.14 1.14 0 0 1-.572-.986V6.958c0-.407.219-.783.572-.986zM2.5 10.03c.259 0 .469.21.469.469v1.667c0 .477.387.864.864.864H5.5a.469.469 0 0 1 0 .938H3.833a1.8 1.8 0 0 1-1.802-1.802V10.5c0-.259.21-.469.469-.469m11 0c.259 0 .469.21.469.469v1.667c0 .995-.807 1.802-1.802 1.802H10.5a.469.469 0 0 1 0-.938h1.667a.864.864 0 0 0 .864-.864V10.5c0-.259.21-.469.469-.469"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArAugmentedRealityScan3DViewCubeOutline.displayName = 'ArAugmentedRealityScan3DViewCubeOutline';
const Memo = memo(SvgArAugmentedRealityScan3DViewCubeOutline);
export default Memo;
