// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgZoomInSearchMagnifyingGlassFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 7.333a5.333 5.333 0 1 1 9.442 3.402l2.245 2.245a.5.5 0 0 1-.707.707l-2.245-2.245A5.333 5.333 0 0 1 2 7.334m5.833-2.166a.5.5 0 1 0-1 0v1.666H5.167a.5.5 0 1 0 0 1h1.666V9.5a.5.5 0 0 0 1 0V7.833H9.5a.5.5 0 1 0 0-1H7.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgZoomInSearchMagnifyingGlassFill.displayName = 'ZoomInSearchMagnifyingGlassFill';
const Memo = memo(SvgZoomInSearchMagnifyingGlassFill);
export default Memo;
