// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMinimizeArrowShrinkOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.167 2a.5.5 0 0 1 .5.5v3.126l3.48-3.48a.5.5 0 0 1 .707.708l-3.48 3.48H13.5a.5.5 0 0 1 0 1H9.833a1.167 1.167 0 0 1-1.166-1.167V2.5a.5.5 0 0 1 .5-.5M2 9.167a.5.5 0 0 1 .5-.5h3.667c.644 0 1.166.522 1.166 1.166V13.5a.5.5 0 0 1-1 0v-3.126l-3.48 3.48a.5.5 0 0 1-.707-.708l3.48-3.48H2.5a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMinimizeArrowShrinkOutline.displayName = 'MinimizeArrowShrinkOutline';
const Memo = memo(SvgMinimizeArrowShrinkOutline);
export default Memo;
