// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCityViewCitySkylineHighFloorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.938 1a1.75 1.75 0 0 0-1.53.898 1.3 1.3 0 0 0-.208-.023h-.013a1.312 1.312 0 1 0 0 2.625h1.75A1.75 1.75 0 0 0 4.939 1v.438zm-.55 1.068a.875.875 0 1 1 .55 1.557h-1.75a.437.437 0 0 1-.007-.875q.122.006.22.075a.438.438 0 0 0 .68-.263.87.87 0 0 1 .307-.494m4.53-.15a.15.15 0 0 1 .103-.043h4.958a.146.146 0 0 1 .146.146v12.104h-5.25V2.021c0-.039.015-.076.043-.103M9.02 1A1.02 1.02 0 0 0 8 2.02v12.25a.73.73 0 0 0 .73.73h5.54a.73.73 0 0 0 .73-.73V2.02A1.02 1.02 0 0 0 13.98 1zm1.166 1.75c.242 0 .438.196.438.437v.438a.438.438 0 0 1-.875 0v-.438c0-.241.196-.437.438-.437m3.063.437a.438.438 0 0 0-.875 0v.438a.438.438 0 0 0 .875 0zm-3.062 1.75c.241 0 .437.196.437.438v.438a.438.438 0 0 1-.875 0v-.438c0-.242.196-.437.438-.437m3.062.438a.438.438 0 0 0-.875 0v.438a.438.438 0 0 0 .875 0zm-3.062 1.75c.241 0 .437.196.437.438V8a.438.438 0 0 1-.875 0v-.437c0-.242.196-.438.438-.438m3.062.438a.438.438 0 0 0-.875 0V8a.438.438 0 0 0 .875 0zm-3.062 1.75c.241 0 .437.195.437.437v.437a.437.437 0 1 1-.875 0V9.75c0-.242.196-.438.438-.438m3.062.437a.438.438 0 0 0-.875 0v.437a.437.437 0 1 0 .875 0zm-3.062 1.75c.241 0 .437.196.437.438v.437a.438.438 0 0 1-.875 0v-.437c0-.242.196-.438.438-.438m3.062.438a.438.438 0 0 0-.875 0v.437a.438.438 0 0 0 .875 0zM6.25 8H1.875v-.73a.146.146 0 0 1 .146-.145h4.083a.146.146 0 0 1 .146.146zm.875.437V7.271a1.02 1.02 0 0 0-1.02-1.021H2.02A1.02 1.02 0 0 0 1 7.27v7.001a.73.73 0 0 0 .73.729h4.666a.73.73 0 0 0 .729-.73zm-5.25.438H6.25v5.25H1.875zm1.313.875c.241 0 .437.196.437.438v.437a.438.438 0 0 1-.875 0v-.437c0-.242.196-.438.438-.438m2.187.438a.438.438 0 1 0-.875 0v.437a.438.438 0 0 0 .875 0zm-2.187 1.75c.241 0 .437.195.437.437v.437a.437.437 0 1 1-.875 0v-.437c0-.242.196-.438.438-.438m2.187.437a.438.438 0 0 0-.875 0v.437a.437.437 0 1 0 .875 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCityViewCitySkylineHighFloorOutline.displayName = 'CityViewCitySkylineHighFloorOutline';
const Memo = memo(SvgCityViewCitySkylineHighFloorOutline);
export default Memo;
