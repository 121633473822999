// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble6MessageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.94 3.578C4.181 2.537 5.931 2 8 2s3.818.537 5.06 1.578C14.315 4.628 15 6.148 15 8s-.686 3.373-1.94 4.422C11.819 13.463 10.069 14 8 14c-1.129 0-2.38-.108-3.49-.615q-.022.014-.045.027c-.186.107-.452.241-.77.352-.627.219-1.521.366-2.41-.057a.5.5 0 0 1-.184-.753c.962-1.275.676-2.054.664-2.086q.001 0-.004-.01l-.005-.013-.008-.018a15 15 0 0 1-.405-1.11C1.176 9.196 1 8.53 1 8c0-1.851.686-3.372 1.94-4.422m1.242 8.832a.5.5 0 0 1 .53-.04c.951.503 2.113.63 3.288.63 1.905 0 3.404-.495 4.419-1.344C13.422 10.815 14 9.586 14 8s-.578-2.815-1.582-3.656C11.405 3.494 9.906 3 8 3s-3.404.495-4.418 1.344C2.578 5.185 2 6.414 2 8c0 .361.13.897.295 1.413.16.5.331.92.376 1.03l.002.003.031.077c.104.285.312 1.184-.412 2.443.374.04.745-.031 1.074-.146a3.6 3.6 0 0 0 .768-.377l.04-.028zm0 0h.001"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble6MessageOutline.displayName = 'Bubble6MessageOutline';
const Memo = memo(SvgBubble6MessageOutline);
export default Memo;
