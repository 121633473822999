// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFitnessOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.093 2a4 4 0 0 0-1.495.29 3.9 3.9 0 0 0-1.267.824L8 3.436l-.331-.322A3.96 3.96 0 0 0 4.907 2c-1.037 0-2.03.4-2.763 1.114A3.75 3.75 0 0 0 1 5.802 3.75 3.75 0 0 0 2.144 8.49l5.524 5.376c.183.178.48.178.663 0l5.525-5.376c.362-.353.65-.772.847-1.233a3.71 3.71 0 0 0 0-2.91 3.8 3.8 0 0 0-.847-1.233 3.9 3.9 0 0 0-1.268-.824A4 4 0 0 0 11.093 2m1.136 1.132c.36.146.688.358.964.627.275.268.494.587.643.937a2.82 2.82 0 0 1-.003 2.22.5.5 0 0 0-.21-.05H10.5a.48.48 0 0 0-.349.152L8.867 8.406l-2.165-3.16a.475.475 0 0 0-.738-.052L4.416 6.866H2.375a.5.5 0 0 0-.209.047 2.8 2.8 0 0 1-.229-1.112c0-.766.313-1.501.87-2.043a3 3 0 0 1 2.1-.846c.787 0 1.542.304 2.099.846l.662.645c.183.178.48.178.663 0l.663-.645c.275-.269.603-.481.963-.627a3.04 3.04 0 0 1 2.272 0M2.741 7.78l.066.066L8 12.9l5.193-5.054.066-.066h-2.552L9.16 9.453a.475.475 0 0 1-.738-.053L6.257 6.24 4.973 7.628a.48.48 0 0 1-.348.151z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFitnessOutline.displayName = 'FitnessOutline';
const Memo = memo(SvgFitnessOutline);
export default Memo;
