// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChart6StatisticsGraph4Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.668 2a1 1 0 0 0-1 1v10h-1V6.667a1 1 0 0 0-1-1H7.335a1 1 0 0 0-1 1V13h-1v-2.666a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1V13h-.833a.5.5 0 1 0 0 1h13.667a.5.5 0 1 0 0-1H14V3a1 1 0 0 0-1-1z" />
    </Svg>
  );
};
SvgChart6StatisticsGraph4Fill.displayName = 'Chart6StatisticsGraph4Fill';
const Memo = memo(SvgChart6StatisticsGraph4Fill);
export default Memo;
