// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDossierOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833V6.333h-7.5a.5.5 0 0 1-.5-.5V3.667zm3.5 0v1.666h3V3.667zm4 0v1.666h3V4.5a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDossierOutline.displayName = 'DossierOutline';
const Memo = memo(SvgDossierOutline);
export default Memo;
