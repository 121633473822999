// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSignaturePencilPencilPenWriteDrawFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.036 2.422a1.833 1.833 0 0 1 2.592 0l.281.281a1.833 1.833 0 0 1 0 2.593l-8.166 8.167c-.344.344-.81.537-1.297.537H1.832a.5.5 0 0 1-.5-.5v-1.615c0-.486.193-.952.537-1.296zM14.326 11.622a.5.5 0 0 0-.706.051l-.004.005-.023.025-.098.1a4 4 0 0 1-.364.31c-.317.235-.682.417-1.022.417-.354 0-.694-.169-1.165-.418l-.046-.025c-.418-.222-.96-.51-1.575-.51-1.187 0-1.88.567-2.521 1.246a.5.5 0 1 0 .727.687c.571-.605 1.017-.933 1.794-.933.344 0 .678.166 1.152.418l.036.019c.422.224.97.516 1.598.516.661 0 1.24-.334 1.618-.614a4.6 4.6 0 0 0 .634-.57l.01-.013.004-.004.001-.001.001-.001L14 12l.378.327a.5.5 0 0 0-.052-.705" />
    </Svg>
  );
};
SvgSignaturePencilPencilPenWriteDrawFill.displayName = 'SignaturePencilPencilPenWriteDrawFill';
const Memo = memo(SvgSignaturePencilPencilPenWriteDrawFill);
export default Memo;
