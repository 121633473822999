// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBookmarkBannerFlagTagOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.888 1h6.224c.412 0 .748 0 1.02.02.28.021.53.066.766.176.37.173.68.452.876.807.128.23.18.477.203.743.023.255.023.566.023.94v9.083c0 .43 0 .786-.026 1.06-.025.261-.08.569-.295.792a1.26 1.26 0 0 1-1.038.372c-.292-.03-.539-.206-.748-.384-.216-.184-.476-.446-.793-.767l-2.52-2.55a7 7 0 0 0-.348-.338.4.4 0 0 0-.12-.082.37.37 0 0 0-.224 0 .4.4 0 0 0-.12.082 7 7 0 0 0-.347.338L4.9 13.842c-.317.32-.576.583-.793.767-.21.178-.456.354-.748.384a1.26 1.26 0 0 1-1.038-.372c-.214-.223-.27-.531-.295-.793C2 13.555 2 13.198 2 12.768V3.688c0-.375 0-.686.023-.941.023-.266.075-.513.203-.743.197-.355.506-.634.876-.807.235-.11.487-.155.767-.176C4.14 1 4.476 1 4.889 1m-.951.951c-.23.017-.356.05-.448.092a1 1 0 0 0-.459.417c-.038.07-.07.169-.088.37-.018.207-.019.477-.019.878v9.036c0 .46 0 .772.022.995.016.171.04.229.045.238.061.06.162.1.274.088.006-.002.073-.02.25-.17.181-.155.412-.388.75-.729L6.78 10.62c.147-.148.276-.28.393-.379.124-.106.264-.203.438-.259.253-.08.526-.08.778 0 .174.056.314.153.438.26.117.099.246.23.393.378l2.517 2.546c.337.34.568.574.75.729.176.15.243.168.249.17a.35.35 0 0 0 .274-.088c.004-.01.029-.067.045-.238.021-.223.022-.534.022-.995V3.708c0-.4 0-.67-.019-.878-.018-.201-.05-.3-.088-.37a1 1 0 0 0-.46-.417c-.091-.043-.217-.075-.447-.092a14 14 0 0 0-.97-.018H4.907c-.435 0-.736 0-.97.018"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBookmarkBannerFlagTagOutline.displayName = 'BookmarkBannerFlagTagOutline';
const Memo = memo(SvgBookmarkBannerFlagTagOutline);
export default Memo;
