import React, {type FC, useCallback} from 'react';
import {StyleSheet, View} from 'react-native';

import {useHive, withStaticProperties} from '@propertyguru/hive';
import {Surface} from '@propertyguru/hive-deprecated-surface';
import {Icon} from '@propertyguru/hive-icon';
import {Text} from '@propertyguru/hive-text';

import {getColorBadge} from './getColorBadge';
import type {BadgeContentProps, BadgeProps} from './types';

const MAX_LABEL_LIMIT = 30;
const MAX_LABEL_LENGTH = 27;
const ICON_SIZE = 24;
const MAX_COUNT_BADGE_LIMIT = 99;

const formatLabel = (label: string) => {
  if (label?.length > MAX_LABEL_LIMIT) {
    return `${label.substring(0, MAX_LABEL_LENGTH)}...`;
  }

  return label;
};

const BadgeContent = ({
  icon,
  labelText,
  iconColor,
  textColor,
  iconSize,
  size,
}: BadgeContentProps) => {
  const hive = useHive();
  const x1 = hive.spacing('x1');
  return (
    <>
      {!!icon && (
        <View style={[styles.icon, {marginRight: x1}]}>
          <Icon
            icon={icon}
            color={iconColor}
            width={iconSize}
            height={iconSize}
          />
        </View>
      )}
      <Text
        typography={size === 'default' ? 'body/xs' : 'caption/xs'}
        color={textColor}
      >
        {labelText}
      </Text>
    </>
  );
};

const Count: FC<{count: number}> = ({count}) => {
  const hive = useHive();

  // If count is more than 99, show 99+
  const getCount = useCallback(() => {
    if (count > MAX_COUNT_BADGE_LIMIT) {
      return `${MAX_COUNT_BADGE_LIMIT}+`;
    }
    return count.toString();
  }, [count]);

  return (
    <View
      style={[
        {
          backgroundColor: hive.color('fill/active/primary'),
          borderRadius: hive.borderRadius('full'),
          paddingHorizontal: hive.spacing('x1'),
          minWidth: hive.spacing('x4'),
        },
        styles.count,
      ]}
    >
      <Text
        typography={'caption/s'}
        color={'text/static/primary'}
      >
        {getCount()}
      </Text>
    </View>
  );
};

const Dot = () => {
  const hive = useHive();
  return (
    <View
      style={{
        backgroundColor: hive.color('fill/active/primary'),
        borderRadius: hive.borderRadius('full'),
        width: hive.spacing('x2'),
        height: hive.spacing('x2'),
      }}
    />
  );
};

export const BaseBadge = ({
  label,
  type = 'primary',
  disabled,
  color = 'neutral',
  icon,
  style,
  iconSize = ICON_SIZE,
  size = 'default',
}: BadgeProps) => {
  const hive = useHive();

  const {backgroundColor, borderColor, iconColor, textColor} = getColorBadge(
    type,
    color,
    size,
    disabled,
  );

  const labelText = formatLabel(label);

  return (
    <Surface
      color={backgroundColor}
      borderColor={borderColor}
      borderRadius={'small'}
      style={[
        styles.badge,
        {padding: hive.spacing('x2')},
        size === 'small' && {paddingVertical: hive.spacing('x1')},
        style,
      ]}
    >
      <BadgeContent
        icon={icon}
        labelText={labelText}
        iconColor={iconColor}
        textColor={textColor}
        iconSize={iconSize}
        size={size}
      />
    </Surface>
  );
};

export const Badge = withStaticProperties(BaseBadge, {
  Count,
  Dot,
});

const styles = StyleSheet.create({
  badge: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
  },
  icon: {
    justifyContent: 'center',
  },
  count: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
