// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRepeatRetweetOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.313 1.48a.5.5 0 0 1 .707 0l1.647 1.646a1 1 0 0 1 0 1.415L12.02 6.187a.5.5 0 1 1-.707-.707l1.147-1.147H3.833A.833.833 0 0 0 3 5.167v1.667a.5.5 0 0 1-1 0V5.167c0-1.013.82-1.833 1.833-1.833h8.627l-1.147-1.147a.5.5 0 0 1 0-.707M13.5 8.334a.5.5 0 0 1 .5.5v2c0 1.012-.82 1.833-1.833 1.833H3.54l1.147 1.146a.5.5 0 1 1-.707.707l-1.647-1.646a1 1 0 0 1 0-1.414L3.98 9.813a.5.5 0 1 1 .707.707L3.54 11.667h8.627c.46 0 .833-.373.833-.833v-2a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRepeatRetweetOutline.displayName = 'RepeatRetweetOutline';
const Memo = memo(SvgRepeatRetweetOutline);
export default Memo;
