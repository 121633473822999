// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShieldCrossedSecurityProtectionFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.42 1.5a1.83 1.83 0 0 1 1.16 0l4.166 1.389c.749.25 1.254.95 1.254 1.739v3.314c0 1.825-.789 3.172-1.906 4.231-1.098 1.042-2.534 1.825-3.857 2.54a.5.5 0 0 1-.474 0c-1.323-.715-2.76-1.498-3.857-2.54C2.789 11.113 2 9.767 2 7.942V4.628c0-.79.505-1.49 1.254-1.74zm1.71 8.019a.5.5 0 0 0 .708-.705l-1.14-1.147 1.14-1.147a.5.5 0 1 0-.709-.706L7.992 6.958 6.854 5.814a.5.5 0 0 0-.708.705l1.14 1.148-1.14 1.147a.5.5 0 1 0 .708.705l1.138-1.143z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShieldCrossedSecurityProtectionFill.displayName = 'ShieldCrossedSecurityProtectionFill';
const Memo = memo(SvgShieldCrossedSecurityProtectionFill);
export default Memo;
