// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStopwatchTrackFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.5 1a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
      <Path
        fillRule="evenodd"
        d="M14 8.667a6 6 0 1 1-12 0 6 6 0 0 1 12 0M5.646 7.02a.5.5 0 1 1 .708-.707l2 2a.5.5 0 1 1-.708.707z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStopwatchTrackFill.displayName = 'StopwatchTrackFill';
const Memo = memo(SvgStopwatchTrackFill);
export default Memo;
