// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFistbumpBoomHandsFriendsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.002 2a.5.5 0 0 1 .5.5v1.667a.5.5 0 1 1-1 0V2.5a.5.5 0 0 1 .5-.5M4.14 3.404a.5.5 0 0 1 .704.061l1.072 1.277a.5.5 0 1 1-.766.643L4.079 4.108a.5.5 0 0 1 .062-.704m7.714 0a.5.5 0 0 1 .061.704l-1.07 1.277a.5.5 0 0 1-.767-.643l1.071-1.277a.5.5 0 0 1 .705-.061M3.482 7a.47.47 0 0 0-.435.29l-.264.633A1.75 1.75 0 0 1 1.168 9a.5.5 0 0 1 0-1 .75.75 0 0 0 .692-.462l.264-.633a1.47 1.47 0 0 1 2.581-.25l.172.258A.2.2 0 0 0 5.041 7a2.59 2.59 0 0 1 2.577 2.83l-.108 1.151a2.594 2.594 0 0 1-2.582 2.352h-3.76a.5.5 0 1 1 0-1h3.76c.822 0 1.51-.626 1.587-1.445l.108-1.15A1.59 1.59 0 0 0 5.04 8c-.4 0-.774-.2-.996-.533l.416-.277-.416.277-.172-.257A.47.47 0 0 0 3.482 7m7.777-.345a1.47 1.47 0 0 1 2.581.25l.264.633a.75.75 0 0 0 .693.462.5.5 0 0 1 0 1 1.75 1.75 0 0 1-1.616-1.077l-.264-.633a.47.47 0 0 0-.826-.08l-.172.257a1.2 1.2 0 0 1-.995.533c-.936 0-1.67.805-1.582 1.737l.108 1.15c.076.82.764 1.446 1.587 1.446h3.76a.5.5 0 0 1 0 1h-3.76a2.594 2.594 0 0 1-2.583-2.352l-.108-1.15A2.59 2.59 0 0 1 10.924 7a.2.2 0 0 0 .163-.087z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFistbumpBoomHandsFriendsOutline.displayName = 'FistbumpBoomHandsFriendsOutline';
const Memo = memo(SvgFistbumpBoomHandsFriendsOutline);
export default Memo;
