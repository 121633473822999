// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgZaloColour: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.997 12V2.276l-.403-.27a4.66 4.66 0 0 0-2.93 4.327v3.334a4.667 4.667 0 0 0 4.667 4.666h3.333a4.65 4.65 0 0 0 3.806-1.977V12z" />
      <Path d="M9.664 1.667H6.331c-.615 0-1.2.122-1.738.338-1.111 1.078-1.929 2.838-1.929 4.328 0 2.257.312 3.579 1.237 4.87.072.1.12.217.126.34.014.278-.043.788-.545 1.247-.054.05-.02.14.053.143.314.014.948-.005 1.6-.292a.66.66 0 0 1 .576.027c1.104.587 2.397.665 3.62.665 1.558 0 3.113-.346 4.139-.972a4.64 4.64 0 0 0 .86-2.694V6.333a4.667 4.667 0 0 0-4.666-4.666" />
      <Path d="M12.25 9C11.561 9 11 8.439 11 7.75s.561-1.25 1.25-1.25 1.25.561 1.25 1.25S12.939 9 12.25 9m0-2a.751.751 0 0 0 0 1.5.751.751 0 0 0 0-1.5M10.5 9h-.333A.167.167 0 0 1 10 8.833V6h.5zM9 6.583v.173a1.24 1.24 0 0 0-.75-.256C7.561 6.5 7 7.061 7 7.75S7.561 9 8.25 9c.282 0 .54-.098.75-.256v.09c0 .091.075.166.167.166H9.5V6.583zM8.25 8.5a.751.751 0 0 1 0-1.5.751.751 0 0 1 0 1.5" />
      <Path d="M7.083 6H4.417v.5H6.19L4.333 8.667h.009a.4.4 0 0 0-.092.25V9h2.5a.167.167 0 0 0 .167-.167V8.5H5.143L7 6.333h-.009a.4.4 0 0 0 .092-.25z" />
    </Svg>
  );
};
SvgZaloColour.displayName = 'ZaloColour';
const Memo = memo(SvgZaloColour);
export default Memo;
