// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLightningZapFlashOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.22 2.333a.17.17 0 0 0-.145.084L3.008 7.75a.167.167 0 0 0 .145.25h1.962c.738 0 1.29.677 1.143 1.4l-.823 4.026a.16.16 0 0 0 .01.107.18.18 0 0 0 .074.073q.055.028.102.022a.16.16 0 0 0 .095-.05l7.293-7.293A.167.167 0 0 0 12.89 6h-1.645a1.167 1.167 0 0 1-1.044-1.688l.869-1.737a.167.167 0 0 0-.15-.242zm-1.012-.415a1.17 1.17 0 0 1 1.011-.585h4.703c.867 0 1.431.913 1.043 1.689l-.868 1.737a.167.167 0 0 0 .149.241h1.645c1.04 0 1.56 1.257.825 1.992l-7.293 7.292c-.818.818-2.2.075-1.968-1.058L5.278 9.2a.167.167 0 0 0-.163-.2H3.153c-.897 0-1.459-.97-1.012-1.748zl.42.242z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLightningZapFlashOutline.displayName = 'LightningZapFlashOutline';
const Memo = memo(SvgLightningZapFlashOutline);
export default Memo;
