// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRubberDuckDuckOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.91 2.005a3.75 3.75 0 0 1 2.242.599 3.5 3.5 0 0 1 1.401 1.778c.25.724.245 1.506-.016 2.226-.13.36-.321.695-.562.992h1.666a2 2 0 0 0 .83-.186c.26-.12.488-.293.67-.507l.001-.002c.126-.147.293-.255.482-.31.189-.057.391-.06.581-.007.19.052.36.156.49.3a.95.95 0 0 1 .237.507v.007c.138 1.152.124 2.798-.72 4.17-.868 1.411-2.55 2.434-5.524 2.428-1.35-.003-2.629-.274-3.587-.834-.972-.567-1.624-1.443-1.624-2.604 0-.603.194-1.192.554-1.687.208-.285.466-.532.76-.73l-.006-.005h-1.89c-.617 0-1.104-.307-1.421-.729A2.5 2.5 0 0 1 1 5.927c0-.228.192-.414.43-.418a2.76 2.76 0 0 0 1.472-.449c.353-.23.641-.539.843-.896.218-.512.56-.97 1.004-1.332a3.72 3.72 0 0 1 2.162-.827M5.22 7.39q.027.021.053.047.259.221.572.377a.415.415 0 0 1 .236.397.42.42 0 0 1-.284.367c-.424.15-.79.421-1.048.776a2.06 2.06 0 0 0-.397 1.209c0 .81.437 1.441 1.205 1.889.78.456 1.889.708 3.133.711 2.738.005 4.093-.918 4.768-2.015.698-1.136.735-2.558.606-3.647a.13.13 0 0 0-.032-.068.146.146 0 0 0-.213.002c-.263.31-.593.56-.968.732a2.9 2.9 0 0 1-1.203.27l-.004-.419v.419H8.868a.44.44 0 0 1-.419-.298.41.41 0 0 1 .178-.47c.504-.319.885-.788 1.083-1.335s.202-1.14.012-1.69A2.65 2.65 0 0 0 8.66 3.296a2.85 2.85 0 0 0-1.701-.454 2.82 2.82 0 0 0-1.64.628 2.7 2.7 0 0 0-.637.755q-.063.15-.14.292-.08.196-.127.404A2.53 2.53 0 0 0 4.617 6.6c.149.3.353.567.602.791m-1.393-.433c-.198-.4-.313-.83-.342-1.266l-.088.06a3.65 3.65 0 0 1-1.482.558c.05.234.143.446.268.613.187.247.429.38.713.38h1.128a3 3 0 0 1-.197-.345m2.72-2.982c-.483 0-.875.374-.875.837s.392.837.875.837.875-.375.875-.837c0-.463-.392-.837-.875-.837"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRubberDuckDuckOutline.displayName = 'RubberDuckDuckOutline';
const Memo = memo(SvgRubberDuckDuckOutline);
export default Memo;
