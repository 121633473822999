// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile2DocumentsCopyOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.668 3.167c0-1.013.82-1.834 1.833-1.834h3.667a.5.5 0 0 1 .354.147l3.333 3.333a.5.5 0 0 1 .146.354v5.666c0 1.013-.82 1.834-1.833 1.834h-.167v.166c0 1.013-.82 1.834-1.833 1.834H4.501a1.833 1.833 0 0 1-1.833-1.834V5.167c0-1.013.82-1.834 1.833-1.834h.167zm0 1.166h-.167a.833.833 0 0 0-.833.834v7.666c0 .46.373.834.833.834h5.667c.46 0 .833-.373.833-.834v-.166h-4.5a1.833 1.833 0 0 1-1.833-1.834zm1.833 7.334a.833.833 0 0 1-.833-.834V3.167c0-.46.373-.834.833-.834h3.167v1.5c0 1.013.82 1.834 1.833 1.834h1.5v5.166c0 .46-.373.834-.833.834zm5.793-7h-.793a.833.833 0 0 1-.833-.834V3.04z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFile2DocumentsCopyOutline.displayName = 'File2DocumentsCopyOutline';
const Memo = memo(SvgFile2DocumentsCopyOutline);
export default Memo;
