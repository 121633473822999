// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudyCloudsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.996 4.187a4.6 4.6 0 0 0-1.049-.12c-2.55 0-4.611 2.077-4.611 4.633s2.062 4.633 4.611 4.633h5.139a3.59 3.59 0 0 0 3.583-3.6 3.6 3.6 0 0 0-1.693-3.059c.207-.429.323-.91.323-1.418A3.25 3.25 0 0 0 10.059 2c-1.42 0-2.623.915-3.063 2.187m.943.333a4.64 4.64 0 0 1 1.814 1.564.44.44 0 0 0 .459.157 3.6 3.6 0 0 1 1.849.027c.152-.304.238-.648.238-1.012A2.25 2.25 0 0 0 10.059 3c-.98 0-1.817.634-2.12 1.52"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudyCloudsFill.displayName = 'CloudyCloudsFill';
const Memo = memo(SvgCloudyCloudsFill);
export default Memo;
