// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFastForwardSymbolOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.943 4.322a.167.167 0 0 0-.275.126v7.103c0 .143.167.22.275.127l4.22-3.614v-.128zm4.22 2.297-3.57-3.057c-.756-.648-1.925-.11-1.925.886v7.103c0 .997 1.169 1.534 1.926.886L8.163 9.38v2.171c0 .997 1.169 1.534 1.925.886l4.147-3.551a1.167 1.167 0 0 0 0-1.772L10.09 3.562c-.757-.648-1.926-.11-1.926.886zm1.275-2.297a.167.167 0 0 0-.275.126v7.103c0 .143.167.22.275.127l4.147-3.552a.167.167 0 0 0 0-.253z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFastForwardSymbolOutline.displayName = 'FastForwardSymbolOutline';
const Memo = memo(SvgFastForwardSymbolOutline);
export default Memo;
