// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSitePlanSitePlanOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.58 1.578a.83.83 0 0 1 .59-.245h11.666a.833.833 0 0 1 .833.834v1.667a.833.833 0 0 1-.833.833H4.669v9.167a.833.833 0 0 1-.833.833H2.169a.834.834 0 0 1-.833-.833V2.167c0-.221.088-.433.244-.59m.59 3.089v9.167h1.666v-2.5h-.417a.417.417 0 0 1 0-.834h.417V8h-.417a.417.417 0 1 1 0-.833h.417v-2.5zm2.5-.833h2.5v-.417a.417.417 0 0 1 .833 0v.417h2.5v-.417a.417.417 0 0 1 .833 0v.417h2.5V2.167H4.669zm-.834-1.667v1.667H2.169V2.167zm8.75 3.75a.417.417 0 1 0-.833 0v.833h-2.5a.833.833 0 0 0-.834.833V9.25H7.03a.694.694 0 0 0-.694.695v3.889h-.417a.417.417 0 1 0 0 .833h8.334a.417.417 0 1 0 0-.833h-.417v-6.25a.834.834 0 0 0-.833-.834h-.417zm-.417 1.667H9.253V9.25h.972a.695.695 0 0 1 .694.695v3.889h2.084v-6.25zm-5 6.25v-3.75h2.917v3.75z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSitePlanSitePlanOutline.displayName = 'SitePlanSitePlanOutline';
const Memo = memo(SvgSitePlanSitePlanOutline);
export default Memo;
