// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPlusAddDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.501 1.334c-1.012 0-1.833.82-1.833 1.833v9.667c0 1.012.82 1.833 1.833 1.833h6.252a1.5 1.5 0 0 1-.085-.5v-.5h-.5a1.5 1.5 0 1 1 0-3h.5v-.5a1.5 1.5 0 0 1 2.667-.943V3.167c0-1.013-.821-1.833-1.834-1.833z" />
      <Path d="M12.668 10.167a.5.5 0 0 0-1 0v1.5h-1.5a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 1 0 1 0v-1.5h1.5a.5.5 0 1 0 0-1h-1.5z" />
    </Svg>
  );
};
SvgPlusAddDocumentListPageFileFill.displayName = 'PlusAddDocumentListPageFileFill';
const Memo = memo(SvgPlusAddDocumentListPageFileFill);
export default Memo;
