// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPostcardCardNewsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm8.167 1.25a1.75 1.75 0 0 0-1.75 1.75v1c0 .966.783 1.75 1.75 1.75h1a1.75 1.75 0 0 0 1.75-1.75v-1a1.75 1.75 0 0 0-1.75-1.75zm-.25 1.75a.25.25 0 0 1 .25-.25h1a.25.25 0 0 1 .25.25v1a.25.25 0 0 1-.25.25h-1a.25.25 0 0 1-.25-.25zm-4.75-1.583a.75.75 0 1 0 0 1.5h1.666a.75.75 0 1 0 0-1.5zm0 2.666a.75.75 0 1 0 0 1.5h1.666a.75.75 0 0 0 0-1.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPostcardCardNewsFill.displayName = 'PostcardCardNewsFill';
const Memo = memo(SvgPostcardCardNewsFill);
export default Memo;
