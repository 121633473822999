// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgThumbsUpThumbHandYesProOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.701 1.966c.2-.39.602-.633 1.038-.633A1.836 1.836 0 0 1 9.554 3.45L9.26 5.333h2.911a2.5 2.5 0 0 1 2.472 2.876l-.558 3.667A2.5 2.5 0 0 1 11.613 14H2.5a1.167 1.167 0 0 1-1.167-1.167v-5c0-.644.522-1.166 1.167-1.166h1.78zM4 7.666h-1.5a.167.167 0 0 0-.167.167v5c0 .092.075.167.167.167h1.5zm1 5.334h6.614a1.5 1.5 0 0 0 1.483-1.274l.558-3.667a1.5 1.5 0 0 0-1.483-1.726H8.676a.5.5 0 0 1-.494-.577l.384-2.461a.836.836 0 0 0-.827-.962.17.17 0 0 0-.149.09L5 7.456z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgThumbsUpThumbHandYesProOutline.displayName = 'ThumbsUpThumbHandYesProOutline';
const Memo = memo(SvgThumbsUpThumbHandYesProOutline);
export default Memo;
