// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPinchFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m7.593 5.478 3.253-1.948c1.078-.645 2.456-.262 3.079.856l.687 1.234c1.487 2.672.605 6.089-1.972 7.632a5.22 5.22 0 0 1-5.568-.113l-3.714-2.43a.67.67 0 0 1-.295-.655l.074-.503c.125-.851.891-1.436 1.712-1.306l.892.14-2.254-4.048c-.415-.745-.168-1.698.55-2.128s1.638-.175 2.053.57z" />
      <Path
        fillRule="evenodd"
        d="M1.689 4.611a.5.5 0 0 1 .287.646c-.41 1.07-.41 2.01 0 3.08a.5.5 0 0 1-.933.358c-.5-1.301-.5-2.495 0-3.796a.5.5 0 0 1 .646-.288"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPinchFill.displayName = 'PinchFill';
const Memo = memo(SvgPinchFill);
export default Memo;
