// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCamera1PictureImageCamFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.52 2.816C5.86 2.306 6.433 2 7.046 2h1.906c.613 0 1.185.306 1.525.816l.542.813a.83.83 0 0 0 .693.371h1.12c1.013 0 1.833.82 1.833 1.833v6.334c0 1.012-.82 1.833-1.833 1.833H3.165a1.833 1.833 0 0 1-1.833-1.833V5.833C1.332 4.821 2.152 4 3.165 4h1.12c.279 0 .539-.14.693-.371zM8 6a2.333 2.333 0 1 0 0 4.667 2.333 2.333 0 0 0 0-4.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCamera1PictureImageCamFill.displayName = 'Camera1PictureImageCamFill';
const Memo = memo(SvgCamera1PictureImageCamFill);
export default Memo;
