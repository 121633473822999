// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSearchMagnifyingGlassFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.333 10.667a3.333 3.333 0 1 0 0-6.667 3.333 3.333 0 0 0 0 6.667" />
      <Path
        fillRule="evenodd"
        d="M7.333 3a4.333 4.333 0 1 0 0 8.667 4.333 4.333 0 0 0 0-8.667M2 7.333a5.333 5.333 0 1 1 9.442 3.402l2.412 2.411a.5.5 0 1 1-.708.707l-2.411-2.411A5.333 5.333 0 0 1 2 7.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSearchMagnifyingGlassFill.displayName = 'SearchMagnifyingGlassFill';
const Memo = memo(SvgSearchMagnifyingGlassFill);
export default Memo;
