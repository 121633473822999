// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClosedCaptioningCcFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.833 2C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h8.334C13.179 14 14 13.18 14 12.167V3.833C14 2.821 13.18 2 12.167 2zm3.033 5.066a1.167 1.167 0 1 0 0 1.868.5.5 0 0 1 .602.799 2.167 2.167 0 1 1 0-3.466.5.5 0 0 1-.602.8m2.809.109a1.17 1.17 0 0 1 1.525-.109.5.5 0 1 0 .6-.799 2.167 2.167 0 1 0 0 3.466.5.5 0 0 0-.6-.8 1.167 1.167 0 0 1-1.525-1.758"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClosedCaptioningCcFill.displayName = 'ClosedCaptioningCcFill';
const Memo = memo(SvgClosedCaptioningCcFill);
export default Memo;
