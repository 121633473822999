// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTennisRacketFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.187 1a2.187 2.187 0 1 0 0 4.375 2.187 2.187 0 0 0 0-4.375m4.44 1.506a5.3 5.3 0 0 0-.956 1.304 4.8 4.8 0 0 0-.538 1.88c-.026.367-.006.728.058 1.075l-.775 3.202-1.066 1.066-.31-.31a.437.437 0 0 0-.618 0L1.384 12.76a1.313 1.313 0 1 0 1.857 1.856l2.035-2.037a.437.437 0 0 0 0-.619l-.308-.307 1.069-1.069 3.192-.773q.527.098 1.083.06c1.172-.082 2.314-.625 3.185-1.495a5.3 5.3 0 0 0 .942-1.278 4.8 4.8 0 0 0 .552-1.906c.083-1.174-.304-2.288-1.098-3.082a3.7 3.7 0 0 0-1.206-.803 4.1 4.1 0 0 0-1.875-.295 4.8 4.8 0 0 0-1.951.576 5.3 5.3 0 0 0-1.233.92m4.08-.58a3.4 3.4 0 0 0-.834-.042 3.8 3.8 0 0 0-1.018.216l.838.839zm-1.632 1.631L9.022 2.505a4.45 4.45 0 0 0-1.396 1.397l1.052 1.052zm-.778 2.015 1.396-1.396 1.132 1.131-1.397 1.397zm1.75 1.75 1.396-1.396 1.054 1.054a4.45 4.45 0 0 1-1.396 1.396zm-2.369-1.13 1.132 1.13-1.313 1.313a2.8 2.8 0 0 1-1.131-1.131zm1.75 1.75L9.415 8.954q.402.071.836.042a3.8 3.8 0 0 0 1.018-.216zm3.648-3.649-1.014 1.014.84.841c.12-.333.193-.676.217-1.019q.03-.433-.043-.836m-1.633.396 1.314-1.314a2.8 2.8 0 0 0-1.132-1.13l-1.313 1.312zm-4.383.883-.839-.838a3.8 3.8 0 0 0-.215 1.018q-.03.432.042.832zM6.406 9.593l.343-1.416a3.7 3.7 0 0 0 1.072 1.074z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTennisRacketFill.displayName = 'TennisRacketFill';
const Memo = memo(SvgTennisRacketFill);
export default Memo;
