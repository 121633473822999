// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMaidRoomFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.977 1.441a.442.442 0 0 0-.883 0v8.686a2.16 2.16 0 0 0-1.695 1.843l-.317 2.534a.44.44 0 0 0 .438.496h4.038a.442.442 0 0 0 .438-.496l-.317-2.534a2.16 2.16 0 0 0-1.702-1.844zM6.4 4.88a2.208 2.208 0 1 0-2.879.004c-.81.435-1.362 1.223-1.729 2.067-.553 1.272-.744 2.784-.791 3.806a.927.927 0 0 0 .934.965h.853l.221 2.872c.018.23.21.407.44.407h3.03c.23 0 .423-.177.44-.407l.221-2.872h.853c.516 0 .96-.42.934-.965-.046-1.022-.238-2.534-.791-3.806-.368-.846-.922-1.636-1.736-2.07"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMaidRoomFill.displayName = 'MaidRoomFill';
const Memo = memo(SvgMaidRoomFill);
export default Memo;
