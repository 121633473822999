// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolder2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2h2.922c.477 0 .936.186 1.278.52l.594.577a.83.83 0 0 0 .581.236h4.292c1.013 0 1.833.821 1.833 1.834V6.2c-.25-.128-.533-.2-.833-.2H2.165c-.3 0-.583.072-.833.2zM1.332 7.833V11.5c0 1.012.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833V7.833A.833.833 0 0 0 13.832 7H2.165a.833.833 0 0 0-.833.833" />
    </Svg>
  );
};
SvgFolder2Fill.displayName = 'Folder2Fill';
const Memo = memo(SvgFolder2Fill);
export default Memo;
