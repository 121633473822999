// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDeleteRemoveGarbageWasteTrashCanOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.054 3.333H1.832a.5.5 0 0 0 0 1h.865l.556 8.618a1.833 1.833 0 0 0 1.83 1.716h5.832c.967 0 1.768-.751 1.83-1.716l.556-8.618h.864a.5.5 0 0 0 0-1h-3.222a3.167 3.167 0 0 0-5.889 0m1.119 0h3.652a2.166 2.166 0 0 0-3.652 0m4.397 1H3.7l.552 8.554c.028.438.392.78.831.78h5.833c.44 0 .804-.342.832-.78l.552-8.554H10.57M6.5 6.667a.5.5 0 0 1 .5.5v3.666a.5.5 0 0 1-1 0V7.167a.5.5 0 0 1 .5-.5m3 0a.5.5 0 0 1 .5.5v3.666a.5.5 0 0 1-1 0V7.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDeleteRemoveGarbageWasteTrashCanOutline.displayName = 'DeleteRemoveGarbageWasteTrashCanOutline';
const Memo = memo(SvgDeleteRemoveGarbageWasteTrashCanOutline);
export default Memo;
