// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNoFlashFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.331 1.57c0-.817-1.055-1.146-1.519-.473l-2.59 3.756-3.057-2.726a.5.5 0 0 0-.666.746l12.334 11a.5.5 0 0 0 .665-.746l-3.15-2.81 2.535-3.677a.833.833 0 0 0-.686-1.307H9.33zM2.114 9.36l1.964-2.848 6.125 5.463-2.019 2.928c-.464.673-1.52.344-1.52-.473v-3.763H2.8a.833.833 0 0 1-.686-1.307" />
    </Svg>
  );
};
SvgNoFlashFill.displayName = 'NoFlashFill';
const Memo = memo(SvgNoFlashFill);
export default Memo;
