// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChart5StatisticsGraph3Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.333 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h1.334a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM3 8.667a1 1 0 0 0-1 1v3.334a1 1 0 0 0 1 1h1.333a1 1 0 0 0 1-1V9.667a1 1 0 0 0-1-1zM10.667 6.334a1 1 0 0 1 1-1H13a1 1 0 0 1 1 1V13a1 1 0 0 1-1 1h-1.333a1 1 0 0 1-1-1z" />
    </Svg>
  );
};
SvgChart5StatisticsGraph3Fill.displayName = 'Chart5StatisticsGraph3Fill';
const Memo = memo(SvgChart5StatisticsGraph3Fill);
export default Memo;
