// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFlag2PriorityOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.93 2.333c-.84 0-1.71.29-2.197.484-.03.012-.065.048-.065.116V9.31q.1-.036.208-.072c.544-.18 1.291-.368 2.054-.368.806 0 1.588.388 2.241.712l.052.025c.721.357 1.302.634 1.85.634.839 0 1.709-.29 2.196-.484.03-.012.066-.048.066-.116v-6.05c0-.057-.029-.12-.102-.169a.3.3 0 0 0-.25-.04c-.525.163-1.21.322-1.91.322-.806 0-1.589-.388-2.241-.712l-.053-.025c-.72-.357-1.302-.634-1.85-.634m-2.262 8.048c.136-.056.314-.125.522-.193.494-.164 1.128-.318 1.74-.318.547 0 1.128.277 1.85.634l.052.025c.652.324 1.435.712 2.24.712 1.033 0 2.045-.346 2.57-.556.44-.177.693-.604.693-1.044v-6.05c0-.878-.902-1.397-1.649-1.164-.475.148-1.053.277-1.613.277-.548 0-1.129-.277-1.85-.634l-.052-.025c-.653-.324-1.435-.712-2.241-.712-1.032 0-2.044.346-2.569.556a1.12 1.12 0 0 0-.693 1.044v11.234a.5.5 0 0 0 1 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFlag2PriorityOutline.displayName = 'Flag2PriorityOutline';
const Memo = memo(SvgFlag2PriorityOutline);
export default Memo;
