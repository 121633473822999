// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgContrastOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 13.667a5.667 5.667 0 1 0 0-11.334zM14.665 8A6.667 6.667 0 1 1 1.332 8a6.667 6.667 0 0 1 13.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgContrastOutline.displayName = 'ContrastOutline';
const Memo = memo(SvgContrastOutline);
export default Memo;
