// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderCloud1Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2h2.787c.613 0 1.185.306 1.525.816l.542.813A.83.83 0 0 0 8.712 4h4.12c1.013 0 1.833.82 1.833 1.833V11.5c0 1.012-.82 1.833-1.833 1.833H8.499a.5.5 0 1 1 0-1h4.333c.46 0 .833-.373.833-.833V5.833A.833.833 0 0 0 12.832 5h-4.12a1.83 1.83 0 0 1-1.525-.816l-.542-.813A.83.83 0 0 0 5.952 3H3.165a.833.833 0 0 0-.833.833V7.5a.5.5 0 0 1-1 0z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M3.168 10a1.5 1.5 0 0 0 0 3h1.833a1 1 0 1 0-.066-1.998.5.5 0 0 1-.466-.25A1.5 1.5 0 0 0 3.168 10m-2.5 1.5a2.5 2.5 0 0 1 4.505-1.493A2 2 0 0 1 5.001 14H3.168a2.5 2.5 0 0 1-2.5-2.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolderCloud1Outline.displayName = 'FolderCloud1Outline';
const Memo = memo(SvgFolderCloud1Outline);
export default Memo;
