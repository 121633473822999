// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShieldBreakNotSecureOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.285 1.26a1.83 1.83 0 0 1 1.432 0l4.167 1.768a1.83 1.83 0 0 1 1.117 1.688v3.95q-.001.661-.137 1.282a.5.5 0 1 1-.977-.213q.113-.517.114-1.068v-3.95c0-.335-.2-.637-.508-.768L8.327 2.18a.83.83 0 0 0-.651 0L4.863 3.374a.5.5 0 0 1-.39-.92zm-6.507.927a.5.5 0 0 1 .703-.076l13.667 11a.5.5 0 0 1-.627.778l-1.833-1.475A6 6 0 0 1 2.002 8.667V3.813L.854 2.89a.5.5 0 0 1-.076-.703m2.223 2.43v4.05a5 5 0 0 0 8.908 3.12z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShieldBreakNotSecureOutline.displayName = 'ShieldBreakNotSecureOutline';
const Memo = memo(SvgShieldBreakNotSecureOutline);
export default Memo;
