// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBellNotifyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 1.333a4.83 4.83 0 0 0-4.83 4.581l-.124 2.44a.8.8 0 0 1-.091.34l-.825 1.599A1.17 1.17 0 0 0 3.17 12h1.703a3.167 3.167 0 0 0 6.254 0h1.702a1.17 1.17 0 0 0 1.04-1.707l-.824-1.6a.8.8 0 0 1-.091-.34l-.124-2.439A4.83 4.83 0 0 0 8 1.334m0 12.334A2.17 2.17 0 0 1 5.891 12h4.218A2.17 2.17 0 0 1 8 13.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBellNotifyFill.displayName = 'BellNotifyFill';
const Memo = memo(SvgBellNotifyFill);
export default Memo;
