// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUnlockedUnlockPrivateOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.668 4.667a3.333 3.333 0 0 1 6.615-.585.5.5 0 1 1-.984.174 2.334 2.334 0 0 0-4.631.41V6h5.833c1.013 0 1.834.82 1.834 1.833v5c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834v-5C2.668 6.821 3.488 6 4.501 6h.167zM4.501 7a.833.833 0 0 0-.833.833v5c0 .46.373.834.833.834h7c.46 0 .834-.373.834-.834v-5A.833.833 0 0 0 11.5 7zm3.5 1.833a.5.5 0 0 1 .5.5v2a.5.5 0 1 1-1 0v-2a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUnlockedUnlockPrivateOutline.displayName = 'UnlockedUnlockPrivateOutline';
const Memo = memo(SvgUnlockedUnlockPrivateOutline);
export default Memo;
