// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNoteStickerOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v5.333c0 .487-.193.953-.537 1.297l-3 3c-.344.344-.81.537-1.297.537H3.833A1.833 1.833 0 0 1 2 12.167zM7.833 13c.46 0 .834-.373.834-.833V10.5c0-1.013.82-1.833 1.833-1.833h1.667c.46 0 .833-.373.833-.834v-4A.833.833 0 0 0 12.167 3H3.833A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833zm5.097-3.5a1.8 1.8 0 0 1-.763.167H10.5a.833.833 0 0 0-.833.833v1.667c0 .272-.06.53-.166.763q.142-.062.255-.174l3-3a.8.8 0 0 0 .174-.255"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgNoteStickerOutline.displayName = 'NoteStickerOutline';
const Memo = memo(SvgNoteStickerOutline);
export default Memo;
