// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFocusCameraExposureSquareFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.833 3A.833.833 0 0 0 3 3.833v1.5a.5.5 0 1 1-1 0v-1.5C2 2.821 2.82 2 3.833 2h1.5a.5.5 0 1 1 0 1zM10.167 2.5a.5.5 0 0 1 .5-.5h1.5C13.179 2 14 2.82 14 3.833v1.5a.5.5 0 0 1-1 0v-1.5A.833.833 0 0 0 12.167 3h-1.5a.5.5 0 0 1-.5-.5M2.5 10.167a.5.5 0 0 1 .5.5v1.5c0 .46.373.833.833.833h1.5a.5.5 0 0 1 0 1h-1.5A1.833 1.833 0 0 1 2 12.167v-1.5a.5.5 0 0 1 .5-.5M13.5 10.167a.5.5 0 0 1 .5.5v1.5C14 13.179 13.18 14 12.167 14h-1.5a.5.5 0 1 1 0-1h1.5c.46 0 .833-.373.833-.833v-1.5a.5.5 0 0 1 .5-.5M6.833 5.333a1.5 1.5 0 0 0-1.5 1.5v2.334a1.5 1.5 0 0 0 1.5 1.5h2.334a1.5 1.5 0 0 0 1.5-1.5V6.833a1.5 1.5 0 0 0-1.5-1.5z" />
    </Svg>
  );
};
SvgFocusCameraExposureSquareFill.displayName = 'FocusCameraExposureSquareFill';
const Memo = memo(SvgFocusCameraExposureSquareFill);
export default Memo;
