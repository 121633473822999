// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCodeLinesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.167a.5.5 0 0 1 .5-.5h6.967a.5.5 0 1 1 0 1H1.832a.5.5 0 0 1-.5-.5m10 0a.5.5 0 0 1 .5-.5h2.333a.5.5 0 0 1 0 1h-2.333a.5.5 0 0 1-.5-.5M1.332 8a.5.5 0 0 1 .5-.5h3.667a.5.5 0 0 1 0 1H1.832a.5.5 0 0 1-.5-.5m6.667 0a.5.5 0 0 1 .5-.5h5.666a.5.5 0 0 1 0 1H8.5a.5.5 0 0 1-.5-.5m-6.667 4.833a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m8 0a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H9.832a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCodeLinesOutline.displayName = 'CodeLinesOutline';
const Memo = memo(SvgCodeLinesOutline);
export default Memo;
