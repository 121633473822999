// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAccessibilityEyeA11YFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.188 1.48a.5.5 0 0 0-.707.707l2.112 2.112a9 9 0 0 0-1.348 1.35q-.433.534-.82 1.157l-.019.032c-.156.254-.284.46-.351.76-.052.233-.052.57 0 .804.068.3.195.506.352.76l.02.032c2.012 3.261 5.092 4.648 8.032 3.973a7 7 0 0 0 2.122-.88l2.233 2.233a.5.5 0 0 0 .707-.707l-2.112-2.112c.895-.723 1.705-1.681 2.38-2.866a1.69 1.69 0 0 0 0-1.67C13.106 4.213 10.587 2.667 8 2.667c-1.237 0-2.459.353-3.58 1.045zm4.494 4.494 3.345 3.345a2.417 2.417 0 0 0-3.344-3.344M3.314 5.94a8 8 0 0 1 .99-.928l6.547 6.547a6 6 0 0 1-1.351.567zm-.631.784 5.603 5.602c-2.167.112-4.41-1.065-6.009-3.656-.18-.293-.219-.364-.246-.486a1.1 1.1 0 0 1 0-.366c.027-.123.065-.193.246-.486q.196-.318.406-.609"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAccessibilityEyeA11YFill.displayName = 'AccessibilityEyeA11YFill';
const Memo = memo(SvgAccessibilityEyeA11YFill);
export default Memo;
