// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTwo4HrAccesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.336 8a6.667 6.667 0 0 1 12.49-3.246V3.195a.42.42 0 1 1 .842 0v2.403a.42.42 0 0 1-.42.42h-2.403a.42.42 0 1 1 0-.84H13.1a5.826 5.826 0 1 0 .572 4.168.42.42 0 1 1 .819.192A6.667 6.667 0 0 1 1.336 8M5.74 5.87c-.556 0-1.027.356-1.202.85a.42.42 0 0 0 .282.545l.409.79a1.27 1.27 0 0 0-.764 1.168v.486c0 .232.188.42.42.42h2.279a.42.42 0 1 0 0-.84H5.307v-.066c0-.172.102-.328.26-.397l1.268-.555a1.253 1.253 0 0 0-.502-2.4zm-.92 1.395a.42.42 0 0 0 .51-.264c.06-.17.222-.29.41-.29h.592a.412.412 0 0 1 .165.79l-1.268.554zm4.128 1.169 1.18-1.35v1.35zm2.021-1.947v1.947h.15a.42.42 0 1 1 0 .84h-.15v.435a.42.42 0 1 1-.84 0v-.434H8.479a.627.627 0 0 1-.472-1.041L9.89 6.081a.616.616 0 0 1 1.08.406"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTwo4HrAccesOutline.displayName = 'Two4HrAccesOutline';
const Memo = memo(SvgTwo4HrAccesOutline);
export default Memo;
