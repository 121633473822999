// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedRealityCardBox3DVirtualRealityVrFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.501 3.333c-1.012 0-1.833.821-1.833 1.834V11.5c0 1.012.82 1.833 1.833 1.833h2.257c.452 0 .887-.166 1.224-.468l1.908-1.71a.167.167 0 0 1 .223 0l1.908 1.71c.336.302.772.468 1.224.468H13.5c1.013 0 1.834-.82 1.834-1.833V5.167c0-1.013-.821-1.834-1.834-1.834zm2.5 2.917a1.417 1.417 0 1 0 0 2.833 1.417 1.417 0 0 0 0-2.833m4.584 1.417a1.417 1.417 0 1 1 2.833 0 1.417 1.417 0 0 1-2.833 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArAugmentedRealityCardBox3DVirtualRealityVrFill.displayName =
  'ArAugmentedRealityCardBox3DVirtualRealityVrFill';
const Memo = memo(SvgArAugmentedRealityCardBox3DVirtualRealityVrFill);
export default Memo;
