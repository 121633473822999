// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgThumbsUpThumbHandYesProFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.74 1.333c-.437 0-.838.244-1.039.633l-2.421 4.7H2.499c-.645 0-1.167.523-1.167 1.167v5c0 .645.522 1.167 1.167 1.167h9.114a2.5 2.5 0 0 0 2.472-2.124l.558-3.667a2.5 2.5 0 0 0-2.472-2.876h-2.91l.293-1.884a1.836 1.836 0 0 0-1.815-2.116M3.998 7.667V13h-1.5a.167.167 0 0 1-.167-.167v-5c0-.092.075-.166.167-.166z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgThumbsUpThumbHandYesProFill.displayName = 'ThumbsUpThumbHandYesProFill';
const Memo = memo(SvgThumbsUpThumbHandYesProFill);
export default Memo;
