// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble7TextMessageOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 5.833A3.833 3.833 0 0 1 5.165 2h5.667a3.833 3.833 0 0 1 3.833 3.833v4.334A3.833 3.833 0 0 1 10.832 14h-9a.5.5 0 0 1-.5-.5zM5.165 3a2.833 2.833 0 0 0-2.833 2.833V13h8.5a2.833 2.833 0 0 0 2.833-2.833V5.833A2.833 2.833 0 0 0 10.832 3zm.167 3.5a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H5.832a.5.5 0 0 1-.5-.5m0 3a.5.5 0 0 1 .5-.5h2.333a.5.5 0 1 1 0 1H5.832a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble7TextMessageOutline.displayName = 'Bubble7TextMessageOutline';
const Memo = memo(SvgBubble7TextMessageOutline);
export default Memo;
