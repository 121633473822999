// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBasket2ShoppingBagFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.963 2.686a.5.5 0 1 0-.929-.372l-1.207 3.02H2.624c-.724 0-1.273.652-1.15 1.366l1.006 5.78c.153.879.915 1.52 1.806 1.52h7.424c.891 0 1.653-.641 1.806-1.52l1.005-5.78a1.167 1.167 0 0 0-1.149-1.367h-1.201l-1.208-3.019a.5.5 0 1 0-.928.372l1.059 2.647h-6.19z" />
    </Svg>
  );
};
SvgBasket2ShoppingBagFill.displayName = 'Basket2ShoppingBagFill';
const Memo = memo(SvgBasket2ShoppingBagFill);
export default Memo;
