// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHighlightFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.193 1.867a1.82 1.82 0 0 0-2.575 0l-8.753 8.752a1.82 1.82 0 0 0-.533 1.288v2.26a.5.5 0 0 0 .5.5h2.26c.483 0 .946-.192 1.288-.534l8.752-8.752a1.82 1.82 0 0 0 0-2.575zM7.999 14.167a.5.5 0 0 1 .5-.5h5.666a.5.5 0 0 1 0 1H8.5a.5.5 0 0 1-.5-.5" />
    </Svg>
  );
};
SvgHighlightFill.displayName = 'HighlightFill';
const Memo = memo(SvgHighlightFill);
export default Memo;
