// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBadmintonOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.6 6.627a1.37 1.37 0 0 0-.964-.4h-1.249v-1.25a1.368 1.368 0 0 0-1.364-1.365H9.774V2.366A1.368 1.368 0 0 0 8.41 1H7.26c-.272 0-.54.081-.766.238s-.4.372-.499.633L3.195 9.025l-1.161 1.161s0 .012-.012.012l-.307.308A2.68 2.68 0 0 0 1 12.376c.012.696.29 1.347.784 1.84A2.67 2.67 0 0 0 3.625 15h.046c.68 0 1.324-.255 1.824-.714l.011-.012.308-.308 1.156-1.155 7.159-2.793c.255-.099.476-.273.633-.505.157-.227.238-.494.238-.773V7.596c0-.365-.14-.708-.4-.963zM11.022 4.6c.098 0 .197.04.267.11s.11.169.11.268V6.47l-5.667 4.25-.459-.458L9.524 4.6zM6.906 2.225a.37.37 0 0 1 .354-.238h1.15c.098 0 .197.04.267.11s.11.169.11.268v1.573L4.571 9.56l-.377-.377zM3.613 10l2.381 2.381-.534.534-2.38-2.38zm.03 4.013a1.67 1.67 0 0 1-1.162-.494 1.7 1.7 0 0 1-.494-1.161 1.68 1.68 0 0 1 .401-1.12l2.375 2.374a1.65 1.65 0 0 1-1.12.4m10.37-5.273a.374.374 0 0 1-.238.354l-6.956 2.712-.378-.377 5.615-4.21h1.58c.098 0 .197.04.267.11s.11.169.11.267v1.15z" />
    </Svg>
  );
};
SvgBadmintonOutline.displayName = 'BadmintonOutline';
const Memo = memo(SvgBadmintonOutline);
export default Memo;
