// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSort1SwitchHorizontalOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.283 11.991a1.167 1.167 0 0 1 0-1.65l2.195-2.195a.5.5 0 1 1 .707.707l-1.813 1.813H13.5a.5.5 0 0 1 0 1H3.372l1.813 1.813a.5.5 0 1 1-.707.708zl.354-.353zM2 4.833a.5.5 0 0 1 .5-.5h10.126L10.812 2.52a.5.5 0 1 1 .707-.707l2.195 2.195a1.167 1.167 0 0 1 0 1.65L11.52 7.853a.5.5 0 1 1-.707-.707l1.813-1.813H2.499a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSort1SwitchHorizontalOutline.displayName = 'Sort1SwitchHorizontalOutline';
const Memo = memo(SvgSort1SwitchHorizontalOutline);
export default Memo;
