// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettings1SwitchPreferencesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 7.833A3.833 3.833 0 0 1 4.501 4h7a3.833 3.833 0 0 1 3.834 3.833v.334A3.833 3.833 0 0 1 11.5 12h-7A3.833 3.833 0 0 1 .668 8.167zM11.335 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettings1SwitchPreferencesFill.displayName = 'Settings1SwitchPreferencesFill';
const Memo = memo(SvgSettings1SwitchPreferencesFill);
export default Memo;
