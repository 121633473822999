// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeSimpleHouseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.84 1.304a1.83 1.83 0 0 1 2.32 0l4.168 3.409c.425.348.672.869.672 1.419v6.035C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167V6.132c0-.55.247-1.07.672-1.42z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHomeSimpleHouseFill.displayName = 'HomeSimpleHouseFill';
const Memo = memo(SvgHomeSimpleHouseFill);
export default Memo;
