// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWriteBriefFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.463 2.423a1.833 1.833 0 0 0-2.593 0l-3 3c-.344.343-.537.81-.537 1.296v1.448a.5.5 0 0 0 .5.5h1.448c.486 0 .953-.193 1.296-.537l3-3a1.833 1.833 0 0 0 0-2.593z" />
      <Path d="M3.833 7.667a1.833 1.833 0 0 0 0 3.666h7a.833.833 0 1 1 0 1.667h-3a.5.5 0 0 0 0 1h3a1.833 1.833 0 1 0 0-3.667h-7a.833.833 0 1 1 0-1.666H5.5a.5.5 0 0 0 0-1z" />
    </Svg>
  );
};
SvgWriteBriefFill.displayName = 'WriteBriefFill';
const Memo = memo(SvgWriteBriefFill);
export default Memo;
