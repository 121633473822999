// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMedicinePharmacyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.04 2.38a3.54 3.54 0 0 1 2.509-1.047c.94 0 1.843.377 2.509 1.048a3.58 3.58 0 0 1 1.04 2.528 3.6 3.6 0 0 1-1.04 2.53 3.54 3.54 0 0 1-2.51 1.047A3.54 3.54 0 0 1 3.04 7.438 3.58 3.58 0 0 1 2 4.91a3.6 3.6 0 0 1 1.04-2.528m5.269 2.53q0 .2-.029.397H2.817a2.8 2.8 0 0 1 0-.795H8.28q.029.196.029.397M3.597 6.875a2.8 2.8 0 0 1-.542-.775h4.987a2.8 2.8 0 0 1-.542.775 2.75 2.75 0 0 1-1.951.815 2.75 2.75 0 0 1-1.952-.815m10.32 3.107-1.636 1.65a.4.4 0 0 1-.06.06l-1.644 1.657-.01.01a2.356 2.356 0 0 1-3.286-.058 2.394 2.394 0 0 1-.058-3.311l.01-.01 1.658-1.67.034-.035 1.65-1.662a2.37 2.37 0 0 1 1.683-.754 2.35 2.35 0 0 1 1.715.698 2.39 2.39 0 0 1 .692 1.728 2.4 2.4 0 0 1-.747 1.697m-4.73-.847 2.228 2.246-1.39 1.401a1.57 1.57 0 0 1-2.186-.043 1.595 1.595 0 0 1-.043-2.203z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMedicinePharmacyFill.displayName = 'MedicinePharmacyFill';
const Memo = memo(SvgMedicinePharmacyFill);
export default Memo;
