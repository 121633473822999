// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHairDryerFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.946 2.58C3.496 2.133 7.76 1 10.625 1a4.376 4.376 0 0 1 2.642 7.862v3.95A2.19 2.19 0 0 1 11.08 15h-.67a2.188 2.188 0 0 1-2.186-2.428l.331-2.97c-2.55-.322-5.39-1.085-6.607-1.432h-.003A1.31 1.31 0 0 1 1 6.91V3.84a1.31 1.31 0 0 1 .945-1.26zm1.968.376c-.708.179-1.308.345-1.725.465a.44.44 0 0 0-.314.42v3.068a.44.44 0 0 0 .314.42c.42.12 1.02.285 1.725.463zM13.1 7.85a3.5 3.5 0 0 1-1.6.914V1.986a3.5 3.5 0 0 1 1.6 5.864"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHairDryerFill.displayName = 'HairDryerFill';
const Memo = memo(SvgHairDryerFill);
export default Memo;
