// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBadgeVerifiedAwardFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.077 1.754a1.224 1.224 0 0 1 1.847 0l.913 1.051c.051.059.128.087.204.075l1.375-.219a1.224 1.224 0 0 1 1.415 1.188l.024 1.392a.22.22 0 0 0 .109.188l1.194.717c.637.382.789 1.241.32 1.819l-.876 1.082a.22.22 0 0 0-.038.213l.454 1.316a1.224 1.224 0 0 1-.924 1.6l-1.367.266a.22.22 0 0 0-.166.14l-.498 1.299a1.224 1.224 0 0 1-1.736.632l-1.218-.675a.22.22 0 0 0-.217 0l-1.217.675c-.65.36-1.47.063-1.736-.632l-.499-1.3a.22.22 0 0 0-.166-.14l-1.367-.265a1.224 1.224 0 0 1-.923-1.6l.453-1.316a.22.22 0 0 0-.038-.213l-.876-1.082a1.224 1.224 0 0 1 .32-1.82l1.194-.716a.22.22 0 0 0 .109-.188l.024-1.392a1.224 1.224 0 0 1 1.415-1.188l1.375.219a.22.22 0 0 0 .204-.075zM10.02 7.52a.5.5 0 0 0-.707-.707l-1.98 1.98-.646-.647a.5.5 0 1 0-.708.708l1 1a.5.5 0 0 0 .708 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBadgeVerifiedAwardFill.displayName = 'BadgeVerifiedAwardFill';
const Memo = memo(SvgBadgeVerifiedAwardFill);
export default Memo;
