// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChatGptOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.39 2.333c-1.162 0-2.134.757-2.444 1.78a2.4 2.4 0 0 0-.103.704v2.016c0 .298.158.573.415.722l.731.422.026-2.514a1.83 1.83 0 0 1 .931-1.576L8.74 2.872q.068-.038.135-.072a2.58 2.58 0 0 0-1.486-.467m2.597.112A3.57 3.57 0 0 0 7.39 1.333c-1.49 0-2.772.905-3.296 2.195A3.53 3.53 0 0 0 1.81 5.18a3.435 3.435 0 0 0 .288 3.908 3.42 3.42 0 0 0 .322 2.766c.752 1.276 2.198 1.904 3.59 1.701a3.57 3.57 0 0 0 2.598 1.112c1.49 0 2.773-.905 3.296-2.195a3.53 3.53 0 0 0 2.284-1.652 3.435 3.435 0 0 0-.288-3.907 3.42 3.42 0 0 0-.321-2.767c-.752-1.275-2.197-1.904-3.592-1.701m3.056 3.703a2.42 2.42 0 0 0-.326-1.494c-.7-1.188-2.26-1.603-3.485-.911L7.438 4.757a.83.83 0 0 0-.423.717l-.008.805L9.285 5.02a1.83 1.83 0 0 1 1.788.009l1.815 1.025q.078.045.155.093m-6.048 1.28-.012 1.125 1.004.58 1.017-.561.011-1.124-1.004-.581zm2.037-1.126L11.24 7.58c.566.328.915.932.915 1.587v2.016l-.004.162a2.5 2.5 0 0 0 1.175-1.032 2.44 2.44 0 0 0-.355-2.944 2.5 2.5 0 0 0-.576-.443L10.581 5.9a.83.83 0 0 0-.813-.004zm.978 1.721-.026 2.513a1.83 1.83 0 0 1-.931 1.577l-1.794 1.015q-.067.038-.136.073c.417.293.93.466 1.485.466 1.161 0 2.134-.757 2.444-1.78q.102-.335.103-.704V9.167a.83.83 0 0 0-.416-.721zM8.992 9.721l-2.28 1.258a1.83 1.83 0 0 1-1.788-.008L3.11 9.945a4 4 0 0 1-.154-.092 2.42 2.42 0 0 0 .326 1.493c.574.974 1.728 1.43 2.803 1.178.233-.055.463-.143.682-.267l1.794-1.014a.83.83 0 0 0 .423-.717zm-2.027-.023L4.758 8.42a1.83 1.83 0 0 1-.915-1.587V4.817q0-.081.003-.162a2.5 2.5 0 0 0-1.174 1.032 2.44 2.44 0 0 0 .356 2.945c.164.169.356.319.574.442L5.416 10.1c.252.143.56.144.813.004z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChatGptOutline.displayName = 'ChatGptOutline';
const Memo = memo(SvgChatGptOutline);
export default Memo;
