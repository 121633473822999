// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgOpenQuoteBlockquoteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.836 13.333a1.834 1.834 0 0 0 1.833-1.834V8.641c0-1.013-.82-1.834-1.833-1.834h-.5V3.163a.5.5 0 0 0-.218-.413c-.311-.214-.68.034-.95.19a5.3 5.3 0 0 0-1.014.77c-.743.722-1.485 1.882-1.485 3.597V11.5c0 1.014.822 1.833 1.834 1.833zM5.503 13.333A1.834 1.834 0 0 0 7.336 11.5V8.641c0-1.013-.82-1.834-1.833-1.834h-.5V3.163a.5.5 0 0 0-.218-.413c-.312-.214-.68.034-.95.19a5.3 5.3 0 0 0-1.014.77c-.743.722-1.485 1.882-1.485 3.597V11.5c0 1.014.821 1.833 1.833 1.833z" />
    </Svg>
  );
};
SvgOpenQuoteBlockquoteFill.displayName = 'OpenQuoteBlockquoteFill';
const Memo = memo(SvgOpenQuoteBlockquoteFill);
export default Memo;
