// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBusinessShopStoreFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zm1 0c0-.46.373-.833.833-.833h.917v1.8a.88.88 0 0 1-.875.88A.88.88 0 0 1 3 4.8zm2.75.967V3H7.5v1.8a.88.88 0 0 1-.875.88.88.88 0 0 1-.875-.88m2.75 0V3h1.75v1.8a.88.88 0 0 1-.875.88.88.88 0 0 1-.875-.88m2.75 0V3h.917c.46 0 .833.373.833.833V4.8a.88.88 0 0 1-.875.88.88.88 0 0 1-.875-.88M7.167 8.667c-.47 0-.912.145-1.248.404s-.586.65-.586 1.121V13h1v-2.808c0-.096.05-.216.196-.329.146-.112.37-.196.638-.196h1.666c.268 0 .492.084.638.196.146.113.196.233.196.33V13h1v-2.808c0-.47-.25-.862-.586-1.121a2.05 2.05 0 0 0-1.248-.404z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBusinessShopStoreFill.displayName = 'BusinessShopStoreFill';
const Memo = memo(SvgBusinessShopStoreFill);
export default Memo;
