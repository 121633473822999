// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowExpandVFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.499 2.207 5.686 4.02a.5.5 0 0 1-.708-.707l2.196-2.195.35.35-.35-.35a1.167 1.167 0 0 1 1.65 0l2.195 2.195a.5.5 0 1 1-.707.707L8.499 2.207v11.586l1.813-1.813a.5.5 0 1 1 .707.707l-2.195 2.195a1.167 1.167 0 0 1-1.65 0l-2.196-2.195a.5.5 0 1 1 .708-.707l1.813 1.813z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowExpandVFill.displayName = 'ArrowExpandVFill';
const Memo = memo(SvgArrowExpandVFill);
export default Memo;
