// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPinFlagLocationFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.594 1.54a.5.5 0 0 0-.095.302V11.5a.5.5 0 0 0 1 0V6.787L12.25 4.6a.5.5 0 0 0 0-.864l-3.993-2.33a.5.5 0 0 0-.664.135" />
      <Path d="M6.068 9.796a.5.5 0 1 0-.138-.99c-1.251.173-2.358.504-3.167.956-.78.435-1.431 1.072-1.431 1.904 0 .525.264.976.639 1.334.373.358.886.657 1.48.898 1.188.482 2.797.768 4.548.768s3.359-.286 4.548-.768c.593-.241 1.107-.54 1.48-.898.374-.358.638-.81.638-1.334 0-.832-.65-1.469-1.43-1.904-.81-.452-1.917-.783-3.167-.956a.5.5 0 1 0-.138.99c1.173.163 2.15.466 2.817.84.697.388.918.766.918 1.03 0 .166-.08.373-.33.612-.251.241-.641.48-1.164.693-1.042.422-2.517.695-4.172.695s-3.13-.273-4.173-.695c-.523-.212-.912-.452-1.163-.693-.25-.239-.331-.446-.331-.612 0-.264.221-.642.918-1.03.668-.373 1.644-.677 2.818-.84" />
    </Svg>
  );
};
SvgPinFlagLocationFill.displayName = 'PinFlagLocationFill';
const Memo = memo(SvgPinFlagLocationFill);
export default Memo;
