// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPinCircleLocationFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.941 5.39a1.334 1.334 0 1 0-1.885 1.887A1.334 1.334 0 0 0 8.94 5.39" />
      <Path
        fillRule="evenodd"
        d="M7.999 1.333a6.667 6.667 0 1 0 0 13.334 6.667 6.667 0 0 0 0-13.334m-1.65 3.35a2.334 2.334 0 1 1 2.15 3.93V11.5a.5.5 0 1 1-1 0V8.613a2.334 2.334 0 0 1-1.15-3.93"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPinCircleLocationFill.displayName = 'PinCircleLocationFill';
const Memo = memo(SvgPinCircleLocationFill);
export default Memo;
