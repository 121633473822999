// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShopGroceryOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.82 10.674a1.16 1.16 0 0 0-.421-.367 1 1 0 0 0-.288-.099l-.65-1.62a.414.414 0 0 0-.545-.234.414.414 0 0 0-.233.546l.511 1.278H6.72L7.23 8.9a.42.42 0 0 0-.778-.313l-.65 1.626a1.113 1.113 0 0 0-.69.46 1.25 1.25 0 0 0-.202.507 1.2 1.2 0 0 0 .054.56l.655 1.735a.79.79 0 0 0 .739.525H9.57a.782.782 0 0 0 .744-.515l.644-1.725a1.18 1.18 0 0 0-.139-1.08zm-.654.783-.635 1.695H6.397l-.644-1.7a.4.4 0 0 1-.015-.153c.01-.05.025-.1.054-.144.03-.04.07-.08.114-.1a.4.4 0 0 1 .144-.039H9.85a.33.33 0 0 1 .267.134.32.32 0 0 1 .035.297z" />
      <Path d="M14 5.767c0-.03 0-.06-.01-.09l-.68-3.052A.785.785 0 0 0 12.537 2H3.464a.8.8 0 0 0-.496.178.82.82 0 0 0-.278.447l-.68 3.053C2 5.718 2 5.758 2 5.797c.03.466.228.897.57 1.214.055.05.114.09.174.134v6.434a.421.421 0 1 0 .843 0V7.467c.525.065 1.08-.084 1.477-.46q.061-.06.115-.12.068.084.143.159c.685.684 1.88.684 2.569 0q.076-.076.144-.159.052.06.114.12c.68.639 1.815.639 2.494 0q.061-.06.114-.12.068.084.144.159c.342.342.798.53 1.284.53.074 0 .154-.015.228-.025v6.018a.421.421 0 1 0 .843 0V7.224c.075-.054.149-.109.213-.173.343-.342.531-.798.531-1.284m-2.504.689a.96.96 0 0 1-.283-.689.42.42 0 0 0-.406-.421h-.015a.423.423 0 0 0-.422.396.98.98 0 0 1-.307.655 1 1 0 0 1-1.339 0 .97.97 0 0 1-.307-.655.418.418 0 0 0-.838.025.97.97 0 0 1-.283.689c-.362.362-1.012.362-1.374 0a.96.96 0 0 1-.282-.689.42.42 0 0 0-.412-.421.41.41 0 0 0-.431.396 1 1 0 0 1-.303.655 1 1 0 0 1-1.339 0 1 1 0 0 1-.302-.6l.65-2.954h8.995l.659 2.969a.96.96 0 0 1-.283.644c-.362.367-1.006.362-1.373 0z" />
    </Svg>
  );
};
SvgShopGroceryOff.displayName = 'ShopGroceryOff';
const Memo = memo(SvgShopGroceryOff);
export default Memo;
