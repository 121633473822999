// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAiAvatarGeneratedAvatarProfileAiMagicAvatarOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.667.667a.5.5 0 0 1 .447.276l.648 1.295 1.295.648a.5.5 0 0 1 0 .895l-1.295.647-.648 1.296a.5.5 0 0 1-.895 0l-.647-1.296-1.296-.647a.5.5 0 0 1 0-.895l1.296-.648.648-1.295a.5.5 0 0 1 .447-.276m0 1.618-.275.55a.5.5 0 0 1-.224.223l-.55.275.55.275a.5.5 0 0 1 .224.224l.275.55.275-.55a.5.5 0 0 1 .223-.224l.55-.275-.55-.275a.5.5 0 0 1-.223-.223zM3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h.56a3.668 3.668 0 0 1 7.213 0h.56c.46 0 .834-.373.834-.833V8.5a.5.5 0 0 1 1 0v3.667C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167V3.833C2 2.821 2.82 2 3.833 2H7.5a.5.5 0 0 1 0 1zm6.75 10a2.668 2.668 0 0 0-5.166 0zM8 5.667a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666M5.667 7a2.333 2.333 0 1 1 4.666 0 2.333 2.333 0 0 1-4.666 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAiAvatarGeneratedAvatarProfileAiMagicAvatarOutline.displayName =
  'AiAvatarGeneratedAvatarProfileAiMagicAvatarOutline';
const Memo = memo(SvgAiAvatarGeneratedAvatarProfileAiMagicAvatarOutline);
export default Memo;
