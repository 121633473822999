// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTitleCaseOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.336 12.431c.348 0 .546-.168.672-.558l.654-1.86h3.312l.66 1.866c.12.384.318.552.678.552.39 0 .672-.258.672-.612 0-.114-.024-.228-.084-.396L5.26 4.277c-.18-.48-.468-.696-.936-.696-.474 0-.768.222-.942.702l-2.634 7.14a1.2 1.2 0 0 0-.084.396c0 .366.27.612.672.612m1.668-3.516 1.29-3.798h.036l1.302 3.798zM11.4 12.443c.822 0 1.626-.444 1.992-1.146h.03v.534c.012.384.258.618.618.618.366 0 .624-.246.624-.654v-3.81c0-1.266-.978-2.076-2.508-2.076-1.134 0-2.088.456-2.418 1.17a.9.9 0 0 0-.114.42c0 .324.234.54.558.54a.59.59 0 0 0 .528-.276c.36-.576.774-.798 1.398-.798.792 0 1.266.42 1.266 1.122v.486l-1.734.102c-1.494.084-2.346.762-2.346 1.866 0 1.134.87 1.902 2.106 1.902m.354-1.026c-.69 0-1.152-.36-1.152-.912 0-.54.438-.882 1.212-.936l1.56-.096v.498c0 .822-.714 1.446-1.62 1.446" />
    </Svg>
  );
};
SvgTitleCaseOutline.displayName = 'TitleCaseOutline';
const Memo = memo(SvgTitleCaseOutline);
export default Memo;
