// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleBehindUserAvatarGroup3Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.332 3a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333M2.666 4.667a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0m8.667-1a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666M8.999 5a2.333 2.333 0 1 1 4.667 0 2.333 2.333 0 0 1-4.667 0M3.115 9.868c-.575.553-1 1.356-1.243 2.336-.093.375.212.796.728.796h5.465c.516 0 .821-.42.728-.796-.243-.98-.668-1.783-1.244-2.336C6.983 9.322 6.25 9 5.332 9s-1.65.322-2.217.868m-.693-.721C3.175 8.423 4.156 8 5.332 8s2.158.423 2.91 1.147c.745.716 1.246 1.704 1.522 2.817.28 1.128-.666 2.036-1.7 2.036H2.6c-1.033 0-1.979-.908-1.699-2.036.276-1.113.777-2.101 1.521-2.817M11.332 9q-.389.002-.72.083a.5.5 0 0 1-.238-.971q.455-.111.959-.112c2.07 0 3.374 1.427 3.903 3.283.324 1.14-.648 2.05-1.673 2.05h-1.73a.5.5 0 1 1 0-1h1.73c.526 0 .811-.424.711-.776C13.826 9.983 12.82 9 11.333 9"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPeopleBehindUserAvatarGroup3Outline.displayName = 'PeopleBehindUserAvatarGroup3Outline';
const Memo = memo(SvgPeopleBehindUserAvatarGroup3Outline);
export default Memo;
