// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCodeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V3.833A.833.833 0 0 0 12.167 3zm3.354 2.646a.5.5 0 0 1 0 .708L5.658 7.882a.167.167 0 0 0 0 .236l1.529 1.528a.5.5 0 0 1-.707.708L4.95 8.825a1.167 1.167 0 0 1 0-1.65L6.48 5.646a.5.5 0 0 1 .707 0m1.626 0a.5.5 0 0 1 .707 0l1.529 1.529a1.167 1.167 0 0 1 0 1.65L9.52 10.354a.5.5 0 0 1-.707-.708l1.529-1.528a.167.167 0 0 0 0-.236L8.813 6.354a.5.5 0 0 1 0-.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCodeOutline.displayName = 'CodeOutline';
const Memo = memo(SvgCodeOutline);
export default Memo;
