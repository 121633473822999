// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAirpodCaseAirpodsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 4.833A2.833 2.833 0 0 1 4.833 2h6.334A2.833 2.833 0 0 1 14 4.833v6.334A2.833 2.833 0 0 1 11.167 14H4.833A2.833 2.833 0 0 1 2 11.167zM4.833 3C3.821 3 3 3.82 3 4.833V6h10V4.833C13 3.821 12.18 3 11.167 3zM13 7H3v4.167C3 12.179 3.82 13 4.833 13h6.334C12.179 13 13 12.18 13 11.167z"
        clipRule="evenodd"
      />
      <Path d="M8.335 9a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0" />
      <Path
        fillRule="evenodd"
        d="M8.665 9a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M8 9.333a.333.333 0 1 0 0-.666.333.333 0 0 0 0 .666"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAirpodCaseAirpodsOutline.displayName = 'AirpodCaseAirpodsOutline';
const Memo = memo(SvgAirpodCaseAirpodsOutline);
export default Memo;
