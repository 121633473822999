// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPieChart2GraphChartStatisticsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m10.564 1.474-.014-.004a3 3 0 0 0-.254-.059.8.8 0 0 0-.367.02.86.86 0 0 0-.474.363.8.8 0 0 0-.114.36 3 3 0 0 0-.009.279v3.214c0 .081 0 .17.006.246a.8.8 0 0 0 .085.319c.08.156.207.284.364.364a.8.8 0 0 0 .319.084c.076.007.165.007.246.007h3.214c.09 0 .19 0 .278-.009a.8.8 0 0 0 .36-.114.86.86 0 0 0 .364-.475.8.8 0 0 0 .02-.366c-.014-.084-.038-.176-.06-.254l-.003-.014a5.63 5.63 0 0 0-3.96-3.96" />
      <Path d="M7.332 2.667a6 6 0 1 0 5.997 5.818.5.5 0 0 0-.5-.485H9.102c-.288 0-.474 0-.616-.012a.6.6 0 0 1-.21-.042.5.5 0 0 1-.219-.219.6.6 0 0 1-.043-.21 9 9 0 0 1-.012-.617V3.17a.5.5 0 0 0-.484-.5z" />
    </Svg>
  );
};
SvgPieChart2GraphChartStatisticsFill.displayName = 'PieChart2GraphChartStatisticsFill';
const Memo = memo(SvgPieChart2GraphChartStatisticsFill);
export default Memo;
