// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowTriangleTopOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.365 3.68c.674-1.35 2.6-1.35 3.274 0l3.501 7.004a1.83 1.83 0 0 1-1.637 2.65H4.501a1.83 1.83 0 0 1-1.637-2.65zm2.38.446a.83.83 0 0 0-1.486 0l-3.501 7.005a.83.83 0 0 0 .743 1.203h7.002a.83.83 0 0 0 .743-1.203z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowTriangleTopOutline.displayName = 'ArrowTriangleTopOutline';
const Memo = memo(SvgArrowTriangleTopOutline);
export default Memo;
