// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddPeopleAddUserAddPersonOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2.333a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3 5a5 5 0 0 0-.248.006c-1.915.094-3.353 1.266-3.96 2.91a.48.48 0 0 0 .088.494.77.77 0 0 0 .585.257h3.87a.5.5 0 0 1 0 1h-3.87a1.77 1.77 0 0 1-1.35-.615 1.48 1.48 0 0 1-.261-1.483c.76-2.054 2.61-3.526 5.04-3.568a6 6 0 0 1 .856.046q.324.04.635.117.42.103.81.268a.5.5 0 1 1-.388.921 4.4 4.4 0 0 0-.596-.201l-.002-.001a5 5 0 0 0-.585-.112 5 5 0 0 0-.623-.039m4.168.334a.5.5 0 0 1 .5.5v1.5h1.5a.5.5 0 1 1 0 1h-1.5v1.5a.5.5 0 0 1-1 0v-1.5h-1.5a.5.5 0 0 1 0-1h1.5v-1.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAddPeopleAddUserAddPersonOutline.displayName = 'AddPeopleAddUserAddPersonOutline';
const Memo = memo(SvgAddPeopleAddUserAddPersonOutline);
export default Memo;
