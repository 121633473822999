// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBasketballFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1 7.132C1 3.522 3.88 1 7.997 1S14.994 3.522 15 7.132v1.302c0 .596-.231 1.151-.653 1.573a2.2 2.2 0 0 1-1.573.654H11.71l-.625 3.078a.48.48 0 0 1-.578.382.49.49 0 0 1-.382-.579l.058-.277h-.711l-.11 1.284A.49.49 0 0 1 8.87 15h-.04a.493.493 0 0 1-.451-.532l.098-1.203h-.954l.098 1.203a.493.493 0 0 1-.45.532H7.13a.496.496 0 0 1-.492-.451l-.11-1.284h-.711l.058.277a.485.485 0 0 1-.382.579c-.035.011-.063.011-.098.011a.494.494 0 0 1-.48-.393L4.29 10.66H3.226c-.595 0-1.15-.231-1.573-.654A2.2 2.2 0 0 1 1 8.434zm8.691 3.414h1.036l.156-.77H9.755zm-2.463-.758.064.758h1.41l.064-.758zm-.92.758-.063-.758v.005H5.112l.156.753zm-.844.977.156.752h.827l-.063-.752zm1.909 0 .063.752h1.116l.064-.752zm2.232 0-.064.752h.833l.15-.752zM4.96 3.603h6.072c.272 0 .492.22.492.492v3.471c0 .272-.22.492-.492.492H4.96a.49.49 0 0 1-.491-.492V4.095c0-.272.22-.492.491-.492m.492 3.471h5.089V4.587H5.45z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBasketballFill.displayName = 'BasketballFill';
const Memo = memo(SvgBasketballFill);
export default Memo;
