// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgColorsPaletteColoursFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8c0-3.524 3.011-6.333 6.667-6.333S14.665 4.477 14.665 8c0 1.259-.337 2.101-1.043 2.542-.657.41-1.49.365-2.19.274-.246-.032-.502-.075-.746-.115l-.328-.053a7 7 0 0 0-.937-.099c-.557-.008-.827.124-.975.42-.088.176-.088.389.01.678.086.256.224.505.372.773q.038.071.08.144c.081.15.169.317.23.478.056.15.119.37.071.602a.79.79 0 0 1-.484.572c-.208.09-.46.117-.726.117-3.656 0-6.667-2.81-6.667-6.333m5.5-3.833a1 1 0 1 0 0 2 1 1 0 0 0 0-2M4.832 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2m5.333-1.833a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgColorsPaletteColoursFill.displayName = 'ColorsPaletteColoursFill';
const Memo = memo(SvgColorsPaletteColoursFill);
export default Memo;
