// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHeadphonesSupportOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 3a5 5 0 0 0-5 5v.667h.833c1.013 0 1.834.82 1.834 1.833v1.667a1.833 1.833 0 0 1-3.667 0V8a6 6 0 1 1 12 0v4.167a1.833 1.833 0 0 1-3.667 0V10.5c0-1.013.821-1.833 1.834-1.833H13V8a5 5 0 0 0-5-5m5 6.667h-.833a.833.833 0 0 0-.834.833v1.667a.833.833 0 0 0 1.667 0zm-10 0v2.5a.833.833 0 0 0 1.667 0V10.5a.833.833 0 0 0-.834-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHeadphonesSupportOutline.displayName = 'HeadphonesSupportOutline';
const Memo = memo(SvgHeadphonesSupportOutline);
export default Memo;
