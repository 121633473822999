// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTwoDiceGameRandomFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.543 7.42a1.833 1.833 0 0 1-1.095-2.35l.798-2.192a1.833 1.833 0 0 1 2.35-1.096l2.192.798a1.833 1.833 0 0 1 1.096 2.35l-.798 2.192a1.833 1.833 0 0 1-2.35 1.096zm1.896-1.794a.667.667 0 1 0 .456-1.252.667.667 0 0 0-.456 1.252M1.79 7.118a1.833 1.833 0 0 0-.887 2.437l1.55 3.323a1.833 1.833 0 0 0 2.436.887l3.323-1.55a1.833 1.833 0 0 0 .887-2.436l-1.55-3.323a1.833 1.833 0 0 0-2.436-.887zm2.484 1.783a.667.667 0 1 1-1.208.564.667.667 0 0 1 1.208-.564m2.337 1.854a.667.667 0 1 0-.564-1.209.667.667 0 0 0 .564 1.209"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTwoDiceGameRandomFill.displayName = 'TwoDiceGameRandomFill';
const Memo = memo(SvgTwoDiceGameRandomFill);
export default Memo;
