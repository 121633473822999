// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEyeClosedSeeHiddenOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 3.035c-1.8 0-3.624.781-5.136 2.39a.5.5 0 1 1-.728-.686C3.817 2.951 5.896 2.035 8 2.035s4.183.916 5.864 2.704a.5.5 0 0 1-.728.685C11.624 3.816 9.8 3.035 8 3.035m-5.843 5.28a.5.5 0 0 1 .707.022C4.376 9.945 6.2 10.727 8 10.727s3.624-.782 5.136-2.39a.5.5 0 1 1 .728.685c-.865.92-1.835 1.61-2.86 2.062l.905 1.283a.5.5 0 0 1-.818.576l-1.06-1.505a7.4 7.4 0 0 1-1.531.271V13.5a.5.5 0 0 1-1 0v-1.79a7.4 7.4 0 0 1-1.657-.31l-.915 1.513a.5.5 0 1 1-.856-.517l.821-1.359c-.987-.452-1.921-1.126-2.757-2.015a.5.5 0 0 1 .021-.706"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEyeClosedSeeHiddenOutline.displayName = 'EyeClosedSeeHiddenOutline';
const Memo = memo(SvgEyeClosedSeeHiddenOutline);
export default Memo;
