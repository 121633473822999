// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCornerRightDownOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 2.5a.5.5 0 0 1 .5-.5H8a3.167 3.167 0 0 1 3.167 3.167v7.46l1.813-1.814a.5.5 0 0 1 .707.707l-2.195 2.196a1.167 1.167 0 0 1-1.65 0L7.646 11.52a.5.5 0 1 1 .708-.707l1.813 1.813v-7.46C10.167 3.97 9.197 3 8 3H2.5a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCornerRightDownOutline.displayName = 'CornerRightDownOutline';
const Memo = memo(SvgCornerRightDownOutline);
export default Memo;
