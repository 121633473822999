// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgExpandWindowLayoutFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.165 8.667C7.178 8.667 8 9.487 8 10.5v2.333c0 1.013-.821 1.834-1.834 1.834h-3a1.833 1.833 0 0 1-1.833-1.834V10.5c0-1.013.82-1.833 1.833-1.833z" />
      <Path d="M13.665 3.833A.833.833 0 0 0 12.832 3h-9a.833.833 0 0 0-.833.833v3a.5.5 0 1 1-1 0v-3C1.999 2.821 2.819 2 3.832 2h9c1.013 0 1.833.82 1.833 1.833v5c0 1.013-.82 1.834-1.833 1.834h-3a.5.5 0 0 1 0-1h3c.46 0 .833-.373.833-.834z" />
      <Path d="M11.499 4.667a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-.793L9.686 7.687a.5.5 0 1 1-.708-.707l1.314-1.313h-.793a.5.5 0 0 1 0-1z" />
    </Svg>
  );
};
SvgExpandWindowLayoutFill.displayName = 'ExpandWindowLayoutFill';
const Memo = memo(SvgExpandWindowLayoutFill);
export default Memo;
