// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCreditCard3CardPaymentOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3.669a.833.833 0 0 0-.833.833V6h11.33V4.498a.83.83 0 0 0-.831-.83zM13.663 7H2.332v4.497c0 .46.373.834.833.834h9.664c.46 0 .834-.373.834-.834zM1.332 4.502c0-1.013.82-1.833 1.833-1.833h9.666c1.01 0 1.83.817 1.832 1.829v6.999c0 1.013-.821 1.834-1.834 1.834H3.165a1.833 1.833 0 0 1-1.833-1.834zm2.667 4.331a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCreditCard3CardPaymentOutline.displayName = 'CreditCard3CardPaymentOutline';
const Memo = memo(SvgCreditCard3CardPaymentOutline);
export default Memo;
