// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHand5FingerSelectFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.046 1.333a.753.753 0 0 0-.755.751v4.508c0 .207-.17.375-.378.375a.377.377 0 0 1-.378-.375V2.836a.753.753 0 0 0-.755-.752.753.753 0 0 0-.756.752V7.43a.38.38 0 0 1-.65.26l-.897-.927a1.137 1.137 0 0 0-1.789.201L1.668 7l1.863 4.523a5.09 5.09 0 0 0 4.712 3.145c2.812 0 5.092-2.267 5.092-5.063V4.338a.753.753 0 0 0-.756-.751.753.753 0 0 0-.755.751v3.005c0 .207-.17.375-.378.375a.377.377 0 0 1-.378-.375V2.836a.753.753 0 0 0-.755-.752.753.753 0 0 0-.756.752v4.131c0 .208-.169.376-.378.376a.377.377 0 0 1-.377-.376V2.084a.753.753 0 0 0-.756-.75"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHand5FingerSelectFill.displayName = 'Hand5FingerSelectFill';
const Memo = memo(SvgHand5FingerSelectFill);
export default Memo;
