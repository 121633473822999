// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDoctorFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.417 1.333c.23 0 .417.189.417.421v.662a.4.4 0 0 1 0 .08V3.72a.42.42 0 0 1-.417.421.42.42 0 0 1-.416-.42v-.843h-.415A.41.41 0 0 0 2.29 3a.42.42 0 0 0-.122.298v3.369c0 .781.308 1.531.855 2.084a2.9 2.9 0 0 0 2.062.863c.773 0 1.515-.31 2.062-.863a2.96 2.96 0 0 0 .855-2.084V3.298A.42.42 0 0 0 7.88 3a.41.41 0 0 0-.295-.123h-.417v.842a.42.42 0 0 1-.417.421.42.42 0 0 1-.417-.42V1.753c0-.232.187-.42.417-.42s.417.188.417.42v.281h.417c.331 0 .65.133.884.37s.366.558.366.893v3.369a3.8 3.8 0 0 1-1.098 2.68 3.74 3.74 0 0 1-2.234 1.085v.024c0 .893.351 1.75.976 2.382a3.32 3.32 0 0 0 2.357.987c.884 0 1.732-.355 2.357-.987a3.39 3.39 0 0 0 .977-2.382V8.73a2.08 2.08 0 0 1-1.056-.574 2.117 2.117 0 0 1 0-2.977c.39-.395.92-.617 1.473-.617s1.083.222 1.474.617c.39.395.61.93.61 1.489 0 .558-.22 1.093-.61 1.488-.292.295-.66.493-1.058.575v1.726a4.23 4.23 0 0 1-1.22 2.977 4.14 4.14 0 0 1-2.947 1.234 4.14 4.14 0 0 1-2.946-1.234 4.23 4.23 0 0 1-1.22-2.977v-.023a3.74 3.74 0 0 1-2.237-1.087 3.8 3.8 0 0 1-1.098-2.68V3.299c0-.335.132-.656.366-.893s.552-.37.884-.37H3v-.28c0-.233.186-.422.416-.422"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDoctorFill.displayName = 'DoctorFill';
const Memo = memo(SvgDoctorFill);
export default Memo;
