// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSearchMagnifierDocumentListPageFileOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 2.333a.833.833 0 0 0-.833.834v9.666c0 .46.373.834.833.834h2.334a.5.5 0 1 1 0 1H4.5a1.833 1.833 0 0 1-1.833-1.834V3.167c0-1.013.82-1.834 1.833-1.834h7c1.013 0 1.834.821 1.834 1.834v4a.5.5 0 1 1-1 0v-4a.833.833 0 0 0-.834-.834zm6.5 7.334a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-3 2a3 3 0 1 1 5.453 1.727l.573.591a.5.5 0 1 1-.718.696l-.559-.577a3 3 0 0 1-4.749-2.438"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSearchMagnifierDocumentListPageFileOutline.displayName =
  'SearchMagnifierDocumentListPageFileOutline';
const Memo = memo(SvgSearchMagnifierDocumentListPageFileOutline);
export default Memo;
