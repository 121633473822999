// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPanoramaViewFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill={color}
      viewBox="0 0 24 24"
      {...props}
    >
      <Path d="M4.602 4.091C2.844 3.641 1 4.911 1 6.808v10.383c0 1.903 1.854 3.173 3.615 2.718 5.007-1.295 9.763-1.295 14.77 0 1.761.455 3.615-.815 3.615-2.718V6.808c0-1.897-1.844-3.167-3.602-2.717-5.032 1.289-9.764 1.289-14.796 0" />
    </Svg>
  );
};
SvgPanoramaViewFill.displayName = 'PanoramaViewFill';
const Memo = memo(SvgPanoramaViewFill);
export default Memo;
