// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgForkOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.333 2.333a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667M2 3.667a2.333 2.333 0 1 1 2.833 2.28v.72c0 .46.373.833.834.833h1c.525 0 .999.22 1.333.575A1.83 1.83 0 0 1 9.333 7.5h1c.46 0 .834-.373.834-.833v-.72a2.334 2.334 0 1 1 1 0v.72c0 1.012-.821 1.833-1.834 1.833h-1a.833.833 0 0 0-.833.833v.72a2.334 2.334 0 1 1-1 0v-.72a.833.833 0 0 0-.833-.833h-1a1.833 1.833 0 0 1-1.834-1.833v-.72A2.334 2.334 0 0 1 2 3.666m9.667-1.334a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667M8 11a1.333 1.333 0 1 0 0 2.667A1.333 1.333 0 0 0 8 11"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgForkOutline.displayName = 'ForkOutline';
const Memo = memo(SvgForkOutline);
export default Memo;
