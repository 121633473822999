// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFastForward30SOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.64.05a.52.52 0 0 0-.308.474v.842a6.668 6.668 0 1 0 5.529 2.072.5.5 0 0 0-.73.685 5.667 5.667 0 1 1-4.8-1.75v.77c.001.203.12.388.308.474a.54.54 0 0 0 .568-.072l1.6-1.31a.52.52 0 0 0 0-.804L8.207.12A.54.54 0 0 0 7.639.05" />
      <Path d="M4.124 9.469c0 .59.742 1.135 1.708 1.135 1.078 0 1.837-.598 1.837-1.454 0-.62-.447-1.128-1.042-1.185v-.061c.494-.072.892-.559.892-1.089 0-.762-.695-1.296-1.683-1.296-.935 0-1.633.527-1.633 1.1 0 .232.165.393.394.393.168 0 .294-.075.401-.254q.287-.485.82-.487c.455 0 .77.269.77.663 0 .393-.322.68-.763.68h-.333a.36.36 0 0 0-.372.376c0 .222.157.383.372.383h.351c.526 0 .881.3.881.745 0 .444-.347.734-.884.734-.402 0-.72-.176-.928-.505-.132-.193-.25-.269-.408-.269a.374.374 0 0 0-.38.39M8.217 8.176c0 1.475.691 2.428 1.851 2.428s1.87-.963 1.87-2.428v-.23c0-1.478-.688-2.427-1.855-2.427-1.164 0-1.866.96-1.866 2.428zm.967-.225c0-1.032.337-1.644.892-1.644s.891.616.891 1.644v.218c0 1.031-.337 1.647-.892 1.647S9.185 9.2 9.185 8.17z" />
    </Svg>
  );
};
SvgFastForward30SOutline.displayName = 'FastForward30SOutline';
const Memo = memo(SvgFastForward30SOutline);
export default Memo;
