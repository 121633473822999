// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLiftFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.524 1C1.682 1 1 1.686 1 2.532v10.936c0 .846.682 1.531 1.524 1.531H3.57V7.627c0-.846.683-1.532 1.524-1.532h5.809c.842 0 1.524.686 1.524 1.532V15h1.05c.84 0 1.523-.686 1.523-1.533V2.532C15 1.686 14.318 1 13.476 1zm2.57 6.116H7.5v7.863H4.586V7.627a.51.51 0 0 1 .508-.51m3.422 0v7.863h2.895V7.627a.51.51 0 0 0-.508-.51zM5.91 2.641c.144 0 .28.061.377.169l1.176 1.314c.188.21.171.532-.038.72a.506.506 0 0 1-.717-.037l-.798-.892-.799.892a.506.506 0 0 1-.717.038.513.513 0 0 1-.038-.721L5.533 2.81a.5.5 0 0 1 .378-.17m2.898.13a.506.506 0 0 1 .717.039l.799.892.798-.892a.506.506 0 0 1 .718-.038.513.513 0 0 1 .037.721l-1.176 1.314a.507.507 0 0 1-.755 0L8.771 3.493a.513.513 0 0 1 .038-.721"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLiftFill.displayName = 'LiftFill';
const Memo = memo(SvgLiftFill);
export default Memo;
