// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBagShoppingOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.42 3a.833.833 0 0 0-.831.783l-.505 8.333a.833.833 0 0 0 .831.884h8.167c.48 0 .861-.405.832-.884l-.505-8.333A.833.833 0 0 0 11.577 3zm-1.83.722A1.833 1.833 0 0 1 4.42 2h7.157c.97 0 1.771.755 1.83 1.722l.505 8.334A1.833 1.833 0 0 1 12.082 14H3.915a1.833 1.833 0 0 1-1.83-1.944zM6 4.833a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0 .5.5 0 1 1 1 0 2.5 2.5 0 1 1-5 0 .5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBagShoppingOutline.displayName = 'BagShoppingOutline';
const Memo = memo(SvgBagShoppingOutline);
export default Memo;
