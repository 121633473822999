// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLightBulbIdeaLightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.998 1.667a4.334 4.334 0 0 0-2.045 8.156h4.09A4.34 4.34 0 0 0 12.332 6a4.334 4.334 0 0 0-4.334-4.334m1.667 9.156H6.331v.677c0 .092.074.167.166.167H9.5a.167.167 0 0 0 .166-.167zm-4.334-.202A5.35 5.35 0 0 1 2.664 6a5.334 5.334 0 1 1 8.001 4.62v.88c0 .644-.522 1.166-1.166 1.166H6.497A1.167 1.167 0 0 1 5.331 11.5zm.668 3.546a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLightBulbIdeaLightOutline.displayName = 'LightBulbIdeaLightOutline';
const Memo = memo(SvgLightBulbIdeaLightOutline);
export default Memo;
