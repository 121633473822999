// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBinocularFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.69 8.667q.001.004.002.004l-1.277-2.8a2 2 0 0 0-.058-.12l-.002-.004a2.15 2.15 0 0 0-1.254-1.071l-.463-1.001-.002.001A1.18 1.18 0 0 0 9.586 3C8.932 3 8.4 3.565 8.4 4.26v1.287c0 .234-.179.424-.4.425-.221 0-.4-.19-.4-.425V4.26C7.6 3.565 7.068 3 6.414 3a1.18 1.18 0 0 0-1.05.676l-.002-.001-.463 1a2.15 2.15 0 0 0-1.254 1.072l-.002.003q-.03.06-.058.12L2.308 8.672l.003-.004c-.198.408-.311.87-.311 1.36C2 11.665 3.256 13 4.8 13c1.221 0 2.262-.835 2.644-1.995l.004-.013c.112-.326.341-.468.552-.468.21 0 .44.142.552.468l.004.013C8.938 12.165 9.98 13 11.2 13c1.544 0 2.8-1.334 2.8-2.974 0-.49-.113-.951-.31-1.359M4.794 12.24c-1.138 0-2.06-.98-2.06-2.188s.922-2.188 2.06-2.188 2.06.98 2.06 2.188-.922 2.188-2.06 2.188m6.407 0c-1.138 0-2.06-.98-2.06-2.188s.922-2.188 2.06-2.188 2.06.98 2.06 2.188-.923 2.188-2.06 2.188" />
    </Svg>
  );
};
SvgBinocularFill.displayName = 'BinocularFill';
const Memo = memo(SvgBinocularFill);
export default Memo;
