// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMoneyHandCoinsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.332 1.667A1.667 1.667 0 1 0 7.352 5q-.02-.164-.02-.333c0-.993.542-1.858 1.346-2.317a1.67 1.67 0 0 0-1.346-.683m2.322.355a2.665 2.665 0 0 0-4.989 1.311 2.667 2.667 0 0 0 3.011 2.645 2.665 2.665 0 0 0 4.99-1.311 2.667 2.667 0 0 0-3.012-2.645M10 3a1.666 1.666 0 1 0 0 3.333 1.667 1.667 0 1 0 0-3.333M1.332 9.833a1.833 1.833 0 0 1 3.33-1.059c1.79-.45 3.286-.58 4.457-.505 1.21.078 2.149.383 2.667.865.18.168.325.373.395.608q.172-.125.332-.252c.722-.574 1.843-.544 2.462.231.47.588.495 1.456-.04 2.045-1.624 1.789-3.682 3.017-6.298 2.892-.613-.03-1.345-.216-1.898-.382a16 16 0 0 1-.9-.301H4.6a1.833 1.833 0 0 1-3.268-1.141zm3.661 3.142h.937a.5.5 0 0 1 .18.033m0 0 .003.002.012.004q.018.006.05.02l.193.069c.165.058.396.136.66.215.539.163 1.172.318 1.657.341 2.228.106 4.018-.922 5.51-2.565.176-.194.19-.51-.001-.749-.233-.291-.713-.347-1.06-.072-.599.476-1.445 1.056-2.29 1.29-.284.079-.565.14-.825.19q-.583.12-1.302.168h-.013l-.01.001H8.69l-.029-.499.028.5a.5.5 0 0 1-.06-.999l.026-.001.017-.001.121-.01a11 11 0 0 0 1.031-.14c.62-.13 1.018-.314 1.233-.489.157-.128.169-.21.17-.226 0-.01.001-.076-.122-.19-.266-.248-.922-.527-2.05-.6-1.03-.066-2.391.043-4.06.455Q5 9.777 5 9.833v3q0 .072-.006.142M3.165 9a.833.833 0 0 0-.833.833v3a.833.833 0 0 0 1.667 0v-3A.833.833 0 0 0 3.165 9"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMoneyHandCoinsOutline.displayName = 'MoneyHandCoinsOutline';
const Memo = memo(SvgMoneyHandCoinsOutline);
export default Memo;
