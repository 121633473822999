// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCryptoCoinOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.667-4a.5.5 0 0 1 .5.5v.71a2.83 2.83 0 0 1 1.726 1.037.5.5 0 0 1-.786.62 1.833 1.833 0 1 0 0 2.268.5.5 0 0 1 .786.618 2.83 2.83 0 0 1-1.726 1.036v.711a.5.5 0 1 1-1 0v-.71a2.834 2.834 0 0 1 0-5.58V4.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCryptoCoinOutline.displayName = 'CryptoCoinOutline';
const Memo = memo(SvgCryptoCoinOutline);
export default Memo;
