// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDropWaterPrecipitationLiquidOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.48 2.54a.667.667 0 0 0-.965 0c-.68.688-1.653 1.763-2.453 2.998C4.255 6.784 3.664 8.13 3.664 9.37c0 2.37 1.937 4.297 4.333 4.297s4.334-1.928 4.334-4.297c0-1.24-.591-2.586-1.398-3.832-.8-1.235-1.773-2.31-2.453-2.999m-1.677-.703a1.666 1.666 0 0 1 2.389 0c.704.713 1.729 1.843 2.58 3.157.844 1.303 1.559 2.847 1.559 4.376 0 2.93-2.392 5.297-5.334 5.297S2.664 12.299 2.664 9.37c0-1.529.715-3.073 1.559-4.376.851-1.314 1.876-2.444 2.58-3.157"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDropWaterPrecipitationLiquidOutline.displayName = 'DropWaterPrecipitationLiquidOutline';
const Memo = memo(SvgDropWaterPrecipitationLiquidOutline);
export default Memo;
