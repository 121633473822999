// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCreditCard1CardPaymentFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M1.332 4.502c0-1.013.82-1.833 1.833-1.833h9.666c1.01 0 1.83.817 1.832 1.829v6.999c0 1.013-.821 1.834-1.834 1.834H3.165a1.833 1.833 0 0 1-1.833-1.834V7h13.33V6H1.333z" />
    </Svg>
  );
};
SvgCreditCard1CardPaymentFill.displayName = 'CreditCard1CardPaymentFill';
const Memo = memo(SvgCreditCard1CardPaymentFill);
export default Memo;
