// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayoutGrid1GridWindowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.613 2c-.356 0-.653 0-.896.02-.253.02-.491.065-.716.18A1.83 1.83 0 0 0 2.2 3c-.115.225-.16.463-.18.716-.02.243-.02.54-.02.896V5.5a.5.5 0 0 0 .5.5h4.333a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM12.999 2.2a1.9 1.9 0 0 0-.716-.18C12.04 2 11.743 2 11.387 2h-2.22a.5.5 0 0 0-.5.5v5.667a.5.5 0 0 0 .5.5H13.5a.5.5 0 0 0 .5-.5V4.613c0-.356 0-.653-.02-.896A1.9 1.9 0 0 0 13.8 3 1.83 1.83 0 0 0 13 2.2M2.5 7.333a.5.5 0 0 0-.5.5v3.554c0 .356 0 .653.02.896.02.253.065.491.18.716.176.345.456.625.801.801.225.115.463.16.716.18.243.02.54.02.896.02h2.22a.5.5 0 0 0 .5-.5V7.833a.5.5 0 0 0-.5-.5zM9.167 10a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h2.22c.356 0 .653 0 .896-.02.253-.02.491-.065.716-.18.345-.176.625-.456.801-.801.115-.225.16-.463.18-.716.02-.243.02-.54.02-.896V10.5a.5.5 0 0 0-.5-.5z" />
    </Svg>
  );
};
SvgLayoutGrid1GridWindowFill.displayName = 'LayoutGrid1GridWindowFill';
const Memo = memo(SvgLayoutGrid1GridWindowFill);
export default Memo;
