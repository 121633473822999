// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPictureInPictureFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.332 4.5c0-.46.373-.833.833-.833H11.5c.46 0 .833.373.833.833v3a.5.5 0 0 0 1 0v-3c0-1.013-.82-1.833-1.833-1.833H3.165c-1.012 0-1.833.82-1.833 1.833v5.666c0 1.013.82 1.834 1.833 1.834h3a.5.5 0 1 0 0-1h-3a.833.833 0 0 1-.833-.834z" />
      <Path d="M9.499 9.333a1.5 1.5 0 0 0-1.5 1.5V12.5a1.5 1.5 0 0 0 1.5 1.5h3.666a1.5 1.5 0 0 0 1.5-1.5v-1.667a1.5 1.5 0 0 0-1.5-1.5z" />
    </Svg>
  );
};
SvgPictureInPictureFill.displayName = 'PictureInPictureFill';
const Memo = memo(SvgPictureInPictureFill);
export default Memo;
