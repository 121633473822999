// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCocktailOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.54 2.229a.4.4 0 0 0-.22-.062H1.754a.417.417 0 0 1 0-.834H3.32a1.25 1.25 0 0 1 1.119.691l.904 1.81H9.67v-.001a2.498 2.498 0 1 1 1.593 2.33l-3.26 3.26v4.41h1.25a.417.417 0 1 1 0 .834H5.92a.417.417 0 1 1 0-.834h1.25V9.422L3.003 5.256a.834.834 0 0 1 .59-1.423h.819l-.719-1.436a.4.4 0 0 0-.153-.168m8.4 3.256a1.665 1.665 0 1 0-1.437-1.652h1.078a.834.834 0 0 1 .59 1.423zm-1.835-.818h1.475L7.588 8.66 3.593 4.667h6.512"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCocktailOff.displayName = 'CocktailOff';
const Memo = memo(SvgCocktailOff);
export default Memo;
