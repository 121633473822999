// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAudioMusicPlaylistMusicalNoteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.789 2.557c.107-.03.213.05.213.16v6.05a2.8 2.8 0 0 0-1.5-.434c-1.292 0-2.5.889-2.5 2.167s1.208 2.167 2.5 2.167c1.29 0 2.5-.889 2.5-2.167V2.717c0-.775-.742-1.334-1.488-1.122l-5.668 1.62c-.5.143-.846.6-.846 1.121v6.43a2.8 2.8 0 0 0-1.5-.433c-1.291 0-2.5.889-2.5 2.167s1.209 2.167 2.5 2.167S7 13.778 7 12.5V4.336c0-.074.05-.14.12-.16z" />
    </Svg>
  );
};
SvgAudioMusicPlaylistMusicalNoteFill.displayName = 'AudioMusicPlaylistMusicalNoteFill';
const Memo = memo(SvgAudioMusicPlaylistMusicalNoteFill);
export default Memo;
