// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgToastBreakfestFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.999 2c-1.774 0-3.403.407-4.608 1.09C2.202 3.765 1.332 4.775 1.332 6c0 .959.536 1.789 1.333 2.423v3.744C2.665 13.179 3.486 14 4.5 14h7c1.012 0 1.833-.82 1.833-1.833V8.423c.797-.634 1.333-1.464 1.333-2.423 0-1.225-.87-2.235-2.059-2.91C11.402 2.407 9.772 2 7.999 2" />
    </Svg>
  );
};
SvgToastBreakfestFill.displayName = 'ToastBreakfestFill';
const Memo = memo(SvgToastBreakfestFill);
export default Memo;
