// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBombBoomFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.165.667a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0v-1a.5.5 0 0 1 .5-.5M12.019 3.98a.5.5 0 0 1 0 .707l-1.245 1.245a5.333 5.333 0 1 1-.707-.707l1.245-1.245a.5.5 0 0 1 .707 0M13.832 4.333a.5.5 0 0 0 0 1h1a.5.5 0 1 0 0-1zM14.519 1.48a.5.5 0 0 1 0 .707l-.833.833a.5.5 0 1 1-.707-.707l.833-.833a.5.5 0 0 1 .707 0" />
    </Svg>
  );
};
SvgBombBoomFill.displayName = 'BombBoomFill';
const Memo = memo(SvgBombBoomFill);
export default Memo;
