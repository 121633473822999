// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSidebarMenuListWindowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3.667a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833H7V3.667zm4.834 0v8.666h4.833c.46 0 .833-.373.833-.833v-7a.833.833 0 0 0-.833-.833zM1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5z"
        clipRule="evenodd"
      />
      <Path d="M4.667 6a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M4.667 8.334a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667M4.667 10.667a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667" />
      <Path
        fillRule="evenodd"
        d="M4.665 6.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m0 2.334a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334m0 2.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m0-5a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667m0 2.333a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666m-.333 2a.333.333 0 1 0 .667 0 .333.333 0 0 0-.667 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSidebarMenuListWindowOutline.displayName = 'SidebarMenuListWindowOutline';
const Memo = memo(SvgSidebarMenuListWindowOutline);
export default Memo;
