// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStudioDisplayThunderboltFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v5.667c0 1.012-.82 1.833-1.833 1.833h-2.167v1.5a.5.5 0 0 1-.5.5H5.832a.5.5 0 0 1-.5-.5V12H3.165a1.833 1.833 0 0 1-1.833-1.833zm5 7.5v1h3.333v-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStudioDisplayThunderboltFill.displayName = 'StudioDisplayThunderboltFill';
const Memo = memo(SvgStudioDisplayThunderboltFill);
export default Memo;
