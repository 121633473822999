// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCropOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.832 1.333a.5.5 0 0 1 .5.5v1.5h6.5c1.013 0 1.833.821 1.833 1.834v6.5h1.5a.5.5 0 1 1 0 1h-1.5v1.5a.5.5 0 0 1-1 0v-1.5h-6.5a1.833 1.833 0 0 1-1.833-1.834v-6.5h-1.5a.5.5 0 0 1 0-1h1.5v-1.5a.5.5 0 0 1 .5-.5m.5 3v6.5c0 .46.373.834.833.834h6.5v-6.5a.833.833 0 0 0-.833-.834z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCropOutline.displayName = 'CropOutline';
const Memo = memo(SvgCropOutline);
export default Memo;
