// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDisk1SaveFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.667 2h-.834C2.821 2 2 2.82 2 3.833v8.334C2 13.179 2.82 14 3.833 14h.834V8.5a.5.5 0 0 1 .5-.5h5.666a.5.5 0 0 1 .5.5V14h.834C13.179 14 14 13.18 14 12.167V5.052c0-.486-.193-.952-.537-1.296l-1.219-1.219a1.83 1.83 0 0 0-.91-.496V5.5a.5.5 0 0 1-.5.5H5.166a.5.5 0 0 1-.5-.5z" />
      <Path d="M10.333 2H5.667v3h4.666zM10.333 14V9H5.667v5z" />
    </Svg>
  );
};
SvgDisk1SaveFill.displayName = 'Disk1SaveFill';
const Memo = memo(SvgDisk1SaveFill);
export default Memo;
