import type {
  BorderRadiuses,
  Breakpoints,
  Colors,
  Elevations,
  Spacings,
  Theme as RequiredTheme,
  Typographies,
} from '@propertyguru/hive-theme';

import type {Tokenize} from './Tokenize';

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export interface RequiredOption {
  theme: {
    light: RequiredTheme;
    dark: RequiredTheme;
    highContrast: RequiredTheme;
  };
}

export type Theme = DeepPartial<RequiredTheme>;
export type Option = DeepPartial<RequiredOption>;

export enum ThemeVariant {
  Light = 'ThemeVariant.Light',
  Dark = 'ThemeVariant.Dark',
  HighContrast = 'ThemeVariant.HighContrast',
}

export interface Props {
  variant?: ThemeVariant;
}

export type FillColor = Tokenize<Pick<Colors<unknown>, 'fill' | 'surface'>>;
export type TextColor = Tokenize<Pick<Colors<unknown>, 'text'>>;
export type BorderColor = Tokenize<Pick<Colors<unknown>, 'border'>>;
export type IconColor = Tokenize<Pick<Colors<unknown>, 'icon'>>;
export type Color = FillColor | TextColor | BorderColor | IconColor;
export type Elevation = Tokenize<Elevations<unknown>>;
export type BorderRadius = Tokenize<BorderRadiuses>;
export type Breakpoint = Tokenize<Breakpoints>;
export type Spacing = Tokenize<Spacings>;
export type Typography = Tokenize<Typographies<unknown>>;
