// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDownloadSquareArrowDownFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zm3.833 7.5a.5.5 0 1 1 0-1h4.334a.5.5 0 0 1 0 1zm4.02-3.48-1.5 1.5a.5.5 0 0 1-.707 0l-1.5-1.5a.5.5 0 1 1 .708-.707l.646.647V5.167a.5.5 0 1 1 1 0v2.626l.646-.647a.5.5 0 1 1 .708.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDownloadSquareArrowDownFill.displayName = 'DownloadSquareArrowDownFill';
const Memo = memo(SvgDownloadSquareArrowDownFill);
export default Memo;
