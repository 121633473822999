// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAddSlideAddPageAddCopyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 2.5c0-.644.522-1.167 1.167-1.167h8.333c.644 0 1.167.523 1.167 1.167v1.574h1.5c.644 0 1.166.522 1.166 1.167V13.5c0 .644-.522 1.167-1.166 1.167H5.165A1.167 1.167 0 0 1 4 13.5V12h-1.5a1.167 1.167 0 0 1-1.167-1.167zm9.667 1.574H5.165C4.521 4.074 4 4.596 4 5.241V11h-1.5a.167.167 0 0 1-.167-.167V2.5c0-.092.075-.167.167-.167h8.333c.092 0 .167.075.167.167zm-1.13 3.093a.5.5 0 0 0-1 0V8.87H7.165a.5.5 0 0 0 0 1H8.87v1.63a.5.5 0 1 0 1 0V9.87h1.63a.5.5 0 0 0 0-1h-1.63z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAddSlideAddPageAddCopyFill.displayName = 'AddSlideAddPageAddCopyFill';
const Memo = memo(SvgAddSlideAddPageAddCopyFill);
export default Memo;
