// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAlbumsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.362 3.049a.833.833 0 0 0-1.029.81v8.282c0 .539.505.936 1.029.81l3-.723a.83.83 0 0 0 .638-.81V4.582a.83.83 0 0 0-.638-.81zm-2.029.81c0-1.186 1.11-2.06 2.263-1.782l3 .722A1.83 1.83 0 0 1 14 4.582v6.836c0 .848-.58 1.584-1.404 1.783l-3 .722a1.833 1.833 0 0 1-2.263-1.782zM5.167 2.667a.5.5 0 0 1 .5.5v9.666a.5.5 0 1 1-1 0V3.167a.5.5 0 0 1 .5-.5M2.5 3.333a.5.5 0 0 1 .5.5v8.334a.5.5 0 0 1-1 0V3.833a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAlbumsFill.displayName = 'AlbumsFill';
const Memo = memo(SvgAlbumsFill);
export default Memo;
