// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgImageWallpaperLandscapeOsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v3.698l.132-.073.378-.208c.492-.27 1.05-.572 1.631-.852C5.611 6.515 6.915 6 8 6s2.388.515 3.525 1.065c.58.28 1.14.581 1.632.852l.377.208.132.073V4.5a.833.833 0 0 0-.833-.833zm10.5 5.67c-.19-.102-.403-.22-.63-.344l-.36-.2a34 34 0 0 0-1.586-.828c-1.029-.497-2.044-.885-2.845-.954a8 8 0 0 0-.57.94c-.16.317-.288.64-.34.924-.053.29-.016.47.054.575.036.055.144.14.499.166.283.02.609-.002.98-.028l.245-.016c.44-.028.952-.051 1.387.057.226.055.458.152.655.323.203.176.344.409.413.69.133.534-.046.993-.326 1.34a2.5 2.5 0 0 1-.352.351h1.943c.46 0 .833-.373.833-.833zm-5.047 2.996.043-.012a6.3 6.3 0 0 0 1.114-.446c.295-.159.54-.336.688-.52.137-.171.173-.316.134-.473a.3.3 0 0 0-.098-.174.6.6 0 0 0-.24-.109c-.263-.065-.626-.059-1.083-.03l-.214.015c-.372.027-.787.056-1.149.03-.422-.032-.953-.149-1.26-.612-.272-.412-.278-.895-.202-1.307.076-.417.249-.837.43-1.196q.091-.179.185-.342c-.634.17-1.343.463-2.058.808-.556.27-1.097.56-1.585.828l-.361.2c-.226.125-.439.242-.63.345V11.5c0 .46.373.833.833.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgImageWallpaperLandscapeOsOutline.displayName = 'ImageWallpaperLandscapeOsOutline';
const Memo = memo(SvgImageWallpaperLandscapeOsOutline);
export default Memo;
