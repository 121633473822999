// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgYogaOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.277 6.707c-.282 0-.511.21-.511.47s.229.471.51.471h2.937c.211 0 .383.158.383.353v1.882a.4.4 0 0 1-.082.233.6.6 0 0 1-.096.097 1.5 1.5 0 0 1-.182.122c-.112.066-.26.141-.406.217q-.023.01-.043.022c-.35.18-.677.354-.835.5-.148.137-.198.257-.208.477-.005.122.003.268.017.46l.01.125c.012.161.027.347.037.555v.016c0 .3.315.588.69.588.337 0 .587-.253.587-.541V12c0-.15.102-.283.255-.333l1.583-.517a.4.4 0 0 1 .436.123l1.425 1.782c.137.155.334.24.514.24.515 0 .82-.57.513-.939l-.008-.01-1.379-1.789a.3.3 0 0 1-.056-.107v-.002l-.002-.003-.003-.012-.05-.182a1.7 1.7 0 0 1-.061-.386l.102-1.883c.01-.187.178-.335.382-.335h2.987c.282 0 .511-.21.511-.47s-.229-.471-.51-.471zm6.81 3.494 1.336 1.732c.706.855-.009 2.068-1.125 2.068a1.5 1.5 0 0 1-1.125-.52L7.91 11.903l-1.06.347v.504c0 .653-.567 1.247-1.353 1.247-.746 0-1.45-.555-1.455-1.285-.01-.195-.023-.367-.036-.525l-.01-.131a5 5 0 0 1-.018-.538c.016-.357.12-.66.431-.947.25-.23.687-.455 1-.616l.003-.002.038-.019c.154-.08.282-.145.374-.199l.005-.002V8.354H3.277C2.572 8.354 2 7.827 2 7.177s.572-1.176 1.277-1.176h9.446c.705 0 1.277.527 1.277 1.176 0 .65-.572 1.177-1.277 1.177H10.1l-.083 1.534.002.016.008.052a2 2 0 0 0 .06.245M5.91 9.685"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M6 4.001a2 2 0 1 1 4 0 2 2 0 0 1-4 0m2-1.2a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgYogaOutline.displayName = 'YogaOutline';
const Memo = memo(SvgYogaOutline);
export default Memo;
