// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeDoorWindowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.416 2.15a.83.83 0 0 0-.832 0L3.418 4.549A.83.83 0 0 0 3 5.27v6.896c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V5.27a.83.83 0 0 0-.418-.722zm-1.33-.867a1.83 1.83 0 0 1 1.829 0l4.166 2.4c.569.326.919.932.919 1.588v6.896C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167V5.27c0-.656.35-1.262.919-1.589zM8 6.667a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.5 1.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHomeDoorWindowOutline.displayName = 'HomeDoorWindowOutline';
const Memo = memo(SvgHomeDoorWindowOutline);
export default Memo;
