// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBranchesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.001 1.333a2.333 2.333 0 0 0-.5 4.613v4.108a2.334 2.334 0 1 0 1 0v-.72c0-.46.373-.834.834-.834h3.333c1.013 0 1.833-.82 1.833-1.833v-.72a2.334 2.334 0 1 0-1 0v.72c0 .46-.373.833-.833.833H6.335c-.3 0-.584.072-.834.2V5.946a2.334 2.334 0 0 0-.5-4.613" />
    </Svg>
  );
};
SvgBranchesFill.displayName = 'BranchesFill';
const Memo = memo(SvgBranchesFill);
export default Memo;
