// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCursorClickArrowClickbaitOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.332 1.334a.5.5 0 0 1 .5.5V3a.5.5 0 1 1-1 0V1.834a.5.5 0 0 1 .5-.5M3.041 3.042a.5.5 0 0 1 .707 0l.873.874a.5.5 0 1 1-.707.707l-.873-.874a.5.5 0 0 1 0-.707m8.583 0a.5.5 0 0 1 0 .707l-.874.874a.5.5 0 1 1-.707-.707l.873-.874a.5.5 0 0 1 .707 0M7.52 7.376l2.247 6.117 1.254-2.166a.83.83 0 0 1 .304-.304l2.196-1.271zm-1.067-.005A.833.833 0 0 1 7.542 6.31l6.667 2.64a.833.833 0 0 1 .111 1.495l-2.455 1.422-1.41 2.436a.833.833 0 0 1-1.504-.13zm-5.121-.038a.5.5 0 0 1 .5-.5h1.167a.5.5 0 1 1 0 1H1.832a.5.5 0 0 1-.5-.5m3.29 2.711a.5.5 0 0 1 0 .707l-.874.874a.5.5 0 0 1-.707-.707l.873-.874a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCursorClickArrowClickbaitOutline.displayName = 'CursorClickArrowClickbaitOutline';
const Memo = memo(SvgCursorClickArrowClickbaitOutline);
export default Memo;
