// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVolumeOffSpeakerLoudSoundOffMusicFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.348 2.141a.833.833 0 0 1 1.32.676v10.366a.833.833 0 0 1-1.32.676l-3.29-2.369a.83.83 0 0 0-.487-.157h-1.07A1.833 1.833 0 0 1 .668 9.5v-3c0-1.013.82-1.833 1.833-1.833h1.07a.83.83 0 0 0 .487-.157zM14.688 7.02a.5.5 0 0 0-.707-.707l-1.06 1.06-1.061-1.06a.5.5 0 0 0-.707.707l1.06 1.06-1.06 1.062a.5.5 0 0 0 .707.707l1.06-1.061 1.061 1.06a.5.5 0 1 0 .707-.706l-1.06-1.061z" />
    </Svg>
  );
};
SvgVolumeOffSpeakerLoudSoundOffMusicFill.displayName = 'VolumeOffSpeakerLoudSoundOffMusicFill';
const Memo = memo(SvgVolumeOffSpeakerLoudSoundOffMusicFill);
export default Memo;
