// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSnowCloudOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.667 12a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M4.667 12a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M6.667 13.333a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M12.667 12a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M10.667 13.333a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0" />
      <Path
        fillRule="evenodd"
        d="M2 6.333C2 3.947 3.91 2 6.281 2c1.494 0 2.807.775 3.571 1.943.081.123.258.206.442.18q.224-.032.456-.032c1.801 0 3.25 1.478 3.25 3.288s-1.449 3.288-3.25 3.288H6.281C3.911 10.667 2 8.72 2 6.333M6.281 3C4.475 3 3 4.486 3 6.333s1.475 3.334 3.281 3.334h4.469c1.236 0 2.25-1.018 2.25-2.288S11.986 5.09 10.75 5.09q-.161 0-.315.022c-.53.075-1.105-.143-1.42-.623A3.27 3.27 0 0 0 6.282 3"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSnowCloudOutline.displayName = 'SnowCloudOutline';
const Memo = memo(SvgSnowCloudOutline);
export default Memo;
