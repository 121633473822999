// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSharerightFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.737 2.977v2.195c-2.658.063-4.505.646-5.658 2.044C.924 8.615.57 10.712.57 13.5a.5.5 0 0 0 .948.223l.015-.031c.493-.985.807-1.613 1.567-2.062.778-.459 2.085-.76 4.637-.794v2.187c0 .872 1.039 1.326 1.679.734l5.43-5.023a1 1 0 0 0 0-1.468l-5.43-5.024c-.64-.592-1.679-.138-1.679.735" />
    </Svg>
  );
};
SvgSharerightFill.displayName = 'SharerightFill';
const Memo = memo(SvgSharerightFill);
export default Memo;
