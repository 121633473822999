// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWhisperApiFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v1a.5.5 0 0 1-1 0v-1a.833.833 0 0 0-.833-.833H3.165a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h3.667a.5.5 0 1 1 0 1H3.165A1.833 1.833 0 0 1 1.332 11.5zm10.667 2.833a.5.5 0 0 1 .5.5c0 .923.204 1.473.532 1.801s.878.533 1.801.533a.5.5 0 1 1 0 1c-.923 0-1.473.204-1.8.532-.329.328-.533.878-.533 1.801a.5.5 0 0 1-1 0c0-.923-.205-1.473-.533-1.801s-.878-.532-1.8-.532a.5.5 0 1 1 0-1c.922 0 1.472-.205 1.8-.533s.533-.878.533-1.8a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
      <Path d="M4.665 5.333a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M6.665 5.333a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M7.999 6a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333" />
    </Svg>
  );
};
SvgWhisperApiFill.displayName = 'WhisperApiFill';
const Memo = memo(SvgWhisperApiFill);
export default Memo;
