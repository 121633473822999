// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgNoFlashOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.222 4.853 2.165 2.127a.5.5 0 0 0-.666.746l12.334 11a.5.5 0 0 0 .665-.746l-3.15-2.81 2.535-3.677a.833.833 0 0 0-.686-1.307H9.33V1.57c0-.817-1.055-1.146-1.519-.473zm.752.671 4.62 4.122 2.285-3.313H9.165A.833.833 0 0 1 8.33 5.5V2.106z"
        clipRule="evenodd"
      />
      <Path d="m4.078 6.512.752.67-1.713 2.485h3.714c.46 0 .834.373.834.833v3.394l1.786-2.59.752.671-2.019 2.928c-.464.673-1.52.344-1.52-.473v-3.763H2.8a.833.833 0 0 1-.686-1.307z" />
    </Svg>
  );
};
SvgNoFlashOutline.displayName = 'NoFlashOutline';
const Memo = memo(SvgNoFlashOutline);
export default Memo;
