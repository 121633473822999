// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUploadShareFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2a.5.5 0 0 1 .354.146l3 3a.5.5 0 0 1-.708.708L8.5 3.707V10a.5.5 0 0 1-1 0V3.707L5.354 5.854a.5.5 0 1 1-.708-.708l3-3A.5.5 0 0 1 8 2M2.5 8a.5.5 0 0 1 .5.5v3.667c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833V8.5a.5.5 0 0 1 1 0v3.667C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167V8.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUploadShareFill.displayName = 'UploadShareFill';
const Memo = memo(SvgUploadShareFill);
export default Memo;
