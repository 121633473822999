// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFocusCameraExposureLockFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3 3.833c0-.46.373-.833.833-.833h1.5a.5.5 0 1 0 0-1h-1.5C2.821 2 2 2.82 2 3.833v1.5a.5.5 0 1 0 1 0zM10.667 2a.5.5 0 1 0 0 1h1.5c.46 0 .833.373.833.833v1.5a.5.5 0 0 0 1 0v-1.5C14 2.821 13.18 2 12.167 2zM3 10.667a.5.5 0 1 0-1 0v1.5C2 13.179 2.82 14 3.833 14h1.5a.5.5 0 0 0 0-1h-1.5A.833.833 0 0 1 3 12.167zM14 10.667a.5.5 0 1 0-1 0v1.5c0 .46-.373.833-.833.833h-1.5a.5.5 0 1 0 0 1h1.5C13.179 14 14 13.18 14 12.167z" />
      <Path
        fillRule="evenodd"
        d="M10 6.779v-.112a2 2 0 1 0-4 0v.112a1.17 1.17 0 0 0-.667 1.054V9.5c0 .644.523 1.167 1.167 1.167h3c.644 0 1.167-.523 1.167-1.167V7.833c0-.465-.273-.867-.667-1.054m-3-.112h2a1 1 0 1 0-2 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFocusCameraExposureLockFill.displayName = 'FocusCameraExposureLockFill';
const Memo = memo(SvgFocusCameraExposureLockFill);
export default Memo;
