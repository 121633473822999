// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgKitchenOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.984 1.983v1.62H5.34v-1.62zM5.83 1H1.926A.926.926 0 0 0 1 1.925v1.736c0 .511.414.926.926.926h12.149A.926.926 0 0 0 15 3.66V1.925A.926.926 0 0 0 14.075 1H5.83m8.186 2.603h-3.355v-1.62h3.355zm-4.338 0v-1.62H6.323v1.62zM2.36 9.504a.376.376 0 0 0-.375.376v.955h12.033V9.88a.376.376 0 0 0-.376-.376H2.36m8.073-.983h-2.54a2.66 2.66 0 0 0 .596-1.678.49.49 0 0 0-.492-.492H3.657a.49.49 0 0 0-.491.492c0 .614.212 1.206.595 1.678H2.36C1.609 8.52 1 9.129 1 9.88v3.76c0 .75.609 1.36 1.36 1.36H13.64c.75 0 1.36-.61 1.36-1.36V9.88c0-.75-.61-1.36-1.36-1.36h-.274l.316-1.58a.492.492 0 0 0-.482-.589h-2.603a.492.492 0 0 0-.483.588zm1.932 0h-.93l-.237-1.186h1.404zm-5.35-.492c-.311.311-.732.487-1.17.492H5.81a1.68 1.68 0 0 1-1.588-1.186H7.43c-.08.26-.222.499-.418.694m2.665 3.79H6.323v2.198h3.356zm.983 2.198v-2.199h3.355v1.823a.376.376 0 0 1-.376.376zm-8.678-2.199H5.34v2.199H2.36a.376.376 0 0 1-.376-.376z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgKitchenOutline.displayName = 'KitchenOutline';
const Memo = memo(SvgKitchenOutline);
export default Memo;
