// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalendarCheckFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.667 1.833a.5.5 0 0 0-1 0v.834h-.834C2.821 2.667 2 3.487 2 4.5v7.667C2 13.179 2.82 14 3.833 14H7.5a.5.5 0 0 0 0-1H3.833A.833.833 0 0 1 3 12.167v-5.5h10v1.5a.5.5 0 0 0 1 0V4.5c0-1.013-.82-1.833-1.833-1.833h-.834v-.834a.5.5 0 1 0-1 0v.834H5.667z" />
      <Path d="M14.56 10.81a.5.5 0 0 0-.786-.62l-2.538 3.222-1.046-1.063a.5.5 0 0 0-.713.702l1.444 1.467a.5.5 0 0 0 .75-.042z" />
    </Svg>
  );
};
SvgCalendarCheckFill.displayName = 'CalendarCheckFill';
const Memo = memo(SvgCalendarCheckFill);
export default Memo;
