// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSlidesPagesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 3.667a.833.833 0 0 0-.833.833v4.333c0 .46.373.834.833.834H4v-2.5c0-1.013.82-1.834 1.833-1.834h5.167V4.5a.833.833 0 0 0-.834-.833zM12 5.333V4.5c0-1.012-.821-1.833-1.834-1.833h-7c-1.012 0-1.833.82-1.833 1.833v4.333c0 1.013.82 1.834 1.833 1.834H4v.833c0 1.013.82 1.833 1.833 1.833h7c1.013 0 1.833-.82 1.833-1.833V7.167c0-1.013-.82-1.834-1.833-1.834zm-6.167 1a.833.833 0 0 0-.833.834V11.5c0 .46.373.833.833.833h7c.46 0 .833-.373.833-.833V7.167a.833.833 0 0 0-.833-.834z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSlidesPagesOutline.displayName = 'SlidesPagesOutline';
const Memo = memo(SvgSlidesPagesOutline);
export default Memo;
