// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBezierVectorNodesEditCursorFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.694 8.748a.853.853 0 0 1 1.054-1.054l4.555 1.301c.735.21.843 1.208.17 1.571l-1.89 1.017-1.017 1.89c-.363.673-1.36.565-1.571-.17zm1.034-.02 1.13 3.952.866-1.61c.079-.146.2-.267.346-.346l1.61-.867z"
        clipRule="evenodd"
      />
      <Path d="M3.167 2C2.522 2 2 2.522 2 3.167v1.666C2 5.478 2.522 6 3.167 6H3.5v4h-.333C2.522 10 2 10.522 2 11.167v1.666C2 13.478 2.522 14 3.167 14h1.5c.644 0 1.166-.522 1.166-1.167V12.5h1a.5.5 0 0 0 0-1h-1v-.333c0-.645-.522-1.167-1.166-1.167H4.5V6h.333C5.478 6 6 5.478 6 4.833V4.5h4v.333C10 5.478 10.522 6 11.167 6h.333v.833a.5.5 0 0 0 1 0V6h.333C13.478 6 14 5.478 14 4.833V3.167C14 2.522 13.478 2 12.833 2h-1.666C10.522 2 10 2.522 10 3.167V3.5H6v-.333C6 2.522 5.478 2 4.833 2z" />
    </Svg>
  );
};
SvgBezierVectorNodesEditCursorFill.displayName = 'BezierVectorNodesEditCursorFill';
const Memo = memo(SvgBezierVectorNodesEditCursorFill);
export default Memo;
