// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCamera1OffPictureImageCamFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.186 1.48a.5.5 0 1 0-.708.707L3.292 4h-.127c-1.012 0-1.833.82-1.833 1.833v6.334c0 1.012.82 1.833 1.833 1.833h9.667q.213 0 .413-.047l.567.567a.5.5 0 1 0 .707-.707zm7.265 8.68L6.172 6.88a2.333 2.333 0 0 0 3.279 3.279"
        clipRule="evenodd"
      />
      <Path d="M14.665 12.167q0 .178-.032.346L5.286 3.167l.234-.35C5.86 2.306 6.433 2 7.046 2h1.906c.613 0 1.185.306 1.525.816l.542.813a.83.83 0 0 0 .693.371h1.12c1.013 0 1.833.82 1.833 1.833z" />
    </Svg>
  );
};
SvgCamera1OffPictureImageCamFill.displayName = 'Camera1OffPictureImageCamFill';
const Memo = memo(SvgCamera1OffPictureImageCamFill);
export default Memo;
