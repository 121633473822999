// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgForwardsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.403 5.992C3.273 3.667 5.439 2 7.995 2 10.023 2 11.96 3.06 13 4.733V3.167a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1h1.873C11.606 4.077 9.862 3 7.995 3 5.893 3 4.076 4.372 3.34 6.342a.5.5 0 0 1-.937-.35"
        clipRule="evenodd"
      />
      <Path d="M5.441 13.437V9.324h-.025l-.58.41c-.182.119-.288.157-.41.157a.396.396 0 0 1-.406-.406q.001-.265.325-.479l.758-.529c.305-.207.546-.292.783-.292.41 0 .685.284.685.711v4.541c0 .39-.207.614-.567.614-.355 0-.563-.229-.563-.614M7.57 11.254v-.271c0-1.735.83-2.87 2.205-2.87 1.38 0 2.192 1.122 2.192 2.87v.27c0 1.731-.838 2.87-2.21 2.87-1.37 0-2.187-1.126-2.187-2.87m1.143-.267v.258c0 1.219.397 1.947 1.053 1.947s1.054-.728 1.054-1.947v-.258c0-1.215-.398-1.942-1.054-1.942s-1.053.723-1.053 1.942" />
    </Svg>
  );
};
SvgForwardsOutline.displayName = 'ForwardsOutline';
const Memo = memo(SvgForwardsOutline);
export default Memo;
