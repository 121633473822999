// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayoutTopGridWindowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.613 2h6.774c.356 0 .653 0 .896.02.253.02.491.065.716.18.345.176.625.456.801.801.115.225.16.463.18.716.02.243.02.54.02.896v6.774c0 .356 0 .653-.02.896-.02.253-.065.491-.18.716A1.83 1.83 0 0 1 13 13.8c-.225.115-.463.16-.716.18-.243.02-.54.02-.896.02H4.613c-.356 0-.653 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.2 13a1.9 1.9 0 0 1-.18-.716C2 12.04 2 11.743 2 11.387V4.613c0-.356 0-.653.02-.896.02-.253.065-.491.18-.716.176-.345.456-.625.801-.801.225-.115.463-.16.716-.18.243-.02.54-.02.896-.02M3 7v4.367c0 .381 0 .637.017.835.015.191.043.283.074.343.08.157.207.284.364.364.06.03.152.059.343.074.198.017.454.017.835.017h6.734c.381 0 .637 0 .835-.017.191-.015.283-.043.343-.074a.83.83 0 0 0 .364-.364c.03-.06.059-.152.074-.343.017-.198.017-.454.017-.835V7zm10-1H3V4.633c0-.381 0-.637.017-.835.015-.191.043-.283.074-.343a.83.83 0 0 1 .364-.364c.06-.03.152-.059.343-.074C3.996 3 4.252 3 4.633 3h6.734c.381 0 .637 0 .835.017.191.015.283.043.343.074.157.08.284.207.364.364.03.06.059.152.074.343.017.198.017.454.017.835z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLayoutTopGridWindowOutline.displayName = 'LayoutTopGridWindowOutline';
const Memo = memo(SvgLayoutTopGridWindowOutline);
export default Memo;
