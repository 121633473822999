// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalendarDaysOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zm1 1.834v6.5c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833v-6.5zm10-1H3v-.834c0-.46.373-.833.833-.833h8.334c.46 0 .833.373.833.833z"
        clipRule="evenodd"
      />
      <Path d="M4.664 8a.667.667 0 1 1 1.333 0 .667.667 0 0 1-1.333 0M4.664 10.667a.667.667 0 1 1 1.333 0 .667.667 0 0 1-1.333 0M7.33 10.667a.667.667 0 1 1 1.334 0 .667.667 0 0 1-1.333 0M7.33 8a.667.667 0 1 1 1.334 0 .667.667 0 0 1-1.333 0M9.997 8a.667.667 0 1 1 1.334 0 .667.667 0 0 1-1.334 0" />
    </Svg>
  );
};
SvgCalendarDaysOutline.displayName = 'CalendarDaysOutline';
const Memo = memo(SvgCalendarDaysOutline);
export default Memo;
