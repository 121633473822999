// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAirpodCaseAirpodsFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.833 2A2.833 2.833 0 0 0 2 4.833V6h12V4.833A2.833 2.833 0 0 0 11.167 2z" />
      <Path
        fillRule="evenodd"
        d="M14 7H2v4.167A2.833 2.833 0 0 0 4.833 14h6.334A2.833 2.833 0 0 0 14 11.167zM8 9.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAirpodCaseAirpodsFill.displayName = 'AirpodCaseAirpodsFill';
const Memo = memo(SvgAirpodCaseAirpodsFill);
export default Memo;
