// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgImacComputerDevice2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2h9.667c1.013 0 1.833.82 1.833 1.833v6.334c0 1.012-.82 1.833-1.833 1.833H9.999v2.167a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V12H3.165a1.833 1.833 0 0 1-1.833-1.833zm1 5.5v.834c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833v-.834zm11.333-1H2.332v-4.5c0-.46.373-.833.833-.833h9.667c.46 0 .833.373.833.833zM7 12v1.667h2V12z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgImacComputerDevice2Outline.displayName = 'ImacComputerDevice2Outline';
const Memo = memo(SvgImacComputerDevice2Outline);
export default Memo;
