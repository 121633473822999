// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {ClipPath,Defs, G, Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLineBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          fillRule="evenodd"
          d="M12.378 15.975H3.619A3.596 3.596 0 0 1 .023 12.38V3.62A3.596 3.596 0 0 1 3.62.025h8.759a3.596 3.596 0 0 1 3.596 3.596v8.759a3.596 3.596 0 0 1-3.596 3.595m.937-8.726c0-2.38-2.385-4.316-5.317-4.316S2.68 4.87 2.68 7.25c0 2.133 1.892 3.92 4.447 4.258.173.037.409.114.469.262.053.134.035.345.017.48l-.076.456-.002.012c-.025.143-.09.507.463.275.567-.24 3.062-1.804 4.178-3.088.77-.845 1.14-1.703 1.14-2.655M6.017 8.623a.1.1 0 0 0 .102-.102v-.377a.1.1 0 0 0-.102-.101H5.002V6.2a.1.1 0 0 0-.101-.102h-.378a.1.1 0 0 0-.101.102v2.32c0 .056.045.102.101.102h1.494m5.532 0h-1.494a.1.1 0 0 1-.102-.102v-2.32c0-.057.046-.102.102-.102h1.494c.056 0 .101.046.101.102v.377a.1.1 0 0 1-.101.101h-1.016v.392h1.016c.056 0 .101.046.101.101v.378a.1.1 0 0 1-.101.101h-1.016v.392h1.016c.056 0 .101.045.101.101v.377a.1.1 0 0 1-.101.102m-5.01-2.524h.377c.056 0 .102.046.102.102v2.32a.1.1 0 0 1-.102.102H6.54a.1.1 0 0 1-.101-.102v-2.32c0-.056.045-.102.101-.102m2.947 0h-.377a.1.1 0 0 0-.102.102v1.378L7.946 6.145l-.009-.01v-.001l-.006-.007-.002-.001-.006-.005-.003-.002-.005-.004-.003-.002-.006-.003-.003-.001-.006-.003-.003-.001-.006-.002h-.004L7.878 6.1h-.004l-.006-.002h-.384a.1.1 0 0 0-.101.102v2.32c0 .056.045.102.101.102h.377a.1.1 0 0 0 .102-.102V7.143L9.026 8.58q.011.015.026.026h.002l.006.004.003.002.005.002.005.003h.003l.003.002.004.001h.001l.027.004h.375a.1.1 0 0 0 .102-.102v-2.32a.1.1 0 0 0-.102-.102"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
SvgLineBw.displayName = 'LineBw';
const Memo = memo(SvgLineBw);
export default Memo;
