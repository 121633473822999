// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPlayListFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.668 3.167a.5.5 0 0 0-1 0v6.266a2.8 2.8 0 0 0-1.5-.433c-1.291 0-2.5.889-2.5 2.167s1.209 2.166 2.5 2.166c1.292 0 2.5-.888 2.5-2.166zM1.832 3.333a.5.5 0 0 0 0 1h9.007a.5.5 0 0 0 0-1zM1.832 7.5a.5.5 0 0 0 0 1h5.667a.5.5 0 0 0 0-1zM1.832 11.667a.5.5 0 0 0 0 1h4.333a.5.5 0 1 0 0-1z" />
    </Svg>
  );
};
SvgPlayListFill.displayName = 'PlayListFill';
const Memo = memo(SvgPlayListFill);
export default Memo;
