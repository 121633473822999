// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEmail2EnvelopeMailFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M14.665 4.935V4.5c0-1.013-.82-1.833-1.833-1.833H3.165c-1.012 0-1.833.82-1.833 1.833v.435a9.47 9.47 0 0 0 6.667 2.732 9.47 9.47 0 0 0 6.666-2.732" />
      <Path d="M14.665 6.28A10.46 10.46 0 0 1 8 8.666c-2.532 0-4.854-.896-6.667-2.388V11.5c0 1.012.82 1.833 1.833 1.833h9.667c1.013 0 1.833-.82 1.833-1.833z" />
    </Svg>
  );
};
SvgEmail2EnvelopeMailFill.displayName = 'Email2EnvelopeMailFill';
const Memo = memo(SvgEmail2EnvelopeMailFill);
export default Memo;
