// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPoliceOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.576 3.424a.75.75 0 0 1 .462.07l.955.483.956-.482a.75.75 0 0 1 .876.144l.494.5a.77.77 0 0 1 .22.596.78.78 0 0 1-.3.56l-.288-.387.275.395a.43.43 0 0 0-.18.35v.002c0 .216.065.355.163.527l.03.054c.097.164.258.439.258.783a.9.9 0 0 1-.121.427 1.7 1.7 0 0 1-.228.307c-.17.185-.392.365-.624.521a4.4 4.4 0 0 1-.747.407c-.245.103-.524.186-.784.186s-.538-.083-.783-.186a4.4 4.4 0 0 1-.748-.407 3.6 3.6 0 0 1-.623-.52 1.7 1.7 0 0 1-.228-.308.9.9 0 0 1-.121-.427c0-.344.16-.62.257-.783q.017-.03.03-.054c.098-.172.163-.311.163-.527v-.002a.43.43 0 0 0-.191-.358.77.77 0 0 1-.302-.56.78.78 0 0 1 .22-.596l.494-.5a.76.76 0 0 1 .415-.215m2.916 1.245-.243-.245-1.042.526a.47.47 0 0 1-.427 0l-1.043-.526-.242.245a1.4 1.4 0 0 1 .401.986c0 .466-.16.781-.288 1.007-.112.198-.149.264-.159.318a1 1 0 0 0 .09.115c.105.114.264.247.454.375.188.127.393.239.581.318.198.082.342.112.42.112s.22-.03.418-.112c.189-.079.393-.191.582-.318.19-.128.348-.26.453-.375a1 1 0 0 0 .09-.115c-.01-.054-.047-.12-.159-.318a1.94 1.94 0 0 1-.288-1.007h.479-.479a1.4 1.4 0 0 1 .402-.986m-2.596.986h-.478zm-.058-1.333"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M8.003 1.333c-.66 0-1.4.264-2.109.634-.719.375-1.452.885-2.11 1.432-.656.547-1.25 1.143-1.685 1.698a5 5 0 0 0-.535.815c-.128.254-.228.536-.228.813 0 .997.497 2.076 1.009 2.897-.11.167-.173.367-.173.581v.569c0 .479.316.883.75 1.01a6 6 0 0 0 2.064 2.054 5.9 5.9 0 0 0 6.033 0 6 6 0 0 0 2.068-2.06c.423-.133.73-.532.73-1.004v-.569c0-.21-.06-.404-.164-.568.213-.342.42-.722.591-1.117.243-.563.425-1.187.425-1.79 0-.278-.1-.56-.228-.814a5 5 0 0 0-.534-.816c-.435-.555-1.03-1.152-1.686-1.699a12.3 12.3 0 0 0-2.11-1.432c-.708-.37-1.45-.634-2.108-.634m4.642 8.785a.5.5 0 0 0 .216.126v.528a.085.085 0 0 1-.08.085H3.23l-.095-.61a.5.5 0 0 0 .226-.129zm.178-.966c.199-.315.39-.664.545-1.021.215-.498.345-.984.345-1.403 0-.057-.025-.178-.124-.375a4 4 0 0 0-.432-.655c-.384-.49-.927-1.039-1.545-1.553a11.3 11.3 0 0 0-1.94-1.32c-.657-.342-1.236-.525-1.67-.525s-1.012.183-1.67.526a11.3 11.3 0 0 0-1.94 1.319c-.616.514-1.16 1.062-1.543 1.552a4 4 0 0 0-.433.655c-.099.196-.124.317-.124.373 0 .75.41 1.666.89 2.427h9.641m-9.69 1.095.078.61a.085.085 0 0 1-.083-.085v-.524zm7.401 2.756c.52-.309.978-.709 1.353-1.18h-7.77c.376.471.834.871 1.354 1.18a4.95 4.95 0 0 0 5.063 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPoliceOutline.displayName = 'PoliceOutline';
const Memo = memo(SvgPoliceOutline);
export default Memo;
