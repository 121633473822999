// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBarcodeScanOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h1.667a.5.5 0 0 1 0 1H3.165a.833.833 0 0 0-.833.833v1.667a.5.5 0 0 1-1 0zm9.333-1.333a.5.5 0 0 1 .5-.5h1.667c1.013 0 1.833.82 1.833 1.833v1.667a.5.5 0 0 1-1 0V4.5a.833.833 0 0 0-.833-.833h-1.667a.5.5 0 0 1-.5-.5M5.165 6a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5M8 6a.5.5 0 0 1 .5.5v1.667a.5.5 0 0 1-1 0V6.5a.5.5 0 0 1 .5-.5m2.833 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3a.5.5 0 0 1 .5-.5m-9 3.334a.5.5 0 0 1 .5.5V11.5c0 .46.373.834.833.834h1.667a.5.5 0 1 1 0 1H3.165A1.833 1.833 0 0 1 1.332 11.5V9.834a.5.5 0 0 1 .5-.5m12.333 0a.5.5 0 0 1 .5.5V11.5c0 1.013-.82 1.834-1.833 1.834h-1.667a.5.5 0 1 1 0-1h1.667c.46 0 .833-.373.833-.834V9.834a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBarcodeScanOutline.displayName = 'BarcodeScanOutline';
const Memo = memo(SvgBarcodeScanOutline);
export default Memo;
