// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBrushColorFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.744 5.704a1.833 1.833 0 0 1 0 2.592L10.02 12.02a1.44 1.44 0 0 1-2.04 0 .914.914 0 0 0-1.293 0l-1.724 1.724a1.833 1.833 0 0 1-2.593 0l-.114-.114a1.833 1.833 0 0 1 0-2.593L3.98 9.312a.913.913 0 0 0 0-1.292 1.44 1.44 0 0 1 0-2.04l1.323-1.323.01-.01 2.39-2.391a1.834 1.834 0 0 1 2.593 0L12.02 3.98a.5.5 0 0 1 .146.353v.834H13a.5.5 0 0 1 .354.146zm-3.452 4.63L5.666 5.706l-.98.98a.44.44 0 0 0 0 .626c.748.747.749 1.959.001 2.706l-1.724 1.725a.833.833 0 0 0 0 1.179l.114.114a.833.833 0 0 0 1.179 0l1.724-1.725a1.914 1.914 0 0 1 2.707.001.44.44 0 0 0 .626 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBrushColorFill.displayName = 'BrushColorFill';
const Memo = memo(SvgBrushColorFill);
export default Memo;
