// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLawLegalTermsImprintBalanceFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.499 1.833a.5.5 0 0 0-1 0v.834H6.98c-.284 0-.565.066-.82.193l-.77.385a.8.8 0 0 1-.373.088H1.832a.5.5 0 0 0 0 1h1.151l-1.47 4.654c-.248.787.053 1.806.994 2.091.777.236 1.54.236 2.317 0 .94-.285 1.242-1.304.993-2.091l-1.47-4.654h.67c.285 0 .566-.066.82-.193l.77-.385a.8.8 0 0 1 .373-.088H7.5V13h-3a.5.5 0 0 0 0 1h7a.5.5 0 1 0 0-1h-3V3.667h.518q.197 0 .373.088l.77.385c.255.127.535.193.82.193h.67l-1.47 4.654c-.248.787.053 1.806.994 2.091.777.236 1.54.236 2.317 0 .94-.285 1.241-1.304.993-2.091l-1.47-4.654h1.151a.5.5 0 0 0 0-1H10.98a.8.8 0 0 1-.373-.088l-.77-.385a1.8 1.8 0 0 0-.82-.193H8.5z" />
    </Svg>
  );
};
SvgLawLegalTermsImprintBalanceFill.displayName = 'LawLegalTermsImprintBalanceFill';
const Memo = memo(SvgLawLegalTermsImprintBalanceFill);
export default Memo;
