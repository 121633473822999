// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleLikeLoveHeartFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.966 4.333a3.036 3.036 0 1 1 6.072 0 3.036 3.036 0 0 1-6.072 0M8.172 8c-2.555 0-4.51 1.53-5.304 3.677a1.64 1.64 0 0 0 .29 1.649c.35.419.893.674 1.478.674h4.588c-.462-.543-.889-1.292-.889-2.222 0-.802.257-1.55.782-2.11a2.63 2.63 0 0 1 2.073-.83C10.337 8.305 9.314 8 8.172 8" />
      <Path
        fillRule="evenodd"
        d="m11.985 10.075.016.009.017-.01c.22-.12.543-.24.946-.24.435 0 .87.174 1.193.518.325.348.511.837.511 1.426 0 .927-.652 1.657-1.197 2.108a5.5 5.5 0 0 1-.812.555 4 4 0 0 1-.316.157q-.067.03-.135.052c-.028.008-.111.035-.207.035s-.178-.026-.206-.035a2 2 0 0 1-.136-.052 4 4 0 0 1-.315-.157 5.5 5.5 0 0 1-.812-.555c-.546-.45-1.197-1.181-1.197-2.108 0-.589.186-1.078.511-1.426a1.63 1.63 0 0 1 1.192-.519c.403 0 .726.122.947.242m-1.65 1.703c0-.375.114-.607.24-.742a.63.63 0 0 1 .463-.203.97.97 0 0 1 .613.219l.002.001a.5.5 0 0 0 .697 0l.001-.001.024-.02a.974.974 0 0 1 .589-.199.63.63 0 0 1 .463.203c.126.135.241.367.241.742 0 .457-.341.93-.834 1.337a4.5 4.5 0 0 1-.833.54 4.49 4.49 0 0 1-.833-.54c-.492-.407-.833-.88-.833-1.337"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPeopleLikeLoveHeartFill.displayName = 'PeopleLikeLoveHeartFill';
const Memo = memo(SvgPeopleLikeLoveHeartFill);
export default Memo;
