// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBankOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.374 2.417a.83.83 0 0 0-.751 0L2.528 4.99a.357.357 0 0 0 .161.676h10.62a.357.357 0 0 0 .16-.676zm4.958 4.25a1.357 1.357 0 0 0 .588-2.569L8.825 1.524a1.83 1.83 0 0 0-1.653 0L2.077 4.098a1.357 1.357 0 0 0 .588 2.568v4.515c-.437.21-.784.591-.945 1.073l-.07.21A1.167 1.167 0 0 0 2.757 14H13.24c.797 0 1.359-.78 1.107-1.536l-.07-.21a1.83 1.83 0 0 0-.945-1.073zm-1 0h-1.667V11h1.667zM3.46 12a.83.83 0 0 0-.79.57l-.071.21a.167.167 0 0 0 .158.22H13.24a.167.167 0 0 0 .159-.22l-.07-.21a.83.83 0 0 0-.791-.57zm.205-1h1.667V6.667H3.665zm2.667-4.333V11h3.333V6.667z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBankOutline.displayName = 'BankOutline';
const Memo = memo(SvgBankOutline);
export default Memo;
