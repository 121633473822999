// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRefreshRenewFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3 8a5 5 0 0 1 6.97-4.597.5.5 0 1 0 .393-.92A6 6 0 0 0 4.683 13H2.5a.5.5 0 0 0 0 1h3.667a.5.5 0 0 0 .5-.5V9.833a.5.5 0 0 0-1 0v2.59A5 5 0 0 1 3 8m8.707-2.91a.667.667 0 1 0 1.155-.667.667.667 0 0 0-1.155.666M14 7.332a.667.667 0 1 0-1.333 0 .667.667 0 0 0 1.333 0M8.667 14a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m4.863-3.577a.667.667 0 1 0-1.154-.667.667.667 0 0 0 1.154.667m-1.952 2.44a.667.667 0 1 0-.666-1.155.667.667 0 0 0 .666 1.155"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRefreshRenewFill.displayName = 'RefreshRenewFill';
const Memo = memo(SvgRefreshRenewFill);
export default Memo;
