// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgUmbrellaSecurityOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 1.333a.5.5 0 0 1 .5.5v.85c2.608.164 4.856 1.607 6.005 3.685a1.46 1.46 0 0 1-.094 1.595c-.31.43-.835.706-1.42.706H3.007c-.585 0-1.11-.276-1.42-.706a1.46 1.46 0 0 1-.094-1.595C2.643 4.29 4.89 2.847 7.499 2.682v-.849a.5.5 0 0 1 .5-.5m0 2.334c-2.47 0-4.592 1.305-5.631 3.185a.465.465 0 0 0 .029.526.75.75 0 0 0 .61.29h9.982a.75.75 0 0 0 .61-.29.465.465 0 0 0 .03-.526C12.59 4.972 10.467 3.667 8 3.667m0 6a.5.5 0 0 1 .5.5v2.717a1.782 1.782 0 0 1-3.53.35l-.128-.636a.5.5 0 0 1 .98-.196l.128.636a.782.782 0 0 0 1.55-.154v-2.717a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgUmbrellaSecurityOutline.displayName = 'UmbrellaSecurityOutline';
const Memo = memo(SvgUmbrellaSecurityOutline);
export default Memo;
