// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAirplayOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.945 2h8.107c.356 0 .654 0 .896.02.254.02.492.065.716.18.345.176.626.456.802.801.114.225.159.463.18.716.02.243.02.54.02.896v4.774c0 .356 0 .653-.02.896-.021.253-.066.491-.18.716a1.83 1.83 0 0 1-.802.801c-.224.115-.462.16-.716.18-.242.02-.54.02-.896.02h-.22a.5.5 0 0 1 0-1h.2c.382 0 .638 0 .835-.017.191-.015.283-.043.343-.074a.83.83 0 0 0 .364-.364c.031-.06.06-.152.075-.343.016-.198.016-.454.016-.835V4.633c0-.381 0-.637-.016-.835-.016-.191-.044-.283-.074-.343a.83.83 0 0 0-.365-.364c-.06-.03-.152-.059-.343-.074A12 12 0 0 0 12.032 3H3.965c-.381 0-.637 0-.835.017-.191.015-.283.043-.343.074a.83.83 0 0 0-.364.364c-.03.06-.059.152-.074.343-.017.198-.017.454-.017.835v4.734c0 .381 0 .637.017.835.015.191.043.283.074.343.08.157.207.284.364.364.06.03.152.059.343.074.198.017.454.017.835.017h.2a.5.5 0 0 1 0 1h-.22c-.356 0-.653 0-.896-.02a1.9 1.9 0 0 1-.716-.18A1.83 1.83 0 0 1 1.532 11a1.9 1.9 0 0 1-.18-.716c-.02-.243-.02-.54-.02-.896V4.613c0-.356 0-.653.02-.896.02-.253.065-.491.18-.716.176-.345.456-.625.801-.801.225-.115.463-.16.716-.18.243-.02.54-.02.896-.02M8 9.5a.5.5 0 0 1 .397.197l2.667 3.5a.5.5 0 0 1-.398.803H5.332a.5.5 0 0 1-.398-.803l2.667-3.5a.5.5 0 0 1 .398-.197M6.342 13h3.314l-1.657-2.175z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAirplayOutline.displayName = 'AirplayOutline';
const Memo = memo(SvgAirplayOutline);
export default Memo;
