// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSharedSpaceSharedFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.672 1.443a.55.55 0 0 1 .661 0l6.116 4.57a.55.55 0 0 1 .11.77.553.553 0 0 1-.772.109L8.003 2.569 2.218 6.892a.553.553 0 0 1-.772-.11.55.55 0 0 1 .11-.769zM1.963 8.765a1.9 1.9 0 0 1 1.357-.572 1.9 1.9 0 0 1 1.356.572c.358.364.557.856.557 1.367 0 .51-.199 1.002-.557 1.367a1.9 1.9 0 0 1-1.356.571 1.9 1.9 0 0 1-1.357-.571 1.95 1.95 0 0 1-.557-1.367c0-.511.2-1.003.557-1.367M11.959 8.342a1.89 1.89 0 0 1 2.092.423 1.94 1.94 0 0 1 .557 1.367 1.96 1.96 0 0 1-.557 1.367 1.92 1.92 0 0 1-1.356.571 1.9 1.9 0 0 1-1.357-.571 1.94 1.94 0 0 1-.557-1.367 1.96 1.96 0 0 1 .557-1.367c.177-.18.388-.325.62-.423M6.65 8.765a1.9 1.9 0 0 1 1.357-.572 1.9 1.9 0 0 1 1.357.572c.357.364.556.856.556 1.367 0 .51-.199 1.002-.556 1.367a1.9 1.9 0 0 1-1.357.571A1.9 1.9 0 0 1 6.65 11.5a1.95 1.95 0 0 1-.556-1.367c0-.511.199-1.003.556-1.367"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M8.008 12.486c-.382 0-.756.114-1.076.33-.32.215-.573.523-.725.886a.49.49 0 0 1-.449.298H5.57a.49.49 0 0 1-.45-.298 2 2 0 0 0-.512-.721 1.95 1.95 0 0 0-.764-.421 1.91 1.91 0 0 0-1.663.3.49.49 0 0 1-.68-.103.484.484 0 0 1 .104-.678 2.89 2.89 0 0 1 3.652.18q.226.202.407.446c.2-.27.443-.504.724-.693a2.9 2.9 0 0 1 3.964.693q.18-.244.407-.446a2.89 2.89 0 0 1 3.652-.18.484.484 0 0 1 .104.678.49.49 0 0 1-.68.104 1.913 1.913 0 0 0-1.663-.301c-.281.08-.542.223-.763.42a2 2 0 0 0-.514.722.49.49 0 0 1-.448.298h-.189a.49.49 0 0 1-.449-.298 2 2 0 0 0-.725-.887 1.93 1.93 0 0 0-1.076-.33"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSharedSpaceSharedFill.displayName = 'SharedSpaceSharedFill';
const Memo = memo(SvgSharedSpaceSharedFill);
export default Memo;
