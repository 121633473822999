// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgZoomOutSearchMagnifyingGlassFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.333 2a5.333 5.333 0 1 0 3.402 9.442l2.245 2.245a.5.5 0 0 0 .707-.707l-2.245-2.245A5.333 5.333 0 0 0 7.334 2M10 7.333a.5.5 0 0 1-.5.5H5.167a.5.5 0 1 1 0-1H9.5a.5.5 0 0 1 .5.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgZoomOutSearchMagnifyingGlassFill.displayName = 'ZoomOutSearchMagnifyingGlassFill';
const Memo = memo(SvgZoomOutSearchMagnifyingGlassFill);
export default Memo;
