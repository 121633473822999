// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRadarLocationSearchOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.666 3.417a5.667 5.667 0 1 0 6.666 0l-.853 1.421a4 4 0 1 1-4.96 0zm.857-.515L7.982 7h.034l2.459-4.098a5.64 5.64 0 0 0-2.476-.569c-.888 0-1.728.205-2.476.569m1.601 4.613a1 1 0 1 0 1.749 0l1.086-1.81a3 3 0 1 1-3.92 0zM1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRadarLocationSearchOutline.displayName = 'RadarLocationSearchOutline';
const Memo = memo(SvgRadarLocationSearchOutline);
export default Memo;
