// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChevronLargeRightChevRightOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.245 1.403a.5.5 0 0 1 .685.175l3.261 5.485a1.83 1.83 0 0 1 0 1.874L6.93 14.422a.5.5 0 0 1-.86-.511l3.262-5.485a.83.83 0 0 0 0-.852L6.07 2.089a.5.5 0 0 1 .175-.686"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChevronLargeRightChevRightOutline.displayName = 'ChevronLargeRightChevRightOutline';
const Memo = memo(SvgChevronLargeRightChevRightOutline);
export default Memo;
