// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPasskeysPasskeyPasswordlessFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.999 1.333a3 3 0 1 0 0 6 3 3 0 0 0 0-6M8 8C5.454 8 3.548 9.657 2.81 11.921 2.437 13.071 3.424 14 4.455 14h5.71v-2.292a2.995 2.995 0 0 1-.92-3.568A5.5 5.5 0 0 0 8 8" />
      <Path
        fillRule="evenodd"
        d="M11.999 7.333a2 2 0 0 0-.834 3.819v2.355c0 .1.046.197.126.26l.5.4a.33.33 0 0 0 .416 0l.5-.4a.33.33 0 0 0 .125-.26V13.1a.33.33 0 0 0-.11-.248l-.39-.352.402-.402a.33.33 0 0 0 .098-.236v-.71a2 2 0 0 0-.833-3.819m-.667 2a.667.667 0 1 1 1.333 0 .667.667 0 0 1-1.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPasskeysPasskeyPasswordlessFill.displayName = 'PasskeysPasskeyPasswordlessFill';
const Memo = memo(SvgPasskeysPasskeyPasswordlessFill);
export default Memo;
