// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeDoorHouseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.16 1.304a1.83 1.83 0 0 0-2.32 0L2.671 4.713A1.83 1.83 0 0 0 2 6.132v6.035C2 13.179 2.82 14 3.833 14h1.5V9.833C5.333 8.821 6.154 8 7.167 8h1.666c1.013 0 1.834.82 1.834 1.833V14h1.5C13.179 14 14 13.18 14 12.167V6.132c0-.55-.247-1.07-.672-1.42z" />
      <Path d="M9.667 14V9.833A.833.833 0 0 0 8.833 9H7.167a.833.833 0 0 0-.834.833V14z" />
    </Svg>
  );
};
SvgHomeDoorHouseFill.displayName = 'HomeDoorHouseFill';
const Memo = memo(SvgHomeDoorHouseFill);
export default Memo;
