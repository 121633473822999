// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgZapLightningFlashOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m8.331 2.106-5.214 7.56h3.714c.46 0 .834.374.834.834v3.394l5.214-7.56H9.165A.833.833 0 0 1 8.33 5.5zm-.519-1.009c.464-.673 1.52-.344 1.52.473v3.763h3.865a.833.833 0 0 1 .686 1.307l-5.699 8.263c-.464.673-1.52.344-1.52-.473v-3.763H2.8a.833.833 0 0 1-.686-1.307z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgZapLightningFlashOutline.displayName = 'ZapLightningFlashOutline';
const Memo = memo(SvgZapLightningFlashOutline);
export default Memo;
