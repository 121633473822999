// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBathFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.625 2.939c0-.616.53-1.115 1.185-1.115.449 0 .859.238 1.06.616l.327.615-.766.477a.476.476 0 0 0 .503.809l2.19-1.363a.476.476 0 0 0-.503-.809l-.69.429-.279-.526C6.303 1.415 5.59 1 4.81 1c-1.138 0-2.06.868-2.06 1.939v4.54h-.875c-.483 0-.875.368-.875.823s.392.823.875.823v2.883c0 1.117.946 2.027 2.125 2.058v.434a.5.5 0 0 0 1 0v-.433h6v.433a.5.5 0 0 0 1 0v-.434c1.18-.031 2.125-.94 2.125-2.058V9.125c.483 0 .875-.368.875-.823s-.392-.824-.875-.824h-10.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBathFill.displayName = 'BathFill';
const Memo = memo(SvgBathFill);
export default Memo;
