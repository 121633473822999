// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble4AnnotationMessageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 3.935 6.083.2.2 0 0 0-.13-.018l-2.314.52a1.167 1.167 0 0 1-1.39-1.413l.54-2.22a.2.2 0 0 0-.019-.136A6.64 6.64 0 0 1 1.332 8m3 0a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0m3 0a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0m3.667.667a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble4AnnotationMessageFill.displayName = 'Bubble4AnnotationMessageFill';
const Memo = memo(SvgBubble4AnnotationMessageFill);
export default Memo;
