// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLayers2StackFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.517 2.122a1.16 1.16 0 0 0-1.037 0L1.981 4.858c-.865.43-.865 1.67 0 2.1l5.5 2.737c.326.162.71.162 1.036 0l5.5-2.736c.865-.431.865-1.67 0-2.1z" />
      <Path d="m3.07 8.5-1.089.541c-.865.431-.865 1.67 0 2.1l5.5 2.737c.326.163.71.163 1.036 0l5.5-2.736c.865-.43.865-1.67 0-2.1L12.927 8.5l-4.411 2.195c-.327.162-.71.162-1.037 0z" />
    </Svg>
  );
};
SvgLayers2StackFill.displayName = 'Layers2StackFill';
const Memo = memo(SvgLayers2StackFill);
export default Memo;
