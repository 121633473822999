// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFile6DocumentTextFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 1.333h-3.5c-1.012 0-1.833.821-1.833 1.834v9.666c0 1.013.82 1.834 1.833 1.834h7c1.013 0 1.834-.821 1.834-1.834V6.667h-3.5A1.833 1.833 0 0 1 8 4.833zM5.335 9.5a.5.5 0 0 1 .5-.5h2.333a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m.5 2.167a.5.5 0 1 0 0 1h4.333a.5.5 0 0 0 0-1z"
        clipRule="evenodd"
      />
      <Path d="m13.039 5.667-.046-.05L9.05 1.676l-.049-.046v3.204c0 .46.373.834.834.834z" />
    </Svg>
  );
};
SvgFile6DocumentTextFill.displayName = 'File6DocumentTextFill';
const Memo = memo(SvgFile6DocumentTextFill);
export default Memo;
