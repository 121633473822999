// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderRestrictedFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.165 2c-1.012 0-1.833.82-1.833 1.833v3.258a4.502 4.502 0 0 1 6.243 6.242h5.257c1.013 0 1.833-.82 1.833-1.833V5.833c0-1.012-.82-1.833-1.833-1.833h-4.12a.83.83 0 0 1-.693-.371l-.542-.813A1.83 1.83 0 0 0 5.952 2z" />
      <Path
        fillRule="evenodd"
        d="M6.307 8.358a3.5 3.5 0 1 0-4.95 4.95 3.5 3.5 0 0 0 4.95-4.95m-.389 3.854L2.453 8.747a2.5 2.5 0 0 1 3.465 3.465M1.746 9.454l3.465 3.465a2.5 2.5 0 0 1-3.465-3.465"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolderRestrictedFill.displayName = 'FolderRestrictedFill';
const Memo = memo(SvgFolderRestrictedFill);
export default Memo;
