// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMaidRoomOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.977 1.441a.442.442 0 0 0-.883 0v8.686a2.16 2.16 0 0 0-1.695 1.843l-.317 2.534a.44.44 0 0 0 .438.496h4.038a.442.442 0 0 0 .438-.496l-.317-2.534a2.16 2.16 0 0 0-1.702-1.844zM11.275 12.08a1.273 1.273 0 0 1 2.528 0l.254 2.038H11.02zM2.603 7.302c-.492 1.13-.675 2.519-.72 3.495a.03.03 0 0 0 .01.025.06.06 0 0 0 .042.016h1.262c.231 0 .423.178.44.408l.222 2.871h2.21l.222-2.871a.44.44 0 0 1 .44-.408h1.262q.03-.001.042-.016l.008-.012.002-.013C8 9.82 7.817 8.432 7.325 7.302c-.491-1.13-1.231-1.887-2.36-1.887-1.13 0-1.87.756-2.362 1.887M6.4 4.879a2.208 2.208 0 1 0-2.879.004c-.81.435-1.362 1.223-1.729 2.067-.553 1.272-.744 2.784-.791 3.806l.441.02-.441-.02a.927.927 0 0 0 .934.965h.853l.221 2.872c.018.23.21.407.44.407h3.03c.23 0 .423-.177.44-.407l.221-2.872h.853c.516 0 .96-.42.934-.965-.046-1.022-.238-2.534-.791-3.806-.368-.846-.922-1.636-1.736-2.07M3.633 3.207a1.325 1.325 0 1 1 2.65 0 1.325 1.325 0 0 1-2.65 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMaidRoomOutline.displayName = 'MaidRoomOutline';
const Memo = memo(SvgMaidRoomOutline);
export default Memo;
