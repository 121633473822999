// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgOpenNewLinkOpenLinkBoxArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.667 2.5a.5.5 0 0 1 .5-.5H13.5a.5.5 0 0 1 .5.5v4.333a.5.5 0 0 1-1 0V3.707L7.687 9.02a.5.5 0 0 1-.707-.707L12.293 3H9.167a.5.5 0 0 1-.5-.5m-4.103.833h1.603a.5.5 0 0 1 0 1H4.583c-.357 0-.597 0-.782.015a.9.9 0 0 0-.324.065.83.83 0 0 0-.397.397.9.9 0 0 0-.065.324C3 5.319 3 5.559 3 5.917v5.45c0 .381 0 .637.017.835.015.191.043.283.074.343.08.157.207.284.364.364.06.03.152.059.343.074.198.017.454.017.835.017h5.4c.382 0 .638 0 .835-.017.192-.015.283-.043.344-.074a.83.83 0 0 0 .364-.364c.03-.06.058-.152.074-.343.016-.198.017-.454.017-.835V9.333a.5.5 0 1 1 1 0v2.054c0 .356 0 .653-.02.896a1.9 1.9 0 0 1-.18.716 1.83 1.83 0 0 1-.801.801c-.225.115-.463.16-.716.18-.243.02-.54.02-.896.02H4.613c-.356 0-.653 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.2 13a1.9 1.9 0 0 1-.18-.716C2 12.04 2 11.743 2 11.387v-5.49c0-.333 0-.611.017-.84.019-.237.058-.46.159-.674.181-.384.49-.692.873-.874.214-.1.438-.14.675-.158.228-.018.507-.018.84-.018"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgOpenNewLinkOpenLinkBoxArrowOutline.displayName = 'OpenNewLinkOpenLinkBoxArrowOutline';
const Memo = memo(SvgOpenNewLinkOpenLinkBoxArrowOutline);
export default Memo;
