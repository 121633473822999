// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCrossedSmallDeleteRemoveFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.814 4.813a.5.5 0 0 1 .708 0l2.48 2.48 2.48-2.48a.5.5 0 1 1 .706.708L8.708 8l2.48 2.48a.5.5 0 1 1-.707.706l-2.48-2.48-2.48 2.48a.5.5 0 1 1-.707-.707L7.294 8l-2.48-2.48a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCrossedSmallDeleteRemoveFill.displayName = 'CrossedSmallDeleteRemoveFill';
const Memo = memo(SvgCrossedSmallDeleteRemoveFill);
export default Memo;
