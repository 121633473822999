// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTelevision2TvMonitorVideoScreenDisplayOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.604 1.86a.5.5 0 0 1 .702-.088l2.693 2.095 2.693-2.095a.5.5 0 0 1 .614.79L9.456 4h3.376c1.013 0 1.833.82 1.833 1.833v6.334c0 1.012-.82 1.833-1.833 1.833H3.165a1.833 1.833 0 0 1-1.833-1.833V5.833C1.332 4.821 2.152 4 3.165 4h3.376l-1.85-1.439a.5.5 0 0 1-.087-.701M3.165 5a.833.833 0 0 0-.833.833v6.334c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833V5.833A.833.833 0 0 0 12.832 5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTelevision2TvMonitorVideoScreenDisplayOutline.displayName =
  'Television2TvMonitorVideoScreenDisplayOutline';
const Memo = memo(SvgTelevision2TvMonitorVideoScreenDisplayOutline);
export default Memo;
