// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSadEmojiUnhappyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334m-1.532-4.032a2.167 2.167 0 0 1 3.064 0 .5.5 0 0 0 .707-.708 3.167 3.167 0 0 0-4.478 0 .5.5 0 0 0 .707.708m.532-4.302c0 .553-.373 1-.834 1s-.833-.447-.833-1 .373-1 .833-1 .834.448.834 1m2.833 1c.46 0 .833-.447.833-1s-.373-1-.833-1-.833.448-.833 1c0 .553.373 1 .833 1"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSadEmojiUnhappyFill.displayName = 'SadEmojiUnhappyFill';
const Memo = memo(SvgSadEmojiUnhappyFill);
export default Memo;
