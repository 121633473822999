// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgVisitPageOpenAppFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.165 2.667c-1.012 0-1.833.82-1.833 1.833v7.667c0 1.013.82 1.833 1.833 1.833h5a.5.5 0 1 0 0-1h-5a.833.833 0 0 1-.833-.833V4.5c0-.46.373-.833.833-.833h9.667c.46 0 .833.373.833.833v3.667a.5.5 0 1 0 1 0V4.5c0-1.012-.82-1.833-1.833-1.833z" />
      <Path d="M4.332 6.334a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334M8.978 8.98a.5.5 0 0 1 .501-.124l4.667 1.436a.5.5 0 0 1 .076.925l-2.005 1.002-1.002 2.005a.5.5 0 0 1-.925-.077L8.854 9.481a.5.5 0 0 1 .124-.5M6.665 6.334a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334M8.999 6.334a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334" />
    </Svg>
  );
};
SvgVisitPageOpenAppFill.displayName = 'VisitPageOpenAppFill';
const Memo = memo(SvgVisitPageOpenAppFill);
export default Memo;
