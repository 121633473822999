// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHeadAvatarOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 6.63a5.3 5.3 0 0 1 5.308-5.297c2.113 0 4.133 1.02 4.968 3.15.407 1.037.932 1.855 1.557 2.786a1.165 1.165 0 0 1-.433 1.684l-.643.333a.17.17 0 0 0-.09.148v1.344c0 1.012-.821 1.833-1.834 1.833h-.666a.167.167 0 0 0-.167.167v.722c0 .644-.522 1.167-1.167 1.167H5.167a1.16 1.16 0 0 1-1.162-1.172c.013-1.436-.012-2.5-.875-3.598A5.27 5.27 0 0 1 2 6.63m5.308-4.297A4.3 4.3 0 0 0 3 6.63c0 1 .342 1.918.916 2.648 1.1 1.4 1.102 2.794 1.089 4.227-.001.088.07.162.162.162h3.666A.167.167 0 0 0 9 13.5v-.722c0-.645.522-1.167 1.167-1.167h.666c.46 0 .834-.373.834-.833V9.434c0-.437.243-.836.63-1.037l.644-.332a.165.165 0 0 0 .061-.24c-.628-.936-1.207-1.831-1.657-2.977-.65-1.66-2.235-2.515-4.037-2.515"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHeadAvatarOutline.displayName = 'HeadAvatarOutline';
const Memo = memo(SvgHeadAvatarOutline);
export default Memo;
