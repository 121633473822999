// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGrowthGrowLeafsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 2.5a.5.5 0 0 1 .5-.5h.833a5.17 5.17 0 0 1 5.082 4.23A5.16 5.16 0 0 1 12.667 4h.833a.5.5 0 0 1 .5.5v1a5.167 5.167 0 0 1-5.167 5.167H8.5V13.5a.5.5 0 0 1-1 0v-5h-.333A5.167 5.167 0 0 1 2 3.333zm5.5 5h-.333A4.167 4.167 0 0 1 3 3.333V3h.333A4.167 4.167 0 0 1 7.5 7.167zm1 2.167h.333A4.167 4.167 0 0 0 13 5.5V5h-.333A4.167 4.167 0 0 0 8.5 9.167z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGrowthGrowLeafsOutline.displayName = 'GrowthGrowLeafsOutline';
const Memo = memo(SvgGrowthGrowLeafsOutline);
export default Memo;
