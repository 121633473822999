// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFourkOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.012.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v7c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833v-7a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
      <Path d="M6.676 10.402c-.27 0-.465-.166-.465-.479v-.452H4.46c-.355 0-.593-.222-.593-.55 0-.202.059-.385.205-.642.323-.58.791-1.295 1.302-2.054.326-.498.564-.66.96-.66.499 0 .811.27.811.71v2.411h.267c.26 0 .404.163.404.394s-.147.39-.404.39h-.267v.453c0 .313-.198.479-.469.479m-.446-1.69V6.35h-.02c-.67.967-1.103 1.628-1.483 2.337v.026zM8.838 10.395c-.31 0-.492-.189-.492-.51v-3.8c0-.325.183-.514.492-.514.31 0 .491.189.491.514v1.628h.03l1.767-1.93c.137-.15.26-.212.424-.212.26 0 .462.199.462.452 0 .137-.05.245-.173.375l-1.295 1.357 1.41 1.712c.146.183.21.323.21.456a.47.47 0 0 1-.474.475c-.202 0-.34-.074-.492-.27l-1.4-1.722-.469.492v.986c0 .322-.182.511-.491.511" />
    </Svg>
  );
};
SvgFourkOutline.displayName = 'FourkOutline';
const Memo = memo(SvgFourkOutline);
export default Memo;
