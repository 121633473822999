// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgScissorsCutFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.37 3.917c-.818 0-1.481.684-1.481 1.527 0 .844.663 1.528 1.481 1.528s1.482-.684 1.482-1.528c0-.843-.664-1.527-1.482-1.527M2 5.444C2 4.094 3.061 3 4.37 3s2.37 1.094 2.37 2.444S5.68 7.89 4.37 7.89C3.061 7.889 2 6.794 2 5.444M4.37 10.028c-.818 0-1.481.684-1.481 1.528 0 .843.663 1.527 1.481 1.527s1.482-.684 1.482-1.527c0-.844-.664-1.528-1.482-1.528M2 11.556c0-1.35 1.061-2.445 2.37-2.445s2.37 1.095 2.37 2.445S5.68 14 4.37 14C3.061 14 2 12.906 2 11.556"
        clipRule="evenodd"
      />
      <Path d="M12.629 4.662a1.59 1.59 0 0 0-1.705-.244L8.302 5.62c-.589.27-.968.871-.968 1.535v.638l-2.2-.939-.34.847 1.871.8-1.871.798.34.847 2.2-.94v.639c0 .664.38 1.266.968 1.535l2.622 1.202a1.59 1.59 0 0 0 1.705-.244l1.211-1.041a.466.466 0 0 0-.114-.776L8.99 8.501l4.736-2.022a.466.466 0 0 0 .115-.776z" />
    </Svg>
  );
};
SvgScissorsCutFill.displayName = 'ScissorsCutFill';
const Memo = memo(SvgScissorsCutFill);
export default Memo;
