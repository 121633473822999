// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArrowPathLeftOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m6.332 3.905-4.43 3.712a.5.5 0 0 0 0 .766l-.322.384.322-.384 4.43 3.713v-1.93a.5.5 0 0 1 .5-.5h6c.46 0 .834-.372.834-.833V7.167a.833.833 0 0 0-.834-.834h-6a.5.5 0 0 1-.5-.5zm-.368-.996a.833.833 0 0 1 1.368.639v1.785h5.5c1.013 0 1.834.821 1.834 1.834v1.666c0 1.013-.821 1.834-1.834 1.834h-5.5v1.786a.833.833 0 0 1-1.368.638L1.259 9.15a1.5 1.5 0 0 1 0-2.3z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArrowPathLeftOutline.displayName = 'ArrowPathLeftOutline';
const Memo = memo(SvgArrowPathLeftOutline);
export default Memo;
