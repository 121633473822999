// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPinLocationBookmarkFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.168 1.334c-1.013 0-1.833.82-1.833 1.833v1.498c0 1.15-.457 2.251-1.27 3.064l-.25.25a.5.5 0 0 0-.147.355v1.833a.5.5 0 0 0 .5.5h4.333v3.5a.5.5 0 1 0 1 0v-3.5h4.334a.5.5 0 0 0 .5-.5V8.334a.5.5 0 0 0-.147-.354l-.25-.251a4.33 4.33 0 0 1-1.27-3.064V3.167c0-1.013-.82-1.833-1.833-1.833z" />
    </Svg>
  );
};
SvgPinLocationBookmarkFill.displayName = 'PinLocationBookmarkFill';
const Memo = memo(SvgPinLocationBookmarkFill);
export default Memo;
