// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFinderFaceIdOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2H5.5a.5.5 0 0 1 0 1H3.833A.833.833 0 0 0 3 3.833V5.5a.5.5 0 0 1-1 0zM10 2.5a.5.5 0 0 1 .5-.5h1.667C13.179 2 14 2.82 14 3.833V5.5a.5.5 0 0 1-1 0V3.833A.833.833 0 0 0 12.167 3H10.5a.5.5 0 0 1-.5-.5M5.167 5.333a.5.5 0 0 1 .5.5v1a.5.5 0 1 1-1 0v-1a.5.5 0 0 1 .5-.5m3 0a.5.5 0 0 1 .5.5v1.5c0 .936-.7 1.707-1.605 1.82a.5.5 0 0 1-.124-.993.834.834 0 0 0 .729-.827v-1.5a.5.5 0 0 1 .5-.5m2.666 0a.5.5 0 0 1 .5.5v1a.5.5 0 1 1-1 0v-1a.5.5 0 0 1 .5-.5m-5.266 4.881a.5.5 0 0 1 .683-.182A3.5 3.5 0 0 0 8 10.5c.638 0 1.235-.17 1.75-.468a.5.5 0 1 1 .5.866A4.5 4.5 0 0 1 8 11.5c-.819 0-1.588-.22-2.25-.602a.5.5 0 0 1-.183-.684M2.5 10a.5.5 0 0 1 .5.5v1.667c0 .46.373.833.833.833H5.5a.5.5 0 0 1 0 1H3.833A1.833 1.833 0 0 1 2 12.167V10.5a.5.5 0 0 1 .5-.5m11 0a.5.5 0 0 1 .5.5v1.667C14 13.179 13.18 14 12.167 14H10.5a.5.5 0 0 1 0-1h1.667c.46 0 .833-.373.833-.833V10.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFinderFaceIdOutline.displayName = 'FinderFaceIdOutline';
const Memo = memo(SvgFinderFaceIdOutline);
export default Memo;
