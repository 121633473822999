// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRearFrontCameraCamChangeLensFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 8a6 6 0 0 1 6-6c1.685 0 3.237.695 4.341 1.815V2.5a.5.5 0 0 1 1 0v2.167a1 1 0 0 1-1 1h-2.166a.5.5 0 1 1 0-1h1.595A5.1 5.1 0 0 0 8 3a5 5 0 0 0-4.961 5.626.5.5 0 0 1-.993.123A6 6 0 0 1 2 8m11.396-1.184a.5.5 0 0 1 .558.435q.045.368.046.749a6 6 0 0 1-6 6c-1.68 0-3.23-.692-4.333-1.807V13.5a.5.5 0 0 1-1 0v-2.167a1 1 0 0 1 1-1h2.166a.5.5 0 0 1 0 1H4.23A5.1 5.1 0 0 0 8 13a5 5 0 0 0 4.961-5.626.5.5 0 0 1 .435-.558"
        clipRule="evenodd"
      />
      <Path
        stroke="#0D1011"
        strokeLinecap="square"
        strokeWidth={0.5}
        d="M7.418 8a.583.583 0 1 0 1.167 0 .583.583 0 0 0-1.167 0Z"
      />
    </Svg>
  );
};
SvgRearFrontCameraCamChangeLensFill.displayName = 'RearFrontCameraCamChangeLensFill';
const Memo = memo(SvgRearFrontCameraCamChangeLensFill);
export default Memo;
