// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWasherFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.33 1C2.596 1 2 1.61 2 2.36v11.28c0 .751.596 1.36 1.33 1.36h9.34c.734 0 1.33-.609 1.33-1.36V2.36C14 1.609 13.404 1 12.67 1zm-.368 4h10.076V2.36a.37.37 0 0 0-.368-.376H3.33a.37.37 0 0 0-.368.376zm1.58-2c-.3 0-.542.224-.542.5s.243.5.543.5h1.914c.3 0 .543-.224.543-.5S6.757 3 6.457 3zM9.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M10 10a2 2 0 1 1-4 0 2 2 0 0 1 4 0m1 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-3.5 0a.6.6 0 0 1 .106-.333c.057-.08.157-.167.394-.167a.5.5 0 0 0 0-1c-.563 0-.963.246-1.206.583-.224.312-.294.67-.294.917a.5.5 0 1 0 1 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWasherFill.displayName = 'WasherFill';
const Memo = memo(SvgWasherFill);
export default Memo;
