// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRewindSymbolFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.382 12.438 7.812 9.38v2.17c0 .997-1.168 1.535-1.925.887L1.74 8.886a1.167 1.167 0 0 1 0-1.772l4.147-3.551c.757-.648 1.925-.11 1.925.886v2.17l3.57-3.056c.757-.648 1.925-.11 1.925.886v7.103c0 .996-1.168 1.534-1.925.886" />
    </Svg>
  );
};
SvgRewindSymbolFill.displayName = 'RewindSymbolFill';
const Memo = memo(SvgRewindSymbolFill);
export default Memo;
