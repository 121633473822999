// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTiktokBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M10.374 1.333H8.127v9.083c0 1.082-.865 1.97-1.94 1.97-1.076 0-1.94-.888-1.94-1.97 0-1.063.845-1.933 1.882-1.972v-2.28C3.844 6.203 2 8.077 2 10.416c0 2.357 1.882 4.25 4.206 4.25s4.206-1.912 4.206-4.25V5.757a5.2 5.2 0 0 0 2.977 1.005v-2.28c-1.69-.058-3.015-1.45-3.015-3.15" />
    </Svg>
  );
};
SvgTiktokBw.displayName = 'TiktokBw';
const Memo = memo(SvgTiktokBw);
export default Memo;
