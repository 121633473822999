// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDropWaterPrecipitationLiquidFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M9.192 1.837a1.666 1.666 0 0 0-2.389 0C6.1 2.55 5.074 3.68 4.223 4.994 3.379 6.297 2.664 7.841 2.664 9.37c0 2.93 2.392 5.297 5.333 5.297s5.334-2.368 5.334-5.297c0-1.529-.715-3.073-1.56-4.376-.85-1.314-1.875-2.444-2.58-3.157" />
    </Svg>
  );
};
SvgDropWaterPrecipitationLiquidFill.displayName = 'DropWaterPrecipitationLiquidFill';
const Memo = memo(SvgDropWaterPrecipitationLiquidFill);
export default Memo;
