// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWriteBriefOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.756 3.13a.833.833 0 0 0-1.179 0l-3 3a.83.83 0 0 0-.244.589v.948h.948a.83.83 0 0 0 .59-.244l3-3a.833.833 0 0 0 0-1.179l.353-.353-.354.353zm-1.886-.707a1.833 1.833 0 0 1 2.593 0l.114.114a1.833 1.833 0 0 1 0 2.593l-3 3c-.343.344-.81.537-1.296.537H7.833a.5.5 0 0 1-.5-.5V6.719c0-.486.193-.953.537-1.296zM2 9.5c0-1.013.82-1.833 1.833-1.833H5.5a.5.5 0 0 1 0 1H3.833a.833.833 0 0 0 0 1.666h7a1.833 1.833 0 1 1 0 3.667h-3a.5.5 0 0 1 0-1h3a.833.833 0 1 0 0-1.667h-7A1.833 1.833 0 0 1 2 9.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWriteBriefOutline.displayName = 'WriteBriefOutline';
const Memo = memo(SvgWriteBriefOutline);
export default Memo;
