// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPrimeCreditFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
      <Path d="M7.96 13.898a5.93 5.93 0 1 0 0-11.859 5.93 5.93 0 0 0 0 11.859" />
      <Path d="M6.687 11.353q-.335 0-.51-.186Q6 10.98 6 10.643V5.72q0-.346.176-.523.184-.177.52-.177h2.139q1.038 0 1.602.532.563.524.563 1.463t-.563 1.473-1.602.532h-1.47v1.623q0 .338-.177.524-.167.186-.501.186m.677-3.397h1.242q.528 0 .8-.24.282-.24.282-.7 0-.462-.281-.693-.274-.23-.801-.23H7.364z" />
    </Svg>
  );
};
SvgPrimeCreditFill.displayName = 'PrimeCreditFill';
const Memo = memo(SvgPrimeCreditFill);
export default Memo;
