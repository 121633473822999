// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleUserPersonAvatarFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8 1.333a3 3 0 1 0 0 6 3 3 0 0 0 0-6M8 8.333c-2.48 0-4.375 1.484-5.146 3.569a1.48 1.48 0 0 0 .26 1.483c.32.38.815.615 1.351.615h7.071c.537 0 1.033-.234 1.352-.615.326-.388.464-.93.26-1.483-.77-2.085-2.666-3.569-5.147-3.569" />
    </Svg>
  );
};
SvgPeopleUserPersonAvatarFill.displayName = 'PeopleUserPersonAvatarFill';
const Memo = memo(SvgPeopleUserPersonAvatarFill);
export default Memo;
