// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSeaViewOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.54 1.601c0-.331-.242-.6-.54-.6-.297 0-.538.269-.538.6v1.8c0 .331.241.6.539.6.297 0 .538-.269.538-.6zM8 6.001c-.758 0-1.484.263-2.02.732-.4.35-.668.791-.78 1.268h5.6a2.42 2.42 0 0 0-.78-1.268c-.536-.469-1.262-.732-2.02-.732m-6.562 2H4.04c.123-.743.516-1.438 1.13-1.975C5.922 5.37 6.94 5.001 8 5.001c1.06 0 2.078.369 2.828 1.025.615.537 1.008 1.232 1.131 1.975h2.604c.241 0 .437.224.437.5s-.196.5-.437.5H1.438c-.242 0-.438-.224-.438-.5s.196-.5.438-.5m.638 3.5c0-.276.2-.5.45-.5h10.946c.248 0 .45.224.45.5s-.202.5-.45.5H2.525c-.248 0-.45-.224-.45-.5m3.725 2.5c-.27 0-.49.224-.49.5s.22.5.49.5h4.405c.27 0 .49-.224.49-.5s-.22-.5-.49-.5zm7.973-10.858a.46.46 0 0 1 0 .686l-1.108 1.03a.55.55 0 0 1-.74 0 .46.46 0 0 1 0-.686l1.11-1.03a.55.55 0 0 1 .738 0m-10.806 0a.55.55 0 0 0-.74 0 .46.46 0 0 0 0 .686l1.11 1.03c.203.19.534.19.738 0a.46.46 0 0 0 0-.686z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSeaViewOutline.displayName = 'SeaViewOutline';
const Memo = memo(SvgSeaViewOutline);
export default Memo;
