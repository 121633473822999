// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHeadAvatarFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.308 1.333A5.3 5.3 0 0 0 2 6.63c0 1.232.422 2.367 1.13 3.267.863 1.097.888 2.162.875 3.598a1.16 1.16 0 0 0 1.162 1.172h3.666c.645 0 1.167-.523 1.167-1.167v-.722c0-.092.075-.167.167-.167h.666c1.013 0 1.834-.82 1.834-1.833V9.434c0-.063.034-.12.09-.148l.643-.333c.62-.32.82-1.106.433-1.684-.625-.931-1.15-1.75-1.557-2.786-.835-2.13-2.855-3.15-4.968-3.15" />
    </Svg>
  );
};
SvgHeadAvatarFill.displayName = 'HeadAvatarFill';
const Memo = memo(SvgHeadAvatarFill);
export default Memo;
