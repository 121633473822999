// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGhostHiddenUnknownFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 1.333c-2.942 0-5.333 2.364-5.333 5.289v6.829a1.167 1.167 0 0 0 1.782.99l.776-.482a.83.83 0 0 1 .801-.042l1.177.568c.504.242 1.09.242 1.594 0l1.177-.568a.83.83 0 0 1 .802.043l.776.48a1.167 1.167 0 0 0 1.782-.99V6.623c0-2.925-2.392-5.289-5.334-5.289M7.335 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0m2.333 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGhostHiddenUnknownFill.displayName = 'GhostHiddenUnknownFill';
const Memo = memo(SvgGhostHiddenUnknownFill);
export default Memo;
