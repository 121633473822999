// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDongCurrencyMoneyCoinDongOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8"
        clipRule="evenodd"
      />
      <Path d="M9.062 10a.3.3 0 0 1-.194-.071.26.26 0 0 1-.075-.184v-.427q-.312.315-.712.499A2 2 0 0 1 7.22 10q-.528 0-.96-.234a1.8 1.8 0 0 1-.679-.662 2.03 2.03 0 0 1-.248-1.019q0-.61.27-1.08.28-.468.754-.733a2.24 2.24 0 0 1 1.09-.265q.387 0 .71.102.336.092.637.265V3.588q0-.113.075-.183a.3.3 0 0 1 .194-.072q.12 0 .195.072.075.07.075.183v6.157a.26.26 0 0 1-.075.184.27.27 0 0 1-.195.071M7.22 9.49q.54 0 .916-.264a2.4 2.4 0 0 0 .658-.683V6.985a2.6 2.6 0 0 0-.625-.346 2 2 0 0 0-.723-.123q-.453 0-.808.204a1.5 1.5 0 0 0-.561.56q-.205.347-.205.805 0 .621.377 1.019.39.387.97.387" />
      <Path
        fillRule="evenodd"
        d="m8.732 10.075-.01-.01a.46.46 0 0 1-.128-.315 2.6 2.6 0 0 1-.427.248 2.2 2.2 0 0 1-.947.202q-.575 0-1.055-.258l-.002-.001a2 2 0 0 1-.753-.734l-.003-.005a2.23 2.23 0 0 1-.274-1.117q-.001-.658.296-1.18l.002-.002q.307-.515.829-.806h.001q.535-.291 1.185-.29.413 0 .768.11.195.053.38.134V3.588a.44.44 0 0 1 .138-.329l.005-.004.005-.004a.5.5 0 0 1 .321-.118.47.47 0 0 1 .332.126.44.44 0 0 1 .138.33v6.156a.46.46 0 0 1-.129.32l-.004.005-.005.004a.47.47 0 0 1-.332.126.5.5 0 0 1-.321-.117zm.062-3.701a2.8 2.8 0 0 0-.636-.265 2.4 2.4 0 0 0-.712-.102q-.603 0-1.089.265a2 2 0 0 0-.755.733q-.27.47-.27 1.08 0 .58.249 1.019.258.428.679.662.431.234.96.234.474 0 .862-.183a2.4 2.4 0 0 0 .712-.5v.428q0 .103.075.184a.3.3 0 0 0 .194.071q.12 0 .194-.071a.26.26 0 0 0 .076-.184V3.588a.24.24 0 0 0-.076-.183.27.27 0 0 0-.194-.072.3.3 0 0 0-.194.072.24.24 0 0 0-.075.183zm-.77 2.687q.33-.225.57-.578V7.088a2.3 2.3 0 0 0-.496-.262l-.002-.001a1.8 1.8 0 0 0-.65-.108q-.404 0-.71.177a1.3 1.3 0 0 0-.486.486l-.001.002a1.35 1.35 0 0 0-.177.703c0 .37.11.657.32.879.219.217.49.327.828.327.325 0 .589-.08.801-.229zm.77-2.076v1.559a2.4 2.4 0 0 1-.658.682q-.378.265-.916.265-.583 0-.97-.387-.378-.398-.378-1.019 0-.458.205-.805a1.5 1.5 0 0 1 .56-.56q.356-.203.809-.203.41 0 .722.122.323.122.626.346"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7.5 4.667a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H8a.5.5 0 0 1-.5-.5M4.832 11.333a.5.5 0 0 1 .5-.5h4.667a.5.5 0 1 1 0 1H5.332a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDongCurrencyMoneyCoinDongOutline.displayName = 'DongCurrencyMoneyCoinDongOutline';
const Memo = memo(SvgDongCurrencyMoneyCoinDongOutline);
export default Memo;
