// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettingsSliderVerSliderTwoOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.667 2a.5.5 0 0 1 .5.5v.88a2.667 2.667 0 0 1 0 5.24v4.88a.5.5 0 0 1-1 0V8.62a2.667 2.667 0 0 1 0-5.24V2.5a.5.5 0 0 1 .5-.5m6.666 0a.5.5 0 0 1 .5.5v6.213a2.667 2.667 0 1 1-1 0V2.5a.5.5 0 0 1 .5-.5M4.667 4.333a1.666 1.666 0 1 0-.002 3.332 1.666 1.666 0 0 0 .002-3.332m6.666 5.334a1.666 1.666 0 1 0 0 3.332 1.666 1.666 0 0 0 0-3.332"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettingsSliderVerSliderTwoOutline.displayName = 'SettingsSliderVerSliderTwoOutline';
const Memo = memo(SvgSettingsSliderVerSliderTwoOutline);
export default Memo;
