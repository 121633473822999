// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble2MessageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M12.836 2c1.012 0 1.833.82 1.833 1.833v7c0 1.013-.82 1.834-1.833 1.834H8.13l-3.22 1.771A.5.5 0 0 1 4.168 14v-1.333h-1a1.833 1.833 0 0 1-1.833-1.834v-7C1.336 2.821 2.156 2 3.169 2z" />
    </Svg>
  );
};
SvgBubble2MessageFill.displayName = 'Bubble2MessageFill';
const Memo = memo(SvgBubble2MessageFill);
export default Memo;
