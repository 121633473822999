// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSyncCloudSyncFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.855 5.416A4.501 4.501 0 0 1 12.49 6.86a3.25 3.25 0 0 1-.406 6.474H4.668a4 4 0 0 1-.813-7.917m4.584 2.418L9.59 6.87a.267.267 0 0 0 0-.409L8.44 5.5a.267.267 0 0 0-.438.205v.463a2.333 2.333 0 1 0 2.296 2.753.5.5 0 1 0-.984-.18 1.334 1.334 0 1 1-1.312-1.573v.462c0 .227.264.35.438.205"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSyncCloudSyncFill.displayName = 'SyncCloudSyncFill';
const Memo = memo(SvgSyncCloudSyncFill);
export default Memo;
