// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettings6SliderThreeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.8 1.998a.5.5 0 0 1 .5.5v4.335a.5.5 0 1 1-1 0V2.498a.5.5 0 0 1 .5-.5m8.398 0a.5.5 0 0 1 .5.5v5.67a.5.5 0 1 1-1 0v-5.67a.5.5 0 0 1 .5-.5m-4.2.167a.5.5 0 0 1 .5.5v3.001h.834a.5.5 0 1 1 0 1H6.665a.5.5 0 0 1 0-1H7.5V2.665a.5.5 0 0 1 .5-.5m0 5.835a.5.5 0 0 1 .5.5v4.836a.5.5 0 0 1-1 0V8.5a.5.5 0 0 1 .5-.5M1.997 9.168a.5.5 0 0 1 .5-.5h2.67a.5.5 0 1 1 0 1H4.3v3.835a.5.5 0 1 1-1 0V9.668h-.804a.5.5 0 0 1-.5-.5m8.336 1.333a.5.5 0 0 1 .5-.5h2.667a.5.5 0 0 1 0 1h-.801v2.501a.5.5 0 1 1-1 0v-2.5h-.866a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettings6SliderThreeFill.displayName = 'Settings6SliderThreeFill';
const Memo = memo(SvgSettings6SliderThreeFill);
export default Memo;
