// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWatch1ClockTimeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m4.708 1.464-.404 1.213A1.834 1.834 0 0 0 2.668 4.5v7c0 .946.716 1.725 1.636 1.823l.404 1.213c.16.476.605.797 1.107.797h4.373c.502 0 .947-.32 1.106-.797l.404-1.213a1.833 1.833 0 0 0 1.637-1.823v-7c0-.946-.717-1.724-1.637-1.823l-.404-1.213a1.17 1.17 0 0 0-1.107-.797H5.816c-.502 0-.948.321-1.107.797m1.107.203a.17.17 0 0 0-.158.114l-.295.886h5.279l-.295-.886a.17.17 0 0 0-.159-.114zm4.826 11.666H5.36l.296.886a.17.17 0 0 0 .158.114h4.373a.17.17 0 0 0 .158-.114zm-2.14-7.5a.5.5 0 1 0-1 0V8a.5.5 0 0 0 .147.354l1 1a.5.5 0 0 0 .707-.707L8.5 7.793z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWatch1ClockTimeFill.displayName = 'Watch1ClockTimeFill';
const Memo = memo(SvgWatch1ClockTimeFill);
export default Memo;
