// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLinkedinBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.682 1.333H2.32a.97.97 0 0 0-.984.961v11.41c0 .53.44.963.984.963h11.362c.545 0 .987-.433.987-.961V2.294a.974.974 0 0 0-.987-.96m-8.39 11.362h-1.98V6.331h1.98zm-.99-7.231a1.146 1.146 0 1 1-.004-2.293 1.146 1.146 0 0 1 .004 2.293m8.396 7.231H10.72V9.602c0-.737-.013-1.688-1.028-1.688-1.029 0-1.185.805-1.185 1.635v3.146H6.534V6.331H8.43v.87h.026c.263-.5.909-1.03 1.87-1.03 2.002 0 2.372 1.319 2.372 3.032z" />
    </Svg>
  );
};
SvgLinkedinBw.displayName = 'LinkedinBw';
const Memo = memo(SvgLinkedinBw);
export default Memo;
