// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEmailEnvelopeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="m3.297 4.174 4.174 3.415a.83.83 0 0 0 1.055 0L12.7 4.174a17 17 0 0 0-.668-.007H3.965c-.302 0-.51 0-.668.007m-1.433.12c.02-.16.056-.286.113-.4a1.33 1.33 0 0 1 .583-.582c.285-.145.659-.145 1.405-.145h8.067c.747 0 1.12 0 1.405.145a1.34 1.34 0 0 1 .583.583c.058.113.092.24.113.398.032.242.032.556.032 1.007v5.4c0 .747 0 1.12-.145 1.405-.128.251-.332.455-.583.583-.285.145-.658.145-1.405.145H3.965c-.746 0-1.12 0-1.405-.145a1.33 1.33 0 0 1-.583-.583c-.145-.285-.145-.658-.145-1.405V5.3c0-.45 0-.765.032-1.007m11.301.792L9.16 8.363a1.83 1.83 0 0 1-2.322 0L2.832 5.086V10.7c0 .39 0 .622.015.794.008.105.02.15.023.161q.049.092.14.14a1 1 0 0 0 .161.024c.172.014.405.014.794.014h8.067c.39 0 .622 0 .794-.014a1 1 0 0 0 .161-.024.33.33 0 0 0 .14-.14 1 1 0 0 0 .023-.16c.015-.173.015-.405.015-.795V5.086"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEmailEnvelopeOutline.displayName = 'EmailEnvelopeOutline';
const Memo = memo(SvgEmailEnvelopeOutline);
export default Memo;
