// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTapeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.668 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0M10.335 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
      <Path
        fillRule="evenodd"
        d="M.668 4.5c0-1.012.82-1.833 1.833-1.833h11c1.013 0 1.834.82 1.834 1.833v7c0 1.013-.821 1.833-1.834 1.833h-11A1.833 1.833 0 0 1 .668 11.5zm7 3.5c0 .364-.097.706-.268 1h1.202a2 2 0 1 1 1.732 1H5.669a2 2 0 1 1 2-2"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTapeFill.displayName = 'TapeFill';
const Memo = memo(SvgTapeFill);
export default Memo;
