// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMemory2SimCardSdCardFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.501 1.333c1.013 0 1.834.821 1.834 1.834v9.666c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834V5.328A2.5 2.5 0 0 1 3.4 3.561l1.495-1.496a2.5 2.5 0 0 1 1.768-.732h1.005v3.5a.5.5 0 0 0 1 0v-3.5h1.667v3.5a.5.5 0 0 0 1 0v-3.5z" />
    </Svg>
  );
};
SvgMemory2SimCardSdCardFill.displayName = 'Memory2SimCardSdCardFill';
const Memo = memo(SvgMemory2SimCardSdCardFill);
export default Memo;
