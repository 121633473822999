// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBatteryEmptyPowerFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 5.167c0-1.013.82-1.833 1.833-1.833h9c1.013 0 1.834.82 1.834 1.833v.167h.833c.644 0 1.167.522 1.167 1.166v3c0 .645-.523 1.167-1.167 1.167h-.833v.167c0 1.012-.821 1.833-1.834 1.833h-9a1.833 1.833 0 0 1-1.833-1.833zm12.667 4.5h.833a.167.167 0 0 0 .167-.167v-3a.167.167 0 0 0-.167-.167h-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBatteryEmptyPowerFill.displayName = 'BatteryEmptyPowerFill';
const Memo = memo(SvgBatteryEmptyPowerFill);
export default Memo;
