// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgServer2StorageDataCoinsMoneyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.295 8.034c.386-.14.74-.3 1.04-.487v1.387c0 .08-.047.226-.28.42s-.6.393-1.094.566c-.986.354-2.386.58-3.96.58s-2.966-.226-3.96-.58c-.5-.173-.86-.373-1.093-.566-.233-.2-.28-.34-.28-.42V7.547c.3.187.653.347 1.04.487 1.12.4 2.64.633 4.293.633s3.167-.233 4.294-.633M8 11.5c-1.653 0-3.166-.233-4.293-.633-.387-.14-.74-.3-1.04-.487v2.074c0 .44.253.793.56 1.053s.72.467 1.187.633c.94.334 2.206.534 3.586.534s2.647-.2 3.587-.534c.467-.166.88-.373 1.187-.633.306-.253.56-.607.56-1.053V10.38c-.3.187-.654.347-1.04.487-1.12.4-2.64.633-4.294.633m5.334-7.953V6.1c0 .08-.047.227-.28.42-.234.194-.6.394-1.094.567-.986.353-2.386.58-3.96.58s-2.966-.227-3.96-.58c-.5-.173-.86-.373-1.093-.567-.233-.2-.28-.34-.28-.42V3.547c0-.44.253-.793.56-1.053s.72-.467 1.187-.634c.94-.333 2.206-.533 3.586-.533s2.647.2 3.587.533c.467.167.88.374 1.187.634.306.253.56.606.56 1.053m-9.667 0s.013.127.2.287c.18.153.473.313.88.453.807.287 1.96.473 3.253.473s2.44-.186 3.254-.473c.406-.147.7-.307.88-.453.186-.154.2-.254.2-.287s-.014-.127-.2-.287c-.18-.153-.474-.313-.88-.453-.807-.287-1.96-.473-3.254-.473-1.293 0-2.44.186-3.253.473-.407.147-.7.307-.88.453-.187.154-.2.254-.2.287"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgServer2StorageDataCoinsMoneyFill.displayName = 'Server2StorageDataCoinsMoneyFill';
const Memo = memo(SvgServer2StorageDataCoinsMoneyFill);
export default Memo;
