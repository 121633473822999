// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRaisingHand5FingerHeyHelloOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.168 2.333a.5.5 0 0 0-.5.5v4a.5.5 0 1 1-1 0V3.5a.5.5 0 0 0-1 0v4a.5.5 0 0 1-1 0V4.833a.5.5 0 0 0-1 0v4.334a4.5 4.5 0 0 0 9 0V5.842a1.5 1.5 0 0 0-1.333 1.491v.872a.69.69 0 0 1-.434.64 1.96 1.96 0 0 0-1.233 1.822.5.5 0 1 1-1 0c0-1.14.653-2.171 1.667-2.664v-.67c0-.453.12-.88.333-1.248V3.5a.5.5 0 0 0-1 0v3.333a.5.5 0 1 1-1 0v-4a.5.5 0 0 0-.5-.5m1.338-.18A1.5 1.5 0 0 1 11.668 3.5v1.623a2.5 2.5 0 0 1 1.167-.289c.46 0 .833.373.833.834v3.5a5.5 5.5 0 0 1-11 0V4.833A1.5 1.5 0 0 1 4.67 3.42a1.5 1.5 0 0 1 2.16-1.265 1.5 1.5 0 0 1 2.676 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRaisingHand5FingerHeyHelloOutline.displayName = 'RaisingHand5FingerHeyHelloOutline';
const Memo = memo(SvgRaisingHand5FingerHeyHelloOutline);
export default Memo;
