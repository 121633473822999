// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArcBrowserFaceFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m5.167-3.333a.5.5 0 0 1 .5.5V7.5a.5.5 0 0 1-1 0V5.167a.5.5 0 0 1 .5-.5m3 0a.5.5 0 0 1 .5.5V7.5a.5.5 0 0 1-1 0V5.167a.5.5 0 0 1 .5-.5M5.403 9.909a.5.5 0 0 1 .686-.171c.693.416 1.314.604 1.91.604s1.216-.188 1.91-.604a.5.5 0 0 1 .514.857c-.807.484-1.604.747-2.424.747s-1.617-.263-2.424-.747a.5.5 0 0 1-.172-.686"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgArcBrowserFaceFill.displayName = 'ArcBrowserFaceFill';
const Memo = memo(SvgArcBrowserFaceFill);
export default Memo;
