// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgGymOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.94 4.89a.43.43 0 0 0-.31.13.45.45 0 0 0-.128.314v5.333c0 .118.046.231.128.314a.434.434 0 0 0 .619 0 .45.45 0 0 0 .128-.314V8.081a.5.5 0 0 1 0-.161V5.334a.45.45 0 0 0-.128-.315.43.43 0 0 0-.31-.13m1.312 5.777V8.445h3.503v2.222c0 .354.138.693.384.943a1.3 1.3 0 0 0 1.857 0c.246-.25.384-.59.384-.943v-.521a1.29 1.29 0 0 0 1.367-.314c.246-.25.384-.59.384-.943v-.444h.43A.44.44 0 0 0 14.998 8a.44.44 0 0 0-.437-.444h-.43V7.11c0-.353-.138-.692-.384-.942a1.3 1.3 0 0 0-1.367-.314v-.521c0-.354-.138-.693-.384-.943a1.303 1.303 0 0 0-1.857 0c-.246.25-.384.59-.384.943v2.222H6.252V5.334c0-.354-.138-.693-.385-.943a1.303 1.303 0 0 0-1.856 0c-.246.25-.385.59-.385.943v.52a1.29 1.29 0 0 0-1.366.315c-.246.25-.385.589-.385.942v.445h-.438A.44.44 0 0 0 1 8a.44.44 0 0 0 .437.445h.438v.444c0 .354.139.693.385.943a1.3 1.3 0 0 0 1.366.314v.521c0 .354.139.693.385.943a1.3 1.3 0 0 0 1.856 0c.247-.25.385-.59.385-.943m7.004-2.747V7.11a.45.45 0 0 0-.128-.314.434.434 0 0 0-.619 0 .45.45 0 0 0-.128.314V8.89c0 .118.046.231.128.314a.434.434 0 0 0 .619 0 .45.45 0 0 0 .128-.314v-.808a.5.5 0 0 1 0-.161M3.188 6.667a.43.43 0 0 0-.31.13.45.45 0 0 0-.127.314V8.89c0 .118.046.231.128.314a.434.434 0 0 0 .619 0 .45.45 0 0 0 .128-.314V7.111a.45.45 0 0 0-.128-.314.43.43 0 0 0-.31-.13m7.57-1.648a.434.434 0 0 1 .619 0 .45.45 0 0 1 .128.315v5.333a.45.45 0 0 1-.128.314.434.434 0 0 1-.619 0 .45.45 0 0 1-.128-.314V5.334c0-.118.046-.231.128-.315"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgGymOutline.displayName = 'GymOutline';
const Memo = memo(SvgGymOutline);
export default Memo;
