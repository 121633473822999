// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBugIssueOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a2.333 2.333 0 0 0-2.334 2.334h4.667a2.333 2.333 0 0 0-2.333-2.334m3.333 2.403v-.07a3.333 3.333 0 1 0-6.667 0v.07a1.84 1.84 0 0 0-1.25 1.218L2.16 5.527a.5.5 0 0 0-.322.946l1.494.509v1.685h-1.5a.5.5 0 1 0 0 1h1.5V10c0 .393.049.776.14 1.14l-.054.016-1.58.537a.5.5 0 0 0 .322.947l1.58-.537a1 1 0 0 0 .075-.033 4.667 4.667 0 0 0 8.367 0 1 1 0 0 0 .076.033l1.58.537a.5.5 0 0 0 .322-.947l-1.58-.537-.055-.015c.092-.365.14-.748.14-1.141v-.333h1.5a.5.5 0 0 0 0-1h-1.5V6.982l1.495-.509a.5.5 0 1 0-.322-.946l-1.255.427a1.84 1.84 0 0 0-1.251-1.218m-6.167.93a.833.833 0 0 0-.833.834V10a3.67 3.67 0 0 0 3.167 3.633V9.167a.5.5 0 1 1 1 0v4.466A3.67 3.67 0 0 0 11.665 10V6.5a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBugIssueOutline.displayName = 'BugIssueOutline';
const Memo = memo(SvgBugIssueOutline);
export default Memo;
