// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBroomBrushOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.79 4.939a.33.33 0 0 0 .15-.15l.43-.86a.333.333 0 0 1 .596 0l.43.86a.33.33 0 0 0 .149.15l.86.43a.333.333 0 0 1 0 .596l-.86.43a.33.33 0 0 0-.15.149l-.43.86a.333.333 0 0 1-.596 0l-.43-.86a.33.33 0 0 0-.149-.15l-.86-.43a.333.333 0 0 1 0-.595zM6.374 2.48a.24.24 0 0 0 .107-.107l.305-.61a.24.24 0 0 1 .43 0l.305.61a.24.24 0 0 0 .107.107l.61.305a.24.24 0 0 1 0 .43l-.61.305a.24.24 0 0 0-.107.107l-.306.61a.24.24 0 0 1-.429 0l-.305-.61a.24.24 0 0 0-.107-.107l-.61-.305a.24.24 0 0 1 0-.43z" />
      <Path
        fillRule="evenodd"
        d="M13.935 2.082a.5.5 0 1 0-.868-.497L9.659 7.543l-.552-.2c-1.356-.49-2.718.26-3.416 1.35-.624.976-1.453 1.645-2.466 2.316a.5.5 0 0 0 .083.878l6.55 2.74a.5.5 0 0 0 .55-.11c1.281-1.3 1.961-2.449 2.186-4.04.16-1.132-.61-2.093-1.59-2.448l-.39-.14zm-7.401 7.15c.527-.824 1.444-1.234 2.233-.948l1.896.685c.64.232 1.02.804.94 1.369-.173 1.229-.656 2.148-1.674 3.235L7.387 12.51l.452-1.357a.167.167 0 0 0-.255-.188L6.147 11.99l-1.616-.676c.759-.566 1.446-1.214 2.003-2.083"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBroomBrushOutline.displayName = 'BroomBrushOutline';
const Memo = memo(SvgBroomBrushOutline);
export default Memo;
