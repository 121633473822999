// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEthereumOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.667-4.167a.5.5 0 0 1 .404.206l2.667 3.667a.5.5 0 0 1 0 .588l-2.667 3.667a.5.5 0 0 1-.809 0L4.928 8.294a.5.5 0 0 1 0-.588l2.666-3.667A.5.5 0 0 1 8 3.833m-1.283 5.22 1.107.415a.5.5 0 0 0 .351 0l1.107-.415L8 10.817zm3.158-1.29L8 8.466l-1.876-.703 1.876-2.58z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEthereumOutline.displayName = 'EthereumOutline';
const Memo = memo(SvgEthereumOutline);
export default Memo;
