// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCompassSquareBrowserSafariWebInternetNavigationFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM9.705 5.58a.583.583 0 0 1 .716.716l-.746 2.737a.92.92 0 0 1-.643.643l-2.737.746a.583.583 0 0 1-.716-.716l.746-2.737a.92.92 0 0 1 .643-.643z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCompassSquareBrowserSafariWebInternetNavigationFill.displayName =
  'CompassSquareBrowserSafariWebInternetNavigationFill';
const Memo = memo(SvgCompassSquareBrowserSafariWebInternetNavigationFill);
export default Memo;
