// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRentCommercialRentCommercialFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.906 2.4c-.994 0-1.8.811-1.8 1.812v8.4h-.818a.49.49 0 0 0-.491.494c0 .273.22.494.49.494h13.419c.271 0 .49-.221.49-.494a.493.493 0 0 0-.49-.494h-.818v-5.6c0-1-.806-1.812-1.8-1.812H9.96v-.988c0-1-.806-1.812-1.8-1.812zm9 10.212H9.961V6.188h2.127a.82.82 0 0 1 .818.824zM6.5 7a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M8 6.5a1.5 1.5 0 0 1-1 1.415V11.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1 0-1h1v-1h-.5a.5.5 0 0 1 0-1H6V7.915A1.5 1.5 0 1 1 8 6.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRentCommercialRentCommercialFill.displayName = 'RentCommercialRentCommercialFill';
const Memo = memo(SvgRentCommercialRentCommercialFill);
export default Memo;
