// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPanoramaViewOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill={color}
      viewBox="0 0 24 24"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.23 5.537c-.89-.228-1.73.427-1.73 1.264v10.383c0 .84.845 1.497 1.74 1.265 5.253-1.358 10.267-1.358 15.52 0 .894.232 1.74-.426 1.74-1.265V6.801c0-.837-.84-1.492-1.73-1.264-5.276 1.352-10.264 1.352-15.54 0M1 6.801c0-1.897 1.844-3.167 3.602-2.717 5.032 1.29 9.764 1.29 14.796 0 1.758-.45 3.602.82 3.602 2.717v10.383c0 1.903-1.854 3.173-3.615 2.718-5.007-1.295-9.763-1.295-14.77 0C2.854 20.357 1 19.087 1 17.184z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPanoramaViewOutline.displayName = 'PanoramaViewOutline';
const Memo = memo(SvgPanoramaViewOutline);
export default Memo;
