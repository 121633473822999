// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgOrangeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.427.782c.88-.508 1.75-.491 2.654-.342a.5.5 0 0 1 .405.613 3.48 3.48 0 0 1-1.776 2.259 6 6 0 1 1-3.46-.599c-.36-.505-1.04-.966-1.961-1.048a.5.5 0 1 1 .089-.996c1.19.106 2.173.717 2.712 1.498A3.47 3.47 0 0 1 9.427.782M8.94 2.7a2.47 2.47 0 0 0 1.403-.328 2.47 2.47 0 0 0 .969-1.017c-.523-.03-.957.045-1.386.293A2.47 2.47 0 0 0 8.94 2.7M8 3.667a5 5 0 1 0 0 10 5 5 0 0 0 0-10m3.366 4.835a.5.5 0 0 1 .448.547 3.834 3.834 0 0 1-3.431 3.432.5.5 0 1 1-.1-.995 2.834 2.834 0 0 0 2.536-2.535.5.5 0 0 1 .547-.449"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgOrangeOutline.displayName = 'OrangeOutline';
const Memo = memo(SvgOrangeOutline);
export default Memo;
