// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAlignBottom2ArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 2a.5.5 0 0 1 .5.5v9.126l1.98-1.98a.5.5 0 1 1 .707.708l-2.362 2.362c-.134.134-.294.229-.463.284h4.472a.5.5 0 1 1 0 1H3.168a.5.5 0 0 1 0-1H7.64a1.16 1.16 0 0 1-.464-.284l-2.362-2.362a.5.5 0 0 1 .708-.708l1.98 1.98V2.5a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAlignBottom2ArrowFill.displayName = 'AlignBottom2ArrowFill';
const Memo = memo(SvgAlignBottom2ArrowFill);
export default Memo;
