import React from 'react';
import {StyleSheet, View} from 'react-native';

import {useHive} from '@propertyguru/hive';

import type {DividerProps} from './types';

export const Divider: React.FC<DividerProps> = ({style, ...props}) => {
  const hive = useHive();

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: hive.color('border/active/secondary'),
        },
        style,
      ]}
      {...props}
    />
  );
};

Divider.displayName = 'Divider';

const styles = StyleSheet.create({
  container: {
    height: 1,
  },
});
