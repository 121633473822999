// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSmileEmojiFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.285 3.286a6.667 6.667 0 1 1 9.428 9.428 6.667 6.667 0 0 1-9.428-9.428m2.88 4.047c.46 0 .834-.447.834-1s-.373-1-.834-1-.833.448-.833 1c0 .553.373 1 .833 1m4.5-1c0 .553-.373 1-.833 1s-.833-.447-.833-1 .373-1 .833-1 .833.448.833 1M6.467 9.532a.5.5 0 1 0-.707.707 3.167 3.167 0 0 0 4.478 0 .5.5 0 0 0-.707-.707 2.167 2.167 0 0 1-3.064 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSmileEmojiFill.displayName = 'SmileEmojiFill';
const Memo = memo(SvgSmileEmojiFill);
export default Memo;
