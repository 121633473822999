// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTaxiOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.043 2c.232 0 .42.198.42.442v1.579H8.81c.553 0 1.052.346 1.27.88l1.083 2.657h2.125c.763 0 1.381.65 1.381 1.453v2.02c0 .803-.618 1.453-1.381 1.453h-.113c-.19.869-.93 1.516-1.815 1.516s-1.624-.647-1.815-1.516H6.451c-.19.869-.93 1.516-1.815 1.516s-1.624-.647-1.815-1.516h-.104c-.763 0-1.381-.65-1.381-1.452V9.01c0-.803.618-1.453 1.381-1.453h.204L4.003 4.9c.218-.534.717-.88 1.27-.88h1.35V2.442c0-.244.188-.442.42-.442m0 2.905h-1.77a.54.54 0 0 0-.497.345l-.94 2.308h6.412l-.94-2.308a.54.54 0 0 0-.498-.345H7.043m3.852 3.537H2.717a.555.555 0 0 0-.54.569v2.02c0 .314.242.569.54.569h.104c.19-.87.93-1.518 1.815-1.518s1.626.648 1.816 1.518h3.093c.19-.87.93-1.518 1.815-1.518s1.626.648 1.816 1.518h.112c.298 0 .54-.255.54-.568V9.01a.555.555 0 0 0-.54-.569zm-.556 3.646c.024.572.472 1.028 1.021 1.028.565 0 1.022-.481 1.022-1.075s-.457-1.075-1.022-1.075c-.55 0-.998.457-1.02 1.029a.5.5 0 0 1 0 .094m-5.703 1.028c-.548 0-.995-.453-1.02-1.023a.5.5 0 0 0 0-.102c.024-.57.472-1.025 1.02-1.025.565 0 1.022.481 1.022 1.075s-.457 1.075-1.022 1.075"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTaxiOutline.displayName = 'TaxiOutline';
const Memo = memo(SvgTaxiOutline);
export default Memo;
