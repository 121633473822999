// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPeopleTogetherUserAvatarGroup2Fill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.333 4.667a2.667 2.667 0 1 1 5.334 0 2.667 2.667 0 0 1-5.334 0M10.461 11.705c-.318-1.21-.88-2.218-1.606-2.985A4.26 4.26 0 0 1 11.22 8c1.97 0 3.864 1.377 4.542 3.959.297 1.13-.658 2.041-1.687 2.041H9.956c.495-.58.747-1.376.505-2.295M11 2a2.667 2.667 0 1 0 0 5.333A2.667 2.667 0 0 0 11 2M4.952 8c1.971 0 3.864 1.377 4.542 3.959C9.791 13.089 8.837 14 7.807 14h-5.71C1.068 14 .113 13.09.41 11.959 1.088 9.377 2.981 8 4.952 8" />
    </Svg>
  );
};
SvgPeopleTogetherUserAvatarGroup2Fill.displayName = 'PeopleTogetherUserAvatarGroup2Fill';
const Memo = memo(SvgPeopleTogetherUserAvatarGroup2Fill);
export default Memo;
