// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRedoForwardUpwardFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M11.481 2.98a.5.5 0 0 1 .707 0l2.195 2.195a1.167 1.167 0 0 1 0 1.65L12.189 9.02a.5.5 0 0 1-.707-.707L13.294 6.5h-8.96c-1.196 0-2.166.97-2.166 2.167V9.5c0 1.197.97 2.167 2.167 2.167H8a.5.5 0 0 1 0 1H4.335A3.167 3.167 0 0 1 1.168 9.5v-.833A3.167 3.167 0 0 1 4.335 5.5h8.96L11.48 3.687a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRedoForwardUpwardFill.displayName = 'RedoForwardUpwardFill';
const Memo = memo(SvgRedoForwardUpwardFill);
export default Memo;
