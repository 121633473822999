// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEditBigBoxPencilPenWriteDrawOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.256 2.63a.833.833 0 0 0-1.179 0L6.382 8.325a.17.17 0 0 0-.049.118v1.224h1.224a.17.17 0 0 0 .118-.05l5.695-5.694a.833.833 0 0 0 0-1.179zm-1.886-.707a1.833 1.833 0 0 1 2.593 0l.114.114a1.833 1.833 0 0 1 0 2.593l-5.695 5.695a1.17 1.17 0 0 1-.825.342H5.833a.5.5 0 0 1-.5-.5V8.443c0-.31.123-.606.342-.825zM4.613 2H7.5a.5.5 0 0 1 0 1H4.633c-.381 0-.637 0-.835.017-.191.015-.283.043-.343.074a.83.83 0 0 0-.364.364c-.03.06-.059.152-.074.343C3 3.996 3 4.252 3 4.633v6.734c0 .381 0 .637.017.835.015.191.043.283.074.343.08.157.207.284.364.364.06.03.152.059.343.074.198.017.454.017.835.017h6.734c.381 0 .637 0 .835-.017.191-.015.283-.043.343-.074a.83.83 0 0 0 .364-.364c.03-.06.059-.152.074-.343.017-.198.017-.454.017-.835V8h1v3.387c0 .356 0 .653-.02.896-.02.253-.065.491-.18.716A1.83 1.83 0 0 1 13 13.8c-.225.115-.463.16-.716.18-.243.02-.54.02-.896.02H4.613c-.356 0-.653 0-.896-.02A1.9 1.9 0 0 1 3 13.8 1.83 1.83 0 0 1 2.2 13a1.9 1.9 0 0 1-.18-.716C2 12.04 2 11.743 2 11.387V4.613c0-.356 0-.653.02-.896.02-.253.065-.491.18-.716.176-.345.456-.625.801-.801.225-.115.463-.16.716-.18.243-.02.54-.02.896-.02"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEditBigBoxPencilPenWriteDrawOutline.displayName = 'EditBigBoxPencilPenWriteDrawOutline';
const Memo = memo(SvgEditBigBoxPencilPenWriteDrawOutline);
export default Memo;
