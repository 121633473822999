// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTrophyWinChampionOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.401 2.667a1.834 1.834 0 0 1 1.764-1.333h5.667c.84 0 1.547.563 1.764 1.333h.236c1.013 0 1.833.82 1.833 1.833v.333A2.5 2.5 0 0 1 12.52 7.31a4.5 4.5 0 0 1-4.018 3.346v.679h1.831a1.667 1.667 0 1 1 0 3.333H5.665a1.667 1.667 0 1 1 0-3.333h1.836v-.68A4.5 4.5 0 0 1 3.478 7.31a2.5 2.5 0 0 1-2.146-2.476V4.5c0-1.012.82-1.833 1.833-1.833zm-.069 1h-.167a.833.833 0 0 0-.833.833v.333a1.5 1.5 0 0 0 1 1.415V3.667m9.333 2.581a1.5 1.5 0 0 0 1-1.415V4.5a.833.833 0 0 0-.833-.833h-.167v2.581m-7.5-3.915a.833.833 0 0 0-.833.834v3a3.5 3.5 0 0 0 3.5 3.5h.333a3.5 3.5 0 0 0 3.5-3.5v-3a.833.833 0 0 0-.833-.833zm.5 10a.667.667 0 0 0 0 1.334h4.667a.667.667 0 1 0 0-1.333z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTrophyWinChampionOutline.displayName = 'TrophyWinChampionOutline';
const Memo = memo(SvgTrophyWinChampionOutline);
export default Memo;
