// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMagicBookMagicianFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 1.333c-1.012 0-1.833.821-1.833 1.834v9.666c0 1.013.82 1.834 1.833 1.834h8.334a.5.5 0 0 0 .5-.5V1.833a.5.5 0 0 0-.5-.5zm0 12.334a.833.833 0 1 1 0-1.667h7.834v1.667zM8.273 4.79a.33.33 0 0 1-.149.149l-.86.43a.333.333 0 0 0 0 .596l.86.43a.33.33 0 0 1 .15.149l.43.86a.333.333 0 0 0 .596 0l.43-.86a.33.33 0 0 1 .149-.15l.86-.43a.333.333 0 0 0 0-.595l-.86-.43a.33.33 0 0 1-.15-.15l-.43-.86a.333.333 0 0 0-.596 0zM5.81 7.718a.2.2 0 0 1-.09.09l-.693.346a.2.2 0 0 0 0 .358l.694.347a.2.2 0 0 1 .09.09l.346.693a.2.2 0 0 0 .358 0l.347-.693a.2.2 0 0 1 .089-.09l.694-.347a.2.2 0 0 0 0-.358l-.694-.346a.2.2 0 0 1-.09-.09l-.346-.694a.2.2 0 0 0-.358 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMagicBookMagicianFill.displayName = 'MagicBookMagicianFill';
const Memo = memo(SvgMagicBookMagicianFill);
export default Memo;
