// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSecuredLockedSecurityDocumentListPageFileFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M2.668 3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v5.375a2.918 2.918 0 0 0-4.91 1.322 1.5 1.5 0 0 0-.757 1.303v3q.001.264.085.5H4.501a1.833 1.833 0 0 1-1.833-1.833z" />
      <Path
        fillRule="evenodd"
        d="M13.335 10.78v-.113a2 2 0 0 0-4 0v.112a1.17 1.17 0 0 0-.667 1.055V13.5c0 .645.522 1.167 1.167 1.167h3c.644 0 1.166-.522 1.166-1.167v-1.666c0-.466-.272-.868-.666-1.055m-3-.113h2a1 1 0 1 0-2 0m2.5 1h-3a.167.167 0 0 0-.167.167V13.5c0 .092.075.167.167.167h3A.167.167 0 0 0 13 13.5v-1.666a.167.167 0 0 0-.166-.167"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSecuredLockedSecurityDocumentListPageFileFill.displayName =
  'SecuredLockedSecurityDocumentListPageFileFill';
const Memo = memo(SvgSecuredLockedSecurityDocumentListPageFileFill);
export default Memo;
