// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSellResidentialSellResidentialOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.678 1.189a1.16 1.16 0 0 1 .572 0c.215.055.402.19.577.315l.045.033 5.684 4.074a.499.499 0 0 1-.58.81l-.705-.505v5.434c0 .354 0 .65-.02.893-.02.252-.064.489-.178.713a1.83 1.83 0 0 1-.798.798c-.223.114-.46.159-.712.18-.242.02-.538.02-.892.02H5.257c-.354 0-.65 0-.892-.02a1.9 1.9 0 0 1-.712-.18 1.83 1.83 0 0 1-.797-.798 1.9 1.9 0 0 1-.18-.713c-.02-.242-.02-.539-.02-.893V5.916l-.705.505a.497.497 0 1 1-.579-.81l5.685-4.074.044-.032c.175-.127.362-.261.577-.316M3.652 5.202v6.127c0 .38 0 .636.016.832.016.191.044.282.074.343a.83.83 0 0 0 .363.362c.06.031.15.059.341.075.197.015.451.016.831.016h5.374c.38 0 .635 0 .83-.017.191-.015.282-.043.342-.074a.83.83 0 0 0 .363-.362c.03-.06.058-.152.074-.342.016-.197.016-.452.016-.833V5.203L8.292 2.347a5 5 0 0 0-.237-.164.4.4 0 0 0-.05-.028.2.2 0 0 0-.081 0 .4.4 0 0 0-.051.028c-.053.032-.12.08-.237.164zm4.273-3.048zm.078 0h.001z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M8.503 4.836a.67.67 0 0 0-.531.198l-3.56 3.56a.846.846 0 0 0 0 1.196l1.795 1.796a.846.846 0 0 0 1.196 0l3.56-3.56a.7.7 0 0 0 .158-.24l-.468-.178.467.178a.67.67 0 0 0 .038-.327l-.27-1.75a.67.67 0 0 0-.599-.6l-1.75-.27zm2.46 3.19-.005.005-.345-.362.353.354zM7.973 5.033l.003-.003.353.354-.361-.346zM5.228 9.192l3.336-3.336 1.367.21.21 1.367-3.336 3.337zM8.798 7.6a.4.4 0 1 0 0-.8.4.4 0 0 0 0 .8"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSellResidentialSellResidentialOutline.displayName = 'SellResidentialSellResidentialOutline';
const Memo = memo(SvgSellResidentialSellResidentialOutline);
export default Memo;
