// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLifeVestVestOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6 2c.262 0 .474.212.474.474 0 .796.286 1.356.684 1.72.407.371.96.566 1.526.566s1.12-.195 1.526-.566c.399-.364.685-.924.685-1.72a.474.474 0 0 1 .947 0c0 .783.002 1.588.157 2.282.153.687.437 1.178.935 1.44.63.33 1.434.954 1.434 1.922v4.145c0 .96-.777 1.737-1.736 1.737H4.737C3.777 14 3 13.222 3 12.263V8.158c0-.497.219-.906.501-1.221.279-.311.636-.551.97-.73.476-.256.753-.75.903-1.447.15-.701.152-1.511.152-2.286C5.526 2.212 5.738 2 6 2m.346 2.721q-.022.12-.046.238c-.176.82-.551 1.637-1.38 2.082a2.6 2.6 0 0 0-.713.528c-.175.195-.26.388-.26.589v2.053h4.264V8.63h-.948a.474.474 0 0 1 0-.947h.948v-2.01a3.15 3.15 0 0 1-1.692-.78 3 3 0 0 1-.173-.173m2.812.953v2.01h.947a.474.474 0 0 1 0 .948h-.947v1.579h4.263V8.118c0-.396-.349-.78-.927-1.083-.847-.444-1.236-1.252-1.42-2.073a6 6 0 0 1-.048-.244q-.085.091-.177.175a3.15 3.15 0 0 1-1.691.781m4.263 5.484H9.158v1.895h3.474a.79.79 0 0 0 .79-.79zm-5.21 1.895v-1.895H3.947v1.105c0 .436.354.79.79.79z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgLifeVestVestOutline.displayName = 'LifeVestVestOutline';
const Memo = memo(SvgLifeVestVestOutline);
export default Memo;
