// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWebcamCameraViewOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 2.333a4.333 4.333 0 1 0 0 8.667 4.333 4.333 0 0 0 0-8.667M2.668 6.667a5.333 5.333 0 1 1 5.833 5.31v1.69h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-1.69a5.334 5.334 0 0 1-4.833-5.31M8.001 5a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333M5.335 6.667a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWebcamCameraViewOutline.displayName = 'WebcamCameraViewOutline';
const Memo = memo(SvgWebcamCameraViewOutline);
export default Memo;
