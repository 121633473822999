// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgToastBreakfestOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.885 3.96C2.842 4.552 2.332 5.292 2.332 6c0 .602.365 1.224 1.124 1.767a.5.5 0 0 1 .21.407v3.993c0 .46.372.833.833.833h7c.46 0 .833-.373.833-.833V8.174a.5.5 0 0 1 .21-.407C13.3 7.224 13.664 6.602 13.664 6c0-.708-.51-1.448-1.553-2.04C11.085 3.377 9.632 3 8 3s-3.086.377-4.114.96m-.494-.87C4.596 2.407 6.225 2 8 2s3.403.407 4.607 1.09c1.19.675 2.06 1.685 2.06 2.91 0 .959-.537 1.789-1.334 2.423v3.744c0 1.012-.82 1.833-1.833 1.833h-7a1.833 1.833 0 0 1-1.834-1.833V8.423C1.868 7.789 1.332 6.959 1.332 6c0-1.225.87-2.235 2.06-2.91"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgToastBreakfestOutline.displayName = 'ToastBreakfestOutline';
const Memo = memo(SvgToastBreakfestOutline);
export default Memo;
