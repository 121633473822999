// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBug1IssueFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.408 2.172c.288-.398.797-.839 1.592-.839 1.226 0 1.976.972 2.43 1.907l.119.26a6.3 6.3 0 0 1 2.902 0q.059-.13.12-.26c.454-.935 1.204-1.907 2.43-1.907.794 0 1.303.44 1.591.839a2.7 2.7 0 0 1 .384.77l.005.017.002.007v.004a.5.5 0 0 1-.966.258v-.004l-.008-.025a1.675 1.675 0 0 0-.226-.44c-.17-.234-.41-.426-.783-.426-.608 0-1.108.474-1.53 1.343l-.061.133C12.513 4.682 14 6.663 14 9c0 3.159-2.716 5.667-6 5.667S2 12.159 2 9c0-2.337 1.488-4.318 3.592-5.19l-.063-.134C5.108 2.806 4.608 2.333 4 2.333c-.372 0-.613.192-.783.426a1.7 1.7 0 0 0-.233.465l-.001.004a.5.5 0 0 1-.966-.258v-.004l.002-.007.006-.017.017-.056q.022-.068.067-.179c.06-.144.156-.338.299-.535M7 9.167c0 .644-.448 1.166-1 1.166s-1-.522-1-1.166C5 8.522 5.448 8 6 8s1 .522 1 1.167m2 2.166c0 .369-.448.667-1 .667s-1-.298-1-.667c0-.368.448-.666 1-.666s1 .298 1 .666m1-1c.552 0 1-.522 1-1.166C11 8.522 10.552 8 10 8s-1 .522-1 1.167c0 .644.448 1.166 1 1.166"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBug1IssueFill.displayName = 'Bug1IssueFill';
const Memo = memo(SvgBug1IssueFill);
export default Memo;
