// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCmdBoxCommandAppleKeyFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M6.188 5.594v.593h-.594a.594.594 0 1 1 .593-.593M7.188 8.813V7.187h1.625v1.625zM5.594 9.813h.593v.593a.594.594 0 1 1-.593-.594M9.813 10.406v-.594h.593a.594.594 0 1 1-.594.594M10.406 6.188h-.594v-.594a.594.594 0 1 1 .594.593" />
      <Path
        fillRule="evenodd"
        d="M3.167 2C2.522 2 2 2.522 2 3.167v9.666C2 13.478 2.522 14 3.167 14h9.666c.645 0 1.167-.522 1.167-1.167V3.167C14 2.522 13.478 2 12.833 2zm2.427 2a1.594 1.594 0 0 0 0 3.188h.593v1.625h-.593a1.594 1.594 0 1 0 1.593 1.593v-.594h1.625v.594a1.594 1.594 0 1 0 1.594-1.594h-.594V7.188h.594a1.594 1.594 0 1 0-1.594-1.594v.593H7.188v-.593C7.188 4.714 6.474 4 5.594 4"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCmdBoxCommandAppleKeyFill.displayName = 'CmdBoxCommandAppleKeyFill';
const Memo = memo(SvgCmdBoxCommandAppleKeyFill);
export default Memo;
