// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChart6StatisticsGraph4Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.335 3.167C9.335 2.522 9.857 2 10.5 2h2.334C13.479 2 14 2.522 14 3.167V13h.834a.5.5 0 1 1 0 1H1.168a.5.5 0 0 1 0-1h.833v-2.5c0-.644.523-1.167 1.167-1.167h2.5v-2.5c0-.644.522-1.166 1.167-1.166h2.5zm1 9.833H13V3.167A.167.167 0 0 0 12.835 3H10.5a.167.167 0 0 0-.166.167zm-1 0V6.667h-2.5a.167.167 0 0 0-.167.166V13zm-3.667 0v-2.667h-2.5a.167.167 0 0 0-.167.167V13z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgChart6StatisticsGraph4Outline.displayName = 'Chart6StatisticsGraph4Outline';
const Memo = memo(SvgChart6StatisticsGraph4Outline);
export default Memo;
