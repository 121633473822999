// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHashtagOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.894 2.004a.5.5 0 0 1 .436.556l-.256 2.107h4.66l.27-2.227a.5.5 0 0 1 .992.12l-.255 2.107H13.5a.5.5 0 0 1 0 1h-1.88l-.566 4.666H13.5a.5.5 0 1 1 0 1h-2.567l-.27 2.227a.5.5 0 1 1-.993-.12l.256-2.107h-4.66l-.27 2.227a.5.5 0 1 1-.992-.12l.255-2.107H2.5a.5.5 0 0 1 0-1h1.88l.566-4.666H2.5a.5.5 0 0 1 0-1h2.567l.27-2.227a.5.5 0 0 1 .557-.436m.06 3.663-.566 4.666h4.659l.566-4.666z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHashtagOutline.displayName = 'HashtagOutline';
const Memo = memo(SvgHashtagOutline);
export default Memo;
