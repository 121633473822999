// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFloodOff: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.373 10.655a4.1 4.1 0 0 0-2.75 1.035.374.374 0 0 1-.53-.033.377.377 0 0 1 .034-.531 4.86 4.86 0 0 1 3.25-1.224c.551 0 1.18.23 1.795.477l.38.154c.524.214 1.065.434 1.63.597.697.2 1.399.302 2.092.188.685-.112 1.388-.438 2.086-1.138a.374.374 0 0 1 .53 0 .377.377 0 0 1 0 .532c-.802.805-1.646 1.21-2.496 1.35-.842.137-1.663.008-2.42-.209-.607-.175-1.194-.414-1.72-.63q-.185-.076-.36-.145c-.637-.255-1.134-.423-1.519-.423z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M5.373 12.537a4.1 4.1 0 0 0-2.75 1.034.374.374 0 0 1-.53-.032.377.377 0 0 1 .034-.532 4.86 4.86 0 0 1 3.25-1.223c.551 0 1.18.23 1.795.476q.19.076.38.155c.524.213 1.065.434 1.63.596.697.2 1.399.302 2.092.19.685-.113 1.388-.44 2.086-1.14a.374.374 0 0 1 .53 0 .377.377 0 0 1 0 .533c-.802.805-1.646 1.21-2.496 1.349-.842.138-1.663.01-2.42-.208-.607-.175-1.194-.415-1.72-.63l-.36-.146c-.637-.255-1.134-.422-1.519-.422zM11.375 5.036c.207 0 .375.168.375.376V9.99a.376.376 0 1 1-.75 0V5.412c0-.208.168-.376.375-.376M4.625 5.036c.207 0 .375.168.375.376v3.235a.376.376 0 1 1-.75 0V5.412c0-.208.168-.376.375-.376"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M7.999 2.753a.38.38 0 0 0-.286.129l-4.7 4.654a.374.374 0 0 1-.53-.004A.377.377 0 0 1 2.487 7l4.695-4.65a1.13 1.13 0 0 1 1.633 0L13.51 7a.377.377 0 0 1 .004.532.374.374 0 0 1-.53.004l-4.7-4.654-.01-.01a.38.38 0 0 0-.275-.12"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M8 6.891a.75.75 0 0 0-.75.753v1.14a.376.376 0 1 1-.75 0v-1.14c0-.4.158-.782.44-1.064A1.497 1.497 0 0 1 9.5 7.644v1.979a.376.376 0 1 1-.75 0V7.644A.754.754 0 0 0 8 6.891"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFloodOff.displayName = 'FloodOff';
const Memo = memo(SvgFloodOff);
export default Memo;
