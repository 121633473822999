// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolderAddPlusFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.168 2c-1.013 0-1.833.82-1.833 1.833V10h.333v-.5a1.5 1.5 0 1 1 3 0v.5h.5a1.5 1.5 0 0 1 0 3h-.5v.333h8.167c1.012 0 1.833-.82 1.833-1.833V5.833c0-1.012-.82-1.833-1.833-1.833h-4.12a.83.83 0 0 1-.693-.371l-.542-.813A1.83 1.83 0 0 0 5.954 2z" />
      <Path d="M3.668 9.5a.5.5 0 0 0-1 0V11h-1.5a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V12h1.5a.5.5 0 0 0 0-1h-1.5z" />
    </Svg>
  );
};
SvgFolderAddPlusFill.displayName = 'FolderAddPlusFill';
const Memo = memo(SvgFolderAddPlusFill);
export default Memo;
