// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShieldSecurityProtectionFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.58 1.5a1.83 1.83 0 0 0-1.16 0L3.254 2.889C2.505 3.139 2 3.839 2 4.628v3.314c0 1.825.789 3.172 1.906 4.231C5.004 13.215 6.44 14 7.763 14.713a.5.5 0 0 0 .474 0c1.323-.714 2.76-1.498 3.857-2.54C13.211 11.113 14 9.767 14 7.942V4.628c0-.79-.505-1.49-1.254-1.74z" />
    </Svg>
  );
};
SvgShieldSecurityProtectionFill.displayName = 'ShieldSecurityProtectionFill';
const Memo = memo(SvgShieldSecurityProtectionFill);
export default Memo;
