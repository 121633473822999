// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTaxDiscountFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 1.333c-1.012 0-1.833.821-1.833 1.834v11a.5.5 0 0 0 .828.377l1.377-1.2 1.226 1.183a.5.5 0 0 0 .694 0L8 13.36l1.209 1.165a.5.5 0 0 0 .694 0l1.226-1.181 1.376 1.199a.5.5 0 0 0 .829-.377v-11c0-1.013-.821-1.834-1.834-1.834zM10.53 5.52a.5.5 0 1 0-.707-.707L5.49 9.146a.5.5 0 0 0 .707.707zM6.001 6a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333m3.334 3.333a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTaxDiscountFill.displayName = 'TaxDiscountFill';
const Memo = memo(SvgTaxDiscountFill);
export default Memo;
