// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEditPencilWriteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M10.369 1.923a1.833 1.833 0 0 1 2.593 0l1.114 1.114a1.833 1.833 0 0 1 0 2.593l-8.5 8.5c-.344.343-.81.537-1.296.537H1.832a.5.5 0 0 1-.5-.5v-2.448c0-.486.193-.953.537-1.296zm1.886.707a.833.833 0 0 0-1.179 0L9.54 4.167l2.293 2.293 1.537-1.537a.833.833 0 0 0 0-1.179zm-1.13 4.537L8.832 4.874 2.576 11.13a.83.83 0 0 0-.244.589v1.948H4.28a.83.83 0 0 0 .589-.244z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEditPencilWriteOutline.displayName = 'EditPencilWriteOutline';
const Memo = memo(SvgEditPencilWriteOutline);
export default Memo;
