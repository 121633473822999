// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCatImageAnimalCuteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM3.833 3A.833.833 0 0 0 3 3.833v8.334c0 .46.373.833.833.833H6.14a3.8 3.8 0 0 1-.69-1.37 3.87 3.87 0 0 1 .055-2.159l-.582-3.22a.5.5 0 0 1 .606-.576l2.729.634a7.2 7.2 0 0 1 1.591-.635 7.2 7.2 0 0 1 1.69-.246L13 4.008v-.175A.833.833 0 0 0 12.167 3zM13 5.402l-.908.883a.5.5 0 0 1-.352.141 6.2 6.2 0 0 0-1.633.214 6.2 6.2 0 0 0-1.52.631.5.5 0 0 1-.366.056l-2.18-.506.47 2.596a.5.5 0 0 1-.02.251 2.9 2.9 0 0 0-.076 1.704c.175.652.58 1.207 1.158 1.628h4.594c.46 0 .833-.373.833-.833z"
        clipRule="evenodd"
      />
      <Path d="M9.326 9.66c.122.456-.087.909-.467 1.01-.38.102-.788-.185-.91-.641s.087-.909.467-1.01c.38-.102.787.185.91.641M13.455 8.553c.122.457-.087.909-.467 1.01-.38.103-.787-.185-.91-.64-.122-.457.087-.91.468-1.011.38-.102.787.185.91.641M12.045 10.997c.081.304-.223.65-.679.772s-.892-.025-.973-.33c-.082-.303.222-.65.678-.771.456-.122.892.025.974.329" />
    </Svg>
  );
};
SvgCatImageAnimalCuteOutline.displayName = 'CatImageAnimalCuteOutline';
const Memo = memo(SvgCatImageAnimalCuteOutline);
export default Memo;
