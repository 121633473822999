// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettings8Switch2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 8a4.667 4.667 0 0 1 4.667-4.666h5.333a4.667 4.667 0 0 1 0 9.333H5.335A4.667 4.667 0 0 1 .668 8m4.667-3.666a3.667 3.667 0 1 0 0 7.333h5.333a3.667 3.667 0 0 0 0-7.333zM8 8a2.667 2.667 0 1 1 5.334 0A2.667 2.667 0 0 1 8 8m2.667-1.666a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettings8Switch2Outline.displayName = 'Settings8Switch2Outline';
const Memo = memo(SvgSettings8Switch2Outline);
export default Memo;
