// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgArAugmentedReality3DViewCubeFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.094 1.73a1.85 1.85 0 0 1 1.812 0l4.167 2.345q.21.119.381.284L8 7.426 2.546 4.36q.17-.165.38-.284zM2.043 5.223q-.058.226-.059.463v4.628c0 .668.36 1.284.943 1.611l4.167 2.344q.195.11.406.169V8.293zM8.5 14.438q.211-.06.406-.169l4.167-2.344a1.85 1.85 0 0 0 .943-1.611V5.686q0-.238-.06-.463L8.5 8.293z" />
    </Svg>
  );
};
SvgArAugmentedReality3DViewCubeFill.displayName = 'ArAugmentedReality3DViewCubeFill';
const Memo = memo(SvgArAugmentedReality3DViewCubeFill);
export default Memo;
