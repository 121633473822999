// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTelevision2TvMonitorVideoScreenDisplayFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.604 1.86a.5.5 0 0 1 .702-.088l2.693 2.095 2.693-2.095a.5.5 0 1 1 .614.79L9.456 4h3.376c1.013 0 1.833.82 1.833 1.833v6.334c0 1.012-.82 1.833-1.833 1.833H3.165a1.833 1.833 0 0 1-1.833-1.833V5.833C1.332 4.821 2.152 4 3.165 4h3.376l-1.85-1.439a.5.5 0 0 1-.087-.701" />
    </Svg>
  );
};
SvgTelevision2TvMonitorVideoScreenDisplayFill.displayName =
  'Television2TvMonitorVideoScreenDisplayFill';
const Memo = memo(SvgTelevision2TvMonitorVideoScreenDisplayFill);
export default Memo;
