import type {BorderColor, FillColor, TextColor} from '@propertyguru/hive';
import type {PressableState} from '@propertyguru/hive-surface';

export const surfaceColor = (state: PressableState, active?: boolean): FillColor => {
  switch (true) {
    case state.disabled:
      return 'fill/inactive/primary';
    case state.hovered:
      return 'fill/hover/primary';
    case state.pressed:
      return 'fill/pressed/primary';
    case state.focused:
      return 'fill/neutral/secondary';
    default:
      return active ? 'fill/neutral/quaternary' : 'fill/neutral/secondary';
  }
};

export const textColor = (state: PressableState, active?: boolean): TextColor => {
  switch (true) {
    case state.disabled:
      return 'text/inactive/tertiary';
    default:
      return active ? 'text/active/tertiary' : 'text/active/primary';
  }
};

export const borderColor = (state: PressableState, active?: boolean): BorderColor => {
  switch (true) {
    case state.disabled:
      return 'border/inactive/primary';
    default:
      return active ? 'border/active/tertiary' : 'border/active/primary';
  }
};
