// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBenefitFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.997 4.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667M12.67 8a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667M4.667 6.667a1.333 1.333 0 1 1-2.667 0 1.333 1.333 0 0 1 2.667 0m1.364 3.5-2.165 1.274A1.46 1.46 0 0 0 3 12.777v.473c0 .414.336.75.75.75h8.5a.75.75 0 0 0 .75-.75v-.473c0-.581-.34-1.107-.866-1.336L9.97 10.168q.03-.19.031-.39v-.556C10 7.995 9.104 7 8 7s-2 .995-2 2.222v.556q0 .2.03.39M8 11c-.456 0-1-.446-1-1.222v-.556C7 8.446 7.544 8 8 8s1 .446 1 1.222v.556C9 10.554 8.456 11 8 11"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBenefitFill.displayName = 'BenefitFill';
const Memo = memo(SvgBenefitFill);
export default Memo;
