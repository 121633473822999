// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTowerOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.713 2.058a.74.74 0 0 1 .836.185l2.36 2.77a.38.38 0 0 1 .057.407.38.38 0 0 1-.341.222h-.375v1.852l.406 1.645q.008.029.014.057l.998 4.044h1.957c.207 0 .375.17.375.38s-.168.38-.375.38H2.375A.377.377 0 0 1 2 13.62c0-.21.168-.38.375-.38h1.957l.99-4.008a.4.4 0 0 1 .03-.127l.398-1.61V5.642h-.375a.38.38 0 0 1-.341-.222.38.38 0 0 1 .057-.407l2.36-2.77.019-.02a.8.8 0 0 1 .243-.165M5.105 13.24h5.79l-.11-.444-2.787-1.694-2.782 1.691zm4.477-5.319.286 1.158-1.87 1.137L6.13 9.082l.287-1.161zm.946 3.833-1.801-1.095 1.331-.81zM5.941 9.852l1.328.807-1.796 1.092zm.559-4.21h3v1.52h-3zM8 2.762l1.806 2.12H6.194z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTowerOutline.displayName = 'TowerOutline';
const Memo = memo(SvgTowerOutline);
export default Memo;
