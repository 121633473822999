// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFastForwardSymbolFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.594 3.563 8.163 6.62V4.449c0-.997 1.169-1.534 1.925-.886l4.147 3.551a1.167 1.167 0 0 1 0 1.772l-4.146 3.552c-.757.648-1.926.11-1.926-.886V9.38l-3.57 3.057c-.756.648-1.925.11-1.925-.886V4.449c0-.997 1.169-1.534 1.926-.886" />
    </Svg>
  );
};
SvgFastForwardSymbolFill.displayName = 'FastForwardSymbolFill';
const Memo = memo(SvgFastForwardSymbolFill);
export default Memo;
