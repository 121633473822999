// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFire2FlameHotHeatFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.586 1.82c.342-.485 1.019-.656 1.547-.296.64.436 1.763 1.29 2.73 2.488.965 1.199 1.802 2.778 1.802 4.654 0 3.244-2.506 6-5.666 6s-5.667-2.756-5.667-6c0-1.35.586-3.09 1.735-4.482.443-.536 1.212-.498 1.655-.057a.168.168 0 0 0 .256-.021zm.412 11.846c1.031 0 1.867-.958 1.867-2.14 0-1.284-1.007-2.225-1.542-2.634a.53.53 0 0 0-.65 0c-.534.41-1.541 1.35-1.541 2.634 0 1.182.835 2.14 1.866 2.14"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFire2FlameHotHeatFill.displayName = 'Fire2FlameHotHeatFill';
const Memo = memo(SvgFire2FlameHotHeatFill);
export default Memo;
