// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHomeDoorWindowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.085 1.283a1.83 1.83 0 0 1 1.83 0l4.166 2.399c.569.327.919.933.919 1.589v6.896C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167V5.27c0-.656.35-1.262.919-1.589zM5.833 8.167a2.167 2.167 0 1 1 4.334 0 2.167 2.167 0 0 1-4.334 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgHomeDoorWindowFill.displayName = 'HomeDoorWindowFill';
const Memo = memo(SvgHomeDoorWindowFill);
export default Memo;
