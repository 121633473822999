// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubble2AnnotationMessageFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.67 3.833C14.67 2.821 13.847 2 12.835 2H3.169c-1.012 0-1.833.82-1.833 1.833v7c0 1.013.82 1.834 1.833 1.834h1V14a.5.5 0 0 0 .741.438l3.221-1.771h4.705c1.012 0 1.833-.821 1.833-1.834zm-10.336 3.5a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0m3 0a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0M11.001 8a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubble2AnnotationMessageFill.displayName = 'Bubble2AnnotationMessageFill';
const Memo = memo(SvgBubble2AnnotationMessageFill);
export default Memo;
