// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgColorSwatchPaletteColoursFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.165 1.333c-1.012 0-1.833.821-1.833 1.834V11a3.667 3.667 0 0 0 5.55 3.147l6.734-3.888a1.833 1.833 0 0 0 .671-2.505L12.454 4.58a1.8 1.8 0 0 0-.486-.549 1.8 1.8 0 0 0-.548-.485L8.245 1.712a1.8 1.8 0 0 0-.695-.233 1.8 1.8 0 0 0-.718-.146zm9.951 8.06-4.433 2.56 3.408-5.904.028-.05 1.302 2.255a.833.833 0 0 1-.305 1.139m-1.891-3.844-2.56 4.434V3.109L10.92 4.41a.833.833 0 0 1 .305 1.138m-6.226 6.618a1.167 1.167 0 1 0 0-2.334 1.167 1.167 0 0 0 0 2.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgColorSwatchPaletteColoursFill.displayName = 'ColorSwatchPaletteColoursFill';
const Memo = memo(SvgColorSwatchPaletteColoursFill);
export default Memo;
