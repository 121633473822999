// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalendarEditDateEditOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.167 1.333a.5.5 0 0 1 .5.5v.834h4.666v-.834a.5.5 0 0 1 1 0v.834h.834c1.012 0 1.833.82 1.833 1.833v2.333a.5.5 0 0 1-1 0v-.166H3v5.5c0 .46.373.833.833.833H7.5a.5.5 0 1 1 0 1H3.833A1.833 1.833 0 0 1 2 12.167V4.5c0-1.013.82-1.833 1.833-1.833h.834v-.834a.5.5 0 0 1 .5-.5M3 5.667h10V4.5a.833.833 0 0 0-.833-.833H3.833A.833.833 0 0 0 3 4.5zM14.52 9.48a1.443 1.443 0 0 1 0 2.04l-2.61 2.61c-.343.343-.81.537-1.296.537h-.78a.5.5 0 0 1-.5-.5v-.781c0-.487.192-.953.536-1.297l2.61-2.61a1.443 1.443 0 0 1 2.04 0m-.707.707a.443.443 0 0 0-.626 0l-2.61 2.61a.83.83 0 0 0-.244.589v.28h.281a.83.83 0 0 0 .59-.243l2.61-2.61a.443.443 0 0 0 0-.626"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCalendarEditDateEditOutline.displayName = 'CalendarEditDateEditOutline';
const Memo = memo(SvgCalendarEditDateEditOutline);
export default Memo;
