// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAlignRightArrowOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M14.165 2.667a.5.5 0 0 1 .5.5v9.667a.5.5 0 0 1-1 0V3.167a.5.5 0 0 1 .5-.5M8.478 4.813a.5.5 0 0 1 .708 0l2.361 2.362a1.167 1.167 0 0 1 0 1.65l-2.361 2.362a.5.5 0 1 1-.708-.707l1.98-1.98H1.832a.5.5 0 1 1 0-1h8.626l-1.98-1.98a.5.5 0 0 1 0-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAlignRightArrowOutline.displayName = 'AlignRightArrowOutline';
const Memo = memo(SvgAlignRightArrowOutline);
export default Memo;
