// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDollarCurrencyMoneyCoinUsdOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.334 5.667 5.667 0 0 0 0-11.334M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.667-4.667a.5.5 0 0 1 .5.5v.716a2.06 2.06 0 0 1 1.423 1.016.5.5 0 1 1-.874.486c-.17-.306-.513-.55-1.05-.55-.422 0-.711.096-.885.233-.156.123-.272.318-.272.667 0 .387.134.571.32.709.22.162.517.262.916.397l.082.028.11.037c.359.12.807.27 1.16.53.44.323.728.805.728 1.514 0 .609-.224 1.113-.653 1.452a2.13 2.13 0 0 1-1.005.412v.687a.5.5 0 0 1-1 0v-.7a2.06 2.06 0 0 1-1.424-1.015.5.5 0 1 1 .874-.487c.17.307.514.551 1.05.551.422 0 .712-.097.886-.233.155-.123.272-.319.272-.667 0-.388-.134-.572-.32-.71-.22-.161-.518-.262-.916-.396l-.083-.028-.11-.037c-.358-.12-.806-.27-1.16-.53-.439-.324-.727-.805-.727-1.514 0-.61.223-1.114.653-1.452a2.13 2.13 0 0 1 1.005-.412v-.704a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDollarCurrencyMoneyCoinUsdOutline.displayName = 'DollarCurrencyMoneyCoinUsdOutline';
const Memo = memo(SvgDollarCurrencyMoneyCoinUsdOutline);
export default Memo;
