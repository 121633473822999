// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudDownloadOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.001 3.667a3.5 3.5 0 0 0-3.31 2.358.5.5 0 0 1-.409.333 3 3 0 0 0 .386 5.975h.167a.5.5 0 0 1 0 1h-.167a4 4 0 0 1-.813-7.917 4.501 4.501 0 0 1 8.636 1.442 3.25 3.25 0 0 1-.406 6.475h-.917a.5.5 0 1 1 0-1h.917a2.25 2.25 0 1 0-.072-4.499.5.5 0 0 1-.515-.52l.003-.147a3.5 3.5 0 0 0-3.5-3.5m0 3.666a.5.5 0 0 1 .5.5v3.793l.813-.813a.5.5 0 1 1 .707.707l-1.666 1.667a.5.5 0 0 1-.707 0L5.98 11.52a.5.5 0 1 1 .707-.707l.813.813V7.833a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudDownloadOutline.displayName = 'CloudDownloadOutline';
const Memo = memo(SvgCloudDownloadOutline);
export default Memo;
