// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgIconIconsShapesGamesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M11.333 1.959a2.708 2.708 0 1 0 0 5.416 2.708 2.708 0 0 0 0-5.416M4.667 2a.5.5 0 0 1 .5.5v1.667h1.666a.5.5 0 1 1 0 1H5.167v1.667a.5.5 0 0 1-1 0V5.167H2.5a.5.5 0 0 1 0-1h1.667V2.5a.5.5 0 0 1 .5-.5M13.219 10.155a.5.5 0 0 0-.707-.707l-1.179 1.178-1.178-1.178a.5.5 0 1 0-.707.707l1.178 1.178-1.178 1.179a.5.5 0 1 0 .707.707l1.178-1.179 1.179 1.179a.5.5 0 0 0 .707-.707l-1.179-1.179zM3.5 8.667a1.5 1.5 0 0 0-1.5 1.5V12.5A1.5 1.5 0 0 0 3.5 14h2.333a1.5 1.5 0 0 0 1.5-1.5v-2.333a1.5 1.5 0 0 0-1.5-1.5z" />
    </Svg>
  );
};
SvgIconIconsShapesGamesFill.displayName = 'IconIconsShapesGamesFill';
const Memo = memo(SvgIconIconsShapesGamesFill);
export default Memo;
