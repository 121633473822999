// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBook2GuideInfoFaqOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M.668 4.167a1.5 1.5 0 0 1 1.5-1.5h3.833c.818 0 1.544.392 2 1 .456-.608 1.183-1 2-1h3.834a1.5 1.5 0 0 1 1.5 1.5v7.666a1.5 1.5 0 0 1-1.5 1.5h-3.649c-.374 0-.725.09-1.021.273-.307.19-.555.461-.716.784a.5.5 0 0 1-.895 0 1.94 1.94 0 0 0-.716-.784 1.93 1.93 0 0 0-1.021-.273H2.168a1.5 1.5 0 0 1-1.5-1.5zm6.833 1a1.5 1.5 0 0 0-1.5-1.5H2.168a.5.5 0 0 0-.5.5v7.666a.5.5 0 0 0 .5.5h3.649a2.93 2.93 0 0 1 1.684.513zm1 7.68q.067-.048.138-.091a2.93 2.93 0 0 1 1.547-.423h3.649a.5.5 0 0 0 .5-.5V4.167a.5.5 0 0 0-.5-.5H10a1.5 1.5 0 0 0-1.5 1.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBook2GuideInfoFaqOutline.displayName = 'Book2GuideInfoFaqOutline';
const Memo = memo(SvgBook2GuideInfoFaqOutline);
export default Memo;
