// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudSimpleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.951 2.667C3.03 2.667.668 5.059.668 8c0 2.94 2.36 5.333 5.283 5.333h5.467c2.168 0 3.917-1.774 3.917-3.952s-1.749-3.952-3.917-3.952q-.273 0-.537.037a.38.38 0 0 1-.373-.167A5.27 5.27 0 0 0 5.95 2.667" />
    </Svg>
  );
};
SvgCloudSimpleFill.displayName = 'CloudSimpleFill';
const Memo = memo(SvgCloudSimpleFill);
export default Memo;
