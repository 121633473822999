// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAudioMusicPlaylistMusicalNoteOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M13.002 3.6a.833.833 0 0 0-1.063-.8L7.604 4.038A.83.83 0 0 0 7 4.839V12.5c0 1.278-1.209 2.167-2.5 2.167S2 13.778 2 12.5s1.209-2.167 2.5-2.167c.542 0 1.07.157 1.5.434V4.839c0-.818.543-1.538 1.33-1.763l4.335-1.238a1.833 1.833 0 0 1 2.337 1.763v6.9c0 1.277-1.21 2.166-2.5 2.166-1.292 0-2.5-.889-2.5-2.167s1.208-2.167 2.5-2.167c.542 0 1.07.157 1.5.434zm0 6.9c0-.563-.583-1.167-1.5-1.167-.918 0-1.5.604-1.5 1.167s.582 1.167 1.5 1.167c.917 0 1.5-.604 1.5-1.167M6 12.5c0-.563-.582-1.167-1.5-1.167S3 11.937 3 12.5s.582 1.167 1.5 1.167S6 13.063 6 12.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAudioMusicPlaylistMusicalNoteOutline.displayName = 'AudioMusicPlaylistMusicalNoteOutline';
const Memo = memo(SvgAudioMusicPlaylistMusicalNoteOutline);
export default Memo;
