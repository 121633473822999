// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgStreamingLiveStreamFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 5.417a2.75 2.75 0 0 1 2.75-2.75h7.833a2.75 2.75 0 0 1 2.75 2.75v5.166a2.75 2.75 0 0 1-2.75 2.75H4.082a2.75 2.75 0 0 1-2.75-2.75zm10.5.916a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666m-2.167 1a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0m-4.467 4.284C5.58 10.857 6.514 10 7.998 10c1.485 0 2.419.857 2.802 1.617.186.37-.137.716-.551.716h-4.5c-.415 0-.737-.346-.551-.716"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgStreamingLiveStreamFill.displayName = 'StreamingLiveStreamFill';
const Memo = memo(SvgStreamingLiveStreamFill);
export default Memo;
