// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgWallet1Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 4.5c0-1.013.82-1.833 1.833-1.833h9.667c1.013 0 1.833.82 1.833 1.833v7c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1.833-.833a.833.833 0 0 0-.833.833v.167h11.333V4.5a.833.833 0 0 0-.833-.833zm10.5 2H2.332v1h3.5a.5.5 0 0 1 .5.5V7.5c0 .46.373.833.833.833h1.667c.46 0 .833-.373.833-.833v-.333a.5.5 0 0 1 .5-.5h3.5zm0 2h-3.007a1.834 1.834 0 0 1-1.826 1.666H7.165A1.834 1.834 0 0 1 5.34 7.667H2.332V11.5c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgWallet1Outline.displayName = 'Wallet1Outline';
const Memo = memo(SvgWallet1Outline);
export default Memo;
