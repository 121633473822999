// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPaperPlaneSendOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.778 3.42c-.365-.993.671-1.92 1.617-1.448l9.968 4.984c.86.43.86 1.658 0 2.087l-9.968 4.985c-.946.472-1.982-.455-1.617-1.447L3.466 8zm2.57 5.08-1.631 4.427a.167.167 0 0 0 .23.206l9.969-4.984a.167.167 0 0 0 0-.298L2.948 2.867a.167.167 0 0 0-.231.206L4.347 7.5h1.818a.5.5 0 1 1 0 1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPaperPlaneSendOutline.displayName = 'PaperPlaneSendOutline';
const Memo = memo(SvgPaperPlaneSendOutline);
export default Memo;
