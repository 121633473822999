// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPopsicleIceCreamSweetsOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.332 4.5A3.833 3.833 0 0 1 7.165.667h1.667A3.833 3.833 0 0 1 12.665 4.5v5.667c0 1.012-.82 1.833-1.833 1.833H9.665v1.667a1.667 1.667 0 1 1-3.333 0V12H5.165a1.833 1.833 0 0 1-1.833-1.833zm7.5 6.5c.46 0 .833-.373.833-.833V4.5a2.833 2.833 0 0 0-2.833-2.833H7.165A2.833 2.833 0 0 0 4.332 4.5v5.667c0 .46.373.833.833.833zm-3.5 1v1.667a.667.667 0 1 0 1.333 0V12zm-.833-8a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5m3 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPopsicleIceCreamSweetsOutline.displayName = 'PopsicleIceCreamSweetsOutline';
const Memo = memo(SvgPopsicleIceCreamSweetsOutline);
export default Memo;
