// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDebugDebuggerOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.217 3.005a.167.167 0 0 0-.197.129l-.243 1.14 1.304.278.243-1.141a.167.167 0 0 0-.128-.198zM9.06 4.76l.242-1.141a1.167 1.167 0 0 0-.898-1.384l-.979-.208a1.167 1.167 0 0 0-1.383.899l-.243 1.141-.489-.104a1.833 1.833 0 0 0-2.174 1.412l-1.248 5.869c-.21.99.422 1.964 1.412 2.175l4.239.9c.99.21 1.964-.421 2.174-1.412l1.248-5.869a1.833 1.833 0 0 0-1.412-2.174zm-3.958.181a.833.833 0 0 0-.988.642l-1.248 5.869a.833.833 0 0 0 .642.988l4.239.901a.833.833 0 0 0 .988-.642l1.248-5.868a.833.833 0 0 0-.642-.989zm.124 2.556a.5.5 0 0 1 .689.158l.616.984.963-.648a.5.5 0 0 1 .559.83l-.977.656.627.932a.5.5 0 1 1-.83.558l-.636-.945-.966.605a.5.5 0 1 1-.53-.848l.951-.596-.625-.997a.5.5 0 0 1 .159-.69"
        clipRule="evenodd"
      />
      <Path d="M11.665 4a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M14.999 4a.667.667 0 1 1-1.334 0A.667.667 0 0 1 15 4M13.332 3.167a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M13.332 4.833a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M14.999 2.333a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M14.999 5.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0" />
    </Svg>
  );
};
SvgDebugDebuggerOutline.displayName = 'DebugDebuggerOutline';
const Memo = memo(SvgDebugDebuggerOutline);
export default Memo;
