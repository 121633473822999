// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMemory2SimCardSdCardOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.895 2.065a2.5 2.5 0 0 1 1.768-.732h4.838c1.013 0 1.834.821 1.834 1.834v9.666c0 1.013-.821 1.834-1.834 1.834h-7a1.833 1.833 0 0 1-1.833-1.834V5.328A2.5 2.5 0 0 1 3.4 3.561zm4.773.268v2.5a.5.5 0 0 0 1 0v-2.5h.833c.46 0 .834.373.834.834v9.666c0 .46-.374.834-.834.834h-7a.833.833 0 0 1-.833-.834V5.328c0-.397.158-.779.44-1.06l1.494-1.495a1.5 1.5 0 0 1 1.061-.44h.338v2.5a.5.5 0 0 0 1 0v-2.5z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMemory2SimCardSdCardOutline.displayName = 'Memory2SimCardSdCardOutline';
const Memo = memo(SvgMemory2SimCardSdCardOutline);
export default Memo;
