// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgShareleftOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8 2.977H7l-.339-.367.34.367L1.57 8 7 13.023v-2.356c0-.466.383-.836.84-.832 2.688.023 4.267.322 5.304.934.39.23.694.5.947.797-.148-1.697-.525-2.89-1.204-3.713-.892-1.081-2.411-1.642-5.06-1.684a.84.84 0 0 1-.827-.836V2.977zm0 2.196V2.977c0-.872-1.038-1.326-1.678-.734L.892 7.266l.339.367-.34-.367a1 1 0 0 0 0 1.468l5.43 5.023c.64.593 1.68.138 1.68-.734v-2.186c2.552.034 3.858.335 4.636.793.761.45 1.075 1.077 1.567 2.063l.016.03a.5.5 0 0 0 .947-.223c0-2.787-.353-4.884-1.508-6.284-1.153-1.397-3-1.98-5.658-2.043"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgShareleftOutline.displayName = 'ShareleftOutline';
const Memo = memo(SvgShareleftOutline);
export default Memo;
