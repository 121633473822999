// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLocationMapRouteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M8.665 4.64C8.665 3.18 9.86 2 11.332 2s2.667 1.187 2.667 2.64c0 2.073-2.347 3.253-2.447 3.307a.5.5 0 0 1-.22.053.5.5 0 0 1-.22-.053c-.1-.047-2.447-1.234-2.447-3.307M13.5 8c-.274 0-.5.227-.5.5v3.667c0 .46-.374.833-.834.833H3.5c-.64 0-1.167-.527-1.167-1.167s.527-1.166 1.167-1.166h1.166c.274 0 .5-.227.5-.5V5h2c.274 0 .5-.227.5-.5s-.226-.5-.5-.5h-2V2.5c0-.273-.226-.5-.5-.5H3.5a2.17 2.17 0 0 0-2.167 2.167v7.666A2.17 2.17 0 0 0 3.499 14h8.666C13.18 14 14 13.18 14 12.167V8.5c0-.273-.227-.5-.5-.5" />
    </Svg>
  );
};
SvgLocationMapRouteFill.displayName = 'LocationMapRouteFill';
const Memo = memo(SvgLocationMapRouteFill);
export default Memo;
