// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgHideEyeOffSeeLookNotVisibleFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.188 1.48a.5.5 0 0 0-.707.707l2.112 2.112c-.896.723-1.705 1.681-2.38 2.866a1.69 1.69 0 0 0 0 1.67c1.286 2.256 3.058 3.691 4.985 4.243 1.804.517 3.701.246 5.383-.79l2.233 2.232a.5.5 0 1 0 .707-.707zM9.5 10.205l-.728-.727a1.667 1.667 0 0 1-2.25-2.25l-.727-.727A2.667 2.667 0 0 0 9.5 10.206"
        clipRule="evenodd"
      />
      <Path d="M13.15 11.028a10.6 10.6 0 0 0 1.639-2.193 1.69 1.69 0 0 0 0-1.67C13.107 4.213 10.587 2.667 8 2.667a6.6 6.6 0 0 0-2.652.56z" />
    </Svg>
  );
};
SvgHideEyeOffSeeLookNotVisibleFill.displayName = 'HideEyeOffSeeLookNotVisibleFill';
const Memo = memo(SvgHideEyeOffSeeLookNotVisibleFill);
export default Memo;
