// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgInbox1ArchiveTrayShelfFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.833 2h8.334C13.179 2 14 2.82 14 3.833V8h-3.38a.5.5 0 0 0-.491.407 2.167 2.167 0 0 1-4.258 0A.5.5 0 0 0 5.381 8H2V3.833C2 2.821 2.82 2 3.833 2" />
      <Path d="M2 9v3.167C2 13.179 2.82 14 3.833 14h8.334C13.179 14 14 13.18 14 12.167V9h-2.995a3.168 3.168 0 0 1-6.01 0z" />
    </Svg>
  );
};
SvgInbox1ArchiveTrayShelfFill.displayName = 'Inbox1ArchiveTrayShelfFill';
const Memo = memo(SvgInbox1ArchiveTrayShelfFill);
export default Memo;
