// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBallFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.05 3.05a7 7 0 0 1 11.397 2.222.4.4 0 0 1 .058.143 7 7 0 0 1-.731 6.543.4.4 0 0 1-.084.12 7 7 0 0 1-9.533 1.773 7 7 0 0 1-2.434-8.948.44.44 0 0 1 .094-.185A7 7 0 0 1 3.05 3.05M13.367 5.05A6.13 6.13 0 0 0 8.79 1.926c.168.383.356.932.53 1.71a2.41 2.41 0 0 1 2.077 1.699 8 8 0 0 1 1.97-.286m-1.874 1.168a7 7 0 0 1 2.27-.29 6.12 6.12 0 0 1-.302 4.846 8 8 0 0 0-.351-.621c-.312-.5-.684-.995-1.037-1.429a22 22 0 0 0-1.075-1.22c.286-.371.459-.818.495-1.286m-2.4 2.22c.446 0 .879-.123 1.253-.351a21 21 0 0 1 1.048 1.19c.34.417.687.88.973 1.338.24.384.424.747.53 1.065q-.258.344-.566.652a6.125 6.125 0 0 1-7.195 1.083c.943-.837 1.785-2.051 2.41-3.084a26 26 0 0 0 1.058-1.943q.24.05.49.05m-1.3-.382q-.06.121-.138.274c-.204.402-.499.952-.859 1.548-.67 1.11-1.535 2.322-2.437 3.049A6.12 6.12 0 0 1 1.875 8c0-.888.193-1.755.555-2.547 1.203.053 2.125.14 2.817.23l1.441.283a2.4 2.4 0 0 0 .704 1.767q.185.184.4.322M5.247 5.683l-2.672-.526zm0 0q.434.057.753.113c.302.053.528.102.69.142l-.002.028zm.903-.749c-.695-.121-1.742-.254-3.245-.334a6.12 6.12 0 0 1 4.88-2.721q.058.092.143.266c.144.3.332.793.513 1.57a2.4 2.4 0 0 0-1.56 1.37c-.18-.045-.42-.097-.73-.15"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBallFill.displayName = 'BallFill';
const Memo = memo(SvgBallFill);
export default Memo;
