// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgKeyboardUp2CloseDownOpenArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.668 1.833a.5.5 0 0 0-1 0v.334a.5.5 0 0 1-.5.5h-6c-1.013 0-1.833.82-1.833 1.833v.833H2.5c-1.012 0-1.833.821-1.833 1.834v5.666c0 1.013.82 1.834 1.833 1.834h11c1.013 0 1.834-.821 1.834-1.834V7.167c0-1.013-.821-1.834-1.834-1.834H4.335V4.5c0-.46.373-.833.833-.833h6a1.5 1.5 0 0 0 1.5-1.5zM6.501 11a.5.5 0 0 0 0 1h3a.5.5 0 1 0 0-1zM3.835 9.167a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334m0 3a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334m9-3.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m-.667 3.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334m-5-3.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0m2.333.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgKeyboardUp2CloseDownOpenArrowFill.displayName = 'KeyboardUp2CloseDownOpenArrowFill';
const Memo = memo(SvgKeyboardUp2CloseDownOpenArrowFill);
export default Memo;
