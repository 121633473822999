// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgLightBulbIdeaLightFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M13.336 6a5.32 5.32 0 0 1-1.613 3.823H4.28A5.334 5.334 0 1 1 13.336 6M5.334 11.5v-.677h5.335v.677c0 .645-.522 1.167-1.166 1.167H6.5A1.167 1.167 0 0 1 5.334 11.5M6.501 13.667a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
    </Svg>
  );
};
SvgLightBulbIdeaLightFill.displayName = 'LightBulbIdeaLightFill';
const Memo = memo(SvgLightBulbIdeaLightFill);
export default Memo;
