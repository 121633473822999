// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubbles2MessagesChatCommunicateOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.17 3.833C4.17 2.821 4.99 2 6.002 2h6.833c1.012 0 1.833.82 1.833 1.833v4.334c0 1.012-.82 1.833-1.833 1.833h-.833v.833c0 1.013-.821 1.834-1.834 1.834H7.132L4.245 14.27a.5.5 0 0 1-.742-.437v-1.166h-.334a1.833 1.833 0 0 1-1.833-1.834V6.5c0-1.013.82-1.833 1.833-1.833h1zm-1 1.834a.833.833 0 0 0-.834.833v4.333c0 .46.373.834.833.834h.834a.5.5 0 0 1 .5.5v.817L6.76 11.73a.5.5 0 0 1 .243-.063h3.166c.46 0 .834-.373.834-.834V9.54a1 1 0 0 1 0-.078V6.5a.833.833 0 0 0-.834-.833zM12.002 9V6.5c0-1.013-.821-1.833-1.834-1.833h-5v-.834c0-.46.373-.833.834-.833h6.833c.46 0 .833.373.833.833v4.334c0 .46-.373.833-.833.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBubbles2MessagesChatCommunicateOutline.displayName = 'Bubbles2MessagesChatCommunicateOutline';
const Memo = memo(SvgBubbles2MessagesChatCommunicateOutline);
export default Memo;
