// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMultiMediaMediaImageAndVideoFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M4.165 5a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667" />
      <Path
        fillRule="evenodd"
        d="M3.165 1.333c-1.012 0-1.833.821-1.833 1.834v5.666c0 1.013.82 1.834 1.833 1.834h2.167v2.166c0 1.013.82 1.834 1.833 1.834h5.667c1.013 0 1.833-.821 1.833-1.834V7.167c0-1.013-.82-1.834-1.833-1.834h-2.167V3.167c0-1.013-.82-1.834-1.833-1.834zm6.5 4V3.167a.833.833 0 0 0-.833-.834H3.165a.833.833 0 0 0-.833.834v4.009l.648-.432a1.83 1.83 0 0 1 2.034 0l.33.219a1.834 1.834 0 0 1 1.821-1.63zM5.332 8.157l-.872-.581a.83.83 0 0 0-.925 0l-1.203.802v.455c0 .46.373.834.833.834h2.167zm3.333 3.216V8.627a.5.5 0 0 1 .787-.41l1.962 1.373a.5.5 0 0 1 0 .82l-1.962 1.373a.5.5 0 0 1-.787-.41"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMultiMediaMediaImageAndVideoFill.displayName = 'MultiMediaMediaImageAndVideoFill';
const Memo = memo(SvgMultiMediaMediaImageAndVideoFill);
export default Memo;
