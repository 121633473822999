// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgChromecastCastFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.165 2c-1.012 0-1.833.82-1.833 1.833v.68q.248-.012.5-.013a9 9 0 0 1 8.986 9.5h2.014c1.013 0 1.833-.82 1.833-1.833V3.833c0-1.012-.82-1.833-1.833-1.833zM1.832 8.667a.5.5 0 1 0 0 1A3.833 3.833 0 0 1 5.665 13.5a.5.5 0 0 0 1 0 4.833 4.833 0 0 0-4.833-4.833" />
      <Path d="M1.332 6.5a.5.5 0 0 1 .5-.5 7.5 7.5 0 0 1 7.5 7.5.5.5 0 0 1-1 0 6.5 6.5 0 0 0-6.5-6.5.5.5 0 0 1-.5-.5M1.832 11.333a.5.5 0 0 0 0 1c.644 0 1.167.523 1.167 1.167a.5.5 0 0 0 1 0c0-1.197-.97-2.167-2.167-2.167" />
    </Svg>
  );
};
SvgChromecastCastFill.displayName = 'ChromecastCastFill';
const Memo = memo(SvgChromecastCastFill);
export default Memo;
