// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolder2OpenOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2h2.787c.613 0 1.185.306 1.525.816l.74 1.11c.03.046.083.074.138.074h3.81C13.178 4 14 4.82 14 5.833v.855c.657.127 1.093.801.895 1.475l-1.134 3.854A1.83 1.83 0 0 1 12 13.333h-9.12a1.55 1.55 0 0 1-1.549-1.548zm2.997 8.5h7.672c.37 0 .695-.243.8-.598l1.133-3.855a.167.167 0 0 0-.16-.213H6.33a.83.83 0 0 0-.8.598l-1.164 3.957a2 2 0 0 1-.037.111m8.67-5.666h-6.67c-.813 0-1.529.535-1.758 1.316L3.407 11.94a.549.549 0 0 1-1.075-.155V3.833c0-.46.373-.833.833-.833h2.787c.278 0 .539.14.693.371l.74 1.11c.216.324.58.519.97.519h3.81c.46 0 .834.373.834.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolder2OpenOutline.displayName = 'Folder2OpenOutline';
const Memo = memo(SvgFolder2OpenOutline);
export default Memo;
