// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBook2GuideInfoFaqFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M7.501 5.167a2.5 2.5 0 0 0-2.5-2.5H2.918a2.25 2.25 0 0 0-2.25 2.25v6.166a2.25 2.25 0 0 0 2.25 2.25h2.899c.373 0 .724.09 1.02.273.276.17.504.406.664.686zM8.501 14.292c.16-.28.389-.515.664-.686a1.93 1.93 0 0 1 1.021-.273h2.899a2.25 2.25 0 0 0 2.25-2.25V4.917a2.25 2.25 0 0 0-2.25-2.25H11a2.5 2.5 0 0 0-2.5 2.5zM8.008 14.667h-.014z" />
    </Svg>
  );
};
SvgBook2GuideInfoFaqFill.displayName = 'Book2GuideInfoFaqFill';
const Memo = memo(SvgBook2GuideInfoFaqFill);
export default Memo;
