// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCursor1ArrowFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.32 1.83c-.925-.329-1.817.564-1.489 1.49l3.742 10.55c.356 1.007 1.765 1.045 2.176.06l1.793-4.3a.17.17 0 0 1 .09-.089l4.3-1.794c.985-.41.946-1.82-.06-2.176z" />
    </Svg>
  );
};
SvgCursor1ArrowFill.displayName = 'Cursor1ArrowFill';
const Memo = memo(SvgCursor1ArrowFill);
export default Memo;
