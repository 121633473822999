// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAltTextOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2 3.833C2 2.821 2.82 2 3.833 2h8.334C13.179 2 14 2.82 14 3.833v8.334C14 13.179 13.18 14 12.167 14H3.833A1.833 1.833 0 0 1 2 12.167zM3.833 3A.833.833 0 0 0 3 3.833V6.81l.987-1.011a1.833 1.833 0 0 1 2.626 0L9.729 9H13V3.833A.833.833 0 0 0 12.167 3zM13 10H3v2.167c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833zM3 9h5.333L5.896 6.496a.833.833 0 0 0-1.193 0L3 8.242zm7.5-4a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1M9 5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m-5 6a.5.5 0 0 1 .5-.5h3.667a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5m5.667 0a.5.5 0 0 1 .5-.5H11.5a.5.5 0 0 1 0 1h-1.333a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgAltTextOutline.displayName = 'AltTextOutline';
const Memo = memo(SvgAltTextOutline);
export default Memo;
