// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBubblesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M5.165 1.333a3.833 3.833 0 1 0 0 7.667 3.833 3.833 0 0 0 0-7.667M9.332 9a2.833 2.833 0 1 0 0 5.667 2.833 2.833 0 0 0 0-5.667M9.999 6.167a2.333 2.333 0 1 1 4.666 0 2.333 2.333 0 0 1-4.666 0" />
    </Svg>
  );
};
SvgBubblesFill.displayName = 'BubblesFill';
const Memo = memo(SvgBubblesFill);
export default Memo;
