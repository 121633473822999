// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgOutdoorGymOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M2.17 2.337c0-.08.061-.144.138-.144h1.39c.076 0 .138.064.138.144V5.49c0 .079-.062.143-.139.143H2.308a.14.14 0 0 1-.139-.143zm2.5 1.147V2.337c0-.555-.436-1.004-.973-1.004H2.308c-.537 0-.972.45-.972 1.004V5.49c0 .554.435 1.003.972 1.003h1.39c.536 0 .971-.45.971-1.003V4.344h.834v5.161c0 .238.186.43.416.43s.417-.192.417-.43V4.344h3.333v5.161c0 .238.187.43.417.43s.417-.192.417-.43V4.344h.833V5.49c0 .554.435 1.003.972 1.003h1.389c.537 0 .972-.45.972-1.003V2.337c0-.555-.435-1.004-.972-1.004h-1.389c-.537 0-.972.45-.972 1.004v1.147h-.833v-.86a.424.424 0 0 0-.417-.43.424.424 0 0 0-.417.43v.86H6.336v-.86a.424.424 0 0 0-.417-.43.424.424 0 0 0-.416.43v.86zm7.5 2.007V2.337c0-.08.062-.144.138-.144h1.389c.077 0 .139.064.139.144V5.49c0 .079-.062.143-.139.143h-1.389a.14.14 0 0 1-.139-.143m-7.5 5.304a.424.424 0 0 0-.417.43c0 .238.186.43.416.43H6.2l-.681 2.463a.43.43 0 0 0 .286.532.415.415 0 0 0 .515-.296l.747-2.698h1.871l.747 2.698c.064.229.294.36.515.296a.43.43 0 0 0 .287-.532l-.682-2.463h1.53c.231 0 .418-.192.418-.43a.424.424 0 0 0-.417-.43H4.669"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgOutdoorGymOutline.displayName = 'OutdoorGymOutline';
const Memo = memo(SvgOutdoorGymOutline);
export default Memo;
