// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMouse3ScrollUpOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.983 1.41a1.83 1.83 0 0 1 2.034 0l1.26.84a.5.5 0 0 1-.554.833l-1.26-.84a.83.83 0 0 0-.925 0l-1.26.84a.5.5 0 1 1-.555-.832zM4 8a4 4 0 1 1 8 0v3.333a4 4 0 0 1-8 0zm4-3a3 3 0 0 0-3 3v3.333a3 3 0 0 0 6 0V8a3 3 0 0 0-3-3m0 1.667a.5.5 0 0 1 .5.5v1.666a.5.5 0 0 1-1 0V7.167a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMouse3ScrollUpOutline.displayName = 'Mouse3ScrollUpOutline';
const Memo = memo(SvgMouse3ScrollUpOutline);
export default Memo;
