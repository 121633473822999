// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgClockSnoozeTimerSnoozeZzOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M12.003 1.167a.5.5 0 0 1 .5-.5h2.333a.5.5 0 0 1 .395.807l-1.706 2.193h1.31a.5.5 0 1 1 0 1h-2.332a.5.5 0 0 1-.395-.807l1.706-2.193h-1.311a.5.5 0 0 1-.5-.5m-4 1.166a5.667 5.667 0 1 0 5.457 4.135.5.5 0 0 1 .963-.27 6.667 6.667 0 1 1-3.898-4.371.5.5 0 1 1-.378.926 5.7 5.7 0 0 0-2.144-.42m0 2.5a.5.5 0 0 1 .5.5v2.46l1.52 1.52a.5.5 0 1 1-.707.707L7.649 8.354A.5.5 0 0 1 7.503 8V5.333a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgClockSnoozeTimerSnoozeZzOutline.displayName = 'ClockSnoozeTimerSnoozeZzOutline';
const Memo = memo(SvgClockSnoozeTimerSnoozeZzOutline);
export default Memo;
