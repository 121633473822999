// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgFolder2Outline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833C1.332 2.821 2.152 2 3.165 2h2.922c.477 0 .936.186 1.278.52l.594.577a.83.83 0 0 0 .581.236h4.292c1.013 0 1.833.821 1.833 1.834V11.5c0 1.012-.82 1.833-1.833 1.833H3.165A1.833 1.833 0 0 1 1.332 11.5zm1 4V11.5c0 .46.373.833.833.833h9.667c.46 0 .833-.373.833-.833V7.833A.833.833 0 0 0 12.832 7H3.165a.833.833 0 0 0-.833.833M13.665 6.2c-.25-.128-.533-.2-.833-.2H3.165c-.3 0-.583.072-.833.2V3.833c0-.46.373-.833.833-.833h2.922c.217 0 .425.085.58.236l.595.578c.342.333.8.52 1.278.52h4.292c.46 0 .833.372.833.833z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgFolder2Outline.displayName = 'Folder2Outline';
const Memo = memo(SvgFolder2Outline);
export default Memo;
