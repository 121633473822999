// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCalenderAddOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.167 1.333a.5.5 0 0 1 .5.5v.834h4.666v-.834a.5.5 0 0 1 1 0v.834h.834c1.012 0 1.833.82 1.833 1.833v3a.5.5 0 0 1-1 0v-.833H3v5.5c0 .46.373.833.833.833H7.5a.5.5 0 0 1 0 1H3.833A1.833 1.833 0 0 1 2 12.167V4.5c0-1.013.82-1.833 1.833-1.833h.834v-.834a.5.5 0 0 1 .5-.5M3 5.667h10V4.5a.833.833 0 0 0-.833-.833H3.833A.833.833 0 0 0 3 4.5zm8.833 3.666a.5.5 0 0 1 .5.5V11.5H14a.5.5 0 0 1 0 1h-1.667v1.667a.5.5 0 1 1-1 0V12.5H9.667a.5.5 0 1 1 0-1h1.666V9.833a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCalenderAddOutline.displayName = 'CalenderAddOutline';
const Memo = memo(SvgCalenderAddOutline);
export default Memo;
