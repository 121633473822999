// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgAi3StarsSparklesOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.665 4.667a.5.5 0 0 1 .5.5c0 1.617.358 2.667 1.012 3.321S11.882 9.5 13.499 9.5a.5.5 0 0 1 0 1c-1.617 0-2.668.357-3.322 1.011-.654.655-1.012 1.705-1.012 3.322a.5.5 0 1 1-1 0c0-1.617-.357-2.667-1.011-3.322C6.5 10.858 5.449 10.5 3.832 10.5a.5.5 0 0 1 0-1c1.617 0 2.668-.357 3.322-1.012.654-.654 1.011-1.704 1.011-3.321a.5.5 0 0 1 .5-.5m0 3.339a3.9 3.9 0 0 1-.804 1.19A3.9 3.9 0 0 1 6.67 10q.682.298 1.19.804.505.509.804 1.19.3-.68.805-1.19A3.9 3.9 0 0 1 10.66 10a3.9 3.9 0 0 1-1.19-.804 3.9 3.9 0 0 1-.805-1.19"
        clipRule="evenodd"
      />
      <Path d="M3.999 3.667a.333.333 0 0 0-.667 0c0 .653-.145 1.048-.381 1.285s-.632.381-1.286.381a.333.333 0 0 0 0 .667c.654 0 1.049.145 1.285.382.237.236.382.63.382 1.285a.333.333 0 1 0 .667 0c0-.654.145-1.049.381-1.285C4.617 6.145 5.012 6 5.665 6a.333.333 0 1 0 0-.667c-.653 0-1.048-.144-1.285-.381S4 4.32 4 3.667M7.332 1a.333.333 0 1 0-.667 0c0 .422-.094.65-.221.778-.128.128-.356.222-.779.222a.333.333 0 1 0 0 .667c.423 0 .65.094.779.221.127.128.221.356.221.779a.333.333 0 1 0 .667 0c0-.423.094-.65.222-.779.128-.127.356-.221.778-.221a.333.333 0 1 0 0-.667c-.422 0-.65-.094-.778-.222S7.332 1.422 7.332 1" />
    </Svg>
  );
};
SvgAi3StarsSparklesOutline.displayName = 'Ai3StarsSparklesOutline';
const Memo = memo(SvgAi3StarsSparklesOutline);
export default Memo;
