// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgRaisingHand4FingerHeyHelloFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.892 1.333a1.11 1.11 0 0 0-1.108 1.111V6.89a.37.37 0 1 1-.738 0V3.926A1.11 1.11 0 0 0 3.94 2.815a1.11 1.11 0 0 0-1.107 1.11v5.556c0 2.864 2.313 5.186 5.167 5.186s5.166-2.322 5.166-5.186V5.407c-1.019 0-1.845.83-1.845 1.852v.063a1.57 1.57 0 0 1-.758 1.344 2.25 2.25 0 0 0-1.087 1.927.37.37 0 1 1-.738 0 2.99 2.99 0 0 1 1.445-2.562c.248-.15.4-.419.4-.71V7.26c0-.501.142-.97.387-1.366a.4.4 0 0 1-.018-.115V3.185a1.11 1.11 0 0 0-1.107-1.11 1.11 1.11 0 0 0-1.107 1.11v2.963a.37.37 0 1 1-.738 0V2.444a1.11 1.11 0 0 0-1.107-1.11"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgRaisingHand4FingerHeyHelloFill.displayName = 'RaisingHand4FingerHeyHelloFill';
const Memo = memo(SvgRaisingHand4FingerHeyHelloFill);
export default Memo;
