// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBookmarkBannerFlagTagFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      fill={color}
      viewBox="0 0 17 16"
      {...props}
    >
      <Path d="M11.274 1.02h-5.55c-.362 0-.665 0-.913.02a1.9 1.9 0 0 0-.73.184 1.87 1.87 0 0 0-.817.817 1.9 1.9 0 0 0-.184.73c-.02.248-.02.55-.02.914v8.818c0 .417 0 .764.023 1.03.022.249.07.558.273.789.257.292.641.44 1.028.396.305-.034.549-.23.733-.401.195-.181.428-.438.708-.747l2.246-2.479c.146-.16.234-.257.305-.323a.5.5 0 0 1 .07-.058.17.17 0 0 1 .106 0 .5.5 0 0 1 .071.058c.07.066.159.162.305.323l2.246 2.479c.28.309.513.566.708.747.184.17.427.367.733.401a1.19 1.19 0 0 0 1.027-.396c.204-.23.252-.54.274-.79.023-.265.023-.612.023-1.029V3.685c0-.363 0-.666-.02-.914a1.9 1.9 0 0 0-.184-.73 1.87 1.87 0 0 0-.817-.817 1.9 1.9 0 0 0-.73-.184c-.248-.02-.551-.02-.914-.02" />
    </Svg>
  );
};
SvgBookmarkBannerFlagTagFill.displayName = 'BookmarkBannerFlagTagFill';
const Memo = memo(SvgBookmarkBannerFlagTagFill);
export default Memo;
