// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMenu3ListHamburgerOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.332 3.833a.5.5 0 0 1 .5-.5h12.333a.5.5 0 0 1 0 1H1.832a.5.5 0 0 1-.5-.5m0 4.167a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0 4.166a.5.5 0 0 1 .5-.5h12.333a.5.5 0 0 1 0 1H1.832a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMenu3ListHamburgerOutline.displayName = 'Menu3ListHamburgerOutline';
const Memo = memo(SvgMenu3ListHamburgerOutline);
export default Memo;
