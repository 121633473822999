import React from 'react';
import {StyleSheet} from 'react-native';

import {type Typography, useHive} from '@propertyguru/hive';
import {Icon} from '@propertyguru/hive-icon';
import {Spinner} from '@propertyguru/hive-spinner';
import {Surface} from '@propertyguru/hive-surface';
import {Text} from '@propertyguru/hive-text';

import {
  getIconColor,
  getSpinnerColor,
  getTextColor,
  surfaceBorderColor,
  surfaceColor,
} from './buttonTheme';
import type {ButtonComponent, IconButtonProps} from './types';

const ICON_SIZE = 24;
const SMALL_BUTTON_SIZE = 40;
const LARGE_BUTTON_SIZE = 48;

const Button: ButtonComponent = ({
  type = 'primary',
  text,
  textStyle,
  icon,
  size = 'large',
  disabled,
  loading,
  style,
  onPress,
  ...props
}) => {
  const hive = useHive();

  const iconColor = getIconColor(type, !disabled);
  const borderWidth = disabled && type !== 'secondary' ? 0 : 1;
  const typography: Typography = size === 'large' ? 'label/m' : 'label/s';
  const elevation = type !== 'secondary' && !disabled ? 'soft/down/small' : undefined;

  return (
    <Surface
      style={[
        styles.button,
        {
          minHeight: size === 'large' ? LARGE_BUTTON_SIZE : SMALL_BUTTON_SIZE,
          paddingHorizontal: hive.spacing('x4'),
        },
        style,
      ]}
      color={(state) => surfaceColor(type, state, loading)}
      borderColor={(state) => surfaceBorderColor(type, state)}
      borderRadius={'full'}
      elevation={elevation}
      borderWidth={borderWidth}
      disabled={disabled}
      onPress={loading ? undefined : onPress}
      {...props}
    >
      {loading ? (
        <Spinner
          color={getSpinnerColor(type, !disabled)}
          size={'small'}
        />
      ) : (
        <>
          {icon && (
            <Icon
              icon={icon}
              color={iconColor}
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          )}
          <Text
            typography={typography}
            color={getTextColor(type, !disabled)}
            style={[textStyle, {marginLeft: icon && hive.spacing('x1')}]}
            numberOfLines={1}
          >
            {text}
          </Text>
        </>
      )}
    </Surface>
  );
};

const ButtonIcon: React.FC<IconButtonProps> = ({
  icon,
  size = 'large',
  disabled,
  onPress,
  style,
  loading,
  ...props
}) => {
  const buttonSize = size === 'large' ? LARGE_BUTTON_SIZE : SMALL_BUTTON_SIZE;
  const buttonIconType = 'icon';
  const elevation = !disabled ? 'soft/down/small' : undefined;
  return (
    <Surface
      style={[
        styles.iconButton,
        {
          height: buttonSize,
          width: buttonSize,
        },
        style,
      ]}
      color={(state) => surfaceColor(buttonIconType, state)}
      borderColor={(state) => surfaceBorderColor(buttonIconType, state)}
      borderRadius={'full'}
      elevation={elevation}
      borderWidth={disabled ? 0 : 1}
      disabled={disabled}
      onPress={loading ? undefined : onPress}
      {...props}
    >
      {loading ? (
        <Spinner
          color={getSpinnerColor('icon', !disabled)}
          size={'small'}
        />
      ) : (
        <Icon
          icon={icon}
          color={getIconColor('secondary', !disabled)}
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
      )}
    </Surface>
  );
};

Button.Icon = ButtonIcon;
export {Button};

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 120,
  },
  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
