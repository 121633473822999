// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBusOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M8.006 1.333c-1.631 0-2.955.254-3.888.734-.944.486-1.532 1.236-1.532 2.195v2.305h-.814a.436.436 0 0 0 0 .872h.814v3.801a2.43 2.43 0 0 0 .995 1.96v1.03a.436.436 0 1 0 .872 0v-.626q.275.065.562.066h5.98q.286 0 .56-.066v.626a.436.436 0 1 0 .872 0v-1.027a2.44 2.44 0 0 0 .999-1.963V7.439h.806a.436.436 0 0 0 0-.872h-.806V4.262c0-.96-.588-1.709-1.533-2.195-.933-.48-2.256-.734-3.887-.734m4.547 5.612V5.196H3.458V8.81h9.095V7.06a.5.5 0 0 1 0-.116m0-2.621v-.062c0-.537-.309-1.033-1.059-1.42-.761-.391-1.93-.636-3.488-.636s-2.727.245-3.489.637c-.75.386-1.059.882-1.059 1.419v.062zm0 5.358H3.458v1.558a1.56 1.56 0 0 0 1.557 1.557h5.98a1.557 1.557 0 0 0 1.558-1.557zm-7.041 1.121a.436.436 0 0 0 0 .873h.498a.436.436 0 0 0 0-.873zm4.048.437c0-.241.195-.437.436-.437h.498a.436.436 0 0 1 0 .873h-.498a.436.436 0 0 1-.436-.436"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBusOutline.displayName = 'BusOutline';
const Memo = memo(SvgBusOutline);
export default Memo;
