// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSettings3GearPreferencesFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.624 2.07a1.652 1.652 0 0 1 2.75 0l.38.57a.83.83 0 0 0 .88.35l.47-.11a1.68 1.68 0 0 1 2.014 2.016l-.108.469a.83.83 0 0 0 .35.88l.57.38a1.652 1.652 0 0 1 0 2.75l-.57.38a.83.83 0 0 0-.35.88l.108.47a1.68 1.68 0 0 1-2.015 2.014l-.469-.108a.83.83 0 0 0-.88.35l-.38.57a1.652 1.652 0 0 1-2.75 0l-.38-.57a.83.83 0 0 0-.88-.35l-.47.108a1.68 1.68 0 0 1-2.015-2.014l.109-.47a.83.83 0 0 0-.35-.88l-.57-.38a1.652 1.652 0 0 1 0-2.75l.57-.38a.83.83 0 0 0 .35-.88l-.109-.47a1.68 1.68 0 0 1 2.015-2.014l.47.108a.83.83 0 0 0 .88-.35zM5.915 8a2.083 2.083 0 1 1 4.167 0 2.083 2.083 0 0 1-4.167 0"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSettings3GearPreferencesFill.displayName = 'Settings3GearPreferencesFill';
const Memo = memo(SvgSettings3GearPreferencesFill);
export default Memo;
