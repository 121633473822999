import React from 'react';
import {Pressable, StyleSheet, View, type ViewProps} from 'react-native';

import {useHive, withStaticProperties} from '@propertyguru/hive';
import {Icon} from '@propertyguru/hive-icon';
import {CrossedSmallDeleteRemoveOutline} from '@propertyguru/hive-icons';
import {Surface} from '@propertyguru/hive-surface';
import {Text, type TextProps} from '@propertyguru/hive-text';

import type {HeaderButton, ModalProps} from './types';

const Header: React.FC<React.PropsWithChildren> = ({children}) => {
  const {color, spacing} = useHive();
  return (
    <View
      style={[
        styles.header,
        {
          backgroundColor: color('fill/neutral/quaternary'),
          padding: spacing('x4'),
        },
      ]}
    >
      {children}
    </View>
  );
};

const HeaderTitle: React.FC<TextProps> = (props) => {
  return (
    <Text
      typography="label/l"
      color={'text/active/tertiary'}
      {...props}
    />
  );
};

const BUTTON_SIZE = 24;

const HeaderButton: React.FC<HeaderButton> = ({
  icon = CrossedSmallDeleteRemoveOutline,
  ...props
}) => {
  return (
    <Pressable
      hitSlop={BUTTON_SIZE}
      {...props}
    >
      <Icon
        icon={icon}
        width={BUTTON_SIZE}
        height={BUTTON_SIZE}
        color={'icon/active/tertiary'}
      />
    </Pressable>
  );
};

const Body: React.FC<ViewProps> = ({style, ...props}) => {
  const {spacing} = useHive();
  return (
    <View
      style={[
        {
          padding: spacing('x4'),
        },
        style,
      ]}
      {...props}
    />
  );
};

const Root: React.FC<ModalProps> = ({children, ...restProps}) => {
  return (
    <Surface
      color={'fill/neutral/secondary'}
      borderRadius={'large'}
      elevation={'soft/down/medium'}
      {...restProps}
    >
      {children}
    </Surface>
  );
};

export const Modal = withStaticProperties(Root, {
  Header: withStaticProperties(Header, {
    Title: HeaderTitle,
    Button: HeaderButton,
  }),
  Body,
});

Modal.displayName = 'Modal';
Modal.Header.displayName = 'Modal.Header';
Modal.Header.Title.displayName = 'Modal.Header.Title';
Modal.Header.Button.displayName = 'Modal.Header.Button';
Modal.Body.displayName = 'Modal.Body';

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
