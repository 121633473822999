import React, {useEffect} from 'react';
import {Pressable, StyleSheet} from 'react-native';

import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import {useHive} from '@propertyguru/hive';
import {Icon} from '@propertyguru/hive-icon';
import {CheckSmallCheckmarkFill, CrossedSmallDeleteRemoveFill} from '@propertyguru/hive-icons';

import {knobColor, knobIconColor, trackDisabledColor} from './knob-colors';
import type {SwitchProps} from './types';

const SWITCH_TRACK_WIDTH = 40;
const KNOB_SIZE = 24;
const KNOB_TRANSLATE_X = (SWITCH_TRACK_WIDTH - KNOB_SIZE) / 2;
const ICON_SIZE = 16;

export const Switch: React.FC<SwitchProps> = ({
  style,
  onCheckChange,
  checked = false,
  disabled = false,
}) => {
  const {color} = useHive();
  const knobPosition = useSharedValue(checked ? 1 : 0);
  const knobIcon = disabled ? CrossedSmallDeleteRemoveFill : CheckSmallCheckmarkFill;
  const disabledTrackColor = color(trackDisabledColor(checked));
  const backgroundInterpolateColor = {
    fillNeutralTertiary: color('fill/neutral/tertiary'),
    fillBrandGreen: color('fill/brand/green'),
  };
  const borderInterpolateColor = {
    borderInactivePrimary: color('border/inactive/primary'),
    borderSuccessPrimary: color('border/success/primary'),
  };

  const handlePress = (): void => {
    onCheckChange?.(!checked);
  };

  useEffect(() => {
    knobPosition.value = withTiming(checked ? 1 : 0, {duration: 200});
  }, [checked, knobPosition]);

  const animatedKnobStyle = useAnimatedStyle(() => ({
    transform: [{translateX: knobPosition.value * KNOB_TRANSLATE_X * 2 - KNOB_TRANSLATE_X}],
  }));

  const animatedKnobIconStyle = useAnimatedStyle(() => ({
    opacity: knobPosition.value,
  }));

  const animatedTrackStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: disabled
        ? disabledTrackColor
        : interpolateColor(
            knobPosition.value,
            [0, 1],
            [
              backgroundInterpolateColor.fillNeutralTertiary as string,
              backgroundInterpolateColor.fillBrandGreen as string,
            ],
          ),
      borderColor: disabled
        ? borderInterpolateColor.borderInactivePrimary
        : interpolateColor(
            knobPosition.value,
            [0, 1],
            [
              borderInterpolateColor.borderInactivePrimary as string,
              borderInterpolateColor.borderSuccessPrimary as string,
            ],
          ),
    };
  });

  return (
    <Pressable
      onPress={handlePress}
      disabled={disabled || false}
      style={style}
    >
      <Animated.View style={[styles.switchTrack, animatedTrackStyle]}>
        <Animated.View
          style={[
            styles.knobContainer,
            {
              backgroundColor: color(knobColor(disabled, checked)),
              borderColor: color('border/active/primary'),
              shadowColor: color('fill/inactive/secondary'),
            },
            animatedKnobStyle,
          ]}
        >
          {checked && (
            <Animated.View style={animatedKnobIconStyle}>
              <Icon
                icon={knobIcon}
                color={knobIconColor(disabled)}
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            </Animated.View>
          )}
        </Animated.View>
      </Animated.View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  switchTrack: {
    width: SWITCH_TRACK_WIDTH,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 50,
  },
  knobContainer: {
    width: KNOB_SIZE,
    height: KNOB_SIZE,
    borderWidth: 1,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.08,
    shadowRadius: 4,
    elevation: 2,
  },
  knobIcon: {
    fontSize: 10,
  },
});
