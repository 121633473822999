// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPullRequestOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.333 2.333a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667M2 3.667a2.333 2.333 0 1 1 2.833 2.28v4.107a2.334 2.334 0 1 1-1 0V5.946A2.334 2.334 0 0 1 2 3.666m8.187-2.187a.5.5 0 0 1 0 .707l-.98.98h1.126c1.013 0 1.834.82 1.834 1.833v5.054a2.334 2.334 0 1 1-1 0V5a.833.833 0 0 0-.834-.833H9.207l.98.98a.5.5 0 0 1-.707.707L7.646 4.02a.5.5 0 0 1 0-.707L9.48 1.48a.5.5 0 0 1 .707 0M4.333 11a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667m7.334 0a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPullRequestOutline.displayName = 'PullRequestOutline';
const Memo = memo(SvgPullRequestOutline);
export default Memo;
