// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgEthereumFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="m7.823 9.468-1.107-.415L8 10.817 9.28 9.053l-1.107.415a.5.5 0 0 1-.35 0M7.999 8.466l1.875-.703L8 5.183l-1.876 2.58z" />
      <Path
        fillRule="evenodd"
        d="M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m7.071-3.96a.5.5 0 0 0-.809 0L4.928 7.705a.5.5 0 0 0 0 .588l2.666 3.667a.5.5 0 0 0 .81 0l2.666-3.667a.5.5 0 0 0 0-.588z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgEthereumFill.displayName = 'EthereumFill';
const Memo = memo(SvgEthereumFill);
export default Memo;
