// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPlusCircleAddOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M7.999 2.333a5.667 5.667 0 1 0 0 11.333 5.667 5.667 0 0 0 0-11.333M1.332 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.332 8m6.667-3.328a.5.5 0 0 1 .5.5V7.5h2.328a.5.5 0 1 1 0 1H8.5v2.328a.5.5 0 0 1-1 0V8.5H5.17a.5.5 0 0 1 0-1H7.5V5.172a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPlusCircleAddOutline.displayName = 'PlusCircleAddOutline';
const Memo = memo(SvgPlusCircleAddOutline);
export default Memo;
