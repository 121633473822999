// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloseQuoteBlockquoteFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path d="M3.17 2.667A1.834 1.834 0 0 0 1.335 4.5v2.858c0 1.013.82 1.834 1.833 1.834h.5v3.645a.5.5 0 0 0 .218.412c.312.214.68-.033.95-.19a5.3 5.3 0 0 0 1.014-.77c.743-.722 1.485-1.881 1.485-3.597V4.5a1.833 1.833 0 0 0-1.833-1.833zM10.503 2.667A1.834 1.834 0 0 0 8.669 4.5v2.858c0 1.013.821 1.834 1.834 1.834h.5v3.645a.5.5 0 0 0 .217.412c.312.214.681-.033.951-.19a5.3 5.3 0 0 0 1.013-.77c.743-.722 1.485-1.881 1.485-3.597V4.5a1.833 1.833 0 0 0-1.833-1.833z" />
    </Svg>
  );
};
SvgCloseQuoteBlockquoteFill.displayName = 'CloseQuoteBlockquoteFill';
const Memo = memo(SvgCloseQuoteBlockquoteFill);
export default Memo;
