// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMessengerBw: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M1.336 7.8c0-3.715 2.912-6.467 6.666-6.467s6.667 2.754 6.667 6.469c0 3.714-2.912 6.466-6.667 6.466a7.3 7.3 0 0 1-1.93-.257.54.54 0 0 0-.356.027l-1.323.583a.533.533 0 0 1-.749-.471l-.036-1.187a.53.53 0 0 0-.179-.38c-1.296-1.16-2.093-2.84-2.093-4.783m4.621-1.215L4 9.69c-.188.299.179.634.459.42L6.56 8.515a.4.4 0 0 1 .481-.002l1.559 1.168a1 1 0 0 0 1.446-.266l1.96-3.105c.187-.298-.18-.635-.46-.422L9.444 7.485a.4.4 0 0 1-.482.002L7.404 6.318a1 1 0 0 0-1.447.267"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMessengerBw.displayName = 'MessengerBw';
const Memo = memo(SvgMessengerBw);
export default Memo;
