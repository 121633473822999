// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgMouse3ScrollUpFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4 8a4 4 0 1 1 8 0v3.333a4 4 0 0 1-8 0zm4.5-.833a.5.5 0 1 0-1 0v1.666a.5.5 0 0 0 1 0zM5.584 2.944a.5.5 0 0 0 .693.139l1.26-.84a.83.83 0 0 1 .925 0l1.26.84a.5.5 0 0 0 .555-.832l-1.26-.84a1.83 1.83 0 0 0-2.034 0l-1.26.84a.5.5 0 0 0-.139.693"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgMouse3ScrollUpFill.displayName = 'Mouse3ScrollUpFill';
const Memo = memo(SvgMouse3ScrollUpFill);
export default Memo;
