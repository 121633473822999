// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBrushColorOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M9.589 2.963a.833.833 0 0 0-1.179 0L6.373 5 11 9.626l2.037-2.037a.833.833 0 0 0 0-1.178l-.244-.244h-1.126a.5.5 0 0 1-.5-.5V4.54zm.703 7.37L5.666 5.707l-.98.98a.44.44 0 0 0 0 .626c.748.747.749 1.959.001 2.706l-1.724 1.725a.833.833 0 0 0 0 1.179l.114.114a.833.833 0 0 0 1.179 0l1.724-1.725a1.914 1.914 0 0 1 2.707.001.44.44 0 0 0 .626 0zM5.32 4.64l-.015.015L3.98 5.98a1.44 1.44 0 0 0 0 2.04.913.913 0 0 1 .001 1.292l-1.724 1.725a1.833 1.833 0 0 0 0 2.593l.114.114a1.833 1.833 0 0 0 2.593 0l1.724-1.724a.914.914 0 0 1 1.292 0 1.44 1.44 0 0 0 2.04 0l3.725-3.724a1.833 1.833 0 0 0 0-2.592l-.391-.39a.5.5 0 0 0-.354-.147h-.833v-.834a.5.5 0 0 0-.146-.353l-1.724-1.724a1.833 1.833 0 0 0-2.593 0z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBrushColorOutline.displayName = 'BrushColorOutline';
const Memo = memo(SvgBrushColorOutline);
export default Memo;
