// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgToolboxFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.665 2.333a.06.06 0 0 0-.05.03l-.85 1.578a.83.83 0 0 0-.1.395v2.33h2v-2.33a.83.83 0 0 0-.1-.395l-.849-1.577a.06.06 0 0 0-.05-.03m0-1c.39 0 .747.214.932.557l.85 1.577c.143.267.218.566.218.87v2.33H8v-3.5c0-1.013.82-1.834 1.833-1.834h1.667c1.012 0 1.833.821 1.833 1.834v3.5h.833a.5.5 0 0 1 .5.5v5c0 1.012-.82 1.833-1.833 1.833H3.165a1.833 1.833 0 0 1-1.833-1.833v-5a.5.5 0 0 1 .5-.5h.833v-2.33c0-.304.076-.603.22-.87l.849-1.577c.184-.343.542-.557.931-.557M9 6.667h3.333v-3.5a.833.833 0 0 0-.833-.834H9.832a.833.833 0 0 0-.833.834V4h1.166a.5.5 0 0 1 0 1H9z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgToolboxFill.displayName = 'ToolboxFill';
const Memo = memo(SvgToolboxFill);
export default Memo;
