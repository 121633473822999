// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgDeleteRemoveBackspaceFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.868 2.667c-.603 0-1.167.296-1.509.792l-2.414 3.5a1.83 1.83 0 0 0 0 2.082l2.414 3.5c.342.496.906.793 1.51.793h6.966c1.012 0 1.833-.821 1.833-1.834v-7c0-1.012-.82-1.833-1.833-1.833zm1.279 3.646a.5.5 0 0 1 .707 0l.98.981.982-.98a.5.5 0 0 1 .707.707L9.542 8l.98.98a.5.5 0 1 1-.707.707l-.98-.98-.98.98a.5.5 0 1 1-.707-.707l.98-.98-.981-.98a.5.5 0 0 1 0-.708"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgDeleteRemoveBackspaceFill.displayName = 'DeleteRemoveBackspaceFill';
const Memo = memo(SvgDeleteRemoveBackspaceFill);
export default Memo;
