// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPenthouseFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.5 1c-.264 0-.5.259-.5.501s.236.5.5.5H7v1H1.456a.46.46 0 0 0-.453.467L1 5.534a.46.46 0 0 0 .453.467H2v8.532c0 .243.236.468.5.468s.5-.225.5-.468V6.001h11v8.532c0 .243.236.468.5.468s.503-.225.503-.468V1.467A.46.46 0 0 0 14.551 1zM12 5.001h-2v-3h2zm-10 0v-1h1v1zm2 0v-1h1v1zm2 0v-1h1v1zm-1 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zm0 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zm4-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm1 3a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPenthouseFill.displayName = 'PenthouseFill';
const Memo = memo(SvgPenthouseFill);
export default Memo;
