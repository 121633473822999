import React from 'react';
import {StyleSheet} from 'react-native';

import {type Color, useHive} from '@propertyguru/hive';

import {borderStyle} from './borderStyle';
import {computeState} from './compat/computeState';
import {SurfaceCompat} from './compat/SurfaceCompat';
import {SurfaceForeground} from './SurfaceForeground';
import type {PressableState, StatefulColor, SurfaceProps} from './types';

const toToken = <T extends StatefulColor<Color>>(
  token: T | undefined,
  state: PressableState,
): Color | undefined => {
  return token && typeof token === 'function' ? token(state) : token;
};

const isPressable = (props: SurfaceProps) => {
  switch (true) {
    case !!props.onPress:
    case !!props.onLongPress:
    case !!props.onPressIn:
    case !!props.onPressOut:
      return true;
    default:
      return false;
  }
};

const EmptyState: PressableState = {
  pressed: false,
  disabled: false,
  hovered: false,
  focused: false,
};

export const Surface: React.FC<SurfaceProps> = (props) => {
  const {
    elevation,
    color,
    borderWidth,
    borderRadius,
    borderColor,
    disabled,
    style,
    children,
    ...restProps
  } = props;

  const hive = useHive();

  return (
    <SurfaceCompat
      android_ripple={
        typeof color === 'function'
          ? {
              foreground: true,
              color: hive.color(color({...EmptyState, pressed: true})),
            }
          : undefined
      }
      elevation={elevation}
      style={(state) =>
        computeState(
          (mergedState) => [
            !!color && {
              backgroundColor: hive.color(toToken(color, !disabled ? EmptyState : mergedState)),
            },
            !!borderColor && {
              borderColor: hive.color(toToken(borderColor, mergedState)) || 'transparent',
            },
            !!borderWidth && {borderWidth: borderWidth},
            borderStyle(hive, borderRadius),
            !!borderRadius && styles.border,
            !isPressable(props) && {pointerEvents: 'box-none'},
            disabled && {pointerEvents: 'none'},
            typeof style === 'function' ? style(mergedState) : style,
          ],
          state,
          disabled,
        )
      }
      {...restProps}
    >
      {(state) =>
        computeState(
          (mergeState) => (
            <>
              {typeof children === 'function' ? children(mergeState) : children}
              {!disabled && !!color && typeof color === 'function' && (
                <SurfaceForeground
                  state={mergeState}
                  style={[
                    !!color && {
                      backgroundColor: hive.color(toToken(color, mergeState)),
                    },
                    borderStyle(hive, borderRadius),
                    !!borderRadius && {
                      ...styles.border,
                    },
                  ]}
                />
              )}
            </>
          ),
          state,
          disabled,
        )
      }
    </SurfaceCompat>
  );
};

const styles = StyleSheet.create({
  border: {overflow: 'hidden'},
});
