// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgBookmarkPlusBannerFlagTagOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      fill={color}
      viewBox="0 0 17 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.725 1.02h5.549c.363 0 .666 0 .914.02.258.021.5.067.73.184.352.179.638.465.817.817.117.23.163.472.184.73.02.248.02.55.02.914v8.886c0 .315 0 .586-.02.8s-.064.465-.224.675a1.19 1.19 0 0 1-.888.466c-.264.013-.496-.092-.683-.198-.188-.105-.411-.26-.67-.438L9.444 12.49c-.48-.331-.613-.413-.742-.445a.85.85 0 0 0-.407 0c-.13.032-.263.114-.742.445l-2.009 1.385c-.26.179-.483.333-.67.438-.187.106-.42.211-.683.198a1.19 1.19 0 0 1-.888-.466c-.16-.21-.205-.46-.225-.674s-.02-.486-.02-.801V3.685c0-.363 0-.666.021-.914.021-.258.067-.5.184-.73a1.87 1.87 0 0 1 .817-.817 1.9 1.9 0 0 1 .73-.184c.248-.02.55-.02.914-.02m-.831 1.037c-.195.016-.289.044-.35.075a.85.85 0 0 0-.372.372c-.031.061-.06.155-.075.35-.017.201-.017.462-.017.852v8.841c0 .346 0 .57.015.732.01.101.022.144.026.156a.17.17 0 0 0 .11.058.7.7 0 0 0 .144-.068c.141-.08.326-.206.61-.402l1.99-1.372.067-.046c.379-.262.676-.467 1.01-.55.294-.072.6-.072.895 0 .334.083.631.288 1.01.55l.067.046 1.989 1.372c.285.196.47.323.61.402.09.05.133.064.145.068a.17.17 0 0 0 .11-.058.7.7 0 0 0 .026-.156 9 9 0 0 0 .015-.732V3.706c0-.39 0-.65-.017-.852-.016-.195-.044-.289-.076-.35a.85.85 0 0 0-.371-.372c-.062-.03-.155-.06-.35-.075a12 12 0 0 0-.852-.017H5.746c-.39 0-.65 0-.852.017M8.499 3.74a.51.51 0 0 1 .51.51v1.7h1.7a.51.51 0 0 1 0 1.02h-1.7v1.7a.51.51 0 1 1-1.02 0v-1.7h-1.7a.51.51 0 0 1 0-1.02h1.7v-1.7a.51.51 0 0 1 .51-.51"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgBookmarkPlusBannerFlagTagOutline.displayName = 'BookmarkPlusBannerFlagTagOutline';
const Memo = memo(SvgBookmarkPlusBannerFlagTagOutline);
export default Memo;
