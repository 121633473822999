// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgCloudLinesSyncDocumentListPageFileOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M4.501 2.334a.833.833 0 0 0-.833.833v9.667c0 .46.373.833.833.833h2.667a.5.5 0 0 1 0 1H4.501a1.833 1.833 0 0 1-1.833-1.833V3.167c0-1.013.82-1.833 1.833-1.833h7c1.013 0 1.834.82 1.834 1.833v5a.5.5 0 1 1-1 0v-5a.833.833 0 0 0-.834-.833zM5.335 4.5a.5.5 0 0 1 .5-.5h4.333a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5H7.5a.5.5 0 0 1 0 1H5.835a.5.5 0 0 1-.5-.5m5.5 3.5a1.5 1.5 0 0 0 0 3h1.833a1 1 0 1 0-.066-1.998.5.5 0 0 1-.466-.25 1.5 1.5 0 0 0-1.301-.752m-2.5 1.5a2.5 2.5 0 0 1 4.505-1.493 2 2 0 0 1-.172 3.993h-1.833a2.5 2.5 0 0 1-2.5-2.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgCloudLinesSyncDocumentListPageFileOutline.displayName =
  'CloudLinesSyncDocumentListPageFileOutline';
const Memo = memo(SvgCloudLinesSyncDocumentListPageFileOutline);
export default Memo;
