// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgToiletPaperWipeOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.578 3.951C3.23 4.621 3 5.58 3 6.667s.23 2.046.578 2.715c.361.694.771.951 1.089.951s.727-.257 1.088-.95c.348-.67.578-1.63.578-2.716s-.23-2.047-.578-2.716C5.395 3.257 4.984 3 4.667 3s-.728.257-1.089.951M6.343 3q.165.232.3.49c.435.838.69 1.962.69 3.177v5.5c0 .46.373.833.834.833h4c.46 0 .833-.373.833-.833V6.5c0-1.044-.213-1.964-.533-2.605-.335-.67-.706-.895-.967-.895zm-.01 8.333v.834c0 1.012.821 1.833 1.834 1.833h4C13.179 14 14 13.18 14 12.167V6.5c0-1.165-.235-2.245-.639-3.052C12.973 2.671 12.344 2 11.5 2H4.667c-.88 0-1.553.676-1.976 1.49C2.255 4.328 2 5.452 2 6.667c0 1.214.255 2.338.691 3.177.396.761 1.01 1.402 1.809 1.481v.008z"
        clipRule="evenodd"
      />
      <Path d="M5.168 6.667c0 .46-.224.833-.5.833s-.5-.373-.5-.833.224-.834.5-.834.5.373.5.834" />
    </Svg>
  );
};
SvgToiletPaperWipeOutline.displayName = 'ToiletPaperWipeOutline';
const Memo = memo(SvgToiletPaperWipeOutline);
export default Memo;
