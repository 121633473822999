// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgPrivateGardenFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M3.072 1.075a.5.5 0 0 1 .435-.044l2.16.81 2.16-.81a.5.5 0 0 1 .435.044.43.43 0 0 1 .205.363v2.187c0 .696-.295 1.364-.82 1.856a2.88 2.88 0 0 1-1.658.752c-.152.428-.2.884-.14 1.335.062.468.24.916.518 1.307h3.72q.033-.045.07-.103c.125-.186.292-.451.458-.768.29-.554.564-1.235.635-1.91a2.8 2.8 0 0 1-1.031-.613 2.55 2.55 0 0 1-.82-1.856V1.438a.43.43 0 0 1 .204-.363.5.5 0 0 1 .435-.044l2.16.81 2.16-.81a.5.5 0 0 1 .435.044.43.43 0 0 1 .205.363v2.187c0 .696-.295 1.364-.82 1.856a2.9 2.9 0 0 1-2.007.769c-.098.802-.416 1.564-.718 2.14q-.142.267-.274.485h3.354a.48.48 0 0 1 .356.155.42.42 0 0 1 .105.354l-.674 3.788a2.16 2.16 0 0 1-.793 1.31A2.43 2.43 0 0 1 12.02 15H3.981a2.43 2.43 0 0 1-1.508-.518 2.16 2.16 0 0 1-.793-1.31l-.674-3.788a.42.42 0 0 1 .105-.354.48.48 0 0 1 .356-.155h3.83a3.677 3.677 0 0 1-.265-2.693 2.86 2.86 0 0 1-1.345-.7 2.55 2.55 0 0 1-.82-1.857V1.438a.43.43 0 0 1 .205-.363m1.547 9.563c.25-.058.504.084.566.319l.467 1.75c.062.234-.09.471-.34.53s-.503-.084-.566-.318l-.466-1.75c-.063-.235.09-.472.34-.53m3.379-.013c.258 0 .467.196.467.438v1.75c0 .241-.21.437-.467.437-.258 0-.467-.196-.467-.437v-1.75c0-.242.21-.438.467-.438m3.724.544c.063-.235-.09-.472-.34-.53-.25-.06-.503.083-.565.318l-.467 1.75c-.062.234.09.471.34.53s.503-.084.565-.318z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgPrivateGardenFill.displayName = 'PrivateGardenFill';
const Memo = memo(SvgPrivateGardenFill);
export default Memo;
