// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgSnowFlakesFreezeFrozenOutline: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M5.988 1.646a.5.5 0 0 1 .707.001L8 2.957l1.304-1.31a.5.5 0 1 1 .71.706l-1.514 1.52v3.26l2.806-1.628.553-2.077a.5.5 0 1 1 .967.257l-.479 1.795 1.784.48a.5.5 0 1 1-.26.966l-2.064-.556L8.996 8l2.81 1.63 2.064-.556a.5.5 0 1 1 .26.965l-1.784.48.479 1.796a.5.5 0 1 1-.966.257l-.554-2.077-2.806-1.627v3.259l1.513 1.52a.5.5 0 0 1-.709.706L8 13.043l-1.304 1.31a.5.5 0 0 1-.709-.706l1.513-1.52V8.869l-2.803 1.626-.554 2.077a.5.5 0 0 1-.966-.257l.478-1.795-1.784-.48a.5.5 0 1 1 .26-.966l2.065.556L7.004 8l-2.81-1.63-2.064.556a.5.5 0 1 1-.26-.965l1.784-.48-.478-1.796a.5.5 0 0 1 .966-.257l.554 2.077L7.499 7.13V3.873l-1.513-1.52a.5.5 0 0 1 .002-.707"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgSnowFlakesFreezeFrozenOutline.displayName = 'SnowFlakesFreezeFrozenOutline';
const Memo = memo(SvgSnowFlakesFreezeFrozenOutline);
export default Memo;
