// This file is generated by svgr

import * as React from 'react';
import {memo} from 'react';

import type {SvgProps} from 'react-native-svg';
import Svg, {Path} from 'react-native-svg';

import {useHive} from '@propertyguru/hive';
const SvgTradeTradingFill: React.FC<SvgProps> = (props) => {
  const hive = useHive();
  const color = React.useMemo(() => hive.color('icon/active/primary'), [hive]);
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill={color}
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M6.167 2a.5.5 0 0 1 .5.5v5.667a.5.5 0 0 1-1 0V2.5a.5.5 0 0 1 .5-.5M2.5 4.667a.5.5 0 0 1 .5.5v5.666a.5.5 0 1 1-1 0V5.167a.5.5 0 0 1 .5-.5m11 0a.5.5 0 0 1 .5.5v5.666a.5.5 0 1 1-1 0V5.167a.5.5 0 0 1 .5-.5M9.833 7.333a.5.5 0 0 1 .5.5V13.5a.5.5 0 1 1-1 0V7.833a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </Svg>
  );
};
SvgTradeTradingFill.displayName = 'TradeTradingFill';
const Memo = memo(SvgTradeTradingFill);
export default Memo;
